//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-card/dist/card.css';
import '../../framework/assets/css/tema.scss';
import "./style.scss";

import React, {useRef} from 'react';
import {Col} from "antd";
import {useGridWidth, useWindowSize} from "../../framework/lib/iy2b-react";
import IY2BReactModal from "../../framework/componentes/IY2BReactModal";
import {campoInformado} from "../../framework/lib/iy2b-javascript";

import {CUSTOM} from "../../framework/assets/iconografia";


const iconCheck = CUSTOM.CHECK_ICON_GREEN;
const iconCheckCircle = CUSTOM.CHECK_CIRCLE_ICON_PURPLE;

const PopUpMaisInfoPlano = ( props ) => {

    const { exibePopUp, onRequestClose, plano } = props;

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 10 : 30;
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = 30;

    const modalRefApi = useRef();

    const popupTitle = "Detalhes do plano";

    let detalhesPlano = null;

    if(campoInformado(plano) === true) {

        const colSpanPlanos = {span:24};

        const liLayout1 = plano.descrItens.filter(item => (item.flgLayout==='1')).map(item => {
            return (
                <div key={plano.id+"."+item.nrItem} className={"item"}>
                    {iconCheck}
                    <span>{item.txItem}</span>
                </div>
            );
        });

        const ulLayout1 = (liLayout1.length===0)?null:<div className="itens layout-1">{liLayout1}</div>;

        const liLayout2 = plano.descrItens.filter(item => (item.flgLayout==='2')).map(item => {
            return (
                <div key={plano.id+"."+item.nrItem} className={"item"}>
                    {iconCheckCircle}
                    <span>{item.txItem}</span>
                </div>
            );
        });

        const ulLayout2 = (liLayout2.length===0)?null:<div className="itens layout-2">{liLayout2}</div>;

        const statusClass = plano.selecionado ? "selecionado" : "";

        detalhesPlano =
            <Col key={plano.id} {...colSpanPlanos} >
                <div className={["box-preco", statusClass].toClassName()}>
                    <div>
                        <label className="nome">{plano.noPlano}</label>
                        <label className="formaPagto">{plano.formaPagto}</label>
                        <label className="total">Valor total {plano.total}</label>
                        <label className="descricao">{plano.descrPlano}</label>
                        <div className={"containerItensPlano"}>
                            {ulLayout1}
                            {ulLayout2}
                        </div>
                    </div>
                </div>
            </Col>
        ;

    }

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={24}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"mais-detalhes-plano-contratovenda"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-mais-detalhes-plano-contratovenda"}>
                    {detalhesPlano}
                </div>
            }
        />
    );

}

export default PopUpMaisInfoPlano;

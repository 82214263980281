import '../assets/css/tema.scss';
import '../assets/css/aguardandoTransacao.scss';
//v5 import "antd/dist/antd.css";

import React, {useEffect} from "react";
import IY2BLogoSpinner from "./IY2BLogoSpinner/IY2BLogoSpinner";

const AguardandoTransacao = ( props ) => {

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        console.log("AguardandoTransacao inicializa");

        return () => {

            console.log("AguardandoTransacao finaliza");

        };

    }, []);

    return (
        <div className={"boxAguardandoTransacao"}>
            <IY2BLogoSpinner className={"loader-transacao"}/>
            <h2>
                Aguarde, carregando a página escolhida ...
            </h2>
        </div>
    );

}

/**
 * <Loader type="Circles" color="firebrick" height="45" width="45" className={"loader-transacao"}/>
 */
export default AguardandoTransacao;

import '../assets/css/tema.scss';
import '../assets/css/uploadLoadingIndicator.scss';
//v5 import "antd/dist/antd.css";

import {usePromiseTracker} from "react-promise-tracker";
import React from "react";

import {useWindowSize} from "../lib/iy2b-react";
import {CUSTOM} from "../assets/iconografia";

const iconUpload = CUSTOM.SYNC_ALT_ICON_PRIMARY_4X_PULSE;

const UploadLoadingIndicator = props => {

    const windowSize = useWindowSize();

    const { promiseInProgress } = usePromiseTracker({area: props.area});

    return (
        promiseInProgress &&
        <div id="backdropLoadingUpload" >
            <div
                style={{
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: ((windowSize.height/2)-80) + "px",
                    right: ((windowSize.width/2)-((windowSize.width/2)/2)) + "px",
                    padding: "10px"
                }}
            >
                <div className={"box-spinner"}>
                    {iconUpload}
                    <label>
                        Por favor aguarde, estamos recebendo o seu arquivo !
                    </label>
                </div>
            </div>
        </div>

    );

};

export default UploadLoadingIndicator;

import '../../assets/css/tema.scss';
import './style.scss';

import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {notificacaoErroRequest, useStateRef, useWindowSize} from "../../lib/iy2b-react";
import {Alert, Button, Col, Row, Space, Tooltip} from "antd";
import IY2BTable from "../../componentes/IY2BTable";
import {PLUS_ICON_PRIMARY} from "../../assets/iconografia";
import BotaoEditarRegistroTabela from "../../componentes/BotaoEditarRegistroTabela/botaoEditarRegistroTabela";
import BotaoExcluirRegistroTabela from "../../componentes/BotaoExcluirRegistroTabela/botaoExcluirRegistroTabela";
import {postJSON} from "../../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";
import {notificacao} from "../../lib/Notificacao";
import {pesquisarEnderecoDaPessoa} from "../../lib/Servicos";
import {campoInformado, formataCidadeDoEndereco, formataNoRuaDoEndereco} from "../../lib/iy2b-javascript";
import EditarEndereco from "./editarEndereco";

const { ErrorBoundary } = Alert;

const iconPlusCircle = PLUS_ICON_PRIMARY;

const GridEnderecos = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {

        };
    });

    const { loadingArea } = props;

    const { cdTransacao = 0, cdPessoa = 0, exibeInscricoes = true, exibeMeiosContato = true} = props;

    const tableEnderecoRef = useRef();
    const [dsEndereco, setDsEndereco, refDsEndereco] = useStateRef( [] );

    const [exibeNovoRegistro, setExibeNovoRegistro] = useState(false);
    const [registrosAEditar, setRegistrosAEditar] = useState([]);

    const windowSize = useWindowSize();

    useEffect(() => {

        if(cdPessoa > 0) {

            pesquisarEnderecoDaPessoa (cdPessoa, loadingArea, cdTransacao).then(enderecos => {
                setDsEndereco(enderecos.map( endereco => {
                    return {
                        key: endereco.nrSeqEnd,
                        noRuaFormatado: formataNoRuaDoEndereco(endereco),
                        noCidadeFormatado: formataCidadeDoEndereco(endereco),
                        ...endereco
                    }
                }));
            });

        }

        return () => {

        };

    }, [props.cdPessoa]);

    const salvar = async (registro) => {

        try {

            const acaoWebAExecutar = campoInformado(registro.nrSeqEnd)?"alterar":"incluir";

            const payload = {
                acaoAExecutar: "acaoCRUD",
                registro: {
                    ... registro,
                    cdPessoa: cdPessoa
                }
            }

            console.log(payload);

            const resultado = await postJSON({
                acaoWebAExecutar: acaoWebAExecutar,
                sistema: "sgr",
                projeto: "mendereco"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    const modelo = msg.modeloPadrao;

                    modelo.key = modelo.nrSeqEnd;

                    const dsItem = refDsEndereco.current.getBy("key", modelo.key);

                    if(dsItem === null) {

                        refDsEndereco.current.push(modelo);

                        setDsEndereco ( refDsEndereco.current.clone() );

                    } else {

                        dsItem.tpEndereco = modelo.tpEndereco;
                        dsItem.noRua = modelo.noRua;
                        dsItem.nrTelefone = modelo.nrTelefone;
                        dsItem.noCidade = modelo.noCidade;
                        dsItem.ufCidade = modelo.ufCidade;

                        dsItem.noRuaFormatado = formataNoRuaDoEndereco(modelo);
                        dsItem.noCidadeFormatado = formataCidadeDoEndereco(modelo);

                    }

                } else {

                    console.log(msg);

                }

            });

            notificacao.sucesso({transacao:cdTransacao, message:"Sucesso!", description:"Endereço salvo com sucesso!"})

            return true;

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const novoEndereco = () => {

        setExibeNovoRegistro (true);

        setRegistrosAEditar ([]);

    }

    const editarEndereco = ( endereco ) => {

        setExibeNovoRegistro (true);

        setRegistrosAEditar ([ endereco ]);

    }

    const excluirEndereco = async ( enderecos ) => {

        try {

            const payload = {
                acaoAExecutar: "acaoCRUD",
                lista: enderecos.map(item => {
                    return {
                        cdPessoa: item.cdPessoa,
                        nrSeqEnd: item.nrSeqEnd
                    }
                })
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "excluir",
                sistema: "sgr",
                projeto: "mendereco"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelosExcluidos") {

                    const novods = refDsEndereco.current.removeList("nrSeqEnd", msg.lista).clone();

                    setDsEndereco ( novods ) ;

                } else {

                    console.log(msg);

                }

            });

            setExibeNovoRegistro(false) ;

            notificacao.sucesso({transacao:cdTransacao, message:"Sucesso!", description:"Endereço excluído com sucesso!"})

            return true;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const actionViewEndereco = (_, record) => {

        const botoes = [];

        botoes.push(
            <BotaoEditarRegistroTabela
                key={"btnEditEnd" + record.key}
                tooltip={"Editar este endereco"}
                registro={record}
                editarRegistro={editarEndereco}
            />
        );

        /*
        botoes.push(<BotaoExcluirRegistroTabela
            key={"btnDeleteEnd" + record.key}
            registro={record}
            excluir={excluirEndereco}
            tooltip={"Excluir este endereco"}
        />);
        */

        return (
            <Space size={4} align="baseline" className={"resultado-action-view"}>
                {botoes}
            </Space>
        );
    };

    const nrTelefone_labelF = (rce, row, index) => {

    }

    const tblEnderecoCols = [{
        title: '',
        key: 'actionView',
        width: 80,
        align: 'center',
        render: actionViewEndereco
    },
        {noColuna:'Sequência', field:'nrSeqEnd', width:70, align:'text-right', colSpan:1, hidden:true },
        {noColuna:'Endereço', field:'noRuaFormatado', width:350, colSpan:1},
        {noColuna:'Telefone', field:'nrTelefone', width:150, colSpan:1}, // , labelFunction: nrTelefone_labelF
        {noColuna:'Cidade', field:'noCidadeFormatado', width:300, colSpan:1},
    ];

    const tableEnderecoActions = [
        <Tooltip title="Novo endereço" color={"black"} key={"novoEndereco"}>
            <Button
                type="text"
                onClick={() => {
                    novoEndereco();
                }}
                className="action-button"
                icon={iconPlusCircle}
            >
                <span>&nbsp;Novo endereço</span>
            </Button>
        </Tooltip>
    ];

    /*

     */

    return (
    <ErrorBoundary>
        <Row gutter={[8,16]}>
            <Col span={24}>
                <IY2BTable id={"tblEnderecoMinhaEmpresa"} name={"tblEnderecoMinhaEmpresa"}
                           enableRowCheck={false}
                           api={tableEnderecoRef}
                           dataSource={dsEndereco}
                           columns={tblEnderecoCols}
                           tableHeight={500}
                           downloadDisabled={true}
                           configDisabled={true}
                           paginationDisabled={true}
                           searchDisabled={true}
                />
            </Col>
        </Row>
        <EditarEndereco
            cdTransacao={cdTransacao}
            exibePopUp={ exibeNovoRegistro }
            onRequestClose={() => {
                setExibeNovoRegistro(false) ;
            }}
            registros={registrosAEditar}
            salvar={salvar}
        />
    </ErrorBoundary>
    );

});

export default GridEnderecos;

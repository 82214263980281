//v5 import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
import './login.scss';

import React, {useEffect, useState} from 'react';
import {connect } from 'react-redux';
import {
    Layout,
    Input,
    Form,
    Button,
    Modal,
    Row,
    Col,
    notification,
    Popconfirm,
    Tooltip,
    Drawer
} from 'antd';
import { useAuthState } from "react-firebase-hooks/auth";
import {useHistory} from "react-router-dom";

import {setSession, getSession, clearSession} from "../../reducer/userSession";

import {
    alteraSenhaUsuario,
    efetuaLogin,
    efetuaLoginProvId,
    renovaLogin,
    solicResetSenha
} from "../../lib/LoginAPI";

import {inicializaDataProvider} from "../../../reducer/dataProvider";

import Store from "../../../store";

import {inicializaSuite, isDebugLigado, setEmpresaInicial} from "../../reducer/suite";
import {lazyRetry, notificacaoErroValidForm, useQueryString, useStateRef} from "../../lib/iy2b-react";
import {useSetupConfig} from "../../lib/ConfigManager";
import {campoInformado} from "../../lib/iy2b-javascript";
import AlteraSenhaPopUp from "../../componentes/AlteraSenhaPopUp";
import PopUpTermosDeUso from "../../../pages/TermosDeUso/popUpTermosDeUso";

import {auth, provedoresIdentidadeDisponiveis, signInWithGoogle, signInWithIdProvider} from "../../lib/firebase";

import BotaoProvedorIdentidade from "../../componentes/provedoresIdentidade/botaoProvedorIdentidade";
import {setDebugLigado} from "../../lib/RequestAPI";
import {CUSTOM} from "../../assets/iconografia";
import {notificacao} from "../../lib/Notificacao";
import LoginLoadingIndicator from "../../componentes/LoadingIndicator/LoginLoadingIndicator";

const { Content } = Layout;

const Login = props => {

    useSetupConfig({
        appConfig: props.appConfig,
        store: Store
    });

    const history = useHistory();

    const [user, loading, error] = useAuthState(auth);

    const [frmLogin] = Form.useForm();

    const qs = useQueryString();

    const [noLogin] = useState(qs.get("email"));

    const [exibeTrocaSenha, setExibeTrocaSenha] = useState(false);
    const [trocaSenhaSession, setTrocaSenhaSession] = useState(null);

    const [provedoresId, setProvedoresId] = useState([]);

    const [exibeOrientacoesPid, setExibeOrientacoesPid] = useState(false);

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        console.log("useEffect inicializa");

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1);

        inicializa();

        if(noLogin === null) {

            props.getSession().then(userSession => {

                if(userSession.conectado === true) {

                    reLogin(userSession.loginHash, userSession.cdProvedorIdentidade);

                }

            });

        }

        return () => {

            console.log("final useEffect inicializa");

        };

    }, []);

    const inicializa = () => {

        setProvedoresId(provedoresIdentidadeDisponiveis.map(item => {

            const botao =
                    <BotaoProvedorIdentidade
                        onClickVincular={btnLoginWithProviderId}
                        block={true}
                        cdProvedorIdentidade={item.cdProvedorIdentidade}
                        jaVinculado={false}
                        labelLogin={true}
                    />
            ;

            return {
                ...item,
                botao: botao
            }

        }));

    }

    useEffect(() => {

        if(campoInformado(user)) {

            const {uid} = user;

            processaReLoginProvedorId(uid);

        }

    }, [user, loading, error]);

    useEffect(() => {

        if(noLogin !== qs.get("email")) {

            frmLogin.setFieldsValue({
                usuario: qs.get("email")
            });

        }

    }, [qs.get("email")]);

    const processLogin = (userSession) => {

        if(userSession.flgAlterSenha === "L") {

            setTrocaSenhaSession (userSession);

            props.clearSession().then(data => {

                setExibeTrocaSenha (true);

            });

        } else {

            setTrocaSenhaSession (null);

            setExibeTrocaSenha (false);

            props.setSession(userSession).then(data => {

                props.inicializaSuite(userSession.cdPessoaEmpAtual).then(suite => {

                    props.inicializaDataProvider().then(dataProvider => {

                        const empAtual = suite.listaEmp.filter(item => item.pessoaEmp.cdPessoa === data.cdPessoaEmpAtual);

                        if(empAtual.length > 0) {

                            console.log("setEmpresaInicial no Login", empAtual[0]);

                            props.setEmpresaInicial(empAtual[0], userSession);

                        } else {

                            console.log(data, suite, dataProvider);

                        }

                    }, (error) => {

                        if(error.response) {
                            console.log(error.response.data);
                        } else {
                            console.log(error);
                        }

                    });

                }, (error) => {

                    if(error.response) {

                        console.log(error.response.data);

                    } else {

                        props.clearSession().then(data => {

                            /*
                            Modal.error({
                                title: 'Erro durante o login no sistema',
                                content: error
                            });
                            */

                            if(error.data.descricao) {

                                alert(error.data.descricao);

                            }

                            alert("Estamos enfrentando problemas técnicos. Por favor tente novamente mais tarde !");

                        });

                    }

                });

            });

        }

    };

    const onLogout = async () => {

        try {

            await props.clearSession();

            history.push('/login');

            return true;

        } catch (error) {

            throw error;

        }

    };

    const reLogin = async (hash, cdProvedorIdentidade) => {

        const resultado = await renovaLogin(hash, cdProvedorIdentidade);

        if(resultado.erro === true) {

            Modal.error({
                title: 'Erro no login',
                content: resultado.descricao,
            });

            await onLogout();

        } else {

            if(resultado.id === "perfilLogin") {

                processLogin( resultado.modeloPadrao );

            } else {

                console.log(resultado);

            }

        }

    }


    const onFinish = async values => {

        const suite = await props.isDebugLigado();

        console.log("isDebugLigado", suite.debugHabilitado);

        setDebugLigado(suite.debugHabilitado);

        const resultado = await efetuaLogin(values.usuario.trim(), values.senha.trim(), suite.debugHabilitado);

        if(resultado.erro === true) {

            Modal.error({
                title: 'Erro no login',
                content: resultado.descricao,
            });

        } else {

            if(resultado.id === "perfilLogin") {

                processLogin( resultado.modeloPadrao );

            } else {

                console.log(resultado);

            }

        }

    };

    const onFinishFailed = errorInfo => {
        notificacaoErroValidForm(errorInfo);
    };

    const esqueciASenha = async () => {

        try {

            const values = await frmLogin.validateFields(['usuario']);

            try {

                const resultado = await solicResetSenha(values.usuario.trim());

                if(resultado.erro === true) {

                    notificacao.erro({message:"Atenção !", description: resultado.descricao})

                } else {

                    if(resultado.id === "sucesso") {

                        notificacao.sucesso({
                            duration: 10,
                            message: "Sucesso !",
                            description: resultado.mensagem
                        })

                    } else if (resultado["class"] === "falhaDeValidacao") {

                        notificacao.erro({message:"Atenção !", description: resultado.descricao})

                    } else {

                        console.log(resultado);

                    }

                }

            } catch (error) {

                console.log(error);

            }

        } catch (error) {

            notificacaoErroValidForm(error);

            return ;

        }

    }

    const onTrocaSenha = async (resultado) => {

        setExibeTrocaSenha(false);

        try {

            const resultadoServico = await alteraSenhaUsuario(trocaSenhaSession, resultado.novaSenha);

            if(resultadoServico.id === "senhaAlterada") {

                notificacao.sucesso({
                    message: "Sucesso !",
                    description: "Senha alterada com sucesso !!"
                })

                await onFinish ({
                    usuario: trocaSenhaSession.noLogin,
                    senha: resultado.novaSenha
                });

            }

        } catch (error) {

            console.log(error);

        }

    }

    // console.log(props.appConfig);

    const processaReLoginProvedorId = async (chUId) => {

        /// dois mecanismos de relogin não da certo ......, pelo menos por hora ......

        /// const vinculados = await pesquisarProvedorIdentidadeUsuario({chUId, ws:true});

        /// console.log(vinculados);

    }

    const processaLoginProvId = async (cdProvedorIdentidade, chUId, noEmail) => {

        const suite = await props.isDebugLigado();

        console.log("isDebugLigado", suite.debugHabilitado);

        setDebugLigado(suite.debugHabilitado);

        const resultado = await efetuaLoginProvId(cdProvedorIdentidade, chUId, noEmail, suite.debugHabilitado);

        if(resultado.erro === true) {

            Modal.error({
                title: 'Erro no login',
                content: resultado.descricao,
            });

            if (resultado.id === "loginUsuario-07") {
                setExibeOrientacoesPid(true);
            }

        } else {

            if(resultado.id === "perfilLogin") {

                processLogin( resultado.modeloPadrao );

                return true;

            } else {

                console.log(resultado);

            }

        }

        return false;

    }

    const btnLoginWithProviderId = async (params) => {

        try {

            const user = await signInWithIdProvider(params.cdProvedorIdentidade);

            if(user !== null) {

                await processaLoginProvId(params.cdProvedorIdentidade, user.uid, user.email);

                return true;

            } else {

                notificacao.erro({message:"Opps!!!", description:"Não foi possível confirmar o login com a conta do selecionada"});

                return false;

            }

        } catch (e) {

            console.error(e);

            return false;

        }

    }

    const provedoresDeIdentidade =
        <div className={"box-login-provedores"}>
            <Row gutter={[8, 16]}>
                {provedoresId.map(item => {
                    return (
                        <Col xs={24} key={"col-" + item.cdProvedorIdentidade} style={{marginTop: 6}}>
                            {item.botao}
                        </Col>
                    );
                })}
            </Row>
        </div>
    ;

    ////

    const [exibeTermosDeUso, setExibeTermosDeUso] = useState(false);

    const btnVerTermosUso = () => {
        setExibeTermosDeUso(true);
    }

    const versaoBuild = props.appConfig.txConfig + ", " + props.appConfig.versao;


    return (
        <div className="login">
            <Layout>
                <LoginLoadingIndicator />
                <AlteraSenhaPopUp
                    exigeSenhaAtual={false}
                    exibe={exibeTrocaSenha}
                    onCancel={() => setExibeTrocaSenha(false)}
                    onTrocaSenha={onTrocaSenha}
                />
                <Content>
                    <Row gutter={[8,16]} className={"full-height"}>
                        <Col xs={24} md={12} className={"col-login"}>
                            <div className={"box-login"}>
                                <Form form={frmLogin} id={"frmLogin"}
                                      layout={"vertical"}
                                      onFinish={onFinish}
                                      onFinishFailed={onFinishFailed}
                                >
                                    <Row gutter={[8,16]}>
                                        <Col xs={24}>
                                            <div id={"fazerLogin"}>Fazer Login</div>
                                        </Col>
                                    </Row>
                                    <Row gutter={[8,16]}>
                                        <Col xs={24}>
                                            <div id={"dicaLogin"}>Entre na plataforma através da sua conta social ou digite seu email e senha.</div>
                                        </Col>
                                    </Row>
                                    <Row gutter={[8,16]}>
                                        <Col span={24}>
                                            {provedoresDeIdentidade}
                                        </Col>
                                    </Row>
                                    <Row gutter={[8,16]}>
                                        <Col span={24}>
                                            <div id={"separadorOU"}>
                                                <div className={"antes"}></div>
                                                <div className={"label"}>ou</div>
                                                <div className={"depois"}></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={[8,16]}>
                                        <Col xs={24}>
                                            <Row gutter={[8, 16]}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="usuario"
                                                        rules={[{ required: true, message: 'Por favor, informe o seu email!' }, {type:"email", message: 'Por favor, digite um email válido !' } ]}
                                                        initialValue={noLogin}
                                                    >
                                                        <Input placeholder={"Informe o seu email"} allowClear maxLength={60}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 16]}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name="senha"
                                                        rules={[{ required: true, message: 'Por favor, informe a sua senha!' }]}
                                                    >
                                                        <Input.Password placeholder={"Informe a sua senha"} allowClear />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 16]}
                                                style={{
                                                    alignItems: "center"
                                                }}
                                            >
                                                <Col offset={12} span={12}>
                                                    <Popconfirm
                                                        title="Confirma o inicio do processo de recuperação da sua senha？"
                                                        okText="Sim, re-iniciar a minha senha !"
                                                        cancelText="Não"
                                                        onConfirm={esqueciASenha}
                                                        icon={CUSTOM.QUESTION_CIRCLE_ICON_RED}
                                                    >
                                                        <Button type="link" block className={"esqueceuASenha"}>
                                                            Esqueceu a senha ?
                                                        </Button>
                                                    </Popconfirm>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 16]}>
                                                <Col span={24}>
                                                    <Button className="botaoLogin" type="primary" htmlType="submit" block>
                                                        Entrar
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row gutter={[8,16]}>
                                        <Col span={24}>
                                            <div className={"termosUso"}>
                                                Ao continuar você concorda com os <a onClick={btnVerTermosUso} >termos de uso</a>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                        <Col xs={24} md={12} className={"col-lateral-login"}>
                            <div className={"box-lateral-login"}>
                                <div className={"box-lateral-login-logo"}></div>
                                <div className={"box-lateral-login-texto"}>
                                    Quer saber mais sobre a Ethimo ?<br/>
                                    Acesse nosso site
                                    <p>
                                        <a href="https://ethimo.com.br" target="_blank">ethimo.com.br</a>
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[8,16]}>
                        <Col span={24}>
                            <div className={"versaoPlataforma"}>
                                <Tooltip title={versaoBuild} placement="left" >
                                    {CUSTOM.QUESTION_CIRCLE_ICON}
                                </Tooltip>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Layout>
            <PopUpTermosDeUso
                exibePopUp={ exibeTermosDeUso }
                onRequestClose={() => { setExibeTermosDeUso(false); }}
            />
            <Drawer
                title="Orientações para vincular provedor de identidade"
                closable={true}
                onClose={() => setExibeOrientacoesPid(false)}
                open={exibeOrientacoesPid}
                className={"drawer-orientacoes-prov-id"}
            >
                <div>
                    <div className={"orientacoes-prov-id"}>
                        <ol>
                            <li>
                                Acessar a plataforma utilizando o seu email e a sua senha de acesso
                            </li>
                            <li>
                                Utilizar a tela "Meu perfil" para vincular o seu acesso aos provedores de identidade disponíveis no momento.
                                <ul>
                                    <li>
                                        A tela "Meu perfil" pode ser encontrada no menu acessível através da sua foto, na parte superior, lado direito da tela.
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                    <div>
                        <Button type={"primary"} onClick={() => setExibeOrientacoesPid(false)}>Entendi</Button>
                    </div>
                </div>
            </Drawer>
        </div>
    );

};

const mapStateToProps = function( {appConfig, userSession} ) {

    return {
        appConfig: appConfig,
        userSession: userSession
    };

};

const mapDispatchToProps = dispatch => {
    return {
        isDebugLigado: () => {
            return dispatch( isDebugLigado() );
        },
        getSession: () => {
            return dispatch( getSession() );
        },
        setSession: (userSession) => {
            return dispatch( setSession(userSession) );
        },
        clearSession: () => {
            return dispatch(clearSession());
        },
        inicializaDataProvider: () => {
            return dispatch( inicializaDataProvider() );
        },
        inicializaSuite: (cdPessoaEmpAtual) => {
            return dispatch( inicializaSuite(cdPessoaEmpAtual) );
        },
        setEmpresaInicial: (empresa, userSession) => {
            return dispatch( setEmpresaInicial(empresa, userSession) );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

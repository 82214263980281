import '../../assets/css/tema.scss';
import './style.scss';

import React, {useState, useEffect, useRef} from 'react';
import {ProForm, ProFormText} from '@ant-design/pro-components' ;
import {campoInformadoString, pesquisaCEP} from "../../lib/iy2b-javascript";
import {Col, Row} from "antd";
import IY2BProFormMasked from "../IY2BProFormMasked";
import IY2BHiddenRow from "../IY2BHiddenRow";
import IY2BProFormHidden from "../IY2BProFormHidden";
import CheckGroup from "../proform/CheckGroup/checkGroup";

const FormEnderecoEmpresa = (props) => {

    const inputRefNrCEP = useRef();
    const inputRefTxNumero = useRef();
    const [infoCep, setInfoCep] = useState(null);
    const inputRefTelefone = useRef();

    const [protegeTela, setProtegeTela] = useState(false);

    const [cepUnico, setCepUnico] = useState(false);

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        if(props.api) {
            props.api.current = {
                onValuesChange: onValuesChange,
                setFocus: (field) => {
                    if(field === "CEP") {
                        setTimeout(
                            () => {
                                inputRefNrCEP.current.focus();
                            },
                            250
                        );
                    }
                },
                limpaCEP: () => {
                    props.formRef.current.setFieldsValue({
                        nrCEP: null
                    });
                    limpaCEP();
                },
                preencherCEP: async (nrCEP) => {
                    props.formRef.current.setFieldsValue({
                        nrCEP: nrCEP
                    });
                    return await preencherCEP(nrCEP);
                },
                setFieldsValue: (values) => {
                    props.formRef.current.setFieldsValue(values);
                },
                setProtegeTela: (value) => {
                    setProtegeTela(value);
                },
                focusCEP: () => {
                    inputRefNrCEP.current.focus();
                }
            };
        }

        return () => {

            console.log("final useEffect inicializa FormEndereco");

        };

    }, []);

    const onValuesChange = (changed, all) => {

        console.log("onValuesChange", changed, all);

        if(changed.hasOwnProperty("nrCEP")) {

            if(inputRefNrCEP.current.rawValue().length !== 8) {
                limpaCEP();
            }

        }

    }

    const limpaCEP = () => {

        setInfoCep(null);

        setCepUnico(false);

        const newValue = {
            noLograd: "",
            noCidade: "",
            noBairro: "",
            txNumero: "",
            txCompl: ""
        };

        props.formRef.current.setFieldsValue(newValue);

    }

    const preencherCEP = async (nrCEP) => {

        try {

            const resultado = await pesquisaCEP(nrCEP);

            setInfoCep(resultado);

            const newValue = {
                noLograd: resultado.logradouro,
                noCidade: resultado.localidade + " / " + resultado.uf,
                noBairro: resultado.bairro
            };

            if(campoInformadoString(resultado.logradouro) === false) {
                setCepUnico(true);
                const ffValues = props.formRef.current.getFieldsValue();
                newValue.noLograd = ffValues.noLograd;
                newValue.noBairro = ffValues.noBairro;
            } else {
                setCepUnico(false);
            }

            props.formRef.current.setFieldsValue(newValue);

            inputRefTxNumero.current.focus();

            return true;

        } catch (e) {

            limpaCEP();

            return false;

        }

    }

    const cepPatterns = [{
        pattern: "00000-000",
        length: 8, // sem mascara
        validator: async (value) => {
            const resultado = await preencherCEP(value);
            if(resultado === true) {
                return {valido:true};
            } else {
                return {valido:false, message:"CEP informado não encontrado nos correios!", clearValue: true};
            }
        }
    }];

    const telFixoPatterns = [{
        pattern: "(00) 0000-0000",
        length: 10, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }, {
        pattern: "(00) 0-0000-0000",
        length: 11, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }]


    return (
        <div className={"form-endereco"}>
            <ProForm.Group>
                <Row gutter={[8, 16]}>
                    <Col xs={{span:10}} md={{span:8}} lg={{span:4}}>
                        <IY2BProFormMasked
                            width={"md"}
                            label={"CEP"}
                            name="nrCEP"
                            tooltip = "CEP do endereço"
                            rules={[{ required: true, message: 'Por favor, informe o CEP!' },{
                                validator: async (rule, value) => {
                                    if(inputRefNrCEP) {
                                        const result = await inputRefNrCEP.current.validate();
                                        if(result.valido === false) throw new Error(result.message);
                                    }
                                }
                            }]}
                            api={inputRefNrCEP}
                            patterns={cepPatterns}
                            disabled={protegeTela}
                        />
                    </Col>
                    <Col xs={{span:24}} md={{span:12}} lg={{span:10}}>
                        <ProFormText
                            width={"xl"}
                            name = "noLograd"
                            label = "Logradouro"
                            tooltip = "Nome do logradouro"
                            placeholder = "Nome do logradouro"
                            disabled = {(cepUnico === false) || protegeTela}
                            rules={[
                                {required: (cepUnico === true), message:"Informe o nome do logradouro"},
                            ]}
                        />
                    </Col>
                    <Col xs={{span:10}} md={{span:10}} lg={{span:4}}>
                        <ProFormText
                            width={"md"}
                            name = "txNumero"
                            label = "Número"
                            tooltip = "Número do imóvel"
                            placeholder={"Digite o número do imóvel"}
                            rules={[ {required: true, message:"Informe o número do imóvel"},
                                {message:"No máximo 20 caracteres", max:20}
                            ]}
                            fieldProps={{
                                ref: inputRefTxNumero
                            }}
                            disabled={protegeTela}
                        />
                    </Col>
                    <Col xs={{span:14}} md={{span:14}} lg={{span:6}}>
                        <ProFormText
                            width={"md"}
                            name = "txCompl"
                            label = "Complemento"
                            tooltip = "Existe complemento no endereço ?"
                            placeholder={"Andar, conjunto, sala, apto, etc... "}
                            rules={[ {message:"No máximo 30 caracteres", max:30}]}
                            disabled={protegeTela}
                        />
                    </Col>
                </Row>
            </ProForm.Group>
            <ProForm.Group>
                <Row gutter={[8, 16]}>
                    <Col xs={{span:24}} md={{span:12}} lg={{span:8}}>
                        <ProFormText
                            width={"xl"}
                            name = "noBairro"
                            label = "Bairro"
                            tooltip = "Nome do bairro"
                            placeholder = "Nome do bairro"
                            disabled = {(cepUnico === false) || protegeTela}
                            rules={[
                                {required: (cepUnico === true), message:"Informe o nome do bairro"},
                            ]}
                        />
                    </Col>
                    <Col xs={{span:24}} md={{span:12}} lg={{span:8}}>
                        <ProFormText
                            width={"xl"}
                            name = "noCidade"
                            label = "Cidade / UF"
                            tooltip = "Nome da cidade / UF"
                            placeholder = "Nome da cidade / UF"
                            disabled
                        />
                    </Col>
                    <Col xs={{span:24}} md={{span:12}} lg={{span:8}}>
                        <IY2BProFormMasked
                            width={"xl"}
                            label={"Telefone principal"}
                            name="nrTelefone"
                            tooltip = "Nro. telefone principal da empresa"
                            rules={[{
                                validator: async (rule, value) => {
                                    if(inputRefTelefone) {
                                        const result = await inputRefTelefone.current.validate();
                                        if(result.valido === false) throw new Error(result.message);
                                    }
                                    return true;
                                }
                            }]}
                            api={inputRefTelefone}
                            patterns={telFixoPatterns}
                            disabled={protegeTela}
                        />
                    </Col>
                </Row>
            </ProForm.Group>
            <ProForm.Group>
                <Row gutter={[8, 16]}>
                    <Col xs={{span:24}} md={{span:8}} lg={{span:7}}>
                        <ProFormText
                            width={"xl"}
                            name = "nrInsEstEnd"
                            label = "Inscrição estadual"
                            tooltip = "Inscrição estadual da empresa"
                            placeholder={"Digite a inscrição estadual da empresa "}
                            rules={[ {message:"No máximo 14 caracteres", max:14}]}
                            disabled={protegeTela}
                        />
                    </Col>
                    <Col xs={{span:24}} md={{span:8}} lg={{span:7}}>
                        <ProFormText
                            width={"xl"}
                            name = "nrInsMunicipEnd"
                            label = "Inscrição municipal"
                            tooltip = "Inscrição municipal da empresa. Necessário para pesquisar notas de serviço"
                            placeholder={"Digite a inscrição municipal da empresa "}
                            rules={[ {message:"No máximo 15 caracteres", max:15}]}
                            disabled={protegeTela}
                        />
                    </Col>
                    <Col xs={{span:24}} md={{span:8}} lg={{span:10}}>
                        <ProFormText
                            width={"xl"}
                            name = "noEmailNF_E"
                            label = "E-Mail recebimento NFe"
                            tooltip = "Infome o email para recebimento da NFe nesta empresa"
                            placeholder={"Digite o email para recebimento da NFe nesta empresa"}
                            rules={[
                                {message:"Informe um email válido", type:"email"},
                                {message:"No máximo 60 caracteres", max:60}
                            ]}
                            disabled={protegeTela}
                        />
                    </Col>
                </Row>
            </ProForm.Group>
            <ProForm.Group>
                <Row gutter={[8, 16]}>
                </Row>
            </ProForm.Group>
            <IY2BHiddenRow>
                <IY2BProFormHidden name={"nrSeqEnd"}/>
                <IY2BProFormHidden name={"tpEndereco"}/>
                <IY2BProFormHidden name={"cdCidade"}/>
                <IY2BProFormHidden name={"noContato"}/>
                <IY2BProFormHidden name={"dtCad"}/>
                <IY2BProFormHidden name={"cdRegiao"}/>
                <IY2BProFormHidden name={"cdTipoCalcImposto"}/>
                <IY2BProFormHidden name={"dtInicValid"}/>
                <IY2BProFormHidden name={"noEmail"}/>
            </IY2BHiddenRow>
        </div>
    );

}

export default FormEnderecoEmpresa;

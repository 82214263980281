//v5 import "antd/dist/antd.css";
import "./style.scss";

import {usePromiseTracker} from "react-promise-tracker";
import React from "react";
import {Circles} from "react-loader-spinner";

const PesquisaWSLoadingIndicator = ( props ) => {

    const { promiseInProgress } = usePromiseTracker( {area: props.area});

//

    return (
        promiseInProgress &&
        <div className="pesquisaWSLoadingPosition">
            <div className="pesquisaWSBackdropLoading"
            >
                <div
                    style={{
                        display: "block",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: "14px",
                        left: "14px",
                    }}
                >
                    <Circles color="#00C48C" height="36" width="36"/>
                </div>
            </div>
        </div>
    );

}

export default PesquisaWSLoadingIndicator;

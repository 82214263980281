import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

export const useDecodeWidth = () => {

    const breakpoint = useBreakpoint(true);

    const SCREEN_SIZE = {
        SM: breakpoint.sm,
        MD: breakpoint.md,
        LG: breakpoint.lg,
        XL: breakpoint.xl,
        XXL: breakpoint.xxl,
    };

    return {
        get: (config) => {
            if(config.hasOwnProperty("xxl") === true) {
                if(SCREEN_SIZE.XXL === true) return config["xxl"];
            }
            if(config.hasOwnProperty("xl") === true) {
                if(SCREEN_SIZE.XL === true) return config["xl"];
            }
            if(config.hasOwnProperty("lg") === true) {
                if(SCREEN_SIZE.LG === true) return config["lg"];
            }
            if(config.hasOwnProperty("md") === true) {
                if(SCREEN_SIZE.MD === true) return config["md"];
            }
            if(config.hasOwnProperty("sm") === true) {
                if(SCREEN_SIZE.SM === true) return config["sm"];
            }
            return config["default"];
        }
    };
}

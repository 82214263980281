//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-card/dist/card.css';
import '../../framework/assets/css/tema.scss';
import "./style.scss";

import React, {useRef} from 'react';
import {Button, Col, notification, Row, Space} from "antd";
import {notificacaoErroRequest, useGridWidth, useWindowSize} from "../../framework/lib/iy2b-react";
import IY2BReactModal from "../../framework/componentes/IY2BReactModal";
import {campoInformado, ObjectUtils} from "../../framework/lib/iy2b-javascript";

import {ProForm, ProFormText, ProFormTextArea} from "@ant-design/pro-components";
import IY2BProFormMasked from "../../framework/componentes/IY2BProFormMasked";
import {postWS, postWSWebmakers} from "../../framework/lib/RequestAPI";
import {useHistory} from "react-router-dom";
import {notificacao} from "../../framework/lib/Notificacao";



const PopUpMaisInfoPlano = ( props ) => {

    const { exibePopUp, onRequestClose, plano, cdTransacao=0 } = props;

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 10 : 30;
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = 30;

    const modalRefApi = useRef();
    const formRef = useRef();
    const inputRefTelCel = useRef();

    const noContato = useRef();

    const popupTitle = "Solicitar contratação para o time Ethimo";

    const history = useHistory();

    const validaTela = async (params) => {

        let resultado = true;

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        return resultado;

    };

    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        const attrDaTela = ["txtNoContato", "txtNoEmail", "txtTxTelCelular", "txtNoEmpresa"];

        const registro = {
            ...ObjectUtils.valuesFrom(ffValues, attrDaTela),
            "txtTxMensagem": ffValues.txtNoContato + ", " +
                ffValues.txtNoEmail + ", " +
                ffValues.txtTxTelCelular + ", " +
                plano.noPlano.trim() + ", " +
                (ffValues.txtNoEmpresa||"") + ", " +
                (ffValues.txMensagem||"")
        };

        return registro;

    }

    const doSalvar = async () => {

        await formRef?.current?.validateFields();

        const telaValida = await validaTela();

        if(telaValida === false) return;

        console.log("doSalvar");

        const registro = getRegistroDaTela();

        console.log(registro);

        try {

            const resposta = await postWSWebmakers({
                sistema: "sgr",
                projeto: "mcontatolinkext",
                acaoWebAExecutar: "processarnegocio",
                acaoAExecutar: "enviarMsg"
            }, {
                origem: "PIY2B",
                ...registro
            }, null);

            resposta.mensagens.forEach(msg => {
                console.log(msg);
            });

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

        }

        try {

            const resposta = await postWS({
                sistema: "sgr",
                projeto: "mwizcontratacaobuild",
                acaoWebAExecutar: "processarnegocio",
                acaoAExecutar: "enviarEmailIniciarContratacao"
            }, {
                noContato: registro.txtNoContato,
                noPlano: plano.noPlano.trim(),
                noEmpresa: registro.txtNoEmpresa,
                noEmail: registro.txtNoEmail,
                nrTelCelular: registro.txtTxTelCelular,
            });

            let sucesso = false;

            resposta.mensagens.forEach(msg => {

                if(msg.id === "ok") {

                    sucesso = true;

                } else {

                    console.log(msg);

                }

            });

            if(sucesso === true) {
                notificacao.sucesso({message:"Contato encaminhado", description:"Aguarde o contato do time IY2B sobre a sua contratação !"});
                onRequestClose();
                history.push('/assinar');
            }

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const telCelPatterns = [{
        pattern: "(00) 0000-0000",
        length: 10, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }, {
        pattern: "(00) 0-0000-0000",
        length: 11, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }];

    let detalhesPlano = null;

    if(campoInformado(plano) === true) {

        const fiValues = {

        };

        const colSpanPlanos = {span:24};

        const statusClass = plano.selecionado ? "selecionado" : "";

        detalhesPlano =
            <Row gutter={[8,16]}>
                <Col key={plano.id} {...colSpanPlanos} >
                    <div className={["box-preco", statusClass].toClassName()}>
                        <div>
                            <label className="nome">{plano.noPlano}</label>
                            <label className="formaPagto">{plano.formaPagto}</label>
                            <label className="total">Valor total {plano.total}</label>
                            <label className="descricao">{plano.descrPlano}</label>
                            <div>
                                <ProForm
                                    formRef = { formRef }
                                    isKeyPressSubmit = {true}
                                    initialValues={fiValues}
                                    onFinish = { async ( ) => {
                                        await doSalvar();
                                    }}
                                    submitter={{
                                        // Completely customize the entire area
                                        render : ( props , doms ) => {
                                            return null;
                                        }
                                    }}
                                    onValuesChange={async (changeValues, allValues) => {

                                    }}
                                    onFieldsChange = {(changedFields) => {
                                    }}
                                >
                                    <div className={"pro-form-group-spacer"}>
                                        <ProForm.Group>
                                            <Row gutter={[8,16]}>
                                                <Col xs={{span:24}}>
                                                    <ProFormText
                                                        width={"xl"}
                                                        name = "txtNoContato"
                                                        label = "Nome"
                                                        tooltip = "Informe o seu nome"
                                                        placeholder = "Nome do interessado"
                                                        rules={[
                                                            {required: true, message:"Informe o seu nome"},
                                                            {message:"No máximo 60 caracteres", max:60}
                                                        ]}
                                                        fieldProps={{
                                                            ref: noContato
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </ProForm.Group>
                                        <ProForm.Group>
                                            <Row gutter={[8,16]}>
                                                <Col xs={{span:16}}>
                                                    <ProFormText
                                                        width={"lg"}
                                                        name = "txtNoEmail"
                                                        label = "E-Mail de contato"
                                                        tooltip = "Infome o seu email do contato"
                                                        placeholder={"Digite o email de contato"}
                                                        rules={[
                                                            {required: true, message:"Informe o email de contato"},
                                                            {message:"Informe um email válido", type:"email"},
                                                            {message:"No máximo 60 caracteres", max:60}
                                                        ]}
                                                    />
                                                </Col>
                                                <Col xs={{span:8}}>
                                                    <IY2BProFormMasked
                                                        width={"sm"}
                                                        label={"Telefone celular"}
                                                        name="txtTxTelCelular"
                                                        tooltip = "Nro. telefone celular"
                                                        rules={[{ required: true, message: 'Por favor, informe o nro do seu telefone celular !' }, {
                                                            validator: async (rule, value) => {
                                                                if(inputRefTelCel) {
                                                                    const result = await inputRefTelCel.current.validate();
                                                                    if(result.valido === false) throw new Error(result.message);
                                                                }
                                                                return true;
                                                            }
                                                        }]}
                                                        api={inputRefTelCel}
                                                        patterns={telCelPatterns}
                                                    />
                                                </Col>
                                            </Row>
                                        </ProForm.Group>
                                        <ProForm.Group>
                                            <Row gutter={[8,16]}>
                                                <Col xs={{span:24}}>
                                                    <ProFormText
                                                        width={"xl"}
                                                        name = "txtNoEmpresa"
                                                        label = "Nome da empresa"
                                                        tooltip = "Informe o nome da empresa"
                                                        placeholder = "Nome da empresa"
                                                        rules={[
                                                            {message:"No máximo 60 caracteres", max:60}
                                                        ]}
                                                    />
                                                </Col>
                                            </Row>
                                        </ProForm.Group>
                                        <ProForm.Group>
                                            <Row gutter={[8,16]}>
                                                <Col xs={{span:24}}>
                                                        <ProFormTextArea
                                                            width={"xl"}
                                                            name="txMensagem"
                                                            label="Mensagem"
                                                            placeholder="Faça comentários sobre a sua necessidade"
                                                            tooltip={"Comente a sua necessidade"}
                                                            rules={[
                                                                {type:"string", message:"No máximo 150 caracteres", max: 150}
                                                            ]}
                                                            fieldProps={{
                                                                showCount: true,
                                                            }}
                                                        />
                                                </Col>
                                            </Row>
                                        </ProForm.Group>
                                    </div>
                                </ProForm>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        ;

    }

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={24}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"mais-detalhes-plano-contratovenda"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-mais-detalhes-plano-contratovenda"}>
                    {detalhesPlano}
                </div>
            }
            actions={
                <Space style={{
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <Space>
                        &nbsp;
                        <Button type={"primary"} className={"salvar"} onClick={async () => await doSalvar()}>
                            Enviar solicitação
                        </Button>
                    </Space>
                </Space>
            }
        />
    );

}

export default PopUpMaisInfoPlano;

import {postJSON} from "../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../pages/Workspace/workspace";
import moment from "moment";
import {campoInformado} from "../lib/iy2b-javascript";

const GET_PERFIL_ACESSO_MENU = 'smk/wsDataProvider/GET_PERFIL_ACESSO_MENU';
const POST_NOTIFICATION = 'smk/wsDataProvider/POST_NOTIFICATION';
const DELETE_NOTIFICATION = 'smk/wsDataProvider/DELETE_NOTIFICATION';
const CLEAR_NOTIFICATIONS = 'smk/wsDataProvider/CLEAR_NOTIFICATIONS';


const initialState = {
    cachePerfilAcessoMenu: { },
    notificacoes: []
};


export default function wsDataProvider (state = initialState, action) {

    let newState = {...state};

    if(action.payload === undefined) {
        return newState;
    }

    switch (action.type) {

        case GET_PERFIL_ACESSO_MENU:

            const cache = newState.cachePerfilAcessoMenu;

            if(cache.hasOwnProperty(action.payload.cdPessoaEmp) === false) {
                cache[action.payload.cdPessoaEmp] = { };
            }

            cache[action.payload.cdPessoaEmp][action.payload.cdPlanoContrVenda] = action.payload.dpPerfilAcessoMenu;

            return newState;

        case POST_NOTIFICATION:
            if(campoInformado(action.payload.key) === false) {
                action.payload.key = newState.notificacoes.length + 1;
            }
            newState.notificacoes.unshift(action.payload);
            return newState;

        case DELETE_NOTIFICATION:
            const toDelete = newState.notificacoes.filter(item => item.key === action.payload.key);
            console.log("DELETE_NOTIFICATION", toDelete);
            if(toDelete.isEmpty() === false) {
                toDelete.forEach(item => newState.notificacoes.removeItem(item));
            }
            return newState;

        case CLEAR_NOTIFICATIONS:
            newState.notificacoes.clear();
            return newState;
        default:
            return state;

    }

};

export function clearNotifications() {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {

            ////
            //
            //
            //

            dispatch({
                type: CLEAR_NOTIFICATIONS,
                payload: null
            });

            resolve(true);

        });
    };

}

export function deleteNotification(notificacao) {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {

            ////
            //
            //
            //


            dispatch({
                type: DELETE_NOTIFICATION,
                payload: notificacao
            });

            resolve(true);

        });
    };

}

export function postNotification(notificacao) {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {

            ////
            //
            //
            //


            dispatch({
                type: POST_NOTIFICATION,
                payload: notificacao
            });

            resolve(true);

        });
    };

}

export function getPerfilAcessoMenu(planoContrVenda) {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {

            const cache = getState().wsDataProvider.cachePerfilAcessoMenu;

            if(cache.hasOwnProperty(planoContrVenda.cdPessoaEmpPCtrV)) {

                const cacheEmp = cache[planoContrVenda.cdPessoaEmpPCtrV];

                if(cacheEmp.hasOwnProperty(planoContrVenda.cdPlanoContrVenda)) {

                    const dpPerfilAcessoMenu = cacheEmp[planoContrVenda.cdPlanoContrVenda];

                    resolve(dpPerfilAcessoMenu);

                    return;

                }

            }

            postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "smk",
                projeto: "musuariopiy2b",
                rest: true
            }, {
                "acaoAExecutar": "pesquisaPerfilAcessoCtrV",
                cdPessoaEmpPCtrV: planoContrVenda.cdPessoaEmpPCtrV,
                cdPlanoContrVenda: planoContrVenda.cdPlanoContrVenda
            }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultado => {

                resultado.mensagens.forEach(msg => {

                    if(msg.id === "listaPerfilAcessoMenu") {

                        const dpPerfilAcessoMenu = msg.lista;

                        dispatch({
                            type: GET_PERFIL_ACESSO_MENU,
                            payload: {
                                cdPessoaEmp: planoContrVenda.cdPessoaEmpPCtrV,
                                cdPlanoContrVenda: planoContrVenda.cdPlanoContrVenda,
                                dpPerfilAcessoMenu: dpPerfilAcessoMenu
                            }
                        });

                        resolve(dpPerfilAcessoMenu);

                    } else {

                        console.log(msg);

                    }

                });

            }, (error) => {

                reject(error);

            });

        });

    };

}

//v5 import "antd/dist/antd.css";
import '../../../../assets/css/tema.scss';
import './style.scss';

import React, {useState, useEffect, useRef, useImperativeHandle} from 'react';
import {Button, Drawer, List, notification, Space, Tooltip} from "antd";
import {CUSTOM, TRASH_ICON_RED} from "../../../../assets/iconografia";
import {connect} from "react-redux";
import {clearNotifications, deleteNotification} from "../../../../reducer/wsDataProvider";
import {campoInformado} from "../../../../lib/iy2b-javascript";
import {notificacaoErroRequest} from "../../../../lib/iy2b-react";
import {postJSON} from "../../../../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../../Workspace/workspace";

const DrawerNotificacoes = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {};
    });

    const { wsDataProvider } = props;

    useEffect(() => {
        return () => {
        };
    }, []);

    const removeItem = (item) => {
        props.deleteNotification(item).then(resultado => {
            if(wsDataProvider.notificacoes.isEmpty() === true) {
                props.onClose();
            }
        });
    }

    const removeAllItems = () => {
        props.clearNotifications().then(resultado => props.onClose());
    }

    const procurarSolucao = async (notificacao) => {
        try {
            const payload = {
                acaoAExecutar: "procurarSolucao",
                key: notificacao.key
            }
            const resultado = await postJSON({
                acaoWebAExecutar: "processarNegocio",
                sistema: "sgr",
                projeto: "mlognotifapp"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);
            resultado.mensagens.forEach(msg => {
                if(msg.id === "semSolucao") {
                    notification.warning(
                        {message: "Não encontrada", description: "Estamos providenciando a solução desta notificação."}
                    );
                } else if (msg.id === "urlSolucao") {
                    window.open(msg.mensagem, "_blank");
                } else {
                    console.log(msg);
                }

            });
            } catch (e) {

            notificacaoErroRequest(e, 0);
        }

    }

    const renderNotificacao = item => {

        const icon =
            (item.type === "success") ? CUSTOM.CHECK_ICON_GREEN :
                (item.type === "error") ? CUSTOM.TIMES_CIRCLE_ICON_RED :
                    CUSTOM.EXCLAMATION_CIRCLE_ICON_PURPLE
        ;
        const exibeProcurarSolucao = (item.type === "error") ? true : false;
        const dataHora = <div>{item.date.toMoment().format("DD/MM/YYY") + " as " + item.time}</div>;
        const nomeDaTransacao = (campoInformado(item.noTransacao) === true) ? <div>{item.noTransacao}</div> : null;
        const description = <>
            {dataHora}
            {nomeDaTransacao}
        </>;
        const actions = [];
        if(exibeProcurarSolucao === true) {
            actions.push(
                <Tooltip title={"Procurar uma solução para esta notificação na documentação da plataforma"} placement={"leftBottom"}>
                    <Button type={"text"} className={"botaoSolucao"} icon={CUSTOM.HELP_DUO_ICON} onClick={async () => await procurarSolucao(item)}/>
                </Tooltip>
            );
        }
        actions.push(
            <Tooltip title={"Excluir esta notificação"} placement={"bottom"}>
                <Button type={"text"} className={"botaoExcluir"} icon={TRASH_ICON_RED} onClick={() => removeItem(item)} />
            </Tooltip>
        );
        return (
            <List.Item className={"notificacao"}
                key={item.key}
                actions={actions}
            >
                <List.Item.Meta
                    avatar={icon}
                    title={item.message}
                    description={description}
                />
                <div className={"descricao"}>{item.description}</div>
            </List.Item>
        );
    }

    return (
        <Drawer
            title={"Notificações"}
            placement="right"
            size={"large"}
            onClose={props.onClose}
            open={props.open}
            width={props.width}
            className={"notifications-drawer"}
            extra={
                <Space>
                    <Button type={"primary"} onClick={() => removeAllItems()}>Limpar todas</Button>
                </Space>
            }
        >
            <div className={"scroller"}>
                <List itemLayout="vertical"
                    dataSource={wsDataProvider.notificacoes}
                    renderItem={renderNotificacao}
                >
                </List>
            </div>
        </Drawer>
    );

});

const mapStateToProps = function( { userSession, wsDataProvider } ) {

    return {
        userSession: userSession,
        wsDataProvider: wsDataProvider
    };

};

const mapDispatchToProps = dispatch => {
    return {
        deleteNotification: (notificacao) => {
            return dispatch( deleteNotification(notificacao) );
        },
        clearNotifications: () => {
            return dispatch( clearNotifications() );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps, null,{forwardRef: true})(DrawerNotificacoes);

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';

import '../../framework/assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useRef} from 'react';
import {ProFormText} from "@ant-design/pro-components";
import {Col, Row} from "antd";
import FormEndereco from "../../framework/componentes/FormEndereco";
import {campoInformado, campoInformadoString, cnpjValido, cpfValido} from "../../framework/lib/iy2b-javascript";
import {postJSON, postWS} from "../../framework/lib/RequestAPI";
import {notificacaoErroRequest, useStateRef} from "../../framework/lib/iy2b-react";
import RadioGroup from "../../framework/componentes/proform/RadioGroup/radioGroup";
import {notificacao} from "../../framework/lib/Notificacao";
import IY2BProFormMasked from "../../framework/componentes/IY2BProFormMasked";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";

const StepCadEmpresa = props => {

    const formEnderecoApi = useRef();

    const [_1, setPrimeiraExibicao, refPrimeiraExibicao] = useStateRef(true);
    const [_2, setCnpjJaCadastrado, refCnpjJaCadastrado] = useStateRef(false);

    const forceUpdate = useForceUpdate();

    const inputRefNrCGCCIC = useRef();

    const nrCGCCICPatterns = [{
        pattern: "00.000.000/0000-00",
        length: 14, // sem mascara
        validator: async (value) => {
            if(value.length === 14) {
                if(cnpjValido(value) === false) {
                    return {valido:false, message:"CNPJ informado é inválido !"};
                }
            }
            return {valido: true, message: "ok" };
        }
    }];

    const noFantasiaRef = useRef();
    const noPessoaRef = useRef();

    const { exibeMeiosContato = false } = props;

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        if(props.api) {
            props.api.current = {
                onValuesChange: onValuesChange,
                onPageShow: onPageShow,
                onFinish: onFinish
            };
        }

        if(props.cookies) {

            const cookieCadEmp = props.cookies.get("cadEmp");

            if(campoInformado(cookieCadEmp)) {

                props.formRef.current?.setFieldsValue(cookieCadEmp);

            }

        }

        return () => {

            console.log("final useEffect inicializa StepCadEmpresa");

        };

    }, []);

    const [protegeInfoCertif, setProtegeInfoCertif, refProtegeInfoCertif] = useStateRef(false);

    useEffect(() => {

        console.log("StepCadEmpresa.props.certificado", campoInformado(props.certificado));

        if(campoInformado(props.certificado) === true) {
            const newValue = {
                noRazaoSocial:props.certificado?.noPessoa,
                nrCNPJ: props.certificado?.nrDocto
            };
            props.formRef.current?.setFieldsValue(newValue);
            setProtegeInfoCertif(true);
        } else {
            setProtegeInfoCertif(false);
        }

    }, [props.certificado]);

    const onFinish = async () => {

        if(refCnpjJaCadastrado.current === true) {
            notificacao.erro({message:"Ja é cliente", description:"Este CNPJ ja esta cadastrado como assinante da plataforma. Por favor faça o login para acessar o sistema."})
            return false;
        }

        return true;
    }

    const onPageShow = async () => {

        if(refPrimeiraExibicao.current === true) {

            const { nrCNPJ } = props.formRef.current?.getFieldsValue();

            if(campoInformado(nrCNPJ) === true) {
                const nrCGCCIC = nrCNPJ.retiraCaracteres(".-/");
                pesquisaNrCNPJ(nrCGCCIC, false).then(resultado => console.log(resultado));
            } else {
                inputRefNrCGCCIC.current.focus();
            }

        }

        setPrimeiraExibicao(false);

    }

    const onValuesChange = (changed, all) => {

        formEnderecoApi.current?.onValuesChange(changed, all);

    }

    const pesquisaNrCNPJ = async (nrCGCCIC, pesqCadWS) => {

        try {

            let modeloPessoa = null;
            let modeloCredor = null;
            let modeloEndereco = null;

            let cnpjJaExisteNaEmpresa = false;
            let naoEncontrouDocumentoCPFCNPJ = true;

            let focusOn = "noRazaoSocial";

            const payload = {
                nrCGCCIC,
                "tipoPesq": "emp"
            };

            if(pesqCadWS === true) {
                payload.pesqCadWS = "S";
            }

            const ffValues = {
            };

            let jaExiste = false;

            const resposta = await postWS({
                sistema: "sgr",
                projeto: "mwizcontratacaobuild",
                acaoWebAExecutar: "pesquisar",
                acaoAExecutar: "pesquisaNrCGCCIC"
            }, payload);

            resposta.mensagens.forEach(msg => {
                if(msg.id === "pessoa") {
                    jaExiste = true;
                    notificacao.erro({message:"Ja é cliente", description:"Este CNPJ ja esta cadastrado como assinante da plataforma. Por favor faça o login para acessar o sistema."})
                } else if(msg.id === "modeloPessoa") {
                    modeloPessoa = msg.modeloPadrao;
                    naoEncontrouDocumentoCPFCNPJ = false;
                    ffValues.noFantasia = modeloPessoa.noFantasia;
                    ffValues.noRazaoSocial = modeloPessoa.noPessoa;
                    ffValues.nrInsEst = modeloPessoa.nrInsEst;
                    ffValues.nrInsMunicip = modeloPessoa.nrInsMunicip;
                    focusOn = "noFantasia";
                    setProtegeInfoCertif(true);
                } else if(msg.id === "modeloEndereco") {
                    modeloEndereco = msg.modeloPadrao;
                } else if(msg.id === "modeloCredor") {
                    modeloCredor = msg.modeloPadrao;
                    naoEncontrouDocumentoCPFCNPJ = false;
                } else if(msg.id === "cnpjJaExisteNaEmpresa") {
                    cnpjJaExisteNaEmpresa = true;
                    naoEncontrouDocumentoCPFCNPJ = false;

                } else if(msg.id === "listaPesquisaWS") {

                    msg.lista.forEach(item => {

                        if(item.status === true) {
                            ffValues.noFantasia = item.fantasia.substr(0, 20);
                            if(ffValues.noFantasia === "") {
                                ffValues.noFantasia = item.nome.substr(0, 20);
                            }
                            ffValues.noRazaoSocial = item.nome.substr(0, 60);
                            ffValues.nrCEP = item.endereco.cep.retiraCaracteres(".-/");
                            ffValues.noLogradouro = item.endereco.logradouro.substr(0, 160);
                            ffValues.txNumero = item.endereco.numero.substr(0, 20);
                            ffValues.txComplEnd = item.endereco.complemento.substr(0, 60);
                            ffValues.noBairro = item.endereco.bairro.substr(0, 20);
                            ffValues.noCidade = item.endereco.municipio + " / " + item.endereco.uf;
                            if(exibeMeiosContato === true) {
                                ffValues.nrTelefone = item.telefone.retiraCaracteres(".-/() ");
                                ffValues.noEmailNF_E = item.endereco.email;
                            }
                            focusOn = "noFantasia";
                            naoEncontrouDocumentoCPFCNPJ = false;
                            setProtegeInfoCertif(true);
                        } else {
                            ffValues.noFantasia = null;
                            ffValues.noRazaoSocial = null;
                            ffValues.nrCNPJ = null;
                            notificacao.erro({description:"Pesquisa CNPJ/CPF", message: item.msg});
                        }

                    });

                } else if(msg.id === "falhaValidacao") {
                    notificacao.erro({description:"Pesquisa CNPJ/CPF", message: msg.descricao});
                } else {
                    console.log(msg);
                }
            });

            setCnpjJaCadastrado(jaExiste);

            if(jaExiste === true) {
                return false;
            }

            console.log("modelos", modeloPessoa, modeloEndereco, modeloCredor);

            props.formRef.current?.setFieldsValue( ffValues );

            setTimeout(() => {

                if(focusOn === "noFantasia") {
                    noFantasiaRef.current.focus();
                } else {
                    noPessoaRef.current.focus();
                }

            }, 0);

            forceUpdate();

        } catch (e) {
            console.log(e);
            notificacaoErroRequest(e, 0);
            return false;
        }

        return true;

    }

    console.log("StepCadEmpresa.protegeInfoCertif", protegeInfoCertif, props.certificado);

    return (
        <div className={"containerStepCadEmpresa"}>
            <Row gutter={[8,16]}>
                <Col xs={24} md={8} lg={6}>
                    <IY2BProFormMasked
                        width={"sm"}
                        label={"CNPJ"}
                        name="nrCNPJ"
                        tooltip = "CNPJ da empresa"
                        placeholder = "Digite o CNPJ da empresa"
                        rules={[
                            {required: true, message: 'Informe o CNPJ / CPF'},
                            {
                                validator: async (rule, value) => {
                                    if(inputRefNrCGCCIC) {
                                        const result = await inputRefNrCGCCIC.current.validate();
                                        if(result.valido === false) throw new Error(result.message);
                                    }
                                    if(campoInformadoString(value) === true) {
                                        if(props.formRef.current?.isFieldTouched("nrCNPJ") === true) {
                                            const nrCGCCIC = value.retiraCaracteres(".-/");
                                            const result = await pesquisaNrCNPJ(nrCGCCIC, true);
                                            if(result === true) {
                                                props.formRef.current?.setFields([{
                                                    name: "nrCNPJ",
                                                    touched: false
                                                }]);
                                            }
                                            return (result === true);
                                        } else {
                                            return true;
                                        }
                                    } else {
                                        return false;
                                    }
                                }
                            }
                        ]}
                        api={inputRefNrCGCCIC}
                        patterns={nrCGCCICPatterns}
                        disabled={protegeInfoCertif}
                    />
                </Col>
                <Col xs={16} md={12}>
                    <ProFormText
                        width={"lg"}
                        name = "noRazaoSocial"
                        label = "Razão social"
                        tooltip = "Razão social da empresa"
                        disabled={protegeInfoCertif}
                        rules={[
                            {required: (protegeInfoCertif === false), message: 'Informe a razão social'},
                            {message:"No máximo 60 caracteres", max:60}
                        ]}
                        placeholder = "Digite a razão social da empresa"
                        fieldProps={{
                            ref: noPessoaRef
                        }}
                    />
                </Col>
            </Row>
            <Row gutter={[8,16]}>
                <Col xs={24} md={12} lg={8}>
                        <ProFormText
                            width={"md"}
                            name = "noFantasia"
                            label = "Nome fantasia"
                            tooltip = "Nome fantasia da empresa"
                            placeholder={"Digite o nome fantasia"}
                            rules={[ {required: true, message:"Informe o nome fantasia da sua empresa"}, {message:"No máximo 20 letras", max:20}]}
                            fieldProps={{
                                ref: noFantasiaRef
                            }}
                        />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <RadioGroup
                        width={"sm"}
                        name="flgOptaSimples"
                        label="Empresa optante do Simples ?"
                        tooltip = { "Indique se esta empresa é optante do Simples" }
                        rules={[
                        ]}
                        options={[
                            {
                                label: 'Não',
                                value: 'N',
                            },
                            {
                                label: 'Sim',
                                value: 'S',
                            },
                        ]}
                    />
                </Col>
                <Col xs={24} md={12} lg={8}>
                    <RadioGroup
                        width={"sm"}
                        name="flgMEI"
                        label="Microempreendedor individual (MEI) ?"
                        tooltip = { "Indique se esta empresa é um microempreendedor individual (MEI)" }
                        rules={[
                        ]}
                        options={[
                            {
                                label: 'Não',
                                value: 'N',
                            },
                            {
                                label: 'Sim',
                                value: 'S',
                            },
                        ]}
                    />
                </Col>
            </Row>
            <FormEndereco
                formRef = { props.formRef }
                api = { formEnderecoApi }
                exibeInscricoes = { true }
                exibeMeiosContato = { exibeMeiosContato }
            />
        </div>
    );

}

export default StepCadEmpresa;

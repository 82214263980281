//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-card/dist/card.css';

import '../../framework/assets/css/tema.scss';
import "./style.scss";

import React, {useState, useEffect, useRef} from 'react';

import {
    ProFormUploadDragger,
    StepsForm
} from '@ant-design/pro-components' ;

import {Button, Checkbox, Col, Input, message, notification, Row, Space} from "antd";

import {postWS} from "../../framework/lib/RequestAPI";
import {campoInformado, fileToBase64, formatadorCpfCnpj, topFunction} from "../../framework/lib/iy2b-javascript";
import ListaRequisitos from "../../framework/componentes/ListaRequisitos";
import {FakeEvent} from "../../framework/lib/InputHelpers";
import PopUpTermosDeUso from "../TermosDeUso/popUpTermosDeUso";
import {CUSTOM} from "../../framework/assets/iconografia";
import {notificacao} from "../../framework/lib/Notificacao";

const iconUpload = CUSTOM.UPLOAD_ICON_GREEN_4X;

const StepCertDigital = (props) => {

    // http://servidor:porta/systemakers/SGR/rest/mrecebecertdig/post/processarnegocio/recebecertdig/config/token/endpoint
    // .pfx e .p12

    const refTxSenha = useRef();

    const [nrSeqCD, setNrSeqCD] = useState(0);

    const [txSenha, setTxSenha] = useState(null);

    const [certificado, setCertificado] = useState(null);
    const [infoCertificado, setInfoCertificado] = useState(null);

    const [senhaVerificada, setSenhaVerificada] = useState(false);

    const [aceito, setAceito] = useState(false);

    const [uploadedList, setUploadedList] = useState([ ]);

    const {semAceiteDosTermos = false, atualizarCertDigital = false} = props;

    const [exibeTermosDeUso, setExibeTermosDeUso] = useState(false);

    console.log("props.formRef", props.formRef);

    const listaRequisitos = [
        {label:'Enviar o certificado digital', value:"enviar"},
        {label:'Verificar a senha', value:"validar"}
    ];

    if(semAceiteDosTermos===true) {

    } else {
        listaRequisitos.push({label:'Ler e aceitar os termos', value:"aceite"});
    }

    const [reqsConferidos, setReqsConferidos] = useState([]);

    useEffect(() => {

        if(props.cookies) {

            const cookieCertif = props.cookies.get("certificado");

            if(campoInformado(cookieCertif)) {

                if(campoInformado(cookieCertif.certificado)) {

                    const fakeFile = {name: cookieCertif.certificado.noArqCD};

                    setCertificado(fakeFile);

                    setNrSeqCD(cookieCertif.certificado.nrSeqCD);

                    setInfoCertificado (cookieCertif.certificado);

                    props.onInfoCertificado(cookieCertif.certificado);

                    setSenhaVerificada(true);

                    setTxSenha(cookieCertif.certificado.txSenha);

                }

            }

        }

        return () => {

        };

    }, []);

    const propsUpload = {
        beforeUpload: file => {
            const fileName = file.name.trim().toLowerCase();
            if (fileName.endsWith(".p12") === true) {
                // ok
                return true;
            } else if (fileName.endsWith(".pfx") === true) {
                // ok
                return true;
            } else {
                notificacao.erro({errorId: "certDig", message: "Enviar certificado", description: `${file.name} não é um arquivo de certificado suportado`});
            }
            return false;
        },
        onChange: info => {

            const status = info.file.status;

            if (status === 'done') {
                setUploadedList([]);
            } else if (status === 'error') {

            } else {
                console.log(status);
                setUploadedList(info.fileList);
            }

        },
        maxCount: 1,
        accept: ".pfx,.p12",
        showUploadList: false,
        fileList: uploadedList,
        multiple: false,
        customRequest: async ({
                          file,
                          onError,
                          onSuccess
                      }) => {

            const b64String = await fileToBase64(file);

            const payload = {
                noArquivo: file.name,
                b64File: b64String
            };

            if(nrSeqCD > 0) {
                payload.nrSeqCD = nrSeqCD;
            }

            topFunction();

            postWS({
                sistema: "sgr",
                projeto: "mrecebecertdig",
                acaoWebAExecutar: "processarnegocio",
                acaoAExecutar: "recebeCertDig"
            }, payload, "uploadCertificado")
                .then(( response ) => {
                    onSuccess(response, file);
                    setCertificado(file);
                    response.mensagens.forEach(msg => {
                       if(msg.id === "nrSeqCD") {
                           setNrSeqCD(parseInt(msg.mensagem));
                       }
                    });
                    validaRequisitos(file, false, aceito);
                    notificacao.sucesso({
                        message: 'Sucesso !',
                        description:
                            'Recebemos o seu certificado digital com sucesso!',
                    });
                })
                .catch(onError);
            ;

            return {
                abort: () => {
                    console.log('upload progress is aborted.');
                }
            };
        }
    };

    const enviarNovoCertificado = async () => {

        /// tem q fazer um req WS para apagar o anterior e no volta limpar o certificado
        const payload = {
            nrSeqCD: nrSeqCD
        };

        await postWS({
            sistema: "sgr",
            projeto: "mrecebecertdig",
            acaoWebAExecutar: "processarnegocio",
            acaoAExecutar: "limpaCertDig"
        }, payload);

        setSenhaVerificada(false);

        setCertificado(null);

        setInfoCertificado (null);
        props.onInfoCertificado(null);

        setTxSenha("");

        setUploadedList([certificado]);

        validaRequisitos(null, false, aceito);

    }

    const pesquisaNrDocto = async (flgJuridico, nrCGCCIC) => {

        const payload = {
            nrCGCCIC,
            "tipoPesq": flgJuridico==="F"? "usr" : "emp"
        };

        let jaExiste = false;

        const resposta = await postWS({
            sistema: "sgr",
            projeto: "mwizcontratacaobuild",
            acaoWebAExecutar: "pesquisar",
            acaoAExecutar: "pesquisaNrCGCCIC"
        }, payload);

        resposta.mensagens.forEach((msg) => {

            if(msg.id === "pessoa") {
                jaExiste = true;
                notificacao.erro({errorId: "certDig", duration:8, message:"Ja é cliente", description:"Este " + (flgJuridico==="F"? "CPF" : "CNPJ") + " ja esta cadastrado como assinante da plataforma. Por favor faça o login para acessar o sistema."})
            }

        });

        if(jaExiste === true) {
            onChangeAceite(new FakeEvent());
            await enviarNovoCertificado();
        }

        return true;

    }

    const verificarSenhaCertificado = async () => {

        // LassesLog.pfx=080956

        if(txSenha === null) return;

        const payload = {
            txSenha: txSenha
        };

        if(nrSeqCD > 0) {
            payload.nrSeqCD = nrSeqCD;
        }

        try {

            let pesqDocto = false;
            let flgJuridico = null;
            let nrCGCCIC = null;
            let certificadoInvalido = false;

            const resposta = await postWS({
                sistema: "sgr",
                projeto: "mrecebecertdig",
                acaoWebAExecutar: "processarnegocio",
                acaoAExecutar: "verificarSenhaCertDig"
            }, payload);

            resposta.mensagens.forEach(msg => {
                if(msg.id === "ok") {
                    notificacao.sucesso({
                        message: 'Sucesso !',
                        description: msg.mensagem
                    });
                } else if(msg.id === "info") {
                    msg.modeloPadrao.nrDocto = formatadorCpfCnpj(msg.modeloPadrao.nrCGCCIC);
                    msg.modeloPadrao.txSenha = txSenha;
                    msg.modeloPadrao.nrSeqCD = nrSeqCD;
                    msg.modeloPadrao.noArqCD = certificado.name;
                    setInfoCertificado(msg.modeloPadrao);
                    props.onInfoCertificado(msg.modeloPadrao);
                    setSenhaVerificada(true);
                    validaRequisitos(certificado, true, aceito);
                    if(atualizarCertDigital === true) {
                        pesqDocto = false;
                    } else {
                        pesqDocto = true;
                    }
                    flgJuridico = msg.modeloPadrao.flgJuridico;
                    nrCGCCIC = msg.modeloPadrao.nrCGCCIC;
                } else if(msg["class"] === "falhaDeValidacao") {
                    console.log(msg);
                    setSenhaVerificada(false);
                    validaRequisitos(certificado, false, aceito);
                    setTxSenha("");
                    notificacao.erro({errorId: "certDig",
                        message: 'Falha !',
                        description: msg.descricao,
                        duration: 6
                    });
                    if(msg.id === "certificadoVencido") {
                        certificadoInvalido = true;
                    }
                } else {
                    console.log(msg);
                }
            });

            if(pesqDocto === true) {
                await pesquisaNrDocto(flgJuridico, nrCGCCIC);
            }
            if(certificadoInvalido === true) {
                onChangeAceite(new FakeEvent());
                await enviarNovoCertificado();
            }
        } catch (e) {

            notificacao.erro({errorId: "certDig",
                message: 'Falha !',
                description: "Estamos com dificuldade em ler o seu certificado digital. Por favor entre em contato.",
                duration: 6
            });

            setSenhaVerificada(false);

            validaRequisitos(certificado, false, aceito);

        }

    }

    if(refTxSenha.current)
        setTimeout(() => {
            if(refTxSenha.current) refTxSenha.current.focus()
        }, 100);

    const onChangeAceite = (e) => {
        setAceito(e.target.checked);
        if(props.onAceiteTermos) props.onAceiteTermos(e.target.checked);
        validaRequisitos(certificado, senhaVerificada, e.target.checked);
    }

    const validaRequisitos = (certificado, senhaVerificada, aceito) => {
        console.log("validaRequisitos", certificado, senhaVerificada, aceito)
        const novaListaReqs = [];
        if(certificado !== null) novaListaReqs.push("enviar");
        if(senhaVerificada === true) novaListaReqs.push("validar");
        if(aceito === true) novaListaReqs.push("aceite");
        setReqsConferidos(novaListaReqs);
    }

    const btnVerTermosUso = () => {
        setExibeTermosDeUso(true);
    }

    return (
        <div className={"containerStepCertDig"}>
            {certificado===null?
                <Row className={"rowUpload"}>
                    <Col xs={16} md={24}>
                        <ProFormUploadDragger
                                              description = "Precisamos do certificado digital do tipo PFX ou P12."
                                              title ="Clique aqui ou arraste o certificado digital para esta área"
                                              label = ""
                                              icon={iconUpload}
                                              name = "uplCertDig"
                                              fieldProps={{...propsUpload}}
                        />
                    </Col>
                </Row>
            :null}
            {certificado === null ? null :
                <Row className={"rowCertDig"}>
                    <Col xs={16} md={18}>
                        <Input addonBefore="Certificado digital"
                               defaultValue={certificado.name}
                               placeholder={"Nome do arquivo do certificado digital"}
                               disabled
                        />
                    </Col>
                    <Col xs={16} md={6}>
                        <Button type={"primary"} danger onClick={() => enviarNovoCertificado()}>Substituir certificado</Button>
                    </Col>
                </Row>
            }
            {certificado === null ? null :
                <Row className={"rowSenha"}>
                    <Col xs={16} md={18}>
                        <Input.Password addonBefore="Qual é a senha ?"
                               defaultValue=""
                               value={txSenha}
                               onChange={(e) => setTxSenha(e.target.value)}
                               placeholder={"Digite aqui a senha do seu certificado digital"}
                               disabled={senhaVerificada}
                               ref={refTxSenha}
                        />
                    </Col>
                    <Col xs={16} md={6}>
                        {senhaVerificada === false ?
                                <Button type={"primary"} onClick={() => verificarSenhaCertificado()}>Verificar a
                                    senha</Button>
                            :
                            null
                        }
                    </Col>
                </Row>
            }
            {senhaVerificada === true ?
                <Row className={"rowInfoCertD"}>
                    <Col xs={16} md={24}>
                        <Space>
                            <label>
                                Certificado digital emitido para {infoCertificado.flgJuridico==="J"?"pessoa jurídica":"pessoa física"} {infoCertificado.noPessoa},
                                documento de identificação {infoCertificado.nrDocto}.
                                Válido no intervalo entre {infoCertificado.dtValidoDe.toDateFormat("DD/MMMM/YYYY")} e {infoCertificado.dtValidoAte.toDateFormat("DD/MMMM/YYYY")}.
                            </label>
                        </Space>
                    </Col>
                </Row>
                : null
            }
            {(semAceiteDosTermos===true)?
                <label>&nbsp;</label>
                :
                <Row className={"rowAceite"}>
                    <Col xs={16} md={24}>
                        <Checkbox onChange={onChangeAceite} checked={aceito} value={"A"}>Li e aceito os termos de uso, política de privacidade e utilização de cookies</Checkbox>
                        <Button type={"link"} onClick={btnVerTermosUso}>Visualizar os termos aceitados</Button>
                    </Col>
                </Row>
            }
            <Row className={"rowListaReqs"}>
                <Col span={24}>
                    <ListaRequisitos
                        formRef = { props.formRef }
                        formFieldName={"requisitos"}
                        label={"Necessário enviar e validar o certificado digital"}
                        tooltip={"Verififque os passos necessário para prosseguir"}
                        dataSource={listaRequisitos}
                        checkeds={reqsConferidos}
                    />
                </Col>
            </Row>
            <PopUpTermosDeUso
                exibePopUp={ exibeTermosDeUso }
                onRequestClose={() => { setExibeTermosDeUso(false); }}
            />
        </div>
    );

};

export default StepCertDigital;

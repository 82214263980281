//v5 import "antd/dist/antd.css";
import '../../../assets/css/tema.scss';
import './notificacoesDropdown.scss';

import React, {useContext, useImperativeHandle, useState} from 'react';
import {connect} from "react-redux";
import {BELL_ICON_PRIMARY, BELL_ICON_PRIMARY_2X, CUSTOM, TRASH_ICON_RED} from "../../../assets/iconografia";
import {Badge, List, Col, Tooltip, Button} from "antd";
import ReactModal from "react-modal";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {useWindowSize} from "../../../lib/iy2b-react";
import {campoInformado} from "../../../lib/iy2b-javascript";
import {setEmpresaAtual} from "../../../reducer/suite";
import {clearNotifications, deleteNotification} from "../../../reducer/wsDataProvider";
import {WorkspaceContext} from "../../Workspace/workspace";
import {iy2bNotification} from "../../../assets/svg/iconografia/iy2bSvgIconsMono";

const NotificacoesDropdown = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {
            exibeDropdown: () => {
                setExibeDropDown(true);
            },
            escondeDropdown: () => {
                setExibeDropDown(false);
            }
        };
    });

    const { showNotificacoes } = useContext(WorkspaceContext);

    const [exibeDropdown, setExibeDropDown] = useState(false);

    const breaks = useBreakpoint();

    const windowSize = useWindowSize();

    const scrollerWidth = (breaks.xs===true)?350:430;

    const { wsDataProvider } = props;

    // console.log("notificacoes", wsDataProvider.notificacoes);

    const handleClose = () => {
        setExibeDropDown(false);
    }

    const handleBadgeClick = () => {
        // console.log("handleBadgeClick", wsDataProvider.notificacoes.length);
        if(wsDataProvider.notificacoes.length === 0) return;
        // setExibeDropDown(true);
        showNotificacoes();
    }

    const removeItem = (item) => {
        props.deleteNotification(item);
    }

    const removeAllItems = () => {
        props.clearNotifications();
    }

    const renderNotificacao = item => {

        const icon =
            (item.type === "success") ? CUSTOM.CHECK_ICON_GREEN :
            (item.type === "error") ? CUSTOM.TIMES_CIRCLE_ICON_RED :
            CUSTOM.EXCLAMATION_CIRCLE_ICON_PURPLE
        ;
        const dataHora = <div>{item.date.toMoment().format("DD/MM/YYY") + " as " + item.time}</div>;
        const nomeDaTransacao = (campoInformado(item.noTransacao) === true) ? <div>{item.noTransacao}</div> : null;
        const description = <>
            {dataHora}
            {nomeDaTransacao}
        </>;
        return (
            <List.Item
                key={item.key}
                actions={[
                    <Button type={"text"} icon={TRASH_ICON_RED} onClick={() => removeItem(item)}/>
                ]}
            >
                <List.Item.Meta
                    avatar={icon}
                    title={item.message}
                    description={description}
                />
                {item.description}
            </List.Item>
        );
    }

    const badgeCount = wsDataProvider.notificacoes.length;

    const element = (badgeCount === 0) ? null :
    (
        <Tooltip placement="bottom" title={"Veja as notificações recebidas recentemente"}>
            <Col flex={"45px"}>
                <div id={"boxNotificacoesDropdown"}>
                    <Badge count={badgeCount} overflowCount={99} onClick={handleBadgeClick} size={"small"}>
                        {CUSTOM.NOTIFICATION_ICON}
                    </Badge>
                    <ReactModal
                        isOpen={exibeDropdown}
                        className={"modalContent"}
                        overlayClassName={"modalOverlay"}
                        bodyOpenClassName={"notificacoesDropdownModal"}
                        closeTimeoutMS={500}
                        onRequestClose={handleClose}
                        style={{
                            content: {
                                left: (windowSize.width - scrollerWidth) + 'px',
                            }
                        }}
                    >
                        <div style={{width:scrollerWidth}}>
                            <div className={"header"}><span className={"label"}>Notificações</span><span className={"botao"}><a onClick={handleClose}>{CUSTOM.TIMES_ICON_BLACK_2X}</a></span></div>
                            <div className={"scroller"}>
                                <List
                                    dataSource={wsDataProvider.notificacoes}
                                    renderItem={renderNotificacao}
                                >
                                </List>
                            </div>
                            <div className={"footer"}>
                                <Button type={"text"} icon={TRASH_ICON_RED} onClick={() => removeAllItems()}>&nbsp;Limpar notificações</Button>
                            </div>
                        </div>
                    </ReactModal>
                </div>
            </Col>
        </Tooltip>
    );

    return element;

});

const mapStateToProps = function( { userSession, wsDataProvider } ) {

    return {
        userSession: userSession,
        wsDataProvider: wsDataProvider
    };

};

const mapDispatchToProps = dispatch => {
    return {
        deleteNotification: (notificacao) => {
            return dispatch( deleteNotification(notificacao) );
        },
        clearNotifications: () => {
            return dispatch( clearNotifications() );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps, null,{forwardRef: true})(NotificacoesDropdown);

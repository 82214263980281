export const iy2bSearch = {
    icon: [24, 24, [], "", [
        "M15.7072 14.293L21.7072 20.293L20.293 21.7072L14.293 15.7072L15.7072 14.293Z",
        "M10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z",
        "M13.5 10C13.5 8.067 11.933 6.5 10 6.5V4.5C13.0376 4.5 15.5 6.96243 15.5 10H13.5Z"
    ]],
    iconName: "iy2b-duotone-search",
    prefix: "fa-duotone"
};

export const iy2bPencil = {
    icon: [24, 24, [], "", [
        "M16.7929 0.792893C17.1834 0.402369 17.8166 0.402369 18.2071 0.792893L21.2071 3.79289C21.5976 4.18342 21.5976 4.81658 21.2071 5.20711L12.2071 14.2071C12.0541 14.3601 11.8556 14.4594 11.6414 14.4899L8.14143 14.9899C7.82984 15.0345 7.51547 14.9297 7.2929 14.7071C7.07034 14.4845 6.96555 14.1702 7.01006 13.8586L7.51006 10.3586C7.54066 10.1444 7.63991 9.94589 7.7929 9.79289L16.7929 0.792893ZM9.44282 10.9714L9.17852 12.8215L11.0286 12.5572L19.0858 4.5L17.5 2.91421L9.44282 10.9714Z",
        "M4 5.5C3.17157 5.5 2.5 6.17157 2.5 7V18C2.5 18.8284 3.17157 19.5 4 19.5H15C15.8284 19.5 16.5 18.8284 16.5 18V10.5H18.5V18C18.5 19.933 16.933 21.5 15 21.5H4C2.067 21.5 0.5 19.933 0.5 18V7C0.5 5.067 2.067 3.5 4 3.5H11.5V5.5H4Z"
    ]],
    iconName: "iy2b-duotone-pencil",
    prefix: "fa-duotone"
}

export const iy2bTrash = {
    icon: [24, 24, [], "", [
        "M11 19V10H13V19H11Z M8.00146 19.0555L7.50146 10.0555L9.49839 9.94458L9.99839 18.9446L8.00146 19.0555Z M15.9985 19.0555L16.4985 10.0555L14.5016 9.94458L14.0016 18.9446L15.9985 19.0555Z",
        "M4.26898 6.81765C4.4581 6.61503 4.72284 6.5 5 6.5H19C19.2772 6.5 19.5419 6.61503 19.731 6.81765C19.9202 7.02027 20.0167 7.29229 19.9976 7.5688L18.9976 22.0688C18.9615 22.5931 18.5256 23 18 23H6C5.47442 23 5.03853 22.5931 5.00237 22.0688L4.00237 7.5688C3.9833 7.29229 4.07985 7.02027 4.26898 6.81765ZM6.07134 8.5L6.93341 21H17.0666L17.9287 8.5H6.07134Z M4 6.5C4 4.84315 5.34315 3.5 7 3.5H17C18.6569 3.5 20 4.84315 20 6.5V7.5C20 8.05228 19.5523 8.5 19 8.5H5C4.44772 8.5 4 8.05228 4 7.5V6.5ZM7 5.5C6.44772 5.5 6 5.94772 6 6.5H18C18 5.94772 17.5523 5.5 17 5.5H7Z M12 3C11.4477 3 11 3.44772 11 4V4.5H9V4C9 2.34315 10.3431 1 12 1C13.6569 1 15 2.34315 15 4V4.5H13V4C13 3.44772 12.5523 3 12 3Z M22 8.5H2V6.5H22V8.5Z"
    ]],
    iconName: "iy2b-duotone-trash",
    prefix: "fa-duotone"
}

export const iy2bPrint = {
    icon: [24, 24, [], "", [
        "M2 9C2 7.89543 2.89543 7 4 7H20C21.1046 7 22 7.89543 22 9V17C22 18.1046 21.1046 19 20 19H17V17H20V9L4 9V17H7V19H4C2.89543 19 2 18.1046 2 17V9Z M6 2C6 1.44772 6.44772 1 7 1H17C17.5523 1 18 1.44772 18 2V8C18 8.55228 17.5523 9 17 9H7C6.44772 9 6 8.55228 6 8V2ZM8 3V7H16V3H8Z",
        "M6 15C6 14.4477 6.44772 14 7 14H17C17.5523 14 18 14.4477 18 15V22C18 22.5523 17.5523 23 17 23H7C6.44772 23 6 22.5523 6 22V15ZM8 16V21H16V16H8Z M16 9.5H18V11.5H16V9.5Z"
    ]],
    iconName: "iy2b-duotone-print",
    prefix: "fa-duotone"
}

export const iy2bCloudDownload = {
    icon: [24, 24, [], "", [
        "M11 5.5C8.23858 5.5 6 7.73858 6 10.5C6 11.0523 5.55228 11.5 5 11.5C3.89543 11.5 3 12.3954 3 13.5C3 14.6046 3.89543 15.5 5 15.5H6.25V17.5H5C2.79086 17.5 1 15.7091 1 13.5C1 11.6161 2.30232 10.0364 4.05578 9.61209C4.49209 6.16532 7.43478 3.5 11 3.5C13.8102 3.5 16.2317 5.1554 17.3461 7.54249C17.5604 7.51445 17.7786 7.5 18 7.5C20.7614 7.5 23 9.73858 23 12.5C23 13.7909 22.6232 15.03 21.8489 15.9659C21.0588 16.9209 19.9006 17.5 18.5 17.5H17.75V15.5H18.5C19.3085 15.5 19.9003 15.1837 20.3079 14.691C20.7314 14.1792 21 13.4182 21 12.5C21 10.8431 19.6569 9.5 18 9.5C17.6646 9.5 17.344 9.55467 17.0454 9.6548C16.7905 9.74025 16.5121 9.71953 16.2727 9.59733C16.0333 9.47512 15.8532 9.26171 15.773 9.00522C15.1373 6.97289 13.2394 5.5 11 5.5Z",
        "M11 20V13.5H13V20H11Z M11.2928 21.2071L8.29285 18.2071L9.70706 16.7929L12 19.0858L14.2928 16.7929L15.7071 18.2071L12.7071 21.2071C12.3165 21.5976 11.6834 21.5976 11.2928 21.2071Z"
    ]],
    iconName: "iy2b-duotone-clouddownload",
    prefix: "fa-duotone"
}

export const iy2bCloudUpload = {
    icon: [24, 24, [], "", [
        "M11 5.5C8.23858 5.5 6 7.73858 6 10.5C6 11.0523 5.55228 11.5 5 11.5C3.89543 11.5 3 12.3954 3 13.5C3 14.6046 3.89543 15.5 5 15.5H6.25V17.5H5C2.79086 17.5 1 15.7091 1 13.5C1 11.6161 2.30232 10.0364 4.05578 9.61209C4.49209 6.16532 7.43478 3.5 11 3.5C13.8102 3.5 16.2317 5.1554 17.3461 7.54249C17.5604 7.51445 17.7786 7.5 18 7.5C20.7614 7.5 23 9.73858 23 12.5C23 13.7909 22.6232 15.03 21.8489 15.9659C21.0588 16.9209 19.9006 17.5 18.5 17.5H17.75V15.5H18.5C19.3085 15.5 19.9003 15.1837 20.3079 14.691C20.7314 14.1792 21 13.4182 21 12.5C21 10.8431 19.6569 9.5 18 9.5C17.6646 9.5 17.344 9.55467 17.0454 9.6548C16.7905 9.74025 16.5121 9.71953 16.2727 9.59733C16.0333 9.47512 15.8532 9.26171 15.773 9.00522C15.1373 6.97289 13.2394 5.5 11 5.5Z",
        "M11 14V20.5H13V14H11Z M11.293 12.7928L8.29297 15.7928L9.70718 17.207L12.0001 14.9141L14.293 17.207L15.7072 15.7928L12.7072 12.7928C12.3167 12.4023 11.6835 12.4023 11.293 12.7928Z"
    ]],
    iconName: "iy2b-duotone-cloudupload",
    prefix: "fa-duotone"
}

export const iy2bPlus = {
    icon: [24, 24, [], "", [
        "M11 22V2H13V22H11Z",
        "M22 13H2V11H22V13Z"
    ]],
    iconName: "iy2b-duotone-plus",
    prefix: "fa-duotone"
}

export const iy2bRectangleList = {
    icon: [24, 24, [], "", [
        "M4.094481,7.944851c0-.353688.140475-.692874.390542-.94296s.589166-.390612.942791-.390612.69275.1405.942792.390612.390542.589272.390542.94296-.140459.692873-.390542.94296-.589167.390611-.942792.390611-.69275-.140483-.942791-.390611-.390542-.589272-.390542-.94296Zm4.333333,0c0-.554266.445834-1.000179,1-1.000179h9.333334c.554166,0,1,.445913,1,1.000179s-.445834,1.000178-1,1.000178h-9.333334c-.554166,0-1-.445913-1-1.000178Zm0,4.000714c0-.554266.445834-1.000179,1-1.000179h9.333334c.554166,0,1,.445913,1,1.000179s-.445834,1.000179-1,1.000179h-9.333334c-.554166,0-1-.445913-1-1.000179Zm0,4.000714c0-.554265.445834-1.000178,1-1.000178h9.333334c.554166,0,1,.445913,1,1.000178s-.445834,1.000179-1,1.000179h-9.333334c-.554166,0-1-.445913-1-1.000179Zm-3-2.667143c-.353625,0-.69275-.140483-.942791-.390611s-.390542-.589272-.390542-.94296.140475-.692874.390542-.94296.589166-.390611.942791-.390611.69275.140483.942792.390611.390542.589272.390542.94296-.140459.692874-.390542.94296-.589167.390611-.942792.390611ZM4.094481,15.946279c0-.353688.140475-.692873.390542-.94296s.589166-.390611.942791-.390611.69275.140483.942792.390611.390542.589272.390542.94296-.140459.692874-.390542.94296-.589167.390612-.942792.390612-.69275-.140484-.942791-.390612-.390542-.589272-.390542-.94296Z",
        "M2.666667,4.167877C2.3,4.167877,2,4.467931,2,4.834663v14.189101c0,.755384.3.755385.666667.755385h18.666666C21.7,19.779149,22,19.390496,22,19.023764v-14.189101c0-.366732-.3-.666786-.666667-.666786-6.403783-.055021-15.336004-.099896-18.666666,0ZM0,4.834663C0,3.363567,1.195833,2.16752,2.666667,2.16752h18.666666C22.804167,2.16752,24,3.363567,24,4.834663v14.944485c0,1.471096-1.195833,2.206495-2.666667,2.206495h-18.666666C1.195833,21.985643,0,20.49486,0,19.023764L0,4.834663Z"
    ]],
    iconName: "iy2b-duotone-rectanglelist",
    prefix: "fa-duotone"
}

export const iy2bTableCellsLarge = {
    icon: [24, 24, [], "", [
        "M3.029568,1.528431c-1.654687,0-3,1.345313-3,3v15c0,1.654688,1.345313,3,3,3h18c1.654688,0,3-1.345312,3-3v-15c0-1.654687-1.345312-3-3-3h-18Z",
        "M21.029568,4.528431v6h-7.5v-6h7.5Zm0,9v6h-7.5v-6h7.5Zm-10.5-3h-7.5v-6h7.5v6Zm-7.5,3h7.5v6h-7.5v-6Zm0-12c-1.654687,0-3,1.345313-3,3v15c0,1.654688,1.345313,3,3,3h18c1.654688,0,3-1.345312,3-3v-15c0-1.654687-1.345312-3-3-3h-18Z"
    ]],
    iconName: "iy2b-duotone-tablecellslarge",
    prefix: "fa-duotone"
}

export const iy2bSquarePlus = {
    icon: [24, 24, [], "",[
        "M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4H7Z",
        "M11.3334 16V8H12.6667V16H11.3334Z M16 12.6667H8V11.3333H16V12.6667Z"
    ]],
    iconName: "iy2b-duotone-squareplus",
    prefix: "fa-duotone"
}

export const iy2bSquareMinus = {
    icon: [24, 24, [], "", [
        "M2 7C2 4.23858 4.23858 2 7 2H17C19.7614 2 22 4.23858 22 7V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7ZM7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V7C20 5.34315 18.6569 4 17 4H7Z",
        "M16 12.6667H8V11.3333H16V12.6667Z"
    ]],
    iconName: "iy2b-duotone-squareminus",
    prefix: "fa-duotone"
}

export const iy2bListOl = {
    icon: [24, 24, [], "", [
        "M10.285967,2.862612h12.188853c.842745,0,1.523607.680846,1.523607,1.523572s-.680862,1.523571-1.523607,1.523571h-12.188853c-.842745,0-1.523606-.680846-1.523606-1.523571s.680861-1.523572,1.523606-1.523572Zm0,7.617858h12.188853c.842745,0,1.523607.680846,1.523607,1.523571s-.680862,1.523571-1.523607,1.523571h-12.188853c-.842745,0-1.523606-.680846-1.523606-1.523571s.680861-1.523571,1.523606-1.523571Zm0,7.617857h12.188853c.842745,0,1.523607.680846,1.523607,1.523571s-.680862,1.523572-1.523607,1.523572h-12.188853c-.842745,0-1.523606-.680846-1.523606-1.523572s.680861-1.523571,1.523606-1.523571Z",
        "M0.763426,2.48172c0-.633235.509456-1.142679,1.142705-1.142679h1.523607c.633249,0,1.142705.509444,1.142705,1.142679v5.713392h.761803c.633249,0,1.142705.509445,1.142705,1.142679s-.509456,1.142679-1.142705,1.142679h-3.809017c-.633249,0-1.142705-.509445-1.142705-1.142679s.509456-1.142679,1.142705-1.142679h.761804v-4.570714h-.380902c-.633249,0-1.142705-.509444-1.142705-1.142678ZM3.748743,16.06055c-.309483-.352326-.871313-.32852-1.142705.057134l-.533263.73798c-.366617.514205-1.080808.633234-1.595025.266625s-.633249-1.080784-.266631-1.594989l.528501-.742741c1.128421-1.580705,3.442398-1.694973,4.72318-.233297c1.014151,1.161723.990345,2.899547-.052374,4.032703L3.753504,20.383684h1.580742c.633249,0,1.142705.509444,1.142705,1.142678s-.509456,1.142679-1.142705,1.142679h-4.189918c-.452321,0-.866552-.266625-1.04748-.685607s-.099987-.89986.204735-1.233141l3.428115-3.713705c.252347-.276147.257108-.69513.014283-.976038h.004762Z"
    ]],
    iconName: "iy2b-duotone-listol",
    prefix: "fa-duotone"
}


export const iy2bHelp = {
    icon: [24, 24, [], "", [
        "M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z",
        "M11 19.2222V17H13V19.2222H11Z M11.2227 6.88867C10.6704 6.88867 10.2227 7.33639 10.2227 7.88867V9.22201H8.22266V7.88867C8.22266 6.23182 9.5658 4.88867 11.2227 4.88867H12.7782C14.4351 4.88867 15.7782 6.23182 15.7782 7.88867V9.50469C15.7782 10.3003 15.4621 11.0634 14.8995 11.626L13.2933 13.2322C13.1058 13.4198 13.0004 13.6741 13.0004 13.9393V15.8887H11.0004V13.9393C11.0004 13.1437 11.3165 12.3806 11.8791 11.818L13.4853 10.2118C13.6729 10.0243 13.7782 9.76991 13.7782 9.50469V7.88867C13.7782 7.33639 13.3305 6.88867 12.7782 6.88867H11.2227Z"
    ]],
    iconName: "iy2b-duotone-help",
    prefix: "fa-duotone"
}

export const iy2bEdit = {
    icon: [24, 24, [], "", [
        "M15.2929 1.29289C15.6834 0.902369 16.3166 0.902369 16.7071 1.29289L19.7071 4.29289C20.0977 4.68342 20.0977 5.31658 19.7071 5.70711L7.70713 17.7071C7.57897 17.8353 7.41839 17.9262 7.24256 17.9701L3.24256 18.9701C2.90178 19.0553 2.54129 18.9555 2.29291 18.7071C2.04453 18.4587 1.94468 18.0982 2.02988 17.7575L3.02988 13.7575C3.07384 13.5816 3.16476 13.4211 3.29291 13.2929L15.2929 1.29289ZM4.90299 14.5112L4.37439 16.6256L6.48877 16.097L17.5858 5L16 3.41421L4.90299 14.5112Z",
        "M22 22H2V20H22V22Z"
    ]],
    iconName: "iy2b-duotone-edit",
    prefix: "fa-duotone"
}

export const iy2bWarehouse = {
    icon: [24, 24, [], "", [
        "M5.44727 20V8H3.44727V21C3.44727 21.5523 3.89498 22 4.44727 22H20.4473C20.9995 22 21.4473 21.5523 21.4473 21V8H19.4473V20H5.44727Z M6.44727 16C6.44727 15.4477 6.89498 15 7.44727 15H12.4473C12.9996 15 13.4473 15.4477 13.4473 16V21C13.4473 21.5523 12.9996 22 12.4473 22H7.44727C6.89498 22 6.44727 21.5523 6.44727 21V16ZM8.44727 17V20H11.4473V17H8.44727Z M6.44727 11C6.44727 10.4477 6.89498 10 7.44727 10H12.4473C12.9996 10 13.4473 10.4477 13.4473 11V16C13.4473 16.5523 12.9996 17 12.4473 17H7.44727C6.89498 17 6.44727 16.5523 6.44727 16V11ZM8.44727 12V15H11.4473V12H8.44727Z M11.4473 16C11.4473 15.4477 11.895 15 12.4473 15H17.4473C17.9996 15 18.4473 15.4477 18.4473 16V21C18.4473 21.5523 17.9996 22 17.4473 22H12.4473C11.895 22 11.4473 21.5523 11.4473 21V16ZM13.4473 17V20H16.4473V17H13.4473Z M11.4473 11C11.4473 10.4477 11.895 10 12.4473 10H17.4473C17.9996 10 18.4473 10.4477 18.4473 11V16C18.4473 16.5523 17.9996 17 17.4473 17H12.4473C11.895 17 11.4473 16.5523 11.4473 16V11ZM13.4473 12V15H16.4473V12H13.4473Z",
        "M12 3.10557C12.2815 2.96481 12.6129 2.96481 12.8944 3.10557L22.8944 8.10557L22 9.89443L12.4472 5.11803L2.89443 9.89443L2 8.10557L12 3.10557Z M8.94727 18V16H10.9473V18H8.94727Z M8.94727 13V11H10.9473V13H8.94727Z M13.9473 18V16H15.9473V18H13.9473Z M13.9473 13V11H15.9473V13H13.9473Z"
    ]],
    iconName: "iy2b-duotone-warehouse",
    prefix: "fa-duotone"
}


export const iy2bPayMoney = {
    icon: [24, 24, [], "", [
        "M1 11.25C1 10.6977 1.44772 10.25 2 10.25H5C5.55228 10.25 6 10.6977 6 11.25V19.25C6 19.8023 5.55228 20.25 5 20.25H2C1.44772 20.25 1 19.8023 1 19.25V11.25ZM3 12.25V18.25H4V12.25H3Z M9.6 14.45L13.0667 17.05C13.2398 17.1798 13.4503 17.25 13.6667 17.25H20.5C21.8807 17.25 23 18.3693 23 19.75C23 21.1307 21.8807 22.25 20.5 22.25H12.1609C11.8774 22.25 11.5953 22.2098 11.3231 22.1306L4.7207 20.2102L5.27929 18.2898L11.8816 20.2102C11.9724 20.2366 12.0664 20.25 12.1609 20.25H20.5C20.7761 20.25 21 20.0261 21 19.75C21 19.4739 20.7761 19.25 20.5 19.25H13.6667C13.0176 19.25 12.386 19.0395 11.8667 18.65L8.4 16.05L9.6 14.45Z M9.17157 12.25H5V10.25H9.17157C9.96722 10.25 10.7303 10.5661 11.2929 11.1287L14.6893 14.5251C15.2084 15.0442 15.5 15.7482 15.5 16.4822V17.125C15.5 18.2986 14.5486 19.25 13.375 19.25C12.9152 19.25 12.4678 19.1009 12.1 18.825L8.4 16.05L9.6 14.45L13.3 17.225C13.3216 17.2412 13.348 17.25 13.375 17.25C13.444 17.25 13.5 17.194 13.5 17.125V16.4822C13.5 16.2786 13.4191 16.0833 13.2751 15.9393L9.87868 12.5429C9.69114 12.3554 9.43679 12.25 9.17157 12.25Z",
        "M11 3C11 1.89543 11.8954 1 13 1H21C22.1046 1 23 1.89543 23 3V8C23 9.10457 22.1046 10 21 10H13C11.8954 10 11 9.10457 11 8V3ZM21 3H13V8H21V3Z M17 5C16.7239 5 16.5 5.22386 16.5 5.5C16.5 5.77614 16.7239 6 17 6C17.2761 6 17.5 5.77614 17.5 5.5C17.5 5.22386 17.2761 5 17 5ZM14.5 5.5C14.5 4.11929 15.6193 3 17 3C18.3807 3 19.5 4.11929 19.5 5.5C19.5 6.88071 18.3807 8 17 8C15.6193 8 14.5 6.88071 14.5 5.5Z"
    ]],
    iconName: "iy2b-duotone-pay-money",
    prefix: "fa-duotone"
}

export const iy2bComments = {
    icon: [24, 24, [], "", [
        "M16.239068,20.301699c.60748,0,1.19621-.06,1.766191-.16875.172495.08625.352489.1725.532483.255c1.357455.615,2.988651,1.11375,4.601097,1.11375.359988,0,.682478-.21375.824973-.54375s.074997-.7125-.172494-.97125c-.532483-.585-.982468-1.26375-1.372455-1.95375-.033749-.06375-.071248-.1275-.104996-.19125c1.079964-1.13625,1.724942-2.5725,1.724942-4.14c0-3.54-3.29614-6.43125-7.432253-6.5925.153745.57.232492,1.17.232492,1.7925v.0225c3.269892.25125,5.399821,2.53125,5.399821,4.7775c0,1.05-.427486,2.05875-1.226209,2.895-.536232.5625-.648729,1.41-.281241,2.0925.041249.075.082497.15.119996.22125.093747.16875.194994.3375.29624.51-.637479-.16875-1.271208-.40125-1.871188-.675-.161244-.07125-.318739-.14625-.472484-.225-.356238-.18-.761225-.2325-1.151212-.1575-.453735.09-.926219.135-1.417453.135-2.313673,0-4.124863-.99375-5.12983-2.33625-.59998.2025-1.229959.3525-1.874938.4425c1.271208,2.19,3.93362,3.6975,7.008518,3.6975Z",
        "M3.346995,13.892949c.367488-.68625.254992-1.53-.28124-2.0925-.798724-.84-1.22621-1.84875-1.22621-2.89875c0-2.38125,2.392421-4.8,5.999801-4.8s5.999802,2.41875,5.999802,4.8-2.392421,4.8-5.999802,4.8c-.491233,0-.967467-.04875-1.417453-.135-.389987-.075-.794973-.0225-1.151211.1575-.153745.07875-.31124.15375-.472485.225-.59998.27-1.233709.50625-1.871188.675.104997-.1725.202494-.34125.296241-.51.041248-.07125.082497-.14625.119996-.22125h.003749Zm-3.30739-4.99125c0,1.5675.644979,3.00375,1.721193,4.13625-.033749.06375-.071248.13125-.104997.19125-.386237.69-.836222,1.36875-1.372454,1.95375-.247492.2625-.31124.645-.172494.97125.146245.33375.468734.5475.828722.5475c1.612447,0,3.243643-.49875,4.601098-1.11375.179994-.0825.359988-.16875.532482-.255.566231.1125,1.158712.16875,1.766191.16875c4.308608,0,7.799742-2.955,7.799742-6.6s-3.491134-6.6-7.799742-6.6-7.799741,2.955-7.799741,6.6Z"
    ]],
    iconName: "iy2b-duotone-comments",
    prefix: "fa-duotone"
}

export const iy2bSend = {
    icon: [24, 24, [], "", [
        "M22.2071 3.20712L11.2071 14.2071L9.79285 12.7929L20.7928 1.79291L22.2071 3.20712Z",
        "M22.7071 1.29289C22.9963 1.58203 23.0802 2.01808 22.9192 2.39392L14.3477 22.3939C14.1842 22.7755 13.8018 23.0164 13.387 22.9991C12.9722 22.9819 12.6112 22.7101 12.4799 22.3162L9.78086 14.2191L1.68378 11.5201C1.2899 11.3888 1.01812 11.0278 1.00087 10.613C0.983615 10.1982 1.22448 9.81583 1.60609 9.65228L21.6061 1.08085C21.9819 0.919778 22.418 1.00375 22.7071 1.29289ZM4.81146 10.4545L10.8877 12.4799C11.1863 12.5794 11.4206 12.8137 11.5201 13.1123L13.5455 19.1885L20.0961 3.90394L4.81146 10.4545Z"
    ]],
    iconName: "iy2b-duotone-send",
    prefix: "fa-duotone"
}


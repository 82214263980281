import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../framework/pages/Workspace/workspace";

import {cachedPostJSON} from "../../framework/lib/CacheRequestAPI";
import {pesquisarBanco, pesquisarTipoDespEmp} from "../../lib/servicosPortalIY2B";
import {notificacaoErroRequest} from "../../framework/lib/iy2b-react";
import {campoInformado} from "../../framework/lib/iy2b-javascript";
import {postJSON} from "../../framework/lib/RequestAPI";

const inicializar = async (cdPessoaEmpAtual, cdTransacao) => {

    const dataProvider = {
        cdPessoaEmpAtual: cdPessoaEmpAtual,
    };

    try {

        const payload = {
            "acaoAExecutar": "inicializaProjeto",
        }

        const resultado = await cachedPostJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "scr",
            projeto: "mcliente",
            payload: payload
        }, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaNacionalidade") {

                dataProvider.dpNacionalidade = msg.lista.map(item => {
                    return {
                        value: item.cdNacional,
                        label: item.noNacionalM,
                        data: {...item}
                    }
                });

            } else if (msg.id === "listaGrpEconomico") {

                dataProvider.dpGrpEconomico = msg.lista.map(item => {
                    return {
                        value: item.cdGrpEconomico,
                        label: item.noGrpEconomico,
                        data: {...item}
                    }
                });

            } else if (msg.id === "listaAtividade") {

                dataProvider.dpAtividade = msg.lista.map(item => {
                    return {
                        value: item.cdAtividade,
                        label: item.noAtividade,
                        data: {...item}
                    }
                });

            } else if (msg.id === "listaTipoCtaBanc") {

                dataProvider.dpTipoCtaBanc = msg.lista.map(item => {
                    return {
                        value: item.cdTipoCtaBanc,
                        label: item.noTipoCtaBanc,
                        data: {...item}
                    }
                });

            } else if (msg.id === "listaFormaPagto") {

                dataProvider.dpFormaPagto = msg.lista.map(item => {
                    return {
                        value: item.cdFormaPagto,
                        label: item.noFormaPagto,
                        data: {...item}
                    }
                });

            } else if (msg.id === "listaTipoPessoa") {

                dataProvider.dpTipoPessoa = msg.lista.map(item => {
                    return {
                        value: item.cdTipoPes,
                        label: item.noTipoPes,
                        data: {...item}
                    }
                });

            } else if (msg.id === "listaTipoDespesa") {

                dataProvider.dpTipoDespesa = [];

            } else if (msg.id === "mapaParametros") {

                dataProvider.mapaParametros = msg.mapa;

            } else {

                console.log(msg);

            }

        });

        dataProvider.dpTipoDespesa = await pesquisarTipoDespEmp();

        const dpBanco = await pesquisarBanco();

        dataProvider.dpBanco = dpBanco.map(banco => {
            return {
                value: banco.data.cdBco,
                label: banco.label,
                data: banco.data
            }
        });

    } catch (e) {

        console.log(e);

        notificacaoErroRequest(e, cdTransacao);

    }

    console.log(dataProvider);

    return dataProvider;

}

const salvar = async (registro, cdTransacao) => {

    try {

        let acaoWebAExecutar = campoInformado(registro.cliente.cdPessoaCli)?"alterar":"incluir";

        if(acaoWebAExecutar === "alterar") {
            if(registro.cliente.pessoaJaExiste === "S") {
                acaoWebAExecutar = "incluir";
            }
        }

        delete registro.cliente.pessoaJaExiste

        const payload = {
            acaoAExecutar: "acaoCRUD",
            "versaoHTML": "S",
            "clientePorEmpresa": "S",
            registro: registro
        };

        let modeloCliente = null;

        const resultado = await postJSON({
            acaoWebAExecutar: acaoWebAExecutar,
            sistema: "scr",
            projeto: "mcliente"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "modelo") {

                const modelo = msg.modeloPadrao;

                modelo.key = modelo.cdPessoaCli;

                modeloCliente = {
                    ...modelo
                }

            }

        });

        return modeloCliente;

    } catch (e) {

        notificacaoErroRequest(e, cdTransacao);

        return null;

    }

}

export {
    inicializar, salvar
}

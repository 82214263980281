import {SerializadorEventos} from "./serializadorEventos";

export class SerializadorGerente {

    constructor() {
        this.serializador = null;
        this.jsBlock = null;
    }

    inicio () {
        if(this.serializador === null) {
            this.serializador = new SerializadorEventos();
            this.serializador.setCallback(this.jsBlock);
        }
    }

    adicionaDependencia = (id) => {

        this.serializador.adicionaDependencia(id);

    };

    concluiDependencia = (id) => {

        this.serializador.concluiDependencia(id);

    };

    setCallback = (jsBlock) => {

        this.jsBlock = jsBlock;

    };

    reset = () => {

        this.serializador = null;

        this.inicio();

    }

};

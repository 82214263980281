//v5 import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-layout/dist/layout.css';
import "./style.scss";

import {connect} from "react-redux";
import React, {useContext, useEffect, useRef, useState} from "react";

import {ProForm} from '@ant-design/pro-form' ;

import {  FooterToolbar  } from '@ant-design/pro-layout' ;
import {Button, Col, notification, Popconfirm, Row, Tabs} from "antd";
import {pesquisarPlanoContrVenda} from "../../lib/Servicos";
import IY2BModal from "../../componentes/IY2BModal";
import {postJSON, postWS} from "../../lib/RequestAPI";
import {campoInformado} from "../../lib/iy2b-javascript";
import TooltipOnIcon from "../../componentes/TooltipOnIcon";
import {efetuaLogout} from "../../lib/LoginAPI";
import {closeAll} from "../../reducer/transacaoManager";
import {atualizarInfoComplUsr, clearSession} from "../../reducer/userSession";
import IY2BModalBody from "../../componentes/IY2BModalBody";
import PopUpIFrame from "../../componentes/PopUpIFrame";

import {Bullet} from'@ant-design/charts';
import {CUSTOM} from "../../assets/iconografia";
import {notificacao} from "../../lib/Notificacao";
import {WorkspaceContext} from "../../pages/Workspace/workspace";

const iconCheck = CUSTOM.CHECK_ICON_GREEN;
const iconCheckCircle = CUSTOM.CHECK_CIRCLE_ICON_PURPLE;

const MeuPlano = (props) => {

    const formRef = useRef() ;
    const {paramWeb, loadingArea} = props;

    const cdTransacao = 85062;

    const { availableWidth } = useContext(WorkspaceContext);

    const [dsPlanos, setDsPlanos] = useState([]);
    const [planoSelecionado, setPlanoSelecionado] = useState(null);
    const [planoOriginal, setPlanoOriginal] = useState(null);

    const [exibeOrientacoesCanc, setExibeOrientacoesCanc] = useState (false);

    const [urlMaisInfo, setUrlMaisInfo] = useState(null);
    const [exibeMaisInfo, setExibeMaisInfo] = useState(false);

    useEffect(() => {

        inicializa();

        return () => {

        };

    }, []);

    async function inicializa () {

        const planos = await pesquisaPlanos();

        setDsPlanos(planos);

    }

    const pesquisaPlanos = async () => {

        const { planoContratado } = props.userSession;

        const planos = await pesquisarPlanoContrVenda(loadingArea, cdTransacao);

        planos.forEach(plano => {

            if(plano.id === planoContratado.cdPlanoContrVenda) {

                plano.selecionado = true;
                setPlanoSelecionado(plano);
                setPlanoOriginal(plano);

            }

        });

        return planos;

    }

    const showDetPlano = (plano) => {

        if(campoInformado(plano.txUrlHomeProduct) === false) return;

        const agora = (new Date()).getTime();

        const url = plano.txUrlHomeProduct + "?&dt=" + agora

        // window.open(url,'_blank');

        setUrlMaisInfo(url);
        setExibeMaisInfo(true);

    }


    const btnSelecionaPlano = (plano) => {

        dsPlanos.filter(item => item.selecionado === true).forEach(item => {
            item.selecionado = false;
        });

        plano.selecionado = true;

        setDsPlanos(dsPlanos.clone());

        setPlanoSelecionado(plano);

    }

    const renderToolTipPlano = (plano) => {

        if(plano === null || plano === undefined) return null;

        const liLayout1 = plano.descrItens.filter(item => (item.flgLayout==='1')).map(item => {
            return (
                <div key={plano.id+"."+item.nrItem} className={"item"}>
                    {iconCheck}
                    <span>{item.txItem}</span>
                </div>
            );
        });

        const ulLayout1 = (liLayout1.length===0)?null:<div className="itens layout-1">{liLayout1}</div>;

        const liLayout2 = plano.descrItens.filter(item => (item.flgLayout==='2')).map(item => {
            return (
                <div key={plano.id+"."+item.nrItem} className={"item"}>
                    {iconCheckCircle}
                    <span>{item.txItem}</span>
                </div>
            );
        });

        const ulLayout2 = (liLayout2.length===0)?null:<div className="itens layout-2">{liLayout2}</div>;

        return (<div className={"tooltip-meu-plano-info"}>
            <label className="descricao">{plano.descrPlano}</label>
            {ulLayout1}
            {ulLayout2}
        </div>)

    }

    const renderTipoPessoa = (plano) => {

        if(plano === null || plano === undefined) return null;

        const tipoPessoa = (plano.flgTipoPessoa==="G") ? "Trial" :
                (plano.flgTipoPessoa==="B") ? "Basic" :
                (plano.flgTipoPessoa==="M") ? "Iron" :
                (plano.flgTipoPessoa==="S") ? "Silver" :
                (plano.flgTipoPessoa==="L") ? "Golden" :
                "Platinum";

        return (
            <div className="tipoPessoaAtual">{tipoPessoa}</div>
        );
    }

    const renderCardPlano = (plano) => {

        if(plano === null || plano === undefined) return null;

        const statusClass = plano.selecionado ? ( (planoSelecionado.id === planoOriginal.id) ? "o-mesmo" : "selecionado") : "";

        return  (
            <div className={["box-preco", statusClass].toClassName()}>
                <TooltipOnIcon title={() => renderToolTipPlano(plano)}/>
                <div onClick={() => btnSelecionaPlano(plano)}>
                    <label className="nome">{plano.noPlano}</label>
                    <label className="precoBase">{plano.precoBase}</label>
                    <label className="formaPagto">{plano.formaPagto}<span className={"periodicidade"}>{plano.periodicidade}</span></label>
                </div>
                <div className="toolbar">
                </div>
            </div>
        );

    }

    const colSpanPlanos = {
        xs:{span:11},
        sm:{span:11},
        md:{span:10},
        lg:{span:6},
        xl:{span:6},
        xxl:{span:4}
    };

    const buildGridPlano = (ds) => {

        const filtrados = ds.filter(plano => plano.id !== planoOriginal.id);

        const span = (filtrados.length>1) ? 24 / filtrados.length : 24;

        return filtrados.map(plano => {
                return (<Col key={plano.id} span={span}>
                    {renderCardPlano(plano)}
                </Col>)
            });

    }

    const renderPlanosDisponiveis = () => {

        const dsGratis = dsPlanos.filter(plano => plano.flgTipoPessoa === "G");
        const dsMEI = dsPlanos.filter(plano => plano.flgTipoPessoa === "M");
        const dsSimples = dsPlanos.filter(plano => plano.flgTipoPessoa === "S");
        const dsLucro = dsPlanos.filter(plano => plano.flgTipoPessoa === "L");
        const dsGEmp = dsPlanos.filter(plano => plano.flgTipoPessoa === "E");
        const dsBasic = dsPlanos.filter(plano => plano.flgTipoPessoa === "B");

        const gridPlanoGratis = buildGridPlano(dsGratis);
        const gridPlanoMEI = buildGridPlano(dsMEI);
        const gridPlanoSimples = buildGridPlano(dsSimples);
        const gridPlanoLucro = buildGridPlano(dsLucro);
        const gridPlanoGEmp = buildGridPlano(dsGEmp);
        const gridPlanoBasic = buildGridPlano(dsBasic);

        const tabItens = [{
            key: "G",
            label: "Trial",
            children:
                <Row gutter={[8, 16]} className={"row-box-planos"}>
                    {gridPlanoGratis}
                </Row>
        }, {
            key: "B",
            label: "Iron",
            children:
                <Row gutter={[8, 16]} className={"row-box-planos"}>
                    {gridPlanoBasic}
                </Row>
        }, {
            key: "M",
            label: "Bronze",
            children:
                <Row gutter={[8, 16]} className={"row-box-planos"}>
                    {gridPlanoMEI}
                </Row>
        }, {
            key: "S",
            label: "Silver",
            children:
                <Row gutter={[8, 16]} className={"row-box-planos"}>
                    {gridPlanoSimples}
                </Row>
        }, {
            key: "L",
            label: "Golden",
            children:
                <Row gutter={[8, 16]} className={"row-box-planos"}>
                    {gridPlanoLucro}
                </Row>
        }, {
            key: "E",
            label: "Platinum",
            children:
                <Row gutter={[8, 16]} className={"row-box-planos"}>
                    {gridPlanoGEmp}
                </Row>
        }];

        return (
            <Tabs defaultActiveKey="G" tabPosition={"left"}
                  items={tabItens}
            >
            </Tabs>
        );

/*
        return dsPlanos
            .filter(plano => plano.id !== planoOriginal.id)
            .map(plano => {
                return (<Col key={plano.id} {...colSpanPlanos}>
                    {renderCardPlano(plano)}
                </Col>)
            });
*/

    };

    const onFinish = async () => {

        if(planoSelecionado.id === planoOriginal.id) {

            notificacao.erro({errorId: "mesmoPlano", transacao: cdTransacao, message:"Alterar plano contratado", description:"Plano não foi alterado. Nenhuma informação precisa ser confirmada !"})

            return;

        }

        try {

            const payload = {
                acaoAExecutar: "trocarPlano",
                cdPessoaEmpMaster: props.userSession.planoContratado.cdPessoaEmpMaster,
                cdPessoaEmpPCtrV: planoSelecionado.cdPessoaEmpPCtrV,
                cdPlanoContrVenda: planoSelecionado.cdPlanoContrVenda
            }

            const resposta = await postJSON({
                sistema: "sgr",
                projeto: "mtrocaplanobuild",
                acaoWebAExecutar: "processarnegocio"
            }, payload, loadingArea);

            let planoTrocado = false;

            resposta.mensagens.forEach(msg => {

                if(msg.id === "trocado") {

                    planoTrocado = true;

                    notificacao.sucesso({transacao: cdTransacao, message:"Sucesso", description: msg.mensagem});

                } else {

                    console.log(msg);

                }

            });

            if(planoTrocado === true) {

                await props.atualizarInfoComplUsr();

                setPlanoOriginal(planoSelecionado);

                btnSelecionaPlano(planoSelecionado);

            }

        } catch (e) {

            if(e?.data?.id === "ErroDeAcao") {

                notificacao.erro({errorId: "trocarPlano", transacao: cdTransacao, message: "Ooops!", description: e.data.descricao});

            } else {

                console.log(e);

                notificacao.erro({errorId: "trocarPlano", transacao: cdTransacao, message: "Ooops!", description:"Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !"})

            }

        }

    }

    const confirmCancela = () => {
        setExibeOrientacoesCanc(true);
    }

    const logAuditoria = () => {
        const payload = {
            cdPessoaUsr: props.userSession.cdPessoaUsr,
            cdPessoaEmp: props.userSession.cdPessoaEmpAtual,
            txIdApp: "iy2b-portal"
        };
        postWS({
            sistema: "smk",
            projeto: "msmkpiy2b",
            acaoWebAExecutar: "processarNegocio",
            acaoAExecutar: "finalizarTodos"
        }, payload).then(resposta => {
            resposta.mensagens.forEach(msg => {
                console.log(msg);
            });
        }, error => {
            console.log(error);
        });
    }

    const onLogout = async () => {

        props.closeAllTransacoes().then(r => {
            logAuditoria();
        });

        try {

            await efetuaLogout();

            props.clearSession();

        } catch (error) {

            throw error;

        }

    };

    const handleOkOrientCanc = async () => {

        // sgr.mcanccontratacaobuild
        // cancelarPlano

        try {

            setExibeOrientacoesCanc(false);

            const payload = {
                acaoAExecutar: "cancelarPlano",
                cdPessoaEmp: props.userSession.planoContratado.cdPessoaEmpMaster,
                cdPessoaUsr: props.userSession.cdPessoaUsr
            }

            const resposta = await postJSON({
                sistema: "sgr",
                projeto: "mcanccontratacaobuild",
                acaoWebAExecutar: "processarnegocio"
            }, payload, loadingArea);

            resposta.mensagens.forEach(msg => {

                if(msg.id === "cancelado") {

                    notificacao.sucesso({transacao: cdTransacao, message: "Cancelado!", description:msg.mensagem})

                } else {

                    console.log(msg);

                }

            });

            await onLogout();

            return true;

        } catch (e) {

            console.log(e);

            notificacao.erro({errorId: "cancelarPlano", transacao: cdTransacao, message: "Ooops!", description:"Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !"})

            return false;

        }

    }

    const handleCancelOrientCanc = () => {
        setExibeOrientacoesCanc(false);
    }

    const { nrMaxEmps, nrTotEmps, nrMaxUsrs, nrTotUsrs } = props.userSession.planoContratado;

    const dataUtilEmpresas = [
        {
            title : 'Nro. empresas' ,
            ranges: [nrMaxEmps],
            cadastrados:[nrTotEmps],
            limite: nrMaxEmps
        },
    ];

    const calcMeasureColor = (max, tot) => {

        if(max === -1) return "#8CFF6C";

        const pe = tot / max ;

        if(pe < 0.45) {
            return "#8CFF6C";
        }
        if(pe < 0.80) {
            return "#ffbb6c";
        }

        return "#FF6C82";

    }

    const configUtilEmpresas = {
        data: dataUtilEmpresas,
        measureField: 'cadastrados',
        rangeField: 'ranges',
        targetField: 'limite',
        xField: 'title',
        color: {
            range: ['#DFDFDF'],
            measure: calcMeasureColor(nrMaxEmps, nrTotEmps),
            target: '#E00000',
        },
        xAxis: {line: null},
        yAxis: false,
        label: {
            measure: {
                position: (nrMaxEmps==nrTotEmps)?'middle':'right',
            },
            target: {
                offsetX: -5
            }
        },
        autoFit: false,
        height: 45
    };

    const dataUtilUsuarios = [
        {
            title : 'Nro. usuários' ,
            ranges: [nrMaxUsrs],
            cadastrados:[nrTotUsrs],
            limite: nrMaxUsrs
        },
    ];

    const configUtilUsuarios = {
        data: dataUtilUsuarios,
        measureField: 'cadastrados',
        rangeField: 'ranges',
        targetField: 'limite',
        xField: 'title',
        color: {
            range: ['#DFDFDF'],
            measure: calcMeasureColor(nrMaxUsrs, nrTotUsrs),
            target: '#E00000',
        },
        xAxis: {line: null},
        yAxis: false,
        label: {
            measure: {
                position: (nrMaxUsrs===nrTotUsrs)?'middle':'right',
            },
            target: {
                offsetX: -5
            }
        },
        autoFit: false,
        height: 45,
    };


    return (
        <>
            <ProForm
                formRef = { formRef }
                submitter = {{
                    render : () =>
                        <div className={"iy2b-footer-toolbar"}
                             style={{
                                 width: availableWidth()
                             }}
                        >
                            <Popconfirm
                                title = "Confirma a alteração do plano de assinatura contratado na plataforma ?"
                                okText = "Sim, alterar !"
                                cancelText="Não"
                                onConfirm={onFinish}
                                icon={CUSTOM.QUESTION_CIRCLE_ICON_RED}
                            >
                                <Button type={"primary"}>Confirmar alteração do plano</Button>
                            </Popconfirm>
                        </div>
                }}
            >
                <Row gutter={[8,16]}>
                    <Col id={"form"} xs={24} md={16} lg={18}>
                        <Row gutter={[8,16]} style={{minHeight: 170}}>
                            <Col xs={12} md={8} lg={6} >
                                <TooltipOnIcon label={"Seu plano atual é"} title={"Seu plano contratado. Para cancelar uma nova seleção, basta clicar novamente no seu plano"} placement={"bottom"}/>
                            </Col>
                            <Col {...colSpanPlanos} className={"card-plano-atual"}>
                                {renderTipoPessoa(planoOriginal)}
                                {renderCardPlano(planoOriginal)}
                            </Col>
                        </Row>
                        <Row gutter={[16, 24]}>
                            <Col xs={24} md={8} lg={6} >
                                <TooltipOnIcon label={"Escolha um novo plano"} title={"Selecione um novo plano para contratar. Depois, confirme a alteração."} placement={"bottom"}/>
                            </Col>
                        </Row>
                        <Row gutter={[16, 24]} style={{marginTop: 10}}>
                            <Col span={24}>
                                {renderPlanosDisponiveis()}
                            </Col>
                        </Row>
                    </Col>
                    <Col id={"menu"} xs={24} md={8} lg={6}>
                        <div id={"boxCotasContratadas"}>
                            <TooltipOnIcon label={"Utilização das cotas contratadas"} title={"Acompanhe a utilização das suas cotas contradas no seu plano."} placement={"top"}/>
                            <Bullet {...configUtilEmpresas} />
                            <Bullet {...configUtilUsuarios} />
                        </div>
                        <Button id="btnCancelarPlano" type={"primary"} danger onClick={confirmCancela}>Quero cancelar o meu plano</Button>
                    </Col>
                </Row>
            </ProForm>
            <IY2BModal title={"Orientações para cancelamento da assinatura"}
                       visible={exibeOrientacoesCanc} onCancel={handleCancelOrientCanc}
                       className={"modalOrientacoesCanc"}
                       footer={[
                           <Button key="back" type={"primary"} onClick={handleCancelOrientCanc}>
                               Não quero cancelar
                           </Button>
                       ]}
            >
                    <IY2BModalBody>
                        <>
                            <p>Atenção !</p>
                            <p>O cancelamento do seu plano de assinatura irá efetuar a exclusão das suas informações da plataforma.
                                Após isto a plataforma não terá mais nenhuma informação sua.
                                O seu acesso a plataforma será imediatamente cancelado.</p>
                            <div>
                                <Button type={"primary"} danger onClick={handleOkOrientCanc}>Sim, estou ciente e desejo cancelar !</Button>
                            </div>
                        </>
                    </IY2BModalBody>
            </IY2BModal>
            <PopUpIFrame  name={"popUpMaisInfo"}
                          exibePopUp={exibeMaisInfo}
                          url={urlMaisInfo}
                          onRequestClose={() => setExibeMaisInfo(false)}
            />
        </>
    );

}

const mapStateToProps = function( { userSession, dataProvider, suite } ) {

    return {
        userSession: userSession,
        dataProvider: dataProvider,
        suite: suite
    };

};

const mapDispatchToProps = dispatch => {
    return {
        closeAllTransacoes: async () => {
            return dispatch( closeAll() );
        },
        clearSession: () => {
            return dispatch( clearSession() );
        },
        atualizarInfoComplUsr: () => {
            return dispatch ( atualizarInfoComplUsr() );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MeuPlano);

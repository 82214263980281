//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useRef, useState} from "react";
import {
    notificacaoErroRequest,
    notificacaoErroValidForm, PADRAO_MARGEM_POPUP,
    useConteudoAlterado,
    useGridWidth,
    useModoExecucao, useStateRef,
    useWindowSize
} from "../../lib/iy2b-react";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {cnpjValido, cpfValido, ObjectUtils} from "../../lib/iy2b-javascript";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";
import {Button, Col, Row, Space} from "antd";
import IY2BReactModal from "../IY2BReactModal";
import  {ProForm, ProFormText, ProFormTextArea} from "@ant-design/pro-components";
import IY2BProFormMasked from "../IY2BProFormMasked";
import IY2BHiddenRow from "../IY2BHiddenRow";
import IY2BProFormHidden from "../IY2BProFormHidden";
import ComboBox from "../proform/ComboBox/comboBox";
import {postJSON} from "../../lib/RequestAPI";
import {notificacao} from "../../lib/Notificacao";

const NovoChecklist = ( props ) => {

    const { exibePopUp, onRequestClose, salvar, dataProvider, pessoa, cdTransacao=0 } = props;

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 10 : windowSize.widthMargin(PADRAO_MARGEM_POPUP.XL);
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = windowSize.heightMargin(PADRAO_MARGEM_POPUP.LG);

    const modalRefApi = useRef();
    const formRef = useRef();

    const forceUpdate = useForceUpdate();

    const modoExecucao = useModoExecucao("INC");
    const conteudoAlterado = useConteudoAlterado();

    const cmbTipoChecklistRefApi = useRef();
    const cmbModeloChecklistRefApi = useRef();

    const [dsModeloChecklist, setDsModeloChecklist, refDsModeloChecklist] = useStateRef([]);

    useEffect(() => {

        if(exibePopUp === true) {

            setTimeout(() => {
                novoRegistro();
            }, 0);

        }

        return () => {

        };

    }, [props.exibePopUp]);

    const controleExibicao = (registro) => {


    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();

            cmbTipoChecklistRefApi.current.focus();

        }, 1);

    }

    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        console.log(ffValues);

        const attrDaTela = [ "noChecklist" ];

        const registro = {
            ...ObjectUtils.valuesFrom(ffValues, attrDaTela),
            cdTipoChecklist: ffValues.cmbTipoChecklist.value,
            cdModeloChecklist: ffValues.cmbModeloChecklist.value,
        };

        console.log(registro);

        return registro;

    }

    const novoRegistro = () => {

        formRef?.current?.resetFields();

        controleExibicao ( );

        modoExecucao.setModoExecInclusao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const validaTela = async (params) => {

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        return true;

    };

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return;

            if(conteudoAlterado.isConteudoAlterado() === false) {
                notificacao.aviso({message:"Sem alteração", description:"Nenhuma informação alterada para ser salva !"})
                return;
            }

            const registro = getRegistroDaTela();

            const respSalvar = await salvar (registro);

            if(respSalvar === false) return;

            onRequestClose();

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e, cdTransacao);

        }

    }

    const pesquisarModelosDoTipo = async (value) => {

        const payload = {
            "acaoAExecutar": "pesquisaModelosDoTipo",
            cdTipoChecklist: value
        }

        try {

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sgr",
                projeto: "mmodelochecklist"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "listaModeloChecklist") {

                    setDsModeloChecklist(msg.lista.map(item => {
                        return {
                            value: item.cdModeloChecklist,
                            label: item.noModeloChecklist,
                            data: { ...item }
                        }
                    }));

                    if(msg.lista.length === 1) {

                        const modeloChecklist = refDsModeloChecklist.current[0];

                        formRef.current?.setFieldsValue({
                            cmbModeloChecklist: modeloChecklist
                        });

                        cmbModeloChecklist_OnChange ( modeloChecklist );

                    }

                } else {

                    console.log(msg);

                }

            });

        } catch (e) {

            setDsModeloChecklist( [] );

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

    };

    const cmbTipoChecklist_OnChange = (value) => {

        if(value.hasOwnProperty("value") === true) {

            pesquisarModelosDoTipo(value.value);

        } else {

            setDsModeloChecklist( [] );

        }

    }

    const cmbModeloChecklist_OnChange = (value) => {

        const newValues = {
            noChecklist: ""
        };

        if(value.hasOwnProperty("value") === true) {
            newValues.noChecklist = value.label;
        }

        formRef.current?.setFieldsValue(newValues);

    }

    const nrCGCCICPatterns = [{
        pattern: "000.000.000-00",
        length: 11, // sem mascara
        validator: async (value) => {
            if(value.length === 11) {
                if(cpfValido(value) === false) {
                    return {valido:false, message:"CPF informado é inválido !"};
                }
            }
            return {valido: true, message: "ok" };
        }
    }, {
        pattern: "00.000.000/0000-00",
        length: 14, // sem mascara
        validator: async (value) => {
            if(value.length === 14) {
                if(cnpjValido(value) === false) {
                    return {valido:false, message:"CNPJ informado é inválido !"};
                }
            }
            return {valido: true, message: "ok" };
        }
    }];

    const popupTitle = modoExecucao.isInclusao() ? "Novo checklist" : "Alteração do checklist";

    const ffValues = formRef?.current?.getFieldsValue();

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={windowSize.heightMargin(PADRAO_MARGEM_POPUP.XXL)}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"novo-checklist-pessoa"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-novo-checklist-pessoa"}>
                    <ProForm
                        formRef = { formRef }
                        isKeyPressSubmit = {true}
                        initialValues={{
                            nrCGCCIC: pessoa.nrCGCCIC,
                            noPessoa: pessoa.noPessoa,
                        }}
                        onFinish = { async ( ) => {
                            await doSalvar();
                        }}
                        submitter={{
                            // Completely customize the entire area
                            render : ( props , doms ) => {
                                return null;
                            }
                        }}
                        onValuesChange={async (changeValues, allValues) => {

                            if(changeValues.hasOwnProperty("cmbTipoChecklist")) cmbTipoChecklist_OnChange(changeValues["cmbTipoChecklist"]);
                            if(changeValues.hasOwnProperty("cmbModeloChecklist")) cmbModeloChecklist_OnChange(changeValues["cmbModeloChecklist"]);

                        }}
                        onFieldsChange = {(changedFields, allFields) => {
                            conteudoAlterado.updateConteudoAlterado(changedFields);
                        }}
                    >
                        <div className={"pro-form-group-spacer"}>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={24} md={10} lg={8}>
                                        <IY2BProFormMasked
                                            width={"md"}
                                            label={"CNPJ / CPF"}
                                            name="nrCGCCIC"
                                            tooltip = "CNPJ ou CPF do credor"
                                            patterns={nrCGCCICPatterns}
                                            disabled
                                        />
                                    </Col>
                                    <Col xs={24} md={14} lg={16}>
                                        <ProFormText
                                            width={"xl"}
                                            name = "noPessoa"
                                            label = "Razão social / Nome"
                                            tooltip = "A razão social ou nome da pessoa"
                                            placeholder = "Digite parte da razão social ou apelido"
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Group>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={24} md={12}>
                                        <ComboBox
                                            width={"xl"}
                                            formRef = { formRef }
                                            api = { cmbTipoChecklistRefApi }
                                            name = "cmbTipoChecklist"
                                            label = "Tipo de checklist"
                                            tooltip = "Selecione o tipo de checklist"
                                            placeholder={"Selecione o tipo de checklist"}
                                            rules={[
                                                {required: true, message: 'Informe o tipo de checklist'},
                                            ]}
                                            options={dataProvider.dpTipoChecklist}
                                        />
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <ComboBox
                                            width={"xl"}
                                            formRef = { formRef }
                                            api = { cmbModeloChecklistRefApi }
                                            name = "cmbModeloChecklist"
                                            label = "Modelo de checklist"
                                            tooltip = "Selecione o modelo de checklist"
                                            placeholder={"Selecione o modelo de checklist"}
                                            rules={[
                                                {required: true, message: 'Informe o modelo de checklist'},
                                            ]}
                                            options={refDsModeloChecklist.current}
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Group>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col span={24}>
                                        <ProFormTextArea
                                            width={"xl"}
                                            name = "noChecklist"
                                            label = "Nome"
                                            tooltip = "Nome do checklist"
                                            placeholder = "Digite o nome do checklist"
                                            rules={[
                                                {required: true, message: 'Informe o nome'},
                                                {message:"No máximo 200 caracteres", max:200}
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Group>
                            <IY2BHiddenRow>
                                <IY2BProFormHidden name={"x"}/>
                            </IY2BHiddenRow>
                        </div>
                    </ProForm>
                </div>
            }
            actions={
                <Space style={{
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <Space>
                        &nbsp;
                    </Space>
                    <Space>
                        &nbsp;
                        <Button type={"primary"} className={"salvar"} onClick={async () => await doSalvar()}>
                            Salvar
                        </Button>
                    </Space>
                </Space>
            }
        />
    );

}

export default NovoChecklist;

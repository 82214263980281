import React from "react";
import {Button, Tooltip} from "antd";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {CUSTOM} from "../../assets/iconografia";

const iconSquare = CUSTOM.SQUARE_ICON;
const iconCheckSquare = CUSTOM.CHECK_SQUARE_ICON_GREEN;

const BotaoCheckBox = (props) => {

    const forceUpdate = useForceUpdate();

    const { placement="rightBottom", registro, refreshTable } = props;

    if(registro.marcado === null || registro.marcado === undefined) registro.marcado = false;

    const tooltip = (registro.marcado === true) ? "Desmarcar esta linha" : "Marcar esta linha"

    const iconCheckRecord = (registro.marcado===true)?iconCheckSquare:iconSquare;

    return (
        <Tooltip title={tooltip} placement={placement}>
            <Button type="text" icon={iconCheckRecord}
                    onClick={() => {
                        registro.marcado = ! registro.marcado;
                        if(refreshTable !== undefined) {
                            refreshTable();
                        } else {
                            forceUpdate ();
                        }
                    }}
            />
        </Tooltip>
    );

}

export default BotaoCheckBox;

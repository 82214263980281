//v5 import "antd/dist/antd.css";
import '../../../assets/css/tema.scss';
import './style.scss';

import React, {useState, useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import {Drawer, Layout, notification} from 'antd';
import axios from "axios";
import Iframe from "react-iframe";
import {useHistory} from "react-router-dom";

import HeaderWS from "./HeaderWS";
import ContainerContent from "../../ContainerContent";
import {useStateRef, useWindowSize} from "../../../lib/iy2b-react";
import {closeAll, setActive} from "../../../reducer/transacaoManager";
import {desligaDebug, setEmpresaAtual} from "../../../reducer/suite";
import {clearSession} from "../../../reducer/userSession";
import {efetuaLogout} from "../../../lib/LoginAPI";
import eventBus from "../../../lib/eventBus";
import SiderChecklist from "../../SiderChecklist/siderChecklist";
import {campoInformado} from "../../../lib/iy2b-javascript";
import Apresentacao from "../../Apresentacao/apresentacao";
import {WORKSPACE_LOADING_INDICATOR_AREA, WorkspaceContext} from "../../Workspace/workspace";
import {notificacao} from "../../../lib/Notificacao";

import WorkspaceLoadingIndicator from "../../../componentes/LoadingIndicator/WorkspaceLoadingIndicator";

const verificaVersaoAtual = async () => {

    const { protocol, host, port } = window.location;

    const urlPublica = (process.env.PUBLIC_URL===".") ? "" : process.env.PUBLIC_URL;

    const agora = new Date();

    const urlVersaoAtual = protocol + "//" + host + urlPublica + "/versaoAtual.json?lm=" + agora.getTime();

    try {

        // console.log(urlVersaoAtual);

        const response = await axios.get(urlVersaoAtual,{ timeout: 1024*60 });

        return response.data.id;

    } catch (e) {

        console.log(e);

        return 0;

    }

}

const testaVersaoAtual = async () => {

    const versaoServidor = await verificaVersaoAtual();

    const versaoEmExec = parseInt(process.env.REACT_APP_VERSAO_ATUAL);

    console.log(versaoEmExec, versaoServidor);

    if(versaoServidor > versaoEmExec) {
        notificacao.aviso({message:"Existe uma versão mais recente da plataforma !", description:"Por favor, limpe o cache do seu navegador e acesse novamente a plataforma para utilizar a versão mais recente."});
    }

}

const Workspace = ( props ) => {

    const history = useHistory();

    const windowSize = useWindowSize();

    const refHeaderWSApi = useRef();

    const [rodaExecucaoAutomatica, setRodaExecucaoAutomatica] = useState(true);

    const [checkList, setCheckList] = useState(null);

    useEffect(() => {

        console.log("Workspace render inicial concluido");

        if(rodaExecucaoAutomatica === true) {

            setRodaExecucaoAutomatica(false);

            const callbackExecAutom = () => {
                if(process.env.NODE_ENV === 'development') {
                    // sem isto ???
                    props.setActive(process.env.REACT_APP_TRANSACAODEFAULT);
                } else {
                    console.log("execucao automatica ", props.appConfig.cdTransacaoExecAutom);
                    props.setActive(props.appConfig.cdTransacaoExecAutom);
                }
            }

            eventBus.on("smk/trnManager/INICIALIZA_TRANSACOES", callbackExecAutom);

        }

        eventBus.on("logout/masterDelegated", () => {

            onLogout().then(resultado => {

                notificacao.sucesso({message: "Sucesso", description:"Será necessário fazer um novo login na plataforma."});

            });

            notificacao.sucesso({message: "Sucesso", description:"Você foi desconectado, pois não possui mais acesso MASTER."});

        });

        let unauthorizedCount = 0;

        const cbUnauthorized = () => {

            unauthorizedCount ++;

            if(unauthorizedCount === 1) {

                eventBus.remove("requestAPI/unauthorized", realCbUnauthorized);

                notificacao.erro({message:"Sessão expirada !", description:"A sua sessão na plataforma expirou. Por favor faça o login novamente."});

                props.closeAllTransacoes();

                props.clearSession();

                refHeaderWSApi.current.clearSession();

                history.push('/login?email=' + props.userSession.noLogin.toLowerCase().trim());

            } else {

                console.log("unauthorizedCount", unauthorizedCount);

            }

        }

        const realCbUnauthorized = eventBus.on("requestAPI/unauthorized", cbUnauthorized);

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1);

        testaVersaoAtual();

        setInterval(() => {

            testaVersaoAtual();

        }, 1000 * 60 * 2);

        return () => {
        };

    }, []);

    console.log("windowSize " + windowSize.width + "x" + windowSize.height);

    const onEmpresaChange = (empresa) => {

        props.setEmpresaAtual(empresa, props.userSession);

        props.closeAllTransacoes();

        setVisibleWizard ( false);

        setCheckList(null);

    };

    const onLogout = async () => {

        props.closeAllTransacoes();

        setVisibleWizard ( false);

        setCheckList(null);

        try {

            await efetuaLogout();

            await props.desligaDebug();

            props.clearSession();

            refHeaderWSApi.current.clearSession();

            return true;

        } catch (error) {

            throw error;

        }

    };

    const onVerCheckList = (checklist) => {
        setCheckList(checklist);
    }

    const workspaceClassname = ["workspace"];

    if(props.suite.debugHabilitado === true) {
        workspaceClassname.push("debug-habilitado");
    }

    const [visibleApresentacao, setVisibleApresentacao] = useState(false);

    const onIniciarApresentacao = () => {
        console.log("iniciar Apresentacao");
        props.closeAllTransacoes();
        setVisibleWizard ( false);
        setCheckList(null);
        setVisibleApresentacao(true);
    }

    const exibeAjuda = (evt, pane) => {

        evt?.preventDefault();

        if(campoInformado(pane.noHelp)) {
            setPaneToHelp ( pane );
            setVisibleAjuda(true);
        }

    }

    const [paneToHelp, setPaneToHelp] = useState (null);
    const [visibleAjuda, setVisibleAjuda] = useState(false);

    const onCloseAjuda = () => {
        setVisibleAjuda(false);
    }

    const drawerWidth = windowSize.width >= 740 ? (windowSize.width / 2) : 370;
    const iFrameHeight = windowSize.height - 60;

    const onExibeApresentacaoAcao = (acao) => {

        setTimeout(() => {

            if(acao === "exibeDropdownUsuario") {
                refHeaderWSApi?.current?.exibeDropdownUsuario();
            } else if(acao === "escondeDropdownUsuario") {
                refHeaderWSApi?.current?.escondeDropdownUsuario();
            } else if(acao === "exibeDropdownChecklist") {
                refHeaderWSApi?.current?.exibeDropdownChecklist();
            } else if(acao === "escondeDropdownChecklist") {
                refHeaderWSApi?.current?.escondeDropdownChecklist();
            } else if(acao === "exibeMenuTransacao") {
                refHeaderWSApi?.current?.exibeMenuTransacao();
            } else if(acao === "escondeMenuTransacao") {
                refHeaderWSApi?.current?.escondeMenuTransacao();
            }

        }, 1);

    }

    const [visibleWizard, setVisibleWizard] = useState(false);
    const [opcoesWizard, setOpcoesWizard, refOpcoesWizard] = useStateRef([]);

    const [mapWizard, setMapWizard, refMapWizard] = useStateRef({cdTransacao: 0, exibeMyWizard: null});

    const habilitaWizard = (cdTransacao, opcoes, exibeMyWizard) => {
        setOpcoesWizard ( opcoes );
        setMapWizard({cdTransacao: cdTransacao, exibeMyWizard: exibeMyWizard});
        setVisibleWizard ( true );
    }

    const desabilitaWizard = (cdTransacao) => {
        if(refMapWizard.current.cdTransacao === cdTransacao) {
            setMapWizard({cdTransacao: 0, exibeMyWizard: null});
            setOpcoesWizard ( [] );
            setVisibleWizard ( false );
        }
    }

    const onClickWizard = async (option) => {
        if(campoInformado(refMapWizard.current.exibeMyWizard) === true) {
            refMapWizard.current.exibeMyWizard(option);
        } else {
            notificacao.aviso({message:"Atenção", description:"Este assistente está em desenvolvimento!"});
        }
    }

    const contextValue = {
        habilitaHelp: (pane) => { },
        desabilitaHelp: () => { },
        showNotificacoes: () => { },
        getMenuLateralWidth: () => 0,
        availableWidth: () => (campoInformado(windowSize?.width) === true) ? windowSize.width : 0,
    };

    const mainHeight = (campoInformado(windowSize?.height) === true) ? windowSize.height : 0;
    const bodyHeight = (campoInformado(windowSize?.height) === true) ? (windowSize.height - 66) : 0;

    return (
        <div id="mainWorkspace" className={workspaceClassname.toClassName()}>
            <WorkspaceContext.Provider value={contextValue}>
                <Layout id={"mainLayoutWS"}
                    style={{
                        minHeight: mainHeight,
                        height: mainHeight
                    }}
                >
                    {/*
                    <AdPopUp dsPeca={props.suite.listaPeca} tipo={"inicial"}  userSession={props.userSession}  />
                    */}
                    <WorkspaceLoadingIndicator width={windowSize.width} height={windowSize.height} area={WORKSPACE_LOADING_INDICATOR_AREA}/>
                    <HeaderWS
                        api={refHeaderWSApi}
                        onEmpresaChange={onEmpresaChange}
                        onVerCheckList={onVerCheckList}
                        onLogout={onLogout}
                        onIniciarApresentacao={onIniciarApresentacao}
                        habilitaWizard={visibleWizard}
                        opcoesWizard={refOpcoesWizard.current}
                        onClickWizard={onClickWizard}
                    />
                    <Layout id={"contentLayout"}
                            style={{
                                minHeight: bodyHeight,
                                height: bodyHeight
                            }}
                    >
                        <SiderChecklist checkList={checkList} onRequestClose={() => setCheckList(null)}  onExibeAjuda={exibeAjuda}/>
                        <ContainerContent checkList={checkList} onExibeAjuda={exibeAjuda}
                                          onHabilitaWizard={habilitaWizard}
                                          onDesabilitaWizard={desabilitaWizard}
                        />
                    </Layout>
                </Layout>
                <Apresentacao
                    onRequestClose={() => setVisibleApresentacao(false)}
                    exibir={visibleApresentacao}
                    onExibeAcao={(acao) => {
                        onExibeApresentacaoAcao(acao);
                    }}
                />
                <Drawer
                    title={paneToHelp?.title}
                    placement="right"
                    size={"large"}
                    onClose={onCloseAjuda}
                    open={visibleAjuda}
                    width={drawerWidth}
                    className={"help-drawer"}
                >
                    <div>
                        <Iframe url={paneToHelp?.noHelp}
                                width={drawerWidth + "px"}
                                height={iFrameHeight + "px"}
                                id={"iframe-" + paneToHelp?.key}
                                className={"iframe-help-trn"}
                        />
                    </div>
                </Drawer>
            </WorkspaceContext.Provider>
        </div>
    );

};

const mapStateToProps = function( { userSession, dataProvider, suite, appConfig } ) {

    return {
        userSession: userSession,
        dataProvider: dataProvider,
        suite: suite,
        appConfig: appConfig
    };

};

const mapDispatchToProps = dispatch => {
    return {
        setEmpresaAtual: (empresa, userSession) => {
            return dispatch( setEmpresaAtual(empresa, userSession) );
        },
        closeAllTransacoes: () => {
            return dispatch( closeAll() );
        },
        setActive: (key) => {
            return dispatch( setActive(key) );
        },
        clearSession: () => {
            return dispatch( clearSession() );
        },
        desligaDebug: () => {
            return dispatch( desligaDebug() );
        },
    }
};

// export default connect(mapStateToProps, mapDispatchToProps)(Workspace);

const ws = connect(mapStateToProps, mapDispatchToProps)(Workspace);

export default ws;

export {
    ws
};

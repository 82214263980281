//v5 import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-layout/dist/layout.css';
import "./wizAtualizarCertDig.scss";

import ReactModal from "react-modal";
import {Button, notification, Space} from "antd";
import React, {useRef, useState} from "react";

import {notificacaoErroRequest, notificacaoErroValidForm, useWindowSize} from "../../lib/iy2b-react";
import {scrollToTop} from "../../lib/iy2b-javascript";
import {postJSON} from "../../lib/RequestAPI";
import {StepsForm} from '@ant-design/pro-components';
import StepCertDigital from "../../../pages/Contratar/StepCertDig";
import SinglePageLoadingIndicator from "../../componentes/SinglePageLoadingIndicator";
import UploadLoadingIndicator from "../../componentes/UploadLoadingIndicator";
import IY2BProFormWizard from "../../componentes/IY2BProFormWizard";
import {onAfterClose, onAfterOpen} from "../../componentes/IY2BReactModal";
import {notificacao} from "../../lib/Notificacao";

const { StepForm } = StepsForm;

const WizAtualizarCertDig = (props) => {

    const windowSize = useWindowSize();

    const MARGIN_LEFT = 30;
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT * 2));
    const MODAL_CONTENT_TOP = (windowSize.height - ((windowSize.height < 640) ? 572 : 672)) / 2;

    const {exibePopUp, onRequestClose, cdPessoaEmpAtual, nrCGCCICEmpAtual, onCertificadoAtualizado, cdTransacao=0} = props;

    const [infoCertificado, setInfoCertificado] = useState(null);

    const formRef = useRef();

    const closePopUp = () => {
        onRequestClose();
    };

    const onFinishStepFailed = errorInfo => {
        notificacaoErroValidForm(errorInfo);
    };

    const onFinishWizard = async () => {

        scrollToTop("containerStepCertDig");

        if(infoCertificado.nrDocto !== nrCGCCICEmpAtual) {
            notificacao.erro({message: "Falha no documento", description: "Este certificado digital não foi gerado para o documento " + nrCGCCICEmpAtual})
            return false;
        }

        const payload = {
            acaoAExecutar: "incluirNovoCertDig",
            "cdPessoa": cdPessoaEmpAtual,
            "nrSeqCD": infoCertificado.nrSeqCD
        };

        try {

            const resposta = await postJSON({
                sistema: "sgr",
                projeto: "mpessoacertifdigital",
                acaoWebAExecutar: "processarnegocio"
            }, payload);

            notificacao.sucesso({message: "Sucesso", description: "Novo certificado atualizado com sucesso na plataforma com sucesso !"})

            onCertificadoAtualizado();

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

        }

        return true;

    }

    const onInfoCertificado = (value) => {
        setInfoCertificado(value);
    }

    const onCurrentStepChange = (position, currentKey) => {

    };

    const stepFormCertDig =
        <StepForm
            key={"stepCertDig"}
            name = "stepCertDig"
            title = "Certificado digital"
            stepProps = {{
                description: "Enviar o novo certificado digital"
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = {async ( ) => {
                return true;
            }}
        >
            <StepCertDigital appConfig = { props.appConfig }
                             formRef = { formRef }
                             onInfoCertificado = { onInfoCertificado }
                             semAceiteDosTermos = { true }
                             atualizarCertDigital = { true }
            />
        </StepForm>
    ;

    const wizSteps =  <>
        {stepFormCertDig}
    </>;

    console.log("formRef", formRef)

    return (
        <ReactModal
            isOpen={exibePopUp}
            className={"modalContent"}
            overlayClassName={"modalOverlay"}
            bodyOpenClassName={"popUpWizAtualizarCertDig"}
            closeTimeoutMS={500}
            onRequestClose={closePopUp}
            style={{
                content: {
                    left: MARGIN_LEFT + 'px',
                    width: MODAL_CONTENT_WIDTH + "px",
                    top: MODAL_CONTENT_TOP + "px",
                    bottom: MODAL_CONTENT_TOP + "px"
                }
            }}
            onAfterOpen={() => {
                onAfterOpen();
            }}
            onAfterClose={() => {
                onAfterClose();
            }}
        >
            <div className={"modal-header"}>
                <div className={"title"}>Enviar novo certificado digital</div>
                <div className={"top-bar"}>
                    <Button onClick={closePopUp}>
                        Cancelar
                    </Button>
                </div>
            </div>
            <div className={"box-body"}>
                <SinglePageLoadingIndicator />
                <UploadLoadingIndicator area={"uploadCertificado"}/>
                <IY2BProFormWizard
                    formRef = { formRef }
                    onFinishWizard = { onFinishWizard }
                    onCurrentStepChange = { onCurrentStepChange }
                    stepsSize = "small"
                    steps = { wizSteps }
                >
                </IY2BProFormWizard>
            </div>
            <div className={"modal-footer"}>
            </div>
        </ReactModal>
    )

}

export default WizAtualizarCertDig;

//v5 import "antd/dist/antd.css";
import 'react-image-crop/dist/ReactCrop.css';

import '../assets/css/tema.scss';
import '../assets/css/avatarComEdicao.scss';

import {Avatar, Button, Tooltip} from "antd";
import React, {useCallback, useEffect, useRef, useState} from "react";
import ReactModal from 'react-modal';
import {useWindowSize} from "../lib/iy2b-react";
import ReactCrop from 'react-image-crop';
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";


/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 */
const getCroppedImg = async (image, crop, fileName) => {

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL("image/jpeg");
    // return base64Image;

    // As a blob
    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
                blob.name = fileName;
                resolve(blob);
            },
            "image/jpeg",
            1
        );
    });

}

const AvatarComEdicao = props => {

    const {cropWidth=80, cropHeight=80, id="avatarComEdicao"} = props;

    const breaks = useBreakpoint();
    const windowSize = useWindowSize();

    const imgRef = useRef(null);
    const [srcImg, setSrcImg] = useState();

    /*
        width: 310,
        height: 310,
     */
    const [crop, setCrop] = useState({
        circularCrop: true,
        unit: 'px', // '%'
        width: cropWidth,
        height: cropHeight,
        x: 5,
        y: 5
    });
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [exibeDropdown, setExibeDropDown] = useState(false);

    useEffect(() => {

        setSrcImg(props.src);

        return () => {

        };

    }, []);

    const onEditAvatar = (e) => {

        setTooltipVisible(false);

        setExibeDropDown(true);

    }

    const cancelarAlteracao = (e) => {
        setExibeDropDown(false);
    };

    const confirmarAlteracao = async (e) => {

        const newImg = await getCroppedImg(imgRef.current, crop, "avatar");

        setExibeDropDown(false);

        props.onChangeAvatar(newImg);

    };

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setSrcImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onImageLoaded = (e) => {
        imgRef.current = e.currentTarget;
    };

    const DIMENSAO = (breaks.xs===true)?350:480;

    const classNameStatusDropdown = exibeDropdown ? "open" : "close";

    const reactModalClassName = ["modalContent", id];

    return (
        <Tooltip placement="bottom" title={"clique na imagem para editar"} open={tooltipVisible}>
            <div className={["iy2b-avatar-com-edicao-container", classNameStatusDropdown]}>
                <Avatar
                    size={props.size}
                    shape={props.shape}
                    src={props.src}
                    onClick={onEditAvatar}
                    onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}
                >
                </Avatar>
                <div className={"helpAvatarMobile"}><label>Toque na imagem para editar</label></div>
                <ReactModal
                    isOpen={exibeDropdown}
                    className={reactModalClassName.toClassName()}
                    overlayClassName={"modalOverlay"}
                    bodyOpenClassName={"avatarEdicaoDropdownModal"}
                    closeTimeoutMS={500}
                    shouldFocusAfterRender={false}
                    onRequestClose={(e) => setExibeDropDown(false)}
                    style={{
                        content: {
                            border:"solid 1px #DDD",
                            width: DIMENSAO, height: DIMENSAO,
                            left: ((windowSize.width - DIMENSAO) / 2),
                            top:  ((windowSize.height - DIMENSAO) / 2)
                        }
                    }}
                >
                    <div className={"header"}>Recorte ou troque a imagem</div>
                    <div className={"content"}>
                        <div className={"escolherArquivoBox"}>
                            <input type="file" accept="image/*" onChange={onSelectFile} />
                        </div>
                        <ReactCrop crop={crop}
                                   onChange={newCrop => setCrop(newCrop)}
                        >
                            <img
                                src={srcImg}
                                onLoad={onImageLoaded}
                             />
                        </ReactCrop>
                    </div>
                    <div className={"footer"}>
                        <Button onClick={(e) => setExibeDropDown(false)}>Cancelar</Button>
                        <Button type={"primary"} onClick={(e) => confirmarAlteracao(e)}>Confirmar alteração</Button>
                    </div>
                </ReactModal>
            </div>
        </Tooltip>
    );

};


export default AvatarComEdicao;

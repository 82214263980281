import {
    notificacaoErroValidForm,
    PADRAO_MARGEM_POPUP, useConteudoAlterado,
    useGridWidth,
    useWindowSize
} from "../../framework/lib/iy2b-react";
import React, {useRef} from "react";
import {notificacao} from "../../framework/lib/Notificacao";
import {ObjectUtils} from "../../framework/lib/iy2b-javascript";
import moment from "moment/moment";
import {Button, Col, Row, Space} from "antd";
import NavegadorRegistro from "../../framework/componentes/NavegadorRegistro/navegadorRegistro";
import IY2BReactModal from "../../framework/componentes/IY2BReactModal";
import {ProForm, ProFormTextArea} from "@ant-design/pro-components";
import ComboBox from "../../framework/componentes/proform/ComboBox/comboBox";
import IY2BProFormFiller from "../../framework/componentes/proform/IY2BProFormFiller/iy2bProFormFiller";
import FormFinalizarAtendimento from "./formFinalizarAtendimento";

const FinalizarAtendimento = ( props ) => {

    const { exibePopUp, onRequestClose, finalizarAtend, dataProvider, cdTransacao = 0 } = props;

    const { registros } = props;

    const formRef = useRef();

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 0 : windowSize.widthMargin(PADRAO_MARGEM_POPUP.XL);
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = windowSize.heightMargin(PADRAO_MARGEM_POPUP.XL); // 30;
    const MARGIN_BOTTOM = windowSize.heightMargin(PADRAO_MARGEM_POPUP.XXL); // 24;

    const modalRefApi = useRef();

    const popupTitle = "Finalizar o chamado";

    const fiValues = {
    }

    const doSalvar = async () => {
        formRef.current.salvarForm();
    }

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={MARGIN_BOTTOM}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"finalizar-atendimento"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-finalizar-atendimento"}>
                    <FormFinalizarAtendimento cdTransacao={cdTransacao} ref={formRef}
                                              finalizarAtend={finalizarAtend}
                                              fiValues={fiValues}
                                              dataProvider={dataProvider}
                                              onRequestClose={onRequestClose}
                    />
                </div>
            }
            actions={
                <Space style={{
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    &nbsp;
                    <Space>
                        <Button type={"primary"} className={"salvar"} onClick={async () => await doSalvar()}>
                            Finalizar
                        </Button>
                    </Space>
                </Space>
            }
        />
    );

}

export default FinalizarAtendimento;

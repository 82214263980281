import {Form} from "antd";
import InputMasked from "./InputMasked";
import React from "react";
import {ProForm} from '@ant-design/pro-components' ;

const IY2BProFormMasked = (props) => {

    const className = ["iy2b-pro-field", "pro-field"];

    if(props.width) {
        className.push("pro-field-" + props.width);
    }

    return (
        <ProForm.Item
            label = {props.label}
            name = {props.name}
            tooltip = {props.tooltip}
            rules = {props.rules}
        >
            <InputMasked api = {props.api}
                         patterns = {props.patterns}
                         disabled = {props.disabled}
                         className = {className.toClassName()}
            />
        </ProForm.Item>
    );

}

export default IY2BProFormMasked;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';

import "./style.scss";

import {Col} from "antd";

const REIf = ( props ) => {

    const { children, hidden=false, notExists=false, keepWidth=0, minWidth=0, insideRow=false, state=null, hideFor=null, hideForAll=null, containerStyle={} } = props ;

    const { col=false, xs=null, md=null, lg=null } = props ;

    let lHidden = hidden;

    const className = ["iy2b-react-element-if"];

    if(hideFor != null && hideFor != undefined) {
        lHidden = (state[hideFor] === true);
    }

    if(hideForAll != null && hideForAll != null) {
        let all = true;
        hideForAll.forEach(item => {
            if(state[item] === true) {
                // ok
            } else {
                all = false;
            }
        });
        if(all === true) {
            lHidden = true;
        }
    }

    if(lHidden===true) {
        className.push("hidden");
    } else {
        if(insideRow===true) {
            className.push("inside-row");
        }
    }

    const divStyle = { };

    if(keepWidth > 0) {
        divStyle.minWidth = keepWidth;
    } else {
        if(minWidth > 0) {
            divStyle.minWidth = minWidth;
        }
    }

    const divKeepWidth = (keepWidth === 0) ?
        null
        :
        (lHidden === false) ? null :
        <div style={divStyle}>&nbsp;</div>
    ;

    const divMinWidth = (minWidth === 0) ?
        null
        :
        <div style={divStyle}>&nbsp;</div>
    ;

    const colSpan = (col === true) ?
        {
            xs: xs,
            md: md,
            lg: lg,
        }
        :
        null;

    const element = (notExists===true)?
        null
        :
        (notExists==="for" && lHidden===true)?
        null
        :
        (col === true)?
            <Col {...colSpan}>
                <div className={className.toClassName()} style={containerStyle}>
                    {children}
                    {divMinWidth}
                </div>
                {divKeepWidth}
            </Col>
        :
            <>
                <div className={className.toClassName()} style={containerStyle}>
                    {children}
                    {divMinWidth}
                </div>
                {divKeepWidth}
            </>
        ;

    return element;

}

export default REIf;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";
import {
    notificacaoErroRequest, notificacaoErroValidForm, PADRAO_MARGEM_POPUP,
    useConteudoAlterado,
    useGridWidth,
    useModoExecucao,
    useWindowSize
} from "../../lib/iy2b-react";
import React, {useEffect, useRef, useState} from "react";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {campoInformado, ObjectUtils} from "../../lib/iy2b-javascript";
import {postJSON} from "../../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";
import {Button, Col, Popconfirm, Row, Space, Tabs} from "antd";
import IY2BReactModal from "../IY2BReactModal";
import {ProForm, ProFormDatePicker, ProFormText, ProFormTextArea} from "@ant-design/pro-components";
import ComboBox from "../proform/ComboBox/comboBox";
import RowWrapper from "../RowWrapper/rowWrapper";
import IY2BHiddenRow from "../IY2BHiddenRow";
import IY2BProFormHidden from "../IY2BProFormHidden";
import IY2BProFormButton from "../proform/IY2BProFormButton/proFormButton";
import BotaoAcao from "../BotaoAcao/botaoAcao";
import ProFieldWrapper from "../proform/ProFieldWrapper/proFieldWrapper";
import BotaoUpload from "../BotaoUpload/botaoUpload";
import {notificacao} from "../../lib/Notificacao";

const AtualizarItemChecklist = ( props ) => {

    const { exibePopUp, onRequestClose, salvar, excluir, concluirItem, cdPessoaEmpAtual, cdChecklist, cdTransacao=0 } = props;

    const { registros } = props;

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 10 : windowSize.widthMargin(PADRAO_MARGEM_POPUP.XL);
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = windowSize.heightMargin(PADRAO_MARGEM_POPUP.XL);

    console.log("AtualizarItemChecklist", MARGIN_LEFT, MODAL_CONTENT_WIDTH);

    const modalRefApi = useRef();
    const formRef = useRef();

    const forceUpdate = useForceUpdate();

    const modoExecucao = useModoExecucao("ALT");
    const conteudoAlterado = useConteudoAlterado();

    const cmbTarefaChecklistRefApi = useRef();
    const cmbTipoDoctoGEDRefApi = useRef();

    const [dpTarefaChecklist, setDpTarefaChecklist] = useState([]);
    const [dpTipoDoctoGED, setDpTipoDoctoGED] = useState([]);

    useEffect(() => {

        if(campoInformado(registros)) {

            if(exibePopUp) {

                if(registros.length > 0) {

                    pesquisarPk(registros[0]);

                }

            }

        }

        return () => {

        };

    }, [props.registros]);

    const pesquisarPk = async (registro) => {

        try {

            const payload = {
                acaoAExecutar: "completaInicializa",
                cdPessoaEmp: registro.cdPessoaEmp,
                cdChecklist: registro.cdChecklist,
                nrSeq: registro.nrSeq
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sgr",
                projeto: "matuitemchecklist"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            const registroNaTela = { };

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    registroNaTela.item = msg.modeloPadrao;

                } else if (msg.id === "listaArquivoVO") {

                    registroNaTela.arquivos = msg.lista;

                }

            });

            setRegistroNaTela(registroNaTela);

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const setRegistroNaTela = async (registro) => {

        const attrDaTela = [
            "cdChecklist",
            "cdModeloChecklist",
            "cdPessoaEmp",
            "cdTarefaChecklist",
            "cdTipoDoctoGED",
            "chDocto",
            "chWorkflowRenov",
            "flgNA",
            "flgRenova",
            "noItem",
            "noTarefaChecklist",
            "noTipoDoctoGED",
            "nrOrdem",
            "nrSeq",
            "nrSeqItemModelo",
            "nrSeqPai",
            "txObs",
        ];

        const registroDaTela = {
            ... ObjectUtils.valuesFrom(registro.item, attrDaTela),
            dtCad: registro.item.dtCad.toMoment().toScreen(),
            dtChecklist: registro.item.dtChecklist.toMoment().toScreen(),
            dtInicioRenov: registro.item.dtInicioRenov.toMoment().toScreen(),
            dtUltRenovacao: registro.item.dtUltRenovacao.toMoment().toScreen(),
            dtValidade: registro.item.dtValidade.toMoment().toScreen(),
        };

        if(registro.arquivos.length > 0) {
            const arquivo = registro.arquivos[0];
            registroDaTela.urlArquivo = arquivo.urlArquivo;
            registroDaTela.nomeArquivo = arquivo.nomeArquivo;
        }

        if(campoInformado(registro.item.cdTarefaChecklist)) {
            registroDaTela.cmbTarefaChecklist = {
                value: registro.item.cdTarefaChecklist,
                label: registro.item.noTarefaChecklist
            }
        }
        if(campoInformado(registro.item.cdTipoDoctoGED)) {
            registroDaTela.cmbTipoDoctoGED = {
                value: registro.item.cdTipoDoctoGED,
                label: registro.item.noTipoDoctoGED
            }
        }

        console.log(registroDaTela);

        formRef.current?.setFieldsValue( registroDaTela );

        forceUpdate();

        controleExibicao ( registroDaTela );

        modoExecucao.setModoExecAlteracao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const controleExibicao = (registro) => {

    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();


        }, 1);

    }


    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        let {dtValidade, dtChecklist} = ffValues;

        if(dtChecklist === null) dtChecklist = window.dataZerada;

        if(dtValidade === null) dtValidade = window.dataZerada;

    // "nrSeq":1,"noItem":"Tarefa credor 1","chDocto":null,"dtValidade":"1901/01/01","dtChecklist":"1901/01/01","txObs":"x12"

        const attrDaTela = [
            "nrSeq",
            "noItem",
            "cdTarefaChecklist",
            "cdTipoDoctoGED",
            "chDocto",
            "txObs",
        ];

        const registro = {
            cdPessoaEmp: cdPessoaEmpAtual,
            cdChecklist: cdChecklist,
            ...ObjectUtils.valuesFrom(ffValues, attrDaTela),
            dtValidade: dtValidade.format("YYYY/MM/DD"),
            dtChecklist: dtChecklist.format("YYYY/MM/DD"),
        };

        return registro;

    }

    const validaTela = async (params) => {

        const {exibeMens = true} = params || { };

        let resultado = true;

        const ffValues = formRef.current?.getFieldsValue();

        return resultado;

    };

    const doExcluir = async () => {

        const registro = getRegistroDaTela();

        await excluir ( [ registro ] );

        onRequestClose();

    }

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return;

            if(conteudoAlterado.isConteudoAlterado() === false) {
                notificacao.aviso({message:"Sem alteração", description:"Nenhuma informação alterada para ser salva !"})
                return;
            }

            const registro = getRegistroDaTela();

            const respSalvar = await salvar (registro);

            if(respSalvar === false) return;

            onRequestClose();

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e, cdTransacao);

        }

    }

    const verAnexo = () => {

        const ffValues = formRef.current?.getFieldsValue();

        console.log(ffValues.urlArquivo);

        window.open(ffValues.urlArquivo,'_blank');

    }

    const habilitarReEnvio = () => {

        formRef.current?.setFieldsValue( {
            chDocto: null,
            urlArquivo: null,
            nomeArquivo: null
        });

        forceUpdate();

    }

    const ffValues = formRef.current?.getFieldsValue();

    const popupTitle = "Atualizar o item do checklist";

    const RENOVA = (ffValues?.flgRenova === "S");

    const verificaConcluirAcao = () => {

        let exibe = true;

        const ffValues = formRef.current?.getFieldsValue();

        if (ffValues?.cmbTipoDoctoGED !== undefined) {
            if(campoInformado(ffValues?.chDocto) == false) {
                exibe = false;
            }
        }

        if(ffValues?.flgRenova === "S") {
            if(campoInformado(ffValues?.dtValidade) == false) {
                exibe = false;
            }
        }

        if( conteudoAlterado.isConteudoAlterado () ) {
            exibe = false;
        }

        if(campoInformado(ffValues?.dtChecklist)) {
            exibe = false;
        }

        return exibe;

    }

    const exibeConcluirAcao = verificaConcluirAcao();

    const itemJaConcluido = campoInformado(ffValues?.dtChecklist);

    const tarefaInformada = campoInformado(ffValues?.cmbTarefaChecklist);

    const doctoGEDInformado = campoInformado(ffValues?.cmbTipoDoctoGED);

    const urlArquivoInformado = campoInformado(ffValues?.urlArquivo);

    const propsUpload = {
        maxCount: 1,
        showUploadList: false,
        multiple: false,
        requestToFrameworkSmk: false,
        acaoWebAExecutar: "upload",
        sistema: "sgr",
        projeto: "matuitemchecklist",
        acaoAExecutar: "anexarDocto",
        payload: {
            cdPessoaEmp: cdPessoaEmpAtual,
            cdChecklist: cdChecklist,
            nrSeq: ffValues?.nrSeq
        },
        prCmdUpload: ( msg ) => {

            if(msg.id === "modelo") {

                formRef.current?.setFieldsValue({
                    chDocto: msg.modeloPadrao.chDocto
                });

            } else if (msg.id === "listaArquivoVO") {

                formRef.current?.setFieldsValue( msg.lista[0] );

            } else {

                console.log(msg);

            }

        },
        beforeUpload: file => {
            return true;
        },
        onFinish: async (files) => {
            forceUpdate();
        }
    };
    console.log("urlArquivoInformado", urlArquivoInformado, doctoGEDInformado, itemJaConcluido, ((doctoGEDInformado===false) || (itemJaConcluido === true)), (urlArquivoInformado === true))

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={windowSize.heightMargin(PADRAO_MARGEM_POPUP.LG)}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"atualizar-item-checklist"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-editar-cartao-credito"}>
                    <ProForm
                        formRef = { formRef }
                        isKeyPressSubmit = {true}
                        initialValues={{ }}
                        onFinish = { async ( ) => {
                            await doSalvar();
                        }}
                        submitter={{
                            // Completely customize the entire area
                            render : ( props , doms ) => {
                                return null;
                            }
                        }}
                        onValuesChange={async (changeValues, allValues) => {
                        }}
                        onFieldsChange = {(changedFields, allFields) => {
                            conteudoAlterado.updateConteudoAlterado(changedFields);
                        }}
                    >
                        <div className={"pro-form-group-spacer"}>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={24} md={16} lg={18}>
                                        <ProFormTextArea
                                            width={"xl"}
                                            name = "noItem"
                                            label = "Descrição"
                                            tooltip = "Descrição deste item do checklist"
                                            placeholder = "Digite a descrição deste item do checklist"
                                            disabled
                                        />
                                    </Col>
                                    <Col xs={12} md={8} lg={6}>
                                        <ProFormDatePicker
                                            width={"md"}
                                            name="dtChecklist"
                                            label="Concluído em"
                                            tooltip={"Data de conclusão deste item"}
                                            placeholder={"Em execução"}
                                            format={"DD/MM/YYYY"}
                                            showToday={true}
                                            rules={[
                                            ]}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Group>
                            <RowWrapper hidden={tarefaInformada === false || doctoGEDInformado === false}>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={24} md={12} >
                                            <ProFieldWrapper hidden={tarefaInformada === false}>
                                                <ComboBox
                                                    width={"lg"}
                                                    formRef = { formRef }
                                                    api = { cmbTarefaChecklistRefApi }
                                                    name = "cmbTarefaChecklist"
                                                    label = "Tarefa"
                                                    tooltip = "Selecione a tarefa para executar neste item do checklist"
                                                    placeholder={"Selecione a tarefa para executar"}
                                                    options={dpTarefaChecklist}
                                                    disabled
                                                />
                                            </ProFieldWrapper>
                                        </Col>
                                        <Col xs={24} md={12} >
                                            <ProFieldWrapper hidden={doctoGEDInformado === false}>
                                                <ComboBox
                                                    width={"lg"}
                                                    formRef = { formRef }
                                                    api = { cmbTipoDoctoGEDRefApi }
                                                    name = "cmbTipoDoctoGED"
                                                    label = "Tipo de documento"
                                                    tooltip = "Tipo de documento a ser anexado"
                                                    placeholder={"Selecione o tipo de documento"}
                                                    options={dpTipoDoctoGED}
                                                    disabled
                                                />
                                            </ProFieldWrapper>
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                            </RowWrapper>
                            <RowWrapper hidden={(doctoGEDInformado===false) || (itemJaConcluido === false)} >
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={24} md={12} lg={8}>
                                            <ProFormText
                                                width={"lg"}
                                                name = "nomeArquivo"
                                                label = "Arquivo anexado"
                                                tooltip = "Nome do arquivo anexado ao checklist"
                                                placeholder = "Nome do arquivo anexado"
                                                disabled
                                            />
                                        </Col>
                                        <Col xs={24} md={12} lg={8}>
                                            <IY2BProFormButton
                                                width={"lg"}
                                                label={"Visualizar anexo"}
                                                onClick={() => verAnexo()}
                                            />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                            </RowWrapper>
                            <RowWrapper hidden={(doctoGEDInformado===false) || (itemJaConcluido === true)} >
                                <ProForm.Group>
                                    <ProFieldWrapper hidden={(urlArquivoInformado === true) || (doctoGEDInformado===false)}>
                                        <Row gutter={[8, 16]}>
                                            <Col span={24}>
                                                <BotaoUpload
                                                    formRef = { formRef }
                                                    label="Anexar documento"
                                                    name="anexoChecklist"
                                                    title={"Enviar documento para anexar ao checklist"}
                                                    propsUpload={{...propsUpload}}
                                                />
                                            </Col>
                                        </Row>
                                    </ProFieldWrapper>
                                    <ProFieldWrapper hidden={urlArquivoInformado === false}>
                                        <Row gutter={[8, 16]}>
                                            <Col xs={24} md={12} lg={8}>
                                                <ProFormText
                                                    width={"md"}
                                                    name = "nomeArquivo"
                                                    label = "Arquivo anexado"
                                                    tooltip = "Nome do arquivo anexado ao checklist"
                                                    placeholder = "Nome do arquivo anexado"
                                                    disabled
                                                />
                                            </Col>
                                            <Col xs={24} md={12} lg={8}>
                                                <IY2BProFormButton
                                                    width={"lg"}
                                                    label={"Visualizar anexo"}
                                                    onClick={() => verAnexo()}
                                                />
                                            </Col>
                                            <Col xs={24} md={12} lg={8}>
                                                <IY2BProFormButton
                                                                   width={"lg"}
                                                                   label={"Enviar novo documento"}
                                                                   onClick={() => habilitarReEnvio()}
                                                />
                                            </Col>
                                        </Row>
                                    </ProFieldWrapper>
                                </ProForm.Group>
                            </RowWrapper>
                            <RowWrapper hidden={RENOVA === false}>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col span={24}>
                                            <ProFormDatePicker
                                                width={"sm"}
                                                name="dtValidade"
                                                label="Válido até"
                                                tooltip={"Data de validade da execução deste item do checklist"}
                                                placeholder={"Data de validade da execução deste item do checklist"}
                                                format={"DD/MM/YYYY"}
                                                showToday={true}
                                                rules={[
                                                ]}
                                                disabled = {itemJaConcluido === true}
                                            />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                            </RowWrapper>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={24} md={16} lg={18}>
                                        <ProFormTextArea
                                            width={"xl"}
                                            name = "txObs"
                                            label = "Comentários"
                                            tooltip = "Comentários e/ou observações para executar este item"
                                            placeholder = "Digite comentários e/ou observações para executar este item"
                                            rules={[
                                                {message:"No máximo 200 caracteres", max:200}
                                            ]}
                                            disabled = {itemJaConcluido === true}
                                        />
                                    </Col>
                                    <Col xs={24} md={8} lg={6}>
                                        <ProFormText
                                            width={"md"}
                                            name = "nrSeq"
                                            label = "Sequência"
                                            tooltip = "Número de sequência deste item do checklist"
                                            placeholder = "Sequência"
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Group>
                            <IY2BHiddenRow>
                                <IY2BProFormHidden name={"flgRenova"}/>
                                <IY2BProFormHidden name={"chDocto"}/>
                                <IY2BProFormHidden name={"urlArquivo"}/>
                            </IY2BHiddenRow>
                        </div>
                    </ProForm>
                </div>
            }
            actions={
                <Space style={{
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <Space>
                        &nbsp;
                    </Space>
                    <Space>
                        <BotaoAcao
                            label={"Concluir item"}
                            onClick={async () => await concluirItem()}
                            disabled = {exibeConcluirAcao === false}
                            hidden = {exibeConcluirAcao === false}
                        />
                        &nbsp;
                        {itemJaConcluido === false?
                            <Popconfirm
                                title="Confirma a exclusão deste registro?"
                                onConfirm={async () => {
                                    const resultado = await doExcluir();
                                }}
                                okText="Sim, excluir"
                                cancelText="Não"
                            >
                                <Button type={"primary"} danger className={"excluir"}>
                                    Excluir
                                </Button>
                            </Popconfirm>
                            :null}
                        &nbsp;
                        <BotaoAcao
                            label={"Salvar"}
                            type={"primary"}
                            className={"salvar"}
                            onClick={async () => await doSalvar()}
                            disabled = {itemJaConcluido === true}
                            hidden = {itemJaConcluido === true}
                        >
                        </BotaoAcao>
                    </Space>
                </Space>
            }
        />
    );

}

export default AtualizarItemChecklist;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import './style.scss';

import {Button, Dropdown} from "antd";
import React from "react";

import {BARS_ICON_PRIMARY, ELLIPSIS_VERTICAL_ICON_PRIMARY} from "../../assets/iconografia";

const iconBars = BARS_ICON_PRIMARY;
const iconBarsV2 = ELLIPSIS_VERTICAL_ICON_PRIMARY;

const BotaoAcoesDisponiveis = (props) => {

    const { menuAcoes, disabled=true, placement="bottomRight", relative=false, label="Ações disponíveis", iy2bTable=false, iy2bCards=false } = props;

    const text = disabled?"":label;

    const boxClassName = ["box-btn-acoes-disponiveis"];

    const boxStyle = {
        position:"absolute",
        right:"0px",
        top:"0px"
    };

    if(relative === true) {
        boxStyle.position = "relative";
        delete boxStyle.top;
        delete boxStyle.right;
    }

    if(iy2bTable === true) {
        boxClassName.push("inside-iy2b-table");
        boxStyle.position = "relative";
        delete boxStyle.top;
        delete boxStyle.right;
    }

    if(iy2bCards === true) {
        boxClassName.push("inside-iy2b-cards");
        boxStyle.position = "relative";
        delete boxStyle.top;
        delete boxStyle.right;
    }

    /*
    return (
        <div className={boxClassName.toClassName()} style={boxStyle}>
            <Dropdown menu={menuAcoes.props} placement={placement} disabled={disabled}>
                {disabled?<span></span>:<Button type={"text"} icon={iconBars}>&nbsp;{text}</Button>}
            </Dropdown>
        </div>
    );
     */

    return (
        <div className={boxClassName.toClassName()} style={boxStyle}>
            <Dropdown menu={menuAcoes.props} placement={placement} disabled={disabled} overlayClassName={"dropdown-acoes-disponiveis"}>
                {disabled?<span></span>:<Button className={"botaoAcoesDisponiveis"} type={"text"} icon={iconBarsV2}></Button>}
            </Dropdown>
        </div>
    );

}

export default BotaoAcoesDisponiveis;

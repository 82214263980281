//v5 import "antd/dist/antd.css";
import '../../../assets/css/tema.scss';
import './menuLateral.scss';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {Alert, Button, Image, Layout, Menu, Modal, notification, Tooltip} from 'antd';
import { setActive } from '../../../reducer/transacaoManager';
import {
    ANGLE_DOUBLE_LEFT_ICON_PRIMARY,
    ANGLE_DOUBLE_RIGHT_ICON_PRIMARY, ANGLE_LEFT_ICON, ANGLE_RIGHT_ICON,
    CUSTOM,
    MENU_ICONS,
    MINUS_SQUARE_ICON_PRIMARY,
    PIN_ICON_PRIMARY,
    PLUS_SQUARE_ICON_PRIMARY, RIGHT_TO_BRACKET_ICON,
    SIGN_OUT_ALT_ICON_PRIMARY
} from "../../../assets/iconografia";
import {campoInformado} from "../../../lib/iy2b-javascript";
import {postJSON, postWS} from "../../../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../Workspace/workspace";
import logo from "../../../assets/images/header-logo.png";
import logoExtentido from "../../../assets/images/header-logo-extendido.png";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import eventBus from "../../../lib/eventBus";
import {notificacao} from "../../../lib/Notificacao";
const { ErrorBoundary } = Alert;

const { Sider } = Layout;

const iconStar = CUSTOM.STAR_REGULAR_ICON_YELLOW;
const iconStarSolido = CUSTOM.STAR_SOLID_ICON_YELLOW;
const iconExecAutom = PIN_ICON_PRIMARY;
const iconAddExecAutom = CUSTOM.CIRCLE_PLUS_ICON; // PLUS_SQUARE_ICON_PRIMARY;
const iconDelExecAutom = CUSTOM.CIRCLE_MINUS_ICON; // MINUS_SQUARE_ICON_PRIMARY;
const iconRemoveExecAutom = CUSTOM.MINUS_CIRCLE_ICON_RED;

const MenuLateral = props => {

    const { onLogout, onWidthChange } = props ;

    const [siderHeight, setSiderHeight] = useState(0);
    const [collapsed, setCollapsed] = useState(false);
    const [items, setItems] = useState([]);
    const [favoritas, setFavoritas] = useState([]);
    const [executarAutom, setExecutarAutom] = useState([]);

    const breaks = useBreakpoint();

    const processarStatusExecAutom = async item => {

        try {

            const acaoTrnFav = (item.execAutom === true) ? "EXCLUSAO" :  "INCLUSAO";

            const resultado = await postJSON({
                acaoWebAExecutar: "processarnegocio",
                sistema: "smk",
                projeto: "pnltrnfav",
                rest: false
            }, {
                acaoAExecutar: "mantem",
                cdPessoaUsr: props.userSession.cdPessoaUsr,
                cdTransacao: item.cdTransacao,
                acaoTrnFav: acaoTrnFav,
                nrExec: executarAutom.length + 1,
                tpInclusao: "X"
            }, WORKSPACE_LOADING_INDICATOR_AREA);

            if(item.execAutom === true) {
                item.execAutom = false;
            } else {
                item.execAutom = true;
            }

            inicializaMenu ( true );

            return true;

        } catch (error) {
            notificacao.erro({errorId:"statusExecAutom", message:"Marcar/desmarcar como favorita", description:"Nao foi possivel no momento, por favor tente mais tarde !"});
            return false;
        }

    }

    const onClickFavorito = async item => {

        try {

            const acaoTrnFav = (item.favorito === true) ? "EXCLUSAO" :  "INCLUSAO";

            const resultado = await postJSON({
                acaoWebAExecutar: "processarnegocio",
                sistema: "smk",
                projeto: "pnltrnfav",
                rest: false
            }, {
                acaoAExecutar: "mantem",
                cdPessoaUsr: props.userSession.cdPessoaUsr,
                cdTransacao: item.cdTransacao,
                acaoTrnFav: acaoTrnFav,
                nrExec: favoritas.length + 1,
                tpInclusao: "M"
            }, WORKSPACE_LOADING_INDICATOR_AREA);

            if(item.favorito === true) {
                item.favorito = false;
            } else {
                item.favorito = true;
            }

            inicializaMenu ( true );

            return true;

        } catch (error) {
            notificacao.erro({errorId:"favoritar", message:"Marcar/desmarcar como favorita", description:"Nao foi possivel no momento, por favor tente mais tarde !"});
            return false;
        }

    }

    const wrapperOnClickFavorito = (e, trn) => {
        console.log("wrapperOnClickFavorito", e);
        e.stopPropagation();
        e.preventDefault();
        onClickFavorito(trn).then(resultado => {
            console.log(resultado);
        });
    }

    const wrapperOnClickPickExecAutom = (e, trn) => {
        console.log("wrapperOnClickPickExecAutom", e);
        e.stopPropagation();
        e.preventDefault();
        processarStatusExecAutom(trn).then(resultado => {
            console.log(resultado);
        });
    }

    const wrapperOnClickRemoveExecAutom = (e, trn) => {
        console.log("wrapperOnClickRemoveExecAutom", e);
        e.stopPropagation();
        e.preventDefault();
        processarStatusExecAutom(trn).then(resultado => {
            console.log(resultado);
        });
    }

    const adicionaTrn = ( trn ) => {
        const item = {
            key: "T" + trn.key,
            title: trn.title,
            label: trn.title,
            transacao: {...trn}
        };
        if(trn.tpTransacao === "M") {
            if(trn.hasOwnProperty("childs") === true) {
                item.children = trn.childs.map (child => adicionaTrn ( child ));
                item.popupClassName = "popup-ws-menu-lateral";
                if(campoInformado(trn.noIcon) === true) item.icon = trn.icon;
            } else {
                console.log("?????", trn);
            }
        } else {
            if(trn.favorito === true) {
                item.icon = <Button type={"text"} className={"botaoFavorito"} onClick={e => wrapperOnClickFavorito(e, trn)}>{iconStarSolido}</Button>;
            } else {
                item.icon = <Button type={"text"} className={"botaoFavorito"} onClick={e => wrapperOnClickFavorito(e, trn)}>{iconStar}</Button>;
            }
        }
        return item;
    }

    const adicionaTrnFav = ( trn ) => {
        const item = {
            key: "F" + trn.key,
            title: trn.title,
            label: trn.title,
            transacao: {...trn}
        };
        if(trn.favorito === true) {
            item.icon = <Button type={"text"} className={"botaoFavorito"} onClick={e => wrapperOnClickFavorito(e, trn)}>{iconStarSolido}</Button>;
        } else {
            item.icon = <Button type={"text"} className={"botaoFavorito"} onClick={e => wrapperOnClickFavorito(e, trn)}>{iconStar}</Button>;
        }
        return item;
    }

    const adicionaTrnExecAutom = ( trn ) => {
        const item = {
            key: "X" + trn.key,
            title: trn.title,
            label: trn.title,
            transacao: {...trn}
        };
        if(trn.execAutom === true) {
            item.icon = iconExecAutom;
        }
        return item;
    }

    const adicionaTrnPickExecAutom = ( trn ) => {
        const item = {
            key: "PEA" + trn.key,
            title: "Adicione este item na execução automática",
            label: trn.title,
            transacao: {...trn}
        };
        if(trn.tpTransacao === "M") {
            if(trn.hasOwnProperty("childs") === true) {
                item.children = trn.childs.filter( t => t.execAutom !== true).map (child => adicionaTrnPickExecAutom ( child ));
                item.popupClassName = "popup-ws-menu-lateral";
                if(campoInformado(trn.noIcon) === true) item.icon = trn.icon;
            } else {
                console.log("?????", trn);
            }
        } else {
            item.disabled = true;
            item.icon = <Button type={"text"} className={"botaoPickExecAutom"} onClick={e => wrapperOnClickPickExecAutom(e, trn)}>{iconExecAutom}</Button>;
        }
        return item;
    }

    const adicionaTrnDelExecAutom = ( trn ) => {
        const item = {
            key: "REA" + trn.key,
            title: "Remova este item da execução automática",
            label: trn.title,
            transacao: {...trn}
        };
        item.disabled = true;
        item.icon = <Button type={"text"} className={"botaoRemExecAutom"} onClick={e => wrapperOnClickRemoveExecAutom(e, trn)}>{iconRemoveExecAutom}</Button>;
        return item;
    }

    const inicializaMenu = (forcado) => {

        if(forcado === true) {
            // forcado
        } else {
            if(items.length > 1) return ;
        }

        const itensMenu = props.transacaoManager.menu
            .filter(trn => (trn.tpTransacao === "M"))
            .map(trn => adicionaTrn ( trn ))
        ;

        if(itensMenu.length === 0 ) return ;

        const itemFavoritas = {
            key: "favoritas",
            label: "Favoritas",
            icon: MENU_ICONS['star'],
            popupClassName: "popup-ws-menu-lateral",
            children: props.transacaoManager.disponiveis.filter(trn => (trn.favorito === true)).map(trn => adicionaTrnFav ( trn )),
        };

        setFavoritas(itemFavoritas.children);

        const dpAux = [];

        dpAux.push(itemFavoritas);

        dpAux.addAll(itensMenu);

        dpAux.push({
            type: "divider"
        });

        const itemExecAutom = {
            type: "group",
            key:"execucaoAutom",
            label: "",
            children: props.transacaoManager.disponiveis.filter(trn => (trn.execAutom === true)).map(trn => adicionaTrnExecAutom ( trn ))
        }

        if(itemExecAutom.children.length < 4) {
            const itensMenuPickExecAutom = props.transacaoManager.menu
                .filter(trn => (trn.tpTransacao === "M"))
                .map(trn => adicionaTrnPickExecAutom ( trn ))
            ;
            itensMenuPickExecAutom.unshift({
                key: "helpAddExecAutom",
                label: "Incluir itens na execução automática",
                title: "Clique no icone para incluir o item na execução automática",
                popupClassName: "popup-ws-menu-lateral-desabilitado",
                disabled: true
            });
            itemExecAutom.children.push({
                key: "addExecAutom",
                title: "Adicionar um operação para execução automática",
                label: "Adicionar",
                icon: iconAddExecAutom,
                children: itensMenuPickExecAutom,
                popupClassName: "popup-ws-menu-lateral",
            });
        }

        if(itemExecAutom.children.length > 1) {
            const itensMenuDelExecAutom = props.transacaoManager.disponiveis
                .filter(trn => (trn.execAutom === true))
                .map(trn => adicionaTrnDelExecAutom ( trn ));

            itensMenuDelExecAutom.unshift({
               key: "helpRemoveExecAutom",
               label: "Excluir itens da execução automática",
               title: "Clique no icone para remover o item da execução automática",
               popupClassName: "popup-ws-menu-lateral-desabilitado",
               disabled: true
            });

            itemExecAutom.children.push({
                key: "delExecAutom",
                title: "Remover um operação para execução automática",
                label: "Remover",
                icon: iconDelExecAutom,
                children: itensMenuDelExecAutom,
                popupClassName: "popup-ws-menu-lateral",
            });
        }

        setExecutarAutom(itemExecAutom.children);

        dpAux.push(itemExecAutom);

        console.log("MenuLateral.setItems", dpAux);

        setItems( dpAux );

    };

    inicializaMenu();

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        console.log("useEffect inicializa MenuLateral");

        const height = document.getElementById('mainWorkspace').clientHeight;

        setSiderHeight( height );

        eventBus.on("menuLateral/rebuild", () => {

            console.log("received menuLateral/rebuild");

            inicializaMenu ( true );

        });

        return () => {

            console.log("final useEffect inicializa MenuLateral");

        };

    }, []);

    const handleLogout = () => {

        console.log("logout.....");

        setItems( [] );

        onLogout();

    }

    const handleClick = e => {
        if(e.key==="helpAddExecAutom") return;
        if(e.key==="helpRemoveExecAutom") return;
        console.log("handleClick", e.key.substring(1), e.key.substring(0, 3));
        if(e.key.substring(0, 1) === "X") {
            // console.log("executarAutom", executarAutom, e.key.substring(1));
            const cdTransacao = e.key.substring(1);
            //props.setActive(e.key.substring(1));
            props.setActive(cdTransacao).then(r => {
                logAuditoria( parseInt(cdTransacao) );
            });
        } else {
            const cdTransacao = e.key.substring(1);
            props.setActive(cdTransacao).then(r => {
                logAuditoria( parseInt(cdTransacao) );
            });
        }
    };

    const logAuditoria = (cdTransacao) => {
        const w = window.innerWidth;
        const h = window.innerHeight;
        const uAgent = navigator.userAgent.trim() + "; screen/" + w + "," + h + "; touch/" + window.isTouchDevice();
        const versaoEmExec = parseInt(process.env.REACT_APP_VERSAO_ATUAL);
        const payload = {
            cdPessoaUsr: props.userSession.cdPessoaUsr,
            cdPessoaEmp: props.userSession.cdPessoaEmpAtual,
            cdTransacao: cdTransacao,
            txIdApp: "iy2b-portal",
            appVersion: versaoEmExec,
            client: uAgent,
        };
        postWS({
            sistema: "smk",
            projeto: "msmkpiy2b",
            acaoWebAExecutar: "processarNegocio",
            acaoAExecutar: "incluir"
        }, payload).then(resposta => {
            resposta.mensagens.forEach(msg => {
                console.log(msg);
            });
        }, error => {
            console.log(error);
        });
    }

    const ehLogoExtendido = (collapsed === false);

    const currentLogo = (ehLogoExtendido===true) ? logoExtentido : logo;


//    const logoSize = (breaks.xs===true)?46:54;

    const logoSize = {
        width: (ehLogoExtendido===true) ? 123 : 56
    };

    const classNameLogoRadius = ["logo-radius"];

    if(ehLogoExtendido===true) {
        classNameLogoRadius.push("extendido");
    } else {
        classNameLogoRadius.push("circulo");
    }

    const GAP_HEADER = 80;

    //console.log("MenuLateral", items, props.transacaoManager.menu);

    return (
        <div id={"containerMenuLateral"}
             style={{
                display: "flex"
            }}
        >
            <Sider id={"menuLateral"}
               style={{
                   height: siderHeight
               }}
               trigger={null}
               collapsible collapsed={collapsed}
            >
                <Tooltip placement="bottom" title={"Ethimo - Cloud ERP"}>
                    <div className={"logo-container"}>
                        <div className={classNameLogoRadius.toClassName()}>
                            <Image
                                className={"logo"}
                                preview={false}
                                src={currentLogo}
                                width={logoSize.width}
                            />
                        </div>
                    </div>
                </Tooltip>
                <Menu className={"ws-menu-lateral"}
                    onClick={handleClick}
                    style={{
                        height: siderHeight - 96 - GAP_HEADER
                    }}
                    mode="vertical"
                    items={items}
                    triggerSubMenuAction={"click"}
                />
                <BotaoLogout
                    collapsed={collapsed}
                    aoLogout={handleLogout}
                />
            </Sider>
            <BotaoAbreFecha
                style={{
                    height: siderHeight - GAP_HEADER
                }}
                aoAbrir={() => {
                    setCollapsed( false );
                    onWidthChange (200);
                }}
                aoFechar={() => {
                    setCollapsed( true );
                    onWidthChange (80);
                }}
            />
        </div>
    );

};

const BotaoLogout = props => {

    const { aoLogout, collapsed } = props ;

    const doLogout = () => {
        aoLogout();
    }

    const label = (collapsed === false) ? <span class={"label"}>Encerrar a sessão</span> : null;

    return (
        <Tooltip placement="right" title={"Encerrar a sua sessão e voltar a tela de login"}>
            <div className={"boxBotaoLogout"}>
                <Button className={"botaoLogout"} type={"link"} onClick={doLogout} icon={SIGN_OUT_ALT_ICON_PRIMARY}/>
            </div>
        </Tooltip>
    );

}

const BotaoAbreFecha = props => {

    const [aberto, setAberto] = useState(true);

    const { style, aoFechar, aoAbrir } = props;

    const myStyle = {
        ...style
    }

    const doFechar = async () => {
        setAberto(false);
        aoFechar();
    }

    const doAbrir = async () => {
        setAberto(true);
        aoAbrir();
    }

    const titleTooltip = (aberto === true) ? "Recolher" : "Expandir";

    const botao = (aberto === true) ?
            <Button className="botaoFechar" type={"link"} onClick={doFechar} icon={ANGLE_LEFT_ICON}></Button>
        :
            <Button className="botaoAbrir" type={"link"} onClick={doAbrir} icon={ANGLE_RIGHT_ICON}></Button>
        ;

    return (
        <ErrorBoundary>
            <Tooltip placement="right" title={titleTooltip}>
                    <div className={"boxBotaoAbreFecha"}>
                        <div className={"gap-header"}></div>
                        <div className={"containerBotaoAbreFecha"} style={myStyle}>
                            {botao}
                        </div>
                    </div>
            </Tooltip>
        </ErrorBoundary>
    )
}


const mapStateToProps = function( { userSession, transacaoManager, appConfig } ) {

    return {
        userSession: userSession,
        transacaoManager: transacaoManager,
        appConfig: appConfig
    };

};

const mapDispatchToProps = dispatch => {
    return {
        setActive: (key) => {
            return dispatch( setActive(key) );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuLateral);

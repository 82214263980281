//v5 import "antd/dist/antd.css";
import '../../framework/assets/css/tema.scss';
import "./popUpTermosDeUso.scss";

import Iframe from "react-iframe";
import React, {useContext} from "react";
import {useGridWidth, useWindowSize} from "../../framework/lib/iy2b-react";

const TermosDeUso = (props) => {

    const url = "termosDeUso.pdf";

    const {width, height} = props;

    const CONTENT_WIDTH = width - 40;
    const CONTENT_HEIGHT = height - 136;

    return (
      <div className={"container-termos-de-uso"}>

          <h2>Leia com atenção todos os termos abaixo.</h2>

          <div className={"box-termos-de-uso"}>
              <Iframe url={url}
                      width={CONTENT_WIDTH + "px"}
                      height={CONTENT_HEIGHT + "px"}
                      id={"iframeTermosDeUso"}
                      className={"iframe-termos-de-uso"}
              />
          </div>

      </div>
    );

}

export default TermosDeUso;

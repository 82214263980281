//v5 import "antd/dist/antd.css";
import "./style.scss";

import React from 'react';
import {usePromiseTracker} from "react-promise-tracker";
import IY2BLogoSpinner from "../IY2BLogoSpinner/IY2BLogoSpinner";

const LoginLoadingIndicator = props => {

    const { promiseInProgress } = usePromiseTracker();

    return (
        promiseInProgress &&
        <div id="backdropLoading" name="backdropLoading">
            <div
                style={{
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    padding: "10px"
                }}
            >
                <IY2BLogoSpinner />
            </div>
        </div>
    );

};

/**
// <Loader type="Circles" color="firebrick" height="60" width="60"/>
*/

export default LoginLoadingIndicator;

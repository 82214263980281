//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-descriptions/dist/descriptions.css';

import '../../framework/assets/css/tema.scss';
import "./style.scss";

import React, {useEffect} from 'react';
import ProDescriptions from '@ant-design/pro-descriptions';
import  {ProForm, ProFormDigit} from "@ant-design/pro-components";
import {useGridWidth, useStateRef} from "../../framework/lib/iy2b-react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {Col, Row} from "antd";

const StepResumo = props => {

    const [ , gridWidth ] = useGridWidth();

    const [tokenEmail, setTokenEmail, refTokenEmail] = useStateRef(null);

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        console.log("StepResumo");

        if(props.api) {
            props.api.current = {
                onFinish: onFinish
            };
        }

        return () => {

            console.log("final useEffect inicializa StepCadEmpresa");

        };

    }, []);

    useEffect(() => {

        setTokenEmail ( props.tokenEmail );

    }, [props.tokenEmail]);

    const onFinish = async () => {

        const all = props.formRef.current?.getFieldsValue();

        const resultado = (all.token === parseInt(refTokenEmail.current));

        return resultado;

    }

    const numCols = gridWidth.current.compare(window._XL_) >= 0 ? 3 :
        gridWidth.current.compare(window._MD_) >= 0 ? 2 :
        1
    ;

    const tipoWiz = (props.certificado?.flgJuridico === "F") ? "CPF" : "CNPJ";

    const breakpoints = useBreakpoint();

    const maxWidth = breakpoints.xs ? 330 :
        breakpoints.lg ? 1024 :
        700
    ;

    return (
        <div style={{
            maxWidth: maxWidth
        }}>
            <ProDescriptions column = { numCols } title = "Conferir as informações e confirmar a contratação" tooltip = "Necessário conferir o resumo das informações e confirmar a contratação clicando no enviar">

                <ProDescriptions.Item span={1} label = "Plano selecionado" >
                    {props.plano?.label}
                </ProDescriptions.Item>
                <ProDescriptions.Item span={1} label = "Forma de pagamento" >
                    {props.plano?.formaPagto}
                </ProDescriptions.Item>
                {(tipoWiz==="CNPJ") && (
                    <>
                        <ProDescriptions.Item span={1} label = "Razão social" >
                            {props.cadEmp?.noRazaoSocial}
                        </ProDescriptions.Item>
                        <ProDescriptions.Item span={1} label = "CNPJ" >
                            {props.cadEmp?.nrCNPJ}
                        </ProDescriptions.Item>
                    </>
                )}
                <ProDescriptions.Item span={1} label = "Usuário master" >
                    {props.cadUsr?.noUsuario}
                </ProDescriptions.Item>
                <ProDescriptions.Item span={1} label = "CPF" >
                    {props.cadUsr?.nrCPF}
                </ProDescriptions.Item>

                <ProDescriptions.Item span = { numCols } label = "E-Mail"  >
                    {props.cadUsr?.noEMail}
                </ProDescriptions.Item>

                <ProDescriptions.Item span = { numCols } className={'boxTokenVerificacao'}>
                    <Row className={"rowBox"} gutter={[8,16]}>
                        <Col xs={24} md={6}>
                            <ProForm.Group>
                                <ProFormDigit
                                    width={"md"}
                                    label="Token de verificação"
                                    placeholder={"Digite o token recebido"}
                                    tooltip={"Digite o token recebido antes de concluir a contratação. Verifique a sua caixa de spam / lixo eletrõnico."}
                                    rules={[ {required: true, message:"Infome o token recebido antes de concluir a contrataçáo"}]}
                                    name="token"
                                    fieldProps={{ precision: 0 }}
                                />
                            </ProForm.Group>
                        </Col>
                        <Col xs={24} md={18}>
                            Você recebeu um email com o token de verificaçao. Digite o token recebido antes de concluir a contrataçao. Verifique a sua caixa de spam / lixo eletrõnico.
                        </Col>
                    </Row>
                </ProDescriptions.Item>

                < ProDescriptions.Item span = { numCols } className={"destaque"}>
                    Para concluir a contração você precisa pressionar/clicar o botão "Enviar". Fazendo isto você confirmará a contratação do plano de assinatura indicado com as informações apresentadas. Você receberá um email com as orientações necessárias para iniciar a utilização do sistema. Verifique a sua caixa de spam / lixo eletrõnico.
                </ ProDescriptions.Item >

            </ProDescriptions>
        </div>
    )

};

export default StepResumo;

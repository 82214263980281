import Cookies from 'universal-cookie';

export default class IY2BCookies {

    constructor(path) {
        this.cookies = new Cookies( );
        this.path = path;
    }

    async set(name, value, {cdPessoaEmp=0, cdPessoaUsr=0, cdTransacao=0}) {
        this.cookies.set(this.path + "/" + cdPessoaUsr + "/" + cdPessoaEmp + "/" + cdTransacao + "/" + name, value);
        return true;
    }

    async remove(name, {cdPessoaEmp=0, cdPessoaUsr=0, cdTransacao=0}) {
        this.cookies.remove(this.path + "/" + cdPessoaUsr + "/" + cdPessoaEmp + "/" + cdTransacao + "/" + name);
        return true;
    }

    async get(name, {cdPessoaEmp=0, cdPessoaUsr=0, cdTransacao=0}) {
        const value = this.cookies.get(this.path + "/" + cdPessoaUsr + "/" + cdPessoaEmp + "/" + cdTransacao + "/" + name);
        return value;
    }

}

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss"

import {Popconfirm} from "antd";
import BotaoToolTip from "../BotaoToolTip/botaoToolTip";
import React from "react";
import {TRASH_ICON_RED, xs} from "../../assets/iconografia";

const iconTrash = TRASH_ICON_RED;

const BotaoExcluir = ( props ) => {

    const { excluir, semConfirmacao=false, msgConfirmacao="Confirma a exclusão deste registro?" } = props;

    const tooltip = "Excluir este item";

    const elResultado =
        (semConfirmacao===true) ?
            <BotaoToolTip tooltip={tooltip} icon={iconTrash} className={"botao-excluir-item"}
                          onClick={async () => {
                              await excluir();
                          }}
            />
            :
            <Popconfirm
                title={msgConfirmacao}
                onConfirm={async () => {
                    await excluir();
                }}
                okText="Sim, excluir"
                cancelText="Não"
            >
                <BotaoToolTip tooltip={tooltip} icon={iconTrash} className={"botao-excluir-item"}
                              placement={"bottom"}
                />
            </Popconfirm>
    ;

    return elResultado;

}

export {
    BotaoExcluir
};

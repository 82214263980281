// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider, FacebookAuthProvider, TwitterAuthProvider } from "firebase/auth";
import {firebaseConfig} from "../../lib/firebase-config";
import {notificacao} from "./Notificacao";


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
auth.useDeviceLanguage();

const googleProvider = new GoogleAuthProvider();

const appleProvider = new OAuthProvider('apple.com');
appleProvider.setCustomParameters({
    locale: 'pt-BR'
});

const facebookProvider = new FacebookAuthProvider();

const twitterProvider = new TwitterAuthProvider();

const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const {displayName, email, uid, photoURL} = res.user;
        return {
            displayName, email, uid, photoURL
        };
    } catch (err) {
        console.error(err);
        notificacao.erro({errorId:"firebase", message:"Opps!!!", description:err.message});
        return null;
    }
};

const signInWithApple = async () => {
    try {
        const res = await signInWithPopup(auth, appleProvider);

        const credential = OAuthProvider.credentialFromResult(res);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;

        console.log(res.user, credential);

        const {displayName, email, uid, photoURL} = res.user;

        return {
            displayName, email, uid, photoURL
        };
    } catch (err) {
        console.error(err);
        notificacao.erro({errorId:"firebase", message:"Opps!!!", description:err.message});
        return null;
    }
};

const signInWithFacebook = async () => {
    try {
        const res = await signInWithPopup(auth, facebookProvider);

        const credential = FacebookAuthProvider.credentialFromResult(res);
        const accessToken = credential.accessToken;

        console.log(res.user, credential);

        const {displayName, email, uid, photoURL} = res.user;

        return {
            displayName, email, uid, photoURL
        };
    } catch (err) {
        console.error(err);
        notificacao.erro({errorId:"firebase", message:"Opps!!!", description:err.message});
        return null;
    }
};

const signInWithTwitter = async () => {
    try {
        const res = await signInWithPopup(auth, twitterProvider);

        const credential = TwitterAuthProvider.credentialFromResult(res);
        const token = credential.accessToken;
        const secret = credential.secret;

        console.log(res.user, credential);

        const {displayName, email, uid, photoURL} = res.user;

        return {
            displayName, email, uid, photoURL
        };
    } catch (err) {
        console.error(err);
        notificacao.erro({errorId:"firebase", message:"Opps!!!", description:err.message});
        return null;
    }
};

const logoutProvedorIdentidade = async () => {
    return await auth.signOut();
};

const provedoresIdentidadeDisponiveis = [{
    cdProvedorIdentidade: 1,
    chProvedorIdentidade: "google"
},{
    cdProvedorIdentidade: 2,
    chProvedorIdentidade: "apple"
},{
    cdProvedorIdentidade: 3,
    chProvedorIdentidade: "facebook"
}];

const signInWithIdProvider = async (idProvider) => {

    if(idProvider === 1) {
        return await signInWithGoogle();
    }
    if(idProvider === 2) {
        return await signInWithApple();
    }
    if(idProvider === 3) {
        return await signInWithFacebook();
    }
    if(idProvider === 4) {
        return await signInWithTwitter();
    }

}

export {
    auth,
    logoutProvedorIdentidade,
    signInWithIdProvider,
    provedoresIdentidadeDisponiveis
};

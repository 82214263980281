import {useState} from "react";

export const useNextUpdate = () => {

    const [pilha, setPilha] = useState([]);

    const local = [];

    return {
        clear: () => {
            local.clear();
        },
        add: (block) => {
            local.push(block);
        },
        set: () => {
            setPilha(local);
        },
        run: () => {
            if(pilha.length > 0) {
                setTimeout(() => {
                    pilha.forEach(block => block());
                    setPilha([]);
                },0);
            }
        }
    }

}

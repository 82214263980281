import '../../framework/assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useRef} from 'react';
import {Col, Row} from "antd";
import RadioGroup from "../../framework/componentes/proform/RadioGroup/radioGroup";

const StepTipoPessoa = ( props ) => {

    return (
        <div className={"containerStepTipoPessoa"}>
            <Row gutter={[8,16]}>
                <Col xs={24}>
                    <RadioGroup
                        width={"md"}
                        name="flgTipoPessoa"
                        label="A contratação será para a sua empresa ou para a sua pessoa física ?"
                        tooltip = { "Indique se a contratação é para a sua empresa ou se é para a sua pessoa física" }
                        rules={[
                            {required: true, message:"Informe para quem é a contratação"}
                        ]}
                        options={[
                            {
                                label: 'Empresa',
                                value: 'J',
                            },
                            {
                                label: 'Eu mesmo',
                                value: 'F',
                            },
                        ]}
                    />
                </Col>
            </Row>
        </div>
    );

}

export default StepTipoPessoa;

import './assets/css/index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'moment/locale/pt-br';
import 'dayjs/locale/pt-br';
import {iy2bLibJS} from './framework/lib/iy2b-javascript';
import Modal from 'react-modal';
import {registerLocale} from "@antv/g2plot";
import { PT_BR_LOCALE } from './framework/3rdpart/G2Plot/locales/pt_BR';
import App from "./App";
import ptBR from "antd/es/locale/pt_BR";
import {ConfigProvider} from "antd";
import dayjs from "dayjs";

import customParseFormat from "dayjs/plugin/customParseFormat";

registerLocale('pt-BR', PT_BR_LOCALE);

dayjs.extend(customParseFormat)

iy2bLibJS();

const root = ReactDOM.createRoot( document.getElementById('root') );

// webmakers
// process.env.REACT_APP_SKIN

const skin = process.env.REACT_APP_SKIN;

root.render(
    <ConfigProvider locale={ptBR} theme={{
        cssVar: { key: skin },
    }}>
        <App />
    </ConfigProvider>
);

////// http://localhost:3000/index.html#/?&key=3343

if (window.AMBIENTE_DESENV()) {
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals(console.log);
}

Modal.setAppElement('#root');

console.log((window.AMBIENTE_DESENV()?"desenv":"producao") + ", versao " + window.VERSAO_ATUAL());

//v5 import "antd/dist/antd.css";
import '../../../assets/css/tema.scss';
import './headerWS.scss';

import React, {useEffect, useRef} from 'react';
import { connect } from 'react-redux';
import {Layout, Row, Col, Tooltip, Image} from 'antd';
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

import UsuarioDropdown from "../../../componentes/UsuarioDropdown";
import EmpresaDropdown from "../../../componentes/EmpresaDropdown";
import { setActive, setActiveAt } from "../../../reducer/transacaoManager";
import {setChecklistProcesso} from "../../../reducer/suite";
import {useWindowSize} from "../../../lib/iy2b-react";
import HeaderToolbar from "./HeaderToolbar";

const { Header } = Layout;

const HeaderWS = props => {

    const { logoWidth, paneToHelp } = props ;

    const windowSize = useWindowSize();

    const breaks = useBreakpoint();
    const refMenuSuspensoApi = useRef();
    const chklDropdownApi = useRef();

    useEffect(() => {

        props.api.current = {
            clearSession: () => {

            },
            exibeDropdownUsuario: () => {
                chklDropdownApi?.current?.exibeDropdown();
            },
            escondeDropdownUsuario: () => {
                chklDropdownApi?.current?.escondeDropdown();
            },
            exibeDropdownChecklist: () => {
                chklDropdownApi?.current?.exibeDropdown();
            },
            escondeDropdownChecklist: () => {
                chklDropdownApi?.current?.escondeDropdown();
            },
            exibeMenuTransacao: () => {
                refMenuSuspensoApi?.current?.openMenu();
            },
            escondeMenuTransacao: () => {
                refMenuSuspensoApi?.current?.closeMenu();
            },
        };

        return () => {

        };

    }, []);

    const onSelectEmpresa = (empresa) => {

        if(props.suite.empresaAtual.pessoaEmp.cdPessoa === empresa.pessoaEmp.cdPessoa) {
            // nada
        } else {
            props.onEmpresaChange(empresa);
        }

    }

    const btnEmpresaWidth = (breaks.xs===true)?"70px":"350px";

    const GAP = 17;

    const headerToolbarWidth = (windowSize.width - (logoWidth+24) - ((breaks.xs===true)?70:350) - 72 - GAP) + "px";

    return (
        <Header id={"headerWS"}>
            <Row align={"middle"} justify={"space-between"} gutter={0} className={"full-height"}>
                <Col flex={btnEmpresaWidth} className={"align-center"}>
                    <EmpresaDropdown
                        empresaAtual={props.suite.empresaAtual}
                        dataSource={props.suite.listaEmp}
                        onSelect={onSelectEmpresa}
                    />
                </Col>
                <Col flex={"auto"}>
                    <HeaderToolbar myWidth={headerToolbarWidth}
                        onLogout={props.onLogout}
                        onVerCheckList={(checklist) => {
                            props.onVerCheckList(checklist);
                        }}
                        onIniciarApresentacao={() => {
                            props.onIniciarApresentacao();
                        }}
                        opcoesWizard = {props.opcoesWizard}
                        onClickWizard = {props.onClickWizard}
                        opcoesMenu={props.opcoesMenu}
                        paneToHelp={paneToHelp}
                        onShowHelp={props.onShowHelp}
                        ref={chklDropdownApi}
                    />
                </Col>
            </Row>
        </Header>
    );

};

const mapStateToProps = function( { userSession, suite, transacaoManager } ) {

    return {
        userSession: userSession,
        suite: suite,
        transacaoManager: transacaoManager
    };

};

const mapDispatchToProps = dispatch => {
    return {
        setActive: (key) => {
            return dispatch( setActive(key) );
        },
        setActiveAt: (position, key) => {
            return dispatch( setActiveAt(position, key) );
        },
        setChecklistProcesso: (checklist) => {
            return dispatch( setChecklistProcesso(checklist) );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderWS);



import React, {useRef, useEffect} from 'react';
import {notificacaoErroValidForm, useGridWidth, useWindowSize} from "../../lib/iy2b-react";
import {ProForm, ProFormText} from '@ant-design/pro-form' ;
import {Button, notification, Space} from "antd";
import IY2BReactModal from "../IY2BReactModal";
import md5 from "blueimp-md5";
import {getProjetosUtilizados} from "../../lib/RequestAPI";
import {notificacao} from "../../lib/Notificacao";

const validaSenhaDebug = ( senha ) => {

    const hoje = new Date();

    const  anoMes = hoje.format("YYYYMM");

    const hashAnoMes = md5(anoMes);

    const sMes = anoMes.substring(4); // mes do ano mes

    const mes = parseInt(sMes); // mes inteiro

    let posicaoInicial = mes * 2; // de acordo com o mes

    posicaoInicial --; // volta a posicao inicial do par: 12, 34, 56, 78, ...

    posicaoInicial --; // o java começa em ZERO, 01, 23, 45, 67, ...

    const senhaAnoMes = hashAnoMes.substring(posicaoInicial, posicaoInicial+2);

    return (senhaAnoMes.toLowerCase() === senha.toLowerCase());

}

const SenhaDebug = ( props ) => {

    const {exibePopUp, onRequestClose, onLigarDebug} = props;

    const windowSize = useWindowSize();
    const [gridWidth] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.compare(window._SM_) <= 0) ? 10 : 150;
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT * 2));
    const MODAL_CONTENT_TOP = windowSize.height / 3;

    const modalRefApi = useRef();
    const formRef = useRef();

    const txSenhaRef = useRef();

    const doLigarDebug = async () => {

        try {

            await formRef?.current?.validateFields();

            const ffValues = formRef.current?.getFieldsValue();

            const ehValida = validaSenhaDebug(ffValues.txSenha);

            if(ehValida === true) {
                await onLigarDebug();
            } else {
                notificacao.erro({errorId:"senhaDebug", message:"Invalida", description:"Senha não confere !"})
            }

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e);

        }

    }

    useEffect(() => {

        if(exibePopUp === true) {
            setTimeout(() => {
                txSenhaRef.current.focus();
            }, 1);
        }

        return () => {

        };

    }, [exibePopUp]);


    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={MODAL_CONTENT_TOP}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"popup-senha-ligar-debug"}
            title={"Habilitar acesso ao debug"}
            api={modalRefApi}
            body={
                <div className={"body-popup-senha-ligar-debug"}>
                    <ProForm
                        formRef = { formRef }
                        isKeyPressSubmit = {true}
                        layout={"horizontal"}
                        onFinish = { async ( ) => {
                            await doLigarDebug();
                        }}
                        submitter={{
                            // Completely customize the entire area
                            render : ( props , doms ) => {
                                return null;
                            }
                        }}
                        onFieldsChange = {async (changedFields) => {
                        }}
                    >
                        <div className={"pro-form-group-spacer"}>
                            <ProForm.Group>
                                <ProFormText
                                    width={"md"}
                                    name = "txSenha"
                                    label = "Digite a senha de debug"
                                    tooltip = "Senha para habilitar o debug"
                                    placeholder = "Senha"
                                    rules={[
                                        {required: true, message:"Informe o cliente da ordem de serviço"},
                                        {type:"string", message:"No máximo 2 caracteres", max: 2}
                                    ]}
                                    fieldProps={{
                                        ref: txSenhaRef
                                    }}
                                />
                            </ProForm.Group>
                        </div>
                    </ProForm>
                </div>
            }
            actions={
                <Space style={{
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <Space>
                        &nbsp;
                    </Space>
                    <Space>
                        <Button type={"primary"} className={"salvar"} onClick={async () => await doLigarDebug()} >
                            Ligar debug
                        </Button>
                    </Space>
                </Space>
            }
        />
    );

};

export default SenhaDebug;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useRef, useState} from "react";
import {notificacaoErroRequest, useStateRef} from "../../lib/iy2b-react";
import {postJSON} from "../../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";
import {campoInformado} from "../../lib/iy2b-javascript";
import {Button, notification, Space, Tooltip} from "antd";
import BotaoEditarRegistroTabela from "../BotaoEditarRegistroTabela/botaoEditarRegistroTabela";
import BotaoExcluirRegistroTabela from "../BotaoExcluirRegistroTabela/botaoExcluirRegistroTabela";
import FoldedFormField from "../proform/FoldedFormField/foldedFormField";
import { ProForm } from "@ant-design/pro-components";
import IY2BTable from "../IY2BTable";
import EditarContato from "./editarContato";
import {cachedPostJSON} from "../../lib/CacheRequestAPI";
import {CUSTOM, PLUS_CIRCLE_ICON_PRIMARY, PLUS_ICON_PRIMARY} from "../../assets/iconografia";
import {notificacao} from "../../lib/Notificacao";


const iconPlusCircle = PLUS_ICON_PRIMARY;

const FoldedContatoPessoa = ( props ) => {

    const { pessoa, label = "Contatos", hidden = false, tpOrigemPessoa="CLI", cdPessoaEmpAtual, cdTransacao=0 } = props;

    const { cdPessoa, noPessoa } = pessoa;

    const tableContatoRef = useRef();
    const [dsContato, setDsContato, refDsContato] = useStateRef( [] );

    const [exibeNovoRegistro, setExibeNovoRegistro] = useState(false);
    const [registrosAEditar, setRegistrosAEditar] = useState([]);

    const [dataProvider, setDataProvider] = useState({ });

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                clear: () => {
                    setDsContato ( [] );
                },
                novoContato: () => {
                    novoContato();
                }
            }
        };

        inicializaProjeto ();

        return () => {

        };

    }, []);

    const inicializaProjeto = async () => {

        const payload = {
            "acaoAExecutar": "completaInicializa",
        }

        try {

            const resultado = await cachedPostJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "crm",
                projeto: "mcontato",
                payload: payload
            }, WORKSPACE_LOADING_INDICATOR_AREA);

            const dataProvider = {
            };

            resultado.mensagens.forEach(msg => {

                if(msg.id === "dpTipoFone") {

                    dataProvider.dpTipoFone = msg.lista.map(item => {
                        return {
                            value: item.cdTipoFone,
                            label: item.noTipoFone,
                            data: {...item}
                        }
                    });

                } else if (msg.id === "dpCargoContato") {

                    dataProvider.dpCargoContato = msg.lista.map(item => {
                        return {
                            value: item.cdCargoContato,
                            label: item.noCargoContato,
                            data: {...item}
                        }
                    });

                } else if (msg.id === "dpTratamentoPessoal") {

                    dataProvider.dpTratamentoPessoal = msg.lista.map(item => {
                        return {
                            value: item.cdTratamento,
                            label: item.noAbrev,
                            data: {...item}
                        }
                    });

                } else if (msg.id === "dpNacionalidade") {

                    dataProvider.dpNacionalidade = msg.lista.map(item => {
                        return {
                            value: item.cdNacional,
                            label: item.noNacionalF,
                            data: {...item}
                        }
                    });

                } else if (msg.id === "mapaParametros") {

                    dataProvider.mapaParametros = msg.mapa;

                } else {

                    console.log(msg);

                }

            });

            setDataProvider(dataProvider);

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

        return true;

    };

    const pesquisarContatos = async () => {

        try {

            console.log(cdPessoa);

            const payload = {
                acaoAExecutar: "pesquisa",
                filtro: {"cdPessoa": cdPessoa, "cdPessoaEmp": cdPessoaEmpAtual}
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "crm",
                projeto: "mcontato"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "resposta") {

                    console.log(msg.lista);

                    setDsContato(msg.lista.map(item => {
                        return {
                            key: item.cdContato,
                            ... item
                        }
                    }));

                } else {

                    console.log(msg);

                }

            });

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const salvar = async (registro) => {

        try {

            const acaoWebAExecutar = campoInformado(registro.cdContato)?"alterar":"incluir";

            const payload = {
                acaoAExecutar: "acaoCRUD",
                "cdPessoa": cdPessoa,
                "noPessoa": noPessoa,
                "cdPessoaEmp": cdPessoaEmpAtual,
                "tratarEnviarFat": "S",
                "flgEnviarFat": registro.flgEnviarFat,
                registro: {
                    ... registro,
                }
            }

            console.log(payload);

            const resultado = await postJSON({
                acaoWebAExecutar: acaoWebAExecutar,
                sistema: "crm",
                projeto: "mcontato"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if (msg.id === "modelo") {

                    const modelo = msg.modeloPadrao;

                    modelo.key = modelo.cdContato;

                    const dsItem = refDsContato.current.getBy("key", modelo.key);

                    if(dsItem === null) {

                        refDsContato.current.push(modelo);

                        setDsContato ( refDsContato.current.clone() );

                    } else {

                        dsItem.noApelido = modelo.noApelido;
                        dsItem.noContato = modelo.noContato;
                        dsItem.noCargoContato = modelo.noCargoContato;
                        dsItem.noEmail = modelo.noEmail;
                        dsItem.obsContato = modelo.obsContato;

                    }

                } else {

                    console.log(msg);

                }

            });

            notificacao.sucesso({message:"Sucesso!", description:"Contato salvo com sucesso!"})

            return true;

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const folded_OnStateChange = ( opened ) => {

        if(opened === true) {
            if(refDsContato.current.isEmpty() === true) {
                pesquisarContatos();
            }
        }

    }

    const novoContato = () => {

        setExibeNovoRegistro (true);

        setRegistrosAEditar ([]);

    };

    const editarContato = ( endereco ) => {

        setExibeNovoRegistro (true);

        setRegistrosAEditar ([ endereco ]);

    }

    const excluirContato = async ( contatos ) => {

        console.log(contatos);

        try {

            const payload = {
                acaoAExecutar: "acaoCRUD",
                "cdPessoa": cdPessoa,
                "noPessoa": noPessoa,
                lista: contatos.map(item => {
                    return {
                        cdContato: item.cdContato
                    }
                })
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "excluir",
                sistema: "crm",
                projeto: "mcontato"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelosExcluidos") {

                    const novods = refDsContato.current.removeList("cdContato", msg.lista).clone();

                    setDsContato ( novods ) ;

                } else {

                    console.log(msg);

                }

            });

            setExibeNovoRegistro(false) ;

            notificacao.sucesso({message:"Sucesso!", description:"Contato excluído com sucesso!"})

            return true;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const actionViewContato = (_, record) => {

        const botoes = [];

        botoes.push(
            <BotaoEditarRegistroTabela
                key={"btnEditEnd" + record.key}
                tooltip={"Editar este contato"}
                registro={record}
                editarRegistro={editarContato}
            />
        );

        botoes.push(<BotaoExcluirRegistroTabela
            key={"btnDeleteEnd" + record.key}
            registro={record}
            excluir={excluirContato}
            tooltip={"Excluir este contato"}
        />);

        return (
            <Space size={4} align="baseline" className={"resultado-action-view"}>
                {botoes}
            </Space>
        );
    };

    const tblContatoCols = [{
        title: '',
        key: 'actionView',
        width: 80,
        align: 'center',
        render: actionViewContato
    },
        {noColuna:'Apelido', field:'noApelido', width:300, colSpan:1},
        {noColuna:'Nome', field:'noContato', width:300, colSpan:1},
        {noColuna:'Cargo', field:'noCargoContato', width:300, colSpan:1},
        {noColuna:'E-mail', field:'noEmail', width:300, colSpan:1},
        {noColuna:'Obrservação', field:'obsContato', width:300, colSpan:1},
    ];

    const tableContatoActions = [
        <Tooltip title="Novo contato" color={"black"} key={"novoContato"}>
            <Button
                type="text"
                onClick={() => {
                    novoContato();
                }}
                className="action-button"
                icon={iconPlusCircle}
            >
                <span>&nbsp;Novo contato</span>
            </Button>
        </Tooltip>
    ];

    return (
        <div className={"box-folded-contato-pessoa"}>
            <FoldedFormField label={label} hidden={hidden} onStateChange={folded_OnStateChange} icon={CUSTOM.ADDRESS_BOOK_ICON}>
                <ProForm.Group>
                    <IY2BTable id={"tblContato"} name={"tblContato"}
                               customActions={tableContatoActions}
                               enableRowCheck={false}
                               api={tableContatoRef}
                               dataSource={dsContato}
                               columns={tblContatoCols}
                               tableHeight={500}
                               downloadDisabled={true}
                               configDisabled={true}
                               paginationDisabled={true}
                               searchDisabled={true}
                    />
                </ProForm.Group>
            </FoldedFormField>
            <EditarContato cdTransacao={cdTransacao}
                exibePopUp={ exibeNovoRegistro }
                onRequestClose={() => {
                    setExibeNovoRegistro(false) ;
                }}
                registros={registrosAEditar}
                salvar={salvar}
                excluir={ async (registro) => await excluirContato([registro])}
                dataProvider={dataProvider}
                tpOrigemPessoa={tpOrigemPessoa}
                cdPessoa={cdPessoa}
            />
        </div>
    );

}

export default FoldedContatoPessoa;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../../framework/assets/css/tema.scss';

import BotaoAcoesDisponiveis from "../../../framework/componentes/BotaoAcoesDisponiveis/botaoAcoesDisponiveis";
import React, {useContext, useEffect, useRef, useState} from "react";
import {TransacaoContentContext} from "../../pages/TransacaoContent";
import { useGridWidth, useStateRef} from "../../lib/iy2b-react";
import DataProvider from "../../lib/DataProvider";
import {
    campoInformado,
    confirmWithPromise,
    dateTableCellRender, decimalTableCellRender,
    solicitarExpDados,
    tableHeightCalcs
} from "../../lib/iy2b-javascript";
import {Menu, Space, Dropdown, Button, Descriptions} from "antd";
import BotaoEditarRegistroTabela from "../BotaoEditarRegistroTabela/botaoEditarRegistroTabela";
import BotaoExcluirRegistroTabela from "../BotaoExcluirRegistroTabela/botaoExcluirRegistroTabela";
import {
    CUSTOM,
    ELLIPSISV_ICON_GRAY,
    PLUS_CIRCLE_ICON_PRIMARY,
    PLUS_ICON_PRIMARY,
    TRASH_ICON_RED
} from "../../assets/iconografia";
import {notificacao} from "../../lib/Notificacao";
import IY2BTable from "../IY2BTable";
import IY2BCard from "../IY2BCards/iy2bCard";
import BotaoCheckBox from "../BotaoCheckBox/botaoCheckBox";
import IY2BCookies from "../../lib/iy2b-cookies";
import {connect} from "react-redux";
import IY2BCards from "../IY2BCards/iy2bCards";

const iconTrash = TRASH_ICON_RED;
const iconPlusCircle = PLUS_ICON_PRIMARY;
const iconMenuExtraReg = ELLIPSISV_ICON_GRAY;

const iconTable = CUSTOM.RECTANGLE_LIST_ICON;
const iconCards = CUSTOM.TABLE_CELLS_LARGE_ICON;

const cookies = new IY2BCookies( process.env.REACT_APP_NAME + "/Ccmp_ResultadoFiltro");

const ResultadoComFiltro = ( props ) => {

    const withFilter = true ;

    const { projeto, cols, formFiltro, condicaoExclusao, showFiltroOnFirstInput=false } = props;

    const { boxName, utlFiltro, loadingArea, ID_LISTA_PESQUISA, PAGE_SIZE_RESULTADO, planoEhGratuito, tableTitle, onUpdatePageSize } = props;

    const { pesquisar, editarRegistro, excluir, novoRegistro } = props;

    const { acoesExtras, acoesExtrasPorRegistro } = props;

    const { containerHeight, getCurrentTransacao } = useContext(TransacaoContentContext);
    const cdTransacao = getCurrentTransacao();
    const [tableHeight, setTableHeight, refTableHeight] = useStateRef(80);
    const [ , gridWidth ] = useGridWidth();

    const [dsResultado, setResultado, refDsResultado] = useStateRef(new DataProvider({pageSize: PAGE_SIZE_RESULTADO}));
    // const [dsResultado, setResultado, refDsResultado] = useStateRef([]);

    const [desabilitaAcoesDisp, setDesabilitaAcoesDisp] = useState(showFiltroOnFirstInput);

    const { dualMode="none", labelCard, renderItemCard = null, cardColumns=2, id, orientationCard="vertical" } = props;

    const [currentLayoutResult, setCurrentLayoutResult] = useState("table");

    const tableRef = useRef();

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                updateTableHeight: () => {
                    if(refTableHeight.current === 80) calcTableHeight();
                },
                marcados: () => {
                    const { marcados } = refDsResultado.current;
                    return marcados;
                },
                pesquisar: pesquisaInicial,
                infoPag: () => {
                    const infoPag = tableRef.current?.infoPag();
                    return infoPag;
                },
                findKey: (key) => {
                    const dsItem = refDsResultado.current.find({
                        key: key
                    });
                    return dsItem;
                },
                addRecord: (record) => {
                    const novods = refDsResultado.current.add(record).clone();
                    setResultado ( novods );
                },
                removeList: (list) => {
                    const novods = refDsResultado.current.remove(list).clone() ;
                    setResultado( novods ) ;
                },
                unckeckAll: () => {
                    tableRef.current?.unckeckAll(refDsResultado.current);
                },
                check: (registros) => {
                    tableRef.current?.check(registros);
                },
                forceUpdate: () => {
                    tableRef.current?.forceUpdate();
                }
            }
        };

        return () => {

        };

    }, []);

    useEffect(() => {

        cookies.get(id, {cdPessoaUsr: props.userSession.cdPessoaUsr, cdTransacao: cdTransacao}).then(cookie => {
            if(campoInformado(cookie)===true) {
                console.log("dualMode.cookie", cookie);
                setCurrentLayoutResult(cookie);
            } else {
                console.log("dualMode", dualMode);
                setCurrentLayoutResult(dualMode);
            }
        });

        return () => {

        };

    }, [dualMode]);

    const calcTableHeight = () => {

        const cHeight = containerHeight();

        const tableHeight = (withFilter===true)?
            tableHeightCalcs.boxWithFormFilter({
                gridWidth: gridWidth.current,
                containerHeight: cHeight,
                boxName
            })
            :
            tableHeightCalcs.boxWithActions({
                gridWidth: gridWidth.current,
                containerHeight: cHeight,
                boxName,
            })
        ;

        const dataHeight = refDsResultado.current.page * refDsResultado.current.pageSize * 49;

        if(dataHeight < tableHeight) {

            if(refDsResultado.current.hasNextPage === true) {
                console.log("dataHeight", dataHeight);
                setTableHeight (dataHeight);

            } else {
                console.log("tableHeight", tableHeight);
                setTableHeight (tableHeight);

            }

        } else {

            console.log("tableHeight", tableHeight);
            setTableHeight (tableHeight);

        }

    };

    const clearResultado = async () => {

        setResultado(new DataProvider({pageSize: PAGE_SIZE_RESULTADO}));

        tableRef.current?.clearData();

        tableRef.current?.unckeckAll(refDsResultado.current);

    }

    const pesquisaInicial = async () => {

        setResultado(new DataProvider({pageSize: PAGE_SIZE_RESULTADO}));

        tableRef.current.clearData();

        await pesquisaPaginacao();

        // if(tableHeight === 80) calcTableHeight();

    }

    const pesquisaPaginacao = async () => {

        const dp = await pesquisar ();

        const newDP = refDsResultado.current.clone();

        newDP.addToInfinite(dp);

        setResultado(newDP);

        calcTableHeight();

        return newDP;

    }

    const efetuarDownload = async () => {

        if(utlFiltro === null) {
            notificacao.erro({errorId:"filtroNulo", message:"Necessário pesquisar", description:"Por favor, fazer uma pesquisa primeiro e depois exportar os dados !"})
            return;
        }

        const resultado = await solicitarExpDados({
            filtro: utlFiltro,
            idLista: ID_LISTA_PESQUISA,
            formato: "CSV",
            colunas: tblCols,
            sistema: projeto.sistema,
            projeto: projeto.nome||projeto.projeto,
            loadingArea: loadingArea,
            extraPayload: {
            }
        });

        return resultado;

    }

    const novo = () => {

        if(refTableHeight.current === 80) calcTableHeight();

        novoRegistro();

    }

    const editar = () => {

        const { marcados } = dsResultado;

        editarRegistro ( marcados );

    }

    const excluirMarcados = async () => {

        const { marcados } = dsResultado;

        if(marcados.length === 0) {
            notificacao.erro({errorId:"semRegistrosMarcados", message: "Nenhum marcado!", description:"Por favor marcar os registros pra efetuar a exclusão !"})
            return;
        }

        const paraExcluir = [];

        if(condicaoExclusao != null && condicaoExclusao != undefined) {

            const filtrados = marcados.filter(item => condicaoExclusao(item));

            if(filtrados.length === 0) {
                notificacao.erro({errorId:"semRegistrosMarcados", message: "Nenhum marcado!", description:"Por favor marcar os registros que podem ser excluídos pra efetuar a exclusão !"})
                return;
            }

            paraExcluir.addAll(filtrados);

        } else {

            paraExcluir.addAll(marcados);

        }

        await confirmWithPromise({
            message: "Exclusão",
            description: "Confirma a exclusão de " + marcados.length + " registro(s) ?",
            okText: "Sim, excluir",
            onOk: async () => {
                return new Promise((resolve, reject) => {
                    excluir(paraExcluir).then((resultado) => {
                        resolve(resultado);
                    }, (error) => {
                        reject(error);
                    });
                }).catch((e) => console.log('Oops errors!', e));
            },
            cancelText: "Não",
            onCancel: () => {
            }
        });

    };

    const actionView = (_, record) => {

        const botoes = [];

        if(campoInformado(editarRegistro) === true) {

            botoes.push(
                <BotaoEditarRegistroTabela
                    key={"btnEdit" + record.key}
                    tooltip={"Editar este registro"}
                    registro={record}
                    editarRegistro={() => {
                        editar();
                    }}
                />
            );

        }

        if(campoInformado(excluir) === true) {

            if(condicaoExclusao != null && condicaoExclusao != undefined) {

                if(condicaoExclusao(record) === true) {

                    botoes.push(<BotaoExcluirRegistroTabela
                        key={"btnDelete" + record.key}
                        registro={record}
                        excluir={excluir}
                        tooltip={"Excluir este registro"}
                    />);

                }

            } else {

                botoes.push(<BotaoExcluirRegistroTabela
                    key={"btnDelete" + record.key}
                    registro={record}
                    excluir={excluir}
                    tooltip={"Excluir este registro"}
                />);

            }

        }

        if(acoesExtrasPorRegistro!==undefined && acoesExtrasPorRegistro!==null) {

            const itensMenuAcoes = acoesExtrasPorRegistro.acoes(record);

            const menu = (
                <Menu onClick={({key}) => acoesExtrasPorRegistro.onClick(key, record)}
                      items={itensMenuAcoes}
                />
            );

            const myMenu = {
                items: itensMenuAcoes,
                onClick: ({key}) => {
                    return acoesExtrasPorRegistro.onClick(key, record);
                }
            };

            botoes.push(
                <Dropdown menu={myMenu} key={"menuExtras" + record.key}>
                    <Button type={"text"} onClick={e => e.preventDefault()} icon={iconMenuExtraReg}>
                    </Button>
                </Dropdown>
            );

        }

        return (
            <Space size={4} align="baseline" className={"resultado-action-view"}>
                {botoes}
            </Space>
        );
    };

    const executaAcao = ( { key } ) => {

        if(key === "novo") {

            novo ();

        } else if(key === "excluir") {

            excluirMarcados ();

        } else if(key === "layoutResultadoTable") {
            setCurrentLayoutResult("table");
            cookies.set(id, "table", {cdPessoaUsr: props.userSession.cdPessoaUsr, cdTransacao: cdTransacao});
            clearResultado().then(() => {
                pesquisaInicial();
            });

        } else if(key === "layoutResultadoCards") {
            setCurrentLayoutResult("cards");
            cookies.set(id, "cards", {cdPessoaUsr: props.userSession.cdPessoaUsr, cdTransacao: cdTransacao});
            clearResultado().then(() => {
                pesquisaInicial();
            });

        } else {

            if(acoesExtras!==undefined && acoesExtras!==null) {

                const acao = acoesExtras.filter(item => {
                    return (item.option.key === key)
                });

                if(acao.length === 1) {

                    acao[0].action();

                } else {

                    console.log(acao);

                }

            } else {
                console.log( key );


            }

        }

    }

    const itensMenuAcoes = [];

    if(campoInformado(novoRegistro) === true) {
        itensMenuAcoes.add({
            key: "novo",
            label: "Novo registro",
            icon: iconPlusCircle,
        });
    }

    if(campoInformado(excluir) === true) {
        itensMenuAcoes.add({
            key: "excluir",
            label: "Excluir os marcados",
            icon: iconTrash,
        });
    }

    if(campoInformado(acoesExtras) === true) {
        itensMenuAcoes.addAll(acoesExtras.map(item => item.option));
    }

    if(dualMode !== "none") {
        if(currentLayoutResult === "cards") {
            itensMenuAcoes.push({
                type: 'divider', // Must have
            });
            itensMenuAcoes.push({
                key: "layoutResultadoTable",
                label: "Layout tabela",
                icon: iconTable,
            });
            itensMenuAcoes.push({
                type: 'divider', // Must have
            });
        } else {
            itensMenuAcoes.push({
                type: 'divider', // Must have
            });
            itensMenuAcoes.push({
                key: "layoutResultadoCards",
                label: "Layout cartões",
                icon: iconCards,
            });
            itensMenuAcoes.push({
                type: 'divider', // Must have
            });
        }
    }

    const menuAcoes = (
        <Menu onClick={executaAcao}
              items={itensMenuAcoes}
        />
    );

    const actionViewWidth = (acoesExtrasPorRegistro!==undefined && acoesExtrasPorRegistro!==null) ? acoesExtrasPorRegistro.width : 80;

    const colActionView = {
        title: '',
        key: 'actionView',
        width: actionViewWidth,
        align: 'center',
        render: actionView
    };

    const existemAcoesPossiveis = (
        (campoInformado(editarRegistro) === true) ||
        (campoInformado(excluir) === true) ||
        (campoInformado(acoesExtrasPorRegistro) === true)
    );

    const tblCols = [];

    if(existemAcoesPossiveis === true) {
        tblCols.push(colActionView);
    }

    tblCols.addAll(cols);

    const eFormFiltro = React.cloneElement(formFiltro, {
        setDesabilitaAcoesDisp: setDesabilitaAcoesDisp,
        pesquisaInicial: pesquisaInicial,
    }, null);

    /*
            <BotaoAcoesDisponiveis
                                   menuAcoes={menuAcoes}
                                   disabled={(desabilitaAcoesDisp===true || itensMenuAcoes.length===0)}
            />
     */

    const cardCols = tblCols.filter(col => (col.card === true)).map(col => {
        const novaCol = {...col};
        if(col.hasOwnProperty("formatter")===true) {
            if(col.formatter==="data") {
                novaCol.format = "date";
                novaCol.render = (x,item) => dateTableCellRender(item[col.field]);
                novaCol.align = "center";
                novaCol.ellipsis = false;
            } else if(col.formatter==="valor") {
                const decs = col.decimais||2;
                novaCol.format = "valor";
                novaCol.render = (x,item) => decimalTableCellRender(decs)(item[col.field]);
                novaCol.align = "right";
                novaCol.ellipsis = false;
            }
        }
        return novaCol;
    });

    const labelCardCols = tblCols.filter(col => (col.field === labelCard)).map(col => {
        const novaCol = {...col};
        if(col.hasOwnProperty("formatter")===true) {
            if(col.formatter==="data") {
                novaCol.format = "date";
                novaCol.render = (x,item) => dateTableCellRender(item[col.field]);
                novaCol.align = "center";
                novaCol.ellipsis = false;
            } else if(col.formatter==="valor") {
                const decs = col.decimais||2;
                novaCol.format = "valor";
                novaCol.render = (x,item) => decimalTableCellRender(decs)(item[col.field]);
                novaCol.align = "right";
                novaCol.ellipsis = false;
            }
        }
        return novaCol;
    });

    const localRenderItemCard = (item, actionView) => {

        const items = cardCols.map(col => {
            const value = (campoInformado(col.render) === false) ? item[col.field] : col.render(null, item);
            return {
                key: col.field,
                label: col.noColuna,
                span: col.colSpan || 1,
                children: value
            }
        });

        const body = (
            <Descriptions layout={orientationCard} column={cardColumns}
                          items={items}
            />
        );

        const title = item[labelCard];

        return <IY2BCard title={title}
                         body={body}
                         actions={[
                             <BotaoCheckBox
                                 key={"btnCheck" + item.key}
                                 registro={item}
                             />,
                             actionView(null, item)]}
        />;

    }

    const ordenadores = cardCols.map(col => {
        return {
            key: col.field,
            label: col.noColuna,
            render: col.render
        }
    });

    ordenadores.addAll(labelCardCols.map(col => {
        return {
            key: col.field,
            label: col.noColuna,
            render: col.render
        }
    }));

    const myRenderItemCard = (renderItemCard === null) ? localRenderItemCard : renderItemCard;

    const elemento = (currentLayoutResult === "cards") ?
        <IY2BCards id={"cards-" + id} name={"cards-" + id}
                   renderItem={ item => myRenderItemCard(item, actionView) }
                   formularioDeFiltro={eFormFiltro}
                   botaoAcoesDisponiveis={{
                       onClickMenu: executaAcao,
                       itensMenuAcoes: itensMenuAcoes,
                       disabled: (desabilitaAcoesDisp===true || itensMenuAcoes.length===0)
                   }}
                   enableRowCheck={true}
                   ref={tableRef}
                   dataSource={dsResultado}
                   ordenadores={ordenadores}
                   columns={cardCols}
                   tableHeight={containerHeight()}
                   onDownload={() => efetuarDownload () }
                   pagPageSize={PAGE_SIZE_RESULTADO}
                   onRequestPage = {async () => {
                       return await pesquisaPaginacao();
                   }}
                   downloadDisabled={planoEhGratuito}
                   configDisabled={planoEhGratuito}
                   infiniteScroll={true}
                   onUpdatePageSize={onUpdatePageSize}
        />
        :
        <IY2BTable id={"tblResultado" + id} name={"tblResultado" + id}
                   formularioDeFiltro={eFormFiltro}
                   botaoAcoesDisponiveis={{
                       onClickMenu: executaAcao,
                       itensMenuAcoes: itensMenuAcoes,
                       disabled: (desabilitaAcoesDisp===true || itensMenuAcoes.length===0)
                   }}
                   enableRowCheck={true}
                   api={tableRef}
                   dataSource={dsResultado}
                   columns={tblCols}
                   tableHeight={tableHeight}
                   onDownload={() => efetuarDownload () }
                   pagPageSize={PAGE_SIZE_RESULTADO}
                   onRequestPage = {async () => {
                       return await pesquisaPaginacao();
                   }}
                   downloadDisabled={planoEhGratuito}
                   configDisabled={planoEhGratuito}
                   infiniteScroll={true}
                   onUpdatePageSize={onUpdatePageSize}
                   tableTitle={tableTitle}
        />
    ;

    return elemento;

}

// export default ResultadoComFiltro;

const mapStateToProps = function( { userSession } ) {
    return {
        userSession: userSession
    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultadoComFiltro);

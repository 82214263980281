//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../framework/assets/css/tema.scss';
import './style.scss';

import {
    notificacaoErroRequest,
    notificacaoErroValidForm, PADRAO_MARGEM_POPUP,
    useConteudoAlterado,
    useGridWidth,
    useModoExecucao,
    useStateRef,
    useWindowSize
} from "../../framework/lib/iy2b-react";
import React, {useEffect, useRef, useState} from "react";
import {campoInformado, ObjectUtils} from "../../framework/lib/iy2b-javascript";
import {postJSON, upload} from "../../framework/lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../framework/pages/Workspace/workspace";
import {Button, Col, Row, Space} from "antd";
import IY2BReactModal from "../../framework/componentes/IY2BReactModal";
import { ProForm,
    ProFormDatePicker,
    ProFormDigit,
    ProFormText,
    ProFormTextArea,
    ProFormTimePicker
} from "@ant-design/pro-components";
import ComboBox from "../../framework/componentes/proform/ComboBox/comboBox";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {useNextUpdate} from "../../framework/lib/useNextUpdate";
import {useFormState} from "../../framework/lib/useFormState";
import {useDecodeWidth} from "../../framework/lib/useDecodeWidth";
import Promessas from "../../framework/lib/Promessas";
import moment from "moment";
import AutoCompleteCliente from "../../componentes/AutoCompleteCliente/autoCompleteCliente";
import AutoComplete from "../../framework/componentes/proform/AutoComplete";
import {
    fetchFuncionariosDaEmpresa,
    fetchUsuariosDaEmpresa,
    pesquisarCredor,
    pesquisarJob,
    pesquisarUsuario
} from "../../lib/servicosPortalIY2B";
import FormFieldRepeater from "../../framework/componentes/proform/FormFieldRepeater";
import FoldedFormField from "../../framework/componentes/proform/FoldedFormField/foldedFormField";
import IY2BProFormDecimal from "../../framework/componentes/proform/IY2BProFormDecimal";
import IY2BHiddenRow from "../../framework/componentes/IY2BHiddenRow";
import IY2BProFormHidden from "../../framework/componentes/IY2BProFormHidden";
import NavegadorRegistro from "../../framework/componentes/NavegadorRegistro/navegadorRegistro";
import AutoCompletePessoa from "../../framework/componentes/proform/AutoCompletePessoa";
import {pesquisarContato} from "./atendimento";
import BotaoUpload from "../../framework/componentes/BotaoUpload/botaoUpload";
import ProFieldWrapper from "../../framework/componentes/proform/ProFieldWrapper/proFieldWrapper";
import REIf from "../../framework/componentes/REIf/reIF";
import IY2BProFormText from "../../framework/componentes/proform/IY2BProFormText/iy2bProFormText";
import {notificacao} from "../../framework/lib/Notificacao";
import IY2BProFormFiller from "../../framework/componentes/proform/IY2BProFormFiller/iy2bProFormFiller";
import FinalizarAtendimento from "./finalizarAtendimento";
import {smkPadrao} from "../../framework/lib/iy2b-smkpadrao";

const EditarAtendimento = ( props ) => {

    const { exibePopUp, onRequestClose, salvar, excluir, dataProvider, userSession, cdTransacao = 0, resultadoRefApi } = props;

    const { registros } = props;

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 0 : windowSize.widthMargin(PADRAO_MARGEM_POPUP.MD);
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = windowSize.heightMargin(PADRAO_MARGEM_POPUP.XS); // 30;
    const MARGIN_BOTTOM = windowSize.heightMargin(PADRAO_MARGEM_POPUP.MD); // 24;

    const modalRefApi = useRef();
    const formRef = useRef();
    const navegadorRefApi = useRef();

    const modoExecucao = useModoExecucao("INC");
    const conteudoAlterado = useConteudoAlterado();

    const [, setRegistroDaTela, refRegistroDaTela] = useStateRef( null);
    const [, setTelaProtegida, refTelaProtegida] = useStateRef(false);

    const forceUpdate = useForceUpdate();
    const nextUpdate = useNextUpdate();
    const formState = useFormState({ });
    const decodeWidth = useDecodeWidth();

    const noAtendimentoRef = useRef();

    const frItensRefApi = useRef();
    const frUsuariosRefApi = useRef();
    const frDoctosRefApi = useRef();

    const cmbCdTipoAtendimentoRefApi = useRef();
    const cmbCdStatusAtendimentoRefApi = useRef();
    const cmbCdPessoaUsrDestWFRefApi = useRef();
    const acClienteRefApi = useRef();
    const acCredorRepresRefApi = useRef();
    const acCredorRefApi = useRef();
    const acCreCaptacaoRefApi = useRef();
    const acNrJobRefApi = useRef();

    const [permiteFinalizar, setPermiteFinalizar] = useState(false);

    const [exibeFinalizar, setExibeFinalizar] = useState(false);

    const [stateHidden, setStateHidden, refStateHidden] = useStateRef({ });
    const [stateDisabled, setStateDisabled, refStateDisabled] = useStateRef({ });
    const [stateRequired, setStateRequired, refStateRequired] = useStateRef({ });

    useEffect(() => {

        if(campoInformado(registros)) {

            if(exibePopUp) {

                if(registros.length > 0) {

                    pesquisarPk(registros[0]);

                } else {

                    novoRegistro();

                }

            }

        } else {

            if(exibePopUp) {

                novoRegistro();

            }

        }

        return () => {

        };

    }, [props.registros]);

    useEffect(() => {

        return () => {

        };

    }, [props.dataProvider]);


    const pesquisarPk = async (registro) => {

        try {

            console.log(registro);

            novoRegistro();

            let registroDaTela = {
            };

            const promessas = new Promessas();

            promessas.add(async (resolve, reject) => {
                try {

                    const payload = {
                        acaoAExecutar: "acaoCRUD",
                        registro: {
                            atendimento: {
                                cdPessoaEmp: dataProvider.cdPessoaEmpAtual,
                                cdAtendimento: registro.cdAtendimento
                            },
                        },
                    };

                    const resultado = await postJSON({
                        acaoWebAExecutar: "pesquisarPk",
                        sistema: "crm",
                        projeto: "matendimento"
                    }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

                    resultado.mensagens.forEach(msg => {

                        if(msg.id === "modelo") {

                            registroDaTela = {
                                ...registroDaTela,
                                ...msg.modeloPadrao
                            };

                        } else {

                            console.log(msg);

                        }

                    });

                    resolve(true);

                } catch (e) {

                    console.log(e);

                    notificacaoErroRequest(e, cdTransacao);

                    reject (e);

                }
            });

            await promessas.run();

            console.log(registroDaTela);

            setRegistroNaTela(registroDaTela);

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const setRegistroNaTela = (registro) => {

        console.log("setRegistroNaTela", registro);

        const protegeTela = (registro.atendimento.dtFim.toMoment().ehDataZerada()) ? false : true;

        setTelaProtegida(protegeTela);

        setPermiteFinalizar(registro.atendimento.dtFim.toMoment().ehDataZerada() === true);

        const frDoctosRows = frDoctosRefApi.current.createRowsFrom(registro.conteudoArquivos, protegeTela);

        console.log(frDoctosRows);

        const agora = (new Date()).getTime();

        const rowDataFrDoctos = { };

        registro.conteudoArquivos.forEach((item, nrSeq) => {

            const url = dataProvider.webPautaDocto + "/" + item.nrWorkflow + "/" + item.noArquivo + "?&dt=" + agora ;

            console.log("item arquivos", item, url);

            const frDoctosR = frDoctosRows.filter(r => r.row === (nrSeq+1))[0];

            rowDataFrDoctos[frDoctosR.cdDocto] = item.cdDocto;
            rowDataFrDoctos[frDoctosR.noDocto] = item.noDocto;
            rowDataFrDoctos["ultArq_" + frDoctosR.doctoAnexo] = item.noArquivo;
            rowDataFrDoctos["url_" + frDoctosR.doctoAnexo] = url;

        });

        const registroDaTela = {
            ...ObjectUtils.valuesFrom(registro.atendimento, ["cdAtendimento", "noAtendimento", "txAtendimento", "txOrientacao", "txIndicacao", "noPessoaUsrCad"]),
            dtCad: registro.atendimento.dtCad.toMoment().toScreen(),
            dtFim: registro.atendimento.dtFim.toMoment().toScreen(),
            dtInicio: registro.atendimento.dtInicio.toMoment().toScreen(),
            dtPrazo: registro.atendimento.dtPrazo.toMoment().toScreen(),
            dtPrevisao: registro.atendimento.dtPrevisao.toMoment().toScreen(),
            vlrPrevisto: registro.atendimento.vlrPrevisto?.toScreen(2),
            hrCad: registro.atendimento.hrCad?.toMomentHour("HH:mm:ss").toScreen(),
            hrFim: registro.atendimento.hrFim?.toMomentHour("HH:mm:ss").toScreen(),
            hrInicio: registro.atendimento.hrInicio?.toMomentHour("HH:mm:ss").toScreen(),
            hrPrazo: registro.atendimento.hrPrazo?.toMomentHour("HH:mm:ss").toScreen(),
            ...rowDataFrDoctos,
        };

        registroDaTela.cmbCdTipoAtendimento = dataProvider?.dpTipoAtendimento.getBy("value", registro.atendimento.cdTipoAtendimento.cdTipoAtendimento);
        if(campoInformado(registroDaTela.cmbCdTipoAtendimento) === false) {
            smkPadrao.mensagemUI.popUp("error", "Ação: editar", "Você não tem permissão para visualizar este tipo de atendimento !");
            onRequestClose();
            return false;
        }
        registroDaTela.cmbCdStatusAtendimento = dataProvider?.dpStatusAtendimento.getBy("value", registro.atendimento.cdStatusAtendimento);
        registroDaTela.cmbCdMaterial = dataProvider?.dpMaterial.getBy("value", registro.atendimento.cdMaterial);

        if(campoInformado(registro.atendimento.cdMotivoFimAtendimento) === true) {
            const motivoFimAtend = dataProvider.dpMotivoFimAtendimento.getBy("value", registro.atendimento.cdMotivoFimAtendimento);
            registroDaTela.noMotivoFimAtendimento = motivoFimAtend.label;
        }

        if(campoInformado(registro.atendimento.cdPessoaFunc) === true) {
            registroDaTela.acFuncionario = {
                value: registro.atendimento.cdPessoaFunc,
                label: registro.atendimento.noFuncionario.trim(),
                data: {
                    cdPessoaFunc: registro.atendimento.cdPessoaFunc,
                }
            }
        }
        if(campoInformado(registro.atendimento.cdContato) === true) {
            registroDaTela.acContato = {
                value: registro.atendimento.cdContato,
                label: registro.atendimento.noContato.trim(),
                data: {
                    cdContato: registro.atendimento.cdContato,
                }
            }
        }

        trataTipoAtendimento(registroDaTela.cmbCdTipoAtendimento.data);

        const newStateHidden = {
            ...refStateHidden.current,
            "cmbCdPessoaUsrDestWF": true,
        }

        setStateHidden(newStateHidden);

        const newStateRequired = {
            ...refStateRequired.current,
            "cmbCdPessoaUsrDestWF": false,
        }

        setStateRequired(newStateRequired);

        const newStateDisabled = {
            ...refStateDisabled.current,
            acNrJob: true
        };

        setStateDisabled(newStateDisabled);

        // cliente, credores ...

        const acCliente = {
            cdCli: registro.atendimento.cdCli,
            cdPessoa: registro.atendimento.cdPessoaCli,
            noPessoa: registro.atendimento.noPessoaCli,
            nrCGCCIC: registro.atendimento.nrCGCCICCli,
            srcData: {
                cdCli: registro.atendimento.cdCli,
                cdPessoa: registro.atendimento.cdPessoaCli,
                noPessoa: registro.atendimento.noPessoaCli,
                nrCGCCIC: registro.atendimento.nrCGCCICCli,
            }
        }

        acClienteRefApi.current.setFieldValue(acCliente);

        const acCredor = {
            cdCredor: registro.atendimento.cdCredor,
            cdPessoa: registro.atendimento.cdPessoaCredor,
            nrCGCCIC: registro.atendimento.nrCGCCICCredor,
            noPessoa: registro.atendimento.noPessoaCredor,
            srcData: {
                cdCredor: registro.atendimento.cdCredor,
                cdPessoa: registro.atendimento.cdPessoaCredor,
                nrCGCCIC: registro.atendimento.nrCGCCICCredor,
                noPessoa: registro.atendimento.noPessoaCredor,
            }
        };

        acCredorRefApi.current.setFieldValue(acCredor);

        const acCreCaptacao = {
            cdCredor: registro.atendimento.cdCreCaptacao,
            cdPessoa: registro.atendimento.cdPessoaCreCaptacao,
            nrCGCCIC: registro.atendimento.nrCGCCICCreCaptacao,
            noPessoa: registro.atendimento.noPessoaCreCaptacao,
            srcData: {
                cdCredor: registro.atendimento.cdCreCaptacao,
                cdPessoa: registro.atendimento.cdPessoaCreCaptacao,
                nrCGCCIC: registro.atendimento.nrCGCCICCreCaptacao,
                noPessoa: registro.atendimento.noPessoaCreCaptacao,
            }
        };

        acCreCaptacaoRefApi.current.setFieldValue(acCreCaptacao);

        const acCredorRepres = {
            cdCredor: registro.atendimento.cdRepres,
            cdPessoa: registro.atendimento.cdPessoaRepres,
            nrCGCCIC: registro.atendimento.nrCGCCICRepres,
            noPessoa: registro.atendimento.noPessoaRepres,
            srcData: {
                cdCredor: registro.atendimento.cdRepres,
                cdPessoa: registro.atendimento.cdPessoaRepres,
                nrCGCCIC: registro.atendimento.nrCGCCICRepres,
                noPessoa: registro.atendimento.noPessoaRepres,
            }
        };

        acCredorRepresRefApi.current.setFieldValue(acCredorRepres);

        const acNrJobValue = {
            value: registro.atendimento.nrJob,
            label: registro.atendimento.noJob,
            data: {
                cdCMemo: registro.atendimento.nrJob
            }
        };

        acNrJobRefApi.current.setFieldValue(acNrJobValue);

        setRegistroDaTela ( registroDaTela );

        formRef.current?.setFieldsValue( registroDaTela );

        forceUpdate();

        controleExibicao ( registroDaTela );

        modoExecucao.setModoExecAlteracao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    };

    const controleExibicao = (registro) => {


    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();

            if(modoExecucao.isAlteracao()===true) {

                noAtendimentoRef.current?.focus();

            } else {

                cmbCdTipoAtendimentoRefApi.current?.focus();

            }

        }, 1);

    }


    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        console.log("getRegistroDaTela.ffValues", ffValues);

        let {dtCad, dtFim, dtInicio, dtPrazo, dtPrevisao} = ffValues;

        if(campoInformado(dtCad)===false) dtCad = window.dataZerada;
        if(campoInformado(dtFim)===false) dtFim = window.dataZerada;
        if(campoInformado(dtInicio)===false) dtInicio = window.dataZerada;
        if(campoInformado(dtPrazo)===false) dtPrazo = window.dataZerada;
        if(campoInformado(dtPrevisao)===false) dtPrevisao = window.dataZerada;

        const {hrCad, hrFim, hrInicio, hrPrazo} = ffValues;

        const registro = {
            atendimento: {
                ...ObjectUtils.valuesFrom(ffValues,["cdAtendimento", "noAtendimento", "txAtendimento", "txOrientacao", "txIndicacao"]),
                cdPessoaEmp: dataProvider.cdPessoaEmpAtual,
                cdTipoAtendimento: {
                    cdTipoAtendimento: ffValues.cmbCdTipoAtendimento.data.cdTipoAtendimento,
                    flgExibeCliente: ffValues.cmbCdTipoAtendimento.data.flgExibeCliente,
                    flgExibeCredor: ffValues.cmbCdTipoAtendimento.data.flgExibeCredor,
                    flgOrigemJob: ffValues.cmbCdTipoAtendimento.data.flgOrigemJob,
                },
                cdPessoaCli: ffValues.acCliente_NrCGCCIC?.value,
                "nrJob": ffValues.acNrJob?.value,
                cdPessoaCredor: ffValues.acCredor_NrCGCCIC?.value,
                cdPessoaRepres: ffValues.acCredorRepres_NrCGCCIC?.value,
                cdPessoaCreCaptacao: ffValues.acCreCaptacao_NrCGCCIC?.value,
                cdPessoaFunc: ffValues.acFuncionario?.value,
                cdMaterial: ffValues.cmbCdMaterial?.value,
                cdContato: ffValues.acContato?.value,
                "cdPessoaUsrDestWF": ffValues.cmbCdPessoaUsrDestWF?.value,
                cdPessoaUsrCad: userSession.cdPessoaUsr,
                "dtInicio": dtInicio.format("YYYY/MM/DD"),
                "dtCad": dtCad.format("YYYY/MM/DD"),
                "dtPrazo": dtPrazo.format("YYYY/MM/DD"),
                "dtPrevisao": dtPrevisao.format("YYYY/MM/DD"),
                "dtFim": dtFim.format("YYYY/MM/DD"),
                vlrPrevisto: ffValues.vlrPrevisto?.toDecimal(2),
                hrCad: hrCad?.format("HHmm"),
                hrFim: hrFim?.format("HHmm"),
                hrInicio: hrInicio?.format("HHmm"),
                hrPrazo: hrPrazo?.format("HHmm"),
            },
            "conteudoArquivos": [],
            "conteudoWorkflowUsuarios": [],
            "conteudoString": [],
            "conteudoValores": [],
            "conteudoCombo": []
        };

        if(modoExecucao.isInclusao()===true) {
            // enviar apenas na inclusão

            const listaDoctos = frDoctosRefApi.current.getFieldValue(ffValues);

            registro.conteudoArquivos.addAll(listaDoctos.map(docto => {
                return {
                    "cdDocto": docto.cdDocto,
                    "noArquivo": docto.doctoAnexo[0].response.nomeArquivo,
                    "noDocto": docto.noDocto,
                }
            }));

        }

        console.log("getRegistroDaTela.registro", registro);

        return registro;

    }

    const doExcluir = async () => {

    }

    const trataTipoAtendimento = async (value) => {

        if(value === null || value === undefined) {

            setStateHidden({
                "acCliente": true,
                "acCredor": true,
                "acCredorRepres": true,
                "acFuncionario": true,
                "txIndicacao": true,
                "cmbCdMaterial": true,
                "acCreCaptacao": true,
                "txOrientacao": true,
                "acContato": true,
                "acNrJob": true,
                "dtPrevisao": true,
                "dtPrazo": true,
                "vlrPrevisto": true,
                "cmbCdPessoaUsrDestWF": false,
            });

            setStateRequired({
                "acCliente": false,
                "acCredor": false,
                "acCredorRepres": false,
                "acFuncionario": false,
                "txIndicacao": false,
                "cmbCdMaterial": false,
                "acCreCaptacao": false,
                "txOrientacao": false,
                "acContato": false,
                "acNrJob": false,
                "dtPrevisao": false,
                "dtPrazo": false,
                "vlrPrevisto": false
            });

            acClienteRefApi?.current?.setFieldValue(null);

            acNrJobRefApi?.current?.setFieldValue(null);

        } else {

            const newState = {
                ...stateHidden,
                "acCliente": !(value.flgExibeCliente === "S"),
                "acCredor": !(value.flgExibeCredor === "S"),
                "acFuncionario": !(value.flgExibeFuncSmk === "S"),
                "txIndicacao": !(value.flgExibeIndicacao === "S"),
                "cmbCdMaterial": !(value.flgExibeMaterial === "S"),
                "txOrientacao": !(value.flgExibeOrientacao === "S"),
                "vlrPrevisto": !(value.flgExibeValor === "S"),
                "acContato": !(value.flgExibeContato === "S"),
                "acNrJob": !(value.flgExibeJob === "S"),
                "dtPrevisao": !(value.flgExibePrevisao === "S"),
                "dtPrazo": !(value.flgExibePrazo === "S"),
                "acCreCaptacao": !(value.flgExibeCreCaptacao === "S"),
                "acCredorRepres": !(value.flgExibeRepres === "S"),
                "cmbCdPessoaUsrDestWF": false,
            }

            // console.log(value, newState);

            setStateHidden(newState);

            const newStateDisabled = {
              ...stateDisabled
            };

            const newStateRequired = {
                ...stateRequired,
                "acContato": (value.flgExibeContato === "S"),
                "acCreCaptacao": (value.flgExibeCreCaptacao === "S"),
                "acCredorRepres": (value.flgExibeRepres === "S"),
                "cmbCdMaterial": (value.flgExibeMaterial === "S"),
                "acCredor": (value.flgExibeCredor === "S"),
                "acFuncionario": (value.flgExibeFuncSmk === "S"),
                "dtPrevisao": (value.flgExibePrevisao === "S"),
                "dtPrazo": (value.flgExibePrazo === "S"),
                "txIndicacao": (value.flgExibeIndicacao === "S"),
                "txOrientacao": (value.flgExibeOrientacao === "S"),
            };

            if(value.flgClienteUSR === "S") {

                newStateDisabled.acCliente = true;

                newStateRequired.acCliente = true;

                if(dataProvider.dpClienteUsuario.isEmpty() === true) {

                    acClienteRefApi.current.setFieldValue(null);

                } else {

                    const cliente = dataProvider.dpClienteUsuario[0].data;

                    const acCliente = {
                        cdCli: cliente.cdCli,
                        cdPessoa: cliente.cdPessoaCli.cdPessoa,
                        noPessoa: cliente.cdPessoaCli.noPessoa,
                        nrCGCCIC: cliente.cdPessoaCli.nrCGCCIC,
                        srcData: {
                            cdCli: cliente.cdCli,
                            cdPessoa: cliente.cdPessoaCli.cdPessoa,
                            noPessoa: cliente.cdPessoaCli.noPessoa,
                            nrCGCCIC: cliente.cdPessoaCli.nrCGCCIC,
                        }
                    }

                    acClienteRefApi.current.setFieldValue(acCliente);

                }

            } else {

                acClienteRefApi.current.setFieldValue(null);

                if(value.flgExibeCliente === "S") {

                    newStateDisabled.acCliente = false;
                    newStateRequired.acCliente = true;

                } else {

                    newStateDisabled.acCliente = true;
                    newStateRequired.acCliente = false;

                }

            }

            newStateDisabled.acNrJob = true;

            newStateRequired.acNrJob = false;

            if(value.flgOrigemJob === "C") {

                let acNrJobValue = null;

                if (dataProvider.dpJobPadraoClienteUsuario.length > 0) {

                    const job = dataProvider.dpJobPadraoClienteUsuario[0];

                    acNrJobValue = {
                        ...job
                    };

                }

                acNrJobRefApi.current.setFieldValue(acNrJobValue);

            } else if(value.flgOrigemJob === "E") {

                let acNrJobValue = null;

                if (dataProvider.dpJobPadraoEmpresa.length > 0) {

                    const job = dataProvider.dpJobPadraoEmpresa[0];

                    acNrJobValue = {
                        ...job
                    };

                }

                acNrJobRefApi.current.setFieldValue(acNrJobValue);

            } else if(value.flgOrigemJob === "P") {

                let acNrJobValue = null;

                if (dataProvider.dpJobPadraoParametro.length > 0) {

                    const job = dataProvider.dpJobPadraoParametro[0];

                    acNrJobValue = {
                        ...job
                    };

                }

                acNrJobRefApi.current.setFieldValue(acNrJobValue);

            } else if(value.flgOrigemJob === "I") {

                newStateDisabled.acNrJob = false;

                newStateRequired.acNrJob = true;

                acNrJobRefApi.current.setFieldValue(null);

            } else if(value.flgOrigemJob === "G") {

                acNrJobRefApi.current.setFieldValue(null);

            } else {

                newStateDisabled.acNrJob = false;

                newStateRequired.acNrJob = true;

                acNrJobRefApi.current.setFieldValue(null);

            }

            newStateDisabled.cmbCdPessoaUsrDestWF = false;

            newStateRequired.cmbCdPessoaUsrDestWF = true;

            const registroDaTela = { };

            if(campoInformado(value.cdPessoaUsrDestWF) === true) {

                console.log(value.cdPessoaUsrDestWF.cdPessoaUsr.cdPessoa, dataProvider.dpUsuarioDestWF);

                const dpUsrDest = dataProvider.dpUsuarioDestWF.filter(item => item.value === value.cdPessoaUsrDestWF.cdPessoaUsr.cdPessoa);

                if(dpUsrDest.isEmpty() === false) {

                    registroDaTela.cmbCdPessoaUsrDestWF = dpUsrDest[0];

                    newStateDisabled.cmbCdPessoaUsrDestWF = true;

                    newStateRequired.cmbCdPessoaUsrDestWF = false;

                } else {

                    registroDaTela.cmbCdPessoaUsrDestWF = null;

                }

            } else {

                registroDaTela.cmbCdPessoaUsrDestWF = null;

            }

            registroDaTela.acContato = null;
            registroDaTela.acCreCaptacao = null;
            registroDaTela.acCredorRepres = null;
            registroDaTela.cmbCdMaterial = null;
            registroDaTela.acCredor = null;
            registroDaTela.acFuncionario = null;

            console.log("trataTipoAtendimento", registroDaTela);

            setStateDisabled(newStateDisabled);

            setStateRequired(newStateRequired);

            formRef.current?.setFieldsValue( registroDaTela );

        }

    }

    const novoRegistro = () => {

        console.log("novoRegistro");

        formRef?.current?.resetFields();

        setTelaProtegida ( false ) ;

        // console.log(userSession.cdPessoaUsr);

        setRegistroDaTela ({ });

        formState.clear({ });

        trataTipoAtendimento();

        controleExibicao ( );

        modoExecucao.setModoExecInclusao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const validaTela = async (params) => {

        let resultado = true;

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        return resultado;

    };

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return;

            if(conteudoAlterado.isConteudoAlterado() === false) {
                notificacao.aviso({message:"Sem alteração", description:"Nenhuma informação alterada para ser salva !"})
                return;
            }

            const registro = getRegistroDaTela();

            const resultado = await salvar (registro);

            if(resultado === false) return;

            if(navegadorRefApi.current.temProximo()) {

                navegadorRefApi.current.navegaProximo();

            } else {

                if(registros.length === 1) {

                    novoRegistro();

                } else {

                    onRequestClose();

                }

            }

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e, cdTransacao);

        }

    }

    // fetchOptions={pesquisarUsuario}
    const [dprUsuarios] = useState([
        <Col span={24} >
            <AutoComplete
                formRef = { formRef }
                label={"Usuário"}
                name={"acCdPessoaUsr"}
                tooltip={"Usuário para acompanhar o atendimento"}
                placeholder={"Informe um usuário para acompanhar o atendimento"}
                rules={[
                ]}
                width={"xl"}
                fetchOptions={fetchUsuariosDaEmpresa(userSession.cdPessoaEmpAtual)}
            />
        </Col>,
    ]);

    const propsUpload = {
        maxCount: 1,
        showUploadList: false,
        multiple: false,
        acaoWebAExecutar:"processarnegocio",
        sistema:"suite",
        projeto:"admupload",
        acaoAExecutar: "enviaArquivo",
        prCmdUpload: (msg, file, onSuccess) => {

            if(msg.id === "listaArquivosRecebidos") {

                onSuccess(msg.lista[0], file);

            } else if(msg.id==="resultado") {

                notificacao.sucesso({
                    message: 'Sucesso !',
                    description: msg.mensagem,
                });

            } else {

                console.log(msg);

            }

        },
        onFinish: async (files) => {

            forceUpdate();

        },
        customRequest: async (params) => {

            const {
                file,
                onError,
                onSuccess
            } = params;

            // http://desenvsmk:8080/systemakers/suite/admupload.processarnegocio.smk?TXCONFIG=desenv&lm=1639481497289&habilitaEnvioASP=N

            try {

                console.log("customRequest editarAtendimento");

                const resultado = await upload({
                    acaoWebAExecutar:"processarnegocio",
                    sistema:"suite",
                    projeto:"admupload",
                    headers: {
                        acaoAExecutar: "enviaArquivo",
                        acaoWebAExecutar: "ProcessarNegocioUpload",
                    },
                    rest: true
                }, {
                    "filedata": file,
                    "multi-files": "N"
                }, WORKSPACE_LOADING_INDICATOR_AREA);

                resultado.mensagens.forEach(msg => {

                    if(msg.id === "listaArquivosRecebidos") {

                        onSuccess(msg.lista[0], file);

                    } else if(msg.id==="resultado") {

                        notificacao.sucesso({
                            message: 'Sucesso !',
                            description: msg.mensagem,
                        });

                    } else {

                        console.log(msg);

                    }

                });

            } catch (e) {

                console.log(e);

                onError(e);

                notificacao.erro({message: "Ooops!", description:"Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !"})

            }

            return {
                abort: () => {
                    console.log('upload progress is aborted.');
                }
            };
        }
    };

    const [dprDoctos] = useState([
        <Col xs={24} md={12} lg={12}>
            <BotaoUpload
                formRef = { formRef }
                label="Documento"
                name="doctoAnexo"
                title={"Enviar documento"}
                placeholder={"Último documento recebido"}
                propsUpload={{...propsUpload}}
                rules={[
                    {required: true, message: 'Faça o envio do documento'},
                ]}
                clearFFAfterFinish={false}
                changeDisabled
            />
        </Col>,
        <Col xs={24} md={12} lg={12}>
            <IY2BProFormText
                formRef = { formRef }
                width={"lg"}
                name = "noDocto"
                label = "Descrição deste anexo"
                tooltip = "Descrição deste anexo do atendimento"
                placeholder={"Digite uma descrição para este anexo"}
                rules={[
                    {required: true, message: 'Informe uma descrição deste documento'},
                ]}
                ffrDisabled={() => refTelaProtegida.current===true || modoExecucao.isAlteracao()===true }
            />
        </Col>,
        <ProFieldWrapper hidden={true}>
            <IY2BProFormHidden name={"cdDocto"}/>
        </ProFieldWrapper>,
        <ProFieldWrapper hidden={true}>
            <IY2BProFormHidden name={"urlDocto"}/>
        </ProFieldWrapper>
    ]);

    const cmbCdTipoAtendimento_OnChange = async (value) => {

        await trataTipoAtendimento(value?.data);

    }

    const acCliente_OnChange = async (value) => {
        //console.log("acCliente_OnChange", value);
        // acContato

        formRef.current?.setFieldsValue( {acContato: null} );

    }

    const navegaParaRegistro = async (posicao) => {

        await pesquisarPk(registros[posicao - 1]);

    }

    const exibirFinalizar = () => {

        setExibeFinalizar( true);

    }

    const finalizarAtend = async (registro) => {

        try {

            const registroDaTela = getRegistroDaTela();

            const resposta = {

            };

            const payload = {
                acaoAExecutar: "processaListaAtendimento",
                lista: [registroDaTela.atendimento],
                ...registro
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "processarlista",
                sistema: "crm",
                projeto: "mfinalizaatendimento"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "listaAtendimentoFinalizados") {

                    const atendimentoAtu = msg.lista[0];

                    const dsItem = resultadoRefApi.current?.findKey( atendimentoAtu.cdAtendimento );

                    dsItem.noStatusAtendimento = atendimentoAtu.noStatusAtendimento;

                } else {

                    console.log(msg);

                }

            });

            onRequestClose();

            return resposta;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const popupTitle = modoExecucao.isInclusao() ? "Novo atendimento" : "Atendimento";

    const fiValues = {
        noPessoaUsrCad: userSession?.noPessoa,
        dtCad: moment(),
        hrCad: moment(),
        dtInicio: moment(),
        hrInicio: moment(),
    }

    const [dprItens] = useState([
    ]);

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={MARGIN_BOTTOM}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"editar-atendimento"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-editar-atendimento"}>
                    <ProForm
                        formRef = { formRef }
                        isKeyPressSubmit = {true}
                        initialValues={fiValues}
                        onFinish = { async ( ) => {
                            if(refTelaProtegida.current === true) return;
                            await doSalvar();
                        }}
                        submitter={{
                            // Completely customize the entire area
                            render : ( props , doms ) => {
                                return null;
                            }
                        }}
                        onValuesChange={async (changeValues, allValues) => {
                            conteudoAlterado.fireOnValueChange(changeValues, allValues, "cmbCdTipoAtendimento", cmbCdTipoAtendimento_OnChange, true);
                            conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCliente_NoPessoa", acCliente_OnChange, true);
                            conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCliente_NrCGCCIC", acCliente_OnChange, true);

                            /*
                            conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCentroCusto", acCentroCusto_OnChange);
                            conteudoAlterado.fireOnValueChange(changeValues, allValues, "vlrDesconto", onUpdateVlrTotal);
                            conteudoAlterado.fireOnValueChange(changeValues, allValues, "flgOrcamento", flgOrcamento_OnChange);
                            conteudoAlterado.fireOnChangeFFR(changeValues, allValues, "itens", "vlrOrcPecas", onUpdateVlrTotal);
                            conteudoAlterado.fireOnChangeFFR(changeValues, allValues, "itens", "vlrOrcServ", onUpdateVlrTotal);
                            */
                        }}
                        onFieldsChange = {async (changedFields) => {
                            conteudoAlterado.updateConteudoAlterado(changedFields);
                        }}
                    >
                        <div className={"pro-form-group-spacer"}>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={6} md={6} lg={5}>
                                        <ProFormDigit
                                            width={"sm"}
                                            label="Atendimento"
                                            name="cdAtendimento"
                                            placeholder = "Número do atendimento"
                                            tooltip = { "Número do atendimento" }
                                            fieldProps={{ precision: 0 }}
                                            rules={[
                                            ]}
                                            disabled
                                        />
                                    </Col>
                                    <Col xs={24} md={16} lg={11}>
                                        <ComboBox
                                            width={"lg"}
                                            formRef = { formRef }
                                            api = { cmbCdTipoAtendimentoRefApi }
                                            name = "cmbCdTipoAtendimento"
                                            label = "Tipo"
                                            tooltip = "Tipo de atendimento"
                                            placeholder={"Selecione um tipo de atendimento"}
                                            options={dataProvider.dpTipoAtendimento}
                                            rules={[
                                                {required: true, message:"Informe o tipo de atendimento"},
                                            ]}
                                            disabled={refTelaProtegida.current===true || modoExecucao.isAlteracao()===true}
                                        />
                                    </Col>
                                    <Col xs={24} md={16} lg={8}>
                                        <ComboBox
                                            width={"lg"}
                                            formRef = { formRef }
                                            api = { cmbCdStatusAtendimentoRefApi }
                                            name = "cmbCdStatusAtendimento"
                                            label = "Status"
                                            tooltip = "Status do atendimento"
                                            placeholder={"Selecione um status do atendimento"}
                                            options={dataProvider.dpStatusAtendimento}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Group>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={24} md={12}>
                                        <ProFormText
                                            width={"xl"}
                                            name = "noAtendimento"
                                            label = "Assunto"
                                            tooltip = "Informe o assunto deste atendimento"
                                            placeholder={"Qual o assunto deste atendimento ?"}
                                            rules={[
                                                {required: true, message:"Informe o assunto deste atendimento"},
                                                {type:"string", message:"No máximo 80 caracteres", max: 80}
                                            ]}
                                            fieldProps={{
                                                ref: noAtendimentoRef
                                            }}
                                            disabled={refTelaProtegida.current}
                                        />
                                    </Col>
                                    <REIf state={refStateHidden.current} hideFor={"txIndicacao"} keepWidth={490} col xs={24} md={12}>
                                        <ProFormText
                                            width={"xl"}
                                            name = "txIndicacao"
                                            label = "Indicação"
                                            tooltip = "Informe a indicação deste atendimento"
                                            placeholder={"Qual a indicação deste atendimento ?"}
                                            rules={[
                                                {required: (refStateRequired.current.txIndicacao === true), message:"Informe a indicação do atendimento"},
                                                {type:"string", message:"No máximo 80 caracteres", max: 80}
                                            ]}
                                            disabled={refTelaProtegida.current || (refStateDisabled.current.txIndicacao === true)}
                                        />
                                    </REIf>
                                </Row>
                            </ProForm.Group>
                            <REIf state={refStateHidden.current} hideForAll={["acCliente", "acCredorRepres"]}>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <REIf state={refStateHidden.current} hideFor={"acCliente"} notExists={"for"} col xs={12}>
                                            <AutoCompleteCliente habilitaAdicao
                                                                 width={"sm-xl"}
                                                                 formRef = { formRef }
                                                                 api = { acClienteRefApi }
                                                                 name = "acCliente"
                                                                 label = "Cliente"
                                                                 placeholder = "Cliente do atendimento"
                                                                 tooltip={"Selecione um cliente do atendimento"}
                                                                 attrId={"cdCli"}
                                                                 rules={[
                                                                     {required: (refStateRequired.current.acCliente === true), message:"Informe o cliente do atendimento"},
                                                                 ]}
                                                                 disabled={refTelaProtegida.current || (refStateDisabled.current.acCliente === true)}
                                                                 cdPessoaEmpAtual={dataProvider.cdPessoaEmpAtual}
                                                                 onNovoCliente = {acCliente_OnChange}
                                            />
                                        </REIf>
                                        <REIf state={refStateHidden.current} hideFor={"acCredorRepres"} keepWidth={490} col xs={12} >
                                                <AutoCompletePessoa
                                                    autoFocus
                                                    width={"sm-xl"}
                                                    formRef = { formRef }
                                                    api = { acCredorRepresRefApi }
                                                    name = "acCredorRepres"
                                                    label = "Representante"
                                                    placeholder = "Representante"
                                                    tooltip={"Selecione um representante"}
                                                    attrId={"cdCredor"}
                                                    onRequestCGCCIC={async (value) => {
                                                        return await pesquisarCredor({nrCGCCIC: value});
                                                    }}
                                                    onRequestNome={async (value) => {
                                                        return await pesquisarCredor({noPessoa: value});
                                                    }}
                                                    rules={[
                                                        {required: (refStateRequired.current.acCredorRepres === true), message:"Informe o representante do atendimento"},
                                                    ]}
                                                    disabled={refTelaProtegida.current || (refStateDisabled.current.acCredorRepres === true)}
                                                />
                                        </REIf>
                                    </Row>
                                </ProForm.Group>
                            </REIf>
                            <REIf state={refStateHidden.current} hideForAll={["acCredor", "acFuncionario"]}>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <REIf state={refStateHidden.current} hideFor={"acCredor"} notExists={"for"} col xs={12}>
                                                <AutoCompletePessoa
                                                    autoFocus
                                                    width={"xl"}
                                                    formRef = { formRef }
                                                    api = { acCredorRefApi }
                                                    name = "acCredor"
                                                    label = "Credor"
                                                    placeholder = "Credor"
                                                    tooltip={"Selecione um Credor"}
                                                    attrId={"cdCredor"}
                                                    onRequestCGCCIC={async (value) => {
                                                        return await pesquisarCredor({nrCGCCIC: value});
                                                    }}
                                                    onRequestNome={async (value) => {
                                                        return await pesquisarCredor({noPessoa: value});
                                                    }}
                                                    rules={[
                                                        {required: (refStateRequired.current.acCredor === true), message:"Informe um credor"},
                                                    ]}
                                                    disabled={refTelaProtegida.current || (refStateDisabled.current.acCredor === true)}
                                                />
                                        </REIf>
                                        <REIf state={refStateHidden.current} hideFor={"acFuncionario"} keepWidth={490} col xs={12}>
                                                <AutoComplete
                                                    formRef={formRef}
                                                    label={"Funcionário"}
                                                    name={"acFuncionario"}
                                                    tooltip={"Funcionário"}
                                                    placeholder={"Informe um funcionário"}
                                                    width={"xl"}
                                                    fetchOptions={fetchFuncionariosDaEmpresa(userSession.cdPessoaEmpAtual)}
                                                    rules={[
                                                        {required: (refStateRequired.current.acFuncionario === true), message:"Informe o funcionário do atendimento"},
                                                    ]}
                                                    disabled={refTelaProtegida.current || (refStateDisabled.current.acFuncionario === true)}
                                                />
                                        </REIf>
                                        <Col span={12}>
                                            <IY2BProFormFiller />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                            </REIf>
                            <REIf state={refStateHidden.current} hideForAll={["acContato", "acNrJob"]}>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <REIf state={refStateHidden.current} hideFor={"acContato"} notExists={"for"} col xs={12}>
                                            <AutoComplete
                                                width={"xl"}
                                                formRef={formRef}
                                                label={"Contato"}
                                                name={"acContato"}
                                                tooltip={"Contato"}
                                                placeholder={"Informe um contato"}
                                                rules={[
                                                    {required: (refStateRequired.current.acContato === true), message:"Informe um contato"},
                                                ]}
                                                fetchOptions={async (value) => {
                                                    const ffValues = formRef.current?.getFieldsValue();
                                                    return await pesquisarContato({
                                                        codigo: parseInt(ffValues.acCliente_NrCGCCIC.value),
                                                        nome: value
                                                    });
                                                }}
                                                disabled={refTelaProtegida.current || (refStateDisabled.current.acContato === true)}
                                            />
                                        </REIf>
                                        <REIf state={refStateHidden.current} hideFor={"acNrJob"} keepWidth={490} col xs={12}>
                                            <AutoComplete
                                                formRef = { formRef }
                                                label={"Projeto"}
                                                name={"acNrJob"}
                                                api = { acNrJobRefApi }
                                                tooltip={"Especifique o projeto responsável"}
                                                placeholder={"Informe um projeto"}
                                                rules={[
                                                    {required: (refStateRequired.current.acNrJob === true), message:"Informe um projeto"},
                                                ]}
                                                width={"xl"}
                                                fetchOptions={pesquisarJob}
                                                disabled={refTelaProtegida.current || (refStateDisabled.current.acNrJob === true)}
                                            />
                                        </REIf>
                                        <Col span={12}>
                                            <IY2BProFormFiller />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                            </REIf>
                            <REIf state={refStateHidden.current} hideForAll={["acCreCaptacao", "cmbCdMaterial"]}>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <REIf state={refStateHidden.current} hideFor={"acCreCaptacao"} notExists={"for"} col xs={12} >
                                                <AutoCompletePessoa
                                                    autoFocus
                                                    width={"xl"}
                                                    formRef = { formRef }
                                                    api = { acCreCaptacaoRefApi }
                                                    name = "acCreCaptacao"
                                                    label = "Captador"
                                                    placeholder = "Captador"
                                                    tooltip={"Selecione um captador"}
                                                    attrId={"cdCredor"}
                                                    onRequestCGCCIC={async (value) => {
                                                        return await pesquisarCredor({nrCGCCIC: value});
                                                    }}
                                                    onRequestNome={async (value) => {
                                                        return await pesquisarCredor({noPessoa: value});
                                                    }}
                                                    rules={[
                                                        {required: (refStateRequired.current.acCreCaptacao === true), message:"Informe um captador"},
                                                    ]}
                                                    disabled={refTelaProtegida.current || (refStateDisabled.current.acCreCaptacao === true)}
                                                />
                                        </REIf>
                                        <REIf state={refStateHidden.current} hideFor={"cmbCdMaterial"} keepWidth={490} col xs={12}>
                                            <ComboBox
                                                width={"lg"}
                                                formRef = { formRef }
                                                name = "cmbCdMaterial"
                                                label = "Tipo"
                                                tooltip={"Selecione um serviço"}
                                                placeholder={"Informe o serviço"}
                                                options={dataProvider.dpMaterial}
                                                rules={[
                                                    {required: (refStateRequired.current.cmbCdMaterial === true), message:"Informe um serviço"},
                                                ]}
                                                disabled={refTelaProtegida.current || (refStateDisabled.current.cmbCdMaterial === true)}
                                            />

                                        </REIf>
                                        <Col span={12}>
                                            <IY2BProFormFiller />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                            </REIf>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={12} md={6} lg={4}>
                                        <ProFormDatePicker
                                            width={"sm"}
                                            name="dtInicio"
                                            label="Início"
                                            tooltip={"Data de início deste atendimento"}
                                            placeholder={"Iniciado em"}
                                            format={"DD/MM/YYYY"}
                                            showToday={true}
                                            rules={[
                                                {required: true, message: "Informe a data de inicio"},
                                            ]}
                                            disabled={refTelaProtegida.current}
                                        />
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <ProFormTimePicker
                                            width={"xs"}
                                            name="hrInicio"
                                            label="as"
                                            tooltip = "Hora de início"
                                            placeholder = "Horário"
                                            format={"HH:mm"}
                                            showToday={true}
                                            rules={[
                                                {required: true, message: "Informe o horário de inicio"},
                                            ]}
                                            disabled={refTelaProtegida.current}
                                        />
                                    </Col>
                                    <REIf state={refStateHidden.current} hideFor={"dtPrevisao"} col xs={12} md={6} lg={4} keepWidth={160}>
                                        <ProFormDatePicker
                                            width={"sm"}
                                            name="dtPrevisao"
                                            label="Previsão"
                                            tooltip={"Data de previsão de término deste atendimento"}
                                            placeholder={"Término previsto em"}
                                            format={"DD/MM/YYYY"}
                                            showToday={true}
                                            rules={[
                                                {required: (refStateRequired.current.dtPrevisao === true), message:"Informe a previsão de termino do atendimento"},
                                            ]}
                                            disabled={refTelaProtegida.current}
                                        />
                                    </REIf>
                                    <REIf state={refStateHidden.current} hideFor={"dtPrazo"} col xs={12} md={6} lg={4} keepWidth={160}>
                                        <ProFormDatePicker
                                            width={"sm"}
                                            name="dtPrazo"
                                            label="Prazo"
                                            tooltip={"Data do prazo deste atendimento"}
                                            placeholder={"Prazo em"}
                                            format={"DD/MM/YYYY"}
                                            showToday={true}
                                            rules={[
                                                {required: (refStateRequired.current.dtPrazo === true), message:"Informe a data do prazo do atendimento"},
                                            ]}
                                            disabled={refTelaProtegida.current}
                                        />
                                    </REIf>
                                    <REIf state={refStateHidden.current} hideFor={"dtPrazo"} col xs={12} md={5} lg={3} keepWidth={100}>
                                        <ProFormTimePicker
                                            width={"xs"}
                                            name = "hrPrazo"
                                            label = "as"
                                            tooltip = "Hora do prazo"
                                            placeholder = "Horário"
                                            format={"HH:mm"}
                                            showToday={true}
                                            rules={[
                                                {required: (refStateRequired.current.dtPrazo === true), message:"Informe o horario do prazo do atendimento"},
                                            ]}
                                            disabled={refTelaProtegida.current}
                                        />
                                    </REIf>
                                    <REIf state={refStateHidden.current} hideFor={"vlrPrevisto"} col xs={12} md={6} lg={4} keepWidth={160}>
                                        <IY2BProFormDecimal
                                            formRef = { formRef }
                                            width={"sm"}
                                            label = { "Valor previsto" }
                                            name={"vlrPrevisto"}
                                            tooltip = { "Valor previsto" }
                                            placeholder={ "Previsão de custos" }
                                            rules={[
                                            ]}
                                            precision={2}
                                            disabled={refTelaProtegida.current}
                                        />
                                    </REIf>
                                </Row>
                            </ProForm.Group>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={24} md={12}>
                                        <ProFormTextArea
                                            width={"xl"}
                                            name="txAtendimento"
                                            label="Descrição"
                                            placeholder="Descreva a necessidade de atendimento"
                                            tooltip={"Descrição da necessidade de atendimento"}
                                            rules={[
                                                {required: true, message: "Informe a descrição"},
                                                {type:"string", message:"No máximo 500 caracteres", max: 500}
                                            ]}
                                            fieldProps={{
                                                showCount: true,
                                            }}
                                            disabled={refTelaProtegida.current}
                                        />
                                    </Col>
                                    <REIf state={refStateHidden.current} hideFor={"txOrientacao"} keepWidth={490} col xs={12}>
                                        <ProFormTextArea
                                            width={"xl"}
                                            name="txOrientacao"
                                            label="Orientação"
                                            placeholder="Faça comentários pertinentes para orientar o atendimento"
                                            tooltip={"Orientações para o atendimento"}
                                            rules={[
                                                {required: (refStateRequired.current.txOrientacao === true), message:"Informe comentários de orientação do atendimento"},
                                                {type:"string", message:"No máximo 500 caracteres", max: 500}
                                            ]}
                                            fieldProps={{
                                                showCount: true,
                                            }}
                                            disabled={refTelaProtegida.current || (refStateDisabled.current.txOrientacao === true)}
                                        />
                                    </REIf>
                                </Row>
                            </ProForm.Group>
                            <FoldedFormField label={"Documentos anexados a este atendimento"} fullWidth gapAfter>
                                <FormFieldRepeater
                                    name={"doctos"}
                                    formRef = { formRef }
                                    fields={dprDoctos}
                                    api={frDoctosRefApi}
                                    novaLinhaAdicionada={(posName) => {
                                        if(posName > 1) {
                                            modalRefApi?.current?.bodyScrollBottom();
                                        }
                                    }}
                                    nolabels
                                    titleAddFirstLine={"Adicionar anexo"}
                                    disabled={refTelaProtegida.current || modoExecucao.isAlteracao()}
                                />
                            </FoldedFormField>
                            <FoldedFormField label={"Usuários que acompanham este atendimento"} fullWidth gapAfter hidden>
                                <ProForm.Group>
                                    <FormFieldRepeater
                                        name={"usuarios"}
                                        formRef = { formRef }
                                        fields={dprUsuarios}
                                        api={frUsuariosRefApi}
                                        novaLinhaAdicionada={(posName) => {
                                            if(posName > 1) {
                                                modalRefApi?.current?.bodyScrollBottom();
                                            }
                                        }}
                                        linhaRemovida={() => {
                                            conteudoAlterado.setConteudoAlterado();
                                        }}
                                        firstLine
                                        onLoad={() => {
                                        }}
                                    />
                                </ProForm.Group>
                            </FoldedFormField>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <REIf state={refStateHidden.current} hideFor={"cmbCdPessoaUsrDestWF"} keepWidth={1024} col xs={24}>
                                        <AutoComplete
                                            formRef = { formRef }
                                            name = "cmbCdPessoaUsrDestWF"
                                            label = "Novo usuário responsável"
                                            tooltip = "Envie este atendimento para um novo usuário responsável"
                                            placeholder={"Selecione um novo usuário responsável para este atendimento"}
                                            rules={[
                                                {required: (refStateRequired.current.cmbCdPessoaUsrDestWF === true), message:"Informe o usuário responsável do atendimento"},
                                            ]}
                                            width={"xl"}
                                            fetchOptions={fetchUsuariosDaEmpresa(userSession.cdPessoaEmpAtual)}
                                            disabled={refTelaProtegida.current || (refStateDisabled.current.cmbCdPessoaUsrDestWF === true)}
                                            api = { cmbCdPessoaUsrDestWFRefApi }
                                        />
                                    </REIf>
                                </Row>
                            </ProForm.Group>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={12} md={6} lg={4}>
                                        <ProFormDatePicker
                                            width={"sm"}
                                            name="dtFim"
                                            label="Finalizado"
                                            tooltip={"Data de finalização deste atendimento"}
                                            placeholder={"Finalizado em"}
                                            format={"DD/MM/YYYY"}
                                            showToday={true}
                                            rules={[
                                            ]}
                                            disabled
                                        />
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <ProFormTimePicker
                                            width={"xs"}
                                            name = "hrFim"
                                            label = "as"
                                            tooltip = "Hora de finalização"
                                            placeholder = "Horário"
                                            format={"HH:mm"}
                                            showToday={true}
                                            rules={[
                                            ]}
                                            disabled
                                        />
                                    </Col>
                                    <Col xs={24} md={8} lg={12}>
                                        <ProFormText
                                            width={"xl"}
                                            name = "noMotivoFimAtendimento"
                                            label = "Motivo término"
                                            tooltip = "Motivo do témino"
                                            placeholder={"Qual o motivo do término do atendimento ?"}
                                            rules={[
                                            ]}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Group>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={12} md={6} lg={4}>
                                        <ProFormDatePicker
                                            width={"sm"}
                                            name="dtCad"
                                            label="Cadastrado em"
                                            tooltip={"Data de cadastro desta ordem de serviço"}
                                            placeholder={"Cadastrado em"}
                                            format={"DD/MM/YYYY"}
                                            showToday={true}
                                            rules={[
                                            ]}
                                            disabled
                                        />
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <ProFormTimePicker
                                            width={"xs"}
                                            name = "hrCad"
                                            label = "as"
                                            tooltip = "Hora do cadastro"
                                            placeholder = "Horário"
                                            format={"HH:mm"}
                                            showToday={true}
                                            rules={[
                                            ]}
                                            disabled
                                        />
                                    </Col>
                                    <Col xs={24} md={12} lg={12}>
                                        <ProFormText
                                            width={"xl"}
                                            name = "noPessoaUsrCad"
                                            label = "Usuário"
                                            tooltip = "Usuário responsável no momento"
                                            placeholder={"Qual o usuário responsável no momento ?"}
                                            rules={[
                                            ]}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Group>
                            <IY2BHiddenRow>
                                <IY2BProFormHidden name={"xx"}/>
                            </IY2BHiddenRow>
                        </div>
                    </ProForm>
                    <FinalizarAtendimento cdTransacao={cdTransacao}
                                          exibePopUp={ exibeFinalizar }
                                          onRequestClose={() => {
                                              setExibeFinalizar(false) ;
                                          }}
                                          dataProvider={dataProvider}
                                          finalizarAtend={finalizarAtend}
                    />
                </div>
            }
            actions={
                <Space style={{
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <Space>
                        <NavegadorRegistro
                            api={navegadorRefApi}
                            registros={registros}
                            navegaPara={navegaParaRegistro}
                        />
                    </Space>
                    <Space>
                        {(permiteFinalizar===true)?
                            <Button type={"text"} onClick={exibirFinalizar}>
                                Finalizar
                            </Button>
                        :null}
                        &nbsp;
                        <Button type={"primary"} className={"salvar"} onClick={async () => await doSalvar()} disabled={refTelaProtegida.current}>
                            Salvar
                        </Button>
                    </Space>
                </Space>
            }
        />
    );

}

export default EditarAtendimento;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";

import {ProFormText, ProFormUploadButton, ProForm} from "@ant-design/pro-components";
import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Input, notification, Row} from "antd";
import {campoInformado, campoInformadoString, observeElement} from "../../lib/iy2b-javascript";
import {SerializadorGerente} from "../../lib/SerializadorGerente";
import {upload} from "../../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";
import {useStateRef} from "../../lib/iy2b-react";
import {dateJSLoader} from "../../3rdpart/DateJS/DateJS";
import {CUSTOM} from "../../assets/iconografia";
import {notificacao} from "../../lib/Notificacao";

const BotaoUpload = ( props ) => {

    const { width, formRef, label, name, placeholder = null, title="Enviar arquivo", propsUpload={}, rules, disabled = false, showDisabled = true, changeDisabled = false, clearFFAfterFinish=true } = props;

    const inputRef = useRef();

    const nameUltArq = "ultArq_" + name;

    const [exibeDownload, setExibeDownload, refExibeDownload] = useStateRef(false);

    useEffect(() => {

        // console.log("inputRef.current?.input?.value", inputRef.current?.input?.value)

        setExibeDownload(campoInformadoString(inputRef.current?.input?.value));

        observeElement(inputRef.current?.input, "value", function (oldValue, newValue) {

            // console.log("inputRef.current?.input", inputRef.current?.input, newValue);

            setExibeDownload(campoInformadoString(newValue));

        }, 1);

    }, []);

    const [serializador] = useState( new SerializadorGerente() );

    serializador.setCallback(() => {

        if(propsUpload.hasOwnProperty("onFinish") === true) {

            const ffValue = formRef?.current?.getFieldsValue();

            propsUpload.onFinish (ffValue[name]);

            if(clearFFAfterFinish === true) {

                const newValue = { } ;
                newValue[name] = null;

                formRef?.current?.setFieldsValue(newValue);

            }

        }

    });

    const _OnChange = (info) => {

        const { status, percent, name, type, uid } = info.file;

        if(status === "done") {

            if(campoInformado(placeholder)) {

                const values = { };

                values[nameUltArq] = name.replaceAll(" ", "_");

                formRef.current.setFieldsValue(values);

            }

            serializador.concluiDependencia(uid);

        } else if (status === "uploading") {

            serializador.inicio();

            serializador.adicionaDependencia(uid);

        }

    }

    const _propsUpload = { ...propsUpload };

    if(_propsUpload.hasOwnProperty("onChange")) {

        const superOnChange = _propsUpload.onChange;

        _propsUpload.onChange = (info) => {

            _OnChange(info);

            superOnChange(info);

        };

    } else {

        _propsUpload.onChange = _OnChange ;

    }

    if(_propsUpload.hasOwnProperty("getPayload") === false) {
        _propsUpload.getPayload = () => { return { } };
    }

    if(_propsUpload.hasOwnProperty("validaTela") === false) {
        _propsUpload.validaTela = async () => { return true };
    }

    if(propsUpload.requestToFrameworkSmk === true) {

        _propsUpload.customRequest = async (params) => {

            const {
                file,
                onError,
                onSuccess
            } = params;

            // http://desenvsmk:8080/systemakers/suite/admupload.processarnegocio.smk?TXCONFIG=desenv&lm=1639481497289&habilitaEnvioASP=N

            try {

                const resultado = await upload({
                    acaoWebAExecutar: "processarnegocio",
                    sistema:"suite",
                    projeto:"admupload",
                    headers: {
                        acaoAExecutar: "enviaArquivo",
                        acaoWebAExecutar: "ProcessarNegocioUpload",
                    },
                    rest: true
                }, {
                    "filedata": file,
                    "multi-files": "N"
                }, WORKSPACE_LOADING_INDICATOR_AREA);

                resultado.mensagens.forEach(msg => {

                    if(msg.id === "listaArquivosRecebidos") {

                        onSuccess(msg.lista[0], file);

                    } else if(msg.id==="resultado") {

                        notificacao.sucesso({
                            message: file.name,
                            description: msg.mensagem,
                        });

                    } else {

                        console.log(msg);

                    }

                });

            } catch (e) {

                console.log(e);

                onError(e);

                let description = "Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !";

                if(e.data) {
                    if(e.data["class"] === "erroDeAcao") {
                        description = "Ocorreu uma falha inesperada, verifique o layout do arquivo de retorno! (" + e.data.descricao.trim() + ")";
                    }
                }

                notificacao.erro({errorId:"upload", message: "Ooops!", description: description})

            }

            return {
                abort: () => {
                    console.log('upload progress is aborted.');
                }
            };
        }

    } else {

        _propsUpload.customRequest = async (params) => {

            const {
                file,
                onError,
                onSuccess
            } = params;

            // http://desenvsmk:8080/systemakers/suite/admupload.processarnegocio.smk?TXCONFIG=desenv&lm=1639481497289&habilitaEnvioASP=N

            try {

                const telaValida = await _propsUpload.validaTela();

                console.log("telaValida", telaValida);

                if(telaValida === false) return false;

                const currentPayload = _propsUpload.getPayload();

                const payload = {
                    ...propsUpload.payload,
                    ...currentPayload
                }

                const resultado = await upload({
                    acaoWebAExecutar: propsUpload.acaoWebAExecutar||"upload",
                    sistema: propsUpload.sistema,
                    projeto: propsUpload.projeto,
                    headers: {
                        acaoAExecutar: propsUpload.acaoAExecutar,
                        acaoWebAExecutar: "PROCESSARNEGOCIO",
                    },
                    rest: true
                }, {
                    "filedata": file,
                    "multi-files": "N",
                    ...payload
                }, WORKSPACE_LOADING_INDICATOR_AREA);

                resultado.mensagens.forEach(msg => {

                    propsUpload.prCmdUpload ( msg, file, onSuccess );

                });

                propsUpload.onFinish([file]);

            } catch (e) {

                console.log(e);

                onError(e);

                let description = "Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !";

                if(e.data) {
                    if(e.data["class"] === "erroDeAcao") {
                        description = "Ocorreu uma falha inesperada, verifique o layout do arquivo de retorno! (" + e.data.descricao.trim() + ")";
                    }
                }

                notificacao.erro({errorId:"upload", message: "Ooops!", description: description})

            }

            return {
                abort: () => {
                    console.log('upload progress is aborted.');
                }
            };
        }

    }

    const eTxUrl =
        <div
            className={"text-url"}
        >
            <ProFormText
                formRef = { formRef }
                width={"sm"}
                name = {"url_" + name}
                fieldProps = {{
                    ref: inputRef
                }}
                disabled
            />
        </div>
    ;

    const labelForPlaceHolder = (campoInformado(label) === true) ? "Nome do arquivo" : null;

    const placeHolderEl =
        <Col xs={24} md={12}>
            <ProFormText
                formRef = { formRef }
                width={"lg"}
                name = {nameUltArq}
                placeholder = {placeholder}
                label={labelForPlaceHolder}
                disabled
            />
        </Col>
    ;

    const ePlaceHolder =
        (exibeDownload===true) ? (changeDisabled===true) ? placeHolderEl : null :
            (campoInformado(placeholder)) ? placeHolderEl : null;

    const ffValue = formRef?.current?.getFieldsValue();

    const noArqDownload = (campoInformado(ffValue) === true) ?  ffValue[nameUltArq] : "";

    const mdWidthButtonDownload = ((disabled === true && showDisabled === true) || disabled === false) ? 12 : 24;

    const eBtnDownload = (exibeDownload===true) ?
        <Col xs={24} md={{span:mdWidthButtonDownload}}>
            <ProForm.Item
                label={<div style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden"
                }}>{noArqDownload}</div>}
            >
                <Button
                    block
                    icon={CUSTOM.ARROW_ALT_CIRCLE_DOWN_ICON}
                    onClick={() => {
                        const url = inputRef.current?.input.value ;
                        window.open(url,'_blank');
                    }}
                >
                    Ver documento
                </Button>
            </ProForm.Item>
        </Col>
        : null;

    const widthButton = (exibeDownload===true) ? {span:11, offset:1} : (campoInformado(placeholder))?
        {span:11, offset:1}
        :
        {span:24}
    ;

    const btnUploadEl = ((disabled === true && showDisabled === true) || disabled === false) ?
        <Col xs={24} md={widthButton}>
            <ProFormUploadButton
                formRef = { formRef }
                width = {"xl"}
                label = {label}
                name = {name}
                title = {title}
                fieldProps = {{..._propsUpload}}
                rules={rules}
                disabled={disabled}
            />
        </Col>
        : null
    ;

    const eBtnUpload = (exibeDownload===true) ? (changeDisabled===true) ? null : btnUploadEl : btnUploadEl ;

    const rowClassName = ["box-botao-upload"];

    if(campoInformado(width) === true) {
        rowClassName.push("width-" + width);
    }

    return (
        <Row className={rowClassName.toClassName()}>
            {eTxUrl}
            {ePlaceHolder}
            {eBtnDownload}
            {eBtnUpload}
        </Row>
    );

}

export default BotaoUpload ;

import {notification} from "antd";
import {postNotification} from "../reducer/wsDataProvider";
import {postWS} from "./RequestAPI";
import moment from "moment/moment";
import {campoInformado} from "./iy2b-javascript";

class Notificacao {

    store = null;

    setStore = (value) => {
        this.store = value;
    }

    adiciona = (value) => {

        if(value.type === "error") {

            const w = window.innerWidth;
            const h = window.innerHeight;

            const uAgent = navigator.userAgent.trim() + "; screen/" + w + "," + h + "; touch/" + window.isTouchDevice();
            const versaoEmExec = parseInt(process.env.REACT_APP_VERSAO_ATUAL);

            const { userSession, transacaoManager } = this.store.getState();

            if(campoInformado(userSession) === false) return false;
            if(campoInformado(userSession.cdPessoaEmpAtual) === false) return false;
            if(campoInformado(userSession.cdPessoaUsr) === false) return false;

            const agora = moment();

            const registro = {
                ...value,
                cdPessoaEmp: userSession.cdPessoaEmpAtual,
                cdPessoaUsr: userSession.cdPessoaUsr,
                date: agora.format("YYYY/MM/DD"),
                time: agora.format("HH:mm:ss"),
                appVersion: versaoEmExec,
                client: uAgent,
                txIdApp: "iy2b-portal"
            };

            postWS({
                sistema: "sgr",
                projeto: "mlognotifapp",
                acaoWebAExecutar: "processarNegocio",
                acaoAExecutar: "incluir"
            }, {...registro}).then(resposta => {

                resposta.mensagens.forEach(msg => {

                    if(msg.id === "notificacao") {
                        registro.key = msg.modeloPadrao.key;
                        if(campoInformado(registro.transaction) === true) {
                            transacaoManager.disponiveis
                                .filter(transacao => transacao.key === registro.transaction)
                                .forEach(transacaoDisp => registro.noTransacao = transacaoDisp.title);
                        }
                    }

                });

                this.store.dispatch(postNotification(registro));

            }, error => {

                console.log(error);

                notification.error(
                    {message:"Enviar log de notificações", description: error, duration: 12}
                );

            });

        } else {

            const { userSession } = this.store.getState();

            if(campoInformado(userSession) === false) return false;
            if(campoInformado(userSession.cdPessoaEmpAtual) === false) return false;
            if(campoInformado(userSession.cdPessoaUsr) === false) return false;

            const agora = moment();

            this.store.dispatch(postNotification({
                ...value,
                cdPessoaEmp: userSession.cdPessoaEmpAtual,
                cdPessoaUsr: userSession.cdPessoaUsr,
                date: agora.format("YYYY/MM/DD"),
                time: agora.format("HH:mm:ss")
            }));

        }

    }

    sucesso = ({ transacao=0, message = "titulo", description="descrição", duration=4 }) => {

        notification.success(
            {message:message, description: description, duration: duration}
        );

        this.adiciona({
            type: "success",
            message: message,
            description: description,
            transaction: transacao
        });

    };

    erro = ({ transacao=0, message = "titulo", description="descrição", duration=4, errorId=null }) => {

        notification.error(
            {message:message, description: description, duration: duration}
        );

        this.adiciona({
            type: "error",
            message: message,
            description: description,
            transaction: transacao,
            errorId: errorId
        });

    };

    aviso = ({ transacao=0, message = "titulo", description="descrição", duration=4 }) => {

        notification.warning(
            {message:message, description: description, duration: duration}
        );

        this.adiciona({
            type: "warning",
            message: message,
            description: description,
            transaction: transacao
        });

    };

}

const notificacao = new Notificacao();

const populateDesenv = () => {
    notificacao.aviso({
        message: "Aviso!",
        description: "Vínculo excluído com sucesso!"
    });
    notificacao.erro({
        message: "Erro!",
        description: "Vínculo excluído com sucesso!"
    });
    notificacao.erro({
        message: "Erro!",
        description: "Vínculo excluído com sucesso!"
    });
    notificacao.sucesso({
        message: "Sucesso!",
        description: "Vínculo salvo com sucesso!"
    });
    notificacao.sucesso({
        message: "Sucesso!",
        description: "Vínculo excluído com sucesso!"
    });
    notificacao.sucesso({
        message: "Sucesso!",
        description: "Vínculo salvo com sucesso!"
    });
    notificacao.sucesso({
        message: "Sucesso!",
        description: "Vínculo excluído com sucesso!"
    });
    notificacao.sucesso({
        message: "Sucesso!",
        description: "Vínculo salvo com sucesso!"
    });
    notificacao.sucesso({
        message: "Sucesso!",
        description: "Vínculo excluído com sucesso!"
    });
    notificacao.sucesso({
        message: "Sucesso!",
        description: "Vínculo salvo com sucesso!"
    });
    notificacao.sucesso({
        message: "Sucesso!",
        description: "Vínculo excluído com sucesso!"
    });
    notificacao.erro({
        message: "Erro!",
        description: "Vínculo salvo com sucesso!"
    });
    notificacao.erro({
        message: "Erro!",
        description: "Vínculo excluído com sucesso!"
    });
    notificacao.erro({
        message: "Erro!",
        description: "Vínculo salvo com sucesso!"
    });
    notificacao.erro({
        message: "Erro!",
        description: "Vínculo excluído com sucesso!"
    });
    notificacao.aviso({
        message: "Aviso!",
        description: "Vínculo salvo com sucesso!"
    });
};

/*
setTimeout(() => {
    if (window.AMBIENTE_DESENV()) {
        populateDesenv();
    }
}, 1000 * 10);
*/

export {
    notificacao
}



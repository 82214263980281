//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';

import '../../../framework/assets/css/tema.scss';
import "./wizNovoUsuario.scss";

import React, {useEffect, useRef, useState} from 'react';
import {Col, notification, Row} from "antd";
import {ProForm, ProFormText} from "@ant-design/pro-components";
import IY2BProFormMasked from "../../componentes/IY2BProFormMasked";
import {campoInformado, cpfValido} from "../../lib/iy2b-javascript";
import {postJSON, postWS} from "../../lib/RequestAPI";
import {useStateRef} from "../../lib/iy2b-react";
import IY2BProFormHidden from "../../componentes/IY2BProFormHidden";
import IY2BHiddenRow from "../../componentes/IY2BHiddenRow";
import {notificacao} from "../../lib/Notificacao";

const StepDados = (props) => {

    const inputRefCPF = useRef();
    const inputRefTelCel = useRef();

    const [cpfPreExistente, setCPFPreExistente, refCPFPreExistente] = useStateRef(false);

    const { cdPessoaEmpMaster } = props;

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                onValuesChange: onValuesChange,
                onPageShow: onPageShow,
                onFinish: onFinish
            };
        }

        return () => {

        };

    }, []);

    const validarEMail = async () => {

        const all = props.formRef.current?.getFieldsValue();

        console.log("validarEMail.all", all);

        const payload = {
            noEMail: all.noEMail || all.noEmail,
        };

        let valido = true;

        const resposta = await postWS({
            sistema: "sgr",
            projeto: "mwizcontratacaobuild",
            acaoWebAExecutar: "pesquisar",
            acaoAExecutar: "pesquisaNoEmail"
        }, payload);

        resposta.mensagens.forEach(msg => {

            if(msg.id === "usuario") {
                valido = false;
                notificacao.erro({errorId:"emailJaCadastrado", message:"Ja é cliente", description:"Este email ja esta cadastrado como usuário da plataforma. Por favor faça o login para acessar o sistema."});
            }

        });

        return valido;

    }

    const onFinish = async () => {

        let resultado = true;

        if(refCPFPreExistente.current === false) {
            resultado = await validarEMail();
        }

        return resultado;
    }

    const onPageShow = async () => {

    }

    const onValuesChange = async (changed, all) => {

    }

    const cpfPatterns = [{
        pattern: "000.000.000-00",
        length: 11, // sem mascara
        validator: async (value) => {

            if(value.length === 11) {
                if(cpfValido(value) === false) {
                    return {valido:false, message:"CPF informado é inválido !"};
                }
            } else {
                return {valido:false, message:"CPF informado é inválido !"};
            }

            const payload = {
                nrCGCCIC: value,
                "tipoPesq": "usr"
            }

            let valido = true;
            let msgValido = null;
            let preExistente = false;

            const resposta = await postWS({
                sistema: "sgr",
                projeto: "mwizcontratacaobuild",
                acaoWebAExecutar: "pesquisar",
                acaoAExecutar: "pesquisaNrCGCCIC"
            }, payload);

            let modeloPessoa = null;

            resposta.mensagens.forEach(msg => {

                if(msg.id === "pessoa") {

                    modeloPessoa = msg.modeloPadrao;

                    props.formRef.current?.setFieldsValue({
                        cdPessoaUsr: msg.modeloPadrao.cdPessoa,
                        noUsuario: msg.modeloPadrao.noPessoa,
                        noApelido: msg.modeloPadrao.noFantasia,
                        noEMail: msg.modeloPadrao.noLogin.toLowerCase(),
                        nrTelCelular: msg.modeloPadrao.nrTelCelular
                    });

                    preExistente = true;

                    notificacao.aviso({"message":"CPF já cadastrado na plataforma", description:"As informações pré-existentes serão utilizadas !"})

                }

            });

            if(preExistente === false) {

                props.formRef.current?.setFieldsValue({
                    noUsuario: "",
                    noEMail: "",
                    nrTelCelular: ""
                });

            } else {

                const respostaJaVinc = await postJSON({
                    sistema: "smk",
                    projeto: "musuariopiy2b",
                    acaoWebAExecutar: "pesquisar"
                }, {
                    acaoAExecutar: "pesquisaVinculoUsrEmpresa",
                    cdPessoaUsr: modeloPessoa.cdPessoa,
                    cdPessoaEmpMaster: cdPessoaEmpMaster
                });

                respostaJaVinc.mensagens.forEach(msg => {

                    if(msg.id === "nok") {

                        preExistente = false;

                        props.formRef.current?.setFieldsValue({
                            nrCPF: "",
                            noApelido: "",
                            noUsuario: "",
                            noEMail: "",
                            nrTelCelular: ""
                        });

                        notificacao.erro({errorId:"jaTemPermissoes", message:"Usuário com permissões", description: msg.mensagem});

                        setTimeout(() => {

                            inputRefCPF.current.focus();

                        }, 500);

                    }

                });

            }

            setCPFPreExistente ( preExistente );

            return {valido: valido, message: msgValido, clearValue: (valido===false)};
        }
    }];

    const telCelPatterns = [{
        pattern: "(00) 0000-0000",
        length: 10, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }, {
        pattern: "(00) 0-0000-0000",
        length: 11, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }];

    return (
        <div className={"containerStepDados"}>
            <Row gutter={[8,16]}>
                <Col xs={{span:18}} md={{span:24}}>
                    <ProForm.Group>
                        <IY2BProFormMasked
                            width={"sm"}
                            label={"CPF"}
                            name="nrCPF"
                            tooltip = "CPF do usuário"
                            rules={[{ required: true, message: 'Por favor, informe o CPF!' }, {
                                validator: async (rule, value) => {
                                    if(inputRefCPF) {
                                        const result = await inputRefCPF.current.validate();
                                        if(result.valido === false) throw new Error(result.message);
                                    }
                                }
                            }]}
                            api={inputRefCPF}
                            patterns={cpfPatterns}
                        />
                    </ProForm.Group>
                </Col>
            </Row>
            <Row gutter={[8,16]}>
                <Col xs={{span:18}} md={{span:24}}>
                    <ProForm.Group>
                        <ProFormText
                            width={"md"}
                            name = "noApelido"
                            label = "Apelido"
                            placeholder={"Digite o apelido"}
                            tooltip = "Nome abreviado / apelido do usuário"
                            rules={[ {required: true, message:"Informe o nome abreviado"}, {message:"No máximo 20 caracteres", max:20}]}
                            disabled={cpfPreExistente}
                        />
                        <ProFormText
                            width={"lg"}
                            name = "noUsuario"
                            label = "Nome do usuário"
                            placeholder={"Digite o nome usuário"}
                            tooltip = "Nome do usuário"
                            rules={[ {required: true, message:"Informe o nome completo"}, {message:"No máximo 60 caracteres", max:60}]}
                            disabled={cpfPreExistente}
                        />
                    </ProForm.Group>
                </Col>
            </Row>
            <Row gutter={[8,16]}>
                <Col xs={{span:18}} md={{span:24}}>
                    <ProForm.Group>
                        <ProFormText
                            width={"lg"}
                            name = "noEMail"
                            label = "E-Mail"
                            tooltip = "Infome o email do usuário"
                            placeholder={"Digite o email do usuário"}
                            rules={[
                                {required: true, message:"Informe o email do usuário"},
                                {message:"Informe um email válido", type:"email"},
                                {message:"No máximo 60 caracteres", max:60}
                            ]}
                            disabled={cpfPreExistente}
                        />
                        <IY2BProFormMasked
                            width={"sm"}
                            label={"Telefone celular"}
                            name="nrTelCelular"
                            tooltip = "Nro. telefone celular do usuario"
                            rules={[{ required: true, message: 'Por favor, informe o nro de telefone celular !' }, {
                                validator: async (rule, value) => {
                                    if(inputRefTelCel) {
                                        const result = await inputRefTelCel.current.validate();
                                        if(result.valido === false) throw new Error(result.message);
                                    }
                                    return true;
                                }
                            }]}
                            api={inputRefTelCel}
                            patterns={telCelPatterns}
                            disabled={cpfPreExistente}
                        />
                    </ProForm.Group>
                </Col>
            </Row>
            <IY2BHiddenRow>
                <IY2BProFormHidden name={"cdPessoaUsr"}/>
            </IY2BHiddenRow>
        </div>
    );

}

export default StepDados;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";

import {
    notificacaoErroRequest,
    notificacaoErroValidForm, PADRAO_MARGEM_POPUP,
    useConteudoAlterado,
    useGridWidth,
    useModoExecucao,
    useWindowSize
} from "../../lib/iy2b-react";
import React, {useEffect, useRef, useState} from "react";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {
    campoInformado,
    cnpjValido,
    cpfValido,
    formataNoRuaDoEndereco,
    ObjectUtils,
    pesquisaCEP
} from "../../lib/iy2b-javascript";
import {postJSON} from "../../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";
import moment from "moment";
import {Button, Col, notification, Popconfirm, Row, Space} from "antd";
import IY2BHiddenRow from "../IY2BHiddenRow";
import IY2BProFormHidden from "../IY2BProFormHidden";
import NavegadorRegistro from "../NavegadorRegistro/navegadorRegistro";
import IY2BReactModal from "../IY2BReactModal";
import {ProForm, ProFormText} from "@ant-design/pro-components";
import IY2BProFormMasked from "../IY2BProFormMasked";
import {pesquisarCidadeDoCep} from "../../lib/Servicos";
import CheckGroup from "../proform/CheckGroup/checkGroup";
import {notificacao} from "../../lib/Notificacao";


const EditarEndereco = ( props ) => {

    const { exibePopUp, onRequestClose, salvar, excluir, dataProvider, noPessoa, nrCGCCIC, cdTransacao = 0 } = props;

    const { registros } = props;

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 10 : windowSize.widthMargin(PADRAO_MARGEM_POPUP.SM);
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = windowSize.heightMargin(PADRAO_MARGEM_POPUP.MD);

    const modalRefApi = useRef();
    const formRef = useRef();
    const navegadorRefApi = useRef();

    const forceUpdate = useForceUpdate();

    const modoExecucao = useModoExecucao("INC");
    const conteudoAlterado = useConteudoAlterado();
    const [registroDaTelaInicial, setRegistroDaTelaInicial] = useState(null);

    const inputRefNrCEP = useRef();
    const inputRefTxNumero = useRef();
    const cmbRegiaoRefApi = useRef();
    const cmbTipoCalcImpostoRefApi = useRef();
    const [infoCep, setInfoCep] = useState(null);
    const inputRefTelefone = useRef();
    const inputRefLograd = useRef();

    // 95330-000
    const [desabLograd, setDesabLograd] = useState(true);
    const [desabBairro, setDesabBairro] = useState(true);

    useEffect(() => {

        if(campoInformado(registros)) {

            if(exibePopUp) {

                if(registros.length > 0) {

                    const { novoReg = false } = registros[0];

                    if(novoReg === true) {

                        setTimeout(() => {
                            setRegistroNaTela(registros[0]);
                            conteudoAlterado.setConteudoAlterado();
                        }, 0);

                    } else {

                        setTimeout(() => {
                            pesquisarPk(registros[0]);
                        }, 0);

                    }

                } else {

                    setTimeout(() => {
                        novoRegistro();
                    }, 0);

                }

            }

        } else {

            setTimeout(() => {
                novoRegistro();
            }, 0);

        }

        return () => {

        };

    }, [props.registros]);

    const pesquisarPk = async (registro) => {

        try {

            novoRegistro();

            const payload = {
                acaoAExecutar: "acaoCRUD",
                registro: {
                    "cdPessoa": registro.cdPessoa,
                    "nrSeqEnd": registro.nrSeqEnd
                }
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisarPk",
                sistema: "sgr",
                projeto: "mendereco"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    setRegistroNaTela(msg.modeloPadrao);

                }

            });

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const setRegistroNaTela = async (registro) => {

        console.log(registro);

        const attrDaTela = [ "cdPessoa",
            "nrSeqEnd", "noBairro", "noLograd", "txNumero", "txCompl", "nrInsEstEnd", "nrInsMunicipEnd", "dtCad", "dtInicValid", "cdCidade", "noEmailNF_E", "noEmail",
            "nrTelefone"
        ];

        const registroDaTela = {
            ... ObjectUtils.valuesFrom(registro, attrDaTela),
            nrCEP: registro.nrCep,
            noCidade: registro.noCidade.trim() + " / " + registro.ufCidade,
            tpEndereco: registro.tpEndereco.split("")
        };

        formRef.current?.setFieldsValue( registroDaTela );

        setRegistroDaTelaInicial ( {
            ... registroDaTela,
        });

        forceUpdate();

        controleExibicao ( registro );

        modoExecucao.setModoExecAlteracao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const controleExibicao = (registro) => {


    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();

            inputRefNrCEP.current.focus();

        }, 1);

    }

    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        console.log(ffValues);

        const attrDaTela = [ "cdPessoa", "nrSeqEnd", "noBairro", "noLograd", "txNumero", "txCompl", "nrInsEstEnd", "nrInsMunicipEnd", "dtCad", "dtInicValid", "cdCidade", "noEmailNF_E", "noEmail" ];

        const registro = {
            ...ObjectUtils.valuesFrom(ffValues, attrDaTela),
            nrCep: ffValues.nrCEP.retiraCaracteres("-./"),
            nrTelefone: ffValues.nrTelefone?.retiraCaracteres("-() "),
            "tpEndereco": ffValues.tpEndereco.join().retiraCaracteres(","),
            dtFimValid: "1901/01/01",
            cdRegiao: 0,
            cdTipoCalcImposto: 0,
            "flgZFranca":"N",
            "cdTribServ":"T",
            "cdRegTrib":"0",
            "cdTipoDoc":"01",
        };

        registro.nrInsEstEnd = registro.nrInsEstEnd?.toUpperCase();
        registro.nrInsMunicipEnd = registro.nrInsMunicipEnd?.toUpperCase();

        registro.nrTelefone = registro.nrTelefone?.trim().substr(0, 20);
        registro.noBairro = registro.noBairro?.trim().substr(0, 20);
        registro.txNumero = registro.txNumero?.trim().substr(0, 20);
        registro.txCompl = registro.txCompl?.trim().substr(0, 30);

        registro.noRua = formataNoRuaDoEndereco(registro);

        /*
        registro.noRua = registro.noLograd.trim();
        if(registro.txNumero?.trim().length > 0) {
            registro.noRua += ", " + registro.txNumero?.trim();
        }
        if(registro.txCompl?.trim().length > 0) {
            registro.noRua += " - " + registro.txCompl?.trim();
        }
         */

        console.log(registro);

        return registro;

    }

    const doExcluir = async () => {

        const registro = getRegistroDaTela();

        await excluir ({
            cdPessoa: registro.cdPessoa,
            nrSeqEnd: registro.nrSeqEnd
        });

    }

    const novoRegistro = () => {

        formRef?.current?.resetFields();

        cmbRegiaoRefApi.current?.setFieldValue(dataProvider.regiaoDefault.value);

        cmbTipoCalcImpostoRefApi.current?.setFieldValue(dataProvider.tipoCalcImpostoDefault.value);

        setDesabLograd(true);
        setDesabBairro(true);

        controleExibicao ( );

        modoExecucao.setModoExecInclusao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const validaTela = async (params) => {

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        const cidade = await pesquisarCidadeDoCep(ffValues.nrCEP.retiraCaracteres("-./"), WORKSPACE_LOADING_INDICATOR_AREA, cdTransacao);

        formRef.current?.setFieldsValue( cidade );

        return true;

    };

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return;

            if(conteudoAlterado.isConteudoAlterado() === false) {
                notificacao.aviso({message:"Sem alteração", description:"Nenhuma informação alterada para ser salva !"})
                return;
            }

            const registro = getRegistroDaTela();

            const respSalvar = await salvar (registro);

            if(respSalvar === false) return;

            if(navegadorRefApi.current.temProximo()) {

                navegadorRefApi.current.navegaProximo();

            } else {

                if(registros.length === 1) {

                    novoRegistro();

                } else {

                    onRequestClose();

                }

            }

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e, cdTransacao);

        }

    }

    const navegaParaRegistro = async (posicao) => {

        await pesquisarPk(registros[posicao - 1]);

    }

    const nrCGCCICPatterns = [{
        pattern: "000.000.000-00",
        length: 11, // sem mascara
        validator: async (value) => {
            if(value.length === 11) {
                if(cpfValido(value) === false) {
                    return {valido:false, message:"CPF informado é inválido !"};
                }
            }
            return {valido: true, message: "ok" };
        }
    }, {
        pattern: "00.000.000/0000-00",
        length: 14, // sem mascara
        validator: async (value) => {
            if(value.length === 14) {
                if(cnpjValido(value) === false) {
                    return {valido:false, message:"CNPJ informado é inválido !"};
                }
            }
            return {valido: true, message: "ok" };
        }
    }];

    const onValuesChange = (changed, all) => {

        // console.log(changed, all);

        if(changed.hasOwnProperty("nrCEP")) {

            if(inputRefNrCEP.current.rawValue().length !== 8) {
                limpaCEP();
            }

        }

    }

    const limpaCEP = () => {

        console.log("limpaCEP");

        setInfoCep(null);

        setDesabLograd(true);
        setDesabBairro(true);

        const newValue = {
            noLograd: "",
            noCidade: "",
            noBairro: "",
            txNumero: "",
            txCompl: ""
        };

        formRef.current.setFieldsValue(newValue);

    }

    const preencherCEP = async (nrCEP) => {

        console.log("preencherCEP");

        try {

            const resultado = await pesquisaCEP(nrCEP);

            setInfoCep(resultado);

            const logradInformado = (resultado.logradouro.trim().length > 0);

            setDesabLograd(logradInformado);
            setDesabBairro(resultado.bairro.trim().length > 0);

            const newValue = {
                noLograd: resultado.logradouro.trim().substr(0, 160),
                noCidade: resultado.localidade + " / " + resultado.uf,
                noBairro: resultado.bairro.trim().substr(0, 20)
            };

            if(logradInformado === false) {
                const ffValues = formRef.current.getFieldsValue();
                console.log("ffValues", ffValues);
                newValue.noLograd = ffValues.noLograd;
                newValue.noBairro = ffValues.noBairro;
            }

            formRef.current.setFieldsValue(newValue);

            if(logradInformado) {
                inputRefTxNumero.current.focus();

            } else {
                inputRefLograd.current.focus();

            }

            return true;

        } catch (e) {

            limpaCEP();

            return false;

        }

    }

    const cepPatterns = [{
        pattern: "00000-000",
        length: 8, // sem mascara
        validator: async (value) => {
            const resultado = await preencherCEP(value);
            if(resultado === true) {
                return {valido:true};
            } else {
                return {valido:false, message:"CEP informado não encontrado nos correios!", clearValue: true};
            }
        }
    }];

    const telFixoPatterns = [{
        pattern: "(00) 0000-0000",
        length: 10, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }, {
        pattern: "(00) 0-0000-0000",
        length: 11, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }];

    const popupTitle = modoExecucao.isInclusao() ? "Novo endereço" : "Alteração do endereço";

    const ffValues = formRef?.current?.getFieldsValue();

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={windowSize.heightMargin(PADRAO_MARGEM_POPUP.MD)}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"editar-endereco-pessoa"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-editar-endereco-pessoa"}>
                    <ProForm
                        formRef = { formRef }
                        isKeyPressSubmit = {true}
                        initialValues={{
                            nrCGCCIC: nrCGCCIC,
                            noPessoa: noPessoa,
                            tpEndereco: ["E", "F", "A"],
                            dtInicValid: moment().format("YYYY/MM/DD"),
                            dtCad: moment().format("YYYY/MM/DD"),
                        }}
                        onFinish = { async ( ) => {
                            await doSalvar();
                        }}
                        submitter={{
                            // Completely customize the entire area
                            render : ( props , doms ) => {
                                return null;
                            }
                        }}
                        onValuesChange={async (changeValues, allValues) => {

                        }}
                        onFieldsChange = {(changedFields, allFields) => {
                            conteudoAlterado.updateConteudoAlterado(changedFields);
                        }}
                    >
                        <div className={"pro-form-group-spacer"}>
                            <ProForm.Group>
                                <IY2BProFormMasked
                                    width={"sm"}
                                    label={"CNPJ / CPF"}
                                    name="nrCGCCIC"
                                    tooltip = "CNPJ ou CPF do credor"
                                    patterns={nrCGCCICPatterns}
                                    disabled
                                />
                                <ProFormText
                                    width={"md"}
                                    name = "noPessoa"
                                    label = "Razão social / Nome"
                                    tooltip = "A razão social ou nome da pessoa"
                                    placeholder = "Digite parte da razão social ou apelido"
                                    disabled
                                />
                            </ProForm.Group>
                            <div className={"form-endereco"}>
                                <Row gutter={[8, 16]}>
                                    <Col xs={{span:10}} md={{span:4}}>
                                        <ProForm.Group>
                                            <IY2BProFormMasked
                                                width={"sm"}
                                                label={"CEP"}
                                                name="nrCEP"
                                                tooltip = "CEP do endereço"
                                                rules={[{ required: true, message: 'Por favor, informe o CEP!' },{
                                                    validator: async (rule, value) => {
                                                        if(inputRefNrCEP) {
                                                            const result = await inputRefNrCEP.current.validate();
                                                            if(result.valido === false) throw new Error(result.message);
                                                        }
                                                    }
                                                }]}
                                                api={inputRefNrCEP}
                                                patterns={cepPatterns}
                                            />
                                        </ProForm.Group>
                                    </Col>
                                    <Col xs={{span:18}} md={{span:20}}>
                                        <ProForm.Group>
                                            <ProFormText
                                                width={"lg"}
                                                name = "noLograd"
                                                label = "Logradouro"
                                                tooltip = "Nome do logradouro"
                                                placeholder={"Logradouro"}
                                                disabled={desabLograd}
                                                fieldProps={{
                                                    ref: inputRefLograd
                                                }}
                                                rules={[
                                                    {required: (desabLograd === false), message:"Informe o nome do logradouro"},
                                                ]}
                                            />
                                            <ProFormText
                                                width={"sm"}
                                                name = "txNumero"
                                                label = "Número"
                                                tooltip = "Número do imóvel"
                                                placeholder={"Digite o número do imóvel"}
                                                rules={[ {required: true, message:"Informe o número do imóvel"},
                                                    {message:"No máximo 20 caracteres", max:20}
                                                ]}
                                                fieldProps={{
                                                    ref: inputRefTxNumero
                                                }}
                                            />
                                        </ProForm.Group>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 16]}>
                                    <Col span={24}>
                                        <ProForm.Group>
                                            <ProFormText
                                                width={"sm"}
                                                name = "txCompl"
                                                label = "Complemento"
                                                tooltip = "Existe complemento no endereço ?"
                                                placeholder={"Andar, conjunto, sala, apto, etc... "}
                                                rules={[ {message:"No máximo 30 caracteres", max:30}]}
                                            />
                                            <ProFormText
                                                width={"md"}
                                                name = "noBairro"
                                                label = "Bairro"
                                                tooltip = "Nome do bairro"
                                                placeholder={"Bairro"}
                                                disabled={desabBairro}
                                                rules={[
                                                    {required: (desabBairro === false), message:"Informe o nome do bairro"},
                                                ]}
                                            />
                                            <ProFormText
                                                width={"md"}
                                                name = "noCidade"
                                                label = "Cidade / UF"
                                                tooltip = "Nome da cidade / UF"
                                                placeholder={"Cidade / UF"}
                                                disabled
                                            />
                                        </ProForm.Group>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 16]}>
                                    <Col xs={{span:20}} md={{span:8}} lg={{span:6}}>
                                        <IY2BProFormMasked
                                            width={"sm"}
                                            label={"Telefone principal"}
                                            name="nrTelefone"
                                            tooltip = "Nro. telefone principal da empresa"
                                            rules={[{
                                                validator: async (rule, value) => {
                                                    if(inputRefTelefone) {
                                                        const result = await inputRefTelefone.current.validate();
                                                        if(result.valido === false) throw new Error(result.message);
                                                    }
                                                    return true;
                                                }
                                            }]}
                                            api={inputRefTelefone}
                                            patterns={telFixoPatterns}
                                        />
                                    </Col>
                                    <Col xs={{span:20}} md={{span:16}} lg={{span:12}} >
                                        <ProFormText
                                            width={"lg"}
                                            name = "noEmail"
                                            label = "E-Mail "
                                            tooltip = "Infome o email para contato neste endereço"
                                            placeholder={"Digite o email para contato"}
                                            rules={[
                                                {message:"Informe um email válido", type:"email"},
                                                {message:"No máximo 60 caracteres", max:60}
                                            ]}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[8, 16]}>
                                    <Col xs={{span:20}} md={{span:10}} lg={{span:6}}>
                                        <ProFormText
                                            width={"sm"}
                                            name = "nrInsEstEnd"
                                            label = "Inscrição estadual"
                                            tooltip = "Inscrição estadual da empresa"
                                            placeholder={"Digite a inscrição estadual da empresa "}
                                            rules={[ {message:"No máximo 14 caracteres", max:14}]}
                                        />
                                    </Col>
                                    <Col xs={{span:20}} md={{span:10}} lg={{span:6}}>
                                        <ProFormText
                                            width={"sm"}
                                            name = "nrInsMunicipEnd"
                                            label = "Inscrição municipal"
                                            tooltip = "Inscrição municipal da empresa. Necessário para pesquisar notas de serviço"
                                            placeholder={"Digite a inscrição municipal da empresa "}
                                            rules={[ {message:"No máximo 15 caracteres", max:15}]}
                                        />
                                    </Col>
                                </Row>
                                <CheckGroup
                                    name={"tpEndereco"}
                                    label={"Tipo de endereço"}
                                    options={[{
                                        label:"Entrega", value:"E"
                                    },{
                                        label:"Faturamento", value:"F"
                                    },{
                                        label:"Administrativo", value:"A"
                                    }]}
                                    layout={"horizontal"}
                                    width={"lg"}
                                    rules={[
                                        {required: true, message:"Informe algum tipo de endereço"},
                                    ]}
                                />
                            </div>
                            <IY2BHiddenRow>
                                <IY2BProFormHidden name={"cdPessoa"}/>
                                <IY2BProFormHidden name={"nrSeqEnd"}/>
                                <IY2BProFormHidden name={"noContato"}/>
                                <IY2BProFormHidden name={"dtCad"}/>
                                <IY2BProFormHidden name={"dtInicValid"}/>
                                <IY2BProFormHidden name={"cdCidade"}/>
                            </IY2BHiddenRow>
                        </div>
                    </ProForm>
                </div>
            }
            actions={
                <Space style={{
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <Space>
                        <NavegadorRegistro
                            api={navegadorRefApi}
                            registros={registros}
                            navegaPara={navegaParaRegistro}
                        />
                    </Space>
                    <Space>
                        {modoExecucao.isAlteracao()?
                            <Popconfirm
                                title="Confirma a exclusão deste registro?"
                                onConfirm={async () => {
                                    const resultado = await doExcluir();
                                }}
                                okText="Sim, excluir"
                                cancelText="Não"
                            >
                                <Button type={"primary"} danger className={"excluir"}>
                                    Excluir
                                </Button>
                            </Popconfirm>
                            :null}
                        &nbsp;
                        <Button type={"primary"} className={"salvar"} onClick={async () => await doSalvar()}>
                            Salvar
                        </Button>
                    </Space>
                </Space>
            }
        />
    );

}

export default EditarEndereco;

import "./popUpTermosDeUso.scss";

import {useGridWidth, useWindowSize} from "../../framework/lib/iy2b-react";
import IY2BReactModal from "../../framework/componentes/IY2BReactModal";
import {useRef} from "react";
import TermosDeUso from "./termosDeUso";

const PopUpTermosDeUso = (props) => {

    const modalRefApi = useRef();

    const { exibePopUp, onRequestClose } = props;

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 10 : 30;
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = 30;
    const marginBottom = 24;

    const CONTENT_HEIGHT = windowSize.height - MODAL_CONTENT_TOP - marginBottom ;

    const popupTitle = "Termos de uso, política de privacidade e utilização de cookies do Portal IY2B";

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={marginBottom}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"popUpTermosDeUso"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-termos-de-uso"}>
                    <TermosDeUso
                        width={MODAL_CONTENT_WIDTH}
                        height={CONTENT_HEIGHT}
                    />
                </div>
            }
        />
    );

}

export default PopUpTermosDeUso;

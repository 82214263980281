//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../../../assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useImperativeHandle} from "react";
import {Col, Tooltip} from "antd";
import {faCircleQuestion} from "@fortawesome/free-regular-svg-icons";
import {skinPrimaryColor} from "../../../../assets/css/skins/StyleSkin";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CUSTOM} from "../../../../assets/iconografia";
import {iy2bHelp} from "../../../../assets/svg/iconografia/iy2bSvgIconsMono";

const BotaoHelp = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {
        };
    });

    const { paneToHelp } = props;

    useEffect(() => {
        return () => {
        };
    }, []);

    const element = (paneToHelp === null) ?
        null
        :
        (
            <Tooltip placement="bottom" title={"Exibe ajuda da operação atual"}>
                <Col flex={"45px"}>
                    <div id={"containerIconHelp"}>

                        <FontAwesomeIcon icon={iy2bHelp} color={skinPrimaryColor}
                                         onClick={props.onShowHelp}
                        />
                    </div>
                </Col>
            </Tooltip>
        )
    ;

    return element;

});

export default BotaoHelp;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';

import "./style.scss";
import {campoInformado} from "../../../lib/iy2b-javascript";

const ProFieldWrapper = ( props ) => {

    const { width, children, hidden=false } = props ;

    const className = ["iy2b-pro-field-wrapper"];

    if(campoInformado(width)) {
        className.push("pro-field");
        className.push("pro-field-" + width);
    }

    if(hidden===true) {
        className.push("not-visible");
    }

    return (
        <div className={className.toClassName()}>
            {children}
        </div>
    );

}

export default ProFieldWrapper;

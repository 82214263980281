import React, {Suspense} from 'react';
import {Provider} from "react-redux";
import Store from "./store";
import {ConnectedRouter} from "connected-react-router";
import {history} from "./reducer";
import {HashRouter, Switch} from "react-router-dom";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import AguardandoTransacao from "./framework/componentes/AguardandoTransacao";
import LoginRoute from "./framework/routes/LoginRoute";
import ExactPathRoute from "./framework/routes/ExactPathRoute";
import ResetSenhaUsuario from "./framework/pages/ResetSenhaUsuario";
import AuthRoute from "./framework/routes/AuthRoute";
import Login from "./framework/pages/Login/Login";
import IY2BHotKeys from "./framework/componentes/IY2BHotKeys";
import {useIntl} from "@ant-design/pro-provider";
import {ws as WorkspaceV2} from "./framework/pages/V2/Workspace/workspace";
import {ws as WorkspaceV1} from "./framework/pages/v1/Workspace/workspace";
import VisualizarDocumento from "./pages/VisualizarDocumento/visualizarDocumento";
import Contratar from "./pages/Contratar";
import Assinar from "./pages/Assinar/Assinar";
import Passos from "./testes/Passos";

const wsVersion = parseInt(process.env.REACT_APP_WORKSPACE_VERSION);

const Workspace = WorkspaceV2 ;

const App = ( props ) => {

    const intl = useIntl();

    console.log("App", intl, "wsVersion=", wsVersion);

    return (
        <Provider store={Store}>
            <ConnectedRouter history={history}>
                <HashRouter>
                    <ErrorBoundary>
                        <Suspense fallback={<AguardandoTransacao />}>
                            <IY2BHotKeys>
                                <Switch>
                                    <LoginRoute path="/" exact={true} />
                                    <ExactPathRoute path="/passos">
                                        <Passos />
                                    </ExactPathRoute>
                                    <ExactPathRoute path="/assinar">
                                        <Assinar />
                                    </ExactPathRoute>
                                    <ExactPathRoute path="/contratar">
                                        <Contratar />
                                    </ExactPathRoute>
                                    <ExactPathRoute path="/resetsenhausr">
                                        <ResetSenhaUsuario />
                                    </ExactPathRoute>
                                    <ExactPathRoute path="/visualizar">
                                        <VisualizarDocumento />
                                    </ExactPathRoute>
                                    <AuthRoute path="/login" type="guest">
                                        <Login />
                                    </AuthRoute>
                                    <AuthRoute path="/ws" type="private">
                                        <Workspace />
                                    </AuthRoute>
                                </Switch>
                            </IY2BHotKeys>
                        </Suspense>
                    </ErrorBoundary>
                </HashRouter>
            </ConnectedRouter>
        </Provider>
    );

}

export default App;

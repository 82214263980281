import 'moment/locale/pt-br';
import moment from "moment";
import React from 'react';
import {Button, Input, Modal, Space, Tooltip} from 'antd';
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import InputMask from "../3rdpart/inputmask-core";
import axios from 'axios';
import {postJSON} from "./RequestAPI";
import {dateJSLoader} from "../3rdpart/DateJS/DateJS";
import {skinPrimaryColor} from "../assets/css/skins/StyleSkin";
import dayjs from "dayjs";
import {G2} from "@ant-design/plots";

moment.locale("pt-br");

const { registerTheme } = G2;

const CHART_SERIES_COLORS_10 = [
    '#008552',
    '#392E95',
    '#A41A41',
    '#CB9C3F',
    '#00B978',
    '#6C4EBC',
    '#8DDCBC',
    '#B6A6DE',
    '#DFB1BE',
    '#FDD280',
];

const CHART_SERIES_COLORS_20 = [
    '#008552',
    '#392E95',
    '#A41A41',
    '#CB9C3F',
    '#141414',
    '#00B978',
    '#6C4EBC',
    '#C1657F',
    '#F6B942',
    '#656565',
    '#8DDCBC',
    '#B6A6DE',
    '#DFB1BE',
    '#FDD280',
    '#E4E4E4',
    '#00653B',
    '#392E95',
    '#9A002C',
    '#EAAF3D',
    '#525252',
];

// https://theme-set.antv.vision/
registerTheme('temaEthimo', {
    styleSheet: {
        fontFamily: 'Space Grotesk',
    },
    fontFamily: 'Space Grotesk',
    colors10: CHART_SERIES_COLORS_10,
    colors20: CHART_SERIES_COLORS_20,
    components: {
        axis: {
            common: {
                label: {
                    style: {
                        fontFamily: 'Space Grotesk',
                        fontWeight: "bolder"
                    }
                }
            }
        }
    },
    labels: {
        style: {
            fontFamily: 'Space Grotesk',
            fontWeight: "bolder"
        }
    }
});

export class ObjectToString {

    incluirSeparadorUndefined = true;

    unsetSeparadorUndefined = () => {
        this.incluirSeparadorUndefined = false;
    }

    itemToLabel = (item, label, separador) => {

        if (this.incluirSeparadorUndefined && (separador === null || separador === undefined)) {
            separador = ";";
        }

        let resultado = "";

        const labels = label.split(",");

        while (labels.length > 0) {

            const currLabel = labels.shift();

            resultado += this.getString(item, currLabel);

            if (separador !== undefined && labels.length > 0) resultado += separador;

        }

        return resultado;

    };

    itemToValueId = (item, valueId) => {

        const resultado = this.getString(item, valueId);

        return resultado;

    };

    itemToValue =  (item, label) => {

        const resultado = this.getString(item, label);

        return resultado.trim();

    };

    getString = (item, label) => {

        let resultado = "";

        const labels = label.trim().split(".");

        const currLabel = labels.shift();

        if (labels.length > 0) {

            let reminder = "";

            for (let i = 0; i < labels.length; i++) {

                if (reminder.length > 0) {
                    reminder += ".";
                }

                reminder += labels[i];

            }

            if (item != undefined && item.hasOwnProperty(currLabel)) {
                resultado = this.getString(item[currLabel], reminder);
            } else {
                resultado = "";
            }

        } else {

            if (item != undefined && item.hasOwnProperty(currLabel)) {
                resultado = item[currLabel];
                if (resultado == undefined || resultado == null) {
                    resultado = "";
                }
            } else {
                resultado = "";
            }

        }

        return resultado + ""; // força conversão para string ....?

    };

};

const campoComboInformado = (campo) => {

    if(campo === null || campo === undefined) return false;

    if(campo.hasOwnProperty("value") === false) return false;

    return true;

};

const campoInformado = (campo) => {

    if(campo === null || campo === undefined) return false;

    return true;

};

const campoInformadoString = (campo) => {

    if(campo === null || campo === undefined) return false;

    if(campo.trim().length == 0) return false;

    return true;

};


const searchPropsTableCell = (dataIndex, customRender) => {

    let searchInput = null;
    let searchText = "";
    let searchedColumn = "";

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        searchText = selectedKeys[0];
        searchedColumn = dataIndex;
    };

    const handleReset = clearFilters => {
        clearFilters();
        searchText = "";
    };

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node;
                    }}
                    placeholder={`Procurar em ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Procurar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Limpar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? skinPrimaryColor : undefined }} />,
        onFilter: (value, record) => {
            let cellValue = record[dataIndex];

            if(customRender != null && customRender != undefined) {
                cellValue = customRender(cellValue);
            }

            return cellValue
                ? cellValue.toString().toLowerCase().includes(value.toLowerCase())
                : ''
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        },
        render: (text, row, index) => {

            if(customRender != null && customRender != undefined) {
                text = customRender(text, row, index);
            }

            return searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            );
        }
    }
};


const clobTableCellRender = (text, row, index) => {

    let resultado = "";

    if(text != undefined) {

        resultado = (
            <Tooltip placement="topLeft" title={text}>
                {text}
            </Tooltip>
        );

    }

    return resultado;

};

const decimalTableCellRender = (decimais) => {

    return (text, row, index) => {

        let resultado = "";

        if(text != undefined) {

            resultado = new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: decimais
            }).format(text);

        }

        return resultado;

    }

};

const dateTableCellRender = (text, row, index) => {

    let resultado = "";

    if(text !== undefined) {

        if(text === "1901-01-01" || text === "1901/01/01" || text === "-") {

            resultado = "";

        } else {

            resultado = moment(text, "YYYY-MM-DD").format("DD/MM/YYYY");

        }

    }

    return resultado;

};

const offSetFromDocument = (el) => {

    const rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

const fileToBase64 = async (file) => {

    return new Promise((resolve, reject) => {

        // encode the file using the FileReader API
        const reader = new FileReader();

        reader.onerror = () => {

            reject(reader.error);

        };

        reader.onloadend = () => {

            // use a regex to remove data url part
            const base64String = reader.result
                .replace("data:", "")
                .replace(/^.+,/, "");

            resolve(base64String);

        };

        reader.readAsDataURL(file);

    });

};

const calcDigModN = (conteudo, modN) => {

    conteudo = conteudo + "";

    modN = parseInt(modN);

    let nMenosUm = modN - 1;

    let loop;
    let mult = 2;
    let soma = 0;
    let valor;
    let produto;
    let result;
    let digVerif;
    let digito;

    for (loop = conteudo.length; loop >= 1; loop--) {

        digito = parseInt(conteudo.substr(loop - 1, 1));

        produto = digito * mult;

        if (produto > nMenosUm) {

            valor = parseInt(produto.toString().substr(0, 1)) + parseInt(produto.toString().substr(1, 1));

        } else  {

            valor = produto;

        }

        soma += valor;

        if (mult == 2) {

            mult = 1;

        } else {

            mult = 2;

        }

    }

    result = modN - (soma % modN);

    if (result == modN) {

        digVerif = 0;

    } else {

        digVerif = result;

    }

    return digVerif;

};

const calcDigMod10 = (conteudo) => {

    return calcDigModN(conteudo, 10);

};

const calcDigMod11 = (conteudo) => {

    return calcDigModN(conteudo, 11);

};

class GRID_SYSTEM {

    constructor(value, name) {
        this._value = value;
        this._name = name;
    }

    compare(to) {
        if(this._value > to._value) return 1;
        if(this._value === to._value) return 0;
        if(this._value < to._value) return -1;
    }

    get name () { return this._name };

}

const iy2bPolyfill = () => {

    const apiCustomEvent = () => {

        if ( typeof window.CustomEvent === "function" ) return false;

        function CustomEvent ( event, params ) {
            params = params || { bubbles: false, cancelable: false, detail: null };
            const evt = document.createEvent( 'CustomEvent' );
            evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
            return evt;
        }

        window.CustomEvent = CustomEvent;

    }

    apiCustomEvent();

    if (!Array.isArray) {
        Array.isArray = function(arg) {
            return Object.prototype.toString.call(arg) === '[object Array]';
        };
    }
}


const iy2bLibJS = () => {

    console.log("iy2bLibJS");

    iy2bPolyfill();

    dateJSLoader();

    window.AMBIENTE_DESENV = () => {
        // return false;
        return (process.env.NODE_ENV === 'development');
    }

    window.VERSAO_ATUAL = () => {
        return process.env.REACT_APP_PACKAGE_VERSION;
    }

    const _isTouchDevice_ =
        (('ontouchstart' in window) ||
            (navigator.MaxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));

    window.isTouchDevice = () => {
        return _isTouchDevice_;
    }

    window._UNDEFINED_ = new GRID_SYSTEM(-1, "?");

    window._XS_ = new GRID_SYSTEM(0, "xs");
    window._SM_ = new GRID_SYSTEM(1, "sm");
    window._MD_ = new GRID_SYSTEM(2, "md");
    window._LG_ = new GRID_SYSTEM(3, "lg");
    window._XL_ = new GRID_SYSTEM(4, "xl");
    window._XXL_ = new GRID_SYSTEM(5, "xxl");

    window.dataZerada = moment("1901/01/01", "YYYY/MM/DD");

    window.ehDataZerada = (data) => {
        return data.toMoment().ehDataZerada();
    }

    if(String.prototype.hasOwnProperty("replaceAll") === false) {

        function escapeRegExp(string) {
            return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        }

        String.prototype.replaceAll = function(find, replace) {

            return this.replace(new RegExp(escapeRegExp(find), 'g'), replace);

        }

    }

    String.prototype.toDayJS = function (format) {
        if(this === "") return window.dataZerada.toDayJS();
        const newValue = dayjs(this, format||"YYYY/MM/DD");
        return newValue;
    }

    String.prototype.toMoment = function (format) {

        if(this === "") return window.dataZerada;

        return moment(this, format||"YYYY/MM/DD");

    };

    String.prototype.toMomentHour = function (format) {

        if(this === "") return window.dataZerada;

        return moment(this, format||"HH:mm");

    };

    String.prototype.toDate = function (format) {

        return moment(this, format).toDate();

    };

    String.prototype.toDateHourFormat = function (format) {

        return moment( this, "YYYY-MM-DD HHmmss").format(format);

    };

    String.prototype.toHourFormat = function (format) {

        return moment("1901-01-01 " + this, "YYYY-MM-DD HHmmss").format(format);

    };

    String.prototype.toDateFormat = function (format) {

        return moment(this, "YYYY-MM-DD").format(format);

    };

    String.prototype.toDecimal = function (decs) {
        const decimais = (decs===null || decs===undefined) ? 2 : decs ;
        const floatFormatter = new Intl.NumberFormat('en-US', {
            style: "decimal",
            useGrouping: false,
            minimumFractionDigits: decimais,
            maximumFractionDigits: decimais,
        });
        const value = this;
        const newValue = (value===null||value===undefined||value==="") ? 0 : parseFloat(value.replaceAll(".", "").replace(",", "."));
        const resultado = parseFloat(floatFormatter.format(newValue));
        return resultado;
    }

    String.prototype.toDecimalScreen = function(decs) {
        const decimais = (decs===null || decs===undefined) ? 2 : decs ;
        const floatFormatter = new Intl.NumberFormat('pt-BR', {
            style: "decimal",
            useGrouping: true,
            minimumFractionDigits: decimais,
            maximumFractionDigits: decimais,
        });
        const value = this;
        const newValue = (value===null||value===undefined||value==="") ? "0" : value;
        const resultado = floatFormatter.format(newValue);
        return resultado;
    };

    const removeAccents = str =>
        str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    String.prototype.retiraAcentos = function () {
        const resultado = removeAccents ( this );
        return resultado;
    }

    String.prototype.retiraCaracteres = function (chars) {

        const toRemove = chars.split('');
        const array = this.split('');

        const newString = array.filter( c => {
            return toRemove.indexOf(c) === -1;
        }).join('');

        return newString;
    };

    String.prototype.getQSOptions = function () {

        const partes = this.split("&");

        const opcoes = { };

        partes.forEach(function(parte) {

            const conteudos = parte.split("=");

            if(conteudos.length == 2) {
                opcoes[conteudos[0]] = conteudos[1];
            }

        });

        return {
            opcoes: opcoes,
            get: function(opcao) {
                return opcoes[opcao]
            }
        }

    };

    String.prototype.format = function() {
        let args = arguments;
        if (Array.isArray(arguments[0]) === true) {
            args = arguments[0];
        }
        return this.replace(/{(\d+)}/g, function(match, number) {
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
                ;
        });
    };

    Number.prototype.asDecimal = Number.prototype.toDecimal = function(decs) {
        const decimais = (decs===null || decs===undefined) ? 2 : decs ;
        const floatFormatter = new Intl.NumberFormat('en-US', {
            style: "decimal",
            useGrouping: false,
            minimumFractionDigits: decimais,
            maximumFractionDigits: decimais,
        });
        const value = this;
        const newValue = (value===null||value===undefined||value===""||isNaN(value)) ? "0" : value+"";
        const resultado = floatFormatter.format(newValue);
        return parseFloat(resultado);
    };

    Number.prototype.toScreen = function(decs) {
        const decimais = (decs===null || decs===undefined) ? 2 : decs ;
        const floatFormatter = new Intl.NumberFormat('pt-BR', {
            style: "decimal",
            useGrouping: true,
            minimumFractionDigits: decimais,
            maximumFractionDigits: decimais,
        });
        const value = this;
        const newValue = (value===null||value===undefined||value===""||isNaN(value)) ? "0" : value+"";
        let resultado = floatFormatter.format(newValue);
        if(decimais===0) {
            if(resultado.indexOf(",") > -1) {
                resultado = resultado.substr(0, resultado.indexOf(","));
            }
        }
        return resultado;
    };

    Number.prototype.asCurrency = function(decs) {
        const decimais = (decs===null || decs===undefined) ? 2 : decs ;
        // Create our number formatter.
        const formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            currencyDisplay: 'symbol',
            minimumFractionDigits: decimais,
            maximumFractionDigits: decimais,
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });

        return formatter.format(this);

    }

    Date.prototype.format = function (format) {

        return moment(this).format(format);

    };

    Date.prototype.adiciona = function (amount, unit) {

        let now = moment(this).add(amount, unit);

        let resultado = now.toDate();

        return resultado;

    };

    Date.prototype.subtrai = function (amount, unit) {

        try {

            const result = moment(this).subtract(amount, unit).toDate();

            return result;

        } catch (err) {

            return undefined;

        }

    };

    Date.prototype.januaryFirstDay = function () {
        const strDt = this.getFullYear() + "/01/01";
        return new Date(strDt);
    }

    Date.prototype.decemberFirstDay = function () {
        const strDt = this.getFullYear() + "/12/01";
        return new Date(strDt);
    }

    Date.prototype.firstDay = function () {

        //new Date("2015/06/29");
        const strDt = this.getFullYear() + "/" + (this.getMonth() + 1) + "/01";

        return new Date(strDt);

    };

    Date.prototype.lastDay = function () {

        const first = this.firstDay();

        const nextMonth = first.add(1).month();

        return nextMonth.add(-1).day();

    };

    dayjs.prototype.ehDataZerada = function () {

        return this.format("YYYY/MM/DD") === "1901/01/01";

    }

    dayjs.prototype.toScreen = function () {

        if(this.ehDataZerada()) {
            return null;
        } else {
            return this;
        }

    }

    dayjs.prototype.toMoment = function (format) {

        if(this === "") return window.dataZerada;

        const dayJSFormat = format||"YYYY/MM/DD";

        return moment(this.format(dayJSFormat), dayJSFormat);

    };

    moment.prototype.toDayJS = function () {
        const newValue = dayjs(this.format("YYYY/MM/DD"), "YYYY/MM/DD");
        return newValue;
    }

    moment.prototype.compareTo = function (another, scale) {

        if(scale === null || scale === undefined) return this.diff(another, "days", true);

        return this.diff(another, scale, true);

    }


    moment.prototype.ehDataZerada = function () {

        return this.format("YYYY/MM/DD") === "1901/01/01";

    }

    moment.prototype.toTextScreen = function () {

        if(this.ehDataZerada()) {
            return "";
        } else {
            return this.format("DD/MM/YYYY");
        }

    }

    moment.prototype.toScreen = function () {

        if(this.ehDataZerada()) {
            return null;
        } else {
            return this;
        }

    }

    moment.prototype.trim = function () {
        if(this.ehDataZerada()) {
            return "";
        } else {
            return this.format("YYYY/MM/DD");
        }
    }

    Array.prototype.toHashMap = function(key) {
        const mapa = this.reduce((map, obj) => {
            map[obj[key]] = obj;
            return map;
        }, {});
        return mapa;
    };

    Array.prototype.desmarca = function() {

        const marcados = this.filter(item => item.marcado === true);

        marcados.forEach(item => item.marcado = false);

    }

    Array.prototype.isEmpty = function() {

        return (this.length == 0) ;

    };

    if(Array.prototype.add === null || Array.prototype.add === undefined) {

        Array.prototype.add = function(item) {
            return this.push (item) ;
        };

    }

    Array.prototype.addAll = Array.prototype.addFrom = function(fromArray) {

        const _fromArray = fromArray || [];

        for (let i = 0; i < _fromArray.length; i++) {
            this.push ( _fromArray[i] );
        }

        return this.slice(0);

    };

    Array.prototype.item = function(index) {

        return this[index];

    };

    Array.prototype.last = function() {

        if(this.length == 0) return null;

        return this[this.length - 1];

    };

    Array.prototype.getByTree = function(property, value) {

        const resultado = [];

        this.forEach(item => {

            if(item[property] === value) {
                resultado.push(item);
            }

            if(item.hasOwnProperty("children")) {

                const childs = item.children.getByTree(property, value);

                resultado.addAll(childs);

            } else if(item.hasOwnProperty("childs")) {

                const childs = item.childs.getByTree(property, value);

                resultado.addAll(childs);

            }

        });

        return resultado;

    }

    Array.prototype.getBy = function(property, value) {

        const encontrados = this.filter(item => (item[property] === value));

        const resultado = (encontrados.length>0)?encontrados[0]:null;

        return resultado;

    };

    Array.prototype.findValue = function(value) {

        const resultado = [];

        const keys = Object.keys(value);

        this.filter(item => {

            const iguais = [];

            keys.forEach(key => {

                if(item[key] === value[key]) {
                    iguais.push(key);
                }

            });

            if(iguais.length === keys.length) resultado.push(item);

        });

        return resultado;

    };

    Array.prototype.clone = function() {

        return this.slice(0);

    };

    Array.prototype.setItems = function(data) {

        this.clear();

        this.addAll(data);

    };

    Array.prototype.clear = function() {

        this.splice(0, this.length);

        return this.slice(0);

    };

    Array.prototype.removeAll = function() {

        this.splice(0);

        return this.slice(0);

    };

    Array.prototype.removeAt = function(index) {

        this.splice(index, 1);

        return this.slice(0);

    };

    Array.prototype.removeBy = function(property, value) {

        const item = this.getBy(property, value);

        if(item !== null) {

            return this.removeItem(item);

        } else {

            return this.slice(0);

        }

    };

    Array.prototype.removeItem = function(item) {

        const index = this.indexOf(item);

        if(index > -1) {

            this.splice(index, 1);

        }

        return this.slice(0);

    };

    Array.prototype.removeItems = function(list) {

        list.forEach(item => {

            this.removeItem ( item );

        });

        return this.slice(0);

    }

    Array.prototype.removeList = function(property, list) {

        list.forEach(item => {

            const toRemove = this.getBy(property, item[property]);

            const index = this.indexOf(toRemove);

            if(index > -1) {

                this.splice(index, 1);

            }

        });

        return this.slice(0);

    }

    Array.prototype.toClassName = function () {
        return this.join(" ");
    }

    Array.prototype.formatTo = function(formatter, separador) {

        const objToStr = new ObjectToString();

        const resultado = [];

        for (let i = 0; i < this.length; i++) {

            const data = this[i];

            const item = {
                data: data
            };

            Object.keys(formatter).forEach(key => {

                const value = formatter[key];

                if(value.indexOf(",") >= 0) {

                    item[key] = objToStr.itemToLabel(data, value,separador||" - ");

                } else {

                    item[key] = data[value];

                }

            });

            resultado.push(item);

        }

        return resultado;

    };

};

const ua  = navigator.userAgent;
const currentBrowser =  { };

currentBrowser.ISFF     = ua.indexOf('Firefox') != -1;
currentBrowser.ISOPERA  = ua.indexOf('Opera') != -1;
currentBrowser.ISCHROME = ua.indexOf('Chrome') != -1;
currentBrowser.ISSAFARI = ua.indexOf('Safari') != -1 && !currentBrowser.ISCHROME;
currentBrowser.ISWEBKIT = ua.indexOf('WebKit') != -1;
currentBrowser.ISIE     = ua.indexOf('Trident') > 0 || ua.indexOf('MSIE') > 0;

currentBrowser.ISANDROID = /android/i.test(ua);
currentBrowser.ISIOS     = (/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
currentBrowser.ISMOBILE  = currentBrowser.ISANDROID || currentBrowser.ISIOS;

console.log("navigator.userAgentData", navigator.userAgentData);

console.log(ua, "currentBrowser", currentBrowser);

const body = document.getElementsByTagName('body')[0];

if(currentBrowser.ISMOBILE === true) {

    body.classList.add("iy2b-mobile");

    if(currentBrowser.ISIOS === true) {
        body.classList.add("iy2b-ios");
    } else {
        body.classList.add("iy2b-android");
    }

} else {

    body.classList.add("iy2b-desktop");

}

console.log(body);

const DIGIT_RE = /^\d$/;
const LETTER_RE = /^[A-Za-z]$/;
const LOWER_LETTER_RE = /^[a-z]$/;
const UPPER_LETTER_RE = /^[A-Z]$/;
const ALPHANNUMERIC_RE = /^[\dA-Za-z]$/;

export function isDigit (char) { return DIGIT_RE.test(char) };
export function isLetter (char) { return LETTER_RE.test(char) };
export function isUpperLetter (char) { return UPPER_LETTER_RE.test(char) };
export function isLowerLetter (char) { return LOWER_LETTER_RE.test(char) };
export function isAlphaNumeric (char) { return ALPHANNUMERIC_RE.test(char) };

export function topFunction() {
    console.log("topFunction")
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

export function scrollToTop (divId) {
    console.log("scrollToTop");
    let boxContent = document.getElementById(divId);
    if(boxContent === null) boxContent = document.getElementsByClassName(divId)[0];
    boxContent.scrollTop = 0;
}

export function scrollToBottom (divId) {
    let boxContent = document.getElementById(divId);
    if(boxContent === null) boxContent = document.getElementsByClassName(divId)[0];
    boxContent.scrollTop = boxContent.scrollHeight;
}

export function observeElement(element, property, callback, delay = 0) {
    let elementPrototype = Object.getPrototypeOf(element);
    console.log(elementPrototype, property, elementPrototype.hasOwnProperty(property));
    if (elementPrototype.hasOwnProperty(property)===true) {
        const descriptor = Object.getOwnPropertyDescriptor(elementPrototype, property);
        console.log(descriptor);
        Object.defineProperty(element, property, {
            get: function() {
                return descriptor.get.apply(this, arguments);
            },
            set: function () {
                let oldValue = this[property];
                descriptor.set.apply(this, arguments);
                let newValue = this[property];
                console.log(oldValue, newValue, callback)
                if (typeof callback == "function") {
                    setTimeout(callback.bind(this, oldValue, newValue), delay);
                }
                return newValue;
            }
        });
    }
}

const formatadorCNPJ15 = new InputMask({pattern: "111.111.111/1111-11"});
const formatadorCNPJ14 = new InputMask({pattern: "11.111.111/1111-11"});
const formatadorCPF = new InputMask({pattern: "111.111.111-11"});

export function formatadorCpfCnpj (value) {

    if(campoInformado(value)===false) return "";

    const nrCpfCnpj = value.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "");

    if(nrCpfCnpj.trim().length===14) {
        formatadorCNPJ14.setValue(nrCpfCnpj);
        return formatadorCNPJ14.getValue();
    } else if(nrCpfCnpj.trim().length===15) {
        formatadorCNPJ15.setValue(nrCpfCnpj);
        return formatadorCNPJ15.getValue();
    } else if(nrCpfCnpj.trim().length===11) {
        formatadorCPF.setValue(nrCpfCnpj);
        return formatadorCPF.getValue();
    }

    return "";
}

const validaCGC = ( cCGC ) =>
{

    if (cCGC.length != 14)
    {
        // alert ("CNPJ deve ter 14 digitos.");
        return (false);
    }

    // Define variaveis

    let nCont = 12;
    let nIndice = 0;
    let nDigito = 0;
    let nTotal1 = 0;
    let nTotalCGC = 0;
    let nAux2 = 1;
    let nNumero;
    let nDVCGC1;
    let nDVCGC2;
    let nValidaCGC = false;

    // cCGC = TrocaStr (cCGC, " ", "0")

    while (nAux2 <= 3)
    {
        for (nCont=11; nCont>= 0; nCont--)
        {
            if (nCont == 4 || nCont == 3)
            {
                if (nAux2 == 9)
                {
                    nAux2 = 2;
                }
                else
                {
                    nAux2 = nAux2 + 1;
                }
            }
            else
            {
                nAux2 = nAux2 + 1;
            }

            // nTotal1 = CInt(Mid$(cCGC, nCont, 1)) * nAux2;

            nTotal1 = parseInt(cCGC.substr(nCont,1),10) * parseInt(nAux2,10) ;

            if (nCont == 0)
            {
                if (nAux2 == 6)
                {
                    nDigito = nDigito * 2;
                }
                else
                {
                    nDigito = 0;
                }

                nTotal1 = nTotal1 + nDigito;
            }

            nTotalCGC = nTotalCGC + nTotal1;

        }

        nNumero = 11 - (nTotalCGC - (parseInt((nTotalCGC / 11),10)) * 11);

        if (nNumero > 9)
        {
            nDigito = 0;
        }
        else
        {
            nDigito = nNumero;
        }

        if (nAux2 == 6)
        {
            nIndice = new String (nIndice) + new String (nDigito);
        }
        else
        {
            nIndice = nDigito;
        }

        if (nAux2 == 5)
        {
            nAux2 = 2;
        }
        else
        {
            nAux2 = 6;
        }

        nDVCGC1 = (parseInt(cCGC.substr(12, 2),10)); //'Verificadores Analisados
        nDVCGC2 = nIndice;                            //'Verificadores Encontrados

        if (nDVCGC1 != nDVCGC2)
        {
            nValidaCGC = false;
        }
        else
        {
            nValidaCGC = true;
        }
        //' Inicializa as variaveis p/ 2 digito
        nTotalCGC = 0;
    }
    return ( nValidaCGC );
};

const validaCIC = ( cCIC ) =>
{

    if (cCIC.length != 11 )
    {
        // alert ("CPF deve ter 11 digitos.");
        return (false);
    }

    // Define variaveis

    let nValidaCIC = false;
    let nResto;
    let nDig1;
    let nDig2;

    let nIndice = 0;
    let nMultiplo = 11;
    let nCalculo = 0;

    while (nIndice < 9)
    {
        nMultiplo--;
        nCalculo = nCalculo + parseInt(cCIC.substr(nIndice, 1), 10) * (nMultiplo);
        nIndice++;
    }

    nResto = nCalculo - (parseInt(nCalculo / 11,10) * 11);

    if ( nResto == 0 || nResto == 1 )
    {
        nDig1 = 0;
    }
    else
    {
        nDig1 = 11 - nResto;

    }

    // Inicializa variaveis p/ calculo do nDig2

    nIndice = 0;
    nMultiplo = 12;
    nCalculo = 0;

    while (nIndice < 10)
    {
        nMultiplo--;
        nCalculo = nCalculo + parseInt(cCIC.substr(nIndice, 1),10) * (nMultiplo);
        nIndice++;
    }

    nResto = nCalculo - (parseInt(nCalculo / 11,10) * 11);

    if ( nResto == 0 || nResto == 1 )
    {
        nDig2 = 0;
    }
    else
    {
        nDig2 = 11 - nResto;
    }

    if ( (parseInt(cCIC.substr(nIndice - 1, 1),10) != nDig1) || (parseInt(cCIC.substr(nIndice, 1),10) != nDig2) )
    {
        nValidaCIC = false;
    }
    else
    {
        nValidaCIC = true;

    }

    return ( nValidaCIC );
};


export function cpfValido (conteudo) {
    if(conteudo == null || conteudo == undefined) return false;
    if(conteudo == "") return false;
    return validaCIC(conteudo);
};

export function cnpjValido (conteudo) {
    if(conteudo == null || conteudo == undefined) return false;
    if(conteudo == "") return false;
    return validaCGC(conteudo);
};

export function cpfOUcnpjValido (conteudo) {

    if(conteudo == null || conteudo == undefined) return false;

    if(conteudo == "") return false;

    if(conteudo.length == 11) {
        return validaCIC(conteudo);
    } else if(conteudo.length == 14) {
        return validaCGC(conteudo);
    } else {
        return false;
    }

};

export async function pesquisaCEP ( nrCEP ) {

    let response ;

    try {

        response = await axios.get("https://viacep.com.br/ws/" + nrCEP + "/json/");

    } catch (e) {

        console.log(e);

        return Promise.reject( new Error("Serviço dos correios indisponivel no momento !") );

    }

    if(response.data.hasOwnProperty("erro")) {

        return Promise.reject( new Error("CEP não encontrado !") );

    } else {

        return Promise.resolve(response.data);

    }

}

export function buildColunasExpDados (tblCols) {

    const resultado = JSON.stringify( tblCols
        .filter(col => campoInformadoString(col.title) || campoInformadoString(col.noColuna))
        .map(col => {
            return {
                header: col.title||col.noColuna,
                field: col.dataIndex||col.field,
                format: col.format||col.formatter,
            }
        }));

    return resultado;

}

export const ObjectUtils = {
    filterKeys:  (obj, condition) => {
        if(obj === null || obj === undefined) return null;
        return Object.keys(obj).filter(condition);
    },
    valuesFrom: (obj, attrs) => {
        const resultado = { };
        if(obj === null || obj === undefined) return resultado;
        attrs.forEach(attr => {
            if(obj[attr] === null || obj[attr] === undefined) {
                resultado[attr] = null;
            } else {
                resultado[attr] = obj[attr];
            }
        });
        return resultado;
    },
    setValuesIn: (to, from, attrs) => {
        if(from === null || from === undefined) return;
        attrs.forEach(attr => {
            if(from[attr] === null || from[attr] === undefined) {
                to[attr] = null;
            } else {
                to[attr] = from[attr];
            }
        });
    },
    setAllValuesIn: (to, from) => {
        if(from === null || from === undefined) return;
        const attrs = Object.keys(from);
        attrs.forEach(attr => {
            if(from[attr] === null || from[attr] === undefined) {
                to[attr] = null;
            } else {
                to[attr] = from[attr];
            }
        });
    },
}

const solicitarExpDados = async (args) => {

    const {filtro, idLista="listaPesquisa", formato="CSV", colunas=[], sistema="sistema", projeto="projeto", loadingArea, extraPayload} = args;

    const payload = {
        acaoAExecutar: "pesquisa",
        filtro: filtro,
        idResposta: idLista,
        exportacaoDefault: "S",
        cdOpcaoExportacao: formato,
        colunas: buildColunasExpDados( colunas )
    }

    if(campoInformado(extraPayload)) {

        Object.keys(extraPayload).forEach(key => {
            payload[key] = extraPayload[key];
        });

    }

    try {

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: sistema,
            projeto: projeto
        }, payload, loadingArea);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaArqDownload") {

                const arqDownload = msg.lista[0];

                window.open(arqDownload.urlArquivo,'_blank');

            } else {

                console.log(msg);

            }

        });

    } catch (e) {

        console.log(e);

    }

    return true;

}

const asCurrency = (value, decimais) => {

    // Create our number formatter.
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        currencyDisplay: 'symbol',
        minimumFractionDigits: decimais||2
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value);
}

const urlAsBlob = async (url) => {

    try {

        const result = await fetch(url);

        return result.blob();

    } catch (e) {

        return null;
    }

}


const tableHeightCalcs = {

    boxWithFormFilter: (params) => {

        const {gridWidth, containerHeight, boxName, filtroContainer="formFiltroContainer"} = params;

        const elRoot = document.getElementById(boxName);

        const ffContainer = elRoot?.getElementsByClassName(filtroContainer);

        //if(ffContainer===null || ffContainer===undefined) return 0;
        //const formHeight = ffContainer[0].clientHeight;

        const formHeight = (ffContainer===null || ffContainer===undefined) ? 0 : ffContainer[0].clientHeight;

        const tableLayout = 70;
        const GAP = 0;
        const GAP_MOBILE = gridWidth.compare(window._XS_) <= 0 ? 20 : 0;

        const tableHeight = containerHeight - formHeight - tableLayout - GAP - GAP_MOBILE;

        // console.log("boxWithFormFilter.tableHeight = containerHeight - formHeight - tableLayout - GAP - GAP_MOBILE", tableHeight, containerHeight,  formHeight,  tableLayout,  GAP, GAP_MOBILE, gridWidth);

        return tableHeight;

    },

    boxWithActions: (params) => {

        const {gridWidth, containerHeight, boxName, actionsContainer="box-btn-acoes-disponiveis"} = params;

        const elRoot = document.getElementById(boxName);

        const ffContainer = elRoot?.getElementsByClassName(actionsContainer);

        //if(ffContainer===null || ffContainer===undefined) return 0;
        //const formHeight = ffContainer[0].clientHeight;
        const formHeight = (ffContainer===null || ffContainer===undefined) ? 0 : ffContainer[0].clientHeight;

        const tableLayout = 70;
        const GAP = 0;
        const GAP_MOBILE = gridWidth.compare(window._XS_) === 0 ? 20 : 0;

        const tableHeight = containerHeight - formHeight - tableLayout - GAP - GAP_MOBILE;

        // console.log("boxWithActions.tableHeight = containerHeight - formHeight - tableLayout - GAP - GAP_MOBILE", tableHeight, containerHeight,  formHeight,  tableLayout,  GAP, GAP_MOBILE, gridWidth);

        return tableHeight;

    },

    boxFull: (params) => {

        const {gridWidth, containerHeight, boxName, gap=60} = params;

        const tableLayout = 0;
        const GAP = gap;
        const GAP_MOBILE = gridWidth.compare(window._XS_) === 0 ? 20 : 0;

        const tableHeight = containerHeight - tableLayout - GAP - GAP_MOBILE;

        return tableHeight;

    },

    boxWithGapBox: (params) => {

        const {gridWidth, containerHeight, boxName, boxGapHeightName, tableLayout = 70, GAP = 0} = params;

        const elRoot = document.getElementById(boxName);

        const ffContainer = elRoot?.getElementsByClassName(boxGapHeightName);

        if(ffContainer===null || ffContainer===undefined) return 0;

        const formHeight = ffContainer[0].clientHeight;

        const GAP_MOBILE = gridWidth.compare(window._XS_) === 0 ? 20 : 0;

        const tableHeight = containerHeight - formHeight - tableLayout - GAP - GAP_MOBILE;

        return tableHeight;

    },

};


const preenche = function ( cString, cChar, cEsqDir, nTam ) {
    // preenche com zeros a esquerda ate' preencher o tamanho
    // JC - 03/04/00
    // onde cString = fonte
    //      cChar = caracter
    //        cEsqDir = E ou D
    //      nTam = tamanho

    let cAux=new String(cString);
    const nMax=(parseInt (nTam, 10) - cAux.length);
    let nCont;

    cChar=cChar.substr(0,1)

    cEsqDir=cEsqDir.substr(0,1);
    cEsqDir=cEsqDir.toUpperCase();

    for ( nCont = 1; nCont <= nMax; nCont++ )
    {
        if (cEsqDir == "E")
            cAux=cChar + cAux;
        else
            cAux+=cChar;
    }

    return (cAux);
}

const confirm = (props) => {

    const {
        description = "Confirma operação ?",
        message = "Confirmação",
        okText = "Sim",
        cancelText = "Não",
        onOk = () => "ok",
        onCancel = () => "cancel"
    } = props;

    const confirmProps = {
        title: message,
        content:<div>{description}</div>,
        okText: okText,
        onOk() {
            onOk();
        },
        cancelText: cancelText,
        onCancel() {
            onCancel();
        }
    };

    Modal.confirm(confirmProps);

}

const confirmWithPromise = (props) => {

    const {
        description,
        message = "Confirmação",
        okText = "Sim",
        cancelText = "Não",
        onOk = () => "ok",
        onCancel = () => "cancel"
    } = props;

    return new Promise((resolve, reject) => {

        const confirmProps = {
            title: message,
            content:<div>{description}</div>,
            okText: okText,
            onOk() {
                resolve( onOk() );
            },
            cancelText: cancelText,
            onCancel() {
                resolve( onCancel() );
            }
        };

        Modal.confirm(confirmProps);

    }).catch((e) => {
        console.log('Oops errors at confirmWithPromise', e);
    });

}

const visitaTreeMapDesc = (leaf, fn) => {
    fn(leaf);
    if(leaf.hasOwnProperty("children")) {
        leaf.children.forEach(child => visitaTreeMapDesc(child, fn));
    } else if(leaf.hasOwnProperty("childs")) {
        leaf.childs.forEach(child => visitaTreeMapDesc(child, fn));
    }
}

const visitaTreeMapAsc = (leaf, fn) => {
    if(leaf.hasOwnProperty("children")) {
        leaf.children.forEach(child => visitaTreeMapAsc(child, fn));
    } else if(leaf.hasOwnProperty("childs")) {
        leaf.childs.forEach(child => visitaTreeMapAsc(child, fn));
    }
    fn(leaf);
}

const sortByLabel = (a, b) => {
    if(a.label === b.label) return 0;
    if(a.label > b.label) return 1;
    return -1;
}

const formataCidadeDoEndereco = endereco => {

    let resultado = endereco.noBairro?.trim();

    if(endereco.cdCidade.hasOwnProperty("noCidade") === true) {
        resultado += ", " + endereco.cdCidade.noCidade.trim();
        resultado += ", " + endereco.cdCidade.ufCidade.ufCidade.trim();
    } else {
        resultado += ", " + endereco.noCidade.trim();
        resultado += ", " + endereco.ufCidade.trim();
    }

    resultado += ", CEP " + endereco.nrCep.trim();

    return resultado;
}

const formataNoRuaDoEndereco = endereco => {

    /*
    registro.noRua = $scope.frmCadastro.txtNoLograd;
    if($scope.frmCadastro.txtTxNumero!=undefined && $scope.frmCadastro.txtTxNumero!=null){
        registro.noRua += ", "+$scope.frmCadastro.txtTxNumero.trim();
    }
    if($scope.frmCadastro.txtTxCompl!=undefined && $scope.frmCadastro.txtTxCompl!=null){
        registro.noRua += " - "+$scope.frmCadastro.txtTxCompl.trim();
    }
     */
    let noRua = endereco.noLograd?.trim();
    if(campoInformadoString(endereco.txNumero) === true) {
        noRua += ", " + endereco.txNumero.trim();
    }
    if(campoInformadoString(endereco.txCompl) === true) {
        noRua += " - " + endereco.txCompl.trim();
    }
    return noRua.substr(0, 200);
}

export {
    campoInformado,
    campoInformadoString,
    campoComboInformado,
    dateTableCellRender,
    decimalTableCellRender,
    clobTableCellRender,
    searchPropsTableCell,
    iy2bLibJS,
    currentBrowser,
    offSetFromDocument,
    fileToBase64,
    solicitarExpDados,
    asCurrency,
    calcDigModN,
    calcDigMod10,
    calcDigMod11,
    urlAsBlob,
    tableHeightCalcs,
    preenche,
    confirmWithPromise,
    confirm,
    visitaTreeMapDesc,
    visitaTreeMapAsc,
    sortByLabel,
    formataNoRuaDoEndereco,
    formataCidadeDoEndereco,
    CHART_SERIES_COLORS_10,
    CHART_SERIES_COLORS_20,
}

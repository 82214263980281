//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-card/dist/card.css';

import '../../framework/assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {notificacaoErroValidForm, useQueryString, useWindowSize} from "../../framework/lib/iy2b-react";
import Cookies from 'universal-cookie';

import {StepsForm} from '@ant-design/pro-components';

import {Col, Image, Layout, Row} from 'antd';
import {useSetupConfig} from "../../framework/lib/ConfigManager";
import Store from "../../store";
import {useHistory} from "react-router-dom";
import SinglePageLoadingIndicator from "../../framework/componentes/SinglePageLoadingIndicator";

import StepPlanos from "./StepPlanos";
import {topFunction} from "../../framework/lib/iy2b-javascript";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import IY2BProFormWizard from "../../framework/componentes/IY2BProFormWizard";
import {pesquisarPlanoContrVenda} from "../../framework/lib/Servicos";

import logo from "../../framework/assets/images/logo-contratacao.png";
import {notificacao} from "../../framework/lib/Notificacao";

const { Header, Content } = Layout;

const cookies = new Cookies( process.env.REACT_APP_NAME + "/contratar");

const Contratar = props => {

    useSetupConfig({
        appConfig: props.appConfig,
        store: Store
    });

    const { xs } = useBreakpoint();

    const formRef = useRef();

    const [dsPlanos, setDsPlanos] = useState([]);
    const [planoSelecionado, setPlanoSelecionado] = useState(null);

    const [tokenEmail, setTokenEmail] = useState(null);

    useEffect(() => {

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1);

        inicializa();

        return () => {

        };

    }, []);

    async function inicializa () {

        const planos = await pesquisaPlanos();

        setDsPlanos(planos);

    }

    const pesquisaPlanos = async () => {

        const planos = await pesquisarPlanoContrVenda();

        return planos;
    }

    const onSelecionaPlano = (plano) => {

        dsPlanos.filter(item => item.selecionado === true).map(item => {
            item.selecionado = false;
            return item;
        })

        plano.selecionado = true;

        setDsPlanos(dsPlanos.clone());

        setPlanoSelecionado(plano);

    }

    const onFinishWizard = async () => {

        console.log("onFinishWizard ???");

        topFunction();

    }

    const onFinishStepFailed = errorInfo => {
        notificacaoErroValidForm(errorInfo);
    };

    const onCurrentStepChange = (position, currentKey) => {

    };

    const recebeTokenEmail = (token) => {
        setTokenEmail ( token );
    }

    const stepFormPlano =
        <StepsForm.StepForm
            key={"stepPlano"}
            name = "stepPlano"
            title = "Plano acesso"
            stepProps = {{
                description: "Escolha o seu plano de acesso"
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = { async ( ) => {
                if(planoSelecionado === null) {
                    notificacao.erro({
                        message: 'Escolha um plano',
                        description:
                            'Você deve escolher um plano de assinatura para prosseguir !',
                    });
                    return false ;
                } else {
                    return true ;
                }
            }}
        >
            <StepPlanos
                cookies={cookies}
                dataSource={dsPlanos}
                onSelecionaPlano={onSelecionaPlano}
            />
        </StepsForm.StepForm>
    ;

    const wizSteps =
        <>
            {stepFormPlano}
        </>
    ;

    //182x52

    return (
        <div id={"wizardContratar"}>
            <Layout>
                <Header>
                    <Row align={"middle"} justify={"space-between"} gutter={0}>
                        <Col flex={"70px"} className={"display-contents"}>
                            <Image
                                className={"logo"}
                                preview={false}
                                src={logo}
                            />
                        </Col>
                        <Col flex={"auto"} className={"align-center"}>
                            <label>
                                Contratação da assinatura
                            </label>
                        </Col>
                    </Row>
                </Header>
                <Content>
                    <SinglePageLoadingIndicator />
                    <IY2BProFormWizard
                        formRef = { formRef }
                        onFinishWizard = { onFinishWizard }
                        onCurrentStepChange = { onCurrentStepChange }
                        stepsSize = "small"
                        steps={wizSteps}
                    >
                    </IY2BProFormWizard>
                </Content>
            </Layout>
        </div>
    );

};

const mapStateToProps = function( {appConfig, userSession} ) {

    return {
        appConfig: appConfig,
        userSession: userSession
    };

};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Contratar);

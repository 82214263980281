//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../../assets/css/tema.scss';
import "./index.scss";

import React, {useEffect} from "react";

import {Button, Col, Divider, Row, Spin, Tooltip} from "antd";
import {ProFormSelect} from '@ant-design/pro-form';
import debounce from "lodash/debounce";
import {campoInformadoString} from "../../../lib/iy2b-javascript";
import {useGridWidth, useStateRef} from "../../../lib/iy2b-react";
import {CUSTOM} from "../../../assets/iconografia";

const iconSearch = CUSTOM.EYE_ICON_GRAY;

const defaultRender = item => ({
    value: item?.value,
    label: item?.label,
    data: item?.data
});

const AutoComplete = (props) => {

    const [ , gridWidth ] = useGridWidth();

    const { name, formRef, selectedItem, onSelect, fetchOptions, visible=true, disabled = false, optionRender=null, renderOptions = defaultRender, debounceTimeout = 800, minChars = 4, withoutLabelSpace = false, numRow=null } = props;

    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);

    const [showingAll, setShowingAll, refShowingAll] = useStateRef(false);

    const [openOptions, setOpenOptions] = React.useState(false);

    const refAC = React.useRef();

    const myWidth = (props.width==="auto") ? (
            (gridWidth.current.compare(window._SM_)<=0) ? "lg"
                : (gridWidth.current.compare(window._LG_)<=0) ? "md"
                    : (gridWidth.current.compare(window._XL_)<=0) ? "lg"
                    : "xl")
        : props.width ;

    const { contentNotFoundAfterFetch = null, habilitaAdicao = false} = props;

    const notFoundContent = fetching ? <Spin size="small" /> : contentNotFoundAfterFetch;

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                setFieldValue: setFieldValue,
                focus: () => {
                    setTimeout(() => {
                        refAC.current.focus();
                    }, 1);
                },
                isShowingAll: () => (refShowingAll.current === true),
                showAll: () => {
                    showAll();
                },
                setFirst: () => {
                    const newValue = { };
                    newValue[ props.name ] = renderOptions(options[0]);
                    formRef?.current?.setFieldsValue(newValue);
                    setOpenOptions(false);
                }
            }
        };

        return () => {

        };

    }, []);

    const setFieldValue = (value) => {

        const clearValue = { };

        clearValue[ props.name ] = undefined;

        formRef?.current?.setFieldsValue(clearValue);

        const newValue = { };

        newValue[ props.name ] = renderOptions(value);

        formRef?.current?.setFieldsValue(newValue);

        return newValue;

    }

    const debounceFetcher = React.useMemo(() => {

        const loadOptions = (value) => {

            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value, numRow).then((newOptions) => {

                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(newOptions);
                setFetching(false);

                if(value === null || value === undefined) {
                    if(newOptions.length > 0) {
                        setOpenOptions(true);
                    } else {
                        setShowingAll(false);
                    }
                }

            }, (error) => {

                setOptions([]);
                setFetching(false);
                setOpenOptions(false);

            });

        };

        return debounce(loadOptions, debounceTimeout);

    }, [fetchOptions, debounceTimeout]);

    useEffect(() => {

        //console.log(props.value);

    }, [props.value]);

    useEffect(() => {

        //console.log(props.defaultValue);

    }, [props.defaultValue]);

    useEffect(() => {

        //console.log(props.inputValue);

    }, [props.inputValue]);

    useEffect(() => {

        if(selectedItem !== null && selectedItem !== undefined) {
            setOptions([selectedItem]);
        }

        return () => {

        };

    }, [selectedItem]);

    const handleSearch = (value) => {

        setOpenOptions(false);

        if(campoInformadoString(value)) {
            if(value.trim().length >= minChars) {
                debounceFetcher(value);
            } else {
                setOptions([]);
                setFetching(false);
            }
        } else {
            setOptions([]);
            setFetching(false);
        }

    }

    const showAll = () => {
        setShowingAll(true);
        debounceFetcher();
    }

    const children = options.filter(item => item !== null && item!=undefined).map(renderOptions);

    const className = ["iy2b-pro-field", "iy2b-auto-complete"];

    if(visible === false) className.push("not-visible");

    let botaoAdd = null;

    if(habilitaAdicao===true) {
        botaoAdd = <div className={"container-botao-add"}>
            <Divider
                style={{
                    margin: '8px 0',
                }}
            />
            {notFoundContent}
        </div>;
    }

    const propsOpenModal = (openOptions===true)?{
        open: true
    }:null;

    const eLabelSpace = (withoutLabelSpace===true) ? null : <div className={"ant-form-item-label"}>&nbsp;</div>;

    let currentFieldProps = {
        ...propsOpenModal,
        labelInValue: true,
        showSearch: true,
        allowClear: true,
        filterOption: false,
        notFoundContent: (fetching ? <Spin size="small" /> : null),
        disabled: disabled,
        onSearch (value) {
            return handleSearch(value)
        },
        onSelect(value, option) {
            setOpenOptions(false);
            return onSelect?onSelect(value, option):null
        },
        onMouseLeave() {
            setOpenOptions(false);
        },
        ref: refAC,
    };

    let eIconSearch =
        <Row className={"row-icon-search"}>
            <Col>
                {eLabelSpace}
                <Tooltip placement="right" title={"Clique para ver todas as opções de " + (props.label||"")}>
                    <Button type="default" icon={iconSearch} onClick={showAll}/>
                </Tooltip>
            </Col>
        </Row>;

    if(disabled === true) {
        eIconSearch = null;
        botaoAdd = null;
    }

    if(refShowingAll.current === true) {
        currentFieldProps = {
            ...propsOpenModal,
            labelInValue: true,
            showSearch: true,
            allowClear: true,
            filterOption: true,
            disabled: disabled,
            onSelect(value, option) {
                setOpenOptions(false);
                return onSelect?onSelect(value, option):null
            },
            onMouseLeave() {
                setOpenOptions(false);
            },
            ref: refAC,
        };
        eIconSearch = null;
    }
    if(botaoAdd !== null) {
        currentFieldProps.dropdownRender = (menu) => {
            return (
                <>
                    {menu}
                    {botaoAdd}
                </>
            );
        };
    }

    if(optionRender != null) {
        currentFieldProps.optionRender = optionRender;
    }

    className.push("pro-field");

    className.push("pro-field-" + myWidth);

    if(eIconSearch === null) {
        className.push("close-right-side");
    }

    return (
        <div
            className = {className.toClassName()}
        >
            <ProFormSelect
                label = {props.label}
                name = {props.name}
                tooltip = {props.tooltip}
                rules = {props.rules}
                width = {myWidth}
                placeholder={props.placeholder}
                fieldProps = {currentFieldProps}
                options={children}
            >
            </ProFormSelect>
            {eIconSearch}
        </div>
    );

}

export default AutoComplete;

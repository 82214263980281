//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useRef, useState} from "react";
import ReactModal from 'react-modal';
import {AutoComplete, Button, Input, notification, Tooltip, Row, Col} from "antd";
import {offSetFromDocument, sortByLabel} from "../../lib/iy2b-javascript";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {notificacaoErroRequest} from "../../lib/iy2b-react";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";
import {CUSTOM} from "../../assets/iconografia";
import {postJSON} from "../../lib/RequestAPI";
import {notificacao} from "../../lib/Notificacao";

const iconTeacher = CUSTOM.CHALKBOARD_TEACHER_ICON_WHITE;
const iconFechar = CUSTOM.TIMES_ICON_BLACK;

const ChecklistProcessoDropdown = (props) => {

    const { cdTransacao = 0 } = props;

    const [options, setOptions] = useState([]);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const [aberto, setAberto] = useState(false);
    const [dropDownOffSet, setDropDownOffSet] = useState({top:0, left:0, width: 0});
    const [acWidth, setAcWidth] = useState(400);

    const breaks = useBreakpoint();

    useEffect(() => {

        setAcWidth(document.getElementById('btnEmpresa').getBoundingClientRect().width + 80 - 60);

        const offset = offSetFromDocument(document.getElementById('checklistProcessoDropdown'));

        offset.width = document.getElementById('btnTeacher').getBoundingClientRect().width;

        setDropDownOffSet (offset);

        if(props.api) {
            props.api.current = {
                exibeDropdown: () => {
                    setAberto(true);
                    setTooltipVisible(false);
                },
                escondeDropdown: () => {
                    setAberto(false);
                    setTooltipVisible(false);
                }
            };
        }

        return () => {

        };

    }, []);

    useEffect(() => {

        if(aberto === true) {
            if(options.isEmpty() === true) {
                pesquisarProcessos();
            }
        }

    }, [aberto]);

    const pesquisarProcessos = async () => {

        try {

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sgr",
                projeto: "mchklstprocesso"
            }, {
                "acaoAExecutar": "pesquisaDropDown"
            }, WORKSPACE_LOADING_INDICATOR_AREA);

            const dpGrupos = [];
            const dpCheckL = [];

            resultado.mensagens.forEach(msg => {

                if(msg.id === "listaChecklistProcesso") {

                    const dp = msg.lista.map(item => {
                        return {
                            value: item.cdChecklist,
                            label: item.noChecklist,
                            data: {...item}
                        }
                    });

                    dpCheckL.addAll(dp);

                } else if(msg.id === "listaGrupoChecklistProcesso") {

                    const dp = msg.lista.map(item => {
                        return {
                            value: item.cdGrupoChecklist,
                            label: item.noGrupoChecklist,
                            data: {...item}
                        }
                    });

                    dpGrupos.addAll(dp);

                } else {

                    console.log(msg);

                }

            });

            const dp = dpGrupos.map(item => {
                return {
                    key: item.value,
                    label: item.label,
                    options: dpCheckL.filter(checkl => checkl.data.cdGrupoChecklist === item.value).map(checkl => {
                        return {
                            key: checkl.data.cdGrupoChecklist + "," + checkl.value,
                            label: checkl.label,
                            value: checkl.label.toLowerCase(),
                            data: checkl.data
                        }
                    })
                }
            });

            console.log(dp);

            setOptions(dp);

            if(dp.isEmpty() === true) {
                notificacao.aviso({message:"Atenção", description:"Funcionalidade em desenvolvimento"});
                setAberto(false);
                setTooltipVisible(false);
            }

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const onAutocompleteSelect = (value, option) => {
        if(value === null) return;
        setAberto(false);
        props.onVerCheckList(option.data);
    };

    const onIniciarApresentacao = () => {
        setAberto(false);
        props.onIniciarApresentacao();
    }

    const currentDropDownOffSet = { ...dropDownOffSet };

    currentDropDownOffSet.left = (breaks.xs===true)?10:dropDownOffSet.left;

    const classNameContainerBtnTeacher = ["containerBtnTeacher"];

    if(aberto === true) {
        classNameContainerBtnTeacher.push("aberto");
    }

    return (
        <>
            <Tooltip placement="bottom" title={"O que você deseja fazer ?"} open={tooltipVisible}>
                <div id={"checklistProcessoDropdown"} onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                    <div className={classNameContainerBtnTeacher.toClassName()}>
                        <div id={"btnTeacher"}>
                            <a  onClick={() => {
                                setTooltipVisible(false);
                                setAberto(!aberto);
                            }}>
                                <div className={"containerIcon"}>{iconTeacher}</div>
                            </a>
                        </div>
                    </div>
                </div>
            </Tooltip>
            <ReactModal
                isOpen={aberto}
                className={"modalContent"}
                overlayClassName={"modalOverlay"}
                bodyOpenClassName={"checklistProcessoModal"}
                closeTimeoutMS={500}
                shouldFocusAfterRender={true}
                onRequestClose={() => setAberto(false)}
                style={{
                    content: {
                        left: (currentDropDownOffSet.left + 1) + 'px',
                    }
                }}
            >
                <div className={"containerDropdownCkL"}
                     style={{width: acWidth + 80}}
                >
                        <Row>
                            <Col span={24}>
                                <Button type={"default"} className={"btnFechar ant-btn-lg ant-btn-icon-only"}
                                        onClick={() => setAberto(false)}
                                        style={{left: acWidth - 1}}
                                >
                                    <div className={"containerIconFechar"}>
                                    <span role="img" aria-label="search" className="anticon anticon-times">
                                        {iconFechar}
                                    </span>
                                    </div>
                                </Button>
                                <AutoComplete id={"acChecklistProcesso"}
                                              autoFocus
                                              popupClassName={"dropdownSearchChecklistProcesso"}
                                              style={{ width: acWidth }}
                                              options={options}
                                              onSelect={onAutocompleteSelect}
                                              filterOption={(input, option) => {
                                                  if(option.hasOwnProperty("options")) {
                                                      return false;
                                                  } else {
                                                      return option.value.indexOf(input.toLowerCase()) >= 0;
                                                  }
                                              }}
                                >
                                    <Input.Search size="large" placeholder="Digite o que você deseja fazer" />
                                </AutoComplete>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 15, backgroundColor: "white"}}>
                            <Col span={24}>
                                <Button type={"link"} block
                                        onClick={onIniciarApresentacao}
                                >
                                    Exibir apresentação do Portal
                                </Button>
                            </Col>
                        </Row>
                </div>
            </ReactModal>
        </>
    );
}

export default ChecklistProcessoDropdown;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-card/dist/card.css';

import '../../framework/assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {notificacaoErroValidForm, useQueryString, useStateRef, useWindowSize} from "../../framework/lib/iy2b-react";
import Cookies from 'universal-cookie';

import {StepsForm} from '@ant-design/pro-components';

import {Button, Col, Drawer, Image, Layout, Row, Space} from 'antd';

import {postWS} from "../../framework/lib/RequestAPI";
import {useSetupConfig} from "../../framework/lib/ConfigManager";
import Store from "../../store";
import {useHistory} from "react-router-dom";
import SinglePageLoadingIndicator from "../../framework/componentes/SinglePageLoadingIndicator";

import StepPlanos from "./StepPlanos";
import StepCertDigital from "./StepCertDig";
import UploadLoadingIndicator from "../../framework/componentes/UploadLoadingIndicator";
import {campoInformado, topFunction} from "../../framework/lib/iy2b-javascript";
import StepCadEmpresa from "./StepCadEmpresa";
import StepCadUsuario from "./StepCadUsuario";
import StepResumo from "./StepResumo";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import IY2BProFormWizard from "../../framework/componentes/IY2BProFormWizard";
import {pesquisarPlanoContrVenda} from "../../framework/lib/Servicos";

import logo from "../../framework/assets/images/logo-contratacao.png";
import {notificacao} from "../../framework/lib/Notificacao";
import StepTipoPessoa from "./StepTipoPessoa";
import IY2BHiddenRow from "../../framework/componentes/IY2BHiddenRow";

const { StepForm } = StepsForm;

const { Header, Footer, Content } = Layout;

const cookies = new Cookies( process.env.REACT_APP_NAME + "/contratar");

const Assinar = props => {

    useSetupConfig({
        appConfig: props.appConfig,
        store: Store
    });

    const history = useHistory();
    const windowSize = useWindowSize();
    const qs = useQueryString();

    const { xs } = useBreakpoint();

    const layoutVertical = (xs===true) ;

    const formRef = useRef();

    const [dsPlanos, setDsPlanos] = useState([]);
    const [planoSelecionado, setPlanoSelecionado] = useState(null);

    const [aceiteTermos, setAceiteTermos] = useState(false);
    const [infoCertificado, setInfoCertificado] = useState(null);

    const [cadEmpData, setCadEmpData] = useState(null);
    const [cadUsrData, setCadUsrData] = useState(null);
    const [tipoPessoaData, setTipoPessoaData] = useState(null);

    const [exibeConclusao, setExibeConclusao] = useState(false);
    const [msgConclusao, setMsgConclusao] = useState(null);

    const [tokenEmail, setTokenEmail] = useState(null);


    useEffect(() => {

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1);

        inicializa();

        return () => {

        };

    }, []);

    async function inicializa () {

        const planos = await pesquisaPlanos();

        setDsPlanos(planos);

        const cookieCertif = cookies.get("certificado");

        if(campoInformado(cookieCertif)) {

            if(campoInformado(cookieCertif.certificado)) {

                setInfoCertificado (cookieCertif.certificado);

            }

        }

    }

    const updateCooke = (etapa, value) => {

        const cookieEtapa = cookies.get(etapa) || { };

        const agora = new Date();

        const expires = agora.adiciona(4, 'd');

        const newValue = {
            ...cookieEtapa,
            expires: expires,
            ...value
        };

        cookies.set(etapa, newValue);

    }

    const pesquisaPlanos = async () => {

        const planos = await pesquisarPlanoContrVenda();

        const cookiePlano = cookies.get("plano");

        planos.forEach(plano => {

            if(cookiePlano?.plano.id===plano.id) {
                plano.selecionado = true;
                setPlanoSelecionado(plano);
            }

        });

        return planos;
    }

    const onSelecionaPlano = (plano) => {

        dsPlanos.filter(item => item.selecionado === true).map(item => {
            item.selecionado = false;
            return item;
        })

        plano.selecionado = true;

        setDsPlanos(dsPlanos.clone());

        setPlanoSelecionado(plano);

        const agora = new Date();

        const expires = agora.adiciona(4, 'd');

        cookies.set("plano", {
            plano: plano,
            expires: expires
        });

    }

    const onAceiteTermos = (value) => {

        setAceiteTermos(value);

    }

    const onInfoCertificado = (value) => {

        setInfoCertificado(value);

        updateCooke("certificado", {
            certificado: value
        });

        setTipoPessoaData(null);

        if(value === null || value === undefined) {
            cookies.remove("cadEmp");
            cookies.remove("cadUsr");
        }

    }

    const onFinishStepFailed = errorInfo => {
        notificacaoErroValidForm(errorInfo);
    };

    const onCurrentStepChange = (position, currentKey) => {

        if(currentKey==="stepCadEmp") {
            formRefEmpresaApi.current?.onPageShow();
        } else if(currentKey==="stepCadUsr") {
            formRefUsuarioApi.current?.onPageShow();
        }

    };

    const onFinishWizard = async () => {

        if(await formRefResumoApi.current?.onFinish() === false) return false;

        topFunction();

        const payload = {
            "origin": window.location.origin,
            infos: {
                plano: {
                    cdPessoaEmp: props.appConfig.cdPessoaEmpWsToken,
                    cdPlanoContrVenda: planoSelecionado.id
                },
                usuario: {
                    cdPessoaUsrJaExiste: cadUsrData.cdPessoaUsrJaExiste,
                    noEMail: cadUsrData.noEMail,
                    noUsuario: cadUsrData.noUsuario,
                    nrCPF: cadUsrData.nrCPF,
                    nrTelCelular: cadUsrData.nrTelCelular,
                    endereco: {
                        nrSeqEnd: cadUsrData.nrSeqEnd,
                        nrCEP: cadUsrData.nrCEP,
                        txNumero: cadUsrData.txNumero,
                        txComplEnd: cadUsrData.txComplEnd,
                        noLogradouro: cadUsrData.noLogradouro,
                        noBairro: cadUsrData.noBairro,
                    },
                    provedorId: {
                        noEmail: cadUsrData.noEmailProvId,
                        chUId: cadUsrData.chUId,
                        cdProvedorIdentidade: cadUsrData.cdProvedorIdentidade
                    }
                }
            }
        }

        if(campoInformado(infoCertificado)===true) {
            payload.infos.certificado = {
                nrSeqCD: infoCertificado.nrSeqCD,
                flgJuridico: infoCertificado.flgJuridico,
                noPessoa: infoCertificado.noPessoa,
                nrCGCCIC: infoCertificado.nrCGCCIC,
                cdPessoaEmpModeloOrig: props.appConfig.cdPessoaEmpModeloOrig
            };
            if(infoCertificado.flgJuridico === "J") {
                payload.infos.empresa = {
                    noFantasia: cadEmpData.noFantasia,
                    flgOptaSimples: cadEmpData.flgOptaSimples,
                    flgMEI: cadEmpData.flgMEI,
                    endereco: {
                        nrCEP: cadEmpData.nrCEP,
                        txNumero: cadEmpData.txNumero,
                        txComplEnd: cadEmpData.txComplEnd,
                        nrInsEst: cadEmpData.nrInsEst,
                        nrInsMunicip: cadEmpData.nrInsMunicip,
                        noLogradouro: cadEmpData.noLogradouro,
                        noBairro: cadEmpData.noBairro,
                    }
                };
            } else {
                payload.infos.empresa = {
                    noFantasia: cadUsrData.noUsuario,
                    endereco: {
                        nrCEP: cadUsrData.nrCEP,
                        txNumero: cadUsrData.txNumero,
                        txComplEnd: cadUsrData.txComplEnd,
                        noLogradouro: cadUsrData.noLogradouro,
                        noBairro: cadUsrData.noBairro,
                    }
                };
            }
        } else if(campoInformado(tipoPessoaData)===true) {

            payload.infos.semCertificado = "S";

            if(tipoPessoaData.flgTipoPessoa === "J") {
                payload.infos.empresa = {
                    cdPessoaEmpModeloOrig: props.appConfig.cdPessoaEmpModeloOrig,
                    flgJuridico: "J",
                    nrCGCCIC: cadEmpData.nrCNPJ,
                    noPessoa: cadEmpData.noRazaoSocial,
                    noFantasia: cadEmpData.noFantasia,
                    flgOptaSimples: cadEmpData.flgOptaSimples,
                    flgMEI: cadEmpData.flgMEI,
                    endereco: {
                        nrCEP: cadEmpData.nrCEP,
                        txNumero: cadEmpData.txNumero,
                        txComplEnd: cadEmpData.txComplEnd,
                        nrInsEst: cadEmpData.nrInsEst,
                        nrInsMunicip: cadEmpData.nrInsMunicip,
                        noLogradouro: cadEmpData.noLogradouro,
                        noBairro: cadEmpData.noBairro,
                    }
                };
            } else {
                payload.infos.empresa = {
                    cdPessoaEmpModeloOrig: props.appConfig.cdPessoaEmpModeloOrig,
                    flgJuridico: "F",
                    nrCGCCIC: cadUsrData.nrCPF,
                    noPessoa: cadUsrData.noUsuario,
                    noFantasia: cadUsrData.noUsuario,
                    endereco: {
                        nrCEP: cadUsrData.nrCEP,
                        txNumero: cadUsrData.txNumero,
                        txComplEnd: cadUsrData.txComplEnd,
                        noLogradouro: cadUsrData.noLogradouro,
                        noBairro: cadUsrData.noBairro,
                    }
                };
            }

        }

        try {

            const resposta = await postWS({
                sistema: "sgr",
                projeto: "mwizcontratacaobuild",
                acaoWebAExecutar: "processarnegocio",
                acaoAExecutar: "contratar"
            }, payload);

            resposta.mensagens.forEach(msg => {

                if(msg.id === "ok") {

                    setExibeConclusao(true);
                    setMsgConclusao(msg.mensagem);

                } else {

                    console.log(msg);

                }

            });

            // mantem na pagina final de envio
            return false;

        } catch (e) {

            console.log(e);

            if(e.hasOwnProperty("data")) {

                if(e.data.id==="ErroDeAcao") {

                    try {

                        const erroDeAcao = JSON.parse(e.data.descricao);

                        notificacao.erro({errorId: "finalizarAssinatura", message: "Ooops!", description:erroDeAcao.descricao, duration: 6})

                    } catch (pe) {

                        notificacao.erro({errorId: "finalizarAssinatura", message: "Ooops!", description:e.data.descricao, duration: 6})

//                        notificacao.erro({message: "Ooops!", description:"Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !"})

                    }

                } else {

                    notificacao.erro({errorId: "finalizarAssinatura", message: "Ooops!", description:"Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !"})

                }

            } else {

                notificacao.erro({errorId: "finalizarAssinatura", message: "Ooops!", description:"Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !"})

            }

            // com erro, mantem na pagina final de envio
            return false;

        }

        // conclui com sucesso e volta para pagina inicial
        return true;
    }

    const onCloseSucesso = () => {

        cookies.remove("plano");
        cookies.remove("certificado");
        cookies.remove("cadEmp");
        cookies.remove("cadUsr");

        setExibeConclusao(false);

        history.push('/login?email=' + cadUsrData?.noEMail.trim());

    }

    const recebeTokenEmail = (token) => {
        setTokenEmail ( token );
    }

    const tipoWiz = (campoInformado(infoCertificado)===true) ?
                                (infoCertificado.flgJuridico === "F") ? "CPF" : "CNPJ"
                    : (campoInformado(tipoPessoaData)===true) ? ((tipoPessoaData.flgTipoPessoa === "F") ? "CPF" : "CNPJ") : "UNDEF"
    ;

    // console.log(tipoWiz, (tipoWiz==="CPF"));


    const stepFormPlano =
        <StepForm
            key={"stepPlano"}
            name = "stepPlano"
            title = "Plano acesso"
            stepProps = {{
                description: "Escolha o seu plano de acesso"
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = { async ( ) => {
                if(planoSelecionado === null) {
                    notificacao.erro({
                        message: 'Escolha um plano',
                        description:
                            'Você deve escolher um plano de assinatura para prosseguir !',
                    });
                    return false ;
                } else {
                    return true ;
                }
            }}
        >
            <StepPlanos
                cookies={cookies}
                dataSource={dsPlanos}
                onSelecionaPlano={onSelecionaPlano}
            />
        </StepForm>
    ;

    const stepFormCertDig =
        <StepForm
            key={"stepCertDig"}
            name = "stepCertDig"
            title = "Certificado digital"
            stepProps = {{
                description: "Enviar o seu certificado digital"
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = {async ( ) => {
                if(aceiteTermos === false) {
                    notificacao.erro({
                        message: 'Aceitar os termos',
                        description:
                            'Leia e aceite os termos para prosseguir !',
                    });
                    return false;
                }
                return true;
            }}
        >
            <StepCertDigital appConfig = { props.appConfig }
                             formRef = { formRef }
                             onAceiteTermos = { onAceiteTermos }
                             onInfoCertificado = { onInfoCertificado }
                             cookies={cookies}
                             opcional
            />
        </StepForm>
    ;

    const formRefTipoPessoa = useRef() ;

    const stepFormTipoPessoa =
        <StepForm
            formRef = { formRefTipoPessoa }
            key={"stepTipoPessoa"}
            name = "stepTipoPessoa"
            title = "Escolha de quem está contratando"
            stepProps = {{
                description: "Para sua empresa ou para você ?",
            }}
            initialValues={{
                flgTipoPessoa: "J",
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = {async ( ) => {
                const all = formRefTipoPessoa.current?.getFieldsValue();
                setTipoPessoaData(all);
                updateCooke("tipoPessoa", all);
                return true;
            }}
        >
            <StepTipoPessoa
                formRef = { formRefTipoPessoa }
                cookies = { cookies }
            />
        </StepForm>
    ;


    const formRefEmpresa = useRef() ;
    const formRefEmpresaApi = useRef();

    const stepFormEmpresa =
        <StepForm
            formRef = { formRefEmpresa }
            key={"stepCadEmp"}
            name = "stepCadEmp"
            title = "Dados empresa"
            stepProps = {{
                description: "Preenchimento de informações da empresa",
                disabled: (tipoWiz==="CPF")
            }}
            initialValues={{
                flgOptaSimples: "N",
                flgMEI: "N"
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = {async ( ) => {
                if(await formRefEmpresaApi.current?.onFinish() === false) return false;
                const all = formRefEmpresa.current?.getFieldsValue();
                setCadEmpData(all);
                updateCooke("cadEmp", all);
                return true;
            }}
            onValuesChange = {async (changed, all) => {
                // updateCooke("cadEmp", all);
                formRefEmpresaApi.current?.onValuesChange(changed, all);
            }}
        >
            <StepCadEmpresa
                api = { formRefEmpresaApi }
                formRef = { formRefEmpresa }
                certificado = { infoCertificado }
                cookies = { cookies }
            />
        </StepForm>
    ;


    const formRefUsuario = useRef() ;
    const formRefUsuarioApi = useRef();

    const stepFormUsuario =
        <StepForm
            formRef = { formRefUsuario }
            key={"stepCadUsr"}
            name = "stepCadUsr"
            title = "Dados usuário"
            stepProps = {{
                description: "Preenchimento de informações do usuário MASTER"
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = {async ( ) => {
                const all = formRefUsuario.current?.getFieldsValue();
                console.log("stepCadUsr.StepForm.onFinish.all", all);
                if(await formRefUsuarioApi.current?.onFinish() === false) return false;
                setCadUsrData(all);
                updateCooke("cadUsr", all);
                return true;
            }}
            onValuesChange = {async (changed, all) => {
                //updateCooke("cadUsr", all);
                formRefUsuarioApi.current?.onValuesChange(changed, all);
            }}
        >
            <StepCadUsuario
                api = { formRefUsuarioApi }
                formRef = { formRefUsuario }
                certificado = { infoCertificado }
                cookies = { cookies }
                onTokenEmail = { recebeTokenEmail }
            />
        </StepForm>
    ;


    const formRefResumo = useRef() ;
    const formRefResumoApi = useRef();

    const stepFormResumo =
        <StepForm
            formRef = { formRefResumo }
            key={"stepResumo"}
            name = "stepResumo"
            title = "Resumo"
            stepProps = {{
                description: "Conferência e confirmação"
            }}
            onFinishFailed={onFinishStepFailed}
        >
            <StepResumo
                api = { formRefResumoApi }
                formRef = { formRefResumo }
                cookies = { cookies }
                plano = { planoSelecionado }
                certificado = { infoCertificado }
                cadEmp = { cadEmpData }
                cadUsr = { cadUsrData }
                tokenEmail = { tokenEmail }
            />
        </StepForm>
    ;

    const aWizSteps = [stepFormPlano.key, stepFormCertDig.key];

    let wizSteps = null;

    if(tipoWiz==="CPF")  {
        if(campoInformado(tipoPessoaData)===true) {
            wizSteps = <>
                {stepFormPlano}
                {stepFormCertDig}
                {stepFormTipoPessoa}
                {stepFormUsuario}
                {stepFormResumo}
            </>;
            aWizSteps.push(stepFormTipoPessoa.key);
        } else {
            wizSteps = <>
                {stepFormPlano}
                {stepFormCertDig}
                {stepFormUsuario}
                {stepFormResumo}
            </>;
        }
        aWizSteps.push(stepFormUsuario.key);
    } else if(tipoWiz==="CNPJ") {
        if(campoInformado(tipoPessoaData)===true) {
            wizSteps = <>
                {stepFormPlano}
                {stepFormCertDig}
                {stepFormTipoPessoa}
                {stepFormEmpresa}
                {stepFormUsuario}
                {stepFormResumo}
            </>;
            aWizSteps.push(stepFormTipoPessoa.key);
        } else {
            wizSteps = <>
                {stepFormPlano}
                {stepFormCertDig}
                {stepFormEmpresa}
                {stepFormUsuario}
                {stepFormResumo}
            </>;
        }
        aWizSteps.push(stepFormEmpresa.key);
        aWizSteps.push(stepFormUsuario.key);
    } else if(tipoWiz==="UNDEF") {
        wizSteps = <>
            {stepFormPlano}
            {stepFormCertDig}
            {stepFormTipoPessoa}
            {stepFormResumo}
        </>;
        aWizSteps.push(stepFormTipoPessoa.key);
    }

    aWizSteps.push(stepFormResumo.key);

    console.log("formRef", formRef)

    //182x52

    return (
        <div id={"wizardContratar"}>
            <Layout>
                <Header>
                    <Row align={"middle"} justify={"space-between"} gutter={0}>
                        <Col flex={"50px"} className={"display-contents"}>
                            <Image
                                className={"logo"}
                                preview={false}
                                src={logo}
                            />
                        </Col>
                        <Col flex={"auto"} className={"align-center"}>
                            <label>
                                Concluir contratação da assinatura
                            </label>
                        </Col>
                    </Row>
                </Header>
                <Content>
                    <SinglePageLoadingIndicator />
                    <UploadLoadingIndicator area={"uploadCertificado"}/>
                    <IY2BProFormWizard
                        formRef = { formRef }
                        onFinishWizard = { onFinishWizard }
                        onCurrentStepChange = { onCurrentStepChange }
                        stepsSize = "small"
                        steps={wizSteps}
                        passosValidos={aWizSteps}
                    >
                    </IY2BProFormWizard>
                </Content>
            </Layout>
            <Drawer
                title="Contratação concluída"
                placement="right"
                onClose={onCloseSucesso}
                open={exibeConclusao}
                key={"drawerConclusao"}
                width={378}
                destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={onCloseSucesso} type="primary">
                            Ok !
                        </Button>
                    </Space>
                }
            >
                <div>{msgConclusao}</div>
            </Drawer>
        </div>
    );

};

const mapStateToProps = function( {appConfig, userSession} ) {

    return {
        appConfig: appConfig,
        userSession: userSession
    };

};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Assinar);

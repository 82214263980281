//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../framework/assets/css/tema.scss';
import './style.scss';

import {connect} from "react-redux";
import {notificacaoErroRequest, preventInjection, useStateRef} from "../../framework/lib/iy2b-react";
import React, {useEffect, useRef, useState} from "react";
import Promessas from "../../framework/lib/Promessas";
import {postJSON} from "../../framework/lib/RequestAPI";
import {campoInformado, sortByLabel} from "../../framework/lib/iy2b-javascript";
import ResultadoComFiltro from "../../framework/componentes/CadastroBasico/resultadoComFiltro";
import DataProvider from "../../framework/lib/DataProvider";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../framework/pages/Workspace/workspace";
import FiltroAtendimento from "./filtroAtendimento";
import EditarAtendimento from "./editarAtendimento";
import {cachedPostJSON} from "../../framework/lib/CacheRequestAPI";
import {notificacao} from "../../framework/lib/Notificacao";

const ID_LISTA_PESQUISA = "resposta";
const PAGE_SIZE_RESULTADO = 20;

const Atendimento = ( props ) => {

    const { userSession } = props;

    const {loadingArea, cdTransacao, paramWeb} = props;

    const boxName = "trn" + cdTransacao;

    const [utlFiltro, setUltFiltro, refUltFiltro] = useStateRef(null);
    const [exibeNovoRegistro, setExibeNovoRegistro] = useState(false);
    const [registrosAEditar, setRegistrosAEditar] = useState([]);
    const [dataProvider, setDataProvider] = useState({ });
    const [ffFiltro, setFFFiltro] = useState(null);

    const resultadoRefApi = useRef();

    useEffect(() => {

        inicializaProjeto ();

        return () => {

        };

    }, []);

    const inicializaProjeto = async () => {

        try {

            const dataProvider = {
                cdPessoaEmpAtual: userSession.cdPessoaEmpAtual,
                dpClienteUsuario: [],
                dpJobPadraoClienteUsuario: [],
                dpJobPadraoEmpresa: [],
                dpJobPadraoParametro: [],
                webPautaDocto: "http://desenv.webmakers.inf.br:8080/smkworkflowdocto/desenv",
            };

            const promessas = new Promessas();

            promessas.add(async (resolve, reject) => {
                try {

                    const resultado = await cachedPostJSON({
                        acaoWebAExecutar: "pesquisar",
                        sistema: "crm",
                        projeto: "matendimento",
                        payload: {
                            "acaoAExecutar": "inicializaProjeto",
                        }
                    }, loadingArea);

                    resultado.mensagens.forEach(msg => {

                        if(msg.id === "listaCondicaoPgto") {

                            dataProvider.dpFormaRecebimento = msg.lista.map(item => {
                                return {
                                    value: item.cdFormaReceb,
                                    label: item.noFormaReceb,
                                    data: {...item}
                                }
                            }).sort(sortByLabel);

                        } else if (msg.id === "dpTipoAtendimento") {

                            dataProvider.dpTipoAtendimento = msg.lista.map(item => {
                                return {
                                    value: item.cdTipoAtendimento,
                                    label: item.noTipoAtendimento,
                                    data: {...item}
                                }
                            }).sort(sortByLabel);

                        } else if (msg.id === "dpStatusAtendimento") {

                            dataProvider.dpStatusAtendimento = msg.lista.map(item => {
                                return {
                                    value: item.cdStatusAtendimento,
                                    label: item.noStatusAtendimento,
                                    data: {...item}
                                }
                            }).sort(sortByLabel);

                        } else if (msg.id === "dpMotivoFimAtendimento") {

                            dataProvider.dpMotivoFimAtendimento = msg.lista.map(item => {
                                return {
                                    value: item.cdMotivoFimAtendimento,
                                    label: item.noMotivoFimAtendimento,
                                    data: {...item}
                                }
                            }).sort(sortByLabel);

                        } else if (msg.id === "dpMaterial") {

                            dataProvider.dpMaterial = msg.lista.map(item => {
                                return {
                                    value: item.cdMaterial,
                                    label: item.noMaterial,
                                    data: {...item}
                                }
                            }).sort(sortByLabel);

                        } else if (msg.id === "dpJobPadraoParametro") {

                            dataProvider.dpJobPadraoParametro = msg.lista.map(item => {
                                return {
                                    value: item.nrJob,
                                    label: item.noJob,
                                    data: {...item}
                                }
                            }).sort(sortByLabel);

                        } else if (msg.id === "dpUsuarioDestWF") {

                            dataProvider.dpUsuarioDestWF = msg.lista.map(item => {
                                return {
                                    value: item.cdPessoaUsr.cdPessoa,
                                    label: item.cdPessoaUsr.noPessoa,
                                    data: {...item}
                                }
                            }).sort(sortByLabel);

                        } else if (msg.id === "dpClienteUsuario") {

                            dataProvider.dpClienteUsuario = msg.lista.map(item => {
                                return {
                                    value: item.cdPessoaCli.cdPessoa,
                                    label: item.cdPessoaCli.noPessoa,
                                    data: {...item}
                                }
                            }).sort(sortByLabel);

                        } else if (msg.id === "dpJobPadraoClienteUsuario") {

                            dataProvider.dpJobPadraoClienteUsuario = msg.lista.map(item => {
                                return {
                                    value: item.nrJob,
                                    label: item.noJob,
                                    data: {...item}
                                }
                            }).sort(sortByLabel);

                        } else if (msg.id === "dpJobPadraoEmpresa") {

                            dataProvider.dpJobPadraoEmpresa = msg.lista.map(item => {
                                return {
                                    value: item.nrJob,
                                    label: item.noJob,
                                    data: {...item}
                                }
                            }).sort(sortByLabel);

                        } else if (msg.id === "dpJobPadraoParametro") {

                            dataProvider.dpJobPadraoParametro = msg.lista.map(item => {
                                return {
                                    value: item.nrJob,
                                    label: item.noJob,
                                    data: {...item}
                                }
                            }).sort(sortByLabel);

                        } else if(msg.id === "webPautaDocto") {

                            if (window.AMBIENTE_DESENV()) {
                                dataProvider.webPautaDocto = "http://desenv.webmakers.inf.br:8080/smkworkflowdocto/desenv" ;
                            } else {
                                dataProvider.webPautaDocto = msg.mensagem ;
                            }

                        } else {

                            console.log(msg);

                        }

                    });

                    resolve(true);

                } catch (e) {

                    console.log(e);

                    notificacaoErroRequest(e, cdTransacao);

                    reject (e);

                }
            });

            promessas.add(async (resolve, reject) => {
                try {

                    const resultado = await cachedPostJSON({
                        acaoWebAExecutar: "pesquisar",
                        sistema: "crm",
                        projeto: "mfinalizaatendimento",
                        payload: {
                            "acaoAExecutar": "inicializaProjeto",
                        }
                    }, loadingArea);

                    resultado.mensagens.forEach(msg => {

                        if(msg.id === "dpMotivoFimAtendimento") {

                            dataProvider.dpMotivoFimAtendimento = msg.lista.map(item => {
                                return {
                                    value: item.cdMotivoFimAtendimento,
                                    label: item.noMotivoFimAtendimento,
                                    data: {...item}
                                }
                            }).sort(sortByLabel);

                        } else {

                            console.log(msg);

                        }

                    });

                    resolve(true);

                } catch (e) {

                    console.log(e);

                    notificacaoErroRequest(e, cdTransacao);

                    reject (e);

                }
            });

            await promessas.run();

            setDataProvider(dataProvider);

            console.log("inicializaProjeto", dataProvider);

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

        return true;

    };

    const pesquisar = async () => {

        const filtro = {
            ...refUltFiltro.current,
        };

        const infoPag = resultadoRefApi.current?.infoPag();

        const payload = {
            "acaoAExecutar": "pesquisa",
            filtro: filtro,
            infoPag: infoPag,
            "validaUsrCli": "N",
            // "orderBySql": "NOMATERIAL"
        }

        try {

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "crm",
                projeto: "matendimento"
            }, payload, loadingArea);

            let dp = new DataProvider({pageSize: PAGE_SIZE_RESULTADO});

            resultado.mensagens.forEach(msg => {

                if(msg.id === ID_LISTA_PESQUISA) {

                    const listaDP = msg.lista.map(item => {
                        item.key = item.cdAtendimento;
                        return item;
                    });

                    dp = new DataProvider({
                        data: listaDP,
                        total: msg.totRegs,
                        page: infoPag.nroPagina,
                        pageSize: infoPag.tamPagina
                    });

                }

            });

            return dp;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return new DataProvider();

        }

    }

    const novoRegistro = () => {

        resultadoRefApi.current.updateTableHeight();

        setExibeNovoRegistro (true);

        setRegistrosAEditar ([]);

    }

    const editarRegistro = (marcados) => {

        setExibeNovoRegistro (true);

        setRegistrosAEditar (marcados);

    }

    const salvar = async (registro) => {

        try {

            const acaoWebAExecutar = campoInformado(registro.atendimento.cdAtendimento)?"alterar":"incluir";

            const payload = {
                acaoAExecutar: "acaoCRUD",
                registro: registro,
            };

            const resultado = await postJSON({
                acaoWebAExecutar: acaoWebAExecutar,
                sistema: "crm",
                projeto: "matendimento"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            let resposta = false;

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    const modelo = msg.modeloPadrao;

                    modelo.key = modelo.atendimento.cdAtendimento;

                    const dsItem = resultadoRefApi.current?.findKey( modelo.key );

                    if(dsItem === null) {

                        modelo.atendimento.key = modelo.atendimento.cdAtendimento;

                        const tipoAtend = dataProvider.dpTipoAtendimento.filter(item => item.value === modelo.atendimento.cdTipoAtendimento);

                        modelo.atendimento.noTipoAtendimento = tipoAtend[0].data.noTipoAtendimento;

                        resultadoRefApi.current?.addRecord( modelo.atendimento );

                    } else {

                        dsItem.noAtendimento = modelo.atendimento.noAtendimento;
                        dsItem.noContato = modelo.atendimento.noContato;
                        dsItem.noStatusAtendimento = modelo.atendimento.noStatusAtendimento;

                        const tipoAtend = dataProvider.dpTipoAtendimento.filter(item => item.value === modelo.atendimento.cdTipoAtendimento);

                        dsItem.noTipoAtendimento = tipoAtend[0].data.noTipoAtendimento;

                    }

                    resposta = {
                        cdAtendimento: modelo.atendimento.cdAtendimento
                    }

                }

            });

            notificacao.sucesso({transacao:cdTransacao,message:"Sucesso!", description:"Chamado salvo com sucesso!"})

            return resposta;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const tblCols = [
        {noColuna:'Atendto', field:'cdAtendimento', width:110, align:'text-right', colSpan:1},
        {noColuna:'Assunto', field:'noAtendimento', width:200, colSpan:1},
        {noColuna:'Tipo', field:'noTipoAtendimento', width:150, colSpan:1},
        {noColuna:'Contato', field:'noContato', width:150, colSpan:1},
        {noColuna:'Status', field:'noStatusAtendimento', width:150, colSpan:1},
    ];

    return (
        <div id={boxName}>
            <ResultadoComFiltro
                projeto={{
                    sistema: "crm",
                    projeto: "matendimento"
                }}
                formFiltro={
                    <FiltroAtendimento
                        setUltFiltro = {setUltFiltro}
                        dataProvider={dataProvider}
                        onNovoRegistro={() => {
                            novoRegistro()
                        }}
                        setFormFiltro={(values) => {
                            setFFFiltro(values);
                        }}
                        getFormFiltro={() =>{
                            return ffFiltro;
                        }}
                    />
                }
                showFiltroOnFirstInput
                cols={tblCols}
                api={resultadoRefApi}
                boxName={boxName}
                utlFiltro={utlFiltro}
                loadingArea={loadingArea}
                ID_LISTA_PESQUISA={ID_LISTA_PESQUISA}
                PAGE_SIZE_RESULTADO={PAGE_SIZE_RESULTADO}
                planoEhGratuito={(userSession.planoContratado?.flgGratuito === "S")}
                pesquisar={pesquisar}
                editarRegistro={editarRegistro}
                novoRegistro={novoRegistro}
            />
            <EditarAtendimento cdTransacao={cdTransacao}
                exibePopUp={ exibeNovoRegistro }
                onRequestClose={() => {
                    resultadoRefApi.current?.unckeckAll();
                    setExibeNovoRegistro(false) ;
                }}
                dataProvider={dataProvider}
                registros={registrosAEditar}
                salvar={salvar}
                userSession={userSession}
                resultadoRefApi={resultadoRefApi}
            />
        </div>
    );

}

const mapStateToProps = function( { userSession } ) {

    return {
        userSession: userSession,
    };

};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Atendimento);

export async function pesquisarAcUsuario_NAOUSAR (params) {

    const payload = {
        "acaoAExecutar": "pesquisaUsuario",
        filtro: preventInjection({
            txtNoPessoa: params
        }),
    }

    try {

        const lista = [];

        const resultado = await postJSON({
            acaoWebAExecutar: "autocompletar",
            sistema: "crm",
            projeto: "matendimento",
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaUsuario") {
                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdPessoaUsr,
                        label: item.noPessoa,
                        data: { ...item }
                    }
                }));
            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

export async function pesquisarAcFuncionario_NAOUSAR (params) {

    const payload = {
        "acaoAExecutar": "pesquisaFuncionarioSmk",
        filtro: preventInjection({
            txtNoFuncionario: params
        }),
    }

    try {

        const lista = [];

        const resultado = await postJSON({
            acaoWebAExecutar: "autocompletar",
            sistema: "crm",
            projeto: "matendimento",
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "resposta") {

                console.log(msg.lista);

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdPessoaFunc,
                        label: item.noFuncionario,
                        data: { ...item }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

export async function pesquisarContato (params) {

    const payload = {
        "acaoAExecutar": "pckContato",
        filtro: preventInjection({
            txtNoContato: params.nome,
        }),
        paramCdPessoaContato: params.codigo
    }

    try {

        const lista = [];

        const resultado = await postJSON({
            acaoWebAExecutar: "autocompletar",
            sistema: "crm",
            projeto: "matendimento",
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "resposta") {

                console.log(msg.lista);

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdContato,
                        label: item.noContato,
                        data: { ...item }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

export async function pesquisarAcMaterial (params) {

    const payload = {
        "acaoAExecutar": "pesquisa",
        filtro: preventInjection({
            txtNoMaterial: params
        }),
    }

    try {

        const lista = [];

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "smt",
            projeto: "mmatserv",
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaMaterial") {

                console.log(msg.lista);

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdMaterial,
                        label: item.noMaterial,
                        data: { ...item }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-card/dist/card.css';

import '../../framework/assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {useSetupConfig} from "../../framework/lib/ConfigManager";
import Store from "../../store";
import {useHistory} from "react-router-dom";
import {useQueryString, useWindowSize} from "../../framework/lib/iy2b-react";
import Iframe from 'react-iframe';

import {Image, Layout, Row, Col} from 'antd';
import logo from "../../framework/assets/images/header-logo.png";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const { Header, Footer, Content } = Layout;

const VisualizarDocumento = ( props ) => {

    useSetupConfig({
        appConfig: props.appConfig,
        store: Store
    });

    const breaks = useBreakpoint();

    const history = useHistory();
    const windowSize = useWindowSize();
    const qs = useQueryString();

    const iFrameHeight = windowSize.height - 64 - 70 ;
    const iFrameWidth = windowSize.width;

    /// http://localhost:3000/#/visualizar?chave=6b8c73848d91bba2&arquivo=nfe_000000010_FAT_20220323_105237.pdf
    /// https://portal.iy2b.com/#/visualizar?chave=aabbb19abcb38fa5&arquivo=dacte_35230548378185000107570010000005801000006706.pdf
    /// http://192.168.14.30:8080/systemakers/suite/rest/mgedview/desenv/6b8c73848d91bba2/exibedocumento/nfe_000000010_FAT_20220323_105237.pdf


    /// http://localhost:3000/#/visualizar?chave=81677c9e71a99f72&arquivo=termoAceite-1340-20230919-180353.pdf
    /// http://192.168.14.30:8080/systemakers/suite/rest/mgedview/desenv/81677c9e71a99f72/exibedocumento/termoAceite-1340-20230919-180353.pdf

    const chave = qs.get("chave");
    const arquivo = qs.get("arquivo");

    const url = props.appConfig.host + "/systemakers/suite/rest/mgedview/" + props.appConfig.txConfig + "/" + chave + "/exibedocumento/" + arquivo;

    const logoSize = (breaks.xs===true)?46:54;

    return (<>
        <Layout>
            <Header>
                <Row>
                    <Col flex={"70px"}>
                        <Image
                            className={"logo"}
                            preview={false}
                            src={logo}
                            width={logoSize} height={logoSize}
                        />
                    </Col>
                    <Col flex="auto">
                        <div className={"titulo"}>
                            Visualizador de documentos
                        </div>
                    </Col>
                </Row>
            </Header>
            <Content>
                <Iframe url={url}
                        width={iFrameWidth}
                        height={iFrameHeight}
                        id={"iframe-" + chave}
                />
            </Content>
            <Footer>
                powered by IY2B
            </Footer>
        </Layout>
    </>);

}

const mapStateToProps = function( {appConfig, userSession} ) {

    return {
        appConfig: appConfig,
        userSession: userSession
    };

};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VisualizarDocumento);

//v5 import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-layout/dist/layout.css';
import './style.scss';

import CLIQUE_PARA_EDITAR from "../../assets/images/clique-para-editar.png";

import React, {useState, useEffect, useRef, useContext} from 'react';
import {connect} from "react-redux";
import {ProForm,
    ProFormDateRangePicker,
    ProFormText,
} from '@ant-design/pro-form' ;
import {FooterToolbar} from "@ant-design/pro-layout";
import {Avatar, Button, Col, notification, Popconfirm, Row, Select, Popover, Space} from "antd";
import TooltipOnIcon from "../../componentes/TooltipOnIcon";
import {campoInformado, formatadorCpfCnpj, formataNoRuaDoEndereco} from "../../lib/iy2b-javascript";
import FormEndereco from "../../componentes/FormEndereco";
import {
    pesquisarCertificadoDigital,
    pesquisarCidadeDoCep,
    pesquisarEnderecoDaPessoa,
    pesquisarPlanoContrVenda
} from "../../lib/Servicos";
import {
    convertBlobToBase64,
    notificacaoErroRequest,
    notificacaoErroValidForm,
    ProjetoContext,
    useStateRef
} from "../../lib/iy2b-react";
import {postJSON, postWS} from "../../lib/RequestAPI";
import WizNovaEmpresa from "./wizNovaEmpresa";
import {atualizarListaEmpresas, setEmpresaAtual} from "../../reducer/suite";
import {atualizarInfoComplUsr} from "../../reducer/userSession";
import {closeAll} from "../../reducer/transacaoManager";
import moment from "moment";
import WizAtualizarCertDig from "./wizAtualizarCertDig";
import AvatarComEdicao from "../../componentes/AvatarComEdicao";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import WizNovoContrato from "./wizNovoContrato";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {cachedPostJSON} from "../../lib/CacheRequestAPI";
import Promessas from "../../lib/Promessas";
import EditarIntegrador from "./editarIntegrador";
import {WORKSPACE_LOADING_INDICATOR_AREA, WorkspaceContext} from "../../pages/Workspace/workspace";
import RadioGroup from "../../componentes/proform/RadioGroup/radioGroup";
import REIf from "../../componentes/REIf/reIF";
import {CUSTOM, FILESIGNATURE_ICON_PRIMARY} from "../../assets/iconografia";
import {notificacao} from "../../lib/Notificacao";
import GridEnderecos from "./gridEnderecos";
import EditarConfigImob from "./editarConfigImob";

const iconBuilding = CUSTOM.BUILDING_ICON;
const iconFileSignature = FILESIGNATURE_ICON_PRIMARY;
const iconCertificadoOk = CUSTOM.CHECK_CIRCLE_ICON_GREEN_3X;
const iconCertificadoAviso = CUSTOM.EXCLAMATION_CIRCLE_ICON_PURPLE_3X;
const iconCertificadoErro = CUSTOM.EXCLAMATION_CIRCLE_ICON_RED_3X;
const iconConnection = CUSTOM.NETWORK_WIRED_ICON;
const iconSettings = CUSTOM.SETTINGS_ICON;

const MinhasEmpresas = (props) => {

    const { loadingArea } = props;

    const cdTransacao = 85063;

    const { availableWidth } = useContext(WorkspaceContext);

    const formRef = useRef() ;
    const refNoFantasia = useRef();

    const [empresaAtual, setEmpresaAtual] = useState(null);
    const [campoAlterado, setCampoAlterado] = useState(false);

    const [iconValidCertif, setIconValidCertif] = useState(iconCertificadoOk);

    const [exibeWizNovaEmpresa, setExibeWizNovaEmpresa] = useState(false);
    const [exibeWizAtualizarCertDig, setExibeWizAtualizarCertDig] = useState(false);
    const [exibeWizNovoContrato, setExibeWizNovoContrato] = useState(false);
    const [exibeConfigImob, setExibeConfigImob] = useState(false);

    const [dpIntegradores, setDpIntegradores] = useState([]);

    const [dataProvider, setDataProvider] = useState({});

    const [integracaoSpedy, setIntegracaoSpedy, refIntegracaoSpedy] = useStateRef({});

    const [contextValue, setContextValue] = useState({
        setValue: (key, value) => {
            contextValue[key] = value;
            setContextValue({
                ...contextValue
            });
        },
        getValue: key => {
            return contextValue[key];
        }
    });

    const dpEmpresas = props.suite.listaEmp
                        .filter(empresa => empresa.pessoaEmp.flgJuridico==="J" || empresa.pessoaEmp.flgJuridico==="F")
                        .map(empresa => {
                            return {value: empresa.pessoaEmp.cdPessoa, label: empresa.noOficial}
                        });

    const cdPessoaEmpMaster = props.userSession?.planoContratado?.cdPessoaEmpMaster;

    const usrMaster = props.userSession?.master;

    const usuarioAtual = {
        noEMail: props.userSession?.noEmail,
        noUsuario: props.userSession?.noPessoa,
        cdPessoaUsr: props.userSession?.cdPessoaUsr,
        nrTelCelular: props.userSession?.nrTelCelular,
    };

    const { xl, xxl } = useBreakpoint();

    const { nrMaxEmps, nrTotEmps } = props.userSession?.planoContratado;

    const [b64LogoBD, setB64LogoBD, refB64LogoBD] = useStateRef(null);

    const [dsPlanos, setDsPlanos] = useState([]);

    const [exibeEditarIntegrador, setExibeEditarIntegrador] = useState(false);
    const [registrosAEditar, setRegistrosAEditar] = useState([]);

    const [possueCertificado, setPossueCertificado] = useState(false);

    const forceUpdate = useForceUpdate();

    const { portalImobiliario } = props.suite;

    console.log("portal Imobiliario?", portalImobiliario);
    console.log("integracaoSpedy", integracaoSpedy);

    useEffect(() => {

        inicializa();

        return () => {

        };

    }, []);

    const inicializa = async () => {

        const empAtualDoWS = dpEmpresas.filter(empresa => empresa.value === props.userSession?.cdPessoaEmpAtual);

        setEmpresaAtual(empAtualDoWS[0]);

        await pesquisaPK(empAtualDoWS[0]);

        const planos = await pesquisarPlanoContrVenda(loadingArea, cdTransacao);

        setDsPlanos(planos);

        contextValue.setValue("planoEhGratuito", (props.userSession?.planoContratado?.flgGratuito === "S"));

    }

    const onChangeEmpresa = (value) => {

        setEmpresaAtual(value);

        pesquisaPK(value);

    }

    const pesquisarLogoEmp = async (cdPessoaEmp) => {

        const resposta =  {
            b64LogoBD: null
        };

        try {

            const payload = {
                acaoAExecutar: "acaoCRUD",
                registro: {"cdPessoaEmp": cdPessoaEmp}
            };

            const resultado = await postJSON({
                sistema: "sgr",
                projeto: "memplogo",
                acaoWebAExecutar: "pesquisarpk"
            }, payload, loadingArea);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    resposta.b64LogoBD = msg.modeloPadrao.b64LogoBD;

                }

            });

        } catch (e) {

            if(e.data?.id === "pkNaoEncontrada") {

            } else {

                console.log(e.data);

                notificacaoErroRequest(e, cdTransacao);

            }

        }

        return resposta;

    }

    const pesquisarIntegracoes = async () => {

        try {

            const resultado = await cachedPostJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sgr",
                projeto: "mintegradorapi",
                payload: {
                    acaoAExecutar: "pesquisa",
                    orderBySql: "NOINTEGRADOR"
                }
            }, loadingArea);

            const msgsLista = resultado.mensagens.filter(msg => msg.id === "listaIntegradorAPI");

            if(msgsLista.length === 1) {
                return msgsLista[0].lista.map(item => {
                    return {
                        value: item.cdIntegrador,
                        label: item.noIntegrador,
                        data: { ...item }
                    }
                });
            } else {
                return null;
            }

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return null;

        }

    }

    const pesquisarEmpresa = async (cdEmp) => {

        try {

            const resultado = await postJSON({
                sistema: "smk",
                projeto: "mempresa",
                acaoWebAExecutar: "pesquisarpk"
            }, {
                acaoAExecutar: "acaoCRUD",
                registro: {"cdEmp": cdEmp}
            }, loadingArea);

            const msgsLista = resultado.mensagens.filter(msg => msg.id === "modelo");

            if(msgsLista.length === 1) {
                return msgsLista[0].modeloPadrao;
            } else {
                return null;
            }

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return null;

        }

    }

    const pesquisarIntegradorSPEDY = async (cdEmp) => {

        try {

            const resultado = await postJSON({
                sistema: "smk",
                projeto: "mempresapiy2b",
                acaoWebAExecutar: "pesquisar"
            }, {
                acaoAExecutar: "pesquisaIntegradorSPEDY",
                "cdEmp": cdEmp
            }, loadingArea);

            const msgsLista = resultado.mensagens.filter(msg => msg.id === "resposta");

            if(msgsLista.length === 1) {
                return msgsLista[0].modeloPadrao;
            } else {
                return null;
            }

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return null;

        }

    }

    const pesquisaPK = async (registro) => {

        const registros = props.suite.listaEmp
            .filter(empresa => empresa.pessoaEmp.cdPessoa===registro.value);

        const pk = {
            ...registros[0],
            enderecos: [ ],
            certificado: null
        };

        try {

            const promessas = new Promessas();

            //

            promessas.add(async (resolve, reject) => {
                try {

                    const empresaPk = await pesquisarEmpresa(pk.cdEmp);

                    pk.empresa = { ...empresaPk };

                    resolve(true);

                } catch (e) {

                    console.log(e);

                    notificacaoErroRequest(e, cdTransacao);

                    reject (e);

                }
            });

            promessas.add(async (resolve, reject) => {
                try {

                    const integradorSpedy = await pesquisarIntegradorSPEDY(pk.cdEmp);

                    pk.spedy = { ...integradorSpedy };

                    resolve(true);

                } catch (e) {

                    console.log(e);

                    notificacaoErroRequest(e, cdTransacao);

                    reject (e);

                }
            });

            promessas.add(async (resolve, reject) => {
                try {

                    const enderecos = await pesquisarEnderecoDaPessoa(registro.value, loadingArea, cdTransacao);

                    pk.enderecos.addAll(enderecos);

                    resolve(true);

                } catch (e) {

                    console.log(e);

                    notificacaoErroRequest(e, cdTransacao);

                    reject (e);

                }
            });

            promessas.add(async (resolve, reject) => {
                try {

                    const certificados = await pesquisarCertificadoDigital(registro.value, loadingArea, cdTransacao);

                    if(certificados.isEmpty() === true) {
                        pk.certificado = null;
                        setPossueCertificado(false);
                    } else {
                        pk.certificado = certificados[0];
                        setPossueCertificado(true);
                    }

                    resolve(true);

                } catch (e) {

                    console.log(e);

                    notificacaoErroRequest(e, cdTransacao);

                    reject (e);

                }
            });

            promessas.add(async (resolve, reject) => {
                try {

                    const logoEmp = await pesquisarLogoEmp (registro.value);

                    pk.b64LogoBD = logoEmp.b64LogoBD;

                    resolve(true);

                } catch (e) {

                    console.log(e);

                    notificacaoErroRequest(e, cdTransacao);

                    reject (e);

                }
            });

            promessas.add(async (resolve, reject) => {
                try {

                    const integradores = await pesquisarIntegracoes();

                    setDpIntegradores(integradores);

                    setDataProvider({
                        cdPessoaEmpAtual: registro.value,
                        dpIntegrador: integradores
                    });

                    resolve(true);

                } catch (e) {

                    console.log(e);

                    notificacaoErroRequest(e, cdTransacao);

                    reject (e);

                }
            });

            await promessas.run();

            setRegistroNaTela(pk);

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return ;
        }

    }

    const setRegistroNaTela = (registro) => {

        console.log("setRegistroNaTela", registro);

        const endereco = registro.enderecos[0];

        const registroNaTela = {
            nrCNPJ: formatadorCpfCnpj(registro.pessoaEmp.nrCGCCIC),
            noFantasia: registro.pessoaEmp.noFantasia,
            flgOptaSimples: registro.empresa.flgOptaSimples||"N",
            flgMEI: registro.empresa.flgMEI||"N",
            nrCEP: endereco.nrCep,
            noLogradouro: endereco.noLograd,
            txNumero: endereco.txNumero,
            txComplEnd: endereco.txCompl,
            noBairro: endereco.noBairro,
            noCidade: endereco.noCidade.trim() + "/" + endereco.ufCidade,
            nrInsEst: endereco.nrInsEstEnd,
            descrInsEstEnd: endereco.descrInsEstEnd,
            nrInsMunicip: endereco.nrInsMunicipEnd,
            nrTelefone: endereco.nrTelefone,
            noEmailNF_E: endereco.noEmailNF_E,
            nrSeqEnd: endereco.nrSeqEnd,
            tpEndereco: endereco.tpEndereco,
            cdCidade: endereco.cdCidade,
            cdRegiao: endereco.cdRegiao,
            cdTipoCalcImposto: endereco.cdTipoCalcImposto,
            dtCad: endereco.dtCad,
            dtInicValid: endereco.dtInicValid,
            noContato: endereco.noContato,
            noEmail: endereco.noEmail
        };

        if(campoInformado(registro.certificado) === true) {

            registroNaTela.dtValidCertifDig = [moment(registro.certificado.dtInicValid), moment(registro.certificado.dtFimValid)];

            const hoje = moment();

            const diasValidade = moment(registro.certificado.dtFimValid).diff(hoje, 'days');

            if(diasValidade > 30) {
                setIconValidCertif(iconCertificadoOk);
            } else if(diasValidade > 10) {
                setIconValidCertif(iconCertificadoAviso);
            } else {
                setIconValidCertif(iconCertificadoErro);
            }

        } else {

            registroNaTela.dtValidCertifDig = [];
            setIconValidCertif(iconCertificadoErro);

        }

        formRef.current?.setFieldsValue(registroNaTela);

        if(campoInformado(registro.b64LogoBD) === true) {
            setB64LogoBD ( "data:image/*;base64," + registro.b64LogoBD );

        } else {

            if(usrMaster === "S") {
                setB64LogoBD ( CLIQUE_PARA_EDITAR );

            } else {
                setB64LogoBD ( null );

            }

        }

        if(campoInformado(registro.certificado) === false) {
            registro.spedy.permitida = false;
        }

        console.log("registro.spedy", registro.spedy);

        setIntegracaoSpedy ( registro.spedy ) ;

        refNoFantasia.current.focus();

        setCampoAlterado(false);

        // formEnderecoApi.current.setFocus("CEP");

    }

    const salvarEmpresa = async () => {

        if(campoAlterado === false) {
            notificacao.aviso({transacao: cdTransacao, message:"Salvar", description:"Nenhuma informação foi alterada. Não é necessário salvar !"})
            return false;
        }

        try {

            const camposDoForm = await formRef.current?.validateFields();

            if(camposDoForm.flgOptaSimples === "S" && camposDoForm.flgMEI === "S") {
                notificacao.aviso({transacao: cdTransacao, message:"Salvar", description:"Empresa não pode ser MEI e optante do Simples ao mesmo tempo."})
                return false;
            }

            const cidade = await pesquisarCidadeDoCep(camposDoForm.nrCEP, loadingArea, cdTransacao);

            const payloadEndereco = {
                acaoAExecutar: "acaoCRUD",
                registro: {
                    cdPessoa: empresaAtual.value,
                    nrSeqEnd: camposDoForm.nrSeqEnd,
                    tpEndereco: camposDoForm.tpEndereco,
                    nrCep: camposDoForm.nrCEP.replace("-",""),
                    noLograd: camposDoForm.noLogradouro?.substr(0, 160),
                    txNumero: camposDoForm.txNumero?.substr(0, 20),
                    txCompl: camposDoForm.txComplEnd?.substr(0, 30),
                    noBairro: camposDoForm.noBairro?.substr(0, 20),
                    nrInsEstEnd: camposDoForm.nrInsEst,
                    descrInsEstEnd: camposDoForm.descrInsEstEnd,
                    nrInsMunicipEnd: camposDoForm.nrInsMunicip,
                    nrTelefone: camposDoForm.nrTelefone,
                    noEmailNF_E: camposDoForm.noEmailNF_E?.substr(0, 60),
                    cdRegiao: camposDoForm.cdRegiao,
                    cdTipoCalcImposto: camposDoForm.cdTipoCalcImposto,
                    dtCad: camposDoForm.dtCad,
                    dtInicValid: camposDoForm.dtInicValid,
                    dtFimValid: "1901/01/01",
                    noContato: camposDoForm.noContato,
                    noEmail: camposDoForm.noEmail?.substr(0, 60),
                    ...cidade,
                    flgExpDados: "N",
                    flgZFranca: "N"
                }
            };

            payloadEndereco.registro.noRua = formataNoRuaDoEndereco(payloadEndereco.registro);

            let sucessoEnd = false;

            const resultadoEnd = await postJSON({
                sistema: "sgr",
                projeto: "mendereco",
                acaoWebAExecutar: "alterar"
            }, payloadEndereco, loadingArea);

            resultadoEnd.mensagens.forEach(msg => {
                if(msg.id === "modelo") {
                    // tudo certo
                    sucessoEnd = true;
                } else {
                    console.log(msg);
                }
            });

            const payloadEmpresa = {
                acaoAExecutar: "acaoCRUD",
                registro: {
                    cdPessoaEmp: empresaAtual.value,
                    noFantasia: camposDoForm.noFantasia,
                    flgOptaSimples: camposDoForm.flgOptaSimples||"N",
                    flgMEI: camposDoForm.flgMEI||"N",
                }
            };

            const resultadoEmpresa = await postJSON({
                sistema: "smk",
                projeto: "mempresapiy2b",
                acaoWebAExecutar: "alterar"
            }, payloadEmpresa, loadingArea);

            const msgsSpedyAPI = resultadoEmpresa.mensagens.filter(msg => msg.id === "resposta");

            if(msgsSpedyAPI.length === 1) {
                setIntegracaoSpedy ( msgsSpedyAPI[0].modeloPadrao ) ;
            }

            if(sucessoEnd === true) {
                props.atualizarListaEmpresas();
                setCampoAlterado(false);
                notificacao.sucesso({transacao: cdTransacao, message:"Salvar", description:"Informações salvas com sucesso !"})
            }

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const logAuditoria = () => {
        const payload = {
            cdPessoaUsr: props.userSession.cdPessoaUsr,
            cdPessoaEmp: props.userSession.cdPessoaEmpAtual,
            txIdApp: "iy2b-portal"
        };
        postWS({
            sistema: "smk",
            projeto: "msmkpiy2b",
            acaoWebAExecutar: "processarNegocio",
            acaoAExecutar: "finalizarTodos"
        }, payload).then(resposta => {
            resposta.mensagens.forEach(msg => {
                console.log(msg);
            });
        }, error => {
            console.log(error);
        });
    }

    const excluirEmpresa = async () => {

        try {

            const payloadEmpresa = {
                acaoAExecutar: "acaoCRUD",
                lista:[ {
                    cdPessoaEmp: empresaAtual.value
                }]
            };

            const resultadoEmpresa = await postJSON({
                sistema: "smk",
                projeto: "mempresapiy2b",
                acaoWebAExecutar: "excluir"
            }, payloadEmpresa, loadingArea);

            const empresaExcluidaEraEmpresaWS = (empresaAtual.value === props.userSession?.cdPessoaEmpAtual);

            const empAtualDoWS = dpEmpresas.filter(empresa => empresa.value === (empresaExcluidaEraEmpresaWS)?cdPessoaEmpMaster:props.userSession?.cdPessoaEmpAtual);

            setEmpresaAtual(empAtualDoWS[0]);

            await pesquisaPK(empAtualDoWS[0]);

            await props.atualizarListaEmpresas();

            if(empresaExcluidaEraEmpresaWS) {

                const empresaMaster = props.suite.listaEmp
                    .filter(empresa => empresa.pessoaEmp.cdPessoa===cdPessoaEmpMaster);

                await props.setEmpresaAtual(empresaMaster[0], props.userSession);

            }

            await props.atualizarInfoComplUsr();

            if(empresaExcluidaEraEmpresaWS) {

                await props.closeAllTransacoes();

                logAuditoria();

            }

            notificacao.sucesso({transacao: cdTransacao, message: "Excluída!", description:"Empresa excluída da plataforma com sucesso !"})

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const btnAdicionarEmp_Click = () => {

        if(nrMaxEmps > nrTotEmps) {

            setExibeWizNovaEmpresa(true);

        } else {

            notificacao.erro({transacao: cdTransacao, duration:8, message:"Limite contratado atingido", description:"A cota de empresas cadastradas chegou ao seu limite. Se necessário troque o seu plano de assinatura."})

        }

    }

    const onContratoAdicionado = () => {

        props.atualizarListaEmpresas().then((data) => {

            props.atualizarInfoComplUsr();

        });

    }

    const btnNovaContratacao_Click = () => {

        setExibeWizNovoContrato(true);

    }

    const onEmpresaAdicionada = () => {

        props.atualizarListaEmpresas().then((data) => {

            props.atualizarInfoComplUsr();

        });

    }

    const atualizarCertDigSpedy = async () => {

        try {

            const payload = {
                acaoAExecutar: "atualizarCertDigIntegradorSPEDY",
                "cdPessoaEmp": empresaAtual.value
            }

            const resultado = await postJSON({
                sistema: "smk",
                projeto: "mempresapiy2b",
                acaoWebAExecutar: "processarNegocio"
            }, payload, loadingArea);

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const onCertificadoAtualizado = async () => {

        setExibeWizAtualizarCertDig (false);

        if(integracaoSpedy.habilitada === true) {

            const respAtuCert = await atualizarCertDigSpedy();

        }

        const certificados = await pesquisarCertificadoDigital(empresaAtual.value, loadingArea, cdTransacao);

        const certificado = certificados[0];

        const values = {
            dtValidCertifDig:[moment(certificado.dtInicValid), moment(certificado.dtFimValid)]
        }

        formRef.current?.setFieldsValue(values);

        const hoje = moment();

        const diasValidade = moment(certificado.dtFimValid).diff(hoje, 'days');

        if(diasValidade > 30) {
            setIconValidCertif(iconCertificadoOk);
        } else if(diasValidade > 10) {
            setIconValidCertif(iconCertificadoAviso);
        } else {
            setIconValidCertif(iconCertificadoErro);
        }

        setCampoAlterado(false);

    }

    const iniciarAtualizacaoCertificado = () => {

        setExibeWizAtualizarCertDig(true);

    }

    const btnConfigImob_Click = () => {
        setExibeConfigImob ( true );
    }

    const salvarLogoEmp = async (base64String) => {

        try {

            const strB64 = base64String.substring(base64String.indexOf("base64,") + 7);

            const payload = {
                acaoAExecutar: "acaoCRUD",
                registro: {"cdPessoaEmp": empresaAtual.value},
                b64LogoBD: strB64
            };

            const resultado = await postJSON({
                sistema: "sgr",
                projeto: "memplogo",
                acaoWebAExecutar: (refB64LogoBD.current===null) ? "incluir" : "alterar"
            }, payload, loadingArea);

            resultado.mensagens.forEach(msg => {

                console.log(msg);

            });

            await props.atualizarListaEmpresas();

            setB64LogoBD ( base64String );

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const onChangeAvatar = async (newImg) => {

        const base64String = await convertBlobToBase64(newImg);

        await salvarLogoEmp ( base64String );

    }

    const exibeExcluirEmpresa = (cdPessoaEmpMaster != empresaAtual?.value);

    const typeBtnAtuCertDig = (iconValidCertif===iconCertificadoOk)?"default":"primary";

    const excluirIntegrador = async (registros) => {

        try {

            const payload = {
                acaoAExecutar: "acaoCRUD",
                lista: registros
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "excluir",
                sistema: "sgr",
                projeto: "mintegradorapiemp"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelosExcluidos") {

                } else {

                    console.log(msg);

                }

            });

            setExibeEditarIntegrador(false) ;

            notificacao.sucesso({transacao: cdTransacao, message:"Sucesso!", description:"Integração excluída com sucesso!"})

            return true;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const salvarIntegrador = async (registro) => {

        try {

            const payload = {
                acaoAExecutar: "acaoCRUD",
                registro: registro
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "alterar",
                sistema: "sgr",
                projeto: "mintegradorapiemp"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            notificacao.sucesso({transacao: cdTransacao, message:"Sucesso!", description:"Integração salva com sucesso!"})

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const [openListaIntegrAPI, setOpenListaIntegrAPI] = useState(false);

    const handleOpenChangeListaIntegrAPI = (newOpen) => {
        setOpenListaIntegrAPI(newOpen);
    };

    const exibeConfigIntegracaoAPI = (value) => {
        setOpenListaIntegrAPI(false);
        setRegistrosAEditar([{
            cdPessoaEmp: dataProvider.cdPessoaEmpAtual,
            "cdIntegrador": value
        }]);
        setExibeEditarIntegrador(true) ;
    }

    const eListaIntegracaoApi = <>
        {dpIntegradores.map(item => {
            return <Button key={item.value} type={"link"} onClick={() => exibeConfigIntegracaoAPI(item.value)}>{item.label}</Button>
        })}
    </>;

    const btnHabSpedy_OnClick = async () => {

        try {

            const payload = {
                acaoAExecutar: "habilitarIntegracaoSPEDY",
                "cdPessoaEmp": empresaAtual.value
            }

            const resultadoEmpresa = await postJSON({
                sistema: "smk",
                projeto: "mempresapiy2b",
                acaoWebAExecutar: "processarNegocio"
            }, payload, loadingArea);

            const msgsSpedyAPI = resultadoEmpresa.mensagens.filter(msg => msg.id === "resposta");

            if(msgsSpedyAPI.length === 1) {
                setIntegracaoSpedy ( msgsSpedyAPI[0].modeloPadrao ) ;
                notificacao.sucesso({transacao: cdTransacao, message:"Sucesso!", description:"Solicitado com sucesso, necessário aguardar!"})
            }

        } catch (e) {
            notificacaoErroRequest(e, cdTransacao);
        }

    }

    const btnDesabSpedy_OnClick = async () => {

        try {

            const payload = {
                acaoAExecutar: "desabilitarIntegracaoSPEDY",
                "cdPessoaEmp": empresaAtual.value
            }

            const resultadoEmpresa = await postJSON({
                sistema: "smk",
                projeto: "mempresapiy2b",
                acaoWebAExecutar: "processarNegocio"
            }, payload, loadingArea);

            const msgsSpedyAPI = resultadoEmpresa.mensagens.filter(msg => msg.id === "resposta");

            if(msgsSpedyAPI.length === 1) {
                setIntegracaoSpedy ( msgsSpedyAPI[0].modeloPadrao ) ;
                notificacao.sucesso({transacao: cdTransacao, message:"Sucesso!", description:"Desabilitado com sucesso!"})
            }

        } catch (e) {
            notificacaoErroRequest(e, cdTransacao);
        }

    }


    const boxIntegracaoSpedyApi = (integracaoSpedy.permitida === true) ?
        <div style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%"
              }}
        >
            <Row style={{alignItems: "center"}}>
                <Col xs={24}>
                    <REIf hidden={integracaoSpedy.emHabilitacao === false}>
                        <>Em processo de habilitação para emissão de NFSe</>
                    </REIf>
                    <REIf hidden={integracaoSpedy.habilitada === false}>
                        <Button onClick={btnDesabSpedy_OnClick}>Desabilitar emissão NFSe</Button>
                    </REIf>
                    <REIf hidden={integracaoSpedy.habilitada === true || integracaoSpedy.emHabilitacao === true}>
                        <Popconfirm
                            title={"Confirma solicitar emissão de NFSe ?"}
                            onConfirm={async () => {
                                await btnHabSpedy_OnClick();
                            }}
                            okText="Sim, solicitar"
                            cancelText="Não"
                        >
                            <Button>Solicitar emissão NFSe</Button>
                        </Popconfirm>
                    </REIf>
                </Col>
            </Row>
        </div>
        : null;


    return (
        <div id={"containerMinhasEmpresas"}>
            <ProjetoContext.Provider value={contextValue}>
                <Row gutter={[8,16]}>
                    {(usrMaster === "S") ?
                        <Col xs={8} md={4} lg={3} xl={3} className={"align-center"}>
                            <AvatarComEdicao id={"avatarLogoEmpresa"}
                                cropWidth={46} cropHeight={46}
                                size={108}
                                shape={"circle"}
                                src={b64LogoBD}
                                onChangeAvatar={onChangeAvatar}
                            >
                            </AvatarComEdicao>
                        </Col>
                        :
                        <Col xs={8} md={4} lg={3} xl={3} className={"align-center"}>
                            <Avatar
                                size={108}
                                shape={"circle"}
                                src={b64LogoBD}
                            >
                            </Avatar>
                        </Col>
                    }
                    <Col xs={16} md={12} lg={12} xl={8}>
                        <TooltipOnIcon label={"Empresa"} title={"Escolha a empresa para editar as informações"} />
                        <Select style={{width:"100%"}}
                            labelInValue
                            onChange={onChangeEmpresa}
                            placeholder={"Escolha uma empresa para editar as informações"}
                            options={dpEmpresas}
                            value={empresaAtual}
                        />
                    </Col>
                    {(usrMaster==="S")?
                        <Col xs={24} md={8} lg={9} xl={13}>
                            {(xl===true || xxl===true) ?
                                <Row gutter={[8, 16]}>
                                    <Col span={10}>
                                        <label>&nbsp;</label>
                                        <Button type={"default"} icon={iconBuilding} onClick={ btnAdicionarEmp_Click } block>&nbsp;Adicionar nova empresa</Button>
                                    </Col>
                                    <Col span={10}>
                                        <label>&nbsp;</label>
                                        <Button type={"default"} icon={iconFileSignature} onClick={ btnNovaContratacao_Click } block>&nbsp;Fazer nova contratação</Button>
                                    </Col>
                                </Row>
                            :
                                <>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={24} md={22} lg={16} xl={10}>
                                            <label>&nbsp;</label>
                                            <Button type={"default"} icon={iconBuilding} onClick={ btnAdicionarEmp_Click } block>&nbsp;Adicionar nova empresa</Button>
                                        </Col>
                                    </Row>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={24} md={22} lg={16} xl={10}>
                                            <label>&nbsp;</label>
                                            <Button type={"default"} icon={iconFileSignature} onClick={ btnNovaContratacao_Click } block>&nbsp;Fazer nova contratação</Button>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Col>
                        : null
                    }
                </Row>
                <ProForm
                    formRef = { formRef }
                    submitter = {{
                        render : () =>
                            <div className={"iy2b-footer-toolbar"}
                                style={{
                                    width: availableWidth()
                                }}
                            >
                                {(exibeExcluirEmpresa===true)?
                                    <Popconfirm
                                        title = "Confirma a exclusão desta empresa. Todas as informações desta empresa serão excluidas da plataforma ?"
                                        okText = "Sim, excluir esta empresa. Já tenho todas as informações seguras em outro lugar !"
                                        cancelText="Não"
                                        onConfirm={excluirEmpresa}
                                        icon={CUSTOM.QUESTION_CIRCLE_ICON_RED}
                                    >
                                        <Button type={"primary"} danger>Excluir esta empresa</Button>
                                    </Popconfirm>
                                    :null
                                }
                                <Popover
                                    content={eListaIntegracaoApi}
                                    title="Escolha uma integração para configurar"
                                    trigger="click"
                                    open={openListaIntegrAPI}
                                    onOpenChange={handleOpenChangeListaIntegrAPI}
                                    arrow={{ pointAtCenter: true }}
                                >
                                    <Button type={"default"} icon={iconConnection}>&nbsp;Integrações disponíveis</Button>
                                </Popover>
                                {(portalImobiliario===true) ?
                                    <Button type={"default"} icon={iconSettings} onClick={ btnConfigImob_Click }>&nbsp;Configurações imobiliárias</Button>
                                    :null
                                }
                                <Button type={"primary"} onClick={salvarEmpresa}>Salvar empresa</Button>
                            </div>
                    }}
                    onFieldsChange = {(changedFields) => {
                        setCampoAlterado(changedFields.length > 0);
                    }}
                >
                    <Row gutter={[8,16]}>
                        <Col xs={24} md={12} lg={5}>
                            <ProFormText
                                width={"sm"}
                                name = "nrCNPJ"
                                label = "CNPJ"
                                tooltip = "CNPJ da empresa"
                                disabled
                            />
                        </Col>
                        <Col xs={24} md={12} lg={5}>
                            <ProFormText
                                width={"sm"}
                                name = "noFantasia"
                                label = "Nome fantasia"
                                tooltip = "Nome fantasia da empresa"
                                placeholder={"Digite o nome fantasia"}
                                rules={[ {required: true, message:"Informe o nome fantasia da empresa"}, {message:"No máximo 20 caracteres", max:20}]}
                                fieldProps={{
                                    ref: refNoFantasia
                                }}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={6}>
                            <RadioGroup
                                width={"sm"}
                                name="flgOptaSimples"
                                label="Empresa optante do Simples ?"
                                tooltip = { "Indique se esta empresa é optante do Simples Nacional" }
                                rules={[
                                ]}
                                options={[
                                    {
                                        label: 'Não',
                                        value: 'N',
                                    },
                                    {
                                        label: 'Sim',
                                        value: 'S',
                                    },
                                ]}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <RadioGroup
                                width={"sm"}
                                name="flgMEI"
                                label="Microempreendedor individual (MEI) ?"
                                tooltip = { "Indique se esta empresa é um microempreendedor individual (MEI)" }
                                rules={[
                                ]}
                                options={[
                                    {
                                        label: 'Não',
                                        value: 'N',
                                    },
                                    {
                                        label: 'Sim',
                                        value: 'S',
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[8,16]}>
                        <Col xs={24} md={18} lg={16}>
                            <Row style={{alignItems: "center"}}>
                                <Col xs={18} md={12}>
                                    <ProFormDateRangePicker
                                        width = {"xl"}
                                        name = "dtValidCertifDig"
                                        label = "Validade do certificado"
                                        tooltip = {"Período de validade do certificado digital"}
                                        format = {'DD/MM/YY'}
                                        disabled
                                    />
                                </Col>
                                <Col xs={6} md={3} style={{
                                    textAlign: "center"
                                }}>
                                    {iconValidCertif}
                                </Col>
                                <Col xs={12} md={9}>
                                    <Button type={typeBtnAtuCertDig} onClick={iniciarAtualizacaoCertificado}>Atualizar certificado</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} md={6} lg={8}>
                            {boxIntegracaoSpedyApi}
                        </Col>
                    </Row>
                    <GridEnderecos cdTransacao={cdTransacao}
                        loadingArea={loadingArea}
                        cdPessoa = { empresaAtual?.value }
                        cdPessoaEmpMaster = { cdPessoaEmpMaster }
                        usuarioAtual = { usuarioAtual }
                        exibeInscricoes = { true }
                        exibeMeiosContato = { true }
                    />
                </ProForm>
                <WizNovaEmpresa cdTransacao={cdTransacao}
                    exibePopUp={exibeWizNovaEmpresa}
                    onRequestClose={() => setExibeWizNovaEmpresa(false)}
                    appConfig = { props.appConfig }
                    cdPessoaEmpMaster = { cdPessoaEmpMaster }
                    usuarioAtual = { usuarioAtual }
                    onEmpresaAdicionada = { onEmpresaAdicionada }
                />
                <WizAtualizarCertDig cdTransacao={cdTransacao}
                    exibePopUp={exibeWizAtualizarCertDig}
                    onRequestClose={() => setExibeWizAtualizarCertDig(false)}
                    onCertificadoAtualizado = { onCertificadoAtualizado }
                    appConfig = { props.appConfig }
                    cdPessoaEmpAtual = { empresaAtual?.value }
                    nrCGCCICEmpAtual = { formRef.current?.getFieldsValue()["nrCNPJ"] }
                />
                <WizNovoContrato cdTransacao={cdTransacao}
                    exibePopUp={exibeWizNovoContrato}
                    onRequestClose={() => setExibeWizNovoContrato(false)}
                    appConfig = { props.appConfig }
                    cdPessoaEmpMaster = { cdPessoaEmpMaster }
                    usuarioAtual = { usuarioAtual }
                    dsPlanoCtrVenda = { dsPlanos }
                    onContratoAdicionado = { onContratoAdicionado }
                />
                <EditarIntegrador cdTransacao={cdTransacao}
                    exibePopUp={ exibeEditarIntegrador }
                    onRequestClose={() => {
                        setExibeEditarIntegrador(false) ;
                    }}
                    dataProvider={dataProvider}
                    registros={registrosAEditar}
                    salvar={salvarIntegrador}
                    excluir={ async (registro) => await excluirIntegrador([registro])}
                />
                <EditarConfigImob cdTransacao={cdTransacao}
                                  exibePopUp={ exibeConfigImob }
                                  onRequestClose={() => {
                                      setExibeConfigImob ( false );
                                  }}
                                  dataProvider={dataProvider}
                                  cdPessoaEmpAtual = { empresaAtual?.value }
                />
            </ProjetoContext.Provider>
        </div>
    );

};

const mapStateToProps = function( { appConfig, userSession, dataProvider, suite } ) {

    return {
        appConfig: appConfig,
        suite: suite,
        userSession: userSession,
        dataProvider: dataProvider,
    };

};

const mapDispatchToProps = dispatch => {
    return {
        setEmpresaAtual: (empresa, userSession) => {
            return dispatch( setEmpresaAtual(empresa, userSession) );
        },
        closeAllTransacoes: async () => {
            return dispatch( closeAll() );
        },
        atualizarListaEmpresas: () => {
            return dispatch ( atualizarListaEmpresas() );
        },
        atualizarInfoComplUsr: () => {
            return dispatch ( atualizarInfoComplUsr() );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MinhasEmpresas);

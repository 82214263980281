//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../../assets/css/tema.scss';
import "./style.scss";

import React, {useRef} from "react";

const IY2BProFormFiller = ( props ) => {

    return (
        <div className={"iy2b-pro-form-filler"}
            {...props} >

        </div>
    );
}

export default IY2BProFormFiller;

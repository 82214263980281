//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../../assets/css/tema.scss';
import "./style.scss";

import {ProForm} from "@ant-design/pro-components";
import InputDecimal from "../../InputDecimal";
import React, {useEffect, useState} from "react";

const IY2BProFormDecimal = (props) => {

    const {width="sm", label, name, tooltip, rules, placeholder, precision, disabled=false, onChange=null, ffrDisabled=undefined} = props;

    return (
        <ProForm.Item
            width = { width }
            label = { label }
            name = { name }
            tooltip = { tooltip }
            rules = { rules }
        >
            <InputDecimal placeholder={placeholder}
                          precision={precision}
                          width={width}
                          disabled={disabled}
                          ffrDisabled={ffrDisabled}
                          className={"iy2b-pro-form-decimal"}
                          onChangeInput={onChange}
                          name={name + "Input"}
            />
        </ProForm.Item>
    );

}

export default IY2BProFormDecimal;

import {ProForm, ProFormTextArea} from "@ant-design/pro-components";
import {Col, Row} from "antd";
import ComboBox from "../../framework/componentes/proform/ComboBox/comboBox";
import IY2BProFormFiller from "../../framework/componentes/proform/IY2BProFormFiller/iy2bProFormFiller";
import React, {useImperativeHandle, useRef} from "react";
import {notificacaoErroValidForm, useConteudoAlterado} from "../../framework/lib/iy2b-react";
import {ObjectUtils} from "../../framework/lib/iy2b-javascript";
import {notificacao} from "../../framework/lib/Notificacao";


const FormFinalizarAtendimento = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {
            salvarForm: doSalvar
        };
    });

    const {cdTransacao, finalizarAtend, fiValues, dataProvider, onRequestClose} = props;

    const formRef = useRef();

    const conteudoAlterado = useConteudoAlterado();

    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        console.log("getRegistroDaTela.ffValues", ffValues);

        const registro = {
            ...ObjectUtils.valuesFrom(ffValues, ["txResultado"]),
            motivoFimAtendimento: {
                cdMotivoFimAtendimento: ffValues.cmbCdMotivoFimAtendimento?.data.cdMotivoFimAtendimento,
                cdStatusAtendimento: ffValues.cmbCdMotivoFimAtendimento?.data.cdStatusAtendimento.cdStatusAtendimento,
            },
        }

        return registro;
    }

    const validaTela = async (params) => {

        let resultado = true;

        const {exibeMens = true} = params || {};

        const ffValues = formRef.current?.getFieldsValue();

        return resultado;

    };

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if (telaValida === false) return false;

            if (conteudoAlterado.isConteudoAlterado() === false) {
                notificacao.aviso({
                    message: "Sem alteração",
                    description: "Nenhuma informação alterada para ser salva !"
                })
                return;
            }

            const registro = getRegistroDaTela();

            const resultado = await finalizarAtend(registro);

            if (resultado === false) return false;

            onRequestClose();

            return true;

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e, cdTransacao);

            return false;

        }

    }

    return (
        <ProForm
            formRef={formRef}
            isKeyPressSubmit={true}
            initialValues={fiValues}
            onFinish={async () => {
                await doSalvar();
            }}
            submitter={{
                // Completely customize the entire area
                render: (props, doms) => {
                    return null;
                }
            }}
            onValuesChange={async (changeValues, allValues) => {
            }}
            onFieldsChange={async (changedFields) => {
                conteudoAlterado.updateConteudoAlterado(changedFields);
            }}
        >
            <div className={"pro-form-group-spacer"}>
                <ProForm.Group>
                    <Row gutter={[8, 16]}>
                        <Col xs={24} md={12}>
                            <ComboBox
                                width={"xl"}
                                formRef={formRef}
                                name="cmbCdMotivoFimAtendimento"
                                label="Motivo"
                                tooltip="Motivo para finalizar este chamado"
                                placeholder={"Selecione um motivo de finalização"}
                                options={dataProvider.dpMotivoFimAtendimento}
                                rules={[
                                    {required: true, message: "Informe o motivo"},
                                ]}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <IY2BProFormFiller/>
                        </Col>
                    </Row>
                </ProForm.Group>
                <ProForm.Group>
                    <Row gutter={[8, 16]}>
                        <Col span={24}>
                            <ProFormTextArea
                                width={"xl"}
                                name="txResultado"
                                label="Justificativa"
                                placeholder="Descreva op motivo para finalizar o atendimento"
                                tooltip={"Descrição da necessidade de finalizar o atendimento"}
                                rules={[
                                    {required: true, message: "Informe a justificativa"},
                                    {type: "string", message: "No máximo 500 caracteres", max: 500}
                                ]}
                                fieldProps={{
                                    showCount: true,
                                }}
                            />
                        </Col>
                    </Row>
                </ProForm.Group>
            </div>
        </ProForm>
    );

});

export default FormFinalizarAtendimento;

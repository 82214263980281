import '../../../assets/css/tema.scss';
import './cidadesAtendidas.scss';

import React, {useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import IY2BReactModal from "../../../componentes/IY2BReactModal";
import {ProForm, ProFormSwitch} from "@ant-design/pro-components";
import {Button, Col, Descriptions, Row, Space} from "antd";
import IY2BProFormFiller from "../../../componentes/proform/IY2BProFormFiller/iy2bProFormFiller";
import RadioGroup from "../../../componentes/proform/RadioGroup/radioGroup";
import AutoComplete from "../../../componentes/proform/AutoComplete";
import {fetchUsuariosDaEmpresa, pesquisarCidade} from "../../../../lib/servicosPortalIY2B";
import IY2BHiddenRow from "../../../componentes/IY2BHiddenRow";
import IY2BProFormHidden from "../../../componentes/IY2BProFormHidden";
import {WORKSPACE_LOADING_INDICATOR_AREA, WorkspaceContext} from "../../../pages/Workspace/workspace";
import {
    notificacaoErroRequest,
    notificacaoErroValidForm,
    PADRAO_MARGEM_POPUP, ProjetoContext,
    useConteudoAlterado,
    useGridWidth,
    useModoExecucao, useStateRef,
    useWindowSize
} from "../../../lib/iy2b-react";
import {useFormState} from "../../../lib/useFormState";
import {notificacao} from "../../../lib/Notificacao";
import {PLUS_ICON_PRIMARY} from "../../../assets/iconografia";
import IY2BProFormButton from "../../../componentes/proform/IY2BProFormButton/proFormButton";
import IY2BCards from "../../../componentes/IY2BCards/iy2bCards";
import {campoInformado, dateTableCellRender, decimalTableCellRender, ObjectUtils} from "../../../lib/iy2b-javascript";
import IY2BCard, {
    buildCardCols,
    buildLabelCard,
    buildOrdenadores,
    defaultItemCardRender
} from "../../../componentes/IY2BCards/iy2bCard";
import BotaoCheckBox from "../../../componentes/BotaoCheckBox/botaoCheckBox";
import {TransacaoContentContext} from "../../../pages/TransacaoContent";
import DataProvider from "../../../lib/DataProvider";
import {postJSON} from "../../../lib/RequestAPI";
import BotaoExcluirRegistroTabela from "../../../componentes/BotaoExcluirRegistroTabela/botaoExcluirRegistroTabela";

const iconPlusCircle = PLUS_ICON_PRIMARY;

const PAGE_SIZE_RESULTADO = 1000;

const CidadesAtendidas = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {};
    });

    const {exibePopUp, onRequestClose, dataProvider, cdTransacao = 0} = props;

    const { cdPessoaEmpAtual } = props;

    const { availableWidth } = useContext(WorkspaceContext);

    const { getValue } = useContext(ProjetoContext);

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 10 : windowSize.widthMargin(PADRAO_MARGEM_POPUP.LG);
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = windowSize.heightMargin(PADRAO_MARGEM_POPUP.SM);
    const MARGIN_BOTTOM = windowSize.heightMargin(PADRAO_MARGEM_POPUP.SM); // 24;

    const modalRefApi = useRef();

    const formRef = useRef() ;
    const formState = useFormState({ });

    const modoExecucao = useModoExecucao("INC");
    const conteudoAlterado = useConteudoAlterado();

    const [, setRegistroDaTela, refRegistroDaTela] = useStateRef( null);
    const [, setTelaProtegida, refTelaProtegida] = useStateRef(false);

    const forceUpdate = useForceUpdate();

    const planoEhGratuito = getValue("planoEhGratuito", true);

    const tableRef = useRef();

    const { containerHeight } = useContext(TransacaoContentContext);

    const [dsResultado, setResultado, refDsResultado] = useStateRef(new DataProvider({pageSize: PAGE_SIZE_RESULTADO}));

    const labelCard= "noCidade" ;
    const cardColumns= 1;
    const orientationCard= "horizontal";

    const [tableHeight, setTableHeight, refTableHeight] = useStateRef(80);

    useEffect(() => {

        console.log("props.cdPessoaEmpAtual", props.cdPessoaEmpAtual)

        if(campoInformado(cdPessoaEmpAtual)) {
            if(exibePopUp) {
                pesquisaInicial();
            }
        }

        return () => {

        };

    }, [props.exibePopUp]);

    const controleExibicao = (registro) => {

    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();

        }, 1);

    }

    const novoRegistro = () => {

        formRef?.current?.resetFields();

        setRegistroDaTela ({

        });

        controleExibicao ( );

        modoExecucao.setModoExecInclusao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        console.log("getRegistroDaTela.ffValues", ffValues);

        const registro = {
            "cdCidade": ffValues.acCidade?.value,
        };

        console.log("getRegistroDaTela.registro", registro);

        return registro;

    }

    const validaTela = async (params) => {

        let resultado = true;

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        return resultado;

    };

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return;

            if(conteudoAlterado.isConteudoAlterado() === false) {
                notificacao.aviso({message:"Sem alteração", description:"Nenhuma informação alterada para ser salva !"})
                return;
            }

            const registro = getRegistroDaTela();

            const resultado = await vincularCidade (registro);

            if(resultado === true) {
                pesquisaInicial();
            }

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e, cdTransacao);

        }

    }

    const vincularCidade = async (registro) => {

        try {

            const payload = {
                acaoAExecutar: "vinculaCidade",
                cdPessoaImobiliaria: cdPessoaEmpAtual,
                cdCidade: registro.cdCidade
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "processarnegocio",
                sistema: "sli",
                projeto: "mimobiliaria"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            notificacao.sucesso({transacao: cdTransacao, message:"Sucesso!", description:"Cidade vinculada com sucesso!"})

            return true;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const excluir = async (registros) => {

        try {

            const payload = {
                acaoAExecutar: "desvinculaCidade",
                cdPessoaImobiliaria: cdPessoaEmpAtual,
                cdCidade: registros[0].cdCidade
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "processarnegocio",
                sistema: "sli",
                projeto: "mimobiliaria"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "ImobiliariaCidade") {

                    const novods = refDsResultado.current.remove([{key: msg.modeloPadrao.cdCidade}]).clone() ;

                    setResultado( novods ) ;

                } else {

                    console.log(msg);

                }

            });

            notificacao.sucesso({transacao:cdTransacao,message:"Sucesso!", description:"Atendimento da cidade excluído com sucesso!"})

            return true;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    };

    const actionView = (_, record) => {

        const botoes = [];

        botoes.push(<BotaoExcluirRegistroTabela
            key={"btnDelete" + record.key}
            registro={record}
            excluir={excluir}
            tooltip={"Excluir este registro"}
        />);

        return (
            <Space size={4} align="baseline" className={"resultado-action-view"}>
                {botoes}
            </Space>
        );

    };

    const calcTableHeight = () => {

        const cHeight = containerHeight();

        console.log("calcTableHeight", cHeight);

        setTableHeight(cHeight - 70 - 50);

    }

    const pesquisar = async () => {

        const filtro = {
        };

        const payload = {
            "acaoAExecutar": "pesqCidadeVinculadas",
            filtro: {
                cdPessoaImobiliaria: cdPessoaEmpAtual,
            },
        }

        try {

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sli",
                projeto: "mimobiliaria"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            let dp = new DataProvider({pageSize: PAGE_SIZE_RESULTADO});

            resultado.mensagens.forEach(msg => {

                if(msg.id === "listaImobiliariaCidade") {

                    const listaDP = msg.lista.map(item => {
                        item.key = item.cdCidade;
                        return item;
                    });

                    dp = new DataProvider({
                        data: listaDP,
                        total: listaDP.length,
                        page: 1,
                        pageSize: listaDP.length
                    });

                }

            });

            return dp;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return new DataProvider();

        }

    }

    const pesquisaInicial = async () => {

        novoRegistro();

        setResultado(new DataProvider({pageSize: PAGE_SIZE_RESULTADO}));

        tableRef.current?.clearData();

        await pesquisaPaginacao();

        // if(tableHeight === 80) calcTableHeight();

    }

    const pesquisaPaginacao = async () => {

        const dp = await pesquisar ();

        const newDP = refDsResultado.current.clone();

        newDP.addToInfinite(dp);

        setResultado(newDP);

        calcTableHeight();

        return newDP;

    }

    const tblCols = [
        {noColuna:'Cidade', field:'noCidade', width:300, colSpan:1, card:false},
        {noColuna:'Estado', field:'ufCidade', width:100, colSpan:1, card:true},
    ];

    const cardCols = buildCardCols({tblCols: tblCols});

    const labelCardCols = buildLabelCard({tblCols: tblCols, labelCard: labelCard});

    const localRenderItemCard = defaultItemCardRender({cardCols: cardCols, labelCard: labelCard, orientationCard: orientationCard, cardColumns: cardColumns});

    const ordenadores = buildOrdenadores({ cardCols: cardCols, labelCardCols: labelCardCols });

    const popupTitle = "Cidades atendidas";

    const fiValues = {
    };

    return (
        <ErrorBoundary>
            <IY2BReactModal
                left={MARGIN_LEFT}
                top={MODAL_CONTENT_TOP}
                width={MODAL_CONTENT_WIDTH}
                marginBottom={MARGIN_BOTTOM}
                exibePopUp={exibePopUp}
                onRequestClose={onRequestClose}
                bodyOpenClassName={"editar-cidades-atendidas-imob"}
                title={popupTitle}
                api={modalRefApi}
                body={
                    <div className={"body-editar-cidades-atendidas-imob"}>
                        <ProForm
                            formRef = { formRef }
                            isKeyPressSubmit = {true}
                            initialValues={fiValues}
                            onFinish = { async ( ) => {
                                if(refTelaProtegida.current === true) return;
                                await doSalvar();
                            }}
                            submitter={{
                                // Completely customize the entire area
                                render : ( props , doms ) => {
                                    return null;
                                }
                            }}
                            onValuesChange={async (changeValues, allValues) => {
                                /*
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "cmbCdTipoAtendimento", cmbCdTipoAtendimento_OnChange, true);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCliente_NoPessoa", acCliente_OnChange, true);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCliente_NrCGCCIC", acCliente_OnChange, true);

                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCentroCusto", acCentroCusto_OnChange);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "vlrDesconto", onUpdateVlrTotal);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "flgOrcamento", flgOrcamento_OnChange);
                                conteudoAlterado.fireOnChangeFFR(changeValues, allValues, "itens", "vlrOrcPecas", onUpdateVlrTotal);
                                conteudoAlterado.fireOnChangeFFR(changeValues, allValues, "itens", "vlrOrcServ", onUpdateVlrTotal);
                                */
                            }}
                            onFieldsChange = {async (changedFields) => {
                                conteudoAlterado.updateConteudoAlterado(changedFields);
                            }}
                        >
                            <div className={"pro-form-group-spacer"}>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={20} md={18}>
                                            <AutoComplete
                                                formRef={formRef}
                                                label={"Cidade"}
                                                name={"acCidade"}
                                                tooltip={"Cidade"}
                                                placeholder={"Informe uma cidade"}
                                                rules={[
                                                    {required: true, message: "Informe uma cidade"},
                                                ]}
                                                width={"xl"}
                                                fetchOptions={pesquisarCidade}
                                            />
                                        </Col>
                                        <Col xs={{span:12, offset:12}} md={{span:6, offset:0}}>
                                            <IY2BProFormButton
                                                type={"primary"}
                                                width={"lg"}
                                                label={"Adicionar"}
                                                icon={iconPlusCircle}
                                                onClick={() => doSalvar()}
                                            />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col span={24}>
                                            <IY2BCards id={"cards-cidades-atendidas-imob" } name={"cards-cidades-atendidas-imob"}
                                                       renderItem={ item => localRenderItemCard(item, actionView) }
                                                       botaoAcoesDisponiveis={{
                                                           disabled: true
                                                       }}
                                                       enableRowCheck={false}
                                                       ref={tableRef}
                                                       dataSource={dsResultado}
                                                       ordenadores={ordenadores}
                                                       columns={cardCols}
                                                       tableHeight={tableHeight}
                                                       pagPageSize={PAGE_SIZE_RESULTADO}
                                                       onRequestPage = {async () => {
                                                           return await pesquisaPaginacao();
                                                       }}
                                                       downloadDisabled={planoEhGratuito}
                                                       configDisabled={planoEhGratuito}
                                                       infiniteScroll={true}
                                            />
                                        </Col>
                                    </Row>
                                <IY2BHiddenRow>
                                    <IY2BProFormHidden name={"xx"}/>
                                </IY2BHiddenRow>
                            </div>
                        </ProForm>
                    </div>
                }
            />
        </ErrorBoundary>
    );

});

export default CidadesAtendidas;


//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";
import {
    notificacaoErroRequest,
    notificacaoErroValidForm, PADRAO_MARGEM_POPUP,
    useConteudoAlterado,
    useGridWidth,
    useModoExecucao,
    useWindowSize
} from "../../lib/iy2b-react";
import React, {useEffect, useRef, useState} from "react";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {ObjectUtils} from "../../lib/iy2b-javascript";
import {Button, Col, notification, Row, Space} from "antd";
import IY2BReactModal from "../IY2BReactModal";
import {ProForm, ProFormDigit, ProFormText, ProFormTextArea} from "@ant-design/pro-components";
import ComboBox from "../proform/ComboBox/comboBox";
import IY2BHiddenRow from "../IY2BHiddenRow";
import IY2BProFormHidden from "../IY2BProFormHidden";
import {postJSON} from "../../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";
import {notificacao} from "../../lib/Notificacao";


const NovoItemChecklist = ( props ) => {

    const { exibePopUp, cdPessoaEmpAtual, cdChecklist, onRequestClose, salvar, cdTransacao = 0  } = props;

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 10 : windowSize.widthMargin(PADRAO_MARGEM_POPUP.XL);
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = windowSize.heightMargin(PADRAO_MARGEM_POPUP.XL);

    const modalRefApi = useRef();
    const formRef = useRef();

    const forceUpdate = useForceUpdate();

    const modoExecucao = useModoExecucao("INC");
    const conteudoAlterado = useConteudoAlterado();

    const noItemRef = useRef();
    const cmbTarefaChecklistRefApi = useRef();
    const cmbNrSeqPaiRefApi = useRef();

    const [dataProvider, setDataProvider] = useState({ });

    useEffect(() => {

        if(exibePopUp === true) {

            setTimeout(() => {

                inicializaProjeto ();

            }, 0);

        }

        return () => {

        };

    }, [props.exibePopUp]);

    const inicializaProjeto = async () => {

        const payload = {
            "acaoAExecutar": "inicializaProjeto",
            cdPessoaEmp: cdPessoaEmpAtual,
            cdChecklist: cdChecklist
        }

        try {

            const dataProvider = { } ;

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sgr",
                projeto: "mnovoitemchecklist"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "listaTarefaChecklist") {

                    dataProvider.dpTarefaChecklist = msg.lista.map(item => {
                        return {
                            value: item.cdTarefaChecklist,
                            label: item.noTarefaChecklist,
                            data: {...item}
                        }
                    });

                } else if (msg.id === "listaItemChecklistPai") {

                    dataProvider.dpItemChecklistPai = msg.lista.map(item => {
                        return {
                            value: item.nrSeq,
                            label: item.noItem,
                            data: {...item}
                        }
                    });

                } else {

                    console.log(msg);

                }

            });

            setDataProvider(dataProvider);

            novoRegistro();

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

        return true;

    };

    const controleExibicao = (registro) => {


    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();

            noItemRef.current.focus();

        }, 1);

    }

    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        const attrDaTela = [ "noItem", "nrOrdem", "txObs" ];

        const registro = {
            cdPessoaEmp: cdPessoaEmpAtual,
            cdChecklist: cdChecklist,
            ...ObjectUtils.valuesFrom(ffValues, attrDaTela),
            cdTarefaChecklist: ffValues.cmbTarefaChecklist?.value,
            nrSeqPai: ffValues.cmbNrSeqPai?.value,
        };

        console.log(registro);

        return registro;

    }

    const novoRegistro = () => {

        formRef?.current?.resetFields();

        controleExibicao ( );

        modoExecucao.setModoExecInclusao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const validaTela = async (params) => {

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        return true;

    };

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return;

            if(conteudoAlterado.isConteudoAlterado() === false) {
                notificacao.aviso({message:"Sem alteração", description:"Nenhuma informação alterada para ser salva !"})
                return;
            }

            const registro = getRegistroDaTela();

            const respSalvar = await salvar (registro);

            if(respSalvar === false) return;

            onRequestClose();

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e, cdTransacao);

        }

    }

    const popupTitle = "Novo item do checklist" ;

    const ffValues = formRef?.current?.getFieldsValue();

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={windowSize.heightMargin(PADRAO_MARGEM_POPUP.LG)}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"novo-item-checklist-pessoa"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-novo-item-checklist-pessoa"}>
                    <ProForm
                        formRef = { formRef }
                        isKeyPressSubmit = {true}
                        initialValues={{
                        }}
                        onFinish = { async ( ) => {
                            await doSalvar();
                        }}
                        submitter={{
                            // Completely customize the entire area
                            render : ( props , doms ) => {
                                return null;
                            }
                        }}
                        onValuesChange={async (changeValues, allValues) => {

                        }}
                        onFieldsChange = {(changedFields, allFields) => {
                            conteudoAlterado.updateConteudoAlterado(changedFields);
                        }}
                    >
                        <div className={"pro-form-group-spacer"}>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={24} md={16} lg={18}>
                                        <ProFormTextArea
                                            width={"xl"}
                                            name = "noItem"
                                            label = "Descrição"
                                            tooltip = "Descrição deste item do checklist"
                                            placeholder = "Digite a descrição deste item do checklist"
                                            rules={[
                                                {required: true, message: 'Informe a descrição deste tipo de checklist'},
                                                {message:"No máximo 200 caracteres", max:200}
                                            ]}
                                            fieldProps={{
                                                showCount: true,
                                                ref: noItemRef
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} md={8} lg={6}>
                                        <ProFormDigit
                                            width={"lg"}
                                            label="Ordem"
                                            name="nrOrdem"
                                            placeholder = "Ordem de execução"
                                            tooltip = { "Ordem de execução deste item na sequencia do checklist" }
                                            fieldProps={{ precision: 0 }}
                                            rules={[
                                                {required: true, message:"Informe a ordem de execução deste item no checkclist"},
                                                {type: "integer", min:1, max:9999, message:"Número máximo permitido 9999"}
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Group>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={24} md={12} >
                                        <ComboBox
                                            width={"xl"}
                                            formRef = { formRef }
                                            api = { cmbTarefaChecklistRefApi }
                                            name = "cmbTarefaChecklist"
                                            label = "Tarefa"
                                            tooltip = "Selecione a tarefa para executar neste item do checklist"
                                            placeholder={"Selecione a tarefa para executar"}
                                            options={dataProvider.dpTarefaChecklist}
                                        />
                                    </Col>
                                    <Col xs={24} md={12} >
                                        <ComboBox
                                            width={"xl"}
                                            formRef = { formRef }
                                            api = { cmbNrSeqPaiRefApi }
                                            name = "cmbNrSeqPai"
                                            label = "Agrupador"
                                            tooltip = "Selecione o agrupador de tarefas"
                                            placeholder={"Selecione o agrupador de tarefas"}
                                            options={dataProvider.dpItemChecklistPai}
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Group>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={24} md={16} lg={18}  >
                                        <ProFormTextArea
                                            width={"xl"}
                                            name = "txObs"
                                            label = "Comentários"
                                            tooltip = "Comentários e/ou observações para executar este item"
                                            placeholder = "Digite comentários e/ou observações para executar este item"
                                            rules={[
                                                {message:"No máximo 200 caracteres", max:200}
                                            ]}
                                        />
                                    </Col>
                                    <Col xs={24} md={8} lg={6}>
                                        <ProFormText
                                            width={"lg"}
                                            name = "nrSeq"
                                            label = "Sequência"
                                            tooltip = "Número de sequência deste item do checklist"
                                            placeholder = "Sequência"
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Group>
                            <IY2BHiddenRow>
                                <IY2BProFormHidden name={"x"}/>
                            </IY2BHiddenRow>
                        </div>
                    </ProForm>
                </div>
            }
            actions={
                <Space style={{
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <Space>
                        &nbsp;
                    </Space>
                    <Space>
                        &nbsp;
                        <Button type={"primary"} className={"salvar"} onClick={async () => await doSalvar()}>
                            Salvar
                        </Button>
                    </Space>
                </Space>
            }
        />
    );

}

export default NovoItemChecklist ;

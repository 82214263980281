//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';

import '../../framework/assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useRef, useState} from 'react';
import {ProForm, ProFormText} from "@ant-design/pro-components";
import {Col, Row} from "antd";
import FormEndereco from "../../framework/componentes/FormEndereco";
import {campoInformado, campoInformadoString, cpfValido, ObjectUtils} from "../../framework/lib/iy2b-javascript";
import {postWS} from "../../framework/lib/RequestAPI";
import {useStateRef} from "../../framework/lib/iy2b-react";
import IY2BProFormMasked from "../../framework/componentes/IY2BProFormMasked";
import {
    logoutProvedorIdentidade,
    provedoresIdentidadeDisponiveis,
    signInWithGoogle,
    signInWithIdProvider
} from "../../framework/lib/firebase";
import IY2BHiddenRow from "../../framework/componentes/IY2BHiddenRow";
import IY2BProFormHidden from "../../framework/componentes/IY2BProFormHidden";
import BotaoProvedorIdentidade from "../../framework/componentes/provedoresIdentidade/botaoProvedorIdentidade";
import {notificacao} from "../../framework/lib/Notificacao";

const StepCadUsuario = props => {

    const formEnderecoApi = useRef();
    const inputRefCPF = useRef();
    const inputRefTelCel = useRef();

    const [primeiraExibicao, setPrimeiraExibicao, refPrimeiraExibicao] = useStateRef(true);

    const [provedoresId, setProvedoresId] = useState([]);

    const [usuarioJaExiste, setUsuarioJaExiste, refUsuarioJaExiste] = useStateRef(false);

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        if(props.api) {
            props.api.current = {
                onValuesChange: onValuesChange,
                onPageShow: onPageShow,
                onFinish: onFinish
            };
        }

        const cookieCadUsr = props.cookies.get("cadUsr");

        if(campoInformado(cookieCadUsr)) {
            props.formRef.current?.setFieldsValue(cookieCadUsr);
        }

        inicializaProvedoresId();

        return () => {

            console.log("final useEffect inicializa StepCadEmpresa");

        };

    }, []);

    const inicializaProvedoresId = () => {

        const ffValues = props.formRef.current?.getFieldsValue();

        const jaExisteAlgumVinculado = campoInformado(ffValues.cdProvedorIdentidade);

        setProvedoresId(provedoresIdentidadeDisponiveis.map(item => {

            const jaVinculado = (ffValues.cdProvedorIdentidade === item.cdProvedorIdentidade);

            const bloquear = (jaExisteAlgumVinculado===false)?false
                :(jaVinculado===true)?false:true;

            const botao =
                    <BotaoProvedorIdentidade
                        onClickVincular={btnLoginWithProviderId}
                        onClickDesvincular={btnDesvincularProvId}
                        block={true}
                        cdProvedorIdentidade={item.cdProvedorIdentidade}
                        jaVinculado={jaVinculado}
                        disabled={bloquear}
                    />
            ;

            return {
                ...item,
                botao: botao
            }

        }));

    }

    const [protegeInfoCertif, setProtegeInfoCertif, refProtegeInfoCertif] = useStateRef(false);

    useEffect(() => {

        if(props.certificado?.flgJuridico === "F") {

            setProtegeInfoCertif ( true );

            const newValue = {
                noUsuario:props.certificado?.noPessoa,
                nrCPF: props.certificado?.nrDocto
            };

            props.formRef.current?.setFieldsValue(newValue);

        } else {

            setProtegeInfoCertif ( false );

        }

    }, [props.certificado]);

    const validarEMail = async () => {

        const all = props.formRef.current?.getFieldsValue();

        console.log("validarEMail.all", all);

        const payload = {
            cdPessoaJaExiste: all.cdPessoaUsrJaExiste,
            noEMail: all.noEMail || all.noEmail,
            enviarEmailVerificacao: "S",
            noContratante: all.noUsuario
        };

        let valido = true;

        const resposta = await postWS({
            sistema: "sgr",
            projeto: "mwizcontratacaobuild",
            acaoWebAExecutar: "pesquisar",
            acaoAExecutar: "pesquisaNoEmail"
        }, payload);

        resposta.mensagens.forEach(msg => {

            if(msg.id === "usuario") {

                valido = false;
                notificacao.erro({errorId:"emailJaCadastrado", message:"Ja é cliente", description:"Este email ja esta cadastrado como usuário da plataforma. Por favor faça o login para acessar o sistema."});

            } else if (msg.id === "token" ) {

                props.onTokenEmail ( msg.mensagem ) ;

            } else {

                console.log(msg);

            }

        });

        return valido;

    }

    const onFinish = async () => {

        let resultado = true;

        resultado = await validarEMail();

        return resultado;

    }

    const onPageShow = async () => {

        if(refPrimeiraExibicao.current === true) {

        }

        setPrimeiraExibicao(false);

    }

    const onValuesChange = async (changed, all) => {

        formEnderecoApi.current?.onValuesChange(changed, all);

    }

    const limpaCampos = () => {

        console.log("StepCadUsuario.limpaCampos");

        const newValue = {
            cdPessoaUsrJaExiste: null,
            noUsuario: null,
            noEMail: null,
            nrTelCelular: null,
        };

        props.formRef.current?.setFieldsValue(newValue);
        formEnderecoApi.current.limpaCEP();
        formEnderecoApi.current.setFieldsValue({
            nrSeqEnd: null,
        });
        formEnderecoApi.current.setProtegeTela(false);
        setUsuarioJaExiste(false);

    }

    const cpfPatterns = [{
        pattern: "000.000.000-00",
        length: 11, // sem mascara
        validator: async (value) => {

            if(refProtegeInfoCertif.current === true) {
                return {valido: true, message: "CPF importado do certificado digital", clearValue: false};
            }

            if(value.length === 11) {
                if(cpfValido(value) === false) {
                    return {valido:false, message:"CPF informado é inválido !"};
                }
            } else {
                return {valido:false, message:"CPF informado é inválido !"};
            }

            const payload = {
                nrCGCCIC: value,
                "tipoPesq": "usr"
            }

            let valido = true;
            let msgValido = null;
            let pessoaJaExiste = false;
            let dadosEndereco = null;

            const resposta = await postWS({
                sistema: "sgr",
                projeto: "mwizcontratacaobuild",
                acaoWebAExecutar: "pesquisar",
                acaoAExecutar: "pesquisaNrCGCCIC"
            }, payload);

            resposta.mensagens.forEach(msg => {

                if(msg.id === "pessoa") {
                    pessoaJaExiste = true;
                    //
                    const newValue = {
                        cdPessoaUsrJaExiste: msg.modeloPadrao.cdPessoa,
                        noUsuario: msg.modeloPadrao.noPessoa,
                        noEMail: msg.modeloPadrao.noEmail,
                        nrTelCelular: msg.modeloPadrao.nrTelCelular,
                    };

                    props.formRef.current?.setFieldsValue(newValue);

                    dadosEndereco = {
                        ...ObjectUtils.valuesFrom(msg.modeloPadrao, ["nrSeqEnd", "nrCep", "txNumero", "txCompl"]),
                    };

                }

            });

            if( pessoaJaExiste === false) {
                limpaCampos();
            } else {
                const cepExiste = await formEnderecoApi.current.preencherCEP(dadosEndereco.nrCep);
                if(cepExiste === true) {
                    formEnderecoApi.current.setFieldsValue({
                        nrSeqEnd: dadosEndereco.nrSeqEnd,
                        txNumero: dadosEndereco.txNumero,
                        txComplEnd: dadosEndereco.txCompl
                    });
                    formEnderecoApi.current.setProtegeTela(true);
                }
                setUsuarioJaExiste(true);
            }

            return {valido: valido, message: msgValido, clearValue: (valido===false)};
        }
    }];

    const telCelPatterns = [{
        pattern: "(00) 0000-0000",
        length: 10, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }, {
        pattern: "(00) 0-0000-0000",
        length: 11, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }]

    const protegeDados = (refUsuarioJaExiste?.current === true);

    console.log("StepCadUsuario.protegeInfoCertif", protegeInfoCertif, (props.certificado?.flgJuridico === "F"));

    const btnDesvincularProvId = async (params) => {

        await logoutProvedorIdentidade();

        props.formRef.current?.setFieldsValue({
            noEmailProvId: null,
            chUId: null,
            cdProvedorIdentidade: null
        });

        setTimeout(() => {

            inicializaProvedoresId();

        }, 1);

        notificacao.sucesso({message:"Sucesso!", description:"Desvinculamos a sua conta escolhida com sucesso."});

        return true;

    }

    const btnLoginWithProviderId = async (params) => {

        try {

            await logoutProvedorIdentidade();

            const user = await signInWithIdProvider(params.cdProvedorIdentidade);

            if(user !== null) {

                console.log(params.cdProvedorIdentidade, user);

                props.formRef.current?.setFieldsValue({
                    noUsuario: user.displayName,
                    noEMail: user.email,
                    noEmailProvId: user.email,
                    chUId: user.uid,
                    cdProvedorIdentidade: params.cdProvedorIdentidade
                });

                setTimeout(() => {

                    inicializaProvedoresId();

                }, 1);

                notificacao.sucesso({message:"Sucesso!", description:"Identificamos a sua conta escolhida com sucesso."});

                return true;

            } else {

                notificacao.erro({errorId:"emailLoginProv", message:"Opps!!!", description:"Não foi possível confirmar o login com a conta escolhida"});

                return false;

            }

        } catch (e) {

            console.error(e);

            return false;

        }

    }

    const provedoresDeIdentidade =
        <div className={"box-login-provedores"}>
            <Row gutter={[8, 16]}>
                <Col xs={24}>
                    <h4>Provedores de identidade disponíveis para vincular</h4>
                </Col>
            </Row>
            <Row gutter={[8, 16]}>
                {provedoresId.map(item => {
                    return (
                        <Col xs={24} key={"col-" + item.cdProvedorIdentidade} style={{marginTop: 6}}>
                            {item.botao}
                        </Col>
                    );
                })}
            </Row>
        </div>
    ;

    return (
        <div className={"containerStepCadUsuario"}>
            <Row gutter={[8,16]}>
                <Col xs={{span:24}} md={{span:16}} lg={{span:18}}>
                    <Row gutter={[8,16]}>
                        <Col xs={{span:18}} md={{span:24}}>
                            <ProForm.Group>
                                <IY2BProFormMasked
                                    width={"sm"}
                                    label={"CPF"}
                                    name="nrCPF"
                                    tooltip = "CPF do usuario"
                                    rules={[{ required: true, message: 'Por favor, informe o seu CPF!' }, {
                                        validator: async (rule, value) => {
                                            if(inputRefCPF) {
                                                const result = await inputRefCPF.current.validate();
                                                if(result.valido === false) throw new Error(result.message);
                                            }
                                            if(campoInformadoString(value) === false) {
                                                limpaCampos();
                                            }
                                        }
                                    }]}
                                    api={inputRefCPF}
                                    patterns={cpfPatterns}
                                    disabled={protegeInfoCertif}
                                />
                                <ProFormText
                                    width={"lg"}
                                    name = "noUsuario"
                                    label = "Nome do usuário"
                                    placeholder={"Digite o seu nome"}
                                    tooltip = "Nome do usuário contratante"
                                    rules={[ {required: true, message:"Informe o seu nome completo"}, {message:"No máximo 60 caracteres", max:60}]}
                                    disabled={protegeInfoCertif || protegeDados}
                                />
                            </ProForm.Group>
                        </Col>
                    </Row>
                    <Row gutter={[8,16]}>
                        <Col xs={{span:18}} md={{span:24}}>
                            <ProForm.Group>
                                <ProFormText
                                    width={"lg"}
                                    name = "noEMail"
                                    label = "E-Mail"
                                    tooltip = "Infome o seu email de contato"
                                    placeholder={"Digite o seu email de contato"}
                                    rules={[
                                        {required: true, message:"Informe o seu email"},
                                        {message:"Informe um email válido", type:"email"},
                                        {message:"No máximo 60 caracteres", max:60}
                                    ]}
                                    disabled={protegeDados}
                                />
                                <IY2BProFormMasked
                                    width={"sm"}
                                    label={"Telefone celular"}
                                    name="nrTelCelular"
                                    tooltip = "Nro. telefone celular do usuario"
                                    rules={[{ required: true, message: 'Por favor, informe o seu nro de telefone celular !' }, {
                                        validator: async (rule, value) => {
                                            if(inputRefTelCel) {
                                                const result = await inputRefTelCel.current.validate();
                                                if(result.valido === false) throw new Error(result.message);
                                            }
                                            return true;
                                        }
                                    }]}
                                    api={inputRefTelCel}
                                    patterns={telCelPatterns}
                                    disabled={protegeDados}
                                />
                            </ProForm.Group>
                        </Col>
                    </Row>
                </Col>
                <Col xs={{span:24}} md={{span:8}} lg={{span:6}}>
                    {(protegeDados===true)?null:provedoresDeIdentidade}
                </Col>
            </Row>
            <IY2BHiddenRow>
                <IY2BProFormHidden name={"cdProvedorIdentidade"}/>
                <IY2BProFormHidden name={"chUId"}/>
                <IY2BProFormHidden name={"noEmailProvId"}/>
                <IY2BProFormHidden name={"cdPessoaUsrJaExiste"}/>
            </IY2BHiddenRow>
            <FormEndereco
                formRef = { props.formRef }
                api = { formEnderecoApi }
            />
        </div>
    );

}


export default StepCadUsuario;

export default class DataProvider  {

    constructor(args) {

        if(args instanceof DataProvider) {

            this._array = args.data.clone();
            this._total = args.total;
            this._page = args.page;
            this._pageSize = args.pageSize;

        } else {

            const { data= [], total= null, page= null, pageSize= null } = args || { };

            this._array = data.clone();
            this._total = total || data.length;
            this._page = page || 1;
            this._pageSize = pageSize || data.length;

        }

    }

    get hasNextPage () {
        return (this._array.length < this._total);
    }

    addToInfinite (dp) {

        if(dp.data.length > 0) {
            this._array.addAll(dp.data);
            this._page = dp.page;
            if(this._total === 0) {
                this._total = dp.total;
            }
        }

    }

    toArray () {
        return this._array.clone();
    }

    clone () {
        return new DataProvider( this );
    }

    clear () {
        this._array.clear();
        this._total = 0;
        this._page = 1;
    }

    add (item) {

        this._total += 1;

//        if(this._array.length >= this._pageSize) {
//            this._page += 1;
//            this._array.clear();
//        }

        this._array.push(item);

        return this;
    }

    push (item) {
        return this.add(item);
    }

    find (item) {

        const finded = this._array.findValue(item);

        if(finded.length === 0) return null;

        return finded[0];

    }

    indexOf (item) {

        const finded = this._array.findValue(item);

        if(finded.length === 0) return -1;

        return this._array.indexOf( finded[0] );

    }

    getItemAt (index) {
        return this._array[index];
    }

    removeItem (item) {

        this._total -= 1;

        this._array.removeItem(item);

        return this;

    }

    remove (lista) {

        lista.forEach(item => {

            const todelete = this._array.findValue(item);

            if(todelete.length > 0) {

                this.removeItem(todelete[0]);

            }

        });

        return this;

    }

    desmarca () {

        const marcados = this._array.filter(item => item.marcado === true);

        marcados.forEach(item => item.marcado = false);

    }

    filter (fn) {

        return this._array.filter(fn);

    }

    forEach (fn) {

        this._array.forEach(fn);

    }

    isEmpty () {
        return (this._total === 0);
    }

    getBy (property, value) {

        const encontrados = this.filter(item => (item[property] === value));

        const resultado = (encontrados.length>0)?encontrados[0]:null;

        return resultado;

    };

    sort (compareFunction) {

        this._array.sort(compareFunction);

    }

    get marcados () {
        const marcados = this._array.filter(item => item.marcado === true);
        return marcados;
    }

    get total () {
        return this._total;
    }

    get data () {
        return this._array;
    }

    get page () {
        return this._page;
    }

    get pageSize () {
        return this._pageSize;
    }

}

import React, {useEffect, useRef, useState} from "react";
import {getSelection, hasSelection, keyPressPropName, setSelection} from "../lib/InputHelpers";
import {Input} from "antd";
import StringMask from "string-mask";
import {campoInformado} from "../lib/iy2b-javascript";

const transformValue = (value) => {
    let ignoraZero = true;
    const newValue = value.replaceAll(".", "").replace(",", "").split('').filter((c, i) => {
        if(c==='0') {
            if(i===0 && value==="0") {
                return true;
            }
            if(ignoraZero) {
                return false;
            }
            return true;
        } else {
            ignoraZero=false;
            return true;
        }
    }).join('');
    return newValue;
}

const InputDecimal = ( props ) => {

    const {precision = 2, type = "decimal", symbol = "R$", width, onChangeInput=null } = props;

    const inputRef = useRef();
    const [previousValue, setPreviousValue] = useState(null);
    const [value, setValue] = useState(null);

    const numberFormatOptions = {
        style: type,
        useGrouping: true,
        minimumFractionDigits: precision
    };

    if(type==="currency") {
        numberFormatOptions.currencyDisplay = symbol || "R$";
    }

    const floatFormatter = new Intl.NumberFormat('en-US', {
        style: "decimal",
        useGrouping: false,
        minimumFractionDigits: precision
    });

    const precisionMask = "000000000000000000000000000000".substring(0, precision);

    const valueFormatter = new StringMask('#.##0,' + precisionMask, {reverse: true});

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                focus: inputRef.current.focus,
                blur: inputRef.current.blur,
                decimalValue: () => {
                    const value = inputRef.current.input.value;
                    const newValue = (value===null||value===undefined||value==="") ? 0 : parseFloat(value.replaceAll(".", "").replace(",", "."));
                    const resultado = parseFloat(floatFormatter.format(newValue));
                    return resultado;
                }
            };
        }

        inputRef.current.input.classList[ 'add' ]('iy2b-ant-input-decimal');

        return () => {

        };

    }, []);

    const updateFromValue = (value) => {

        const incomingValue = value || "";

        if(incomingValue !== previousValue) {

            setInputValue(incomingValue);

        }

    }

    useEffect(() => {

        updateFromValue(props.defaultValue);

    }, [props.defaultValue]);

    useEffect(() => {

        if(inputRef.current?.input.value !== props.value) {

            updateFromValue(props.value);

        }

    }, [props.value]);

    const _getDisplayValue = (value) => {
        if(value === null) return "";
        if(value === undefined) return "";
        if(value === "") return "";
        const resultado = ((type==="currency")?symbol:"") + valueFormatter.apply(transformValue(value));
        return resultado;
    }

    const setInputValue = (value) => {
        inputRef.current.input.value = value;
        setValue(value);
    };

    const dispatchOnChange = (e, incomingValue) => {
        if (props.hasOwnProperty("onChange")) {
            if(previousValue != incomingValue) {
                setPreviousValue(incomingValue);
                props.onChange(e);
                if(onChangeInput !== null) onChangeInput(e);
            }
        }
    }

    const onInputChange = (e, newValue) => {

        setTimeout(() => {
            inputRef.current.input.classList[inputRef.current.input.value ? 'add' : 'remove']('has-value');
        }, 100);

        const incomingValue = newValue || e.target.value;

        setInputValue(_getDisplayValue(incomingValue));

        dispatchOnChange(e, incomingValue);

    }

    const onInputPaste = (e) => {

        const incomingValue = e.clipboardData.getData('Text');

        e.preventDefault();

        onInputChange(e, incomingValue);

    }

    const onInputKeyPress = (e) => {

        if (e.metaKey || e.altKey || e.ctrlKey || e.key === 'Enter') {
            return;
        }

        const key = (e.key || e.data);

        const currSelection = hasSelection(inputRef.current.input);

        const incomingValue = (currSelection===true) ? key : e.target.value + key;

        e.preventDefault();

        onInputChange(e, incomingValue);

    }

    const onFocus = (e) => {

        // console.log(e.target.value, inputRef.current.input.value);

        if(campoInformado(inputRef?.current?.input?.value) === true) {

            const end = inputRef?.current?.input?.value.length;

            setSelection(inputRef.current.input, {start: 0, end: end});

        }

    }

    const getInputProps = () => {

        const inputProps = {
            onChange: onInputChange,
            onPaste: onInputPaste,
            onFocus: onFocus
        };

        inputProps[keyPressPropName()] = onInputKeyPress;

        const { onPressEnter, className, placeholder, allowClear } = props;

        const resultProps = {
            onPressEnter, className, placeholder, allowClear,
            ...inputProps };

        return resultProps;

    }

    const contentClassName = ["pro-field"];

    contentClassName.push("pro-field-" + width);

    const { disabled=false, ffrDisabled=undefined } = props;

    const [localDisabled, setLocalDisabled] = useState(disabled);

    useEffect(() => {

        if(props.hasOwnProperty("ffrDisabled")===false) {
            setLocalDisabled(disabled);
        } else {
            if(ffrDisabled === undefined) {
                setLocalDisabled(disabled);
            }
        }

        return () => {

        };

    }, [disabled]);

    if(props.hasOwnProperty("ffrDisabled")===true) {
        if(ffrDisabled != undefined) {
            const ffrDisabledValue = ffrDisabled();
            if(ffrDisabledValue !== localDisabled) setLocalDisabled(ffrDisabledValue);
        }
    }

    return (
        <div className={contentClassName.toClassName()}>
            <Input type={"text"} ref={inputRef} {...getInputProps()} value={value} disabled={localDisabled} />
        </div>
    );

}

export default InputDecimal;

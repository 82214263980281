import '../../../assets/css/tema.scss';
import './cidadesAtendidas.scss';

import React, {useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import IY2BReactModal from "../../../componentes/IY2BReactModal";
import {PLUS_ICON_PRIMARY} from "../../../assets/iconografia";
import {WORKSPACE_LOADING_INDICATOR_AREA, WorkspaceContext} from "../../../pages/Workspace/workspace";
import {
    notificacaoErroRequest,
    notificacaoErroValidForm,
    PADRAO_MARGEM_POPUP,
    ProjetoContext, useConteudoAlterado,
    useGridWidth,
    useModoExecucao, useStateRef,
    useWindowSize
} from "../../../lib/iy2b-react";
import {useFormState} from "../../../lib/useFormState";
import {TransacaoContentContext} from "../../../pages/TransacaoContent";
import DataProvider from "../../../lib/DataProvider";
import {ProForm, ProFormText} from "@ant-design/pro-components";
import {Button, Col, Row, Space} from "antd";
import AutoComplete from "../../../componentes/proform/AutoComplete";
import {pesquisarCidade} from "../../../../lib/servicosPortalIY2B";
import IY2BProFormButton from "../../../componentes/proform/IY2BProFormButton/proFormButton";
import IY2BCards from "../../../componentes/IY2BCards/iy2bCards";
import IY2BHiddenRow from "../../../componentes/IY2BHiddenRow";
import IY2BProFormHidden from "../../../componentes/IY2BProFormHidden";
import {
    campoInformado,
    campoInformadoString,
    cpfValido,
    formatadorCpfCnpj,
    ObjectUtils
} from "../../../lib/iy2b-javascript";
import IY2BProFormMasked from "../../../componentes/IY2BProFormMasked";
import {postJSON, postWS} from "../../../lib/RequestAPI";
import {notificacao} from "../../../lib/Notificacao";
import BotaoExcluirRegistroTabela from "../../../componentes/BotaoExcluirRegistroTabela/botaoExcluirRegistroTabela";
import {
    buildCardCols,
    buildLabelCard,
    buildOrdenadores,
    defaultItemCardRender
} from "../../../componentes/IY2BCards/iy2bCard";
import IY2BProFormFiller from "../../../componentes/proform/IY2BProFormFiller/iy2bProFormFiller";
import BotaoEditarRegistroTabela from "../../../componentes/BotaoEditarRegistroTabela/botaoEditarRegistroTabela";

const iconPlusCircle = PLUS_ICON_PRIMARY;

const PAGE_SIZE_RESULTADO = 1000;

const RepresLegais = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {};
    });

    const {exibePopUp, onRequestClose, dataProvider, cdTransacao = 0} = props;

    const {cdPessoaEmpAtual} = props;

    const {availableWidth} = useContext(WorkspaceContext);

    const {getValue} = useContext(ProjetoContext);

    const windowSize = useWindowSize();
    const [, gridWidth] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_) <= 0) ? 10 : windowSize.widthMargin(PADRAO_MARGEM_POPUP.LG);
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT * 2));
    const MODAL_CONTENT_TOP = windowSize.heightMargin(PADRAO_MARGEM_POPUP.SM);
    const MARGIN_BOTTOM = windowSize.heightMargin(PADRAO_MARGEM_POPUP.SM); // 24;

    const modalRefApi = useRef();

    const formRef = useRef();
    const formState = useFormState({});

    const modoExecucao = useModoExecucao("INC");
    const conteudoAlterado = useConteudoAlterado();

    const [, setRegistroDaTela, refRegistroDaTela] = useStateRef(null);
    const [, setTelaProtegida, refTelaProtegida] = useStateRef(false);

    const forceUpdate = useForceUpdate();

    const planoEhGratuito = getValue("planoEhGratuito", true);

    const tableRef = useRef();

    const {containerHeight} = useContext(TransacaoContentContext);

    const [dsResultado, setResultado, refDsResultado] = useStateRef(new DataProvider({pageSize: PAGE_SIZE_RESULTADO}));

    const labelCard= "noPessoaRepLegal" ;
    const cardColumns= 1;
    const orientationCard= "horizontal";

    const [tableHeight, setTableHeight, refTableHeight] = useStateRef(80);

    const inputRefCPF = useRef();
    const inputRefTelCel = useRef();


    useEffect(() => {

        console.log("props.cdPessoaEmpAtual", props.cdPessoaEmpAtual)

        if(campoInformado(cdPessoaEmpAtual)) {
            if(exibePopUp) {
                pesquisaInicial();
            }
        }

        return () => {

        };

    }, [props.exibePopUp]);

    const controleExibicao = (registro) => {

    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();

        }, 1);

    }

    const novoRegistro = () => {

        formRef?.current?.resetFields();

        setRegistroDaTela ({

        });

        controleExibicao ( );

        modoExecucao.setModoExecInclusao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const setRegistroNaTela = (registro) => {

        console.log("setRegistroNaTela", registro);

        /*
        cmbCdPessoaBidDefault
         */

        const attrsTela = [
            "cdPessoaRepLegal",
            "cdRepLegal",
            "flgOk",
            "noEMail",
            "noPessoaRepLegal",
            "nrCGCCICRepLegal",
            "nrTelCelular"
        ];

        const registroDaTela = {
            ...ObjectUtils.valuesFrom(registro, attrsTela),
        };

        setRegistroDaTela ( registroDaTela );

        formRef.current?.setFieldsValue( registroDaTela );

        forceUpdate();

        controleExibicao ( registroDaTela );

        modoExecucao.setModoExecAlteracao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    };

    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        console.log("getRegistroDaTela.ffValues", ffValues);

        const attrsTela = [
            "cdPessoaRepLegal",
            "cdRepLegal",
            "flgOk",
            "noEMail",
            "noPessoaRepLegal",
            "nrCGCCICRepLegal",
            "nrTelCelular"
        ];

        const registro = {
            ...ObjectUtils.valuesFrom(ffValues, attrsTela),
            cdPessoaEmp: cdPessoaEmpAtual
        };

        console.log("getRegistroDaTela.registro", registro);

        return registro;

    }

    const validaTela = async (params) => {

        let resultado = true;

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        return resultado;

    };

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return;

            if(conteudoAlterado.isConteudoAlterado() === false) {
                notificacao.aviso({message:"Sem alteração", description:"Nenhuma informação alterada para ser salva !"})
                return;
            }

            const registro = getRegistroDaTela();

            const resultado = await salvar (registro);

            if(resultado === true) {
                pesquisaInicial();
            }

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e, cdTransacao);

        }

    }

    const salvar = async (registro) => {

        try {

            const acaoWebAExecutar = campoInformado(registro.cdPessoaRepLegal)?"alterar":"incluir";

            const payload = {
                acaoAExecutar: "acaoRepLegalEmp",
                registro: registro,
            };

            const resultado = await postJSON({
                acaoWebAExecutar: acaoWebAExecutar,
                sistema: "sli",
                projeto: "mimobiliaria"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    const modelo = msg.modeloPadrao;

                    modelo.key = modelo.cdPessoaRepLegal;

                    const dsItem = refDsResultado.current.find({
                        key: modelo.key
                    });

                    if(dsItem === null) {

                        const novods = refDsResultado.current.add( modelo ).clone();
                        setResultado ( novods );

                    } else {

                        dsItem.noPessoaRepLegal = modelo.noPessoaRepLegal;
                        dsItem.nrCGCCICRepLegal = modelo.nrCGCCICRepLegal;

                    }

                }

            });

            notificacao.sucesso({transacao:cdTransacao,message:"Sucesso!", description:"Representante legal salvo com sucesso!"})

            return true;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const excluir = async (registros) => {

        try {

            const payload = {
                acaoAExecutar: "acaoRepLegalEmp",
                lista: registros
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "excluir",
                sistema: "sli",
                projeto: "mimobiliaria"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelosExcluidos") {

                    const novods = refDsResultado.current.remove(msg.lista.map(item => {
                        return {key: item.cdPessoaRepLegal}
                    })).clone() ;

                    setResultado( novods ) ;

                } else {

                    console.log(msg);

                }

            });

            notificacao.sucesso({transacao:cdTransacao,message:"Sucesso!", description:"Representante legal excluído com sucesso!"})

            return true;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    };

    const editarRegistro = (registros) => {
        novoRegistro();
        setRegistroNaTela(registros[0]);
    }

    const editar = () => {

        const { marcados } = dsResultado;

        editarRegistro ( marcados );

    }

    const actionView = (_, record) => {

        const botoes = [];

        botoes.push(
            <BotaoEditarRegistroTabela
                key={"btnEdit" + record.key}
                tooltip={"Editar este registro"}
                registro={record}
                editarRegistro={() => {
                    editar();
                }}
            />
        );

        botoes.push(<BotaoExcluirRegistroTabela
            key={"btnDelete" + record.key}
            registro={record}
            excluir={excluir}
            tooltip={"Excluir este registro"}
        />);

        return (
            <Space size={4} align="baseline" className={"resultado-action-view"}>
                {botoes}
            </Space>
        );

    };

    const calcTableHeight = () => {

        const cHeight = containerHeight();

        console.log("calcTableHeight", cHeight);

        setTableHeight(cHeight - 70 - 50);

    }

    const pesquisar = async () => {

        const filtro = {
        };

        const payload = {
            "acaoAExecutar": "pesquisaRepLegalEmp",
            filtro: {
                txtCdPessoaImobiliaria: cdPessoaEmpAtual,
            },
        }

        try {

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sli",
                projeto: "mimobiliaria"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            let dp = new DataProvider({pageSize: PAGE_SIZE_RESULTADO});

            resultado.mensagens.forEach(msg => {

                if(msg.id === "listaRepLegalEmpresa") {

                    const listaDP = msg.lista.map(item => {
                        item.key = item.cdPessoaRepLegal;
                        return item;
                    });

                    dp = new DataProvider({
                        data: listaDP,
                        total: listaDP.length,
                        page: 1,
                        pageSize: listaDP.length
                    });

                }

            });

            return dp;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return new DataProvider();

        }

    }

    const pesquisaInicial = async () => {

        novoRegistro();

        setResultado(new DataProvider({pageSize: PAGE_SIZE_RESULTADO}));

        tableRef.current?.clearData();

        await pesquisaPaginacao();

        // if(tableHeight === 80) calcTableHeight();

    }

    const pesquisaPaginacao = async () => {

        const dp = await pesquisar ();

        const newDP = refDsResultado.current.clone();

        newDP.addToInfinite(dp);

        setResultado(newDP);

        calcTableHeight();

        return newDP;

    }

    const colCNPJCPFRender = (_, registro) => {
        return formatadorCpfCnpj(registro.nrCGCCICRepLegal);
    };

    const tblCols = [
        {noColuna:'Nome', field:'noPessoaRepLegal', width:300, colSpan:1, card:false},
        {noColuna:'CPF', field:'nrCGCCICRepLegal', width:100, colSpan:1, card:true, render: colCNPJCPFRender},
    ];

    const cardCols = buildCardCols({tblCols: tblCols});

    const labelCardCols = buildLabelCard({tblCols: tblCols, labelCard: labelCard});

    const localRenderItemCard = defaultItemCardRender({cardCols: cardCols, labelCard: labelCard, orientationCard: orientationCard, cardColumns: cardColumns});

    const ordenadores = buildOrdenadores({ cardCols: cardCols, labelCardCols: labelCardCols });

    const cpfPatterns = [{
        pattern: "000.000.000-00",
        length: 11, // sem mascara
        validator: async (value) => {
            if(value.length === 11) {
                if(cpfValido(value) === false) {
                    return {valido:false, message:"CPF informado é inválido !"};
                }
            } else {
                return {valido:false, message:"CPF informado é inválido !"};
            }
            return {valido:true, message:""};
        }
    }];

    const telCelPatterns = [{
        pattern: "(00) 0000-0000",
        length: 10, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }, {
        pattern: "(00) 0-0000-0000",
        length: 11, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }];

    const popupTitle = "Representantes legais";

    const fiValues = {
    };

    return (
        <ErrorBoundary>
            <IY2BReactModal
                left={MARGIN_LEFT}
                top={MODAL_CONTENT_TOP}
                width={MODAL_CONTENT_WIDTH}
                marginBottom={MARGIN_BOTTOM}
                exibePopUp={exibePopUp}
                onRequestClose={onRequestClose}
                bodyOpenClassName={"editar-repres-legais-imob"}
                title={popupTitle}
                api={modalRefApi}
                body={
                    <div className={"body-editar-repres-legais-imob"}>
                        <ProForm
                            formRef = { formRef }
                            isKeyPressSubmit = {true}
                            initialValues={fiValues}
                            onFinish = { async ( ) => {
                                if(refTelaProtegida.current === true) return;
                                await doSalvar();
                            }}
                            submitter={{
                                // Completely customize the entire area
                                render : ( props , doms ) => {
                                    return null;
                                }
                            }}
                            onValuesChange={async (changeValues, allValues) => {
                                /*
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "cmbCdTipoAtendimento", cmbCdTipoAtendimento_OnChange, true);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCliente_NoPessoa", acCliente_OnChange, true);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCliente_NrCGCCIC", acCliente_OnChange, true);

                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCentroCusto", acCentroCusto_OnChange);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "vlrDesconto", onUpdateVlrTotal);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "flgOrcamento", flgOrcamento_OnChange);
                                conteudoAlterado.fireOnChangeFFR(changeValues, allValues, "itens", "vlrOrcPecas", onUpdateVlrTotal);
                                conteudoAlterado.fireOnChangeFFR(changeValues, allValues, "itens", "vlrOrcServ", onUpdateVlrTotal);
                                */
                            }}
                            onFieldsChange = {async (changedFields) => {
                                conteudoAlterado.updateConteudoAlterado(changedFields);
                            }}
                        >
                            <div className={"pro-form-group-spacer"}>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={24} md={8}>
                                            <IY2BProFormMasked
                                                width={"lg"}
                                                label={"CPF"}
                                                name="nrCGCCICRepLegal"
                                                tooltip = "CPF do representante legal"
                                                rules={[{ required: true, message: 'Por favor, informe o seu CPF!' }, {
                                                    validator: async (rule, value) => {
                                                        if(inputRefCPF) {
                                                            const result = await inputRefCPF.current.validate();
                                                            if(result.valido === false) throw new Error(result.message);
                                                        }
                                                    }
                                                }]}
                                                api={inputRefCPF}
                                                patterns={cpfPatterns}
                                            />
                                        </Col>
                                        <Col xs={24} md={16}>
                                            <ProFormText
                                                width={"xl"}
                                                name = "noPessoaRepLegal"
                                                label = "Nome do representante legal"
                                                placeholder={"Digite o nome do representante legal"}
                                                tooltip = "Nome do representante legal"
                                                rules={[ {required: true, message:"Informe o nome completo"}, {message:"No máximo 60 caracteres", max:60}]}
                                            />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={24} md={12}>
                                            <ProFormText
                                                width={"xl"}
                                                name = "noEMail"
                                                label = "E-Mail"
                                                tooltip = "Infome o email de contato"
                                                placeholder={"Digite o email de contato"}
                                                rules={[
                                                    {required: true, message:"Informe o email"},
                                                    {message:"Informe um email válido", type:"email"},
                                                    {message:"No máximo 60 caracteres", max:60}
                                                ]}
                                            />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <IY2BProFormMasked
                                                width={"sm"}
                                                label={"Telefone celular"}
                                                name="nrTelCelular"
                                                tooltip = "Nro. telefone celular"
                                                rules={[{ required: true, message: 'Por favor, informe o nro de telefone celular !' }, {
                                                    validator: async (rule, value) => {
                                                        if(inputRefTelCel) {
                                                            const result = await inputRefTelCel.current.validate();
                                                            if(result.valido === false) throw new Error(result.message);
                                                        }
                                                        return true;
                                                    }
                                                }]}
                                                api={inputRefTelCel}
                                                patterns={telCelPatterns}
                                            />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={24} md={{span:4, offset:20}}>
                                            <Button type={"primary"} className={"salvar"} onClick={async () => await doSalvar()}>
                                                Salvar
                                            </Button>
                                        </Col>
                                        <Col xs={24} md={{span:6, offset:18}}>
                                            <IY2BProFormFiller />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col span={24}>
                                        <IY2BCards id={"cards-repres-legais-imob" } name={"cards-repres-legais-imob"}
                                                   renderItem={ item => localRenderItemCard(item, actionView) }
                                                   botaoAcoesDisponiveis={{
                                                       disabled: true
                                                   }}
                                                   enableRowCheck={false}
                                                   ref={tableRef}
                                                   dataSource={dsResultado}
                                                   ordenadores={ordenadores}
                                                   columns={cardCols}
                                                   tableHeight={tableHeight}
                                                   pagPageSize={PAGE_SIZE_RESULTADO}
                                                   onRequestPage = {async () => {
                                                       return await pesquisaPaginacao();
                                                   }}
                                                   downloadDisabled={planoEhGratuito}
                                                   configDisabled={planoEhGratuito}
                                                   infiniteScroll={true}
                                        />
                                    </Col>
                                </Row>
                                <IY2BHiddenRow>
                                    <IY2BProFormHidden name={"cdPessoaRepLegal"}/>
                                    <IY2BProFormHidden name={"cdRepLegal"}/>
                                    <IY2BProFormHidden name={"flgOk"}/>
                                </IY2BHiddenRow>
                            </div>
                        </ProForm>
                    </div>
                }
            />
        </ErrorBoundary>
    );

});

export default RepresLegais;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";

import {
    notificacaoErroRequest, notificacaoErroValidForm,
    useConteudoAlterado,
    useGridWidth,
    useModoExecucao,
    useWindowSize
} from "../../lib/iy2b-react";
import React, {useEffect, useRef, useState} from "react";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {
    campoInformado,
    ObjectUtils,
} from "../../lib/iy2b-javascript";
import {postJSON} from "../../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";
import moment from "moment";
import {Button, Col, Popconfirm, Space} from "antd";
import IY2BHiddenRow from "../IY2BHiddenRow";
import IY2BProFormHidden from "../IY2BProFormHidden";
import NavegadorRegistro from "../NavegadorRegistro/navegadorRegistro";
import IY2BReactModal from "../IY2BReactModal";
import {ProForm, ProFormText, ProFormTextArea} from "@ant-design/pro-components";
import IY2BProFormMasked from "../IY2BProFormMasked";
import ComboBox from "../proform/ComboBox/comboBox";
import RadioGroup from "../proform/RadioGroup/radioGroup";
import FormFieldRepeater from "../proform/FormFieldRepeater";
import {useDecodeWidth} from "../../lib/useDecodeWidth";
import ReIF from "../REIf/reIF";
import {notificacao} from "../../lib/Notificacao";


const EditarContato = ( props ) => {

    const { exibePopUp, onRequestClose, salvar, excluir, dataProvider, tpOrigemPessoa, cdPessoa, cdTransacao=0 } = props;

    const { registros } = props;

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 10 : 60;
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = 66;

    const modalRefApi = useRef();
    const formRef = useRef();
    const navegadorRefApi = useRef();

    const forceUpdate = useForceUpdate();
    const decodeWidth = useDecodeWidth();

    const modoExecucao = useModoExecucao("INC");
    const conteudoAlterado = useConteudoAlterado();
    const [registroDaTelaInicial, setRegistroDaTelaInicial] = useState(null);

    const cmbCdTratamentoRefApi = useRef();
    const cmbCdNacionalRefApi = useRef();
    const cmbCdCargoContatoRefApi = useRef();

    const noContatoRef = useRef();

    const [dpTipoFone] = useState([]);
    const frTelefonesRefApi = useRef();

    const inputRefTelefone = useRef();

    useEffect(() => {

        if(campoInformado(registros)) {

            if(exibePopUp) {

                if(registros.length > 0) {

                    setTimeout(() => {
                        pesquisarPk(registros[0]);
                    }, 0);

                } else {

                    setTimeout(() => {
                        novoRegistro();
                    }, 0);

                }

            }

        } else {

            setTimeout(() => {
                novoRegistro();
            }, 0);

        }

        return () => {

        };

    }, [props.registros]);


    useEffect(() => {

        dpTipoFone.setItems(props.dataProvider?.dpTipoFone?.map(item => {
            return item;
        }));

        return () => {

        };

    }, [props.dataProvider]);

    const pesquisarPk = async (registro) => {

        try {

            novoRegistro();

            const payload = {
                acaoAExecutar: "acaoCRUD",
                "cdPessoa": cdPessoa,
                "tratarEnviarFat": "S",
                registro: {
                    "cdContato": registro.cdContato,
                }
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisarPk",
                sistema: "crm",
                projeto: "mcontato"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            let registroNaTela = { };

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    console.log(msg.modeloPadrao);

                    registroNaTela = {
                        ...registroNaTela,
                        ...msg.modeloPadrao,
                    }

                } else if(msg.id === "mapaRespostas") {

                    console.log(msg.mapa);

                    registroNaTela = {
                        ...registroNaTela,
                        flgEnviarFat: msg.mapa.flgEnviarFat
                    }

                }

            });

            setRegistroNaTela(registroNaTela);

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const setRegistroNaTela = async (registro) => {

        console.log(registro);

        const attrDaTela = [ "cdContato", "estCivil", "sexo", "noEmail", "noContato", "noApelido", "flgEnviarFat", "obsContato", "flgEnviaMailing"];


        const frTelefonesRows = frTelefonesRefApi.current.createRowsFrom(registro.listaFoneContato, false);

        const rowDataFrTelefones = { };

        registro.listaFoneContato.forEach((item, i) => {

            const frTelefoneR = frTelefonesRows.filter(r => r.row === (i+1))[0];

            rowDataFrTelefones[frTelefoneR.cmbCdTipoFone] = dataProvider.dpTipoFone.getBy("value", item.cdTipoFone);

            rowDataFrTelefones[frTelefoneR.nrFoneContato] = item.nrFoneContato;
            rowDataFrTelefones[frTelefoneR.txFoneContato] = item.txFoneContato;

        });

        const registroDaTela = {
            ... ObjectUtils.valuesFrom(registro, attrDaTela),
            cmbCdTratamento: dataProvider.dpTratamentoPessoal.getBy("value", registro.cdTratamento),
            cmbCdCargoContato: dataProvider.dpCargoContato.getBy("value", registro.cdCargoContato),
            ...rowDataFrTelefones,
        };

        if(campoInformado(registro.cdNacional) === true) {
            registroDaTela.cmbCdNacional = dataProvider.dpNacionalidade.getBy("value", registro.cdNacional);
        }

        formRef.current?.setFieldsValue( registroDaTela );

        setRegistroDaTelaInicial ( {
            ... registroDaTela,
        });

        forceUpdate();

        controleExibicao ( registro );

        modoExecucao.setModoExecAlteracao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const controleExibicao = (registro) => {


    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();

            noContatoRef.current.focus();

        }, 1);

    }

    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        console.log(ffValues);

        const attrDaTela = [ "cdContato", "estCivil", "sexo", "noEmail", "noContato", "noApelido", "flgEnviarFat", "obsContato", "flgEnviaMailing"];

        const registro = {
            ...ObjectUtils.valuesFrom(ffValues, attrDaTela),
        };

        registro.cdTratamento = ffValues.cmbCdTratamento.value;
        registro.noAbrev = ffValues.cmbCdTratamento.data.noAbrev;

        if(campoInformado(ffValues.cmbCdCargoContato) === true) {
            registro.cdCargoContato = ffValues.cmbCdCargoContato.value;
            registro.noCargoContato = ffValues.cmbCdCargoContato.label;
        }

        if(campoInformado(ffValues.cmbCdNacional) === true) {
            registro.cdNacional = ffValues.cmbCdNacional.value;
            registro.noNacionalF = ffValues.cmbCdNacional.label;
        }

        if(campoInformado(ffValues.cdContato) === false) {
            registro.dtUltimaAlt = window.dataZerada.format("YYYY/MM/DD");
        } else {
            const agora = new Date();
            registro.dtUltimaAlt = agora.format("YYYY/MM/DD");
        }

        const listaTelefones = frTelefonesRefApi.current.getFieldValue(ffValues);

        // nrFoneContato

        registro.listaFoneContato = listaTelefones.map((item, nrSeq) => {

            const registro = {
                nrFoneContato: item.nrFoneContato,
                cdTipoFone: item.cmbCdTipoFone.data.cdTipoFone,
                noTipoFone: item.cmbCdTipoFone.data.noTipoFone,
                txFoneContato: item.txFoneContato
            };

            return registro;

        });

        console.log(registro);

        return registro;

    }

    const doExcluir = async () => {

        const registro = getRegistroDaTela();

        await excluir ({
            cdContato: registro.cdContato,
        });

    }

    const novoRegistro = () => {

        formRef?.current?.resetFields();

        controleExibicao ( );

        modoExecucao.setModoExecInclusao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const validaTela = async (params) => {

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        return true;

    };

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return;

            if(conteudoAlterado.isConteudoAlterado() === false) {
                notificacao.aviso({message:"Sem alteração", description:"Nenhuma informação alterada para ser salva !"})
                return;
            }

            const registro = getRegistroDaTela();

            const respSalvar = await salvar (registro);

            if(respSalvar === false) return;

            if(navegadorRefApi.current.temProximo()) {

                navegadorRefApi.current.navegaProximo();

            } else {

                if(registros.length === 1) {

                    novoRegistro();

                } else {

                    onRequestClose();

                }

            }

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e, cdTransacao);

        }

    }

    const navegaParaRegistro = async (posicao) => {

        await pesquisarPk(registros[posicao - 1]);

    }

    const onValuesChange = (changed, all) => {
        // console.log(changed, all);
    }

    const telFixoPatterns = [{
        pattern: "(00) 0000-0000",
        length: 10, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }, {
        pattern: "(00) 0-0000-0000",
        length: 11, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }];

    const [dprTelefones] = useState([
        <Col xs={12} >
            <ComboBox
                width={"xl"}
                formRef = { formRef }
                name = "cmbCdTipoFone"
                label = "Tipo de telefone"
                tooltip = "Selecione o tipo do telefone"
                placeholder={"Tipo"}
                options={dpTipoFone}
                rules={[
                    {required: true, message:"Informe o tipo do telefone"},
                ]}
            />
        </Col>,
        <Col xs={12} >
            <IY2BProFormMasked
                width={"md"}
                label={"Telefone"}
                name="txFoneContato"
                tooltip = "Número do telefone"
                rules={[
                    {required: true, message:"Informe o número do telefone"},
                    {
                    validator: async (rule, value) => {
                        if(inputRefTelefone) {
                            const result = await inputRefTelefone.current.validate();
                            if(result.valido === false) throw new Error(result.message);
                        }
                        return true;
                    }
                }]}
                api={inputRefTelefone}
                patterns={telFixoPatterns}
            />
        </Col>,
        <IY2BHiddenRow>
            <IY2BProFormHidden name={"nrFoneContato"}/>
        </IY2BHiddenRow>,
    ]);

    const popupTitle = modoExecucao.isInclusao() ? "Novo contato" : "Alteração do contato";

    const ffValues = formRef?.current?.getFieldsValue();

    const fiValues = {
        sexo: "N",
        estCivil: "O",
        flgEnviarFat: "N",
        flgEnviaMailing: "S",
    };

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={24}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"editar-contato-pessoa"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-editar-contato-pessoa"}>
                    <ProForm
                        formRef = { formRef }
                        isKeyPressSubmit = {true}
                        initialValues={fiValues}
                        onFinish = { async ( ) => {
                            await doSalvar();
                        }}
                        submitter={{
                            // Completely customize the entire area
                            render : ( props , doms ) => {
                                return null;
                            }
                        }}
                        onValuesChange={async (changeValues, allValues) => {

                        }}
                        onFieldsChange = {(changedFields, allFields) => {
                            conteudoAlterado.updateConteudoAlterado(changedFields);
                        }}
                    >
                        <div className={"pro-form-group-spacer"}>
                            <ProForm.Group>
                                <ComboBox
                                    width={"xs"}
                                    formRef = { formRef }
                                    api = { cmbCdTratamentoRefApi }
                                    name = "cmbCdTratamento"
                                    label = "Tratamento formal"
                                    tooltip = "Selecione o tipo de tratamento formal"
                                    placeholder={"Tratamento"}
                                    rules={[
                                        {required: true, message: 'Informe o tipo de tratamento formal'},
                                    ]}
                                    options={dataProvider.dpTratamentoPessoal}
                                />
                                <ProFormText
                                    width={"xl"}
                                    name="noContato"
                                    label="Nome"
                                    tooltip={"Nome do contato"}
                                    placeholder={"Nome do contato"}
                                    rules={[
                                        {required: true, message: 'Informe o nome do contato'},
                                        {message:"No máximo 100 caracteres", max: 100}
                                    ]}
                                    fieldProps={{
                                        ref: noContatoRef
                                    }}
                                />
                            </ProForm.Group>
                            <ProForm.Group>
                                <ProFormText
                                    width={"md"}
                                    name="noApelido"
                                    label="Apelido"
                                    tooltip={"Apelido do contato"}
                                    placeholder={"Apelido do contato"}
                                    rules={[
                                        {message:"No máximo 30 caracteres", max: 30}
                                    ]}
                                />
                                <ComboBox
                                    width={"sm"}
                                    formRef = { formRef }
                                    api = { cmbCdNacionalRefApi }
                                    name = "cmbCdNacional"
                                    label = "Nacionalidade"
                                    tooltip = "Selecione a nacionalidade"
                                    placeholder={"Selecione a nacionalidade"}
                                    rules={[
                                    ]}
                                    options={dataProvider.dpNacionalidade}
                                />
                                <RadioGroup
                                    width={decodeWidth.get({xxl: "sm", xl:"lg", default:"sm"})}
                                    name="sexo"
                                    label="Sexo ?"
                                    tooltip = { "Sexo do contato" }
                                    rules={[
                                        {required: true, message: 'Informe o sexo do contato'},
                                    ]}
                                    options={[
                                        {
                                            label: 'Não declarado',
                                            value: 'N',
                                        },
                                        {
                                            label: 'Feminino',
                                            value: 'F',
                                        },
                                        {
                                            label: 'Masculino',
                                            value: 'M',
                                        },
                                    ]}
                                />
                                <RadioGroup
                                    width={"xxl"}
                                    name="estCivil"
                                    label="Estado civil ?"
                                    tooltip = { "Estado civil do contato" }
                                    rules={[
                                        {required: true, message: 'Informe o estado civil do contato'},
                                    ]}
                                    options={[
                                        {
                                            label: 'Casado',
                                            value: 'C',
                                        },
                                        {
                                            label: 'Solteiro',
                                            value: 'S',
                                        },
                                        {
                                            label: 'Viúvo',
                                            value: 'V',
                                        },
                                        {
                                            label: 'Divorciado',
                                            value: 'D',
                                        },
                                        {
                                            label: 'Separado',
                                            value: 'P',
                                        },
                                        {
                                            label: 'Não declarado',
                                            value: 'O',
                                        },
                                    ]}
                                />
                            </ProForm.Group>
                            <ProForm.Group>
                                <ProFormText
                                    width={"lg"}
                                    name = "noEmail"
                                    label = "E-Mail "
                                    tooltip = "Infome o email para contato"
                                    placeholder={"Digite o email para contato"}
                                    rules={[
                                        {required: true, message: 'Informe o email de contato'},
                                        {message:"Informe um email válido", type:"email"},
                                        {message:"No máximo 100 caracteres", max: 100}
                                    ]}
                                />
                                <ComboBox
                                    width={"md"}
                                    formRef = { formRef }
                                    api = { cmbCdCargoContatoRefApi }
                                    name = "cmbCdCargoContato"
                                    label = "Cargo"
                                    tooltip = "Selecione o cargo deste contato"
                                    placeholder={"Selecione uma das opções de cargo"}
                                    rules={[
                                        {required: true, message: 'Informe o cargo'},
                                    ]}
                                    options={dataProvider.dpCargoContato}
                                />
                            </ProForm.Group>
                            <ReIF hidden={tpOrigemPessoa!=="CLI"}>
                                <ProForm.Group>
                                    <RadioGroup
                                        width={"sm"}
                                        name="flgEnviarFat"
                                        label="Enviar faturamento para o email deste contato ?"
                                        tooltip = { "Indique se é feito envio automático do faturamento para o email deste contato" }
                                        rules={[
                                        ]}
                                        options={[
                                            {
                                                label: 'Não',
                                                value: 'N',
                                            },
                                            {
                                                label: 'Sim',
                                                value: 'S',
                                            },
                                        ]}
                                    />
                                </ProForm.Group>
                            </ReIF>
                            <ProForm.Group>
                                <FormFieldRepeater
                                    name={"telefones"}
                                    formRef = { formRef }
                                    fields={dprTelefones}
                                    api={frTelefonesRefApi}
                                    novaLinhaAdicionada={(posName) => {
                                        if(posName > 1) {
                                            modalRefApi?.current?.bodyScrollBottom();
                                        }
                                    }}
                                    linhaRemovida={() => {
                                        conteudoAlterado.setConteudoAlterado();
                                    }}
                                    title={"Telefones do contato"}
                                    onLoad={() => {
                                    }}
                                />
                            </ProForm.Group>
                            <ProForm.Group>
                                <ProFormTextArea
                                    width={"xl"}
                                    name="obsContato"
                                    label="Comentários"
                                    placeholder="Comentários sobre este contato"
                                    tooltip={"Observações pertinentes"}
                                    rules={[
                                        {type:"string", message:"No máximo 300 caracteres", max:300}
                                    ]}
                                    fieldProps={{
                                        showCount: true,
                                    }}
                                />
                                <RadioGroup
                                    width={"sm"}
                                    name="flgEnviaMailing"
                                    label="Contato permite enviar mensagens ?"
                                    tooltip = { "Indique se este contato permite enviar mensagens eletrônicas" }
                                    rules={[
                                    ]}
                                    options={[
                                        {
                                            label: 'Não',
                                            value: 'N',
                                        },
                                        {
                                            label: 'Sim',
                                            value: 'S',
                                        },
                                    ]}
                                />
                            </ProForm.Group>
                            <IY2BHiddenRow>
                                <IY2BProFormHidden name={"cdContato"}/>
                            </IY2BHiddenRow>
                        </div>
                    </ProForm>
                </div>
            }
            actions={
                <Space style={{
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <Space>
                        <NavegadorRegistro
                            api={navegadorRefApi}
                            registros={registros}
                            navegaPara={navegaParaRegistro}
                        />
                    </Space>
                    <Space>
                        {modoExecucao.isAlteracao()?
                            <Popconfirm
                                title="Confirma a exclusão deste registro?"
                                onConfirm={async () => {
                                    const resultado = await doExcluir();
                                }}
                                okText="Sim, excluir"
                                cancelText="Não"
                            >
                                <Button type={"primary"} danger className={"excluir"}>
                                    Excluir
                                </Button>
                            </Popconfirm>
                            :null}
                        &nbsp;
                        <Button type={"primary"} className={"salvar"} onClick={async () => await doSalvar()}>
                            Salvar
                        </Button>
                    </Space>
                </Space>
            }
        />
    );

}

export default EditarContato;

import {directGet, postJSON} from "../lib/RequestAPI";
import {SerializadorEventos} from "../lib/serializadorEventos";
import {SET_EMPRESA_ATUAL_DA_SESSAO, UPDATE_CHAVE_POST, updateChavePost} from "./userSession";
import {INICIALIZA_TRANSACOES} from "../reducer/transacaoManager";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../pages/Workspace/workspace";
import {campoInformado} from "../lib/iy2b-javascript";
import {useUserSession} from "../lib/ConfigManager";
import {pesquisarEnderecoDaPessoa} from "../lib/Servicos";
import eventBus from "../lib/eventBus";
import {notification} from "antd";
import {notificacao} from "../lib/Notificacao";

const listaSistemas = process.env.REACT_APP_SISTEMAS_HABILITADOS;

const INICIALIZA = 'smk/suite/INICIALIZA';
const SET_EMPRESA_ATUAL = 'smk/suite/SET_EMPRESA_ATUAL';
const SUITE_INICIALIZADA = 'smk/suite/SUITE_INICIALIZADA';
const ATUALIZAR_LISTA_EMPRESAS = "smk/suite/ATUALIZAR_LISTA_EMPRESAS";
const ATUALIZAR_TRANSACOES_DA_EMPRESA = "smk/suite/ATUALIZAR_TRANSACOES_DA_EMPRESA";
const LIGA_DEBUG = "smk/suite/LIGA_DEBUG";
const DESLIGA_DEBUG = "smk/suite/DESLIGA_DEBUG";
const IS_DEBUG_LIGADO = "smk/suite/IS_DEBUG_LIGADO";
const SET_CHECKLISTPROCESSO = "smk/suite/SET_CHECKLISTPROCESSO";

const initialState = {
    arvoreTrn: null,
    arvoreTrnFav: null,
    arvoreTrnExecAut: null,
    listaEmp: [],
    listaPeca: [],
    empresaAtual: null,
    debugHabilitado: false,
    checkListProcesso: null,
    portalImobiliario: false
};

const montaListaEmpresa = data => {

    const listaEmp = [];

    data.arvoreEmp.folhas.forEach(item => {

        if(item.Empresa !== undefined) {

            const empresa = {
                cdEmp: item.Empresa.cdEmp,
                noOficial: item.Empresa.noOficial,
                pessoaEmp: {
                    cdPessoa: item.Empresa.cdPessoaEmp.Pessoa.cdPessoa,
                    nrCGCCIC: item.Empresa.cdPessoaEmp.Pessoa.nrCGCCIC,
                    noPessoa: item.Empresa.cdPessoaEmp.Pessoa.noPessoa,
                    noFantasia: item.Empresa.cdPessoaEmp.Pessoa.noFantasia,
                    flgJuridico: item.Empresa.cdPessoaEmp.Pessoa.flgJuridico
                },
                logoBD: null
            };

            if(item.Empresa.cdPessoaEmpCTB === "itSelf") {
                empresa.pessoaEmpCtb = {
                    cdEmp: item.Empresa.cdEmp,
                    noOficial: item.Empresa.noOficial,
                    pessoaEmp: {
                        cdPessoa: item.Empresa.cdPessoaEmp.Pessoa.cdPessoa,
                        nrCGCCIC: item.Empresa.cdPessoaEmp.Pessoa.nrCGCCIC,
                        noPessoa: item.Empresa.cdPessoaEmp.Pessoa.noPessoa,
                        noFantasia: item.Empresa.cdPessoaEmp.Pessoa.noFantasia,
                        flgJuridico: item.Empresa.cdPessoaEmp.Pessoa.flgJuridico
                    }
                };
            } else {
                empresa.pessoaEmpCtb = {
                    cdEmp: item.Empresa.cdPessoaEmpCTB.Empresa.cdEmp,
                    noOficial: item.Empresa.cdPessoaEmpCTB.Empresa.noOficial,
                    pessoaEmp: {
                        cdPessoa: item.Empresa.cdPessoaEmpCTB.Empresa.cdPessoaEmp.Pessoa.cdPessoa,
                        nrCGCCIC: item.Empresa.cdPessoaEmpCTB.Empresa.cdPessoaEmp.Pessoa.nrCGCCIC,
                        noPessoa: item.Empresa.cdPessoaEmpCTB.Empresa.cdPessoaEmp.Pessoa.noPessoa,
                        noFantasia: item.Empresa.cdPessoaEmpCTB.Empresa.cdPessoaEmp.Pessoa.noFantasia,
                        flgJuridico: item.Empresa.cdPessoaEmpCTB.Empresa.cdPessoaEmp.Pessoa.flgJuridico
                    }
                }
            }

            if(item.Empresa.cdPessoaEmpMatriz === "itSelf") {
                empresa.pessoaEmpMatriz = {
                    cdEmp: item.Empresa.cdEmp,
                    noOficial: item.Empresa.noOficial,
                    pessoaEmp: {
                        cdPessoa: item.Empresa.cdPessoaEmp.Pessoa.cdPessoa,
                        nrCGCCIC: item.Empresa.cdPessoaEmp.Pessoa.nrCGCCIC,
                        noPessoa: item.Empresa.cdPessoaEmp.Pessoa.noPessoa,
                        noFantasia: item.Empresa.cdPessoaEmp.Pessoa.noFantasia,
                        flgJuridico: item.Empresa.cdPessoaEmp.Pessoa.flgJuridico
                    }
                };
            } else {
                empresa.pessoaEmpMatriz = {
                    cdEmp: item.Empresa.cdPessoaEmpMatriz.Empresa.cdEmp,
                    noOficial: item.Empresa.cdPessoaEmpMatriz.Empresa.noOficial,
                    pessoaEmp: {
                        cdPessoa: item.Empresa.cdPessoaEmpMatriz.Empresa.cdPessoaEmp.Pessoa.cdPessoa,
                        nrCGCCIC: item.Empresa.cdPessoaEmpMatriz.Empresa.cdPessoaEmp.Pessoa.nrCGCCIC,
                        noPessoa: item.Empresa.cdPessoaEmpMatriz.Empresa.cdPessoaEmp.Pessoa.noPessoa,
                        noFantasia: item.Empresa.cdPessoaEmpMatriz.Empresa.cdPessoaEmp.Pessoa.noFantasia,
                        flgJuridico: item.Empresa.cdPessoaEmpMatriz.Empresa.cdPessoaEmp.Pessoa.flgJuridico
                    }
                }
            }

            if(data.listaLogoEmp != undefined) {

                const logoEmp = data.listaLogoEmp.filter(item => item.EmpresaLogoVO.cdPessoaEmp === empresa.pessoaEmp.cdPessoa);

                if(logoEmp.length > 0) empresa.logoBD = logoEmp[0].EmpresaLogoVO.logoBD

            }

            listaEmp.push(empresa);

        }

    });

    return listaEmp;

}

export default function suite (state = initialState, action) {

    let newState = {...state};

    if(action.payload == undefined) {
        return newState;
    }
    if(action.payload.data == undefined) {
        return newState;
    }

    switch (action.type) {

        case SET_CHECKLISTPROCESSO:
            newState.checkListProcesso = action.payload.data;
            return newState;

        case IS_DEBUG_LIGADO:
            console.log("IS_DEBUG_LIGADO");
            return newState;

        case LIGA_DEBUG:
            console.log("LIGA_DEBUG");
            newState.debugHabilitado = action.payload.data;
            return newState;

        case DESLIGA_DEBUG:
            console.log("DESLIGA_DEBUG");
            newState.debugHabilitado = action.payload.data;
            return newState;

        case ATUALIZAR_LISTA_EMPRESAS:

            console.log("ATUALIZAR_LISTA_EMPRESAS");

            newState.listaEmp = [];

            if(action.payload.data.arvoreEmp.hasOwnProperty("folhas")) {

                newState.listaEmp = montaListaEmpresa(action.payload.data);

            }

            const novaEmpAtual = newState.listaEmp.filter(empresa => {
                return empresa.pessoaEmp.cdPessoa === newState.empresaAtual.pessoaEmp.cdPessoa;
            });

            if(novaEmpAtual.length > 0) {
                newState.empresaAtual = {
                    ...newState.empresaAtual,
                    logoBD: novaEmpAtual[0].logoBD
                };
            }

            return newState;

        case SET_EMPRESA_ATUAL:

            console.log("SET_EMPRESA_ATUAL");

            newState.empresaAtual = {
                cdEmp: action.payload.data.cdEmp,
                noOficial: action.payload.data.noOficial,
                flgOptaSimples: action.payload.data.flgOptaSimples||"N",
                flgContribICMS: action.payload.data.flgContribICMS||"N",
                flgME: action.payload.data.flgME||"N",
                flgMEI: action.payload.data.flgMEI||"N",
                pessoaEmp: {
                    cdPessoa: action.payload.data.pessoaEmp.cdPessoa,
                    nrCGCCIC: action.payload.data.pessoaEmp.nrCGCCIC,
                    noPessoa: action.payload.data.pessoaEmp.noPessoa,
                    noFantasia: action.payload.data.pessoaEmp.noFantasia,
                    flgJuridico: action.payload.data.pessoaEmp.flgJuridico
                },
                logoBD: action.payload.data.logoBD,
                enderecos: action.payload.data.enderecos.clone()
            };

            return newState;

        case ATUALIZAR_TRANSACOES_DA_EMPRESA:

            newState.arvoreTrnFav = [];

            if(action.payload.data.arvoreTrnFav.length > 0) {

                const moduloFav = action.payload.data.arvoreTrnFav[0];

                if(moduloFav.childs.length > 0) {

                    const sistemaFav = moduloFav.childs[0];

                    newState.arvoreTrnFav = sistemaFav.childs;

                }

            }

            newState.arvoreTrnExecAut = [];

            if(action.payload.data.arvoreTrnExecAut?.length > 0) {

                const moduloFav = action.payload.data.arvoreTrnExecAut[0];

                if(moduloFav.childs.length > 0) {

                    const sistemaFav = moduloFav.childs[0];

                    newState.arvoreTrnExecAut = sistemaFav.childs;

                }

            }

            newState.arvoreTrn = [];

            if(action.payload.data.arvoreTrn.length > 0) {

                const moduloGestao = action.payload.data.arvoreTrn[0];

                const sistema = moduloGestao.childs[0];

                newState.arvoreTrn = sistema.childs;

            }

            return newState;

        case INICIALIZA:

            newState.listaPeca = [];

            if(action.payload.data.listaPecas.length > 0) {
                newState.listaPeca.addAll(action.payload.data.listaPecas);
            }

            newState.arvoreTrnFav = [];

            newState.arvoreTrnExecAut = [];

            newState.arvoreTrn = [];

            newState.listaEmp = [];

            if(action.payload.data.arvoreEmp.hasOwnProperty("folhas")) {
                newState.listaEmp = montaListaEmpresa(action.payload.data);
            }

            newState.portalImobiliario = (action.payload.data.portalImobiliario === true);

            return newState;

        default:
            return state;

    }

};

// Action Creators

export function setEmpresaInicial_VersaoOriginal_Bug_JavaFarm(empresa) {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {

            console.log("setEmpresaInicial I", empresa.pessoaEmp.cdPessoa);

            const serializador = new SerializadorEventos();

            const suiteData = { };

            serializador.adicionaDependencia("SGR");

            serializador.adicionaDependencia("CTB");

            serializador.adicionaDependencia("SCP");

            serializador.adicionaDependencia("SMK");

            serializador.adicionaDependencia("SCB");

            serializador.adicionaDependencia("CRM");

            serializador.adicionaDependencia("SVD");

            serializador.adicionaDependencia("empresaAtual");

            serializador.adicionaDependencia("transacoes");

            serializador.setCallback(() => {

                dispatch({
                    type: SET_EMPRESA_ATUAL,
                    payload: {
                        data: empresa
                    }
                });

                dispatch({
                    type: SET_EMPRESA_ATUAL_DA_SESSAO,
                    payload: {
                        data: empresa
                    }
                });

                dispatch({
                    type: ATUALIZAR_TRANSACOES_DA_EMPRESA,
                    payload: {
                        data: suiteData
                    }
                });

                dispatch({
                    type: INICIALIZA_TRANSACOES,
                    payload: {
                        arvore: getState().suite.arvoreTrn,
                        arvoreFav: getState().suite.arvoreTrnFav,
                        arvoreExecAut: getState().suite.arvoreTrnExecAut
                    }
                });

                resolve(getState().suite);

            });

            // alterar empresa atual

            postJSON({
                acaoWebAExecutar: "processarnegocio",
                sistema: "suite",
                projeto: "merpsmk",
                rest: true
            }, {
                "acaoAExecutar": "atualizaEmpresaAtual",
                "cdPessoaEmpAtual": empresa.pessoaEmp.cdPessoa,
            }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultadoEmp => {

                serializador.concluiDependencia("empresaAtual");

                postJSON({
                    acaoWebAExecutar: "processarnegocio",
                    sistema: "sgr",
                    projeto: "infosessao",
                    rest: true
                }, {
                    "acaoAExecutar": "refreshCachePerfilT"
                }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultado => {

                    serializador.concluiDependencia("SGR");

                }, (error) => {

                    reject(error);

                });

                postJSON({
                    acaoWebAExecutar: "processarnegocio",
                    sistema: "ctb",
                    projeto: "infosessao",
                    rest: true
                }, {
                    "acaoAExecutar": "refreshCachePerfilT"
                }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultado => {

                    serializador.concluiDependencia("CTB");

                }, (error) => {

                    reject(error);

                });

                postJSON({
                    acaoWebAExecutar: "processarnegocio",
                    sistema: "scp",
                    projeto: "infosessao",
                    rest: true
                }, {
                    "acaoAExecutar": "refreshCachePerfilT"
                }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultado => {

                    serializador.concluiDependencia("SCP");

                }, (error) => {

                    reject(error);

                });

                postJSON({
                    acaoWebAExecutar: "processarnegocio",
                    sistema: "smk",
                    projeto: "infosessao",
                    rest: true
                }, {
                    "acaoAExecutar": "refreshCachePerfilT"
                }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultado => {

                    serializador.concluiDependencia("SMK");

                }, (error) => {

                    reject(error);

                });

                postJSON({
                    acaoWebAExecutar: "processarnegocio",
                    sistema: "scb",
                    projeto: "infosessao",
                    rest: true
                }, {
                    "acaoAExecutar": "refreshCachePerfilT"
                }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultado => {

                    serializador.concluiDependencia("SCB");

                }, (error) => {

                    reject(error);

                });

                postJSON({
                    acaoWebAExecutar: "processarnegocio",
                    sistema: "crm",
                    projeto: "infosessao",
                    rest: true
                }, {
                    "acaoAExecutar": "refreshCachePerfilT"
                }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultado => {

                    serializador.concluiDependencia("CRM");

                }, (error) => {

                    reject(error);

                });

                postJSON({
                    acaoWebAExecutar: "processarnegocio",
                    sistema: "svd",
                    projeto: "infosessao",
                    rest: true
                }, {
                    "acaoAExecutar": "refreshCachePerfilT"
                }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultado => {

                    serializador.concluiDependencia("SVD");

                }, (error) => {

                    reject(error);

                });

            }, (error) => {

                serializador.removeDependencia("empresaAtual");

                console.log(error);

                reject(error);

            });

            // transacoes

            postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "smk",
                projeto: "minfousuariobuild",
                rest: true
            }, {
                "acaoAExecutar": "pesquisarXMLTrn",
                "cdPessoaEmp": empresa.pessoaEmp.cdPessoa,
                "listaSistemas": listaSistemas
            }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultadoTrn => {

                resultadoTrn.mensagens.forEach(msg => {

                    if(msg.id === "transacoes") {
                        suiteData.arvoreTrn = msg.arvore;

                    } else if(msg.id === "transacoesFav") {
                        suiteData.arvoreTrnFav = msg.arvore;

                    } else if(msg.id === "transacoesExecAutom") {
                        suiteData.arvoreTrnExecAut = msg.arvore;

                    } else {
                        console.log(msg);

                    }

                });

                serializador.concluiDependencia("transacoes");

            }, (error) => {

                serializador.removeDependencia("transacoes");

                console.log(error);

                reject(error);

            });

        });

    };

}

const processaTrocaEmpresa = (dispatch, getState, resolve, reject, empresa) => {

    const serializador = new SerializadorEventos();

    const suiteData = { };

    serializador.adicionaDependencia("enderecos");

    serializador.adicionaDependencia("detalheEmpresa");

    serializador.adicionaDependencia("transacoes");

    serializador.setCallback(() => {

        dispatch({
            type: SET_EMPRESA_ATUAL,
            payload: {
                data: empresa
            }
        });

        dispatch({
            type: SET_EMPRESA_ATUAL_DA_SESSAO,
            payload: {
                data: empresa
            }
        });

        dispatch({
            type: ATUALIZAR_TRANSACOES_DA_EMPRESA,
            payload: {
                data: suiteData
            }
        });

        dispatch({
            type: INICIALIZA_TRANSACOES,
            payload: {
                arvore: getState().suite.arvoreTrn,
                arvoreFav: getState().suite.arvoreTrnFav,
                arvoreExecAut: getState().suite.arvoreTrnExecAut
            }
        });

        eventBus.dispatch("menuSuspenso/rebuild");
        eventBus.dispatch("menuLateral/rebuild");

        resolve(getState().suite);

    });

    // enderecos da empresa

    pesquisarEnderecoDaPessoa(empresa.pessoaEmp.cdPessoa, WORKSPACE_LOADING_INDICATOR_AREA).then(response => {

        empresa.enderecos = [];

        response.forEach(endereco => {
            empresa.enderecos.push(endereco);
        })

        serializador.concluiDependencia("enderecos");

    }, error => {

        serializador.removeDependencia("enderecos");

        reject(error);

    });

    postJSON({
        acaoWebAExecutar: "pesquisar",
        sistema: "smk",
        projeto: "mempresa"
    }, {
        "acaoAExecutar": "pesquisa",
        filtro: {
            "txtCdEmp": [empresa.cdEmp,empresa.cdEmp],
        }
    }, WORKSPACE_LOADING_INDICATOR_AREA).then(response => {
        response.mensagens.forEach(msg => {
            if(msg.id === "listaEmpresa") {
                const detalhesDaEmpresa = msg.lista[0];
                empresa.flgOptaSimples = detalhesDaEmpresa.flgOptaSimples;
                empresa.flgContribICMS = detalhesDaEmpresa.flgContribICMS||"N";
                empresa.flgME = detalhesDaEmpresa.flgME||"N";
                empresa.flgMEI = detalhesDaEmpresa.flgMEI||"N";
            }
        });
        serializador.concluiDependencia("detalheEmpresa");
    }, error => {
        serializador.removeDependencia("detalheEmpresa");
        reject(error);
    });

    // transacoes

    postJSON({
        acaoWebAExecutar: "pesquisar",
        sistema: "smk",
        projeto: "minfousuariobuild",
        rest: true
    }, {
        "acaoAExecutar": "pesquisarXMLTrn",
        "cdPessoaEmp": empresa.pessoaEmp.cdPessoa,
        "listaSistemas": listaSistemas
    }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultadoTrn => {

        resultadoTrn.mensagens.forEach(msg => {

            if(msg.id === "transacoes") {
                suiteData.arvoreTrn = msg.arvore;

            } else if(msg.id === "transacoesFav") {
                suiteData.arvoreTrnFav = msg.arvore;

            } else if(msg.id === "transacoesExecAutom") {
                suiteData.arvoreTrnExecAut = msg.arvore;

            } else {
                console.log(msg);

            }

        });

        serializador.concluiDependencia("transacoes");

    }, error => {

        serializador.removeDependencia("transacoes");

        console.log(error);

        reject(error);

    });

};

export function setEmpresaInicial(empresa, userSession) {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {

            console.log("setEmpresaInicial I", empresa.pessoaEmp.cdPessoa, userSession);

            postJSON({
                acaoWebAExecutar: "processarnegocio",
                sistema: "suite",
                projeto: "podtransacoes",
                rest: true
            }, {
                acaoAExecutar: "ExecutaTransacao",
                CP: userSession.txCP,
                jqsSMK: "S",
                acaoWebAExecutar: "ProcessarNegocioTransacoes",
                acao: "ExecutaTransacao",
                flgTransacaoAspJava: "J",
                cdPessoaEmp: empresa.pessoaEmp.cdPessoa,
                liberaDebug: userSession.flgDebug, // "N", ctrl-shift-d-l
                servicoJava: "musuarioportal.inicapp.smk",
                paramWeb: null,
                cdSistema: "SMK",
                cdTransacao: 85056, // 11088,
                nrSeqLoginSuiteSmk: userSession.nrSeqAudit
            }, WORKSPACE_LOADING_INDICATOR_AREA).then(response => {

                response.mensagens.forEach(msg => {

                    if(msg.id === "urlExec") {

                        // console.log("olduserSession", userSession);

                        directGet(msg.mensagem, WORKSPACE_LOADING_INDICATOR_AREA).then(response => {
                            // console.log(response.data);
                            const partes = response.data.split("self.location");
                            const location = partes[1];
                            // location.indexOf("&CP=")
                            // location.indexOf("&TXCONFIG=")
                            const novaChavePost = location.substring(location.indexOf("&CP=")+4, location.indexOf("&TXCONFIG="));

                            dispatch({
                                type: UPDATE_CHAVE_POST,
                                payload: novaChavePost
                            });

                            const newUserSession = {
                                ...userSession,
                                txCP: novaChavePost
                            }

                            // console.log(newUserSession)

                            useUserSession(newUserSession);

                            processaTrocaEmpresa (dispatch, getState, resolve, reject, empresa);

                        });

                    }

                });

            }, error => {

                console.log(error);

                if(campoInformado(error?.data?.descricao) === true) {
                    notificacao.erro({errorId: "empresaInicial", message:"Falha!", description:error.data.descricao});
                }

            });

        });

    };

}

export function setEmpresaAtual(empresa, userSession) {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {

            console.log("setEmpresaAtual I", empresa.pessoaEmp.cdPessoa, userSession);

            postJSON({
                acaoWebAExecutar: "processarnegocio",
                sistema: "suite",
                projeto: "podtransacoes",
                rest: true
            }, {
                acaoAExecutar: "ExecutaTransacao",
                CP: userSession.txCP,
                jqsSMK: "S",
                acaoWebAExecutar: "ProcessarNegocioTransacoes",
                acao: "ExecutaTransacao",
                flgTransacaoAspJava: "J",
                cdPessoaEmp: empresa.pessoaEmp.cdPessoa,
                liberaDebug: userSession.flgDebug, // "N", ctrl-shift-d-l
                servicoJava: "musuarioportal.inicapp.smk",
                paramWeb: null,
                cdSistema: "SMK",
                cdTransacao: 85056, // 11088,
                nrSeqLoginSuiteSmk: userSession.nrSeqAudit
            }, WORKSPACE_LOADING_INDICATOR_AREA).then(response => {

                response.mensagens.forEach(msg => {

                    if(msg.id === "urlExec") {

                        // console.log("olduserSession", userSession);

                        directGet(msg.mensagem, WORKSPACE_LOADING_INDICATOR_AREA).then(response => {
                            // console.log(response.data);
                            const partes = response.data.split("self.location");
                            const location = partes[1];
                            // location.indexOf("&CP=")
                            // location.indexOf("&TXCONFIG=")
                            const novaChavePost = location.substring(location.indexOf("&CP=")+4, location.indexOf("&TXCONFIG="));

                            dispatch({
                                type: UPDATE_CHAVE_POST,
                                payload: novaChavePost
                            });

                            const newUserSession = {
                                ...userSession,
                                txCP: novaChavePost
                            }

                            // console.log(newUserSession)

                            useUserSession(newUserSession);

                            processaTrocaEmpresa (dispatch, getState, resolve, reject, empresa);

                        });

                    }

                });

            }, error => {

                console.log(error);

                if(campoInformado(error?.data?.descricao) === true) {
                    notificacao.erro({errorId: "empresaAtual", message:"Falha!", description:error.data.descricao});
                }

            });

        });

    };

}

export function atualizarListaEmpresas() {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {

            const suiteData = { } ;

            // empresas

            postJSON({
                acaoWebAExecutar: "processarnegocio",
                sistema: "suite",
                projeto: "podtransacoes",
                rest: false
            }, {
                "acaoAExecutar": "PesquisarXMLEmp",
                "acaoWebAExecutar": "ProcessarNegocioTransacoes",
                "acao": "PesquisarPodTransacoesXMLEmp"
            }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultadoEmp => {

                if(resultadoEmp.hasOwnProperty("ErroDeAcao")) {

                    suiteData.listaLogoEmp = [];
                    suiteData.arvoreEmp = [];

                    reject(new Error(resultadoEmp.ErroDeAcao.descricao));

                } else {

                    resultadoEmp.RespostaValida.mensagens.forEach(msg => {

                        if(msg.MensagemXML) {

                            if(msg.MensagemXML.id === "transacoes") {
                                console.log(msg.MensagemXML.mensagem);

                            } else if(msg.MensagemXML.id === "empresas") {
                                console.log(msg.MensagemXML.mensagem);

                            } else {
                                console.log(msg.MensagemXML.id, msg.MensagemXML.mensagem);

                            }

                        } else if(msg.ListaPesquisa) {

                            if(msg.ListaPesquisa.id === "listaEmpresaLogo") {
                                suiteData.listaLogoEmp = msg.ListaPesquisa.lista;

                            } else {
                                console.log(msg.ListaPesquisa.id, msg.ListaPesquisa.lista);

                            }

                        } else if(msg.MensagemArvore) {

                            if(msg.MensagemArvore.id === "empresas") {
                                suiteData.arvoreEmp = msg.MensagemArvore.arvore;

                            } else {
                                console.log(msg.MensagemArvore.id, msg.MensagemArvore.arvore);

                            }

                        } else {

                            console.log(msg);

                        }

                    });

                    dispatch({
                        type: ATUALIZAR_LISTA_EMPRESAS,
                        payload: {
                            data: suiteData
                        }
                    });

                    resolve(getState().suite);

                }

            }, (error) => {

                console.log(error);

                reject(error);

            });

        });

    };

}

export function inicializaSuite(cdPessoaEmpAtual) {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {

            console.log(getState().suite.debugHabilitado);

            const serializador = new SerializadorEventos();

            const suiteData = {
            };

            suiteData.arvoreTrn = [ ];
            suiteData.arvoreTrnFav = [ ];
            suiteData.arvoreTrnExecAut = [ ];

            serializador.adicionaDependencia("empresas");

            serializador.adicionaDependencia("adpecas");

            serializador.adicionaDependencia("portalImobiliario");

            serializador.setCallback(() => {

                console.log("finaliza inicializa suite", suiteData);

                dispatch({
                    type: INICIALIZA,
                    payload: {
                        data: suiteData
                    }
                });

                dispatch({
                    type: SUITE_INICIALIZADA
                });

                console.log("getState().suite ???", getState().suite);

                resolve(getState().suite);

            });

            // empresas

            postJSON({
                acaoWebAExecutar: "processarnegocio",
                sistema: "suite",
                projeto: "podtransacoes",
                rest: false
            }, {
                "acaoAExecutar": "PesquisarXMLEmp",
                "acaoWebAExecutar": "ProcessarNegocioTransacoes",
                "acao": "PesquisarPodTransacoesXMLEmp"
            }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultadoEmp => {

                if(resultadoEmp.hasOwnProperty("ErroDeAcao")) {

                    suiteData.listaLogoEmp = [];
                    suiteData.arvoreEmp = [];

                    serializador.removeDependencia("empresas");

                    reject(new Error(resultadoEmp.ErroDeAcao.descricao));

                } else {

                    resultadoEmp.RespostaValida.mensagens.forEach(msg => {

                        if(msg.MensagemXML) {

                            if(msg.MensagemXML.id === "transacoes") {
                                console.log(msg.MensagemXML.mensagem);

                            } else if(msg.MensagemXML.id === "empresas") {
                                console.log(msg.MensagemXML.mensagem);

                            } else {
                                console.log(msg.MensagemXML.id, msg.MensagemXML.mensagem);

                            }

                        } else if(msg.ListaPesquisa) {

                            if(msg.ListaPesquisa.id === "listaEmpresaLogo") {
                                suiteData.listaLogoEmp = msg.ListaPesquisa.lista;

                            } else {
                                console.log(msg.ListaPesquisa.id, msg.ListaPesquisa.lista);

                            }

                        } else if(msg.MensagemArvore) {

                            if(msg.MensagemArvore.id === "empresas") {
                                suiteData.arvoreEmp = msg.MensagemArvore.arvore;

                            } else {
                                console.log(msg.MensagemArvore.id, msg.MensagemArvore.arvore);

                            }

                        } else {

                            console.log(msg);

                        }

                    });

                    serializador.concluiDependencia("empresas");

                }

            }, (error) => {

                serializador.removeDependencia("empresas");

                console.log(error);

                reject(error);

            });

            // pecas

            const cdTipoMidiaAd = process.env.REACT_APP_AD_TIPOMIDIA ;

            postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "crm",
                projeto: "mpecapublic",
                rest: true
            }, {
                "acaoAExecutar": "pesquisa",
                "filtro": {
                    "cmbCdTipoMidia": cdTipoMidiaAd
                },
                "orderBySql": "PECAPUBLIC.DTULTPUBLICADET DESC, PECAPUBLIC.CDPECA ASC"
            }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultadoPecaPublic => {

                suiteData.listaPecas = [];

                resultadoPecaPublic.mensagens.forEach(msg => {

                    if(msg.id === "listaPecaPublic") {

                        msg.lista.forEach(peca => {

                            if(campoInformado(peca.txUrlPublicaDet)) {

                                suiteData.listaPecas.push({
                                    cdPeca: peca.cdPeca,
                                    noPeca: peca.noPeca,
                                    noCampanha: peca.noCampanha,
                                    url: peca.txUrlPublicaDet
                                });

                            }

                        });

                    } else {

                        console.log(msg);

                    }

                });

                serializador.concluiDependencia("adpecas");

            }, (error) => {

                suiteData.listaPecas = [];

                serializador.removeDependencia("adpecas");

                console.log(error);

                reject(error);

            });

            // parametro portal imobiliario

            postJSON({
                acaoWebAExecutar: "pesquisarPk",
                sistema: "sgr",
                projeto: "mparametro",
                rest: true
            }, {
                "acaoAExecutar": "acaoCRUD",
                "registro": {
                    "cdSistema": "SLI",
                    "cdParametro": "PORTALIMOBILIARIO"
                },
            }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultadoPortalImob => {

                suiteData.portalImobiliario = false;

                resultadoPortalImob.mensagens.forEach(msg => {

                    if(msg.id === "modelo") {

                        console.log("PARAMETRO SLI.PORTALIMOB", msg.modeloPadrao, msg.modeloPadrao.conteudo);

                        if(msg.modeloPadrao.flgGlobal === "N") {
                            serializador.removeDependencia("portalImobiliario");
                            const myError = new Error("Parametro PORTALIMOBILIARIO configurado de forma incorreta!");
                            console.log(myError);
                            reject(myError);
                        } else {
                            suiteData.portalImobiliario = (msg.modeloPadrao.conteudo === "S");
                            console.log("suiteData.portalImobiliario", suiteData.portalImobiliario)
                        }

                    } else {

                        console.log(msg);

                    }

                });

                serializador.concluiDependencia("portalImobiliario");

            }, (error) => {

                suiteData.portalImobiliario = false;

                serializador.removeDependencia("portalImobiliario");

                console.log(error);

                reject(error);

            });

        });

    };

}


export function isDebugLigado() {

    return (dispatch, getState) => {

        dispatch({
            type: IS_DEBUG_LIGADO,
            payload: {
                data: "get"
            }
        });

        return Promise.resolve(getState().suite);

    };

}

export function ligaDebug() {

    return (dispatch, getState) => {

        dispatch({
            type: LIGA_DEBUG,
            payload: {
                data: true
            }
        });

        return Promise.resolve(getState().suite);

    };

}

export function desligaDebug() {

    return (dispatch, getState) => {

        dispatch({
            type: DESLIGA_DEBUG,
            payload: {
                data: false
            }
        });

        return Promise.resolve(getState().suite);

    };

}

export function setChecklistProcesso(checklist) {

    return (dispatch, getState) => {

        dispatch({
            type: SET_CHECKLISTPROCESSO,
            payload: {
                data: checklist
            }
        });

        return Promise.resolve(getState().suite);

    };

}


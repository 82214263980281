//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../framework/assets/css/tema.scss';
import './style.scss';

import React, {useRef} from "react";
import {ProForm, ProFormText} from "@ant-design/pro-components";
import ComboBox from "../../framework/componentes/proform/ComboBox/comboBox";

import IY2BProFormRangeDigit from "../../framework/componentes/proform/IY2BProFormRangeDigit";
import IY2BDateRangePicker from "../../framework/componentes/proform/IY2BProFormDateRangePicker/IY2BDateRangePicker";
import FormFiltroPopUp from "../../framework/componentes/FormFiltroPopUp/formFiltroPopUp";
import {Col, Row} from "antd";

const FiltroAtendimento = ( props ) => {

    const { setUltFiltro, pesquisaInicial, dataProvider, setDesabilitaAcoesDisp, onNovoRegistro } = props;
    const {setFormFiltro, getFormFiltro} = props;

    const formRef = useRef();
    const apiFormRef = useRef();
    const cdAtendimentoApi = useRef();
    const dtCadApi = useRef();
    const dtInicioApi = useRef();
    const dtPrevisaoApi = useRef();

    const validaCamposFiltro = async (values) => {
        return true;
    }

    const montaFiltro = () => {

        const ffValues = formRef.current?.getFieldsValue();

        const filtro = {
            cdPessoaEmp: dataProvider.cdPessoaEmpAtual,
            txtCdAtendimento: cdAtendimentoApi.current.getRange(ffValues),
            txtDtCad: dtCadApi.current.getRange(ffValues),
            txtDtInicio: dtInicioApi.current.getRange(ffValues),
            txtDtPrevisao: dtPrevisaoApi.current.getRange(ffValues),
            cmbCdTipoAtendimento: ffValues.cmbCdTipoAtendimento?.value,
            cmbCdStatusAtendimento: ffValues.cmbCdStatusAtendimento?.value,
            txtNoAtendimento: ffValues.txtNoAtendimento,
        };

        setUltFiltro ( filtro );

        setFormFiltro (ffValues);

        return filtro;

    }

    const ffInitialValues = { } ;

    return (
        <FormFiltroPopUp
            showOnFirstInput
            formRef = { formRef }
            ref = { apiFormRef }
            onFilter = {async () => {
                montaFiltro();
                await pesquisaInicial();
            }}
            onAfterOpen = { () => {
                formRef.current?.setFieldsValue( getFormFiltro() );
            }}
            onExibeFiltro = {exibe => {
                setDesabilitaAcoesDisp(exibe);
            }}
            novoRegistro={true}
            onNovoRegistro={onNovoRegistro}
            layout = "vertical"
            validaCamposFiltro = { validaCamposFiltro }
            initialValues={ffInitialValues}
            onValuesChange={async (changeValues, allValues) => {
            }}
        >
            <div className={"pro-form-group-spacer"}>
                <ProForm.Group>
                    <Row gutter={[8,16]}>
                        <Col xs={24} md={12} lg={8}>
                            <IY2BProFormRangeDigit
                                formRef = { formRef }
                                name = "cdAtendimento"
                                label = "Número do atendimento"
                                tooltip = {"Intervalo de pesquisa do número do atendimento"}
                                digits={10}
                                api={cdAtendimentoApi}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={16}>
                            <ProFormText
                                width={"xl"}
                                name = "txtNoAtendimento"
                                label = "Assunto"
                                tooltip = "Informe o assunto do atendimento"
                                placeholder={"Qual o assunto do atendimento ?"}
                                rules={[
                                    {type:"string", message:"No máximo 100 caracteres", max: 100}
                                ]}
                            />
                        </Col>
                    </Row>
                </ProForm.Group>
                <ProForm.Group>
                    <Row gutter={[8,16]}>
                        <Col xs={24} md={12}>
                            <ComboBox
                                width={"xl"}
                                formRef = { formRef }
                                name = "cmbCdTipoAtendimento"
                                label = "Tipo"
                                tooltip = "Tipo"
                                placeholder={"Selecione o tipo para pesquisar"}
                                options={dataProvider.dpTipoAtendimento}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <ComboBox
                                width={"xl"}
                                formRef = { formRef }
                                name = "cmbCdStatusAtendimento"
                                label = "Status"
                                tooltip = "Status"
                                placeholder={"Selecione o status para pesquisar"}
                                options={dataProvider.dpStatusAtendimento}
                            />
                        </Col>
                    </Row>
                </ProForm.Group>
                <ProForm.Group>
                    <Row gutter={[8,16]}>
                        <Col xs={24} md={12} lg={8}>
                            <IY2BDateRangePicker
                                width = {"md"}
                                name = "dtCad"
                                label = "Data de cadastro"
                                tooltip = {"Intervalo de pesquisa da data de cadastro"}
                                formRef = { formRef }
                                api={dtCadApi}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <IY2BDateRangePicker
                                width = {"md"}
                                name = "dtInicio"
                                label = "Data de início"
                                tooltip = {"Intervalo de pesquisa da data de início"}
                                formRef = { formRef }
                                api={dtInicioApi}
                            />
                        </Col>
                        <Col xs={24} md={12} lg={8}>
                            <IY2BDateRangePicker
                                width = {"md"}
                                name = "dtPrevisao"
                                label = "Data de previsão"
                                tooltip = {"Intervalo de pesquisa da data de previsão"}
                                formRef = { formRef }
                                api={dtPrevisaoApi}
                            />
                        </Col>
                    </Row>
                </ProForm.Group>
            </div>
        </FormFiltroPopUp>
    );

}

export default FiltroAtendimento;

import '../../assets/css/tema.scss';
import './editarConfigImob.scss';

import React, {useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {WORKSPACE_LOADING_INDICATOR_AREA, WorkspaceContext} from "../../pages/Workspace/workspace";
import {
    notificacaoErroRequest, notificacaoErroValidForm,
    PADRAO_MARGEM_POPUP,
    useConteudoAlterado,
    useGridWidth,
    useModoExecucao, useStateRef,
    useWindowSize
} from "../../lib/iy2b-react";
import {useFormState} from "../../lib/useFormState";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {campoInformado, ObjectUtils, sortByLabel} from "../../lib/iy2b-javascript";
import Promessas from "../../lib/Promessas";
import {postJSON} from "../../lib/RequestAPI";
import {smkPadrao} from "../../lib/iy2b-smkpadrao";
import {notificacao} from "../../lib/Notificacao";
import moment from "moment/moment";
import IY2BReactModal from "../../componentes/IY2BReactModal";
import {
    ProForm,
    ProFormDatePicker,
    ProFormDigit, ProFormSwitch,
    ProFormText,
    ProFormTextArea,
    ProFormTimePicker
} from "@ant-design/pro-components";
import {Button, Col, Row, Space} from "antd";
import ComboBox from "../../componentes/proform/ComboBox/comboBox";
import REIf from "../../componentes/REIf/reIF";
import AutoCompleteCliente from "../../../componentes/AutoCompleteCliente/autoCompleteCliente";
import AutoCompletePessoa from "../../componentes/proform/AutoCompletePessoa";
import {
    fetchFuncionariosDaEmpresa,
    fetchUsuariosDaEmpresa,
    pesquisarCredor,
    pesquisarJob
} from "../../../lib/servicosPortalIY2B";
import AutoComplete from "../../componentes/proform/AutoComplete";
import IY2BProFormFiller from "../../componentes/proform/IY2BProFormFiller/iy2bProFormFiller";
import {pesquisarContato} from "../../../transacoes/Atendimento/atendimento";
import IY2BProFormDecimal from "../../componentes/proform/IY2BProFormDecimal";
import FoldedFormField from "../../componentes/proform/FoldedFormField/foldedFormField";
import FormFieldRepeater from "../../componentes/proform/FormFieldRepeater";
import IY2BHiddenRow from "../../componentes/IY2BHiddenRow";
import IY2BProFormHidden from "../../componentes/IY2BProFormHidden";
import FinalizarAtendimento from "../../../transacoes/Atendimento/finalizarAtendimento";
import NavegadorRegistro from "../../componentes/NavegadorRegistro/navegadorRegistro";
import RadioGroup from "../../componentes/proform/RadioGroup/radioGroup";
import {CUSTOM} from "../../assets/iconografia";
import CidadesAtendidas from "./imobiliaria/cidadesAtendidas";
import RepresLegais from "./imobiliaria/represLegais";
import PortaisImob from "./imobiliaria/portaisImob";

const iconCity = CUSTOM.CITY_ICON;
const iconSignature = CUSTOM.FILESIGNATURE_ICON;
const iconWeb = CUSTOM.WEB_ICON;

const EditarConfigImob = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {

        };
    });

    const { exibePopUp, onRequestClose, dataProvider, cdTransacao = 0 } = props;

    const { cdPessoaEmpAtual } = props;

    const { availableWidth } = useContext(WorkspaceContext);

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 10 : windowSize.widthMargin(PADRAO_MARGEM_POPUP.SM);
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = windowSize.heightMargin(PADRAO_MARGEM_POPUP.XL);
    const MARGIN_BOTTOM = windowSize.heightMargin(PADRAO_MARGEM_POPUP.XL); // 24;

    const modalRefApi = useRef();

    const formRef = useRef() ;
    const formState = useFormState({ });

    const modoExecucao = useModoExecucao("INC");
    const conteudoAlterado = useConteudoAlterado();

    const [, setRegistroDaTela, refRegistroDaTela] = useStateRef( null);
    const [, setTelaProtegida, refTelaProtegida] = useStateRef(false);

    const forceUpdate = useForceUpdate();

    const [exibeCidadesAtend, setExibeCidadesAtend] = useState(false);
    const [exibeReprLegais, setExibeReprLegais] = useState(false);
    const [exibePortaisImob, setExibePortaisImob] = useState(false);

    const [dataProviderImob, setDataProviderImob] = useState({ });

    const cmbCdPessoaBidDefaultRefApi = useRef();

    useEffect(() => {

        console.log("props.cdPessoaEmpAtual", props.cdPessoaEmpAtual)

        if(campoInformado(cdPessoaEmpAtual)) {
            if(exibePopUp) {
                pesquisarPk({
                    cdPessoaImobiliaria: cdPessoaEmpAtual
                });
            }
        }

        return () => {

        };

    }, [props.exibePopUp]);

    useEffect(() => {

        return () => {

        };

    }, [props.dataProvider]);

    const pesquisarPk = async (registro) => {

        try {

            console.log(registro);

            novoRegistro();

            let registroDaTela = { };

            let myDataProvider = { } ;

            const promessas = new Promessas();

            promessas.add(async (resolve, reject) => {
                try {

                    const payload = {
                        acaoAExecutar: "acaoCRUD",
                        registro: {
                            cdPessoaImobiliaria: registro.cdPessoaImobiliaria
                        },
                    };

                    const resultado = await postJSON({
                        acaoWebAExecutar: "pesquisarPk",
                        sistema: "sli",
                        projeto: "mimobiliaria"
                    }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

                    resultado.mensagens.forEach(msg => {

                        if(msg.id === "modelo") {

                            registroDaTela = {
                                ...registroDaTela,
                                ...msg.modeloPadrao
                            };

                        } else {

                            console.log(msg);

                        }

                    });

                    resolve(true);

                } catch (e) {

                    console.log(e);

                    notificacaoErroRequest(e, cdTransacao);

                    reject (e);

                }
            });

            promessas.add(async (resolve, reject) => {
                try {

                    const payload = {
                        acaoAExecutar: "inicializaProjeto",
                    };

                    const resultado = await postJSON({
                        acaoWebAExecutar: "pesquisar",
                        sistema: "sli",
                        projeto: "mimobiliaria"
                    }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

                    resultado.mensagens.forEach(msg => {

                        if(msg.id === "listaPortalImovel") {

                            myDataProvider.dpPortalImovel = msg.lista.map(item => {
                                return {
                                    value: item.cdPortalImovel,
                                    label: item.noPortalImovel,
                                    data: { ...item }
                                }
                            }).sort(sortByLabel);

                        } else {

                            console.log(msg);

                        }

                    });

                    resolve(true);

                } catch (e) {

                    console.log(e);

                    notificacaoErroRequest(e, cdTransacao);

                    reject (e);

                }
            });

            await promessas.run();

            console.log(registroDaTela);

            setRegistroNaTela(registroDaTela);

            setDataProviderImob(myDataProvider);

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const setRegistroNaTela = (registro) => {

        console.log("setRegistroNaTela", registro);

        /*
        cmbCdPessoaBidDefault
         */

        const attrsTela = [
            "flgClassifGrpImv", "flgAtivo", "flgDestinoPautaMaisInfo", "flgExibeImvPrp", "flgExpDados", "flgPacoteUso", "flgPromocaoAssinat", "flgSiteImob",
            "noPessoaImobiliaria", "noFantasiaImobiliaria", "nrCGCCICImobiliaria", "nrInsMunicipImobiliaria",
            "cdGrupoImovel","cdImobiliaria",
            "cdPessoaImobiliaria","cdPlanoContrVenda","chWorkflow","dtAlt","dtCad","dtConfigImob","dtInativo",
            "htmlPILocatario","htmlPIProprietario",
            "id_Externo","noComplEnd","noLograd","noSiteImob","nrImovel",
            "nrTelFixo","qtdeAte","qtdeDe","qtdeMaxCorretores",
            "tpEtapaCad","txConfigCli","txMsgInicialAnuncio","txTokenCli",
            "txUrlArqImpImovel1","txUrlArqImpImovel2","txUrlModeloContrProp"
        ];

        const registroDaTela = {
            ...ObjectUtils.valuesFrom(registro, attrsTela),
            flgLocLead: registro.flgLocLead === "S",
            flgLocMaisInfo: registro.flgLocMaisInfo === "S",
            flgLocProposta: registro.flgLocProposta === "S",
            flgLocVisita: registro.flgLocVisita === "S",
            flgVenLead: registro.flgVenLead === "S",
            flgVenMaisInfo: registro.flgVenMaisInfo === "S",
            flgVenProposta: registro.flgVenProposta === "S",
            flgVenVisita: registro.flgVenVisita === "S",
            flgExibeCartImob: registro.flgExibeCartImob === "S",
            flgCompartilhaCartImob: registro.flgCompartilhaCartImob === "S",
            nrItemDescVol: registro.nrItem
        };

        registroDaTela.cmbCdPessoaBidDefault = {
            value: registro.cdPessoaBidDefault,
            label: registro.noPessoaBidDefault.trim() + " (" + registro.noEmail + ")",
            data: {
                cdPessoaUsr: registro.cdPessoaBidDefault,
            }
        }

        setRegistroDaTela ( registroDaTela );

        formRef.current?.setFieldsValue( registroDaTela );

        forceUpdate();

        controleExibicao ( registroDaTela );

        modoExecucao.setModoExecAlteracao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    };

    const controleExibicao = (registro) => {


    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();

            if(modoExecucao.isAlteracao()===true) {

                // noAtendimentoRef.current?.focus();

            } else {

                // cmbCdTipoAtendimentoRefApi.current?.focus();

            }

        }, 1);

    }

    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        console.log("getRegistroDaTela.ffValues", ffValues);

        const attrsTela = [
            "flgClassifGrpImv", "flgAtivo", "flgDestinoPautaMaisInfo", "flgExibeImvPrp", "flgExpDados", "flgPacoteUso", "flgPromocaoAssinat", "flgSiteImob",
            "noPessoaImobiliaria", "noFantasiaImobiliaria", "nrCGCCICImobiliaria", "nrInsMunicipImobiliaria",
            "cdGrupoImovel","cdImobiliaria",
            "cdPessoaImobiliaria","cdPlanoContrVenda","chWorkflow","dtAlt","dtCad","dtConfigImob","dtInativo",
            "htmlPILocatario","htmlPIProprietario",
            "id_Externo","noComplEnd","noLograd","noSiteImob","nrImovel", "nrItemDescVol",
            "nrTelFixo","qtdeAte","qtdeDe","qtdeMaxCorretores",
            "tpEtapaCad","txConfigCli","txMsgInicialAnuncio","txTokenCli",
            "txUrlArqImpImovel1","txUrlArqImpImovel2","txUrlModeloContrProp"
        ];

        const registro = {
            ...ObjectUtils.valuesFrom(ffValues, attrsTela),
            flgLocLead: ffValues.flgLocLead === true ? "S":"N",
            flgLocMaisInfo: ffValues.flgLocMaisInfo === true ? "S":"N",
            flgLocProposta: ffValues.flgLocProposta === true ? "S":"N",
            flgLocVisita: ffValues.flgLocVisita === true ? "S":"N",
            flgVenLead: ffValues.flgVenLead === true ? "S":"N",
            flgVenMaisInfo: ffValues.flgVenMaisInfo === true ? "S":"N",
            flgVenProposta: ffValues.flgVenProposta === true ? "S":"N",
            flgVenVisita: ffValues.flgVenVisita === true ? "S":"N",
            flgExibeCartImob: ffValues.flgExibeCartImob === true ? "S":"N",
            flgCompartilhaCartImob: ffValues.flgCompartilhaCartImob === true ? "S":"N",
            "cdPessoaBidDefault": ffValues.cmbCdPessoaBidDefault?.value,
        };

        console.log("getRegistroDaTela.registro", registro);

        return registro;

    }

    const novoRegistro = () => {

        console.log("novoRegistro");

        formRef?.current?.resetFields();

        setTelaProtegida ( false ) ;

        // console.log(userSession.cdPessoaUsr);

        setRegistroDaTela ({ });

        formState.clear({ });

        controleExibicao ( );

        modoExecucao.setModoExecInclusao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const validaTela = async (params) => {

        let resultado = true;

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        return resultado;

    };

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return;

            if(conteudoAlterado.isConteudoAlterado() === false) {
                notificacao.aviso({message:"Sem alteração", description:"Nenhuma informação alterada para ser salva !"})
                return;
            }

            const registro = getRegistroDaTela();

            const resultado = await salvarImobiliaria (registro);

            if(resultado === false) return;

            onRequestClose();

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e, cdTransacao);

        }

    }

    const salvarImobiliaria = async (registro) => {

        try {

            const payload = {
                acaoAExecutar: "acaoCRUD",
                registro: registro
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "alterar",
                sistema: "sli",
                projeto: "mimobiliaria"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            notificacao.sucesso({transacao: cdTransacao, message:"Sucesso!", description:"Configuração salva com sucesso!"})

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const btnCidade_Click = () => {
        setExibeCidadesAtend ( true );
    }

    const btnRepLegal_Click = () => {
        setExibeReprLegais(true);
    }

    const btnPortalImob_Click = () => {
        setExibePortaisImob(true);
    }

    const popupTitle = "Configuração imobiliária";

    const fiValues = {
        flgLocLead: false,
        flgLocMaisInfo: false,
        flgLocProposta: false,
        flgLocVisita: false,
        flgVenLead: false,
        flgVenMaisInfo: false,
        flgVenProposta: false,
        flgVenVisita: false,
        flgExibeCartImob: false,
        flgCompartilhaCartImob: false,
        flgClassifGrpImv: "R",
        flgAtivo: "S",
        flgDestinoPautaMaisInfo: "L",
        flgExibeImvPrp: "N",
        flgExpDados: "N",
        flgPacoteUso: "F",
        flgPromocaoAssinat: "U",
        flgSiteImob: "N",
    }

    return (
        <ErrorBoundary>
            <IY2BReactModal
                left={MARGIN_LEFT}
                top={MODAL_CONTENT_TOP}
                width={MODAL_CONTENT_WIDTH}
                marginBottom={MARGIN_BOTTOM}
                exibePopUp={exibePopUp}
                onRequestClose={onRequestClose}
                bodyOpenClassName={"editar-config-imob"}
                title={popupTitle}
                api={modalRefApi}
                body={
                    <div className={"body-editar-config-imob"}>
                        <ProForm
                            formRef = { formRef }
                            isKeyPressSubmit = {true}
                            initialValues={fiValues}
                            onFinish = { async ( ) => {
                                if(refTelaProtegida.current === true) return;
                                await doSalvar();
                            }}
                            submitter={{
                                // Completely customize the entire area
                                render : ( props , doms ) => {
                                    return null;
                                }
                            }}
                            onValuesChange={async (changeValues, allValues) => {
                                /*
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "cmbCdTipoAtendimento", cmbCdTipoAtendimento_OnChange, true);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCliente_NoPessoa", acCliente_OnChange, true);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCliente_NrCGCCIC", acCliente_OnChange, true);

                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCentroCusto", acCentroCusto_OnChange);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "vlrDesconto", onUpdateVlrTotal);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "flgOrcamento", flgOrcamento_OnChange);
                                conteudoAlterado.fireOnChangeFFR(changeValues, allValues, "itens", "vlrOrcPecas", onUpdateVlrTotal);
                                conteudoAlterado.fireOnChangeFFR(changeValues, allValues, "itens", "vlrOrcServ", onUpdateVlrTotal);
                                */
                            }}
                            onFieldsChange = {async (changedFields) => {
                                conteudoAlterado.updateConteudoAlterado(changedFields);
                            }}
                        >
                            <div className={"pro-form-group-spacer"}>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={24} md={12} id={"colTratamentoLeads"}>
                                            <Row gutter={[8, 16]}>
                                                <Col span={24} className={"title"}>
                                                    <label>Tratamento de leads</label>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 16]}>
                                                <Col span={12}>
                                                    <ProFormSwitch
                                                        width={"xl"}
                                                        name={"flgVenLead"}
                                                        label={"Vendas"}
                                                        checkedChildren="Ativo" unCheckedChildren="Indisponível"
                                                    />
                                                </Col>
                                                <Col span={12}>
                                                    <ProFormSwitch
                                                        width={"xl"}
                                                        name={"flgLocLead"}
                                                        label={"Locações"}
                                                        checkedChildren="Ativo" unCheckedChildren="Indisponível"
                                                    />
                                                    <IY2BProFormFiller />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Row gutter={[8, 16]}>
                                                <Col span={24} className={"title"}>
                                                    <label>Carteira imobiliária</label>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 16]}>
                                                <Col span={2}>
                                                    <IY2BProFormFiller />
                                                </Col>
                                                <Col span={11}>
                                                    <ProFormSwitch
                                                        width={"xl"}
                                                        name={"flgExibeCartImob"}
                                                        label={"Apresenta de outra imobiliária ?"}
                                                        checkedChildren="Sim" unCheckedChildren="Não"
                                                    />
                                                </Col>
                                                <Col span={11}>
                                                    <ProFormSwitch
                                                        width={"xl"}
                                                        name={"flgCompartilhaCartImob"}
                                                        label={"Compartilha a própria ?"}
                                                        checkedChildren="Sim" unCheckedChildren="Não"
                                                    />
                                                    <IY2BProFormFiller />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={24} md={12} id={"colAcoesVen"}>
                                            <Row gutter={[8, 16]}>
                                                <Col span={24} className={"title"}>
                                                    <label>Ações disponíveis nos anúncios de vendas</label>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 16]}>
                                                <Col span={7}>
                                                    <ProFormSwitch
                                                        width={"lg"}
                                                        name={"flgVenVisita"}
                                                        label={"Visita"}
                                                        checkedChildren="Disponível" unCheckedChildren="Indisponível"
                                                    />
                                                </Col>
                                                <Col span={7}>
                                                    <ProFormSwitch
                                                        width={"lg"}
                                                        name={"flgVenProposta"}
                                                        label={"Proposta"}
                                                        checkedChildren="Disponível" unCheckedChildren="Indisponível"
                                                    />
                                                </Col>
                                                <Col span={7}>
                                                    <ProFormSwitch
                                                        width={"lg"}
                                                        name={"flgVenMaisInfo"}
                                                        label={"Mais informações"}
                                                        checkedChildren="Disponível" unCheckedChildren="Indisponível"
                                                    />
                                                </Col>
                                                <Col span={3}>
                                                    <IY2BProFormFiller />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Row gutter={[8, 16]}>
                                                <Col span={24} className={"title"}>
                                                    <label>Ações disponíveis nos anúncios de locações</label>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 16]}>
                                                <Col span={3}>
                                                    <IY2BProFormFiller />
                                                </Col>
                                                <Col span={7}>
                                                    <ProFormSwitch
                                                        width={"lg"}
                                                        name={"flgLocVisita"}
                                                        label={"Visita"}
                                                        checkedChildren="Disponível" unCheckedChildren="Indisponível"
                                                    />
                                                </Col>
                                                <Col span={7}>
                                                    <ProFormSwitch
                                                        width={"lg"}
                                                        name={"flgLocProposta"}
                                                        label={"Proposta"}
                                                        checkedChildren="Disponível" unCheckedChildren="Indisponível"
                                                    />
                                                </Col>
                                                <Col span={7}>
                                                    <ProFormSwitch
                                                        width={"lg"}
                                                        name={"flgLocMaisInfo"}
                                                        label={"Mais informações"}
                                                        checkedChildren="Disponível" unCheckedChildren="Indisponível"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={24} md={6}>
                                            <RadioGroup
                                                width={"xl"}
                                                name="flgClassifGrpImv"
                                                label="Classificar grupo de imóveis por"
                                                tooltip = { "Forma de classificação do grupo de imóveis ?" }
                                                rules={[
                                                ]}
                                                options={[
                                                    {
                                                        label: 'Cidade',
                                                        value: 'C',
                                                    },
                                                    {
                                                        label: 'Região',
                                                        value: 'R',
                                                    },
                                                ]}
                                            />
                                        </Col>
                                        <Col xs={24} md={14}>
                                            <AutoComplete
                                                formRef = { formRef }
                                                name = "cmbCdPessoaBidDefault"
                                                label = "Usuário default do BID"
                                                tooltip = "Usuário default do BID"
                                                placeholder={"Selecione um usuário"}
                                                rules={[
                                                    {required: true, message:"Informe o usuário responsável default pelo BID de atendimento dos anuncios"},
                                                ]}
                                                width={"xl"}
                                                fetchOptions={fetchUsuariosDaEmpresa(dataProvider.cdPessoaEmpAtual)}
                                                api = { cmbCdPessoaBidDefaultRefApi }
                                            />
                                        </Col>
                                        <Col xs={24} md={4}>
                                            <IY2BProFormFiller />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col span={6}>
                                            <Button type={"default"} icon={iconCity} onClick={ btnCidade_Click }>&nbsp;Cidades atendidas</Button>
                                        </Col>
                                        <Col span={6}>
                                            <Button type={"default"} icon={iconSignature} onClick={ btnRepLegal_Click }>&nbsp;Representantes legais</Button>
                                        </Col>
                                        <Col span={6}>
                                            <Button type={"default"} icon={iconWeb} onClick={ btnPortalImob_Click }>&nbsp;Vínculo com portais</Button>
                                        </Col>
                                        <Col span={6}>
                                            <IY2BProFormFiller />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                                <IY2BHiddenRow>
                                    <IY2BProFormHidden name={"xx"}/>
                                    <IY2BProFormHidden name={"flgAtivo"}/>
                                    <IY2BProFormHidden name={"flgDestinoPautaMaisInfo"}/>
                                    <IY2BProFormHidden name={"flgExibeImvPrp"}/>
                                    <IY2BProFormHidden name={"flgExpDados"}/>
                                    <IY2BProFormHidden name={"flgPacoteUso"}/>
                                    <IY2BProFormHidden name={"flgPromocaoAssinat"}/>
                                    <IY2BProFormHidden name={"flgSiteImob"}/>
                                    <IY2BProFormHidden name={"noPessoaImobiliaria"}/>
                                    <IY2BProFormHidden name={"noFantasiaImobiliaria"}/>
                                    <IY2BProFormHidden name={"nrCGCCICImobiliaria"}/>
                                    <IY2BProFormHidden name={"nrInsMunicipImobiliaria"}/>
                                    <IY2BProFormHidden name={"cdGrupoImovel"} />
                                    <IY2BProFormHidden name={"cdImobiliaria"} />
                                    <IY2BProFormHidden name={"cdPessoaImobiliaria"} />
                                    <IY2BProFormHidden name={"cdPlanoContrVenda"} />
                                    <IY2BProFormHidden name={"chWorkflow"} />
                                    <IY2BProFormHidden name={"dtAlt"} />
                                    <IY2BProFormHidden name={"dtCad"} />
                                    <IY2BProFormHidden name={"dtConfigImob"} />
                                    <IY2BProFormHidden name={"dtInativo"} />
                                    <IY2BProFormHidden name={"htmlPILocatario"} />
                                    <IY2BProFormHidden name={"htmlPIProprietario"} />
                                    <IY2BProFormHidden name={"id_Externo"} />
                                    <IY2BProFormHidden name={"noComplEnd"} />
                                    <IY2BProFormHidden name={"noLograd"} />
                                    <IY2BProFormHidden name={"noSiteImob"} />
                                    <IY2BProFormHidden name={"nrImovel"} />
                                    <IY2BProFormHidden name={"nrItemDescVol"} />
                                    <IY2BProFormHidden name={"nrTelFixo"} />
                                    <IY2BProFormHidden name={"qtdeAte"} />
                                    <IY2BProFormHidden name={"qtdeDe"} />
                                    <IY2BProFormHidden name={"qtdeMaxCorretores"} />
                                    <IY2BProFormHidden name={"tpEtapaCad"} />
                                    <IY2BProFormHidden name={"txConfigCli"} />
                                    <IY2BProFormHidden name={"txMsgInicialAnuncio"} />
                                    <IY2BProFormHidden name={"txTokenCli"} />
                                    <IY2BProFormHidden name={"txUrlArqImpImovel1"} />
                                    <IY2BProFormHidden name={"txUrlArqImpImovel2"} />
                                    <IY2BProFormHidden name={"txUrlModeloContrProp"} />
                                </IY2BHiddenRow>
                            </div>
                        </ProForm>
                        <CidadesAtendidas
                            cdTransacao={cdTransacao}
                            exibePopUp={ exibeCidadesAtend }
                            onRequestClose={() => {
                                setExibeCidadesAtend ( false );
                            }}
                            dataProvider={dataProviderImob}
                            cdPessoaEmpAtual = { props.cdPessoaEmpAtual }
                        />
                        <RepresLegais
                            cdTransacao={cdTransacao}
                            exibePopUp={ exibeReprLegais }
                            onRequestClose={() => {
                                setExibeReprLegais( false );
                            }}
                            dataProvider={dataProviderImob}
                            cdPessoaEmpAtual = { props.cdPessoaEmpAtual }
                        />
                        <PortaisImob
                            cdTransacao={cdTransacao}
                            exibePopUp={ exibePortaisImob }
                            onRequestClose={() => {
                                setExibePortaisImob( false );
                            }}
                            dataProvider={dataProviderImob}
                            cdPessoaEmpAtual = { props.cdPessoaEmpAtual }
                        />
                    </div>
                }
                actions={
                    <Space style={{
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <Space>
                            &nbsp;
                        </Space>
                        <Space>
                            <Button type={"primary"} className={"salvar"} onClick={async () => await doSalvar()} disabled={refTelaProtegida.current}>
                                Salvar
                            </Button>
                        </Space>
                    </Space>
                }
            />
        </ErrorBoundary>
    );

});


export default EditarConfigImob;

import '../assets/css/tema.scss';
import '../assets/css/containerContent.scss';
//v5 import "antd/dist/antd.css";

import React, {useState, useEffect, useContext} from 'react';
import {connect} from 'react-redux';
import {Layout} from 'antd';
import {scrollToTop} from "../lib/iy2b-javascript";
import TransacaoLoadingIndicator from "../componentes/LoadingIndicator/TransacaoLoadingIndicator";
import {WorkspaceContext} from "./Workspace/workspace";
import {ContainerContentContext} from "./ContainerContent";

const { Content } = Layout;

export const TransacaoContentContext = React.createContext(null);

const TransacaoContent = ( props ) => {

    const { withFooterToolbar = false } = props;

    const [layoutHeight, setLayoutHeight] = useState(0);

    const [loaderHeight, setLoaderHeight] = useState(0);

    const { habilitaHelp, desabilitaHelp } = useContext(WorkspaceContext);

    const { isCurrentContent, hasHelp, getCurrentHelp } = useContext(ContainerContentContext);

    const cdTransacao = props.tcKey;

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        const height = document.getElementById('containerContent').clientHeight;

        // gap do tabHeader = 50
        setLayoutHeight(height - 50 - (withFooterToolbar===true?60:0) );

        setLoaderHeight(height - 50 );

        return () => {

        };

    }, []);

    useEffect(() => {

        const currentContent = isCurrentContent(cdTransacao);

        if(currentContent === true) {

            const temAjuda = hasHelp (cdTransacao);

            if(temAjuda === true) {
                const pane = getCurrentHelp(cdTransacao);
                habilitaHelp(pane);
            } else {
                desabilitaHelp();
            }

        }

        return () => {

        };

    }, [isCurrentContent(cdTransacao)]);

    const layoutId = "transacaoContent-" + props.tcKey;

    const contextValue = {
        containerName: layoutId,
        containerHeight: () => {
            const height = document.getElementById(layoutId)?.clientHeight;
            return height;
        },
        scrollToTop: () => {
            scrollToTop(layoutId);
        },
        getCurrentTransacao: () => props.tcKey,
    };

    return (
        <Layout id={layoutId} className={"transacaoContent"} style={{
            height: layoutHeight
        }}>
            <TransacaoContentContext.Provider value={contextValue}>
                <TransacaoLoadingIndicator height={loaderHeight} area={"tc-area-" + props.tcKey} />
                <Content className={"tc-body"}
                         style={{
                             height: layoutHeight
                         }}
                >
                    {React.cloneElement(props.content, { availableHeight: layoutHeight })}
                </Content>
            </TransacaoContentContext.Provider>
        </Layout>
    );

};

const mapStateToProps = function( {appConfig, userSession} ) {

    return {
        appConfig: appConfig,
        userSession: userSession
    };

};

export default connect(mapStateToProps)(TransacaoContent);

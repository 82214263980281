//v5 import "antd/dist/antd.css";
import '../assets/css/tema.scss';
import '../assets/css/empresaDropdown.scss';

import React, {useState, useEffect, useContext} from 'react';
import {Avatar, Image, List, Tooltip} from "antd";
import ReactModal from 'react-modal';
import {offSetFromDocument} from "../lib/iy2b-javascript";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {CUSTOM} from "../assets/iconografia";
import {WORKSPACE_LOADING_INDICATOR_AREA, WorkspaceContext} from "../pages/Workspace/workspace";
import {pesquisarPlanoContrVenda} from "../lib/Servicos";


const iconBuilding = CUSTOM.BUILDING_ICON_WHITE_2X;


const pesquisaPlanos = async () => {
    const planos = await pesquisarPlanoContrVenda(WORKSPACE_LOADING_INDICATOR_AREA, 0);
    return planos;
}


const EmpresaDropdown = props => {

    const breaks = useBreakpoint();

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [exibeDropdown, setExibeDropDown] = useState(false);
    const [dropDownOffSet, setDropDownOffSet] = useState({top:0, left:0, width: 0});

    const [dsPlanos, setDsPlanos] = useState([]);

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        const offset = offSetFromDocument(document.getElementById('empresaDropdown'));

        offset.width = document.getElementById('btnEmpresa').getBoundingClientRect().width;

        setDropDownOffSet (offset);

        pesquisaPlanos().then(planos => setDsPlanos(planos));

        return () => {

        };

    }, []);


    const onClickEmpresa = (value) => {
        props.onSelect(value);
        setExibeDropDown(false);
    }

    const { getUserSession } = useContext(WorkspaceContext);

    const userSession = getUserSession();

    const aPlanoContratado = dsPlanos.filter(plano => plano.cdPessoaEmpPCtrV === userSession.planoContratado.cdPessoaEmpPCtrV && plano.cdPlanoContrVenda === userSession.planoContratado.cdPlanoContrVenda);

    const planoContratado = aPlanoContratado.isEmpty() === true ? null : aPlanoContratado[0];

    // console.log("userSession", userSession.planoContratado, dsPlanos, planoContratado?.noPlano);

    const classNameStatusDropdown = exibeDropdown ? "open" : "close";

    const noEmpresaAtual = props.empresaAtual != null ? props.empresaAtual?.pessoaEmp?.noFantasia.trim() : "";
    const cdPessoaEmpAtual = props.empresaAtual != null ? props.empresaAtual.pessoaEmp.cdPessoa : 0;
    const logoBD = props.empresaAtual != null && props.empresaAtual?.logoBD != null? "data:image/*;base64," + props.empresaAtual.logoBD : null;

    const wsVersion = parseInt(process.env.REACT_APP_WORKSPACE_VERSION);

    const currentDropDownOffSet = {
        left: (wsVersion===2) ? (breaks.xs===true)?10:(document.getElementById('containerMenuLateral')?.clientWidth-1) : (breaks.xs===true)?10:dropDownOffSet.left
    };

    const scrollerWidth = (breaks.xs===true)?350:412;

    const conteudoBotaoEmpresa = (breaks.xs===true)?
        iconBuilding
        :
        <div className={"conteudoBotaoEmpresa"}>
            <div className={"noEmpresa"}>
                {noEmpresaAtual}
            </div>
            <div className={"noPlano"}>
                {planoContratado?.noPlano}
            </div>
        </div>
    ;

    const logoSize = (breaks.xs===true)?38:46;

    const avatarLogo = (logoBD === null) ? null :
        (wsVersion===2) ? null :
        <>
            <Avatar
                size={logoSize}
                shape={"circle"}
                src={logoBD}
            >
            </Avatar>
            &nbsp;
        </>
    ;

    const iconCaret = (props.dataSource.length>1)?CUSTOM.ANGLE_DOWN_ICON_WHITE:null;

    return (
        <Tooltip placement="bottom" title={"Selecione a empresa atual"} open={tooltipVisible}>
            <div id={"empresaDropdown"} className={[classNameStatusDropdown].toClassName()} onMouseEnter={() => setTooltipVisible(true)} onMouseLeave={() => setTooltipVisible(false)}>
                <div className={"containerBtnEmpresa"}>
                    <div id={"btnEmpresa"}>
                        <a  onClick={() => {
                            if(props.dataSource.length<=1 && (breaks.xs===false)) return;
                            setExibeDropDown(!exibeDropdown);
                            setTooltipVisible(false);
                        }}>
                            {avatarLogo}
                            {conteudoBotaoEmpresa}
                            &nbsp;
                            <span className={"containerCaret"}>
                                {iconCaret}
                            </span>
                        </a>
                    </div>
                </div>
                <ReactModal
                    isOpen={exibeDropdown}
                    className={"modalContent"}
                    overlayClassName={"modalOverlay"}
                    bodyOpenClassName={"empresaDropdownModal"}
                    closeTimeoutMS={500}
                    shouldFocusAfterRender={true}
                    onRequestClose={() => setExibeDropDown(false)}
                    style={{
                        content: {
                            left: (currentDropDownOffSet.left + 1) + 'px',
                        }
                    }}
                >
                    <div style={{width:scrollerWidth}}>
                        <div className={"scroller"} >
                            <List
                                dataSource={props.dataSource}
                                renderItem={item => {
                                    const itemClassName = (item.pessoaEmp.cdPessoa === cdPessoaEmpAtual) ? "active": "";
                                    const empLogo = (item.logoBD != null) ?  <Image preview={false} width={60} src={`data:image/jpeg;base64,${item.logoBD}`} /> : null;
                                    return (
                                        <List.Item key={item.pessoaEmp.cdPessoa} className={itemClassName}>
                                            <a onClick={() => onClickEmpresa(item)} className={"opcao"}>
                                                {empLogo}
                                                {item.pessoaEmp.noFantasia}
                                            </a>
                                        </List.Item>
                                    )
                                }}
                            >
                            </List>
                        </div>
                    </div>
                </ReactModal>
            </div>
        </Tooltip>
    );

};

export default EmpresaDropdown;


//v5 import "antd/dist/antd.css";
import "./style.scss";

import React from 'react';
import {usePromiseTracker} from "react-promise-tracker";
import IY2BLogoSpinner from "../IY2BLogoSpinner/IY2BLogoSpinner";

const WorkspaceLoadingIndicator = props => {

    const { promiseInProgress } = usePromiseTracker( {area: props.area});

    return (
        promiseInProgress &&
        <div className="wrkspLoadingPosition">
            <div className="wrkspBackdropLoading"
                 style={{
                     minHeight: props.height
                 }}
            >
                <div
                    style={{
                        display: "block",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: "64px",
                        left: ((props.width/2) - (55/2)) + "px",
                        padding: "5px"
                    }}
                >
                    <IY2BLogoSpinner />
                </div>
            </div>
        </div>
    );

};
/**
 * <Loader type="Circles" color="firebrick" height="45" width="45"/>
 */
export default WorkspaceLoadingIndicator;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../framework/assets/css/tema.scss';
import './style.scss';

import {
    notificacaoErroRequest, notificacaoErroValidForm,
    useConteudoAlterado,
    useGridWidth,
    useModoExecucao,
    useStateRef,
    useWindowSize
} from "../../framework/lib/iy2b-react";
import React, {useEffect, useRef, useState} from "react";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {
    campoInformado,
    campoInformadoString, cnpjValido,
    confirm,
    cpfValido,
    ObjectUtils
} from "../../framework/lib/iy2b-javascript";
import {postJSON} from "../../framework/lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../framework/pages/Workspace/workspace";
import moment from "moment";
import {ATIVIDADE_SEM_DEFINICAO, GRUPO_ECONOMICO_SEM_DEFINICAO} from "../../lib/servicosPortalIY2B";
import {Button, notification, Popconfirm, Space} from "antd";
import IY2BReactModal from "../../framework/componentes/IY2BReactModal";
import {ProForm, ProFormDigit, ProFormText} from "@ant-design/pro-components";
import IY2BProFormMasked from "../../framework/componentes/IY2BProFormMasked";
import RadioGroup from "../../framework/componentes/proform/RadioGroup/radioGroup";
import FoldedEnderecoPessoa from "../../framework/componentes/FoldedEnderecoPessoa/foldedEnderecoPessoa";
import FoldedFormField from "../../framework/componentes/proform/FoldedFormField/foldedFormField";
import ComboBox from "../../framework/componentes/proform/ComboBox/comboBox";
import FoldedChecklistPessoa from "../../framework/componentes/FoldedChecklistPessoa/foldedChecklistPessoa";
import IY2BHiddenRow from "../../framework/componentes/IY2BHiddenRow";
import IY2BProFormHidden from "../../framework/componentes/IY2BProFormHidden";
import NavegadorRegistro from "../../framework/componentes/NavegadorRegistro/navegadorRegistro";
import FoldedContatoPessoa from "../../framework/componentes/FoldedContatoPessoa/foldedContatoPessoa";
import {notificacao} from "../../framework/lib/Notificacao";
import {CUSTOM} from "../../framework/assets/iconografia";


const TIPO_PESSOA_CLIENTE = {
    value: "CLI",
    label: "CLIENTE",
    data: {
        cdTipoPes: "CLI"
    }
};

const EditarCliente = ( props ) => {

    const { exibePopUp, onRequestClose, salvar, excluir, dataProvider, cdTransacao = 0 } = props;

    const { smaller = false, closeAfterSave = false, onNovoCliente=null } = props ;

    const { registros } = props;

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const defaultMarginLeft = [10, 30];
    const defaultContentTop = [30];

    if (smaller === true) {
        defaultMarginLeft[0] = 7+7;
        defaultMarginLeft[1] = 30+30;
        defaultContentTop[0] = 30+30;
    }

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_) <= 0) ? defaultMarginLeft[0] : defaultMarginLeft[1];
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT * 2));
    const MODAL_CONTENT_TOP = defaultContentTop[0];

    const modalRefApi = useRef();
    const formRef = useRef();
    const navegadorRefApi = useRef();

    const forceUpdate = useForceUpdate();

    const modoExecucao = useModoExecucao("INC");
    const conteudoAlterado = useConteudoAlterado();
    const [registroDaTelaInicial, setRegistroDaTelaInicial] = useState(null);

    const inputRefNrCGCCIC = useRef();

    const cmbFormaPagtoRefApi = useRef();
    const cmbTipoCtaBancRefApi = useRef();
    const cmbBancoFavRefApi = useRef();

    const foldedFFDadosBancRefApi = useRef();

    const foldedEnderecoPessoaRefApi = useRef();
    const foldedChecklistPessoaRefApi = useRef();
    const foldedFFInfAdicRefApi = useRef();
    const foldedContatoPessoaRefApi = useRef();

    const noFantasiaRef = useRef();
    const noPessoaRef = useRef();

    const [exibeDadosRep, setExibeDadosRep] = useState(false);
    const [enderecoDefault, setEnderecoDefault, refEnderecoDefault] = useStateRef(null);
    const [dsChecklist, setDsChecklist, refDsChecklist] = useStateRef( [] );
    const [dsContato, setDsContato, refDsContato] = useStateRef( [] );

    const [origemWsCNPJCPF, setOrigemWsCNPJCPF] = useState(false);

    const [revisarDadosBanc, setRevisarDadosBanc] = useState(false);

    const fiValues = {
        flgAtivo: "S",
        flgJuridico: "J",
        flgTipoChavePIX: "S",
        chavePIX: "",
        nrLigacoes: 0,
        pessoaJaExiste: "N",
        "flgME":"N",
        "flgContribICMS":"N",
        "flgOptaSimples":"N",
        "flgMEI": "N"
    };

    useEffect(() => {

        if(campoInformado(registros)) {

            if(exibePopUp) {

                if(registros.length > 0) {

                    pesquisarPk(registros[0]);

                } else {

                    novoRegistro();

                }

            }

        } else {

            novoRegistro();

        }

        return () => {

        };

    }, [props.registros]);

    useEffect(() => {

        console.log(dataProvider);

        return () => {

        };

    }, [props.dataProvider]);

    const pesquisarPk = async (registro) => {

        try {

            novoRegistro();

            const payload = {
                acaoAExecutar: "acaoCRUD",
                "versaoHTML": "S",
                registro: {
                    cliente: {
                        "cdPessoaCli": registro.cdPessoaCli,
                        "cdCli": registro.cdCli
                    }
                }
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisarPk",
                sistema: "scr",
                projeto: "mcliente"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    setRegistroNaTela(msg.modeloPadrao);

                } else if (msg.id === "listaChecklistOrigem") {

                    setDsChecklist(msg.lista.map(item => {
                        return {
                            key: item.cdChecklist,
                            ...item
                        }
                    }));

                }

            });

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const setRegistroNaTela = async (registro) => {

        // console.log(registro);

        const attrDaTela = [
            "cdPessoaCli",
            "cdCli",
            "sexo",
            "cbo",
            "cdAgenciaFav",
            "cdINSS",
            "cdPrefeitura",
            "classeINSS",
            "digCdCli",
            "estCivil",
            "flgAtivo",
            "flgCalcImp",
            "flgContribICMS",
            "flgCooperativa",
            "flgEmiteNFV",
            "flgINSS",
            "flgJuridico",
            "flgME",
            "flgMEI",
            "flgOptaSimples",
            "noConjuge",
            "noFantasia",
            "noOrgaoEmissor",
            "noPessoa",
            "nrCGCCIC",
            "nrCTPS",
            "nrCtaBcoFav",
            "nrDepensIRRF",
            "nrInsEst",
            "nrInsMunicip",
            "nrInsSuframa",
            "nrLigacoes",
            "nrNIF",
            "nrPassaporte",
            "nrRG",
            "nrRNE",
            "percComis"
        ];

        const attrDaTelaCliente = [
            "chavePIX",
            "flgTipoChavePIX"
        ];

        const formaPagto = cmbFormaPagtoRefApi.current.setFieldValue(registro.cdFormaPagto);
        const tipoCtaBanc = cmbTipoCtaBancRefApi.current.setFieldValue(registro.cdTipoCtaBanc);
        const bancoFav = cmbBancoFavRefApi.current.setFieldValue(registro.cdBcoFav);

        const registroDaTela = {
            ...ObjectUtils.valuesFrom(registro, attrDaTela),
            ...ObjectUtils.valuesFrom(registro.cliente, attrDaTelaCliente),
            dtNasc: registro.dtNasc?.toMoment().toScreen(),
            dtNasc1Fil: registro.dtNasc1Fil?.toMoment().toScreen(),
            dtNasc2Fil: registro.dtNasc2Fil?.toMoment().toScreen(),
            dtAlt: registro.cliente.dtAlt.toMoment().toTextScreen(),
            dtCad: registro.cliente.dtCad.toMoment().toTextScreen(),
            dtInativo: registro.cliente.dtInativo.toMoment().toTextScreen(),
        };

        if(registroDaTela.flgTipoChavePIX === "F" || registroDaTela.flgTipoChavePIX === "J") {
            registroDaTela.flgTipoChavePIX = "X";
        }

        console.log(registroDaTela);

        formRef.current?.setFieldsValue( registroDaTela );

        setRegistroDaTelaInicial ( {
            ...registroDaTela,
            formaPagto: formaPagto,
            tipoCtaBanc: tipoCtaBanc,
            bancoFav: bancoFav,
        });

        forceUpdate();

        // console.log(registroDaTelaInicial);

        controleExibicao ( registroDaTelaInicial );

        modoExecucao.setModoExecAlteracao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const controleExibicao = (registro) => {

        if(campoInformado(registro)) {

            // flgTipoChavePIX_OnChange (registro.flgTipoChavePIX);

        }

    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();

            inputRefNrCGCCIC.current.focus();

        }, 1);

    }

    const getRegistroDaTela = () => {

/*

{
   "cliente":{
      "flgJuridico":"J",
      "nrLigacoes":0,
      "nrCGCCIC":"81313432992831",
      "noPessoa":"santa padaria",
      "noFantasia":"sta padaria",
      "cdPessoaEmpMatriz":{
         "cdPessoa":1
      },
      "cdGrpEconomico":0,
      "cdAtividade":0,
      "flgAtivo":"S",
      "dtCad":"2022/03/15",
      "dtInativo":"1901/01/01",
      "dtAlt":"1901/01/01",
      "cdFormaPagto":45,
      "cdBcoFav":"33",
      "flgME":"S",
      "flgContribICMS":"S",
      "flgOptaSimples":"S",
      "flgNacional":"S",
      "flgProspect":"N",
      "flgInterlocutor":"N",
      "tpCredito":"I",
      "dtIniAtividade":"2022/03/15",
      "cdPessoaCredor":10
   },
   "pesFisica":{
      "dtNasc":"1901/01/01",
      "sexo":"M",
      "estCivil":"S",
      "dtNasc1Fil":"1901/01/01",
      "dtNasc2Fil":"1901/01/01",
      "flgINSS":"S"
   },
   "flgJuridico":"J",
   "nrLigacoes":0
}

 */
        const ffValues = formRef.current?.getFieldsValue();

        const attrDaTelaCliente = [ "cdPessoaCli", "cdCli",
            "flgJuridico", "noPessoa", "noFantasia", "chavePIX",
            "nrLigacoes", "cdAgenciaFav", "nrCtaBcoFav",
            "flgME", "flgContribICMS", "flgOptaSimples", "flgMEI",
            "pessoaJaExiste",
        ];
        // "digAgencia"

        const attrDaTelaPesFis = [
            "nrRG", "noOrgaoEmissor", "noConjuge",
            "flgINSS", "sexo", "estCivil",
            "cdPrefeitura", "cdINSS", "classeINSS", "cbo"
        ];

        const { flgJuridico } = ffValues;

        let { dtCad, dtAlt, flgTipoChavePIX, nrCGCCIC, digCdCli } = ffValues;

        if(flgTipoChavePIX === "X") {
            flgTipoChavePIX = flgJuridico;
        }

        if(campoInformado(digCdCli) === false) digCdCli = "";

        nrCGCCIC = nrCGCCIC.retiraCaracteres(".-/");

        if(dtCad === undefined) {
            dtCad = moment();
        } else {
            dtCad = dtCad.toMoment("DD/MM/YYYY");
        }
        if(dtAlt === undefined) {
            dtAlt = window.dataZerada;
        } else {
            dtAlt = dtAlt.toMoment("DD/MM/YYYY");
        }

        const registro = {
            "cliente": {
                ...ObjectUtils.valuesFrom(ffValues, attrDaTelaCliente),
                "cdPessoaEmpMatriz":{
                    "cdPessoa": dataProvider.cdPessoaEmpAtual
                },
                nrCGCCIC: nrCGCCIC,
                flgTipoChavePIX: flgTipoChavePIX,
                cdFormaPagto: ffValues.cmbFormaPagto.value,
                cdBcoFav: ffValues.cmbBancoFav.data.cdBco,
                cdTipoCtaBanc: ffValues.cmbTipoCtaBanc.value,
                dtCad: dtCad.format("YYYY/MM/DD"),
                dtInativo: "1901/01/01",
                dtAlt: dtAlt.format("YYYY/MM/DD"),
                flgAtivo: "S",
                flgCooperativa: "N",
                flgEmiteNFV: "N",
                percComis: "0",
                "flgNacional":"S",
                "flgProspect":"N",
                "flgInterlocutor":"N",
                "tpCredito":"I",
                cdGrpEconomico: GRUPO_ECONOMICO_SEM_DEFINICAO.value,
                cdAtividade: ATIVIDADE_SEM_DEFINICAO.value,
                "cdPessoaCredor": dataProvider.cdPessoaEmpAtual,
                digCdCli: digCdCli
            },
            "pesFisica":{
                cdPessoa: ffValues.cdPessoaCli,
                sexo: "M",
                estCivil: "S",
                flgINSS: "N",
                cdNacional: 1,
                "dtNasc": "1901/01/01",
                "dtNasc1Fil": "1901/01/01",
                "dtNasc2Fil": "1901/01/01",
            },
            "listaTipoPessoa": [TIPO_PESSOA_CLIENTE].map(item => { return {cdTipoPes: item.value}}),
            "listaTipoDespesaVinc": []
        };

        registro.cliente.cdAgenciaFav = (registro.cliente.cdAgenciaFav||"") + "";

        return registro;

    }

    const doExcluir = async () => {

        const registro = getRegistroDaTela();

        await excluir ( registro.cliente );

    }

    const novoRegistro = () => {

        setRevisarDadosBanc(false);

        setDsChecklist ( [] );
        setDsContato ( [] );

        setOrigemWsCNPJCPF ( false );

        formRef?.current?.resetFields();

        foldedEnderecoPessoaRefApi.current?.clear();

        controleExibicao ( );

        modoExecucao.setModoExecInclusao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const validaTela = async (params) => {

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        return true;

    };

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return;

            if(conteudoAlterado.isConteudoAlterado() === false) {
                notificacao.aviso({message:"Sem alteração", description:"Nenhuma informação alterada para ser salva !"})
                return;
            }

            const registro = getRegistroDaTela();

            const respSalvar = await salvar (registro);

            if(respSalvar === null) return;

            if(respSalvar === false) return;

            if(modoExecucao.isInclusao() === true) {

                formRef.current?.setFieldsValue( {
                    cdPessoaCli: respSalvar.cdPessoaCli,
                    cdCli: respSalvar.cdCli,
                    nrLigacoes: respSalvar.nrLigacoes
                });

                modoExecucao.setModoExecAlteracao();

                conteudoAlterado.limpaConteudoAlterado(formRef.current);

                posicionaPrimeiroCampo();

                forceUpdate();

                if(origemWsCNPJCPF === true && campoInformadoString(refEnderecoDefault.current.cep) === true) {

                    setTimeout(() => {
                        foldedEnderecoPessoaRefApi.current.salvarNovoEndereco({
                            ...refEnderecoDefault.current,
                            cdPessoa: respSalvar.cdPessoaCli
                        }).then(respSalvarEnd => {
                            if(respSalvarEnd === true) {
                                if(campoInformado(onNovoCliente) === true) {
                                    onNovoCliente({
                                        key: respSalvar.cdPessoaCli,
                                        value: respSalvar.cdPessoaCli
                                    });
                                }
                                if(closeAfterSave === true) {
                                    onRequestClose();
                                }
                            }
                        });
                    }, 1);

                } else {

                    confirm({
                        message:"Endereço",
                        description: "Deseja cadastrar um novo endereço agora ?",
                        onOk: () => {
                            setTimeout(() => {
                                if(respSalvar.flgJuridico === "F") {
                                    foldedEnderecoPessoaRefApi.current.novoEndereco();
                                } else {
                                    foldedEnderecoPessoaRefApi.current.novoEndereco(refEnderecoDefault.current);
                                }
                            }, 1);
                        },
                        onCancel: () => {
                            if(closeAfterSave === true) {
                                onRequestClose();
                            }
                        }
                    });

                }

            } else {

                if(navegadorRefApi.current.temProximo()) {

                    navegadorRefApi.current.navegaProximo();

                } else {

                    if(registros.length === 1) {

                        novoRegistro();

                    } else {

                        onRequestClose();

                    }

                }

            }

        } catch (e) {

            console.log(e);

            if(e.hasOwnProperty("errorFields")) {

                foldedFFDadosBancRefApi.current?.openOnErrors(e.errorFields);

            }

            notificacaoErroValidForm(e, cdTransacao);

        }

    }

    const navegaParaRegistro = async (posicao) => {

        await pesquisarPk(registros[posicao - 1]);

    }

    /*

     */

    const flgTipoChavePIX_OnChange = (value) => {

        const newValue = {
            chavePIX: ""
        }

        const ffValues = formRef.current?.getFieldsValue();

        if(campoInformadoString(ffValues.nrCGCCIC)) {

            const nrCGCCIC = ffValues.nrCGCCIC.retiraCaracteres("./-");

            if(value === "S") {

            } else if(value === "F" || value === "J" || value === "X") {
                newValue.chavePIX = nrCGCCIC;
            } else {

            }

        }

        formRef.current?.setFieldsValue(newValue);

    };

    const pesquisarNrCGCCIC = async (value) => {

        const nrCGCCIC = value.retiraCaracteres(".-/");

        try {

            const ffValues = {
                ...fiValues
            };

            if(nrCGCCIC.length === 11) {
                ffValues.flgJuridico = "F";
            } else {
                ffValues.flgJuridico = "J";
            }

            let enderecoWS = null;
            let modeloPessoa = null;
            let modeloCliente = null;

            let cnpjJaExisteNaEmpresa = false;

            let origemWsCNPJCPF = false;

            let focusOn = "noPessoa";

            const payload = {
                acaoAExecutar: "verificaCGCCIC",
                filtro: {"nrCGCCIC": nrCGCCIC},
                CGCCIC: nrCGCCIC,
                "versaoHTML": "S",
                "clientePorEmpresa": "S",
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "scr",
                projeto: "mcliente"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "MensagemIdentificada.Ok") {

                } else if(msg.id === "modeloPessoa") {

                    modeloPessoa = msg.modeloPadrao;

                } else if(msg.id === "modeloCliente") {

                    modeloCliente = msg.modeloPadrao;

                } else if(msg.id === "cnpjJaExisteNaEmpresa") {

                    cnpjJaExisteNaEmpresa = true;

                } else if(msg.id === "listaPesquisaWS") {

                    msg.lista.forEach(item => {

                        if(item.status === true) {
                            item.endereco.telefone = item.telefone?.substring(0,20);
                            if(campoInformadoString(item.endereco.cep) === true) {
                                enderecoWS = {
                                    ...item.endereco
                                };
                            }
                            ffValues.noFantasia = item.fantasia.substr(0, 20);
                            if(ffValues.noFantasia === "") {
                                ffValues.noFantasia = item.nome.substr(0, 20);
                            }
                            ffValues.noPessoa = item.nome.substr(0, 60);
                            focusOn = "noFantasia";
                            origemWsCNPJCPF = true;

                        } else {
                            ffValues.noFantasia = null;
                            ffValues.noPessoa = null;
                            ffValues.nrCGCCIC = null;
                            notificacao.erro({description:"Pesquisa CNPJ/CPF", message: item.msg});
                        }

                    });

                } else if(msg.id === "falhaValidacao") {

                    notificacao.erro({description:"Pesquisa CNPJ/CPF", message: msg.descricao});

                } else {

                    console.log(msg);

                }

            });

            setOrigemWsCNPJCPF(origemWsCNPJCPF);

            setEnderecoDefault(enderecoWS);

            if(modeloCliente !== null) {
                // cliente ja existe, fazer o pesquisaPK

                const resultPk = await pesquisarPk({
                    cdCli: modeloCliente.cdCli,
                    cdPessoaCli: modeloCliente.cdPessoaCli
                });

                if(cnpjJaExisteNaEmpresa === false) {

                    // limpar os dados da conta bancaria e avisar pra preencher e salvar

                    const formaPagto = cmbFormaPagtoRefApi.current.setFieldValue(parseInt(dataProvider.mapaParametros["SGR.FORMAPAGTOPADRAO"]));
                    const tipoCtaBanc = cmbTipoCtaBancRefApi.current.setFieldValue(parseInt(dataProvider.mapaParametros["SGR.TIPOCONTAPADRAO"]));
                    const bancoFav = cmbBancoFavRefApi.current.setFieldValue(parseInt(dataProvider.mapaParametros["SGR.BANCOPADRAO"]));

                    console.log(formaPagto, tipoCtaBanc, bancoFav);

                    formRef.current?.setFieldsValue({
                        flgTipoChavePIX: "S",
                        chavePIX: "",
                        "cdAgenciaFav": "",
                        "nrCtaBcoFav": "",
                        "digCdCli": ""
                    });

                    setRevisarDadosBanc(true);

                    forceUpdate();

                    notificacao.aviso({description:"Preencher e salvar", message: "Cliente encontrado na base de dados. Por favor revisar as informações bancárias e salvar."});

                } else {

                    notificacao.aviso({description:"Já cadastrado", message: "Cliente encontrado na base de dados."});

                }

            } else {

                if(modeloPessoa != null) {

                    // preencher campos da pessoa

                    ffValues.cdPessoa = modeloPessoa.cdPessoa ;
                    ffValues.cdPessoaCli = modeloPessoa.cdPessoa ;
                    ffValues.flgJuridico = modeloPessoa.flgJuridico ;
                    ffValues.noFantasia = modeloPessoa.noFantasia ;
                    ffValues.noPessoa = modeloPessoa.noPessoa ;
                    ffValues.nrLigacoes = modeloPessoa.nrLigacoes ;

                    ffValues.pessoaJaExiste = "S" ;

                } else {

                    ffValues.pessoaJaExiste = "N" ;

                }

                const formaPagto = cmbFormaPagtoRefApi.current.setFieldValue(parseInt(dataProvider.mapaParametros["SGR.FORMAPAGTOPADRAO"]));
                const tipoCtaBanc = cmbTipoCtaBancRefApi.current.setFieldValue(parseInt(dataProvider.mapaParametros["SGR.TIPOCONTAPADRAO"]));
                const bancoFav = cmbBancoFavRefApi.current.setFieldValue(parseInt(dataProvider.mapaParametros["SGR.BANCOPADRAO"]));

                console.log(formaPagto, tipoCtaBanc, bancoFav);

                formRef.current?.setFieldsValue( ffValues );

                setTimeout(() => {

                    if(focusOn === "noFantasia") {
                        noFantasiaRef.current.focus();
                    } else {
                        noPessoaRef.current.focus();
                    }

                }, 0);

                forceUpdate();

            }

            return true;

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const nrCGCCICPatterns = [{
        pattern: "000.000.000-00",
        length: 11, // sem mascara
        validator: async (value) => {
            if(value.length === 11) {
                if(cpfValido(value) === false) {
                    return {valido:false, message:"CPF informado é inválido !"};
                }
            }
            return {valido: true, message: "ok" };
        }
    }, {
        pattern: "00.000.000/0000-00",
        length: 14, // sem mascara
        validator: async (value) => {
            if(value.length === 14) {
                if(cnpjValido(value) === false) {
                    return {valido:false, message:"CNPJ informado é inválido !"};
                }
            }
            return {valido: true, message: "ok" };
        }
    }];

    /*

     */

    const popupTitle = modoExecucao.isInclusao() ? "Novo cliente" : "Alteração do cliente";

    const ffValues = formRef?.current?.getFieldsValue();

    const protege_ChavePIX = (campoInformadoString(ffValues?.flgTipoChavePIX) === false || ffValues?.flgTipoChavePIX === "S" || ffValues?.flgTipoChavePIX === "X" || ffValues?.flgTipoChavePIX === "J");

    const PESSOA_FISICA = (ffValues?.flgJuridico === "F");

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={24}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"editar-cliente"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-editar-cliente"}>
                    <ProForm
                        formRef = { formRef }
                        isKeyPressSubmit = {true}
                        initialValues={ fiValues }
                        onFinish = { async ( ) => {
                            await doSalvar();
                        }}
                        submitter={{
                            // Completely customize the entire area
                            render : ( props , doms ) => {
                                return null;
                            }
                        }}
                        onValuesChange={async (changeValues, allValues) => {

                            if(changeValues.hasOwnProperty("flgTipoChavePIX")) flgTipoChavePIX_OnChange(changeValues["flgTipoChavePIX"]);

                        }}
                        onFieldsChange = {(changedFields, allFields) => {
                            conteudoAlterado.updateConteudoAlterado(changedFields);
                        }}
                    >
                        <div className={"pro-form-group-spacer"}>
                            <ProForm.Group>
                                <IY2BProFormMasked
                                    width={"sm"}
                                    label={"CNPJ / CPF"}
                                    name="nrCGCCIC"
                                    tooltip = "CNPJ ou CPF do cliente"
                                    rules={[
                                        {required: true, message: 'Informe o CNPJ / CPF'},
                                        {
                                            validator: async (rule, value) => {
                                                if(inputRefNrCGCCIC) {
                                                    const result = await inputRefNrCGCCIC.current.validate();
                                                    if(result.valido === false) throw new Error(result.message);
                                                }
                                                if(campoInformadoString(value) === true) {
                                                    if(formRef.current?.isFieldTouched("nrCGCCIC") === true) {
                                                        const result = await pesquisarNrCGCCIC(value);
                                                        if(result === true) {
                                                            formRef.current?.setFields([{
                                                                name: "nrCGCCIC",
                                                                touched: false
                                                            }]);
                                                        }
                                                        return (result === true);
                                                    } else {
                                                        return true;
                                                    }
                                                } else {
                                                    return false;
                                                }
                                            }
                                        }]}
                                    api={inputRefNrCGCCIC}
                                    patterns={nrCGCCICPatterns}
                                    disabled={modoExecucao.isAlteracao()}
                                />
                                <ProFormText
                                    width={"md"}
                                    name = "noPessoa"
                                    label = "Razão social / Nome"
                                    tooltip = "A razão social ou nome do cliente"
                                    placeholder = "Digite a razão social ou apelido"
                                    rules={[
                                        {required: true, message: 'Informe a razão social'},
                                        {message:"No máximo 60 caracteres", max:60}
                                    ]}
                                    disabled={PESSOA_FISICA === false}
                                    fieldProps={{
                                        ref: noPessoaRef
                                    }}
                                />
                                <ProFormText
                                    width={"sm"}
                                    name = "noFantasia"
                                    label = "Fantasia / Apelido"
                                    tooltip = "Nome fantasia ou apelido do cliente"
                                    placeholder = "Digite o nome fantasia ou apelido"
                                    rules={[
                                        {required: true, message: 'Informe o nome fantasia'},
                                        {message:"No máximo 20 caracteres", max:20}
                                    ]}
                                    fieldProps={{
                                        ref: noFantasiaRef
                                    }}
                                />
                            </ProForm.Group>
                            <ProForm.Group>
                                <ProFormDigit
                                    width={"xs"}
                                    label="Código"
                                    name="cdCli"
                                    placeholder = "Código"
                                    tooltip = { "Código deste cliente" }
                                    min={0}
                                    max={999999}
                                    fieldProps={{ precision: 0 }}
                                    disabled
                                />
                                <RadioGroup
                                    width={"sm"}
                                    name="flgJuridico"
                                    label="Tipo"
                                    tooltip = { "Tipo de pessoa" }
                                    rules={[
                                        {required: true, message: 'Tipo de pessoa'},
                                    ]}
                                    options={[
                                        {
                                            label: 'Física',
                                            value: 'F',
                                        },
                                        {
                                            label: 'Jurídica',
                                            value: 'J',
                                        },
                                    ]}
                                    disabled
                                />
                                <ProFormText
                                    width={"sm"}
                                    name="dtCad"
                                    label="Cadastrado em"
                                    tooltip={"Data de cadastro deste cliente"}
                                    placeholder={"Data de cadastro"}
                                    disabled
                                />
                                <ProFormText
                                    width={"sm"}
                                    name="dtAlt"
                                    label="Alterado em"
                                    tooltip={"Data de alteração deste cliente"}
                                    placeholder={"Data de alteração"}
                                    disabled
                                />
                            </ProForm.Group>
                            <FoldedEnderecoPessoa cdTransacao={cdTransacao}
                                cdPessoa = { ffValues?.cdPessoaCli }
                                nrCGCCIC = { ffValues?.nrCGCCIC }
                                noPessoa = { ffValues?.noPessoa }
                                hidden = { (modoExecucao.isAlteracao() === false) }
                                api = { foldedEnderecoPessoaRefApi }
                            />
                            <FoldedFormField label={"Dados bancários"} api = { foldedFFDadosBancRefApi } alwaysOpen={modoExecucao.isInclusao() || revisarDadosBanc} icon={CUSTOM.WALLET_ICON} >
                                <ProForm.Group>
                                    <ComboBox
                                        width={"sm"}
                                        formRef = { formRef }
                                        api = { cmbFormaPagtoRefApi }
                                        name = "cmbFormaPagto"
                                        label = "Forma de pagamento"
                                        tooltip = "Selecione a forma de pagamento"
                                        placeholder={"Selecione a forma de pagamento"}
                                        rules={[
                                            {required: true, message: 'Informe a forma de pagamento'},
                                        ]}
                                        options={dataProvider.dpFormaPagto}
                                    />
                                    <ComboBox
                                        width={"sm"}
                                        formRef = { formRef }
                                        api = { cmbBancoFavRefApi }
                                        name = "cmbBancoFav"
                                        label = "Banco para pagamento"
                                        tooltip = "Banco para pagamento deste cliente"
                                        placeholder={"Selecione o banco para pagamento"}
                                        rules={[
                                            {required: true, message: 'Informe o banco para pagamento'},
                                        ]}
                                        options={dataProvider.dpBanco}
                                    />
                                    <ProFormDigit
                                        width={"xs"}
                                        label="Agência"
                                        name="cdAgenciaFav"
                                        placeholder = "Agência"
                                        tooltip = { "Agência com dígito para pagamento deste cliente" }
                                        min={0}
                                        max={99999}
                                        fieldProps={{ precision: 0 }}
                                        rules={[
                                        ]}
                                    />
                                    <ProFormText
                                        width={"sm"}
                                        label="Conta bancária"
                                        name="nrCtaBcoFav"
                                        placeholder = "Conta bancária"
                                        tooltip = { "Conta bancária para pagamento deste cliente" }
                                        rules={[
                                            {message:"No máximo 20 caracteres", max:20}
                                        ]}
                                    />
                                    <ProFormText
                                        width={"xs"}
                                        name="digCdCli"
                                        label="Dígito"
                                        tooltip={"Digito da C/C"}
                                        placeholder={"Digito da C/C"}
                                        rules={[
                                            {message:"No máximo 1 caracteres", max:1}
                                        ]}
                                    />
                                    <ComboBox
                                        width={"sm"}
                                        formRef = { formRef }
                                        api = { cmbTipoCtaBancRefApi }
                                        name = "cmbTipoCtaBanc"
                                        label = "Tipo de conta"
                                        tooltip = "Selecione o tipo de conta"
                                        placeholder={"Selecione o tipo de conta"}
                                        rules={[
                                            {required: true, message: 'Informe o tipo de conta'},
                                        ]}
                                        options={dataProvider.dpTipoCtaBanc}
                                    />
                                </ProForm.Group>
                                <ProForm.Group>
                                    <RadioGroup
                                        width={"md"}
                                        name="flgTipoChavePIX"
                                        label="Tipo da chave PIX"
                                        tooltip = { "Escolha a chave PIX deste cliente" }
                                        rules={[
                                            {required: true, message: 'Escolha a chave PIX deste cliente'},
                                        ]}
                                        options={[
                                            {
                                                label: 'Sem chave',
                                                value: 'S',
                                            },
                                            {
                                                label: 'CPF/CNPJ',
                                                value: 'X',
                                            },
                                            {
                                                label: 'E-Mail',
                                                value: 'E',
                                            },
                                            {
                                                label: 'Telefone',
                                                value: 'T',
                                            },
                                            {
                                                label: 'Aleatória',
                                                value: 'A',
                                            },
                                        ]}
                                        forceUpdate={forceUpdate}
                                    />
                                    <ProFormText
                                        width={"lg"}
                                        name="chavePIX"
                                        label="Chave PIX"
                                        tooltip={"Chave PIX deste cliente"}
                                        placeholder={"Chave PIX deste cliente"}
                                        rules={[
                                            {required: (protege_ChavePIX===false), message: 'Informe a chave PIX indicada'},
                                            {message:"No máximo 200 caracteres", max:200}
                                        ]}
                                        disabled={protege_ChavePIX}
                                    />
                                </ProForm.Group>
                            </FoldedFormField>
                            <FoldedFormField label={"Informações adicionais"} api={foldedFFInfAdicRefApi} icon={CUSTOM.INFO_ICON}>
                                <ProForm.Group>
                                    <RadioGroup
                                        width={"xs"}
                                        name="flgME"
                                        label="Micro empresa ?"
                                        tooltip = { "É uma micro empresa ?" }
                                        rules={[
                                            {required: true, message: 'Indique se é uma micro empresa'},
                                        ]}
                                        options={[
                                            {
                                                label: 'Sim',
                                                value: 'S',
                                            },
                                            {
                                                label: 'Não',
                                                value: 'N',
                                            },
                                        ]}
                                    />
                                    <RadioGroup
                                        width={"xs"}
                                        name="flgContribICMS"
                                        label="Contribuinte ICMS ?"
                                        tooltip = { "É um contribuinte do ICMS ?" }
                                        rules={[
                                            {required: true, message: 'Indique se é um contribuinte do ICMS'},
                                        ]}
                                        options={[
                                            {
                                                label: 'Sim',
                                                value: 'S',
                                            },
                                            {
                                                label: 'Não',
                                                value: 'N',
                                            },
                                        ]}
                                    />
                                    <RadioGroup
                                        width={"xs"}
                                        name="flgOptaSimples"
                                        label="Optante do SIMPLES ?"
                                        tooltip = { "É um optante do SIMPLES Nacional ?" }
                                        rules={[
                                            {required: true, message: 'Indique se é um optante do SIMPLES Nacional'},
                                        ]}
                                        options={[
                                            {
                                                label: 'Sim',
                                                value: 'S',
                                            },
                                            {
                                                label: 'Não',
                                                value: 'N',
                                            },
                                        ]}
                                    />
                                </ProForm.Group>
                            </FoldedFormField>
                            <FoldedChecklistPessoa cdTransacao={cdTransacao}
                                cdPessoaEmpAtual={dataProvider.cdPessoaEmpAtual}
                                pessoa={{
                                    cdPessoa: ffValues?.cdPessoaCli,
                                    noPessoa: ffValues?.noPessoa,
                                    nrCGCCIC: ffValues?.nrCGCCIC
                                }}
                                hidden = { (modoExecucao.isAlteracao() === false) }
                                api = { foldedChecklistPessoaRefApi }
                                dataSource = { refDsChecklist.current }
                                tpOrigemPessoa = "CLI"
                                cdTransacaoAtual={85039}
                            />
                            <FoldedContatoPessoa cdTransacao={cdTransacao}
                                cdPessoaEmpAtual={dataProvider.cdPessoaEmpAtual}
                                pessoa={{
                                    cdPessoa: ffValues?.cdPessoaCli,
                                    noPessoa: ffValues?.noPessoa,
                                }}
                                hidden = { (modoExecucao.isAlteracao() === false) }
                                api = { foldedContatoPessoaRefApi }
                                dataSource = { refDsContato.current }
                                tpOrigemPessoa = "CLI"
                            />
                            <IY2BHiddenRow>
                                <IY2BProFormHidden name={"cdPessoaCli"}/>
                                <IY2BProFormHidden name={"nrLigacoes"}/>
                                <IY2BProFormHidden name={"pessoaJaExiste"}/>
                            </IY2BHiddenRow>
                        </div>
                    </ProForm>
                </div>
            }
            actions={
                <Space style={{
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <Space>
                        <NavegadorRegistro
                            api={navegadorRefApi}
                            registros={registros}
                            navegaPara={navegaParaRegistro}
                        />
                    </Space>
                    <Space>
                        {modoExecucao.isAlteracao()?
                            <Popconfirm
                                title="Confirma a exclusão deste registro?"
                                onConfirm={async () => {
                                    const resultado = await doExcluir();
                                }}
                                okText="Sim, excluir"
                                cancelText="Não"
                            >
                                <Button type={"primary"} danger className={"excluir"}>
                                    Excluir
                                </Button>
                            </Popconfirm>
                            :null}
                        &nbsp;
                        <Button type={"primary"} className={"salvar"} onClick={async () => await doSalvar()}>
                            Salvar
                        </Button>
                    </Space>
                </Space>
            }
        />
    );

}

export default EditarCliente;

import '../../assets/css/tema.scss';
import './style.scss';

import React, {useEffect, useImperativeHandle, useState} from "react";
import {Button, Divider, Dropdown, List, Menu, Tooltip} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import {useStateRef} from "../../lib/iy2b-react";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {campoInformado} from "../../lib/iy2b-javascript";
import DataProvider from "../../lib/DataProvider";
import {CUSTOM, ELLIPSIS_VERTICAL_ICON_PRIMARY} from "../../assets/iconografia";
import BotaoAcoesDisponiveis from "../BotaoAcoesDisponiveis/botaoAcoesDisponiveis";
import Search from "antd/es/input/Search";

const filterByValue = (dataSource, string) => {
    const array = (dataSource instanceof DataProvider) ? dataSource.data : dataSource ;
    return array.filter(o =>
        Object.keys(o).some(k => {
            return String(o[k]).toLowerCase().includes(string.toLowerCase());
        }));
};

const colSorterAsc = (name, a, b) => {
    return a[name] > b[name] ? 1 : a[name] < b[name] ? -1 : 0;
};

const colSorterDesc = (name, a, b) => {
    return a[name] < b[name] ? 1 : a[name] > b[name] ? -1 : 0;
};

const IY2BCards = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {
            forceUpdate: () => {
                forceUpdate();
            },
            infoPag: () => {
                return refInfoPag.current;
            },
            clearData: () => {
                setDataSource([]);
                setSelectedRowKeys([]);
                handleUpdateInfoPag({"nroPagina":defaultNroPagina, "tamPagina":refDefaultPageSize.current, "totRegistros":0, "direcao":"ASC"});
            },
            unckeckAll: (ds) => {
                if(ds.hasOwnProperty("desmarca") === true) {
                    ds.desmarca();
                } else {
                    ds.forEach(item => {
                        item.marcado = false;
                    });
                }
                setSelectedRowKeys([]);
            },
            uncheck: (source) => {
                let upd = false;
                source.forEach(item => {
                    const linha = refSelectedRowKeys.current.filter(row => row.key === item.key);
                    item.marcado = false;
                    if(linha.length > 0) {
                        linha.forEach(row => refSelectedRowKeys.current.removeItem(row));
                        upd = true;
                    }
                });
                if(upd === true) setSelectedRowKeys(refSelectedRowKeys.current.clone());
            },
            check: (source) => {
                let upd = false;
                source.forEach(item => {
                    item.marcado = true;
                    const linha = refSelectedRowKeys.current.filter(row => row.key === item.key);
                    if(linha.length === 0) {
                        refSelectedRowKeys.current.push(item.key);
                        upd = true;
                    }
                });
                if(upd === true) setSelectedRowKeys(refSelectedRowKeys.current.clone());
            }
        };
    });

    const unknownGapHeight = 16;

    const { id } = props;

    const { renderItem, ordenadores=[], gridCols=4 } = props;

    const [dataSource, setDataSource, refDataSource] = useStateRef(props.dataSource);
    const [tableHeight, setTableHeight] = useState(props.tableHeight - unknownGapHeight);
    const [lastFilter, setLastFilter] = useState("");

    const [defaultPageSize, setDefaultPageSize, refDefaultPageSize] = useStateRef(props.pagPageSize||50);
    const defaultNroPagina = 1;

    const [infoPag, setInfoPag, refInfoPag] = useStateRef({"nroPagina": defaultNroPagina, "tamPagina":refDefaultPageSize.current, "totRegistros":0, "direcao":"ASC"});

    const [dispatchRequestPage] = useState(props.hasOwnProperty("onRequestPage"));

    const { allowCheckForRow, tableTitle, visible=true, searchDisabled = false, paginationDisabled = false, downloadDisabled = false, configDisabled = false, enableRowCheck = false, onSelectionChange, onUpdatePageSize } = props;

    const [selectedRowKeys, setSelectedRowKeys, refSelectedRowKeys] = useStateRef([]);

    const forceUpdate = useForceUpdate();

    const infiniteScroll = true;

    const handleUpdateInfoPag = (args) => {
        if(args.tamPagina !== refInfoPag.current?.tamPagina) {
            if(campoInformado(onUpdatePageSize) === true) {
                onUpdatePageSize ( {...args} );
            }
        }
        setInfoPag(args);
    };

    const fetchNextPage = async () => {
        handleUpdateInfoPag({
            ...refInfoPag.current,
            nroPagina: refInfoPag.current.nroPagina + 1,
        });
        await props.onRequestPage();
    }

    useEffect(() => {

        setLastFilter("");

        if(props.dataSource instanceof DataProvider) {

            dataSource.clear();

            setDataSource(props.dataSource);

            handleUpdateInfoPag({
                ...infoPag,
                totRegistros: props.dataSource.total,
                nroPagina: props.dataSource.page,
                tamPagina: (props.dataSource.pageSize===0)?refDefaultPageSize.current:props.dataSource.pageSize
            });

        } else {

            setDataSource(props.dataSource);

        }

        const selected = [];

        props.dataSource.forEach(item => {
            if(item.marcado === true) {
                selected.push(item.key);
            }
        });

        setSelectedRowKeys(selected);

    }, [props.dataSource]);

    useEffect(() => {

        setTableHeight(props.tableHeight - unknownGapHeight);

    }, [props.tableHeight]);

    const [currentSort, setCurrentSort] = useState(null);

    // const dataSourceEhDataProvider = (dataSource instanceof DataProvider);

    // const tableDataSource = (dataSourceEhDataProvider === true)
    //    ? dataSource.data.clone()
    //    : dataSource.clone() ;

    const getCurrentDS = () => {

        const dataSourceEhDataProvider = (dataSource instanceof DataProvider);

        const tableDataSource = (dataSourceEhDataProvider === true)
            ? dataSource.data.clone()
            : dataSource.clone() ;

        if(currentSort != null) {
            if(currentSort.direction === "ASC") {
                tableDataSource.sort((a,b) => colSorterAsc (currentSort.key, a, b));
            } else {
                tableDataSource.sort((a,b) => colSorterDesc (currentSort.key, a, b));
            }
        }

        return tableDataSource;

    }

    const tableDataSource = getCurrentDS();

    const handleSortClick = (item) => {
        const filtrados = menuSort.items.filter(o => o.key === item.key);
        if(filtrados.isEmpty() === false) {
            menuSort.items.forEach(menuItem => {
                if(menuItem.key !== item.key) {
                    menuItem.direction = null;
                    menuItem.icon = null;
                }
            });
            const menuItem = filtrados[0];
            if(menuItem.direction === null) {
                menuItem.direction = "ASC";
            } else {
                if(menuItem.direction === "ASC") {
                    menuItem.direction = "DESC";
                } else {
                    menuItem.direction = "ASC";
                }
            }
            if(menuItem.direction === "ASC") {
                menuItem.icon = CUSTOM.SORT_AMOUNT_UP_ALT_ICON_BLACK;
            } else {
                menuItem.icon = CUSTOM.SORT_AMOUNT_UP_ICON_BLACK;
            }
            setCurrentSort(menuItem);
            setMenuSort({
                items:menuSort.items.clone()
            });
        }
    }


    const [menuSort, setMenuSort] = useState({
        items: ordenadores.map(item => {
            const ordenador = {
                key: item.key,
                label: item.label,
                direction: null,
                icon: null,
                onClick: () => {
                    handleSortClick(item);
                }
            }
            return ordenador;
        })
    });

    const dropdownSort = (
        <Dropdown menu={menuSort} placement={"bottomRight"} disabled={false} overlayClassName={"dropdown-sort-data-cards"}>
            <Button type={"text"} icon={CUSTOM.SORT_ICON} className={"botao-sort"}></Button>
        </Dropdown>
    );

    let tableClassName = "root-container-iy2b-cards ";

    const toolbarActions = [];

    if(campoInformado(props.formularioDeFiltro) === true) {

        toolbarActions.push(props.formularioDeFiltro);

        tableClassName += " with-filter-button";

    }

    if(props.customActions != null && props.customActions != undefined) {

        toolbarActions.addAll(props.customActions);

    }

    const executaAcaoBotaoAcoesDisponiveis = ( item ) => {

        if(item.key === "downloadTable") {
            props.onDownload();
        } else {
            props.botaoAcoesDisponiveis.onClickMenu ( item );
        }

    }

    if(campoInformado(props.botaoAcoesDisponiveis) === true) {

        const acoesLocais = [];
        acoesLocais.addAll( props.botaoAcoesDisponiveis.itensMenuAcoes );

        if(downloadDisabled === false) {
            if (campoInformado(props.onDownload) === true) {
                acoesLocais.push({
                    key: "downloadTable",
                    label: "Exportar dados",
                    icon: CUSTOM.CLOUD_ARROW_DOWN_ICON,
                });
            }
        }

        const menuAcoes = (
            <Menu onClick={executaAcaoBotaoAcoesDisponiveis}
                  items={acoesLocais}
            />
        );

        if(ordenadores.isEmpty() === false) {
            toolbarActions.push(dropdownSort);
        }

        toolbarActions.push(
            <BotaoAcoesDisponiveis iy2bCards
                                   menuAcoes={menuAcoes}
                                   disabled={props.botaoAcoesDisponiveis.disabled}
            />
        );

        tableClassName += " with-acoes-disponiveis";

    } else {

        if(ordenadores.isEmpty() === false) {
            toolbarActions.push(dropdownSort);
        }

        if(downloadDisabled === false) {

            if(campoInformado(props.onDownload) === true) {

                toolbarActions.push(
                    <Tooltip title="Exportar dados" color={"black"} key={"download"}>
                        <Button
                            type="text"
                            onClick={() => {
                                props.onDownload();
                            }}
                            className="action-button"
                            icon={CUSTOM.CLOUD_ARROW_DOWN_ICON}
                        >
                        </Button>
                    </Tooltip>
                );

                tableClassName += " with-download";

            }
        }

        if(campoInformado(props.onRefresh) === true) {

            toolbarActions.push(
                <Tooltip title="Atualizar dados" color={"black"} key={"refresh"}>
                    <Button
                        type="text"
                        onClick={() => {
                            props.onRefresh();
                        }}
                        className="action-button"
                        icon={CUSTOM.SYNC_ALT_ICON_BLACK_FIXED}
                    >
                    </Button>
                </Tooltip>
            );

            tableClassName += " with-refresh";

        }

    }

    //const toolbar = toolbarActions

    const renderListItem = (item) => {
        const content = renderItem ( item );
        return (
            <List.Item>
                {content}
            </List.Item>
        )
    };

    const scrollId = "root-container-cards-" + id;
    const scrollStyle = {
        height: tableHeight - 48 - 32
    };

    const onSearch = (value, _e, info) => {
        const resultado = filterByValue(props.dataSource, value);
        setDataSource(resultado);
    };

    const totRegistros = (tableDataSource.length > infoPag.totRegistros) ? tableDataSource.length : infoPag.totRegistros;

    const endMessage = (totRegistros > 0) ? <Divider plain>Término do resultado</Divider> : null;

    return (
        <div className={tableClassName}>
            <div className={"container-toolbar"} >
                <div className={"search"}>
                    {(searchDisabled===false)?
                        <Search placeholder="Pesquise no resultado" onSearch={onSearch} allowClear />
                        : null
                    }
                </div>
                <div className={"actions"}>
                    {toolbarActions}
                </div>
            </div>
            <div id={scrollId} style={scrollStyle} className={"scroller"}>
                <ErrorBoundary>
                    <InfiniteScroll
                        dataLength={tableDataSource.length}
                        next={fetchNextPage}
                        hasMore={tableDataSource.length < infoPag.totRegistros}
                        endMessage={endMessage}
                        scrollableTarget={scrollId}
                    >
                        <List
                            grid={{
                                gutter: 16,
                                column: gridCols,
                            }}
                            dataSource={tableDataSource}
                            renderItem={renderListItem}
                        />
                    </InfiniteScroll>
                </ErrorBoundary>
            </div>
            <div className={"footer"} >
                {tableDataSource.length + " de " + totRegistros + " registro(s)"}
            </div>
        </div>
    );

});

export default IY2BCards;

import { useRef, useLayoutEffect } from 'react'

const isBrowser = typeof window !== `undefined`

function getScrollPosition({ element, useWindow }) {
    if (!isBrowser) return { x: 0, y: 0 }
    const target = element ? element : document.body
    const xLimit = target.scrollWidth - target.clientWidth;
    const yLimit = target.scrollHeight - target.clientHeight;
    return { x: target.scrollLeft, y: target.scrollTop, xLimit, yLimit }
}

export function useScrollPosition(effect, element, deps =[], useWindow=false, wait=100) {

    const position = useRef({ x: 0, y: 0 });

    let throttleTimeout = null

    const callBack = () => {
        if(element === null || element === undefined) return;
        const currPos = getScrollPosition({ element, useWindow });
        effect({ prevPos: position.current, currPos });
        position.current = currPos;
        throttleTimeout = null
    }

    useLayoutEffect(() => {

        const handleScroll = () => {
            if (wait) {
                if (throttleTimeout === null) {
                    throttleTimeout = setTimeout(callBack, wait)
                }
            } else {
                callBack()
            }
        }

        if(element) element.addEventListener('scroll', handleScroll)

        return () => {
            if(element) element.removeEventListener('scroll', handleScroll)
        }

    }, deps);

}

//v5 import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-layout/dist/layout.css';
import "./wizNovaEmpresa.scss";

import ReactModal from "react-modal";
import {Button, notification, Space} from "antd";
import React, {useRef, useState} from "react";
import {notificacaoErroRequest, notificacaoErroValidForm, useWindowSize} from "../../lib/iy2b-react";
import SinglePageLoadingIndicator from "../../componentes/SinglePageLoadingIndicator";
import UploadLoadingIndicator from "../../componentes/UploadLoadingIndicator";
import IY2BProFormWizard from "../../componentes/IY2BProFormWizard";
import {StepsForm} from "@ant-design/pro-components";
import {scrollToTop} from "../../lib/iy2b-javascript";
import {postJSON} from "../../lib/RequestAPI";
import {onAfterClose, onAfterOpen} from "../../componentes/IY2BReactModal";
import {notificacao} from "../../lib/Notificacao";
import StepCertDigital from "../../../pages/Assinar/StepCertDig";
import StepCadEmpresa from "../../../pages/Assinar/StepCadEmpresa";

const { StepForm } = StepsForm;

const WizNovaEmpresa = (props) => {

    const windowSize = useWindowSize();

    const MARGIN_LEFT = 30;
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = (windowSize.height-((windowSize.height<640)?572:672))/2;

    const { exibePopUp, onRequestClose, cdPessoaEmpMaster, usuarioAtual, onEmpresaAdicionada, cdTransacao=0 } = props;

    const [infoCertificado, setInfoCertificado] = useState(null);

    const [cadEmpData, setCadEmpData] = useState(null);

    const formRef = useRef();

    const closePopUp = () => {
        onRequestClose();
    };

    const onFinishStepFailed = errorInfo => {
        notificacaoErroValidForm(errorInfo);
    };

    const onFinishWizard = async () => {

        scrollToTop("containerStepCadEmpresa");

        const infoEmpresa = cadEmpData != null ? cadEmpData : formRefEmpresa.current?.getFieldsValue();

        const payload = {
            acaoAExecutar: "criarNovaEmpresa",
            registro: {
                "cdPessoaEmpMaster": cdPessoaEmpMaster,
                "cdPessoaEmpModeloOrig": props.appConfig.cdPessoaEmpModeloOrig,
                "nrSeqCD": infoCertificado?.nrSeqCD||0,
                "flgJuridico": "J",
                "nrCGCCIC": infoEmpresa.nrCNPJ.retiraCaracteres(".-/"),
                "noFantasia": infoEmpresa.noFantasia.trim(),
                "noPessoa": infoEmpresa.noRazaoSocial.trim(),
                "flgOptaSimples": infoEmpresa.flgOptaSimples,
                "flgMEI": infoEmpresa.flgMEI,
                endereco: {
                    nrCEP: infoEmpresa.nrCEP,
                    txNumero: infoEmpresa.txNumero?.substr(0, 20),
                    txComplEnd: infoEmpresa.txComplEnd?.substr(0, 30),
                    nrInsEst: infoEmpresa.nrInsEst,
                    nrInsMunicip: infoEmpresa.nrInsMunicip,
                    nrTelefone: infoEmpresa.nrTelefone?infoEmpresa.nrTelefone.retiraCaracteres("()- "):undefined,
                    noEmailNF_E: infoEmpresa.noEmailNF_E?.substr(0, 60),
                    noEMail: usuarioAtual.noEMail?.substr(0, 60),
                    noUsuario: usuarioAtual.noUsuario
                }
            }
        };

        try {

            const resposta = await postJSON({
                sistema: "smk",
                projeto: "mempresapiy2b",
                acaoWebAExecutar: "processarnegocio"
            }, payload);

            notificacao.sucesso({message: "Sucesso", description:"Nova empresa incluída na plataforma com sucesso !"})

            onRequestClose();

            onEmpresaAdicionada();

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const onCurrentStepChange = (position, currentKey) => {

        if(currentKey==="stepCadEmp") {
            formRefEmpresaApi.current?.onPageShow();
        }

    };

    const onInfoCertificado = (value) => {
        setInfoCertificado(value);
    }

    const stepFormCertDig =
        <StepForm
            key={"stepCertDig"}
            name = "stepCertDig"
            title = "Certificado digital"
            stepProps = {{
                description: "Enviar o certificado digital da nova empresa"
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = {async ( ) => {
                return true;
            }}
        >
            <StepCertDigital appConfig = { props.appConfig }
                             formRef = { formRef }
                             onInfoCertificado = { onInfoCertificado }
                             semAceiteDosTermos = { true }
                             opcional
            />
        </StepForm>
    ;

    const formRefEmpresa = useRef() ;
    const formRefEmpresaApi = useRef();

    const stepFormEmpresa =
        <StepForm
            formRef = { formRefEmpresa }
            key={"stepCadEmp"}
            name = "stepCadEmp"
            title = "Dados empresa"
            stepProps = {{
                description: "Preenchimento de informações da empresa",
                disabled: false
            }}
            initialValues={{
                flgOptaSimples: "N",
                flgMEI: "N"
            }}
            onFinishFailed={onFinishStepFailed}
            onFinish = {async ( ) => {
                if(await formRefEmpresaApi.current?.onFinish() === false) return false;
                const all = formRefEmpresa.current?.getFieldsValue();
                setCadEmpData(all);
                return true;
            }}
            onValuesChange = {async (changed, all) => {
                formRefEmpresaApi.current?.onValuesChange(changed, all);
            }}
        >
            <StepCadEmpresa
                api = { formRefEmpresaApi }
                formRef = { formRefEmpresa }
                certificado = { infoCertificado }
                exibeMeiosContato = { true }
            />
        </StepForm>
    ;

    const wizSteps =  <>
            {stepFormCertDig}
            {stepFormEmpresa}
        </>
    ;

    return (
        <ReactModal
            isOpen={exibePopUp}
            className={"modalContent"}
            overlayClassName={"modalOverlay"}
            bodyOpenClassName={"popUpWizNovaEmpresa"}
            closeTimeoutMS={500}
            onRequestClose={closePopUp}
            style={{
                content: {
                    left: MARGIN_LEFT + 'px',
                    width: MODAL_CONTENT_WIDTH + "px",
                    top: MODAL_CONTENT_TOP + "px",
                    bottom: MODAL_CONTENT_TOP + "px"
                }
            }}
            onAfterOpen={() => {
                onAfterOpen();
            }}
            onAfterClose={() => {
                onAfterClose();
            }}
        >
            <div className={"modal-header"}>
                <div className={"title"}>Adicionar nova empresa</div>
                <div className={"top-bar"}>
                    <Button onClick={closePopUp}>
                        Cancelar
                    </Button>
                </div>
            </div>
            <div className={"box-body"}>
                <SinglePageLoadingIndicator />
                <UploadLoadingIndicator area={"uploadCertificado"}/>
                <IY2BProFormWizard
                    formRef = { formRef }
                    onFinishWizard = { onFinishWizard }
                    onCurrentStepChange = { onCurrentStepChange }
                    stepsSize = "small"
                    steps = { wizSteps }
                >
                </IY2BProFormWizard>
            </div>
            <div className={"modal-footer"}>
            </div>
        </ReactModal>
    );

}

export default WizNovaEmpresa;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-card/dist/card.css';

import '../../../../framework/assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useState, useRef} from "react";
import {campoInformado, ObjectUtils} from "../../../lib/iy2b-javascript";
import {Button, Col, notification, Row} from "antd";
import {useGridWidth, useStateRef} from "../../../lib/iy2b-react";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import IY2BProFormHidden from "../../IY2BProFormHidden";
import IY2BHiddenRow from "../../IY2BHiddenRow";
import {SerializadorEventos} from "../../../lib/serializadorEventos";
import {useNextUpdate} from "../../../lib/useNextUpdate";

import {CUSTOM} from "../../../assets/iconografia";
import {notificacao} from "../../../lib/Notificacao";

const rowHeaderActions = <div className={"row-header-actions"}><label>Ações</label></div>;

const rowHeaderActionsEmpty = <div className={"row-header-actions"}><label></label></div>;

const _DEBUG_ = false;

const iconPlus = CUSTOM.PLUS_ICON_WHITE;
const iconMinus = CUSTOM.MINUS_ICON_WHITE;

const FormFieldRepeater = (props) => {

    const { formRef, name, title,
        nolabels=false, alwaysLabels=false, firstLine=false, titleAddFirstLine="Adicionar", disabled=false, summary=null, onLoad=null, layoutSummaryRow="default" } = props;

    const [localDisabled, setLocalDisabled, refLocalDisabled] = useStateRef(false);

    const [, setLocalLayoutSummaryRow, refLocalLayoutSummaryRow] = useStateRef(layoutSummaryRow);

    const [ gridWidth, refGridWidth ] = useGridWidth();

    const [dpLinhas, setDpLinhas] = useState([]);

    const forceUpdate = useForceUpdate();

    const [ultCodLinha, setUltCodLinha, refUltCodLinha] = useStateRef(0);

    const [linhaAdicionadaRecentemente, setLinhaAdicionadaRecentemente] = useState(false);
    const [linhaRemovidaRecentemente, setLinhaRemovidaRecentemente] = useState(false);

    const [campos, setCampos, refCampos] = useStateRef([]);

    const [combos, setCombos, refCombos] = useStateRef({});

    const nextUpdate = useNextUpdate();

    const dinamycRefComboApi = useRef();

    const [onLoadDispatch] = useState ( new SerializadorEventos() );

    onLoadDispatch.setCallback(() => {

        if(campoInformado(onLoad)===true) {
            onLoad();
        }

    });

    onLoadDispatch.adicionaDependencia("api");
    onLoadDispatch.adicionaDependencia("fields");

    useEffect(() => {

        if(campoInformado(props.api)) {

            props.api.current = {
                getFieldValue: getFieldValue,
                setRowValue: setRowValue,
                setCelValue: setCelValue,
                createRowsFrom: createRowsFrom,
                resetField: resetField,
                getFieldValueForRow: getFieldValueForRow,
                updateSummaryCols: updateSummaryCols,
                getRowNumber: getRowNumber,
                getSummaryfield: getSummaryfield,
                getFieldName: getFieldName,
                setFieldOptions: setFieldOptions,
                forceUpdate: () => {
                    forceUpdate();
                },
                getLength: () => dpLinhas.length
            }

        }

        onLoadDispatch.concluiDependencia("api");

        return () => {

        };

    }, []);

    useEffect(() => {

        if(_DEBUG_ === true ) console.log(name, "[props.fields]", props.fields);

        setCampos(props.fields);

        if(dpLinhas.length > 0) {

            dpLinhas.clear();

            setDpLinhas([]);

            refUltCodLinha.current = 0;

        }

        if(dpLinhas.length === 0)  {

            setElLinhaTotal ( criaLinhaTotal () );

            if(firstLine === true) {

                if(disabled === false) {

                    adicionaNovaLinha();

                }

            }

        }

        onLoadDispatch.concluiDependencia("fields");

        return () => {

        };

    }, [props.fields]);

    useEffect(() => {

        setLocalLayoutSummaryRow(props.layoutSummaryRow);

    }, [props.layoutSummaryRow]);

    const criaLinhaTotal = () => {

        if(_DEBUG_ === true ) console.log(name, "criaLinhaTotal", summary, refLocalLayoutSummaryRow.current);

        if(summary === null || summary === undefined) return null;

        const repeatRowHeader =  alwaysLabels;

        const posName = "summary";

        const novaLinha = refCampos.current.map((item, c) => {

            const configSum = summary[item.props.children.props.name];

            const children = [];

            if(configSum != null && configSum !== undefined) {

                configSum.fieldSumName = name + "_" + posName + "x" + c + "_" + item.props.children.props.name ;
                configSum.colName = item.props.children.props.name;

                const propsNewChild = {
                    key: name + "_cel_input_" + posName + "_" + c,
                    name: name + "_" + posName + "x" + c + "_" + item.props.children.props.name,
                    numRow: posName
                };

                if(configSum.rules !== null && configSum.rules !== undefined) {
                    propsNewChild.rules = [];
                    propsNewChild.rules.addAll(configSum.rules);
                }

                propsNewChild.disabled = true;

                if(nolabels === true) {
                    propsNewChild.label = null;
                } else {
                    if(item.props.children.props.hasOwnProperty("label")) {
                        propsNewChild.label = (repeatRowHeader===true)?item.props.children.props.label:null;
                    }
                }

                if(campoInformado(propsNewChild.label) === true) {
                } else {
                    propsNewChild.withoutLabelSpace = true;
                }

                const newChild = React.cloneElement(item.props.children, propsNewChild);

                children.push(newChild);

            }

            const newItem = React.cloneElement(item, {
                key: name + "_cel_" + posName + "_" + c,
            }, children);

            return newItem ;

        });

        const ehDisabled = (refLocalLayoutSummaryRow.current==="disabled") ;

        const novoElemLinha = (ehDisabled===true)?
            <Row gutter={[16, 0]} key={name + "_row_" + posName} className={"row-item-repeater"}>
                <Col xs={24} key={name + "_row_" + posName + "_1"}>
                    <Row gutter={4}>
                        {novaLinha}
                    </Row>
                </Col>
            </Row>
            :
            <Row gutter={[16, 0]} key={name + "_row_" + posName} className={"row-item-repeater"}>
                <Col xs={24} md={20} lg={21} key={name + "_row_" + posName + "_1"}>
                    <Row gutter={4}>
                        {novaLinha}
                    </Row>
                </Col>
                <Col xs={12} md={4} lg={3} key={name + "_row_" + posName + "_2"} className={"box-row-header-actions"}>
                    {(dpLinhas.length===0)?rowHeaderActionsEmpty:(repeatRowHeader)?rowHeaderActionsEmpty:null}
                    &nbsp;
                </Col>
            </Row>
        ;

        return novoElemLinha;
    }

    const [elLinhaTotal, setElLinhaTotal] = useState(null);

    const clearFFs = () => {

        const ffValues = formRef.current?.getFieldsValue();

        if(campoInformado(ffValues)) {

            const rowData = { };

            Object.keys(ffValues).forEach(key => {

                if(key.startsWith(name + "_")) {
                    rowData[key] = null;
                }

            });

            if(_DEBUG_ === true ) console.log(name, "clearFFs", rowData);

            formRef.current?.setFieldsValue(rowData);

        }

    }

    const resetField = () => {

        if(_DEBUG_ === true ) console.log(name, "resetField");

        dpLinhas.clear();

        setDpLinhas([]);

        refUltCodLinha.current = 0;

        clearFFs();

        setElLinhaTotal ( criaLinhaTotal () );

        if(firstLine === true) {

            if(refLocalDisabled.current === false) {

                adicionaNovaLinha();

                clearFFs();

            }

        }

    };

    const createRowsFrom = (registros, disabled = false) => {

        if(_DEBUG_ === true) console.log(name, "createRowsFrom");

        setElLinhaTotal ( criaLinhaTotal () );

        const ehDisabled = (disabled===true);

        const resultado = [];

        dpLinhas.clear();

        refUltCodLinha.current = 0;

        registros.forEach((item, i) => {

            const lastRow = adicionaNovaLinha( i, ehDisabled );

            resultado.push(lastRow) ;

        });

        if(_DEBUG_ === true) console.log(name, "createRowsFrom", resultado);

        return resultado;

    }

    const getRowNumber = (fieldName) => {

        let rowNum = null;

        const partes1 = fieldName.split("_");

        if(partes1.length > 1) {
            const partes2 = partes1[1].split("X");
            rowNum = parseInt(partes2[0]);
        }

        return rowNum;

    }

    const setRowValue = (formRef, row, value) => {

        const cols = { };

        refCampos.current.map((item, c) => {

            cols[item.props.children.props.name] = c;

        });

        const rowData = { };

        Object.keys(value).forEach(key => {

            const attr = name + "_" + row + "x" + cols[key] + "_" + key;

            rowData[attr] = value[key];

        });

        formRef.current?.setFieldsValue(rowData);

    }

    const getFieldName = (formRef, row, attrName) => {

        const cols = { };

        refCampos.current.map((item, c) => {

            if(item.props.children.props.name === attrName) {

                cols[item.props.children.props.name] = c;

            }

        });

        const attr = name + "_" + row + "x" + cols[attrName] + "_" + attrName;

        return attr;

    }

    const setFieldOptions = (formRef, row, attrName, options) => {

        const name = getFieldName(formRef, row, attrName);

        const comboInfo = refCombos.current[name];

        comboInfo.data.clear();
        comboInfo.data.addAll( options );

        comboInfo.api?.current[name]?.refreshOptions();

        forceUpdate();

    }

    const setCelValue = (formRef, row, attrName, value) => {

        const cols = { };

        refCampos.current.map((item, c) => {

            if(item.props.children.props.name === attrName) {

                cols[item.props.children.props.name] = c;

            }

        });

        const attr = name + "_" + row + "x" + cols[attrName] + "_" + attrName;

        const rowData = { };

        rowData[attr] = value;

        formRef.current?.setFieldsValue(rowData);

        if(campoInformado(summary) === true) {

            const configSum = summary[attrName];

            if(campoInformado(configSum) === true) {

                totalizaColuna ( configSum );

            }

        }

    }

    const getSummaryfield = (attrName) => {

        if(campoInformado(summary) === true) {

            const configSum = summary[attrName];

            if (campoInformado(configSum) === true) {

                return configSum.fieldSumName;

            }

        }

        return null;
    }

    const getFieldValueForRow = (ffValues, numRow) => {

        const keys = [];

        Object.keys(ffValues).forEach(key => {

            if(key.startsWith(name + "_")) {
                keys.push(key);
            }

        });

        keys.sort();

        let ultReg = null;

        keys.forEach(key => {

            const partesKey = key.split("_");

            const paresRowCol = partesKey[1].split("x");

            const row = parseInt(paresRowCol[0]);

            if(row === numRow) {

                const col = parseInt(paresRowCol[1]);

                if(col === 0) {
                    ultReg = {
                        _row: row
                    };
                }

                const attrJson = partesKey[2];
                const value = ffValues[key];

                ultReg[attrJson] = value;

            }

        });

        return ultReg;

    }

    const getFieldValue = (ffValues, numRow) => {

        const resultado = [];

        const keys = [];

        Object.keys(ffValues).forEach(key => {

            if(key.startsWith(name + "_")) {
                keys.push(key);
            }

        });

        keys.sort();

        let ultReg = null;

        keys.forEach(key => {

            const partesKey = key.split("_");

            const paresRowCol = partesKey[1].split("x");

            const row = parseInt(paresRowCol[0]);
            const col = parseInt(paresRowCol[1]);

            let processa = true;

            if(isNaN(row) === true || isNaN(col) === true) processa = false;

            if(processa === true) {

                if(col === 0) {
                    if(ultReg !== null) {
                        resultado.push(ultReg);
                    }
                    ultReg = {
                        _row: row
                    };
                }

                const attrJson = partesKey[2];
                const value = ffValues[key];

                ultReg[attrJson] = value;

            }

        });

        if(ultReg !== null) {
            resultado.push(ultReg);
        }

        return resultado;
    }

    const btnRemoveLinha_Click = (linha) => {

        if(refLocalDisabled.current === true) return;

        const ffValues = formRef.current?.getFieldsValue();

        if(ffValues[linha.key + "xAcoes_Remove"] === false) {
            notificacao.aviso({message:"Operação desabilitada", description:"Não pode remover esta linha!"})
            return;
        }

        removeLinha (linha);

    }

    const removeLinha = (linha) => {

        setDpLinhas(dpLinhas.removeItem(linha));

        setLinhaRemovidaRecentemente(true);

        updateSummaryCols();

    };

    const updateSummaryCols = () => {

        return new Promise(async (resolve, reject) => {

            if(summary !== null && summary !== undefined) {

                setTimeout(() => {

                    Object.keys(summary).forEach(key => {

                        totalizaColuna (summary[key]);

                    });

                    resolve(true);

                }, 0);

            } else {

                resolve(true);

            }

        });

    }

    const btnAdicionaNovaLinha_Click = (posicao) => {

        if(refLocalDisabled.current === true) return;

        adicionaNovaLinha(posicao + 1);

        const posName = {
            value: refUltCodLinha.current
        };

        if(props.setDefaultNovaLinha !== undefined) {
            setTimeout(() => {
                props.setDefaultNovaLinha ( posName.value );
            }, 0);
        }

    }

    const totalizaColuna = (configSum) => {

        const ffValues = formRef.current?.getFieldsValue();

        const col_Names = ObjectUtils.filterKeys(ffValues, name => name.endsWith("_" + configSum.colName)).filter(name => name !== configSum.fieldSumName);

        let total = 0 ;

        col_Names.forEach(name => {

            const valor = formRef.current?.getFieldValue(name);

            if(valor !== null && valor !== undefined) {
                total += valor.toDecimal(configSum.precision);
            } else {
                if(_DEBUG_ === true ) console.log("??????", name, valor)
            }

            if(_DEBUG_ === true ) console.log(name, total, valor);

        });

        const newFFValues = { } ;

        newFFValues[configSum.fieldSumName] = total.toScreen(2);

        formRef.current?.setFieldsValue(newFFValues);

    }

    const adicionaNovaLinha = (posicao= 0, ehDisabled = false) => {

        if(_DEBUG_ === true ) console.log(name, props.fields, refCampos.current);

        const repeatRowHeader = (refGridWidth.current.compare(window._MD_) <= 0) || alwaysLabels;

        const posName = refUltCodLinha.current + 1;

        setUltCodLinha(posName);

        const resultado = {
            row: posName
        };

        const novaLinha = refCampos.current.map((item, c) => {
            // console.log(item.props.children);
            // console.log(item.props.children.props.name, item.props.children.props.options);
            resultado[item.props.children.props.name] = name + "_" + posName + "x" + c + "_" + item.props.children.props.name;

            const propsNewChild = {
                key: name + "_cel_input_" + posName + "_" + c,
                name: name + "_" + posName + "x" + c + "_" + item.props.children.props.name,
                numRow: posName
            };

            if(item.props.children.props.hasOwnProperty("ffrDisabled") === true) {
                propsNewChild.ffrDisabled = item.props.children.props.ffrDisabled;
            }

            if(item.props.children.props.hasOwnProperty("disabled") === true) {
                propsNewChild.disabled = true;
            } else {
                propsNewChild.disabled = (ehDisabled === true);
            }

            if(item.props.children.props.hasOwnProperty("cdPessoaEmpAtual")) {
                propsNewChild.cdPessoaEmpAtual = item.props.children.props.cdPessoaEmpAtual();
            }

            if(nolabels === true) {
                propsNewChild.label = null;
            } else {
                if(item.props.children.props.hasOwnProperty("label")) {
                    propsNewChild.label = (dpLinhas.length===0)?item.props.children.props.label:((repeatRowHeader===true)?item.props.children.props.label:null);
                }
            }

            if(campoInformado(propsNewChild.label) === true) {
            } else {
                propsNewChild.withoutLabelSpace = true;
            }

            const comboDataDynamic = [];

            if(item.props.children.props.hasOwnProperty("options")) {

                propsNewChild.dinApi = dinamycRefComboApi;

                if(item.props.children.props.hasOwnProperty("dynamic")) {
                    propsNewChild.options = comboDataDynamic;
                } else {
                    propsNewChild.options = item.props.children.props.options.clone();
                }

                // propsNewChild.options.addAll( item.props.children.props.options );
            }

            if(summary !== null && summary !== undefined) {
                const configSum = summary[item.props.children.props.name];
                if(configSum !== undefined && configSum !== null) {
                    propsNewChild.onChange = (e) => {
                        totalizaColuna(configSum);
                    }
                }
            }

            const newChild = React.cloneElement(item.props.children, propsNewChild);

            if(item.props.children.props.hasOwnProperty("options")) {
                combos[propsNewChild.name] = {
                    element: newChild,
                    data: [],
                    api: propsNewChild.dinApi,
                };
                if(item.props.children.props.hasOwnProperty("dynamic")) {
                    combos[propsNewChild.name].data = comboDataDynamic;
                }
            }

            const newItem = React.cloneElement(item, {
                key: name + "_cel_" + posName + "_" + c,
            }, [newChild]);

            return newItem ;

        });

        setCombos(combos);

        const rowHR = (alwaysLabels===true)?<Col xs={24}><hr className={"row-separator"}/></Col>:null;

        const novoElemLinha = (ehDisabled===true)?
            <Row gutter={[16, 0]} key={name + "_row_" + posName} className={"row-item-repeater"}>
                <Col xs={24} key={name + "_row_" + posName + "_1"} className={"box-container-fields"}>
                    <Row gutter={4}>
                        {novaLinha}
                    </Row>
                </Col>
                {rowHR}
            </Row>
            :
            <Row gutter={[16, 0]} key={name + "_row_" + posName} className={"row-item-repeater"}>
                <Col xs={24} md={20} lg={21} key={name + "_row_" + posName + "_1"} className={"box-container-fields"}>
                    <Row gutter={4}>
                        {novaLinha}
                    </Row>
                </Col>
                <Col xs={12} md={4} lg={3} key={name + "_row_" + posName + "_2"} className={"box-row-header-actions"}>
                    {(dpLinhas.length===0)?rowHeaderActions:(repeatRowHeader)?rowHeaderActions:null}
                    <Button type={"primary"} icon={iconPlus} onClick={() => btnAdicionaNovaLinha_Click( posicao )}></Button>
                    &nbsp;
                    {((dpLinhas.length>0)||(dpLinhas.length === 0 && firstLine===false))?<Button type={"primary"} danger icon={iconMinus} onClick={() => btnRemoveLinha_Click(novoElemLinha)}></Button>:null}
                    <IY2BHiddenRow>
                        <IY2BProFormHidden name={name + "_row_" + posName + "xAcoes_Adiciona"}/>
                        <IY2BProFormHidden name={name + "_row_" + posName + "xAcoes_Remove"}/>
                    </IY2BHiddenRow>
                </Col>
                {rowHR}
            </Row>
        ;

        dpLinhas.splice(posicao, 0, novoElemLinha);

        setDpLinhas(dpLinhas.clone());

        setLinhaAdicionadaRecentemente(true);

        forceUpdate();

        return resultado;

    }

    if(linhaAdicionadaRecentemente === true) {

        setTimeout(() => {

            setLinhaAdicionadaRecentemente(false);

            if(props.hasOwnProperty("novaLinhaAdicionada")) {
                props.novaLinhaAdicionada(ultCodLinha);
            }

        }, 1);

    }

    if(linhaRemovidaRecentemente === true) {

        setTimeout(() => {

            setLinhaRemovidaRecentemente(false);

            if(props.hasOwnProperty("linhaRemovida")) {
                props.linhaRemovida();
            }

        }, 1);

    }

    const eTitle = campoInformado(title) ? <div className={"title"}>{title}</div> : null;

    const eRowBotaoAdicionaInicial = (dpLinhas.length === 0 && firstLine===false && disabled===false)
    ?(
        <Row key={"rowAdicionaInicial"}>
            <Col xs={24}>
                <Button type={"primary"} icon={iconPlus} onClick={() => btnAdicionaNovaLinha_Click(0)}>&nbsp;{titleAddFirstLine}</Button>
            </Col>
        </Row>
    )
    :null;

    if(localDisabled !== disabled) {
        setLocalDisabled(disabled);
    }

    const boxClassName = ["box-form-field-repeater"];

    if(disabled === true) {
        boxClassName.push("bffr-disabled");
    }

    return (
        <div id={name} className={boxClassName.toClassName()}>
            {eTitle}
            {eRowBotaoAdicionaInicial}
            {dpLinhas}
            {elLinhaTotal}
        </div>
    );

}

export default FormFieldRepeater;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useRef, useState} from "react";
import {notificacaoErroRequest, useStateRef} from "../../lib/iy2b-react";
import {postJSON} from "../../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";
import FoldedFormField from "../proform/FoldedFormField/foldedFormField";
import {ProForm} from "@ant-design/pro-components";
import IY2BTable from "../IY2BTable";
import BotaoEditarRegistroTabela from "../BotaoEditarRegistroTabela/botaoEditarRegistroTabela";
import {Alert, Button, notification, Space, Tooltip} from "antd";
import {campoInformado} from "../../lib/iy2b-javascript";
import NovoChecklist from "./novoChecklist";
import VerChecklist from "./verChecklist";
import {CUSTOM, PLUS_CIRCLE_ICON_PRIMARY, PLUS_ICON_PRIMARY} from "../../assets/iconografia";
import {notificacao} from "../../lib/Notificacao";

const { ErrorBoundary } = Alert;

const iconPlusCircle = PLUS_ICON_PRIMARY;

const FoldedChecklistPessoa = ( props ) => {

    const { pessoa, cdPessoaEmpAtual, label = "Checklist", hidden = false, tpOrigemPessoa = "CRE", cdTransacao = 0 } = props;

    const tableRef = useRef();
    const [dsResultado, setDsResultado, refDsResultado] = useStateRef( [] );

    const [exibeNovoRegistro, setExibeNovoRegistro] = useState(false);
    const [exibeEditarRegistro, setExibeEditarRegistro] = useState(false);
    const [registrosAEditar, setRegistrosAEditar] = useState([]);

    const [dataProvider, setDataProvider] = useState({ });

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                clear: () => {
                    setDsResultado ( [] );
                }
            }
        };

        inicializaProjeto ();

        return () => {

        };

    }, []);


    useEffect(() => {

        if(props.dataSource !== null && props.dataSource !== undefined) {

            setDsResultado ( props.dataSource );

        } else {

            setDsResultado ( [] );

        }

        return () => {

        };

    }, [props.dataSource]);

    const inicializaProjeto = async () => {

        const payload = {
            "acaoAExecutar": "completaInicializa",
            tpOrigem: tpOrigemPessoa
        }

        try {

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sgr",
                projeto: "mnovochecklist"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            const dataProvider = {
                cdPessoaEmpAtual: cdPessoaEmpAtual
            };

            resultado.mensagens.forEach(msg => {

                if(msg.id === "listaTipoChecklist") {

                    dataProvider.dpTipoChecklist = msg.lista.map(item => {
                        return {
                            value: item.cdTipoChecklist,
                            label: item.noTipoChecklist,
                            data: {...item}
                        }
                    });

                } else {

                    console.log(msg);

                }

            });

            setDataProvider(dataProvider);

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

        return true;

    };

    /*
    const pesquisarCheckList = async () => {

        try {

            const payload = {
                acaoAExecutar: "pesquisa",
                filtro: {"hidCdPessoa": pessoa.cdPessoa}
            }

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sgr",
                projeto: "mendereco"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "listaEndereco") {

                    setDsResultado(msg.lista.map(item => {
                        return {
                            key: item.nrSeqEnd,
                            ... item
                        }
                    }));

                } else {

                    console.log(msg);

                }

            });

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

    }
*/

    const salvar = async (registro) => {

        try {

            const acaoWebAExecutar = campoInformado(registro.cdChecklist)?"alterar":"incluir";
            const projeto = campoInformado(registro.cdChecklist)?"mnovochecklist":"mnovochecklist";

            const payload = {
                acaoAExecutar: "acaoCRUD",
                tpOrigem: tpOrigemPessoa,
                cdPessoaCredor: pessoa.cdPessoa,
                cdPessoaCli: pessoa.cdPessoa,
                cdPessoaEmp: cdPessoaEmpAtual,
                registro: {
                    ... registro,
                }
            }

            console.log(payload);

            const resultado = await postJSON({
                acaoWebAExecutar: acaoWebAExecutar,
                sistema: "sgr",
                projeto: projeto
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    const modelo = msg.modeloPadrao;

                    modelo.key = modelo.cdChecklist;

                    const dsItem = refDsResultado.current.getBy("key", modelo.key);

                    if(dsItem === null) {

                        refDsResultado.current.push(modelo);

                        setDsResultado ( refDsResultado.current.clone() );

                    } else {

                        dsItem.noChecklist = modelo.noChecklist;
                        dsItem.noModeloChecklist = modelo.noModeloChecklist;
                        dsItem.noTipoChecklist = modelo.noTipoChecklist;
                        dsItem.dtCad = modelo.dtCad;
                        dsItem.dtConclusao = modelo.dtConclusao;

                    }

                } else {

                    console.log(msg);

                }

            });

            notificacao.sucesso({message:"Sucesso!", description:"Checklist salvo com sucesso!"})

            return true;

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const folded_OnStateChange = ( opened ) => {

        if(opened === true) {
            if(refDsResultado.current.isEmpty() === true) {
                // pesquisarCheckList();
            }
        }

    }

    const novo = () => {

        setExibeNovoRegistro (true);
        setExibeEditarRegistro(false) ;

        setRegistrosAEditar ([]);

    };

    const editar = ( endereco ) => {

        setExibeNovoRegistro (false);
        setExibeEditarRegistro(true) ;

        setRegistrosAEditar ([ endereco ]);

    }

    const actionView = (_, record) => {

        const botoes = [];

        botoes.push(
            <BotaoEditarRegistroTabela
                key={"btnEdit" + record.key}
                tooltip={"Editar este checklist"}
                registro={record}
                editarRegistro={editar}
            />
        );

        return (
            <Space size={4} align="baseline" className={"resultado-action-view"}>
                {botoes}
            </Space>
        );
    };

    const tblCols = [{
        title: '',
        key: 'actionView',
        width: 70,
        align: 'center',
        render: actionView
    }
        , {noColuna:'Código', field:'cdChecklist', width:80, align:'text-right'}
        , {noColuna:'Nome', field:'noChecklist', width:250}
        , {noColuna:'Tipo', field:'noTipoChecklist', width:170}
        , {noColuna:'Concluido em', field:'dtConclusao', width:120,  formatter:"data", align:'text-center'}
    ];

    const tableActions = [
        <Tooltip title="Novo checklist" color={"black"} key={"novoChecklist"}>
            <Button
                type="text"
                onClick={() => {
                    novo();
                }}
                className="action-button"
                icon={iconPlusCircle}
            >
                <span>&nbsp;Novo checklist</span>
            </Button>
        </Tooltip>
    ];

    return (
        <ErrorBoundary>
            <div className={"box-folded-checklist-pessoa"}>
                <FoldedFormField label={label} hidden={hidden} onStateChange={folded_OnStateChange} icon={CUSTOM.CHECK_CIRCLE_ICON}>
                    <ProForm.Group>
                        <IY2BTable id={"tblChecklist"} name={"tblChecklist"}
                                   customActions={tableActions}
                                   enableRowCheck={false}
                                   api={tableRef}
                                   dataSource={dsResultado}
                                   columns={tblCols}
                                   tableHeight={500}
                                   downloadDisabled={true}
                                   configDisabled={true}
                                   paginationDisabled={true}
                        />
                    </ProForm.Group>
                </FoldedFormField>
                <NovoChecklist cdTransacao={cdTransacao}
                               exibePopUp={ exibeNovoRegistro }
                               onRequestClose={() => {
                                   setExibeNovoRegistro(false) ;
                                   setExibeEditarRegistro(false) ;
                               }}
                               salvar={salvar}
                               dataProvider={dataProvider}
                               pessoa={pessoa}
                />
                <VerChecklist cdTransacao={cdTransacao}
                              exibePopUp={ exibeEditarRegistro }
                              onRequestClose={() => {
                                  setExibeNovoRegistro(false) ;
                                  setExibeEditarRegistro(false) ;
                              }}
                              registros={registrosAEditar}
                              salvar={salvar}
                              dataProvider={dataProvider}
                              pessoa={pessoa}
                />
            </div>
        </ErrorBoundary>
    );

}

export default FoldedChecklistPessoa;

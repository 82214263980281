//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../../../assets/css/tema.scss';
import "./style.scss";

import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {Col, Tooltip} from "antd";
import {HATWIZARD_ICON_PRIMARY_2X} from "../../../../assets/iconografia";

const iconWizardEnabled = HATWIZARD_ICON_PRIMARY_2X;

const BotaoWizard = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {
        };
    });

    const { opcoes } = props ;

    const [options, setOptions] = useState([]);

    useEffect(() => {
        return () => {
        };
    }, []);

    useEffect(() => {

        setOptions(opcoes.map(item => {

            return {
                value: item.idWizard,
                label: item.noWizard,
                data: {...item}
            }

        }));

        return () => {

        };

    }, [opcoes]);

    const element = (options.isEmpty() === true) ?
        null
    :
        (
            <Tooltip placement="bottom" title={"Assistente da operação atual"}>
                <Col flex={"45px"}>
                    <div id={"containerIconWizard"}>{iconWizardEnabled}</div>
                </Col>
            </Tooltip>
        )
    ;

    return element;

});

export default BotaoWizard;

//v5 import "antd/dist/antd.css";
import "./style.scss";

import {Button, Popconfirm} from "antd";
import React from "react";
import Icon, {DeleteOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import { ReactComponent as IconGoogleSVG }  from "../../../assets/svg/icon-google.svg";

const iconProvedor = <Icon component={IconGoogleSVG} />;

const BotaoGoogle = (props) => {

    const { onClickVincular, onClickReVincular, onClickDesvincular, block=true, cdProvedorIdentidade, jaVinculado, labelLogin=false, disabled=false } = props;

    const onClick = async () => {

        if(jaVinculado) {

            await onClickReVincular({cdProvedorIdentidade});

        } else {

            await onClickVincular({cdProvedorIdentidade});

        }

        return true;
    }

    const title = (disabled===true)?"Desabilitado":(labelLogin===true)?"Log in com Google":(jaVinculado===true)?"Google vinculado":"Vincular ao Google";

    const btnDesvinc = (disabled===false && jaVinculado===true)?
        <Popconfirm
            title="Confirma desvincular o acesso via Google？"
            okText="Sim, desvincular !"
            cancelText="Não"
            onConfirm={() => { onClickDesvincular({cdProvedorIdentidade})} }
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
            <Button
                type={"primary"} danger icon={<DeleteOutlined />}
            />
        </Popconfirm>
        : null;

    const boxClassName = [];

    if(btnDesvinc != null) boxClassName.push("tem-desvinc");

    return (
        <div className={boxClassName.toClassName()}>
            <Button className={"google"} type={"default"} onClick={onClick} icon={iconProvedor} block={block} disabled={disabled}>&nbsp;{title}</Button>
            {btnDesvinc}
        </div>
    );

}

export default BotaoGoogle;

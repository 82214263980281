//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";

import {Popconfirm} from "antd";
import BotaoToolTip from "../BotaoToolTip/botaoToolTip";
import React from "react";

import {TRASH_ICON_RED} from "../../assets/iconografia";

const iconTrash = TRASH_ICON_RED;

const BotaoExcluirRegistroTabela = ( props ) => {

    const { excluir, registro, tooltip, semConfirmacao=false, msgConfirmacao="Confirma a exclusão deste registro?" } = props;

    const elResultado =
        (semConfirmacao===true) ?
            <BotaoToolTip tooltip={tooltip} icon={iconTrash} className={"botao-excluir-registro"}
                          onClick={async () => {
                              await excluir([registro]);
                          }}
            />
        :
            <Popconfirm
                title={msgConfirmacao}
                onConfirm={async () => {
                    await excluir([registro]);
                }}
                okText="Sim, excluir"
                cancelText="Não"
            >
                <BotaoToolTip tooltip={tooltip} icon={iconTrash} className={"botao-excluir-registro"} />
            </Popconfirm>
    ;

    return elResultado;

}

export default BotaoExcluirRegistroTabela;

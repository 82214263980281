import '../../../assets/css/tema.scss';
import './portaisImob.scss';

import React, {useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {PLUS_ICON_PRIMARY} from "../../../assets/iconografia";
import {WORKSPACE_LOADING_INDICATOR_AREA, WorkspaceContext} from "../../../pages/Workspace/workspace";
import {
    notificacaoErroRequest,
    notificacaoErroValidForm,
    PADRAO_MARGEM_POPUP,
    ProjetoContext, useConteudoAlterado,
    useGridWidth,
    useModoExecucao, useStateRef,
    useWindowSize
} from "../../../lib/iy2b-react";
import {useFormState} from "../../../lib/useFormState";
import {TransacaoContentContext} from "../../../pages/TransacaoContent";
import DataProvider from "../../../lib/DataProvider";
import {campoInformado, formatadorCpfCnpj, ObjectUtils} from "../../../lib/iy2b-javascript";
import {notificacao} from "../../../lib/Notificacao";
import {postJSON} from "../../../lib/RequestAPI";
import BotaoEditarRegistroTabela from "../../../componentes/BotaoEditarRegistroTabela/botaoEditarRegistroTabela";
import BotaoExcluirRegistroTabela from "../../../componentes/BotaoExcluirRegistroTabela/botaoExcluirRegistroTabela";
import {Button, Col, Row, Space} from "antd";
import {
    buildCardCols,
    buildLabelCard,
    buildOrdenadores,
    defaultItemCardRender
} from "../../../componentes/IY2BCards/iy2bCard";
import IY2BReactModal from "../../../componentes/IY2BReactModal";
import {ProForm, ProFormText} from "@ant-design/pro-components";
import IY2BProFormMasked from "../../../componentes/IY2BProFormMasked";
import IY2BProFormFiller from "../../../componentes/proform/IY2BProFormFiller/iy2bProFormFiller";
import IY2BCards from "../../../componentes/IY2BCards/iy2bCards";
import IY2BHiddenRow from "../../../componentes/IY2BHiddenRow";
import IY2BProFormHidden from "../../../componentes/IY2BProFormHidden";
import ComboBox from "../../../componentes/proform/ComboBox/comboBox";
import RadioGroup from "../../../componentes/proform/RadioGroup/radioGroup";
import CheckGroup from "../../../componentes/proform/CheckGroup/checkGroup";


const iconPlusCircle = PLUS_ICON_PRIMARY;

const PAGE_SIZE_RESULTADO = 1000;

const PortaisImob = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {};
    });

    const {exibePopUp, onRequestClose, dataProvider, cdTransacao = 0} = props;

    const {cdPessoaEmpAtual} = props;

    const {availableWidth} = useContext(WorkspaceContext);

    const {getValue} = useContext(ProjetoContext);

    const windowSize = useWindowSize();
    const [, gridWidth] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_) <= 0) ? 10 : windowSize.widthMargin(PADRAO_MARGEM_POPUP.LG);
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT * 2));
    const MODAL_CONTENT_TOP = windowSize.heightMargin(PADRAO_MARGEM_POPUP.SM);
    const MARGIN_BOTTOM = windowSize.heightMargin(PADRAO_MARGEM_POPUP.SM); // 24;

    const modalRefApi = useRef();

    const formRef = useRef();
    const formState = useFormState({flgCarteiraCompleta: "S"});

    const modoExecucao = useModoExecucao("INC");
    const conteudoAlterado = useConteudoAlterado();

    const [, setRegistroDaTela, refRegistroDaTela] = useStateRef(null);
    const [, setTelaProtegida, refTelaProtegida] = useStateRef(false);

    const forceUpdate = useForceUpdate();

    const planoEhGratuito = getValue("planoEhGratuito", true);

    const tableRef = useRef();

    const {containerHeight} = useContext(TransacaoContentContext);

    const [dsResultado, setResultado, refDsResultado] = useStateRef(new DataProvider({pageSize: PAGE_SIZE_RESULTADO}));

    const labelCard = "noPortalImovel";
    const cardColumns = 1;
    const orientationCard = "horizontal";

    const [tableHeight, setTableHeight, refTableHeight] = useStateRef(80);

    useEffect(() => {

        console.log("props.cdPessoaEmpAtual", props.cdPessoaEmpAtual)

        if(campoInformado(cdPessoaEmpAtual)) {
            if(exibePopUp) {
                pesquisaInicial();
            }
        }

        return () => {

        };

    }, [props.exibePopUp]);

    const controleExibicao = (registro) => {

    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();

        }, 1);

    }

    const novoRegistro = () => {

        formRef?.current?.resetFields();

        setRegistroDaTela ({

        });

        formState.clear({ flgCarteiraCompleta: "S" });

        controleExibicao ( );

        modoExecucao.setModoExecInclusao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const setRegistroNaTela = (registro) => {

        console.log("setRegistroNaTela", registro);

        /*
        cmbCdPessoaBidDefault
         */

        const attrsTela = [
            "flgCarteiraCompleta",
        ];

        const registroDaTela = {
            ...ObjectUtils.valuesFrom(registro, attrsTela),
            tpObjImovel: []
        };

        registroDaTela.cmbCdPortalImovel = dataProvider.dpPortalImovel.getBy("value", registro.cdPortalImovel);

        if(registro.tpObjImovel === "LV") {
            registroDaTela.tpObjImovel = ["L", "V"];
        } else if(registro.tpObjImovel === "L") {
            registroDaTela.tpObjImovel = ["L"];
        } else if(registro.tpObjImovel === "V") {
            registroDaTela.tpObjImovel = ["V"];
        }

        formState.set("flgCarteiraCompleta", registro.flgCarteiraCompleta);

        setRegistroDaTela ( registroDaTela );

        formRef.current?.setFieldsValue( registroDaTela );

        forceUpdate();

        controleExibicao ( registroDaTela );

        modoExecucao.setModoExecAlteracao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    };

    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        console.log("getRegistroDaTela.ffValues", ffValues);

        /*
"cdPessoaEmp":393454,"cdPortalImovel":4,"noPortalImovel":"Vista Hot","tpObjImovel":"L","flgCarteiraCompleta":"S","txLogin":null,"txSenha":null
         */

        const attrsTela = [
            "flgCarteiraCompleta",
        ];

        const registro = {
            ...ObjectUtils.valuesFrom(ffValues, attrsTela),
            cdPessoaEmp: cdPessoaEmpAtual,
            "txLogin":null, "txSenha":null,
            cdPortalImovel: ffValues.cmbCdPortalImovel.value,
            noPortalImovel: ffValues.cmbCdPortalImovel.label,
            tpObjImovel: " "
        };

        if(ffValues.flgCarteiraCompleta === "S") {

            if(ffValues.tpObjImovel.indexOf("L") > -1 && ffValues.tpObjImovel.indexOf("V") > -1) {
                registro.tpObjImovel = "LV";
            } else if(ffValues.tpObjImovel.indexOf("L") > -1) {
                registro.tpObjImovel = "L";
            } else if(ffValues.tpObjImovel.indexOf("V") > -1) {
                registro.tpObjImovel = "V";
            }

        }

        console.log("getRegistroDaTela.registro", registro);

        return registro;

    }

    const validaTela = async (params) => {

        let resultado = true;

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        return resultado;

    };

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return;

            if(conteudoAlterado.isConteudoAlterado() === false) {
                notificacao.aviso({message:"Sem alteração", description:"Nenhuma informação alterada para ser salva !"})
                return;
            }

            const registro = getRegistroDaTela();

            const resultado = await salvar (registro);

            if(resultado === true) {
                pesquisaInicial();
            }

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e, cdTransacao);

        }

    }

    const salvar = async (registro) => {

        try {

            const acaoWebAExecutar = modoExecucao.isInclusao()?"incluir":"alterar";

            const payload = {
                acaoAExecutar: "acaoPortalImv",
                registro: registro,
            };

            const resultado = await postJSON({
                acaoWebAExecutar: acaoWebAExecutar,
                sistema: "sli",
                projeto: "mimobiliaria"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    const modelo = msg.modeloPadrao;

                    modelo.key = modelo.cdPortalImovel;

                    const dsItem = refDsResultado.current.find({
                        key: modelo.key
                    });

                    if(dsItem === null) {

                        const novods = refDsResultado.current.add( modelo ).clone();
                        setResultado ( novods );

                    } else {

                        dsItem.tpObjImovel = modelo.tpObjImovel;
                        dsItem.flgCarteiraCompleta = modelo.flgCarteiraCompleta;

                    }

                }

            });

            notificacao.sucesso({transacao:cdTransacao,message:"Sucesso!", description:"Vínculo com portal imobiliário salvo com sucesso!"})

            return true;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const excluir = async (registros) => {

        try {

            const payload = {
                acaoAExecutar: "acaoPortalImv",
                lista: registros
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "excluir",
                sistema: "sli",
                projeto: "mimobiliaria"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelosExcluidos") {

                    const novods = refDsResultado.current.remove(msg.lista.map(item => {
                        return {key: item.cdPortalImovel}
                    })).clone() ;

                    setResultado( novods ) ;

                } else {

                    console.log(msg);

                }

            });

            notificacao.sucesso({transacao:cdTransacao,message:"Sucesso!", description:"Vínculo com portal imobiliário excluído com sucesso!"})

            return true;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    };

    const editarRegistro = (registros) => {
        novoRegistro();
        setRegistroNaTela(registros[0]);
    }

    const editar = () => {

        const { marcados } = dsResultado;

        editarRegistro ( marcados );

    }

    const actionView = (_, record) => {

        const botoes = [];

        botoes.push(
            <BotaoEditarRegistroTabela
                key={"btnEdit" + record.key}
                tooltip={"Editar este registro"}
                registro={record}
                editarRegistro={() => {
                    editar();
                }}
            />
        );

        botoes.push(<BotaoExcluirRegistroTabela
            key={"btnDelete" + record.key}
            registro={record}
            excluir={excluir}
            tooltip={"Excluir este registro"}
        />);

        return (
            <Space size={4} align="baseline" className={"resultado-action-view"}>
                {botoes}
            </Space>
        );

    };

    const calcTableHeight = () => {

        const cHeight = containerHeight();

        console.log("calcTableHeight", cHeight);

        setTableHeight(cHeight - 70 - 50);

    }

    const pesquisar = async () => {

        const filtro = {
        };

        const payload = {
            "acaoAExecutar": "pesquisaListaPortaisImv",
            filtro: {
                txtCdPessoaImobiliaria: cdPessoaEmpAtual,
            },
        }

        try {

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sli",
                projeto: "mimobiliaria"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            let dp = new DataProvider({pageSize: PAGE_SIZE_RESULTADO});

            resultado.mensagens.forEach(msg => {

                if(msg.id === "listaImobiliariaPortalImv") {

                    const listaDP = msg.lista.map(item => {
                        item.key = item.cdPortalImovel;
                        return item;
                    });

                    dp = new DataProvider({
                        data: listaDP,
                        total: listaDP.length,
                        page: 1,
                        pageSize: listaDP.length
                    });

                }

            });

            return dp;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return new DataProvider();

        }

    }

    const pesquisaInicial = async () => {

        novoRegistro();

        setResultado(new DataProvider({pageSize: PAGE_SIZE_RESULTADO}));

        tableRef.current?.clearData();

        await pesquisaPaginacao();

        // if(tableHeight === 80) calcTableHeight();

    }

    const pesquisaPaginacao = async () => {

        const dp = await pesquisar ();

        const newDP = refDsResultado.current.clone();

        newDP.addToInfinite(dp);

        setResultado(newDP);

        calcTableHeight();

        return newDP;

    }

    const flgCarteiraCompleta_OnChange = (value) => {

        formState.set("flgCarteiraCompleta", value);

        if(value === "S") {

            formRef.current?.setFieldsValue( {
                tpObjImovel: ["L", "V"]
            });

        } else {

            formRef.current?.setFieldsValue( {
                tpObjImovel: []
            });

        }

    }

    const colFlgCarteiraCompletaRender = (_, registro) => {
        return (registro.flgCarteiraCompleta === "S") ? "Imóveis disponíveis" : "Apenas vínculados";
    };

    const colTpObjImovelRender = (_, registro) => {
        if(registro.tpObjImovel === "L") return "Locação";
        if(registro.tpObjImovel === "V") return "Venda";
        if(registro.tpObjImovel === "LV") return "Locação e venda";
        return "Apenas vínculados";
    };

    const tblCols = [
        {noColuna:'Portal', field:'noPortalImovel', width:300, colSpan:1, card:false},
        {noColuna:'Seleção de imóveis', field:'flgCarteiraCompleta', width:100, colSpan:1, card:true, render: colFlgCarteiraCompletaRender},
        {noColuna:'Objetivo', field:'tpObjImovel', width:100, colSpan:1, card:true, render: colTpObjImovelRender},
    ];

    const cardCols = buildCardCols({tblCols: tblCols});

    const labelCardCols = buildLabelCard({tblCols: tblCols, labelCard: labelCard});

    const localRenderItemCard = defaultItemCardRender({cardCols: cardCols, labelCard: labelCard, orientationCard: orientationCard, cardColumns: cardColumns});

    const ordenadores = buildOrdenadores({ cardCols: cardCols, labelCardCols: labelCardCols });

    const popupTitle = "Portais imobiliários vinculados";

    const fiValues = {
        flgCarteiraCompleta: "S",
        tpObjImovel: ["L", "V"]
    };

    return (
        <ErrorBoundary>
            <IY2BReactModal
                left={MARGIN_LEFT}
                top={MODAL_CONTENT_TOP}
                width={MODAL_CONTENT_WIDTH}
                marginBottom={MARGIN_BOTTOM}
                exibePopUp={exibePopUp}
                onRequestClose={onRequestClose}
                bodyOpenClassName={"editar-portal-vinc-imob"}
                title={popupTitle}
                api={modalRefApi}
                body={
                    <div className={"body-editar-portal-vinc-imob"}>
                        <ProForm
                            formRef = { formRef }
                            isKeyPressSubmit = {true}
                            initialValues={fiValues}
                            onFinish = { async ( ) => {
                                if(refTelaProtegida.current === true) return;
                                await doSalvar();
                            }}
                            submitter={{
                                // Completely customize the entire area
                                render : ( props , doms ) => {
                                    return null;
                                }
                            }}
                            onValuesChange={async (changeValues, allValues) => {
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "flgCarteiraCompleta", flgCarteiraCompleta_OnChange, true);
                                /*
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCliente_NoPessoa", acCliente_OnChange, true);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCliente_NrCGCCIC", acCliente_OnChange, true);

                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCentroCusto", acCentroCusto_OnChange);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "vlrDesconto", onUpdateVlrTotal);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "flgOrcamento", flgOrcamento_OnChange);
                                conteudoAlterado.fireOnChangeFFR(changeValues, allValues, "itens", "vlrOrcPecas", onUpdateVlrTotal);
                                conteudoAlterado.fireOnChangeFFR(changeValues, allValues, "itens", "vlrOrcServ", onUpdateVlrTotal);
                                */
                            }}
                            onFieldsChange = {async (changedFields) => {
                                conteudoAlterado.updateConteudoAlterado(changedFields);
                            }}
                        >
                            <div className={"pro-form-group-spacer"}>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col span={24} >
                                            <ComboBox
                                                width={"xl"}
                                                formRef = { formRef }
                                                name = "cmbCdPortalImovel"
                                                label = "Portal imobiliário"
                                                tooltip = "Portal imobiliário para vincular"
                                                placeholder={"Selecione o portal imobiliário"}
                                                options={dataProvider.dpPortalImovel}
                                                rules={[
                                                    {required: true, message:"Informe o portal imobiliário"}
                                                ]}
                                                disabled={(modoExecucao.isAlteracao() === true)}
                                            />
                                            <IY2BProFormFiller />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={24} md={12}>
                                            <RadioGroup
                                                width = {"xl"}
                                                name="flgCarteiraCompleta"
                                                label="Seleção de imóveis"
                                                tooltip = { "Qual a carteira de imóveis enviada para este portal ?" }
                                                rules={[
                                                    {required: true, message: 'Escolha uma seleção'},
                                                ]}
                                                forceUpdate={forceUpdate}
                                                options={[
                                                    {
                                                        label: 'Imóveis disponíveis',
                                                        value: 'S',
                                                    },
                                                    {
                                                        label: 'Apenas vinculados',
                                                        value: 'N',
                                                    }
                                                ]}
                                            />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <CheckGroup
                                                label={"Objetivo"}
                                                name={"tpObjImovel"}
                                                options={[{
                                                    label:"Locação", value:"L"
                                                }, {
                                                    label:"Venda", value:"V"
                                                }]}
                                                layout={"horizontal"}
                                                width={"xl"}
                                                tooltip={"Qual o objetivo dos imóveis enviada para este portal ?"}
                                                rules={[
                                                    {required: (formState.get("flgCarteiraCompleta")==="S"), message: 'Escolha ao menos um objetivo'},
                                                ]}
                                                disabled={(formState.get("flgCarteiraCompleta")==="N")}
                                            />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                                <ProForm.Group>
                                    <Row gutter={[8, 16]}>
                                        <Col xs={24} md={{span:4, offset:20}}>
                                            <Button type={"primary"} className={"salvar"} onClick={async () => await doSalvar()}>
                                                Salvar
                                            </Button>
                                        </Col>
                                        <Col xs={24} md={{span:6, offset:18}}>
                                            <IY2BProFormFiller />
                                        </Col>
                                    </Row>
                                </ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col span={24}>
                                        <IY2BCards id={"cards-portal-vinc-imob" } name={"cards-portal-vinc-imob"}
                                                   renderItem={ item => localRenderItemCard(item, actionView) }
                                                   botaoAcoesDisponiveis={{
                                                       disabled: true
                                                   }}
                                                   gridCols={3}
                                                   enableRowCheck={false}
                                                   ref={tableRef}
                                                   dataSource={dsResultado}
                                                   ordenadores={ordenadores}
                                                   columns={cardCols}
                                                   tableHeight={tableHeight}
                                                   pagPageSize={PAGE_SIZE_RESULTADO}
                                                   onRequestPage = {async () => {
                                                       return await pesquisaPaginacao();
                                                   }}
                                                   downloadDisabled={planoEhGratuito}
                                                   configDisabled={planoEhGratuito}
                                                   infiniteScroll={true}
                                        />
                                    </Col>
                                </Row>
                                <IY2BHiddenRow>
                                    <IY2BProFormHidden name={"cdPessoaRepLegal"}/>
                                    <IY2BProFormHidden name={"cdRepLegal"}/>
                                    <IY2BProFormHidden name={"flgOk"}/>
                                </IY2BHiddenRow>
                            </div>
                        </ProForm>
                    </div>
                }
            />
        </ErrorBoundary>
    );

});

export default PortaisImob;

import "./index.scss";

import ReactModal from "react-modal";
import React, {useEffect} from "react";
import {Button, Alert} from "antd";
import {useWindowSize} from "../../lib/iy2b-react";
import {campoInformado, currentBrowser} from "../../lib/iy2b-javascript";
import {CUSTOM} from "../../assets/iconografia";

const { ErrorBoundary } = Alert;

const iconTimes = CUSTOM.TIMES_ICON;

const onAfterOpen = () => { // prevent body scrolling iOS
    if(currentBrowser.ISMOBILE === true) {
        console.log("mobile, onAfterOpen");
        document.body.style.position = 'fixed';
    }
}

const onAfterClose = () => { // prevent body scrolling iOS
    if(currentBrowser.ISMOBILE === true) {
        console.log("mobile, onAfterClose");
        document.body.style.position = 'unset';
        setTimeout(() => {
            console.log("mobile, onAfterClose => window.scrollTo(0, 0)");
            window.scrollTo(0, 0);
        }, 1);
    }
}
const IY2BReactModal = (props) => {

    const windowSize = useWindowSize();

    const { width, top, left, exibePopUp=false, onRequestClose, bodyOpenClassName, overlayClassName, showActions=true } = props;

    const { afterOpen } = props;

    const {title, body, footer, actions, marginBottom = top} = props;

    const hasActions = (actions !== null && actions !== undefined && showActions===true);

    const actionsClasses = ["box-actions"];

    if(hasActions === false) actionsClasses.push("hidden");

    const boxBodyClasses = ["box-body"];

    if(hasActions === true) boxBodyClasses.push("has-actions");

    const { height = windowSize.height - top - marginBottom } = props;

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                bodyAvailableHeight: () => {
                    const modalContentEl = document.getElementsByClassName("modalContent " + bodyOpenClassName);
                    if(modalContentEl.length > 0) {
                        const boxBodyEl = modalContentEl[0].getElementsByClassName("box-body");
                        if (boxBodyEl.length > 0) {
                            return boxBodyEl[0].clientHeight;
                        }
                    }
                    return 0;
                },
                bodyScrollTop: () => {
                    console.log("bodyScrollTop");
                    const modalContentEl = document.getElementsByClassName("modalContent " + bodyOpenClassName);
                    if(modalContentEl.length > 0) {
                        const boxBodyEl = modalContentEl[0].getElementsByClassName("box-body");
                        if(boxBodyEl.length > 0) {
                            if(currentBrowser.ISMOBILE === true) {
                                // boxBodyEl[0].scrollTop = 30; esta perdendo o "header do site"
                                console.log("bodyScrollTop, nao setar boxBodyEl[0].scrollTop para mobile")
                            } else {
                                boxBodyEl[0].scrollTop = 0;
                            }
                        }
                    }
                },
                bodyScrollBottom: () => {
                    console.log("bodyScrollBottom");
                    const modalContentEl = document.getElementsByClassName("modalContent " + bodyOpenClassName);
                    if(modalContentEl.length > 0) {
                        const boxBodyEl = modalContentEl[0].getElementsByClassName("box-body");
                        if(boxBodyEl.length > 0) {
                            if(currentBrowser.ISMOBILE === true) {
                                // boxBodyEl[0].scrollTop = 30; esta perdendo o "header do site"
                                console.log("bodyScrollBottom, nao setar boxBodyEl[0].scrollTop para mobile")
                            } else {
                                boxBodyEl[0].scrollTop = boxBodyEl[0].scrollHeight;
                            }
                        }
                    }
                }
            };
        }

        console.log("bodyOpenClassName", bodyOpenClassName);

        return () => {

        };

    }, []);

    const closePopUp = () => {
        onRequestClose();
    };

    return (
        <ReactModal
            isOpen={exibePopUp}
            className={["modalContent", bodyOpenClassName].toClassName()}
            overlayClassName={["modalOverlay", overlayClassName].toClassName()}
            bodyOpenClassName={["iy2b-react-modal", bodyOpenClassName].toClassName()}
            closeTimeoutMS={500}
            onRequestClose={closePopUp}
            style={{
                content: {
                    left: left + 'px',
                    width: width + "px",
                    top: top + "px",
                    height: height + "px"
                }
            }}
            onAfterOpen={() => {
                onAfterOpen();
                if(campoInformado(afterOpen) === true) afterOpen();
            }}
            onAfterClose={() => {
                onAfterClose();
            }}
        >
            <ErrorBoundary>
                <div className={"modal-header"}>
                    <div className={"title"}>{title}</div>
                    <div className={"top-bar"}>
                        <Button onClick={closePopUp} icon={iconTimes} className={"botao-fechar"}></Button>
                    </div>
                </div>
                <div className={boxBodyClasses.toClassName()}>
                    { body }
                </div>
                {(hasActions===true)?
                    <div className={actionsClasses.toClassName()}>
                        { actions }
                    </div>
                    : null
                }
                <div className={"modal-footer"}>
                    { footer }
                </div>
            </ErrorBoundary>
        </ReactModal>
    )

}


export default IY2BReactModal;

export {
    onAfterOpen,
    onAfterClose
}

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";

import {Button} from "antd";
import React, {useState, useEffect} from "react";

const BotaoAcao = ( props ) => {

    const { label, type="default", icon, buttonClassName, onClick, block=false, disabled=false, hidden=false, withoutLabelSpace = false, numRow=null, ffrDisabled=undefined } = props;

    const [localDisabled, setLocalDisabled] = useState(disabled);

    const className = ["iy2b-botao-acao"];

    if(hidden===true) {
        className.push("not-visible");
    }

    const [loading, setLoading] = useState(false);

    const buttonClick = async (e) => {

        setLoading(true);

        await onClick(e, numRow);

        setLoading(false);

    };

    useEffect(() => {

        if(props.hasOwnProperty("ffrDisabled")===false) {
            setLocalDisabled(disabled);
        }

        return () => {

        };

    }, [disabled]);

    if(props.hasOwnProperty("ffrDisabled")===true) {
        const ffrDisabledValue = ffrDisabled();
        if(ffrDisabledValue !== localDisabled) setLocalDisabled(ffrDisabledValue);
    }

    return (
        <div className={className.toClassName()}>
            <Button
                type={type}
                className={buttonClassName}
                block={block}
                icon={icon}
                onClick={buttonClick}
                loading={loading}
                disabled={localDisabled}
            >
                {label}
            </Button>
        </div>
    );


}

export default BotaoAcao;

import { useLocation } from 'react-router-dom';
import React, {  useState, useEffect, useRef } from 'react';
import {message, notification} from "antd";
import qs from "qs";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import eventBus from "./eventBus";
import {campoInformado, campoInformadoString} from "./iy2b-javascript";
import {notificacao} from "./Notificacao";

const useQueryString = () => {
    return new URLSearchParams(useLocation().search);
};

const useInterval = (callback, delay) => {

    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        let id = setInterval(() => {
            savedCallback.current();
        }, delay);
        return () => clearInterval(id);
    }, [delay]);

};

const useStateRef = (value) => {

    const [variavel, _atribuidor] = useState(value);

    const variavelRef = useRef(variavel);

    const atribuidor = (x) => {
        variavelRef.current = x; // keep updated
        _atribuidor(x);
    }

    return [variavel, atribuidor, variavelRef];

}

const useStateKeepRef = (value) => {

    const [variavel, _atribuidor] = useState(value);

    const variavelRef = useRef(variavel);

    const atribuidor = (x) => {
        for(const key in variavelRef.current) {
            variavelRef.current[key] = null;
        }
        for(const key in x) {
            variavelRef.current[key] = x[key];
        }
        _atribuidor(x);
    }

    return [variavel, atribuidor, variavelRef];

}

const useConteudoAlterado = () => {

    const [, setConteudoAlterado, refConteudoAlterado] = useStateRef(false);
    const [, setFFValue, refFFValue] = useStateRef({ });

    const logSetConteudoAlterado = ( value ) => {
        // console.log("setConteudoAlterado", value);
        setConteudoAlterado ( value );
    }

    return {
        setConteudoAlterado: () => logSetConteudoAlterado(true),
        limpaConteudoAlterado: (formInstance) => {
            logSetConteudoAlterado(false);
            if(campoInformado(formInstance)) {
                const ffValues = formInstance.getFieldsValue(true);
                const fields = [];
                Object.keys(ffValues).forEach(field => {
                    fields.push({
                        name: field,
                        touched: false
                    });
                });
                formInstance.setFields(fields);
            }
            setFFValue({ });
        },
        updateConteudoAlterado: (changedFields) => {
            if(changedFields.isEmpty() === true) return;
            const toucheds = changedFields.filter(item => (item.touched === true));
            if(toucheds.isEmpty() === false) logSetConteudoAlterado(true);
        },
        isConteudoAlterado: () => {
            // console.log("isConteudoAlterado", refConteudoAlterado.current);
            return (refConteudoAlterado.current === true);
        },
        fireOnValueChange: async (changeValues, allValues, fieldName, onChange, bLimpa) => {
            if(changeValues.hasOwnProperty(fieldName) === true) {
                refFFValue.current[fieldName] = true;
                setFFValue(refFFValue.current);
                await onChange(changeValues[fieldName]);
            } else {
                if(allValues.hasOwnProperty(fieldName) === false) {
                    if(bLimpa === true) {
                        if(campoInformado(refFFValue.current[fieldName]) === true) {
                            await onChange(undefined);
                        }
                    }
                    refFFValue.current[fieldName] = undefined;
                    setFFValue(refFFValue.current);
                }
            }
        },
        fireOnChangeFFR: async (changeValues, allValues, ffrName, fieldName, onChange, bLimpa) => {

            let encontrou = false;

            for(const name in changeValues) {
                if(name.startsWith(ffrName + "_") && name.endsWith("_" + fieldName)) {
                    encontrou = true;
                    refFFValue.current[name] = true;
                    setFFValue(refFFValue.current);
                    await onChange(changeValues[name]);
                }
            }

            if(encontrou === false) {
                for(const name in allValues) {
                    if (name.startsWith(ffrName + "_") && name.endsWith("_" + fieldName)) {
                        if(bLimpa === true) {
                            if(campoInformado(refFFValue.current[name]) === true) {
                                await onChange(undefined);
                            }
                        }
                        refFFValue.current[name] = undefined;
                        setFFValue(refFFValue.current);
                    }
                }
            }

        }
    }

}

const useModoExecucao = (modoDefault) => {

    const [, setModoExecucao, refModoExecucao] = useStateRef(modoDefault);

    return {
        setModoExecPK: () => setModoExecucao("PK"),
        setModoExecInclusao: () => setModoExecucao("INC"),
        setModoExecAlteracao: () => setModoExecucao("ALT"),
        isInclusao: () => refModoExecucao.current === "INC",
        isAlteracao: () => refModoExecucao.current === "ALT",
        isPK: () => refModoExecucao.current === "PK",
        modoAtual: () => refModoExecucao.current
    }

}

const useGridWidth = () => {

    // const breakPoint = useBreakpoint();

    const windowSize = useWindowSize();

    // console.log("windowSize " + windowSize.width + "x" + windowSize.height);

    let DEFAULT = window._UNDEFINED_ ;

    if(campoInformado(windowSize) === true) {
        if(campoInformado(windowSize.width) === true) {
            if(windowSize.width < 576) {
                DEFAULT = (window._XS_);
            } else if(windowSize.width >= 1600) {
                DEFAULT = (window._XXL_);
            } else if(windowSize.width >= 1200) {
                DEFAULT = (window._XL_);
            } else if(windowSize.width >= 992) {
                DEFAULT = (window._LG_);
            } else if(windowSize.width >= 768) {
                DEFAULT = (window._MD_);
            } else {
                DEFAULT = (window._SM_);
            }
        }
    }

    const [gridSize, setGridSize, refGridSize] = useStateRef(DEFAULT);

    useEffect(() => {

        if(campoInformado(windowSize) === true) {
            if(campoInformado(windowSize.width) === true) {
                if(windowSize.width < 576) {
                    setGridSize(window._XS_);
                } else if(windowSize.width >= 1600) {
                    setGridSize(window._XXL_);
                } else if(windowSize.width >= 1200) {
                    setGridSize(window._XL_);
                } else if(windowSize.width >= 992) {
                    setGridSize(window._LG_);
                } else if(windowSize.width >= 768) {
                    setGridSize(window._MD_);
                } else {
                    setGridSize(window._SM_);
                }
                // console.log("windowSize.effect " + windowSize.width);
            }
        }

    }, [windowSize]);

    // console.log("useGridWidth", gridSize, refGridSize);

    return [gridSize, refGridSize];

}

const PADRAO_MARGEM_POPUP = {
    XXS: 0.025,
    XS: 0.05,
    SM: 0.075,
    MD: 0.10,
    LG: 0.15,
    XL: 0.20,
    XXL: 0.25
}

const useWindowSize = () => {
    //
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
        widthMargin: PADRAO => 0,
        heightMargin: PADRAO => 0
    });

    useEffect(() => {

        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
                widthMargin: PADRAO => (window.innerWidth * PADRAO),
                heightMargin: PADRAO => (window.innerHeight * PADRAO)
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);

    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;

};

const getTableHeight = (idFull, idFilled, gap) => {

    const elFull = document.querySelector(idFull);

    const elFilled = document.querySelector(idFilled);

    if(elFull) {

        if(elFilled) {

            const height = elFull.clientHeight;

            const formHeight = elFilled.clientHeight;

            const tableHeight = height - formHeight - gap;

            return tableHeight;

        }

    }

    return 0;

};

const notificacaoErroValidForm = (errorInfo, transacao) => {

    if(errorInfo.hasOwnProperty("errorFields")) {

        errorInfo.errorFields.forEach(field => {

            field.errors.forEach(erro => notificacao.erro({errorId:"errorFields", transacao:transacao||0, message:"Atenção !", description: erro}));

        });

    } else {

        console.log(errorInfo);

    }

}

const notificacaoErroRequest = (errorInfo, transacao) => {

    if(errorInfo.data) {
        const eClass = errorInfo.data["class"];
        if(eClass === "erroDeAcao") {
            notificacao.erro({transacao:transacao||0, message: "Ooops!", description:errorInfo.data.descricao})
        } else if(eClass === "falhaDeValidacao") {
            notificacao.erro({transacao:transacao||0, message: "Ooops!", description:errorInfo.data.descricao})
        } else {
            console.log(errorInfo);
            notificacao.erro({transacao:transacao||0, message: "Ooops!", description:"Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !"})
        }
    } else {

        if(errorInfo === "authExpired") {

            // mensagem de sessao expirada é exibida no workspace......

        } else {
            console.log(errorInfo);
            notificacao.erro({transacao:transacao||0, message: "Ooops!", description:"Ocorreu uma falha inesperada. Por favor tente novamente em alguns instantes !"})
        }

    }

}

const transformData = (data) => {

    let i, key, v, value;

    for (key in data) {
        value = data[key];
        if (typeof value === "object") {
            for (i in value) {
                v = value[i];

                /***************************************************************
                 // Kalel - 09/01/2017
                 //
                 //      Com a validacao anterior 'if (!value[i])' caso houvesse
                 //  um objeto com o valor ZERO ele retornaria true e excluiria o
                 //  obj do request.
                 //
                 // EX:
                 //   value.i = 0
                 //   if (!value[i]) => if(!'ZERO') => if(!false) => if(true)
                 //
                 //****************************************************************/

                if (value[i] === undefined || value[i] === null) {
                    delete value[i];
                }

            }
            data[key] = JSON.stringify(value);
        }
    }

    return qs.stringify(data);

};

const inputPreventPaste = (e) => {

    e.preventDefault();

}

const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

const trackUnauthorized_V1 = (promise) => {

    const onResolveHandler = (resultado) => {

        if(resultado.hasOwnProperty("data")) {
            const _data = resultado.data;
            if(_data.hasOwnProperty("statusText")) {
                if(_data.hasOwnProperty("key")) {
                    if(_data.statusText === "unauthorized") {
                        if(_data.key === "authExpired") {
                            // redirecionar para login
                            console.log(_data);
                            eventBus.dispatch("requestAPI/unauthorized");
                        }
                    }
                }
            }
        }

    };

    promise.then(onResolveHandler, onResolveHandler);

    return promise;

}

const trackUnauthorized = (promise) => {

    const trackPromise = new Promise(function(myResolve, myReject) {

        const onResolveHandler = (resultado) => {

            myResolve ( resultado );

        };

        const onRejectHandler = (rejection) => {

            let authExpired = false;

            if(rejection.hasOwnProperty("data")) {
                const _data = rejection.data;
                if(_data.hasOwnProperty("statusText")) {
                    if(_data.hasOwnProperty("key")) {
                        if(_data.statusText === "unauthorized") {
                            if(_data.key === "authExpired") {
                                // redirecionar para login
                                console.log(_data);
                                authExpired = true;
                            }
                        } else {
                            if(_data.statusText === "loginTimeout") {
                                if(_data.key === "loginTimeout") {
                                    // redirecionar para login
                                    console.log(_data);
                                    authExpired = true;
                                }
                            }
                        }
                    }
                }
            }

            if(authExpired === true) {
                eventBus.dispatch("requestAPI/unauthorized");
                myReject("authExpired");
            } else {
                myReject(rejection);
            }

        }

        promise.then(onResolveHandler, onRejectHandler);

    });

    return trackPromise;

}

const rulesCreator = (lista) => {

    const rules = lista.filter( item => item.condicao() ).map(item => {
        return item.rule;
    });

    return rules;

}

const ruleRegExpCreator = ({regExp, message}) => {

    return {
        validator: async (rule, value) => {
            if(campoInformadoString(value) === true) {
                const resultadoTeste = regExp.test(value);
                if( resultadoTeste === false) {
                    throw new Error(message) ;
                }
            }
            return true;
        }
    }

}
const fireOnValueChange = async (changeValues, allValues, fieldName, onChange, bLimpa) => {
    if(changeValues.hasOwnProperty(fieldName) === true) {
        await onChange(changeValues[fieldName]);
    } else {
        if(bLimpa === true) {
            if(allValues.hasOwnProperty(fieldName) === false) {
                await onChange(undefined);
            }
        }
    }
}

const fireOnChange = async (changedFields, fieldName, onChange) => {

    changedFields.filter(field => {
        return (field.name.filter(name => name === fieldName).isEmpty()===false);
    }).forEach(field => {
        onChange(field.value);
    });

}

const fireOnChangeFFR = async (changedFields, ffrName, fieldName, onChange) => {

    changedFields.filter(field => {
        return (field.name.filter(name => name.startsWith(ffrName + "_") && name.endsWith("_" + fieldName)).isEmpty()===false);
    }).forEach(field => {
        onChange(field.value);
    });

}

const useEventListener = (eventName, handler, element = window) => {

    const savedHandler = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const eventListener = (event) => savedHandler.current(event);
        element.addEventListener(eventName, eventListener);
        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);

};

const lazyRetry = (fn, pRetriesLeft = 10, pInterval = 1000 * 5, cdTransacao = 0 , errorId= "??.??") => {
    const retriesLeft = pRetriesLeft || 10;
    const interval = pInterval || 1000 * 5;
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        reject(error);
                        return;
                    }
                    console.log("lazyRetry...", retriesLeft, cdTransacao);
                    notificacao.erro({transacao: cdTransacao, message:"Atenção !", description: "Você esta enfrentando problemas de conectividade de rede. Nova tentativa automática em alguns instantes. Caso o problema persista, tente novamente mais tarde.", errorId: errorId});
                    lazyRetry(fn, retriesLeft - 1, 1000 * (11 - retriesLeft + 5), cdTransacao, errorId).then(resolve, reject);
                }, interval);
            });
    });
}

const preventInjection = (object) => {

    if(object === null) return null;
    if(object === undefined) return undefined;

    const resultado = { };

    for(const key in object) {

        let value = object[key];

        if(typeof(value) === 'string') {
            value = value.replaceAll("'", "''");
        }

        resultado[key] = value;

    }

    return resultado;
}

export const ProjetoContext = React.createContext(null);

export {
    useQueryString,
    useInterval,
    useWindowSize,
    getTableHeight,
    useStateRef,
    useStateKeepRef,
    useGridWidth,
    notificacaoErroValidForm,
    transformData,
    inputPreventPaste,
    convertBlobToBase64,
    trackUnauthorized,
    useModoExecucao,
    useConteudoAlterado,
    notificacaoErroRequest,
    rulesCreator,
    ruleRegExpCreator,
    fireOnChange,
    fireOnChangeFFR,
    fireOnValueChange,
    useEventListener,
    lazyRetry,
    preventInjection,
    PADRAO_MARGEM_POPUP
}

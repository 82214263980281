//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../../assets/css/tema.scss';
import "./style.scss"

import {ProFormDateRangePicker} from "@ant-design/pro-components";
import React, {useState, useEffect} from "react";

import moment from "moment";
import {campoInformado} from "../../../lib/iy2b-javascript";
import 'moment/locale/pt-br';
import ptBRIntl from "antd/es/locale/pt_BR";
import {CUSTOM} from "../../../assets/iconografia";

const OPCOES_FIXAS = [
    {
        id: "0", label: "Semana atual",
        dtInicio: function (hoje) {
            if (hoje.getDay() === 0) {
                return hoje;
            } else {
                return hoje.last().sunday();
            }
        },
        dtFim: function (hoje) {
            return hoje.saturday();
        }
    },
    {
        id: "1", label: "Mês atual",
        dtInicio: function (hoje) {
            return hoje.firstDay();
        },
        dtFim: function (hoje) {
            return hoje.lastDay();
        }
    },
    {
        id: "2", label: "Semana passada",
        dtInicio: function (hoje) {
            return hoje.last().week().sunday();
        },
        dtFim: function (hoje) {
            return hoje.last().saturday();
        }
    },
    {
        id: "3", label: "Mês passado",
        dtInicio: function (hoje) {
            return hoje.last().month().firstDay();
        },
        dtFim: function (hoje) {
            return hoje.last().month().lastDay();
        }
    },
    {
        id: "4", label: "Próxima semana",
        dtInicio: function (hoje) {
            return hoje.next().sunday();
        },
        dtFim: function (hoje) {
            return hoje.next().week().saturday();
        }
    },
    {
        id: "5", label: "Próximo mês",
        dtInicio: function (hoje) {
            return hoje.next().month().firstDay();
        },
        dtFim: function (hoje) {
            return hoje.next().month().lastDay();
        }
    },
    {
        id: "6", label: "Mês até a data",
        dtInicio: function (hoje) {
            return hoje.firstDay();
        },
        dtFim: function (hoje) {
            return hoje;
        }
    },
    {
        id: "7", label: "Data até o final do mês",
        dtInicio: function (hoje) {
            return hoje;
        },
        dtFim: function (hoje) {
            return hoje.lastDay();
        }
    },
    {
        id: "8", label: "Últimos 30 dias",
        dtInicio: function (hoje) {
            return hoje.subtrai(30, "d");
        },
        dtFim: function (hoje) {
            return hoje;
        }
    },
    {
        id: "9", label: "Últimos 60 dias",
        dtInicio: function (hoje) {
            return hoje.subtrai(60, "d");
        },
        dtFim: function (hoje) {
            return hoje;
        }
    },
    {
        id: "10", label: "Últimos 90 dias",
        dtInicio: function (hoje) {
            return hoje.subtrai(90, "d");
        },
        dtFim: function (hoje) {
            return hoje;
        }
    },
    {
        id: "11", label: "Próximos 30 dias",
        dtInicio: function (hoje) {
            return hoje;
        },
        dtFim: function (hoje) {
            return hoje.adiciona(30, "d");
        }
    },
    {
        id: "12", label: "+ ou - 7 dias",
        dtInicio: function (hoje) {
            return hoje.subtrai(7, "d");
        },
        dtFim: function (hoje) {
            return hoje.adiciona(7, "d");
        }
    },
    {
        id: "13", label: "+ ou - 15 dias",
        dtInicio: function (hoje) {
            return hoje.subtrai(15, "d");
        },
        dtFim: function (hoje) {
            return hoje.adiciona(15, "d");
        }
    }
];

const IY2BDateRangePicker = (props) => {

    const {
        name,
        label,
        tooltip,
        placeholder = ['De', 'Até'],
        formRef = null,
        width = "md",
        format = 'DD/MM/YYYY',
        rules = [],
        disabled = false
    } = props;

    const [opcoesDisponiveis] = useState(OPCOES_FIXAS.clone());

    const className = ["box-iy2b-date-range-picker", "pro-field", "pro-field-" + width];

    // const presetedRanges = { };
    const presetedRanges = [];

    opcoesDisponiveis.forEach(opcao => {
        const dtInic = opcao.dtInicio(Date.today());
        const dtFim = opcao.dtFim(Date.today());
        // presetedRanges[opcao.label] = [moment(dtInic), moment(dtFim)];
        presetedRanges.push({
            label: opcao.label,
            value: [moment(dtInic), moment(dtFim)]
        });
    });

    useEffect(() => {

        if (props.api) {
            props.api.current = {
                getRange: (values) => {
                    const value = values[name];
                    if (campoInformado(value) === true) {
                        return [value[0].format("YYYY/MM/DD"), value[1].format("YYYY/MM/DD")]
                    } else {
                        return undefined
                    }
                },
                setRange: (value) => {
                    const newValue = {};
                    newValue[name] = value;
                    console.log(newValue)
                    formRef?.current?.setFieldsValue(newValue);
                }
            }
        }

        return () => {

        };

    }, []);

    const executaAcao = ({key}) => {

        const newValue = {};

        newValue[name] = null;

        switch (key) {
            case "limpar":
                formRef?.current?.setFieldsValue(newValue);
                return false;
            default:
                const opcao = opcoesDisponiveis.getBy("id", key);
                newValue[name] = [opcao.dtInicio(Date.today()), opcao.dtFim(Date.today())];
                formRef?.current?.setFieldsValue(newValue);
                return true;
        }

    };

    // console.log("IY2BDateRangePicker", name, disabled)

    return (
        <ProFormDateRangePicker locale={ptBRIntl}
                                name={name}
                                width={width}
                                label={label}
                                tooltip={tooltip}
                                format={format}
                                rules={rules}
                                disabled={disabled}
                                placeholder={placeholder}
                                fieldProps={{
                                    presets: presetedRanges,
                                    locale: ptBRIntl,
                                    placement: "topLeft"
                                }}
        />
    );

}

export default IY2BDateRangePicker;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../../assets/css/tema.scss';
import "./style.scss";

import {ProForm, ProFormText} from "@ant-design/pro-components";
import React, {useEffect, useState} from "react";


const IY2BProFormText = ( props ) => {

    const { disabled=false, ffrDisabled=undefined } = props;

    const [localDisabled, setLocalDisabled] = useState(disabled);

    useEffect(() => {

        if(props.hasOwnProperty("ffrDisabled")===false) {
            setLocalDisabled(disabled);
        } else {
            if(ffrDisabled === undefined) {
                setLocalDisabled(disabled);
            }
        }

        return () => {

        };

    }, [disabled]);

    if(props.hasOwnProperty("ffrDisabled")===true) {
        if(ffrDisabled != undefined) {
            const ffrDisabledValue = ffrDisabled();
            if(ffrDisabledValue !== localDisabled) setLocalDisabled(ffrDisabledValue);
        }
    }


    return (
        <ProFormText
            {...props}
            disabled={localDisabled}
        />
    );

}

export default IY2BProFormText;

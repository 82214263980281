//v5 import "antd/dist/antd.css";
import '../../../assets/css/tema.scss';
//v5 import '@ant-design/pro-form/dist/form.css';
import './style.scss';

import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {ProForm} from '@ant-design/pro-components' ;
import {InputNumber} from "antd";
import {campoInformado} from "../../../lib/iy2b-javascript";
import {CloseCircleFilled, SwapRightOutlined} from "@ant-design/icons";

const IY2BProFormRangeDigit = React.forwardRef((props, ref) => {

    const { digits = 6 } = props;

    const { width = "md", formRef, name, label, tooltip, rules = [], min=null, max=((10**digits)-1) } = props;

    const required = rules?.filter(item => item.required === true).length > 0 ;

    const classNameDe = ["iy2b-range-digit-de", "iy2b-pro-field", "pro-field", "pro-field-xs"];

    const classNameAte = ["iy2b-range-digit-ate", "iy2b-pro-field", "pro-field", "pro-field-xs"];

    const [classNameClear, setClassNameClear]  = useState(["icon-clear", "hidden"]);

    const nomeDe = name + "_De";
    const nomeAte = name + "_Ate";

    useImperativeHandle(ref, () => {
        return {
            getRange: () => {
                const values = formRef.current?.getFieldsValue();
                if(campoInformado(values[nomeDe]) && campoInformado(values[nomeAte])) {
                    return [values[nomeDe], values[nomeAte]]
                } else {
                    return undefined
                }
            },
            getRangeAsString: () => {
                const values = formRef.current?.getFieldsValue();
                if(campoInformado(values[nomeDe]) && campoInformado(values[nomeAte])) {
                    return [values[nomeDe] + "", values[nomeAte] + ""]
                } else {
                    return undefined
                }
            }
        };
    });

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                getRange: (values) => {
                    if(campoInformado(values[nomeDe]) && campoInformado(values[nomeAte])) {
                        return [values[nomeDe], values[nomeAte]]
                    } else {
                        return undefined
                    }
                }
            }
        }

        return () => {

        };

    }, []);


    const showIconClear = () => {

        const classNameClear = [];

        classNameClear.clear();
        classNameClear.push("icon-clear");

        const all = formRef?.current?.getFieldsValue();

        if(campoInformado(all[nomeDe])) {
            if(campoInformado(all[nomeAte]) === false) {
                classNameClear.push("hidden");
                setClassNameClear(classNameClear);
                return;
            }
        } else {
            if(campoInformado(all[nomeAte])) {
                classNameClear.push("hidden");
                setClassNameClear(classNameClear);
                return;
            }
        }

        if(all[nomeDe] > all[nomeAte]) {
            classNameClear.push("hidden");
            setClassNameClear(classNameClear);
            return;
        }

        if(campoInformado(all[nomeDe])) {
            if (campoInformado(all[nomeAte])) {
                classNameClear.push("visible");
                setClassNameClear(classNameClear);
                return;
            }
        }

        classNameClear.push("hidden");
        setClassNameClear(classNameClear);

    }

    const ruleValidaIntervalo = {
        validator: async () => {

            const all = formRef.current?.getFieldsValue();

            if(campoInformado(all[nomeDe]) === true) {
                if(campoInformado(all[nomeAte]) === false) {
                    const newValue = { };
                    newValue[nomeAte] = all[nomeDe];
                    formRef.current?.setFieldsValue(newValue);
                    console.log("Intervalo numérico incompleto! Preencha o valor 'Até'")
                    throw new Error("Intervalo numérico incompleto! Preencha o valor 'Até'");
                }
            } else {
                /*
                if(campoInformado(all[nomeAte])) {
                    const newValue = { };
                    newValue[nomeDe] = all[nomeAte];
                    formRef.current?.setFieldsValue(newValue);
                    console.log("Intervalo numérico incompleto! Preencha o valor 'De'")
                    throw new Error("Intervalo numérico incompleto! Preencha o valor 'De'") ;
                }
                */
            }

            if(all[nomeDe] > all[nomeAte]) {
                console.log("Intervalo numérico inválido")
                throw new Error("Intervalo numérico inválido");
            }

            return Promise.resolve();

        }
    };

    const rulesAte = [ruleValidaIntervalo];

    const onChangeValue = (value) => {
        showIconClear();
    }

    const clearRange = (e) => {
        const newValue = { };
        newValue[nomeDe] = undefined;
        newValue[nomeAte] = undefined;
        formRef.current?.setFieldsValue(newValue);
        showIconClear();
    }

    return (
        <ProForm.Item className={["iy2b-range-digit", "iy2b-pro-field-" + width].toClassName()}
            label = { label }
            tooltip = { tooltip }
            required = { required }
        >
            <ProForm.Item noStyle
                       name = { nomeDe }
                       rules = { rules }
                       validateFirst = { true }
            >
                <InputNumber className={classNameDe.toClassName()}
                    type={"number"}
                    placeholder='De'
                    min={min}
                    max={max}
                    precision={0}
                    controls={false}
                    onChange={onChangeValue}
                />
            </ProForm.Item>
            <SwapRightOutlined />
            <ProForm.Item noStyle
                       name = { nomeAte }
                       rules = { rulesAte }
                       validateFirst = { true }
                       dependencies={[nomeDe]}
            >
                <InputNumber className={classNameAte.toClassName()}
                    type={"number"}
                    placeholder='Até'
                    min={min}
                    max={max}
                    precision={0}
                    controls={false}
                    onChange={onChangeValue}
                />
            </ProForm.Item>
            <span className={classNameClear.toClassName()}>
                <CloseCircleFilled onClick={clearRange}/>
            </span>
        </ProForm.Item>
    );

});

export default IY2BProFormRangeDigit;



//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-card/dist/card.css';
import '../../../../framework/assets/css/tema.scss';
import "./style.scss";

import {Collapse} from "antd";
import React, {useEffect} from "react";
import {useStateRef} from "../../../lib/iy2b-react";
import {CUSTOM} from "../../../assets/iconografia";

const { Panel } = Collapse;

const caretUp = CUSTOM.ANGLE_UP_ICON;
const caretDown = CUSTOM.ANGLE_DOWN_ICON;

const FoldedFormField = ( props ) => {

    const { children, hidden = false, label = "Folded Form Field", forceRender=true, onStateChange, alwaysOpen=false, fullWidth=false } = props;

    const { gapBefore=false, gapAfter=false, icon=null, style={} } = props;

    const [inputChilds, setInputChilds, refInputChilds] = useStateRef([]);

    const [activeKey, setActiveKey, refActiveKey] = useStateRef([]);

    const [lHidden, setLHidden, refLHidden] = useStateRef (false);

    const myStyle = {
      ...style
    };

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                open: () => setActiveKey(['1']),
                close: () => setActiveKey([]),
                isOpen: () => (refActiveKey?.current?.isEmpty() === false),
                openOnErrors: openOnErrors
            }
        };

        return () => {

        };

    }, []);

    useEffect(() => {

        setLHidden(props.hidden);

        return () => {

        };

    }, [props.hidden]);

    useEffect(() => {

        if(props.alwaysOpen === true) {
            setActiveKey(['1']);
        } else {
            setActiveKey([]);
        }

        return () => {

        };

    }, [props.alwaysOpen]);

    const filterChildren = (item) => {

        const resultado = [];

        if(item.hasOwnProperty("props")) {

            const { props } = item;

            if(props.hasOwnProperty("children")) {

                const { children } = props;

                children.forEach(child => {

                    const childs = filterChildren(child);

                    resultado.addFrom(childs);

                });


            } else {

                resultado.push ( props );

            }

        }

        return resultado;

    }

    const openOnErrors = (errorFields) => {

        if(refLHidden.current === true) return false;

        if(refInputChilds.current.isEmpty() === true) {

            const childs = [ ];

            children.forEach(child => {

                const cs = filterChildren ( child );

                childs.addFrom(cs);

            });

            setInputChilds(childs);

        }

        let abre = false;

        errorFields.forEach(errorF => {

            const errName = errorF.name[0];

            const temErro = refInputChilds.current.filter(item => item.name === errName).isEmpty()===false ;

            if(temErro) abre = true;

        });

        if(abre === true) {

            setActiveKey(['1']);

        }

        return true;

    }

    const onChange = (value) => {
        setActiveKey(value);
        if(onStateChange !== undefined) {
            onStateChange (value.isEmpty()===false);
        }
    }

    const boxClassName = ["box-folded-form-field"];

    if(hidden) {
        boxClassName.push("hidden");
    }

    if(fullWidth) {
        boxClassName.push("full-width");
    }

    if(gapBefore) {
        boxClassName.push("gap-before");
    }

    if(gapAfter) {
        boxClassName.push("gap-after");
    }

    /*
    < 5.6
    return (
        <div className={boxClassName.toClassName()}>
            <Collapse
                expandIconPosition={"end"}
                expandIcon={({ isActive }) => isActive ? caretUp : caretDown}
                activeKey={refActiveKey.current}
                onChange={onChange}
            >
                <Panel header={label} key="1" forceRender={forceRender}>
                    {children}
                </Panel>
            </Collapse>
        </div>
    );
     */

    const myItem = {
        key: "1",
        label: label,
        forceRender: forceRender,
        children: children
    };

    if(icon!==null) {
        myItem.extra = icon;
    }

    const items=[myItem];

    return (
        <div className={boxClassName.toClassName()} style={myStyle}>
            <Collapse
                expandIconPosition={"end"}
                expandIcon={({ isActive }) => isActive ? caretUp : caretDown}
                activeKey={refActiveKey.current}
                onChange={onChange}
                items={items}
            >
            </Collapse>
        </div>
    );

}

export default FoldedFormField;

//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";

import {notificacaoErroRequest, useStateRef} from "../../lib/iy2b-react";
import React, {useEffect, useRef, useState} from "react";
import FoldedFormField from "../proform/FoldedFormField/foldedFormField";
import {ProForm} from "@ant-design/pro-components";
import IY2BTable from "../IY2BTable";
import BotaoEditarRegistroTabela from "../BotaoEditarRegistroTabela/botaoEditarRegistroTabela";
import BotaoExcluirRegistroTabela from "../BotaoExcluirRegistroTabela/botaoExcluirRegistroTabela";
import {Button, notification, Space, Tooltip} from "antd";
import {postJSON} from "../../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";

import EditarEndereco from "./editarEndereco";
import {campoInformado, formataNoRuaDoEndereco} from "../../lib/iy2b-javascript";
import moment from "moment";
import {CUSTOM, PLUS_CIRCLE_ICON_PRIMARY, PLUS_ICON_PRIMARY} from "../../assets/iconografia";
import {notificacao} from "../../lib/Notificacao";
import {pesquisarCidadeDoCep} from "../../lib/Servicos";

const iconPlusCircle = PLUS_ICON_PRIMARY;

const FoldedEnderecoPessoa = ( props ) => {

    const { cdPessoa, noPessoa, nrCGCCIC, label = "Endereços", hidden = false, cdTransacao = 0 } = props;

    const tableEnderecoRef = useRef();
    const [dsEndereco, setDsEndereco, refDsEndereco] = useStateRef( [] );

    const [exibeNovoRegistro, setExibeNovoRegistro] = useState(false);
    const [registrosAEditar, setRegistrosAEditar] = useState([]);

    const [dataProvider, setDataProvider] = useState({ });

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                clear: () => {
                    setDsEndereco ( [] );
                },
                salvarNovoEndereco: salvarNovoEndereco,
                novoEndereco: (endereco) => {
                    if(endereco !== null && endereco !== undefined) {
                        preparaNovoEnderecoComDefault(endereco);
                    } else {
                        novoEndereco();
                    }
                }
            }
        };

        inicializaProjeto ();

        return () => {

        };

    }, []);

    const inicializaProjeto = async () => {

        const payload = {
            "acaoAExecutar": "inicializaProjeto",
        }

        try {

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sgr",
                projeto: "mendereco"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            const dataProvider = {
            };

            resultado.mensagens.forEach(msg => {

                if(msg.id === "listaRegiao") {

                    dataProvider.dpRegiao = msg.lista.map(item => {
                        return {
                            value: item.cdRegiao,
                            label: item.noRegiao,
                            data: {...item}
                        }
                    });

                } else if (msg.id === "listaTipoCalcImposto") {

                    dataProvider.dpTipoCalcImposto = msg.lista.map(item => {
                        return {
                            value: item.cdTipoCalcImposto,
                            label: item.noTipoCalcImposto,
                            data: {...item}
                        }
                    });

                } else if (msg.id === "mapaParametros") {

                    dataProvider.mapaParametros = msg.mapa;

                } else {

                    console.log(msg);

                }

            });

            dataProvider.regiaoDefault = dataProvider.dpRegiao.filter(item => item.value === 0)[0]

            dataProvider.tipoCalcImpostoDefault = dataProvider.dpTipoCalcImposto.filter(item => item.value === 0)[0]

            setDataProvider(dataProvider);

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

        return true;

    };

    const pesquisarEnderecos = async () => {

        try {

            const payload = {
                acaoAExecutar: "pesquisa",
                filtro: {"hidCdPessoa": cdPessoa}
            }

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sgr",
                projeto: "mendereco"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "listaEndereco") {

                    setDsEndereco(msg.lista.map(item => {
                        return {
                            key: item.nrSeqEnd,
                            ... item
                        }
                    }));

                } else {

                    console.log(msg);

                }

            });

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const salvar = async (registro) => {

        try {

            const acaoWebAExecutar = campoInformado(registro.nrSeqEnd)?"alterar":"incluir";

            const payload = {
                acaoAExecutar: "acaoCRUD",
                registro: {
                    ... registro,
                    cdPessoa: cdPessoa
                }
            }

            console.log(payload);

            const resultado = await postJSON({
                acaoWebAExecutar: acaoWebAExecutar,
                sistema: "sgr",
                projeto: "mendereco"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    const modelo = msg.modeloPadrao;

                    modelo.key = modelo.nrSeqEnd;

                    const dsItem = refDsEndereco.current.getBy("key", modelo.key);

                    if(dsItem === null) {

                        refDsEndereco.current.push(modelo);

                        setDsEndereco ( refDsEndereco.current.clone() );

                    } else {

                        dsItem.tpEndereco = modelo.tpEndereco;
                        dsItem.noRua = modelo.noRua;
                        dsItem.nrTelefone = modelo.nrTelefone;
                        dsItem.noCidade = modelo.noCidade;
                        dsItem.ufCidade = modelo.ufCidade;

                    }

                } else {

                    console.log(msg);

                }

            });

            notificacao.sucesso({transacao:cdTransacao, message:"Sucesso!", description:"Endereço salvo com sucesso!"})

            return true;

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const folded_OnStateChange = ( opened ) => {

        if(opened === true) {
            if(refDsEndereco.current.isEmpty() === true) {
                pesquisarEnderecos();
            }
        }

    }

    const salvarNovoEndereco = async (endereco) => {

        console.log("salvarNovoEndereco", endereco);

        const novoRegistro = {
            cdPessoa: endereco.cdPessoa,
            nrSeqEnd: null,
            nrCep: endereco.cep.retiraCaracteres("-. "),
            noBairro: endereco.bairro?.trim().substr(0, 20),
            noLograd: endereco.logradouro?.trim().substr(0, 160),
            txCompl: endereco.complemento?.trim().substr(0, 30),
            txNumero: endereco.numero?.trim().substr(0, 20),
            noEmail: endereco.email,
            noEmailNF_E: endereco.email,
            nrTelefone: endereco.telefone.retiraCaracteres("-. () "),
            noCidade: endereco.municipio.trim(),
            ufCidade: endereco.uf,
            tpEndereco: "EFA",
            "dtCad": moment().format("YYYY/MM/DD"),
            "dtInicValid": moment().format("YYYY/MM/DD"),
            dtFimValid: "1901/01/01",
            cdRegiao: 0,
            cdTipoCalcImposto: 0,
            "flgZFranca":"N",
            "cdTribServ":"T",
            "cdRegTrib":"0",
            "cdTipoDoc":"01",
            nrInsEstEnd: null,
            nrInsMunicipEnd: null,
            noRua: null
        }

        novoRegistro.noRua = formataNoRuaDoEndereco(novoRegistro);

        const cidadeDoCep = await pesquisarCidadeDoCep(novoRegistro.nrCep, WORKSPACE_LOADING_INDICATOR_AREA, cdTransacao);

        novoRegistro.cdCidade = cidadeDoCep.cdCidade;

        try {
            const payload = {
                acaoAExecutar: "acaoCRUD",
                registro: novoRegistro
            }
            const resultado = await postJSON({
                acaoWebAExecutar: "incluir",
                sistema: "sgr",
                projeto: "mendereco"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);
            console.log("resultadoSalvar", resultado);
            resultado.mensagens.forEach(msg => {
                if(msg.id === "modelo") {
                    const modelo = msg.modeloPadrao;
                    modelo.key = modelo.nrSeqEnd;
                    refDsEndereco.current.push(modelo);
                    setDsEndereco ( refDsEndereco.current.clone() );
                } else {
                    console.log(msg);
                }
            });
            notificacao.sucesso({transacao:cdTransacao, message:"Sucesso!", description:"Endereço salvo com sucesso!"})
        } catch (e) {
            console.log(e);
            notificacaoErroRequest(e, cdTransacao);
            return false;
        }

        return true;

    }

    const preparaNovoEnderecoComDefault = (endereco) => {

        const regEnd = {
            nrSeqEnd: null,
            nrCep: endereco.cep.retiraCaracteres("-. "),
            noBairro: endereco.bairro?.trim().substr(0, 20),
            txCompl: endereco.complemento?.trim().substr(0, 30),
            noEmail: endereco.email,
            txNumero: endereco.numero?.trim().substr(0, 20),
            noLograd: endereco.logradouro?.trim().substr(0, 160),
            nrTelefone: endereco.telefone.retiraCaracteres("-. ()"),
            noCidade: endereco.municipio.trim(),
            ufCidade: endereco.uf,
            tpEndereco: "EFA",
            "dtCad": moment().format("YYYY/MM/DD"),
            "dtInicValid": moment().format("YYYY/MM/DD"),
            novoReg: true
        }

        setExibeNovoRegistro (true);

        setRegistrosAEditar ([regEnd]);

    }

    const novoEndereco = () => {

        setExibeNovoRegistro (true);

        setRegistrosAEditar ([]);

    };

    const editarEndereco = ( endereco ) => {

        setExibeNovoRegistro (true);

        setRegistrosAEditar ([ endereco ]);

    }

    const excluirEndereco = async ( enderecos ) => {

        console.log(enderecos);

        try {

            const payload = {
                acaoAExecutar: "acaoCRUD",
                lista: enderecos.map(item => {
                    return {
                        cdPessoa: cdPessoa,
                        nrSeqEnd: item.nrSeqEnd
                    }
                })
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "excluir",
                sistema: "sgr",
                projeto: "mendereco"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelosExcluidos") {

                    const novods = refDsEndereco.current.removeList("nrSeqEnd", msg.lista).clone();

                    setDsEndereco ( novods ) ;

                } else {

                    console.log(msg);

                }

            });

            setExibeNovoRegistro(false) ;

            notificacao.sucesso({transacao:cdTransacao, message:"Sucesso!", description:"Endereço excluído com sucesso!"})

            return true;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const actionViewEndereco = (_, record) => {

        const botoes = [];

        botoes.push(
            <BotaoEditarRegistroTabela
                key={"btnEditEnd" + record.key}
                tooltip={"Editar este endereco"}
                registro={record}
                editarRegistro={editarEndereco}
            />
        );

        botoes.push(<BotaoExcluirRegistroTabela
            key={"btnDeleteEnd" + record.key}
            registro={record}
            excluir={excluirEndereco}
            tooltip={"Excluir este endereco"}
        />);

        return (
            <Space size={4} align="baseline" className={"resultado-action-view"}>
                {botoes}
            </Space>
        );
    };

    const tblEnderecoCols = [{
        title: '',
        key: 'actionView',
        width: 80,
        align: 'center',
        render: actionViewEndereco
    },
        {noColuna:'Sequência', field:'nrSeqEnd', width:70, align:'text-right', colSpan:1, hidden:true },
        {noColuna:'Tipo', field:'tpEndereco', width:80, colSpan:1},
        {noColuna:'Endereço', field:'noRua', width:300, colSpan:1},
        {noColuna:'Telefone', field:'nrTelefone', width:100, colSpan:1},
        {noColuna:'Cidade', field:'noCidade', width:200, colSpan:1},
        {noColuna:'UF', field:'ufCidade', width:200, colSpan:1}
    ];

    const tableEnderecoActions = [
        <Tooltip title="Novo endereço" color={"black"} key={"novoEndereco"}>
            <Button
                type="text"
                onClick={() => {
                    novoEndereco();
                }}
                className="action-button"
                icon={iconPlusCircle}
            >
                <span>&nbsp;Novo endereço</span>
            </Button>
        </Tooltip>
    ];

    return (
        <div className={"box-folded-endereco-pessoa"}>
            <FoldedFormField label={label} hidden={hidden} onStateChange={folded_OnStateChange} icon={CUSTOM.LOCATION_DOT_ICON}>
                <ProForm.Group>
                    <IY2BTable id={"tblEndereco"} name={"tblEndereco"}
                               customActions={tableEnderecoActions}
                               enableRowCheck={false}
                               api={tableEnderecoRef}
                               dataSource={dsEndereco}
                               columns={tblEnderecoCols}
                               tableHeight={500}
                               downloadDisabled={true}
                               configDisabled={true}
                               paginationDisabled={true}
                               searchDisabled={true}
                    />
                </ProForm.Group>
            </FoldedFormField>
            <EditarEndereco cdTransacao={cdTransacao}
                exibePopUp={ exibeNovoRegistro }
                onRequestClose={() => {
                    setExibeNovoRegistro(false) ;
                }}
                registros={registrosAEditar}
                salvar={salvar}
                excluir={ async (registro) => await excluirEndereco([registro])}
                dataProvider={dataProvider}
                noPessoa={noPessoa}
                nrCGCCIC={nrCGCCIC}
            />
        </div>
    );

};


export default FoldedEnderecoPessoa ;



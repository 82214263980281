//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";

import {
    notificacaoErroRequest, PADRAO_MARGEM_POPUP,
    useConteudoAlterado,
    useGridWidth,
    useModoExecucao, useStateRef,
    useWindowSize
} from "../../lib/iy2b-react";
import React, {useEffect, useRef, useState} from "react";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {
    campoInformado,
    cnpjValido,
    cpfValido,
    ObjectUtils
} from "../../lib/iy2b-javascript";
import {postJSON} from "../../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";
import {Button, Col, Row, Space, Tree} from "antd";
import IY2BReactModal from "../IY2BReactModal";
import {ProForm, ProFormDatePicker, ProFormDigit, ProFormText} from "@ant-design/pro-components";
import IY2BHiddenRow from "../IY2BHiddenRow";
import IY2BProFormHidden from "../IY2BProFormHidden";
import {BotaoEditar} from "../TreeItemTitleEditor/botaoEditar";
import {BotaoExcluir} from "../TreeItemTitleEditor/botaoExcluir";
import NovoItemChecklist from "../FoldedChecklist/novoItemChecklist";
import AtualizarItemChecklist from "../FoldedChecklist/atualizarItemChecklist";
import BotaoToolTip from "../BotaoToolTip/botaoToolTip";
import {CHECK_ICON_PRIMARY} from "../../assets/iconografia";
import {notificacao} from "../../lib/Notificacao";

const iconDone = CHECK_ICON_PRIMARY;

const VerChecklist = (props ) => {

    const { exibePopUp, cdPessoaEmpAtual, onRequestClose, dataProvider, pessoa, registros, cdTransacao=0 } = props;

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 10 : windowSize.widthMargin(PADRAO_MARGEM_POPUP.LG);
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = windowSize.heightMargin(PADRAO_MARGEM_POPUP.LG);

    const [arvore, setArvore, refArvore] = useStateRef( []);
    const [tableHeight, setTableHeight] = useState(0);

    const modalRefApi = useRef();
    const formRef = useRef();

    const forceUpdate = useForceUpdate();

    const modoExecucao = useModoExecucao("ALT");
    const conteudoAlterado = useConteudoAlterado();

    const [exibeNovoRegistro, setExibeNovoRegistro] = useState(false);
    const [exibeAtualizarRegistro, setExibeAtualizarRegistro] = useState(false);
    const [registrosAEditar, setRegistrosAEditar] = useState([]);

    const calcTableHeight = () => {

        const bodyCheckPessoa = document.getElementsByClassName("body-checklist-pessoa");

        const formGroup = bodyCheckPessoa[0].getElementsByClassName("pro-form-group-spacer");

//        const elTree = bodyCheckPessoa[0].getElementsByClassName("ant-tree");

        const GAP = 20;

        const treeHeight = bodyCheckPessoa[0].clientHeight - formGroup[0].clientHeight - GAP;

        setTableHeight (treeHeight);

    };

    useEffect(() => {

        if(campoInformado(registros)) {

            if(exibePopUp) {

                if(registros.length > 0) {

                    setTimeout(()=> {

                        calcTableHeight ();

                    }, 0);

                    pesquisarPk(registros[0]);

                }

            }

        }

        return () => {

        };

    }, [props.registros]);

    const pesquisarPk = async (registro) => {

        try {

            const payload = {
                acaoAExecutar: "completaInicializa",
                cdChecklist: registro.cdChecklist,
            };

            const resultado = await postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "sgr",
                projeto: "marvchecklist"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            const checklist = {

            };

            resultado.mensagens.forEach(msg => {

                if(msg.id === "pkChecklist") {

                    checklist.checklist = {
                        ...msg.modeloPadrao
                    };

                } else if (msg.id === "listaItemChecklist") {

                    checklist.itens = msg.lista.clone();

                } else if (msg.id === "arvore") {

                    checklist.arvore = msg.arvore.clone();

                    const visitaItem = (item) => {

                        item.key = item.nrSeq;

                        if(item.childs === null || item.childs === undefined || item.childs.length === 0) {

                            item.isLeaf = true;

                        } else {

                            item.isLeaf = false;

                            item.children = [];

                            item.childs.forEach(child => {

                                item.children.push(visitaItem(child));

                            });

                            item.childs = undefined;

                        }

                        return item;

                    };

                    checklist.arvore.forEach(function(item) {

                        visitaItem( item );

                    });

                }

            });

            setRegistroNaTela(checklist);

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const setRegistroNaTela = async (registro) => {

        console.log(registro);

        const attrDaTela = [
            "cdChecklist",
            "noChecklist"
        ];

        const registroDaTela = {
            ... ObjectUtils.valuesFrom(registro.checklist, attrDaTela),
            dtConclusao: registro.checklist.dtConclusao?.toMoment().toScreen(),
        };

        console.log(registroDaTela);

        formRef.current?.setFieldsValue( registroDaTela );

        setArvore(registro.arvore);

        forceUpdate();

        controleExibicao ( registroDaTela );

        modoExecucao.setModoExecAlteracao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const controleExibicao = (registro) => {
        if(campoInformado(registro)) {
        }
    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();


        }, 1);

    }

    const validaTela = async (params) => {

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        return true;

    };

    const novoRegistro = () => {

        setExibeNovoRegistro (true);
        setExibeAtualizarRegistro ( false );

    }

    const atualizarRegistro = ( registro ) => {

        setExibeAtualizarRegistro ( true );
        setExibeNovoRegistro (false);

        setRegistrosAEditar([registro]);

    }

    const concluirItem = async () => {

        const registro = registrosAEditar[0];

        try {

            const payload = {
                acaoAExecutar: "concluiExecucao",
                cdPessoaEmp: registro.cdPessoaEmp,
                cdChecklist: registro.cdChecklist,
                nrSeq: registro.nrSeq
            };

            let refreshTree = false;

            const resultado = await postJSON({
                acaoWebAExecutar: "processarnegocio",
                sistema: "sgr",
                projeto: "matuitemchecklist"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    refreshTree = true;

                }

            });

            if(refreshTree === true) {
                await pesquisarPk(registro);
                setExibeNovoRegistro (false);
                setExibeAtualizarRegistro ( false );
            }

            return true;

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const salvar = async (registro) => {

        try {

            const acaoWebAExecutar = campoInformado(registro.nrSeq)?"alterar":"incluir";
            const projeto = campoInformado(registro.nrSeq)?"matuitemchecklist":"mnovoitemchecklist";

            const payload = {
                acaoAExecutar: "acaoCRUD",
                registro: registro
            };

            let refreshTree = false;

            const resultado = await postJSON({
                acaoWebAExecutar: acaoWebAExecutar,
                sistema: "sgr",
                projeto: projeto
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelo") {

                    refreshTree = true;

                }

            });

            if(refreshTree === true) {
                await pesquisarPk(registro);
            }

            notificacao.sucesso({message:"Sucesso!", description:"Item do checklist salvo com sucesso!"})

            return true;

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const excluir = async (registros) => {

        try {

            console.log(registros);

            const payload = {
                acaoAExecutar: "acaoCRUDItem",
                lista: registros.map(item => {
                    const {cdPessoaEmp, cdChecklist, nrSeq} = item;
                    return {
                        cdPessoaEmp, cdChecklist, nrSeq
                    }
                })
            };

            let refreshTree = false;

            const resultado = await postJSON({
                acaoWebAExecutar: "excluir",
                sistema: "sgr",
                projeto: "marvchecklist"
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resultado.mensagens.forEach(msg => {

                if(msg.id === "modelosExcluidos") {

                    refreshTree = true;

                } else {

                    console.log(msg);

                }

            });

            if(refreshTree === true) {
                await pesquisarPk(registros[0]);
            }

            setExibeNovoRegistro(false) ;
            setExibeAtualizarRegistro ( false );

            notificacao.sucesso({message:"Sucesso!", description:"Item do checklist excluído com sucesso!"})

            return true;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    };

    const treeTitleRender = (record) => {

        const botoes = [];

        const {nrSeq, noItem, dtChecklist, isLeaf } = record;

        if(isLeaf === true) {

            botoes.push(
                <BotaoEditar
                    key={"btnEdit-Checklist-" + nrSeq}
                    editarRegistro={async () => {
                        atualizarRegistro ( record );
                    }}
                />
            );

            if(dtChecklist === "") {
                botoes.push(<BotaoExcluir
                    key={"btnDelete-Checklist-" + nrSeq}
                    excluir={async () => {
                        await excluir([record]);
                    }}
                    msgConfirmacao={"Confirma a exclusão de '" + noItem + "' ?"}
                />);
            } else {
                botoes.push(<BotaoToolTip
                    tooltip={"Item concluido em " + dtChecklist.toMoment().format("DD/MM/YYYY")}
                    icon={iconDone}
                    key={"btnConcluido-Checklist-" + nrSeq}
                    onClick={() => {
                        atualizarRegistro ( record );
                    }}
                />);
            }

        }

        return <div>
            <Space size={4} align="start">
                {botoes}
            </Space>
            <>
                {noItem}
            </>
        </div>
        ;
    }

    const onLoadTreeData = async (params) => {

        console.log(params);

        const { data, key, children } = params;

        try {

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);
        }

    }

    const nrCGCCICPatterns = [{
        pattern: "000.000.000-00",
        length: 11, // sem mascara
        validator: async (value) => {
            if(value.length === 11) {
                if(cpfValido(value) === false) {
                    return {valido:false, message:"CPF informado é inválido !"};
                }
            }
            return {valido: true, message: "ok" };
        }
    }, {
        pattern: "00.000.000/0000-00",
        length: 14, // sem mascara
        validator: async (value) => {
            if(value.length === 14) {
                if(cnpjValido(value) === false) {
                    return {valido:false, message:"CNPJ informado é inválido !"};
                }
            }
            return {valido: true, message: "ok" };
        }
    }];

    const popupTitle = "Visualizar checklist";

    const ffValues = formRef?.current?.getFieldsValue();

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={windowSize.heightMargin(PADRAO_MARGEM_POPUP.LG)}
            exibePopUp={exibePopUp}
            onRequestClose={onRequestClose}
            bodyOpenClassName={"editar-checklist-pessoa"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-checklist-pessoa"}>
                    <ProForm
                        formRef = { formRef }
                        isKeyPressSubmit = {true}
                        initialValues={{
                            nrCGCCIC: pessoa.nrCGCCIC,
                            noPessoa: pessoa.noPessoa,
                        }}
                        onFinish = { async ( ) => {
                        }}
                        submitter={{
                            // Completely customize the entire area
                            render : ( props , doms ) => {
                                return null;
                            }
                        }}
                        onValuesChange={async (changeValues, allValues) => {

                        }}
                        onFieldsChange = {(changedFields, allFields) => {
                            conteudoAlterado.updateConteudoAlterado(changedFields);
                        }}
                    >
                        <div className={"pro-form-group-spacer"}>
                            <ProForm.Group>
                                <Row gutter={[8, 16]}>
                                    <Col xs={24} md={24} lg={10}>
                                        <ProFormText
                                            width={"xl"}
                                            name = "noPessoa"
                                            label = "Razão social / Nome"
                                            tooltip = "A razão social ou nome da pessoa"
                                            placeholder = "Digite parte da razão social ou apelido"
                                            disabled
                                        />
                                    </Col>
                                    <Col xs={24} md={14} lg={8}>
                                        <ProFormText
                                            width={"lg"}
                                            name = "noChecklist"
                                            label = "Nome do checklist"
                                            tooltip = "Nome do checklist"
                                            placeholder = "Digite parte da razão social ou apelido"
                                            disabled
                                        />
                                    </Col>
                                    <Col xs={24} md={10} lg={6}>
                                        <ProFormDatePicker
                                            width={"md"}
                                            name="dtConclusao"
                                            label="Concluído em"
                                            tooltip={"Data de conclusão deste checklist"}
                                            placeholder={"Ainda não foi concluído"}
                                            format={"DD/MM/YYYY"}
                                            showToday={true}
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </ProForm.Group>
                            <IY2BHiddenRow>
                                <IY2BProFormHidden name={"cdChecklist"}/>
                            </IY2BHiddenRow>
                        </div>
                        <Tree
                            treeData={arvore}
                            height={tableHeight}
                            showLine={{
                                showLeafIcon: false
                            }}
                            checkable={false}
                            selectable={false}
                            titleRender={treeTitleRender}
                        />
                        <NovoItemChecklist cdTransacao = {cdTransacao}
                            exibePopUp={ exibeNovoRegistro }
                            onRequestClose={() => {
                                setExibeNovoRegistro(false) ;
                                setExibeAtualizarRegistro(false) ;
                            }}
                            cdPessoaEmpAtual={ dataProvider.cdPessoaEmpAtual }
                            cdChecklist = { ffValues?.cdChecklist }
                            salvar={salvar}
                            pessoa={pessoa}
                        />
                        <AtualizarItemChecklist cdTransacao = {cdTransacao}
                            exibePopUp={ exibeAtualizarRegistro }
                            onRequestClose={() => {
                                setExibeAtualizarRegistro(false) ;
                                setExibeNovoRegistro(false) ;
                            }}
                            cdPessoaEmpAtual={ dataProvider.cdPessoaEmpAtual }
                            cdChecklist = { ffValues?.cdChecklist }
                            salvar={salvar}
                            pessoa={pessoa}
                            registros={registrosAEditar}
                            concluirItem={concluirItem}
                            excluir={excluir}
                        />
                    </ProForm>
                </div>
            }
            actions={
                <Space style={{
                    width: "100%",
                    justifyContent: "space-between"
                }}>
                    <Space>
                        &nbsp;
                    </Space>
                    <Space>
                        &nbsp;
                        <Button type={"default"} className={"salvar"} onClick={() => novoRegistro()}>
                            Novo item
                        </Button>
                    </Space>
                </Space>
            }
        />
    );

}

export default VerChecklist;

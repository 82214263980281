//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-card/dist/card.css';
import '../../framework/assets/css/tema.scss';
import "./style.scss";

import React, {useState} from 'react';

import {Button, Col, Row, Tabs} from "antd";

import {currentBrowser} from "../../framework/lib/iy2b-javascript";
import PopUpMaisInfoPlano from "./PopUpMaisInfoPlano";

const StepPlanos = props => {

    const [urlMaisInfo, setUrlMaisInfo] = useState(null);
    const [exibeMaisInfo, setExibeMaisInfo] = useState(false);
    const [planoMaisInfo, setPlanoMaisInfo] = useState(null);

    const [planoSelecionado, setPlanoSelecionado] = useState(null);

    const showDetPlano = (plano) => {

        /*
        if(campoInformado(plano.txUrlHomeProduct) === false) return;

        console.log(plano.txUrlHomeProduct);

        const agora = (new Date()).getTime();

        const url = plano.txUrlHomeProduct + "?&dt=" + agora

        window.open(url,'_blank');

        setUrlMaisInfo(url);
         */

        setExibeMaisInfo(true);
        setPlanoMaisInfo(plano);

    }

    const btnSelecionaPlano = (plano) => {

        setPlanoSelecionado(plano);

        props.onSelecionaPlano(plano);

    }

    const dsGratis = props.dataSource.filter(plano => plano.flgTipoPessoa === "G");
    const dsMEI = props.dataSource.filter(plano => plano.flgTipoPessoa === "M");
    const dsSimples = props.dataSource.filter(plano => plano.flgTipoPessoa === "S");
    const dsLucro = props.dataSource.filter(plano => plano.flgTipoPessoa === "L");
    const dsGEmp = props.dataSource.filter(plano => plano.flgTipoPessoa === "E");
    const dsBasic = props.dataSource.filter(plano => plano.flgTipoPessoa === "B");

    const buildGridPlano = (dataSource) => {

        let colSpanPlanos = {
            xs:{span:24},
            sm:{span:12},
            md:{span:10},
            lg:{span:8},
            xl:{span:6},
            xxl:{span:6}
        };

        switch (dataSource.length) {

            case 1:
                colSpanPlanos = {
                    xs:{span:24},
                    sm:{span:12},
                    md:{span:12},
                    lg:{span:8},
                    xl:{span:8},
                    xxl:{span:8}
                };
                break;

            case 2:
                colSpanPlanos = {
                    xs:{span:24},
                    sm:{span:12},
                    md:{span:12},
                    lg:{span:12},
                    xl:{span:12},
                    xxl:{span:12}
                };
                break;

            case 3:
                colSpanPlanos = {
                    xs:{span:24},
                    sm:{span:12},
                    md:{span:8},
                    lg:{span:8},
                    xl:{span:8},
                    xxl:{span:8}
                };
                break;

            default:
        };

        let span = (dataSource.length>1) ? 24 / dataSource.length : 24;

        if(currentBrowser.ISMOBILE === true) {
            span = 24;
        }

        const gridPlanos = dataSource.map(plano => {

            const statusClass = plano.selecionado ? "selecionado" : "";

            const botaoEscolha = plano.selecionado ? null : <Button type="primary" className="contrate" onClick={() => btnSelecionaPlano(plano)} block>
                <span>Escolha este plano</span>
            </Button> ;

            const botaoJaEscolhido = plano.selecionado ? <Button type="text" className="jaEscolhido"  block>
                <span>Plano selecionado</span>
            </Button> : null;

            const moeda = plano.formaPagto.indexOf("R$") > -1 ? "R$ ": "";

            return (
                <Col key={plano.id} span={span} >
                    <div className={["box-preco", statusClass].toClassName()}>
                        <div onClick={() => btnSelecionaPlano(plano)} className={"body-preco"}>
                            <label className="nome">{plano.noPlano}</label>
                            <label className="descricao">{plano.descrPlano}</label>
                            <label className="precoBase">{plano.precoBase}</label>
                            <label className="formaPagto"><span className={"periodicidade"}>{moeda}</span><span className={"valor"}>{plano.formaPagto.replace("R$", "")}</span><span className={"periodicidade"}>{plano.periodicidade}</span></label>
                        </div>
                        <div className="toolbar">
                            {botaoEscolha}
                            {botaoJaEscolhido}
                        </div>
                    </div>
                </Col>
            )

        });

        return gridPlanos;

    }

    const gridPlanoCompleto = buildGridPlano(props.dataSource);

    const gridPlanoGratis = buildGridPlano(dsGratis);
    const gridPlanoMEI = buildGridPlano(dsMEI);
    const gridPlanoSimples = buildGridPlano(dsSimples);
    const gridPlanoLucro = buildGridPlano(dsLucro);
    const gridPlanoGEmp = buildGridPlano(dsGEmp);
    const gridPlanoBasic = buildGridPlano(dsBasic);

    // flgtipopessoa	char(1) => G (free), M (mei/pf), S(simples), L(lucro)

    const tabItens = [{
        key: "G",
        label: "Trial",
        children:
            <Row gutter={[8, 16]} className={"row-box-planos"}>
                {gridPlanoGratis}
            </Row>
    }, {
        key: "B",
        label: "Iron",
        children:
            <Row gutter={[8, 16]} className={"row-box-planos"}>
                {gridPlanoBasic}
            </Row>
    }, {
        key: "M",
        label: "Bronze",
        children:
            <Row gutter={[8, 16]} className={"row-box-planos"}>
                {gridPlanoMEI}
            </Row>
    }, {
        key: "S",
        label: "Silver",
        children:
            <Row gutter={[8, 16]} className={"row-box-planos"}>
                {gridPlanoSimples}
            </Row>
    }, {
        key: "L",
        label: "Golden",
        children:
            <Row gutter={[8, 16]} className={"row-box-planos"}>
                {gridPlanoLucro}
            </Row>
    }, {
        key: "E",
        label: "Platinum",
        children:
            <Row gutter={[8, 16]} className={"row-box-planos"}>
                {gridPlanoGEmp}
            </Row>
    }];

    /*


     */

    return (
        <div className={"containerStepPlanos"}>
            <Row gutter={[8,16]} className={"grid-planos"} >
                <Col span={24}>
                    <Tabs tabPosition={"left"}
                          hideAdd={true}
                          items={tabItens}
                    >
                    </Tabs>
                </Col>
            </Row>
            <PopUpMaisInfoPlano
                exibePopUp={exibeMaisInfo}
                onRequestClose={() => setExibeMaisInfo(false)}
                plano={planoMaisInfo}
            />
        </div>
    );

};

export default StepPlanos;

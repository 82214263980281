//v5 import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
//v5 import '@ant-design/pro-form/dist/form.css';
//v5 import '@ant-design/pro-layout/dist/layout.css';
import './style.scss';

import React, {useState, useEffect, useRef, useContext} from 'react';
import {connect} from "react-redux";
import ProForm , {
    ProFormText ,
} from '@ant-design/pro-form' ;
import {  FooterToolbar  } from '@ant-design/pro-layout' ;

import {
    Row,
    Col,
    Button,
} from 'antd';
import AvatarComEdicao from "../../componentes/AvatarComEdicao";
import {convertBlobToBase64, notificacaoErroRequest, notificacaoErroValidForm} from "../../lib/iy2b-react";
import {alteraFotoUsuario} from "../../lib/LoginAPI";
import {atualizarInfoPerfilUsr, updateProfileImage} from "../../reducer/userSession";
import IY2BProFormMasked from "../../componentes/IY2BProFormMasked";
import {TransacaoContentContext} from "../../pages/TransacaoContent";
import {postJSON} from "../../lib/RequestAPI";
import PopUpTermosDeUso from "../../../pages/TermosDeUso/popUpTermosDeUso";
import {
    desvincularProvedorIdentidadeUsuario,
    pesquisarProvedorIdentidade,
    pesquisarProvedorIdentidadeUsuario,
    vincularProvedorIdentidadeUsuario
} from "../../lib/Servicos";
import {
    logoutProvedorIdentidade,
    provedoresIdentidadeDisponiveis,
    signInWithApple,
    signInWithGoogle, signInWithIdProvider
} from "../../lib/firebase";
import {campoInformado, urlAsBlob} from "../../lib/iy2b-javascript";
import BotaoProvedorIdentidade from "../../componentes/provedoresIdentidade/botaoProvedorIdentidade";
import {notificacao} from "../../lib/Notificacao";
import {WorkspaceContext} from "../../pages/Workspace/workspace";

const MeuPerfil = props => {

    const cdTransacao = 85061;

    const { availableWidth } = useContext(WorkspaceContext);

    const { scrollToTop } = useContext(TransacaoContentContext);

    const formRef = useRef() ;
    const inputRefTelCel = useRef();
    const [campoAlterado, setCampoAlterado] = useState(false);

    const [provedoresId, setProvedoresId] = useState([]);

    const {loadingArea} = props;

    const USUARIO_EH_A_EMPRESA = props.suite?.empresaAtual?.pessoaEmp?.cdPessoa === props.userSession?.cdPessoaUsr;

    useEffect(() => {

        formRef.current.setFieldsValue({
            noUsuario: props.userSession.noPessoa,
            noApelido: props.userSession.noApelido,
            noEmail: props.userSession.noEmail,
            nrTelCelular: props.userSession.nrTelCelular,
            noLogin: props.userSession.noLogin.toLowerCase()
        });

        inicializaProvId();

        return () => {

        };

    }, []);

    const inicializaProvId = async () => {

        const provedores = provedoresIdentidadeDisponiveis;

        const vinculados = await pesquisarProvedorIdentidadeUsuario({
            cdPessoaUsr: props.userSession.cdPessoaUsr
        }, loadingArea, cdTransacao);

        setProvedoresId(provedores.map(item => {

            const jaVinculado = vinculados.filter(vinc => vinc.cdProvedorIdentidade === item.cdProvedorIdentidade).length > 0;

            const botao =
                    <BotaoProvedorIdentidade
                        onClickVincular={btnVincularProvId}
                        onClickReVincular={btnReVincularProvId}
                        onClickDesvincular={btnDesvincularProvId}
                        block={true}
                        cdProvedorIdentidade={item.cdProvedorIdentidade}
                        jaVinculado={jaVinculado}
                    />
            ;

            return {
                ...item,
                botao: botao
            }

        }));

        return true;
    }

    const onChangeAvatar = async (newImg) => {

        const base64String = await convertBlobToBase64(newImg);

        const mediaParams = {
            strImg64: base64String.substring(23), // desconsiderar "data:image/jpeg;base64,"
            extensao: "jpeg"
        };

        const resultado = await alteraFotoUsuario(props.userSession, mediaParams);

        if(resultado.id === "urlImgUsuario") {

            props.updateProfileImage(resultado.mensagem);

        } else {
            console.log(resultado);

        }

    }

    const telCelPatterns = [{
        pattern: "(00) 0000-0000",
        length: 10, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }, {
        pattern: "(00) 0-0000-0000",
        length: 11, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }];

    const salvarPerfil = async () => {

        if(campoAlterado === false) {
            notificacao.aviso({transacao: cdTransacao, message:"Salvar", description:"Nenhuma informação foi alterada. Não é necessário salvar !"})
            return false;
        }

        scrollToTop();

        try {

            const camposDoForm = await formRef.current?.validateFields();

            const payload = {
                acaoAExecutar: "atualizaDadosPerfil",
                noApelido: camposDoForm.noApelido,
                noEmail: camposDoForm.noEmail,
                noUsuario: camposDoForm.noUsuario,
                nrTelCelular: camposDoForm.nrTelCelular.retiraCaracteres("() -")
            };

            try {

                const resposta = await postJSON({
                    sistema: "smk",
                    projeto: "musuariopiy2b",
                    acaoWebAExecutar: "processarnegocio"
                }, payload, loadingArea);

                setCampoAlterado(false);

                await props.atualizarInfoPerfilUsr({
                    noApelido: camposDoForm.noApelido,
                    noEmail: camposDoForm.noEmail,
                    noPessoa: camposDoForm.noUsuario,
                    nrTelCelular: camposDoForm.nrTelCelular.retiraCaracteres("() -")
                });

                notificacao.sucesso({transacao: cdTransacao, message: "Sucesso", description:"Informações do seu perfil alteradas na plataforma com sucesso !"})

            } catch (e1) {

                notificacaoErroRequest(e1);

            }

        } catch (e) {

            notificacaoErroValidForm(e, cdTransacao);

        }

    };

    const vincularProvedorIdentidade = async (params) => {

        try {

            const user = await signInWithIdProvider(params.cdProvedorIdentidade);

            if(user !== null) {

                const atualizarFotoDoPerfil = (props.userSession.urlFoto?.indexOf("perfil-sem-foto.png")>=0) && campoInformado(user.photoURL);

                const vinculado = await vincularProvedorIdentidadeUsuario({
                    cdPessoaUsr:props.userSession.cdPessoaUsr,
                    cdProvedorIdentidade: params.cdProvedorIdentidade,
                    chUId: user.uid,
                    noEmail: user.email,
                    photoURL: user.photoURL
                }, loadingArea, cdTransacao);

                if(vinculado === true) {

                    if(atualizarFotoDoPerfil === true) {

                        const newImg = await urlAsBlob(user.photoURL);

                        if(campoInformado(newImg)) {

                            await onChangeAvatar(newImg);

                        }

                    }

                    inicializaProvId();

                    return true;

                } else {

                    return false;

                }

            } else {

                notificacao.erro({errorId: "vincProvId", transacao: cdTransacao, message:"Opps!!!", description:"Não foi possível confirmar o vinculo com a conta selecionada"});

                return false;

            }

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const btnReVincularProvId  = async (params) => {

        const respLogout = await logoutProvedorIdentidade();

        return await btnVincularProvId(params);

    }

    const btnVincularProvId  = async (params) => {

        return await vincularProvedorIdentidade(params);

    }

    const desvincularProvedorIdentidade = async (params) => {

        try {

            const vinculados = await pesquisarProvedorIdentidadeUsuario({
                cdPessoaUsr: props.userSession.cdPessoaUsr,
                cdProvedorIdentidade: params.cdProvedorIdentidade
            }, loadingArea, cdTransacao);

            if(vinculados.length === 1) {

                const desvinculado = await desvincularProvedorIdentidadeUsuario(vinculados[0], loadingArea, cdTransacao);

                console.log(desvinculado);

                await inicializaProvId();

            }

            return true;

        } catch (e) {

            notificacaoErroRequest(e, cdTransacao);

            return false;

        }

    }

    const btnDesvincularProvId = async (params) => {

        return await desvincularProvedorIdentidade(params);

    }

    const provedoresDeIdentidade =
    <>
        <Row gutter={[8,16]}>
            <Col xs={24}>
                <h3>Provedores de identidade disponíveis</h3>
            </Col>
        </Row>
        <Row gutter={[8,16]}>
            {provedoresId.map(item => {
                return (
                    <Col xs={24} md={12} lg={8} xl={6} key={"col-" + item.cdProvedorIdentidade} style={{marginTop: 10}}>
                        {item.botao}
                    </Col>
                );
            })}
        </Row>
    </>
    ;

    const [exibeTermosDeUso, setExibeTermosDeUso] = useState(false);

    const btnVerTermosUso = () => {
        setExibeTermosDeUso(true);
    }

    return (
        <>
            <ProForm
                formRef = { formRef }
                submitter = {{
                    render : () =>
                        <div className={"iy2b-footer-toolbar"}
                             style={{
                                 width: availableWidth()
                             }}
                        >
                            <Button type={"link"} onClick={btnVerTermosUso}>Visualizar os termos de uso</Button>
                            <Button type={"primary"} onClick={salvarPerfil}>Salvar perfil</Button>
                        </div>
                }}
                onFieldsChange = {(changedFields) => {
                    setCampoAlterado(changedFields.length > 0);
                }}
            >
                <Row gutter={[8,16]}>
                    <Col xs={22} md={18} lg={20}>
                        <ProFormText
                            width={"lg"}
                            name = "noLogin"
                            label = "EMail para login"
                            tooltip = "Email de login"
                            placeholder = "Meu email de login"
                            rules = { [ { required : true , message : 'Necessário informar o seu nome completo', max:60 } ] }
                            disabled
                        />
                    </Col>
                    <Col xs={12} md={6} lg={4} className={"align-center"}>
                        <AvatarComEdicao
                            cropWidth={42} cropHeight={42}
                            size={120}
                            shape={"circle"}
                            src={props.userSession.urlFoto}
                            onChangeAvatar={onChangeAvatar}
                        >
                        </AvatarComEdicao>
                    </Col>
                </Row>
                <Row gutter={[8,16]}>
                    <Col xs={{span:18}} md={{span:24}}>
                        <ProForm.Group>
                            <ProFormText
                                width={"lg"}
                                name = "noUsuario"
                                label = "Nome"
                                tooltip = "Meu nome completo"
                                placeholder = "Meu nome"
                                rules = { [ { required : true , message : 'Necessário informar o seu nome completo', max:60 } ] }
                                disabled={USUARIO_EH_A_EMPRESA}
                            />
                            <ProFormText
                                width={"md"}
                                name = "noApelido"
                                label = "Apelido"
                                placeholder={"Digite o apelido"}
                                tooltip = "Nome abreviado / apelido do usuário"
                                rules={[ {required: true, message:"Informe o seu apelido"}, {message:"No máximo 20 caracteres", max:20}]}
                            />
                        </ProForm.Group>
                    </Col>
                </Row>
                <Row gutter={[8,16]}>
                    <Col xs={{span:18}} md={{span:24}}>
                        <ProForm.Group>
                            <ProFormText
                                width={"lg"}
                                name = "noEmail"
                                label = "E-Mail de contato"
                                tooltip = "Infome o seu email do contato"
                                placeholder={"Digite o email de contato"}
                                rules={[
                                    {required: true, message:"Informe o email de contato"},
                                    {message:"Informe um email válido", type:"email"},
                                    {message:"No máximo 60 caracteres", max:60}
                                ]}
                            />
                            <IY2BProFormMasked
                                width={"sm"}
                                label={"Telefone celular"}
                                name="nrTelCelular"
                                tooltip = "Nro. telefone celular"
                                rules={[{ required: true, message: 'Por favor, informe o nro do seu telefone celular !' }, {
                                    validator: async (rule, value) => {
                                        if(inputRefTelCel) {
                                            const result = await inputRefTelCel.current.validate();
                                            if(result.valido === false) throw new Error(result.message);
                                        }
                                        return true;
                                    }
                                }]}
                                api={inputRefTelCel}
                                patterns={telCelPatterns}
                            />
                        </ProForm.Group>
                    </Col>
                </Row>
                {provedoresDeIdentidade}
            </ProForm>
            <PopUpTermosDeUso
                exibePopUp={ exibeTermosDeUso }
                onRequestClose={() => { setExibeTermosDeUso(false); }}
            />
        </>
    );

}

const mapStateToProps = function( { userSession, dataProvider, appConfig, suite } ) {

    return {
        userSession: userSession,
        dataProvider: dataProvider,
        appConfig: appConfig,
        suite: suite
    };

};

const mapDispatchToProps = dispatch => {
    return {
        updateProfileImage: (urlFoto) => {
            return dispatch( updateProfileImage(urlFoto) );
        },
        atualizarInfoPerfilUsr: (perfil) => {
            return dispatch( atualizarInfoPerfilUsr(perfil) );
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MeuPerfil);


//v5 import "antd/dist/antd.css";
import '../../../../assets/css/tema.scss';
import './style.scss';

import React, { useState, useEffect } from 'react';
import {Avatar, Tooltip, Carousel, Col, Dropdown} from 'antd';
import ReactModal from 'react-modal';
import Iframe from "react-iframe";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useWindowSize} from "../../../../lib/iy2b-react";
import {CUSTOM} from "../../../../assets/iconografia";
import {setActive} from "../../../../reducer/transacaoManager";
import {connect} from "react-redux";


const iconSignOutAlt = CUSTOM.SIGN_OUT_ALT_ICON;
const iconFileSignature = CUSTOM.FILESIGNATURE_ICON;
const iconCloud = CUSTOM.CLOUD_ICON;
const iconUsers = CUSTOM.USERS_ICON;
const iconUser = CUSTOM.USER_ICON;
const iconAddressCard = CUSTOM.ADDRESSCARD_ICON;
const iconBuilding = CUSTOM.BUILDING_ICON;
const iconQuestionCircle = CUSTOM.QUESTIONCIRCLE_ICON;
const iconCaretDown = CUSTOM.CARETDOWN_ICON;

const UsuarioDropdown = props => {

    const breaks = useBreakpoint();

    const windowSize = useWindowSize();

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [exibeDropdown, setExibeDropDown] = useState(false);

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                exibeDropdown: () => {
                    setExibeDropDown(true);
                    setTooltipVisible(false);
                },
                escondeDropdown: () => {
                    setExibeDropDown(false);
                    setTooltipVisible(false);
                }
            };
        }

        return () => {

        };

    }, []);

    const exibeMenuUsr = () => {
        setExibeDropDown(! exibeDropdown);
        setTooltipVisible(false);
    };

    const handleClose = () => {
        setExibeDropDown(false);
        setTooltipVisible(false);
    }

    const handleLogout = () => {
        handleClose();
        props.onLogout();
    };

    const handleMeuPerfil = () => {
        handleClose();
        props.setActive(85061);
    }

    const handleMeuPlano = () => {
        handleClose();
        props.setActive(85062);
    }

    const handleMinhasEmpresas = () => {
        handleClose();
        props.setActive(85063);
    }

    const handleMeusUsuarios = () => {
        handleClose();
        props.setActive(85064);
    }

    const classNameStatusDropdown = exibeDropdown ? "open" : "close";

    const { flgGratuito } = props.userSession?.planoContratado;

    const usrMaster = props.userSession?.master;

    const existeEmpCNPJ = props.suite.listaEmp.filter(empresa => empresa.pessoaEmp.flgJuridico==="J").length > 0;

    const scrollerWidth = 328;

    const { acessosEspeciais } = props.transacaoManager;

    const adSpace = (flgGratuito==="N") ? null :
        <div id={"adSpace"}>
            <Carousel autoplay>
                {props.suite.listaPeca.map(item => {
                    return (
                        <div key={item.cdPeca}
                             className={"peca"}
                        >
                            <div>
                                <Iframe url={item.url}
                                        width={(scrollerWidth-2) + "px"}
                                        height="238px"
                                        id={"iframe-" + item.cdPeca}
                                />
                            </div>
                        </div>
                    )
                })}
            </Carousel>
        </div>
        ;

    const menuItems = [];

    const menuOptions = {
        items: menuItems
    };

    menuItems.push({
        key: "botaoMeuPerfil",
        label: "Meu perfil",
        icon: iconAddressCard,
        onClick: handleMeuPerfil
    });

    const botaoMeuPlano = (usrMaster==="S")?
        <div className={"boxBotao"}>
            <Tooltip placement="top" title={"Consultar e alterar as informações do seu plano de assinatura da plataforma"} overlayStyle={{zIndex: 3000}} color={"black"}>
                <div className={"botao"} onClick={handleMeuPlano}>
                    <span className={"icone"}>{iconFileSignature}</span>
                    <span className={"label"}>Meu plano</span>
                </div>
            </Tooltip>
        </div>
        : null
    ;

    if(botaoMeuPlano != null) {
        menuItems.push({
            key: "botaoMeuPlano",
            label: "Meu plano",
            icon: iconFileSignature,
            onClick: handleMeuPlano
        });
    }

    const botaoMinhasEmpresas = ((usrMaster==="S" || acessosEspeciais.usuarios === true) && existeEmpCNPJ === true)?
        <div className={"boxBotao"}>
            <Tooltip placement="left" title={"Empresas"} overlayStyle={{zIndex: 3000}} color={"black"}>
                <div className={"botao"} onClick={handleMinhasEmpresas}>
                    <span className={"icone"}>{iconBuilding}</span>
                    <span className={"label"}>Empresas</span>
                </div>
            </Tooltip>
        </div>
        :null
    ;

    if(botaoMinhasEmpresas != null) {
        menuItems.push({
            key: "botaoMinhasEmpresas",
            label: "Empresas",
            icon: iconBuilding,
            onClick: handleMinhasEmpresas
        });
    }

    const botaoUsuarios = (usrMaster==="S" || acessosEspeciais.usuarios === true) ?
        <div className={"boxBotao"}>
            <Tooltip placement="bottom" title={"Usuários"} overlayStyle={{zIndex: 3000}} color={"black"}>
                <div className={"botao"} onClick={handleMeusUsuarios}>
                    <span className={"icone"}>{iconUsers}</span>
                    <span className={"label"}>Usuários</span>
                </div>
            </Tooltip>
        </div>
        :null
    ;

    if(botaoUsuarios != null) {
        menuItems.push({
            key: "botaoUsuarios",
            label: "Usuários",
            icon: iconUser,
            onClick: handleMeusUsuarios
        });
    }

    const botaoAreaArquivos =
        <div className={"boxBotao"}>
            <Tooltip placement="left" title={"Arquivos gerados pelo sistema disponíveis para download"} overlayStyle={{zIndex: 3000}} color={"black"}>
                <div className={"botao"}>
                    <span className={"icone"}>{iconCloud}</span>
                    <span className={"label"}>Arquivos</span>
                </div>
            </Tooltip>
        </div>
    ;

    const botaoAreaSuporte =
        <div className={"boxBotao"}>
            <Tooltip placement="bottom" title={"Abrir e consultar atendimentos de suporte"} overlayStyle={{zIndex: 3000}} color={"black"}>
                <div className={"botao"}>
                    <span className={"icone"}>{iconQuestionCircle}</span>
                    <span className={"label"}>Suporte</span>
                </div>
            </Tooltip>
        </div>
    ;


    menuItems.push({
        key: "botaoLogout",
        label: "Encerrar a sessão",
        icon: iconSignOutAlt,
        onClick: handleLogout
    });

    const menuDropdownRender = ( menu ) => {
        const { noPessoa } = props.userSession;
        return (
            <div className={"scroller"} style={{width:scrollerWidth}}>
                <div className={"container"}>
                    <div id={"noUsuario"}>{noPessoa}</div>
                    <div id={"botoes"}>
                        <div className={"boxBotao"}>
                            <Tooltip placement="left" title={"Editar suas informações pessoais"} overlayStyle={{zIndex: 3000}} color={"black"}>
                                <div className={"botao"} onClick={handleMeuPerfil}>
                                    <span className={"icone"}>{iconUser}</span>
                                    <span className={"label"}>Meu perfil</span>
                                </div>
                            </Tooltip>
                        </div>
                        {botaoMeuPlano}
                        {botaoMinhasEmpresas}
                        {botaoUsuarios}
                    </div>
                    <div id={"rodape"}>
                        <div className={"boxBotao"}>
                            <Tooltip placement="bottom" title={"Encerrar a sua sessão e voltar a tela de login"} overlayStyle={{zIndex: 3000}} color={"black"}>
                                <div id={"btnEncerrar"} className={"botao"} onClick={handleLogout}>
                                    <span className={"icone"}>{iconSignOutAlt}</span>
                                    <span className={"label"}>Encerrar a sessão</span>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Col flex={"72px"} className={"align-center"}>
            <div id={"usuarioDropdown"} className={[classNameStatusDropdown].toClassName()}>
                <Dropdown
                    menu={menuOptions}
                    dropdownRender={menuDropdownRender}
                    overlayClassName={"usuarioDropdownModal"}
                >
                    <a onClick={exibeMenuUsr}>
                        <Avatar size={40} id={"usrAvatar"}
                                shape={"circle"}
                                src={props.userSession.urlFoto}
                        >
                        </Avatar>
                        <FontAwesomeIcon icon={faCaretDown} id={"caretAvatar"} color={"#333333"} />
                    </a>
                </Dropdown>
            </div>
        </Col>
    );
};

const mapStateToProps = function( { userSession, suite, transacaoManager } ) {

    return {
        userSession: userSession,
        suite: suite,
        transacaoManager: transacaoManager
    };

};

const mapDispatchToProps = dispatch => {
    return {
        setActive: (key) => {
            return dispatch( setActive(key) );
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(UsuarioDropdown);


//v5 import "antd/dist/antd.css";
import '../../../assets/css/tema.scss';
import './style.scss';
import styles from './style.module.scss';

import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {ProForm} from '@ant-design/pro-components' ;
import debounce from "lodash/debounce";

import {campoInformado, campoInformadoString, formatadorCpfCnpj} from "../../../lib/iy2b-javascript";
import {Select, Spin, Divider} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

import {PLUS_CIRCLE_ICON_PRIMARY} from "../../../assets/iconografia";
import classNames from "classnames";

const iconAdd = PLUS_CIRCLE_ICON_PRIMARY;

const { Option } = Select;

const defaultRenderCGCCICV4 = item => (
    <Option key={item.cdPessoa}>{item.nrCGCCIC}&nbsp;-&nbsp;{item.noPessoa}</Option>
);

const defaultRenderNomeV4 = item => (
    <Option key={item.cdPessoa}>{item.noPessoa}&nbsp;-&nbsp;{item.nrCGCCIC}</Option>
);

const defaultRenderCGCCIC = item => (
    {
        value: item.cdPessoa,
        label: <span>{item.nrCGCCIC}&nbsp;-&nbsp;{item.noPessoa}</span>
    }
);

const defaultRenderNome = item => (
    {
        value: item.cdPessoa,
        label: <span>{item.noPessoa}&nbsp;-&nbsp;{item.nrCGCCIC}</span>
    }
);

const renderValueNrCGCCIC = (item, formatter) => {
    if(item === null || item === undefined) return {value: null, label: null, data: undefined};
    const value = formatter(item);
    return { ...value,
        data: item.srcData
    }
}

const renderValueNoPessoa = (item, formatter) => {
    if(item === null || item === undefined) return {value: null, label: null, data: undefined};
    const value = formatter(item);
    return value;
}

const AutoCompletePessoa = React.forwardRef((props, ref) => {

    const { formRef, name, label, placeholder, tooltip, rules, attrId, tipoPessoa, visible=true, disabled=false, autoFocus=false, numRow=null, dropdownMatchSelectWidth=540 } = props;

    const { contentNotFoundAfterFetch = null, habilitaAdicao = false} = props;

    const refNome = useRef();

    const labelNrCGCCIC = (tipoPessoa==="F") ? "CPF" : "CNPJ";

    const breakpoints = useBreakpoint();

    /////////////////////////////////////////////////////////////////////////////

    const {
        attrCdPessoa = "cdPessoa",
        attrNoPessoa = "noPessoa",
        attrNrCGCCIC = "nrCGCCIC"
    } = props;

    const defaultFormatterValueNrCGCCIC = (item) => {
        return {
            value: item[attrCdPessoa],
            label: formatadorCpfCnpj(item[attrNrCGCCIC])
        }
    }

    const defaultFormatterValueNoPessoa = (item) => {
        return {
            value: item[attrCdPessoa],
            label: item[attrNoPessoa]
        }
    }

    const {
        onRequestCGCCIC,
        onRequestNome,
        renderOptionsCGCCIC = defaultRenderCGCCIC,
        renderOptionsNome = defaultRenderNome,
        formatterValueCGCCIC = defaultFormatterValueNrCGCCIC,
        formatterValueNome = defaultFormatterValueNoPessoa,
        debounceTimeout = 800
    } = props;

    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchCGCCICRef = React.useRef(0);
    const fetchNomeRef = React.useRef(0);

    useImperativeHandle(ref, () => {
        return {
            setFieldValue: setFieldValue,
            focus: () => {
                setTimeout(() => {
                    refNome?.current?.focus();
                }, 1);
            },
            getCdPessoa: () => {
                const values = formRef.current?.getFieldsValue();
                const value = values[name + "_NrCGCCIC"];
                if(campoInformado(value) === true) {
                    return value.value
                } else {
                    return undefined
                }
            }
        };
    });

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                setFieldValue: setFieldValue,
                focus: () => {
                    setTimeout(() => {
                        refNome?.current?.focus();
                    }, 1);
                },
                getCdPessoa: (values) => {
                    const value = values[name + "_NrCGCCIC"];
                    if(campoInformado(value) === true) {
                        return value.value
                    } else {
                        return undefined
                    }
                }
            }
        };

        if(autoFocus === true) {
            props.api.current.focus();
        }

        return () => {

        };

    }, []);

    const setFieldValue = (value) => {

        const clearValue = { };

        clearValue[name + "_NoPessoa"] = undefined;
        clearValue[name + "_NrCGCCIC"] = undefined;

        formRef?.current?.setFieldsValue(clearValue);

        const newValue = { };

        newValue[name + "_NrCGCCIC"] = renderValueNrCGCCIC(value, formatterValueCGCCIC);
        newValue[name + "_NoPessoa"] = renderValueNoPessoa(value, formatterValueNome);

        formRef?.current?.setFieldsValue(newValue);

        return newValue;

    }

    const optionsFormatter = (value) => {

        const resultado = value.map(item => {

            const pessoa = { };

            pessoa[attrId] = item[attrId];
            pessoa[attrCdPessoa] = item[attrCdPessoa];
            pessoa[attrNoPessoa] = item[attrNoPessoa];
            if(item[attrNrCGCCIC]) pessoa[attrNrCGCCIC] = formatadorCpfCnpj(item[attrNrCGCCIC]);

            pessoa.srcData = {...item};

            return pessoa;

        });

        return resultado;

    }

    const debounceFetcherCGCCIC = React.useMemo(() => {

        const loadOptions = (value) => {

            fetchCGCCICRef.current += 1;
            const fetchId = fetchCGCCICRef.current;
            setOptions([]);
            setFetching(true);

            onRequestCGCCIC(value).then((newOptions) => {

                if (fetchId !== fetchCGCCICRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(optionsFormatter(newOptions));
                setFetching(false);

            }, (error) => {

                setOptions([]);
                setFetching(false);

            });

        };

        return debounce(loadOptions, debounceTimeout);

    }, [onRequestCGCCIC, debounceTimeout]);

    const debounceFetcherNome = React.useMemo(() => {

        const loadOptions = (value) => {

            fetchNomeRef.current += 1;
            const fetchId = fetchNomeRef.current;
            setOptions([]);
            setFetching(true);

            onRequestNome(value).then((newOptions) => {

                if (fetchId !== fetchNomeRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(optionsFormatter(newOptions));
                setFetching(false);

            }, (error) => {

                setOptions([]);
                setFetching(false);

            });

        };

        return debounce(loadOptions, debounceTimeout);

    }, [onRequestNome, debounceTimeout]);

    const handleSearch = (value, field) => {

        if(campoInformadoString(value)) {
            const minChars = field === "nrCGCCIC" ? 5 : 4 ;
            if(value.trim().length >= minChars) {
                if(field === "nrCGCCIC") {
                    debounceFetcherCGCCIC(value);
                } else {
                    debounceFetcherNome(value);
                }
            } else {
                setOptions([]);
                setFetching(false);
            }
        } else {
            setOptions([]);
            setFetching(false);
        }

    }

    useEffect(() => {

        // console.log(props.value);

    }, [props.value]);

    useEffect(() => {

        // console.log(props.defaultValue);

    }, [props.defaultValue]);

    const selectValue = (value, sourceField) => {

        const clearValue = { };

        if(sourceField === "nrCGCCIC") {
            clearValue[name + "_NoPessoa"] = undefined;
        } else {
            clearValue[name + "_NrCGCCIC"] = undefined;
        }

        formRef.current.setFieldsValue(clearValue);

        const selecionado = options.filter(item => item[attrCdPessoa] === parseInt(value.key));

        const newValue = { };

        if(selecionado.length > 0) {
            newValue[name + "_NrCGCCIC"] = renderValueNrCGCCIC(selecionado[0], formatterValueCGCCIC);
            newValue[name + "_NoPessoa"] = renderValueNoPessoa(selecionado[0], formatterValueNome);
        } else {
            newValue[name + "_NrCGCCIC"] = undefined;
            newValue[name + "_NoPessoa"] = undefined;
        }

        formRef.current.setFieldsValue(newValue);

    }

    const onSelectCGCCIC = (value, option) => {

        selectValue(value, "nrCGCCIC");

    }

    const onSelectNome = (value, option) => {

        selectValue(value, "noPessoa");

    }

    const clearValue = () => {
        const newValue = { };
        newValue[name + "_NrCGCCIC"] = undefined;
        newValue[name + "_NoPessoa"] = undefined;
        formRef.current.setFieldsValue(newValue);
    }

    const childrenNrCGCCIC = options.map(renderOptionsCGCCIC);

    const childrenNoPessoa = options.map(renderOptionsNome);

    const notFoundContent = fetching ? <Spin size="small" /> : contentNotFoundAfterFetch;

    const classNameCGCCIC = ["iy2b-autocomplete-pessoa-cgccic", "iy2b-pro-field", "pro-field"];

    const classNameNome = ["iy2b-autocomplete-pessoa-nome", "iy2b-pro-field", "pro-field"];

    const styleCols = {
        display: "flex"
    };

    let widthCGCCIC = 0;

    if(props.width) {

        if(props.width==="sm") {
            classNameCGCCIC.push("pro-field-md");
            classNameNome.push("pro-field-md");
            widthCGCCIC = 328;

        } else if(props.width==="md") {
            classNameCGCCIC.push("pro-field-md");
            classNameNome.push("pro-field-md");
            widthCGCCIC = 328;

        } else if(props.width==="lg") {

            if(breakpoints.xl === true) {
                classNameCGCCIC.push("pro-field-sm");
                classNameNome.push("pro-field-md");
                widthCGCCIC = 216;

            } else if(breakpoints.lg === true) {
                classNameCGCCIC.push("pro-field-sm");
                classNameNome.push("pro-field-lg");
                widthCGCCIC = 216;

                styleCols["flexWrap"] = "wrap";

            } else {
                classNameCGCCIC.push("pro-field-sm");
                classNameNome.push("pro-field-md");
                widthCGCCIC = 216;

            }

        } else if(props.width==="sm-md") {
            classNameCGCCIC.push("pro-field-sm");
            classNameNome.push("pro-field-md");
            widthCGCCIC = 216;

        } else if(props.width==="sm-lg") {
            classNameCGCCIC.push("pro-field-sm");
            classNameNome.push("pro-field-lg");
            widthCGCCIC = 216;

        } else if(props.width==="sm-xl") {
            classNameCGCCIC.push("pro-field-sm");
            classNameNome.push("pro-field-xl");
            widthCGCCIC = 216;

        } else if(props.width==="xl") {

            if(breakpoints.xl === true) {
                classNameCGCCIC.push("pro-field-md");
                classNameNome.push("pro-field-xl");
                widthCGCCIC = 328;

            } else if(breakpoints.lg === true) {
                classNameCGCCIC.push("pro-field-sm");
                classNameNome.push("pro-field-lg");
                widthCGCCIC = 216;

                styleCols["flexWrap"] = "wrap";

            } else {
                classNameCGCCIC.push("pro-field-sm");
                classNameNome.push("pro-field-md");
                widthCGCCIC = 216;
            }

        }

    } else {

        classNameCGCCIC.push("pro-field-sm");
        classNameNome.push("pro-field-md");
        widthCGCCIC = 216;

    }

    const required = rules?.filter(item => item.required === true).length > 0 ;

    const classNameWrapper = classNames(styles.Wrapper);

    const [myMaxWidth, setMyMaxWidth] = useState(0);

    setTimeout(() => {
        const esWrapper = document.getElementsByClassName("iy2b-pro-field iy2b-autocomplete-pessoa " + classNameWrapper);
        if(esWrapper.length > 0) {
            const eWrapper = esWrapper[0];
            if(myMaxWidth != eWrapper.clientWidth) {
//                console.log("updating myMaxWidth", myMaxWidth, eWrapper.clientWidth, eWrapper.parentElement.clientWidth);
                setMyMaxWidth(eWrapper.clientWidth);
            }
        }
    }, 1);

    const className = ["iy2b-pro-field", "iy2b-autocomplete-pessoa", classNameWrapper];

    widthCGCCIC = 184;
    const styleSelectCGCCIC = {
        maxWidth: widthCGCCIC
    };

    const maxWidthNome = (myMaxWidth-widthCGCCIC);
    const styleSelectNome = {
        maxWidth: maxWidthNome
    };

    if(visible === false) className.push("not-visible");

    let botaoAdd = null;

    if(habilitaAdicao===true) {
        botaoAdd = <div className={"container-botao-add"}>
            <Divider
                style={{
                    margin: '8px 0',
                }}
            />
            {notFoundContent}
        </div>;
    }

    const optionRender_CGCCIC = (option) => {
        const CGCCIC = option.label.props.children[0];
        const noPessoa = option.label.props.children[2];
        return (
            <div className={classNames(styles.boxOptionPod)}>
                <div className={classNames(styles.optionLabel)}>{CGCCIC}</div>
                <div className={classNames(styles.optionDescription)}>{noPessoa}</div>
            </div>
        );
    }

    const optionRender_Nome = (option) => {
        const noPessoa = option.label.props.children[0];
        const CGCCIC = option.label.props.children[2];
        return (
            <div className={classNames(styles.boxOptionPod)}>
                <div className={classNames(styles.optionLabel)}>{noPessoa}</div>
                <div className={classNames(styles.optionDescription)}>{CGCCIC}</div>
            </div>
        );
    }

    return (
        <div className={className.toClassName()}>
            <ProForm.Group>
                <ProForm.Item
                    label = {label}
                    tooltip = {tooltip}
                    required = { required }
                >
                    <div style={styleCols}>
                        <ProForm.Item noStyle
                                   name = {name + "_NrCGCCIC"}
                                   rules = { rules }
                        >
                            <Select className={classNameCGCCIC.toClassName()}
                                    showSearch
                                    allowClear={true}
                                    filterOption={false}
                                    onSearch={(value) => handleSearch(value, "nrCGCCIC")}
                                    placeholder={placeholder + " (" + labelNrCGCCIC + ")"}
                                    labelInValue
                                    onSelect={(value, option) => onSelectCGCCIC(value, option)}
                                    onClear={clearValue}
                                    notFoundContent={ notFoundContent }
                                    popupMatchSelectWidth={dropdownMatchSelectWidth}
                                    disabled={disabled}
                                    optionRender={optionRender_CGCCIC}
                                    options={childrenNrCGCCIC}
                                    style={styleSelectCGCCIC}
                            >
                            </Select>
                        </ProForm.Item>
                        &nbsp;
                        <ProForm.Item noStyle
                                   name = {name + "_NoPessoa"}
                        >
                            <Select className={classNameNome.toClassName()}
                                    popupClassName={"dropdown-iy2b-autocomplete-pessoa-nome"}
                                    showSearch
                                    allowClear={true}
                                    filterOption={false}
                                    onSearch={(value) => handleSearch(value, "noPessoa")}
                                    placeholder={placeholder + " (Nome)"}
                                    labelInValue
                                    onSelect={(value, option) => onSelectNome(value, option)}
                                    onClear={clearValue}
                                    popupMatchSelectWidth={dropdownMatchSelectWidth}
                                    disabled={disabled}
                                    ref={refNome}
                                    dropdownRender={(menu) => {
                                        return (
                                            <>
                                                {menu}
                                                {botaoAdd}
                                            </>
                                        );
                                    }}
                                    optionRender={optionRender_Nome}
                                    options={childrenNoPessoa}
                                    style={styleSelectNome}
                            >
                            </Select>
                        </ProForm.Item>
                    </div>
                </ProForm.Item>
            </ProForm.Group>
        </div>
    );

});

export default AutoCompletePessoa;

import {postJSON} from "../framework/lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../framework/pages/Workspace/workspace";
import {preenche, sortByLabel} from "../framework/lib/iy2b-javascript";
import {cachedPostJSON} from "../framework/lib/CacheRequestAPI";
import {preventInjection} from "../framework/lib/iy2b-react";

const pesquisarCredor = async ({cdPessoa, nrCGCCIC, noPessoa}) => {

    const payload = {
        "acaoAExecutar": "pesquisaACCredor",
        filtro: preventInjection({
            txtCdPessoa: cdPessoa,
            txtNoPessoa: noPessoa,
            txtNrCGCCIC: nrCGCCIC?.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
        })
    }

    try {

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "cpesqiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        const listaCredor = [];

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaCredorEmp") {
                listaCredor.addAll(msg.lista);
            }

        });

        return listaCredor;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarCliente = async ({cdPessoa, nrCGCCIC, noPessoa}) => {

    const payload = {
        "acaoAExecutar": "pesquisaACCliente",
        filtro: preventInjection({
            txtCdPessoa: cdPessoa,
            txtNoPessoa: noPessoa,
            txtNrCGCCIC: nrCGCCIC?.replaceAll(".", "").replaceAll("/", "").replaceAll("-", "")
        })
    }

    try {

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "cpesqiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        const listaCliente = [];

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaCliEmp") {
                listaCliente.addAll(msg.lista);
            }

        });

        return listaCliente;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarCentroCusto = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisaACCentroCusto",
        filtro: preventInjection({
            txtNoCCusto: params
        })
    }

    try {

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "cpesqiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        const lista = [];

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaCentroCusto") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdCCusto,
                        label: item.noCCusto?.trim(),
                        data: {
                            cdCCusto: item.cdCCusto
                        }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarCentroCustoSemRestricao = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisaACCentroCusto",
        filtro: preventInjection({
            txtNoCCusto: params
        }),
        apenasValidos: "N"
    }

    try {

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "cpesqiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        const lista = [];

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaCentroCusto") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdCCusto,
                        label: item.noCCusto?.trim(),
                        data: {
                            cdCCusto: item.cdCCusto
                        }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarPlanoDeCustos = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisaACPlanoDeCusto",
        filtro: preventInjection({
            txtNoCCusto: params
        })
    }

    try {

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "cpesqiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        const lista = [];

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaCentroCusto") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdCCusto,
                        label: item.noCCusto?.trim(),
                        data: {
                            cdCCusto: item.cdCCusto,
                            dtExclusao: item.dtExclusao || "1901/01/01",
                            dtBloqueio: item.dtBloqueio || "1901/01/01",
                        }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarContaMemo = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisaACContaMemo",
        filtro: preventInjection({
            txtNoCMemo: params
        })
    }

    try {

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "cpesqiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        const lista = [];

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaContaMemo") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdCMemo,
                        label: item.noCMemo?.trim(),
                        data: {
                            cdCMemo: item.cdCMemo,
                        }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarPlanoMemo = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisaACPlanoMemo",
        filtro: preventInjection({
            txtNoCMemo: params
        })
    }

    try {

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "cpesqiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        const lista = [];

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaContaMemo") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdCMemo,
                        label: item.noCMemo?.trim(),
                        data: {
                            cdCMemo: item.cdCMemo,
                            dtExclusao: item.dtExclusao || "1901/01/01",
                            dtBloqueio: item.dtBloqueio || "1901/01/01",
                        }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarJobDoMemo = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisaACJob",
        filtro: preventInjection({
            txtCdCMemo: params
        })
    }

    try {

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "cpesqiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        const lista = [];

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaJob") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.nrJob,
                        label: item.noJob?.trim(),
                        data: {
                            cdCMemo: item.cdCMemo
                        }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarJob = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisaACJob",
        filtro: preventInjection({
            txtNoJob: params
        })
    }

    try {

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "cpesqiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        const lista = [];

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaJob") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.nrJob,
                        label: item.noJob?.trim(),
                        data: {
                            cdCMemo: item.cdCMemo
                        }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarJobSemRestricao = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisaACJob",
        filtro: preventInjection({
            txtNoJob: params
        }),
        apenasValidos: "N"
    }

    try {

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "cpesqiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        const lista = [];

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaJob") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.nrJob,
                        label: item.noJob?.trim(),
                        data: {
                            cdCMemo: item.cdCMemo
                        }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}




const pesquisarGrpEconomico = async (params) => {

    try {

        const lista = [];

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarAtividade = async (params) => {

    try {

        const lista = [];

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarTipoDespEmp = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisaACTipoDespEmp",
        filtro: preventInjection({
            txtNoTipoDesp: params
        })
    }

    try {

        const lista = [];

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "cpesqiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaTipoDesp") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdTipoDesp,
                        label: item.noTipoDesp?.trim(),
                        data: {
                            cdCta: item.cdCta
                        }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarTipoDespEmpSemRestricao = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisaACTipoDespEmp",
        filtro: preventInjection({
            txtNoTipoDesp: params
        }),
        apenasValidos: "N"
    }

    try {

        const lista = [];

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "cpesqiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaTipoDesp") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdTipoDesp,
                        label: item.noTipoDesp?.trim(),
                        data: {
                            cdCta: item.cdCta
                        }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarCidade = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisa",
        filtro: preventInjection({
            txtNoCidade: params
        }),
        orderBySql: "CIDADE.NOCIDADE"
    }

    try {

        const lista = [];

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "mcidade",
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaPesquisa") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdCidade,
                        label: item.noCidade?.trim() + "/" + item.ufCidade?.trim(),
                        data: {
                            ... item
                        }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}


const fetchFuncionariosDaEmpresa = (cdPessoaEmp) => {
    return async (params) => {
        return pesquisarFuncionariosDaEmpresa (cdPessoaEmp, params);
    }
}

const pesquisarFuncionariosDaEmpresa = async (cdPessoaEmp, noFuncionario) => {

    const payload = {
        "acaoAExecutar": "pesquisarFuncionarioSmkDaEmpresa",
        filtro: preventInjection({
            cmbCdPessoaEmp: cdPessoaEmp,
            txtNoFuncionario: noFuncionario
        }),
        orderBySql: "PESUSR.NOPESSOA"
    }

    try {

        const lista = [];

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "smk",
            projeto: "msmkpiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if (msg.id === "listaFuncionarioSmk") {

                const dpAux = msg.lista.map(item => {
                    return {
                        value: item.cdPessoaFunc,
                        label: item.noFuncionario.trim() + "(" + item.noEmail.trim() + ")",
                        data: {...item}
                    }
                }).sort(sortByLabel);

                lista.addAll(dpAux);

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const fetchUsuariosDaEmpresa = (cdPessoaEmp) => {
    return async (params) => {
        return pesquisarUsuariosDaEmpresa (cdPessoaEmp, params);
    }
}

const pesquisarUsuariosDaEmpresa = async (cdPessoaEmp, noPessoaUsr) => {

    const payload = {
        "acaoAExecutar": "pesquisarUsuariosDaEmpresa",
        filtro: preventInjection({
            cmbCdPessoaEmp: cdPessoaEmp,
            txtNoPessoa: noPessoaUsr
        }),
        orderBySql: "PESUSR.NOPESSOA"
    }

    try {

        const lista = [];

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "smk",
            projeto: "msmkpiy2b"
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaUsuario") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdPessoaUsr,
                        label: item.noPessoaUsr.trim(),
                        data: {
                            ...item
                        }
                    }
                }).sort(sortByLabel));

            } else if (msg.id === "listaUsuarioEmpresa") {

                const dpAux = msg.lista.map(item => {
                    return {
                        value: item.cdPessoaUsr,
                        label: item.noPessoa.trim() + " (" + item.noEmail + ")",
                        data: {...item}
                    }
                }).sort(sortByLabel);

                lista.addAll(dpAux);

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarUsuario = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisaUsuario",
        filtro: preventInjection({
            txtNoPessoa: params
        }),
        orderBySql: "PESSOA.NOPESSOA"
    }

    try {

        const lista = [];

        const resultado = await postJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "mfuncsmk",
        }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaUsuario") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdPessoaUsr,
                        label: item.noPessoaUsr.trim(),
                        data: {
                            ...item
                        }
                    }
                }).sort(sortByLabel));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarBanco = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisa",
        filtro: {
        }
    }

    try {

        const lista = [];

        const resultado = await cachedPostJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "mbanco",
            payload: payload
        }, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaBanco") {

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdPessoaBco,
                        label: preenche(item.cdBco, "0", "E", 3) + " - " + item.noPessoaBco,
                        data: { ...item }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}

const pesquisarFuncionario = async (params) => {

    const payload = {
        "acaoAExecutar": "pesquisa",
        filtro: preventInjection({
            txtNoFuncionario: params.nome,
            optFlgAtivo: params.ativo||"S"
        }),
    }

    try {

        const lista = [];

        const resultado = await cachedPostJSON({
            acaoWebAExecutar: "pesquisar",
            sistema: "sgr",
            projeto: "mfuncsmk",
            payload: payload
        }, WORKSPACE_LOADING_INDICATOR_AREA);

        resultado.mensagens.forEach(msg => {

            if(msg.id === "listaFuncionarioSmk") {

                console.log(msg.lista);

                lista.addAll(msg.lista.map(item => {
                    return {
                        value: item.cdPessoaFunc,
                        label: item.noPessoaFunc,
                        data: { ...item }
                    }
                }));

            }

        });

        return lista;

    } catch (e) {

        console.log(e);

        return [];

    }

}


const CENTRO_CUSTO_SEM_DEFINICAO = {
    value: 0,
    label: "SEM DEFINIÇÃO",
    data: {
        cdCCusto: 0,
        dtExclusao: "1901/01/01",
        dtBloqueio: "1901/01/01",
    }
}

const CONTA_MEMO_SEM_DEFINICAO = {
    value: 0,
    label: "SEM DEFINIÇÃO",
    data: {
        cdCMemo: 0,
        dtExclusao: "1901/01/01",
        dtBloqueio: "1901/01/01",
    }
}

const GRUPO_ECONOMICO_SEM_DEFINICAO = {
    value: 0,
    label: "SEM DEFINIÇÃO",
    data: {
        cdGrpEconomico: 0
    }
}

const ATIVIDADE_SEM_DEFINICAO = {
    value: 0,
    label: "SEM DEFINIÇÃO",
    data: {
        cdAtividade: 0
    }
}

const HISTORICO_SEM_DEFINICAO = {
    value: 0,
    label: "SEM DEFINIÇÃO",
    data: {
        cdHist: 0
    }
}

const MOEDA_NACIONAL_CORRENTE = {
    value: "    ",
    label: "Moeda nacional corrente",
    data: {
        cdMoeda: "    "
    }
}

export {
    pesquisarCredor,
    pesquisarCliente,
    pesquisarTipoDespEmp,
    pesquisarJob,
    pesquisarJobDoMemo,
    pesquisarPlanoDeCustos,
    pesquisarCentroCusto,
    pesquisarPlanoMemo,
    pesquisarContaMemo,
    pesquisarGrpEconomico,
    pesquisarAtividade,
    pesquisarBanco,
    pesquisarCidade,
    pesquisarTipoDespEmpSemRestricao,
    pesquisarJobSemRestricao,
    pesquisarCentroCustoSemRestricao,
    pesquisarFuncionario,
    pesquisarUsuario,
    pesquisarUsuariosDaEmpresa,
    fetchUsuariosDaEmpresa,
    pesquisarFuncionariosDaEmpresa,
    fetchFuncionariosDaEmpresa,
    CENTRO_CUSTO_SEM_DEFINICAO,
    CONTA_MEMO_SEM_DEFINICAO,
    GRUPO_ECONOMICO_SEM_DEFINICAO,
    ATIVIDADE_SEM_DEFINICAO,
    HISTORICO_SEM_DEFINICAO,
    MOEDA_NACIONAL_CORRENTE,
}

export async function pesquisarEmissor (params) {
    return await pesquisarCredor(params);
}

export async function pesquisarTomador (params) {
    return await pesquisarCliente(params);
}


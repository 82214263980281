import {notification} from "antd";
import moment from "moment";
import {postJSON} from "./RequestAPI";
import {notificacao} from "./Notificacao";

const dataZeradaMoment = moment("01-01-1901", "DD=MM-YYYY");
const dataZeradaDate = new Date("1901/01/01");

const cacheImgUsr = {
    deferred: {

    }
}

const URL_IMG_USR_DEFAULT = "https://erp.iy2b.com/systemakers/assets/images/perfil-sem-foto.png";

const smkPadrao = {
    jsonParsers: {
        respostaValida: (json) => {
            const mensagens = [];
            json.RespostaValida.mensagens.forEach(msg => {
                if(msg.hasOwnProperty("ListaPesquisa") === true) {
                    mensagens.push(smkPadrao.jsonParsers.listaPesquisa(msg));
                } else {
                    console.log(msg);
                }
            });
            return mensagens;
        },
        listaPesquisa: (json) => {
            const resposta = {
                id: json.ListaPesquisa.id,
                lista: json.ListaPesquisa.lista,
                totalRegistros: json.ListaPesquisa.totalRegistros
            };
            return resposta;
        },
        defineLayoutFXOpcoesVO: json => {
            const resposta = {
                hashCode: json.DefineLayoutFXOpcoesVO.hashCode,
                opcao: smkPadrao.jsonParsers.defineLayoutFXOpcoes(json.DefineLayoutFXOpcoesVO.opcao),
                colunas: json.DefineLayoutFXOpcoesVO.colunas.map(item => smkPadrao.jsonParsers.defineLayoutFXColunas(item))
            };
            return resposta;
        },
        defineLayoutFXColunas: json => {
            const resposta = {
                hashCode: json.DefineLayoutFXColunas.hashCode,
                noColuna: json.DefineLayoutFXColunas.noColuna,
                nrOrdem: json.DefineLayoutFXColunas.nrOrdem,
                opcao: smkPadrao.jsonParsers.defineLayoutFXOpcoes(json.DefineLayoutFXColunas.opcao)
            };
            return resposta;
        },
        defineLayoutFXOpcoes: json => {
            const resposta = {
                flgDefault: json.DefineLayoutFXOpcoes.flgDefault,
                hashCode: json.DefineLayoutFXOpcoes.hashCode,
                layout: smkPadrao.jsonParsers.defineLayoutFX(json.DefineLayoutFXOpcoes.layout),
                noOpcao: json.DefineLayoutFXOpcoes.noOpcao,
                nrOpcao: json.DefineLayoutFXOpcoes.nrOpcao
            };
            return resposta;
        },
        defineLayoutFX: json => {
            const resposta = {
                ...json["DefineLayoutFX"]
            };
            return resposta;
        }
    },
    dataZerada: {
        moment: dataZeradaMoment,
        date: dataZeradaDate
    },
    mensagemUI: {
        popUp: function (type, label, message, transaction) {

            if(type === "error") {
                notificacao.erro({transacao:transaction, message: message, description: label});
            } else if(type === "warning") {
                notificacao.aviso({transacao:transaction, message: message, description: label});
            } else if(type === "success") {
                notificacao.sucesso({transacao:transaction, message: message, description: label});
            }

        }
    },
    imagemUsuarioAPI: {
        getURLDefault: () => {
            return URL_IMG_USR_DEFAULT;
        },
        getURL: async (cdPessoaUsr) => {

            if(cacheImgUsr[cdPessoaUsr] === undefined) {

                cacheImgUsr[cdPessoaUsr] = "fetching";

                const payload = {
                    acaoAExecutar: "pesqImagemUsuario",
                    acaoWebAExecutar: "ProcessarNegocioPerfil",
                    cdPessoaUsrAtual: cdPessoaUsr
                };

                const lmImg = new Date();

                let url = URL_IMG_USR_DEFAULT;

                try {

                    const resultado = await postJSON({
                        acaoWebAExecutar: "processarnegocio",
                        sistema: "suite",
                        projeto: "podperfil"
                    }, payload);

                    resultado.mensagens.forEach(mensagem => {

                        if (mensagem.id === "urlImgUsuario") {

                            if (mensagem.mensagem === "padrao") {
                            } else {
                                url = mensagem.mensagem;
                            }

                        }

                    });

                } catch (e) {

                    console.log(e);

                }

                url = url + "?" + lmImg.getTime();

                cacheImgUsr[cdPessoaUsr] = url;

                if(cacheImgUsr.deferred[cdPessoaUsr] === undefined) {
                } else {
                    cacheImgUsr.deferred[cdPessoaUsr].forEach(resolve => {
                        resolve(url);
                    });
                }

                return cacheImgUsr[cdPessoaUsr];

            } else if (cacheImgUsr[cdPessoaUsr] === "fetching") {

                if(cacheImgUsr.deferred[cdPessoaUsr] === undefined) {
                    cacheImgUsr.deferred[cdPessoaUsr] = [];
                }

                const promise = new Promise((resolve, reject) => {
                    cacheImgUsr.deferred[cdPessoaUsr].push(resolve);
                });

                const url = await promise;

                return url;

            } else {

                return cacheImgUsr[cdPessoaUsr];

            }

        }
    }
};

const smkJS = {
    ID_RESPOSTA_MODELO: "modelo",
    ID_RESPOSTA_PADRAO: "resposta",
    ID_RESPOSTA_LISTA_PESQUISA: "listaPesquisa",
    ID_RESPOSTA_EXIBEMODELO_CHWORKFLOW: "exibeModeloChWorkflow",
    ID_RESPOSTA_PKNAOENCONTRADA: "pkNaoEncontrada",
}


export {
    smkPadrao,
    smkJS,
}

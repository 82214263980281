
import {StepsForm} from "@ant-design/pro-components";

import React, {useEffect, useRef, useState} from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {campoInformado, topFunction} from "../lib/iy2b-javascript";

const IY2BProFormWizard = (props) => {

    const { passosValidos = null } = props;

    const { xs } = useBreakpoint();
    const [currentStep, setCurrentStep] = useState(0);

    const [totalSteps, setTotalSteps] = useState (0);

    const formMapRef = useRef([]);

    useEffect(() => {
        let newLength = 0;
        if(Array.isArray(props.steps) === true) {
            newLength = (props.steps.length);
        } else {
            newLength = (props.steps.props.children.length);
        }
        setTotalSteps(newLength);
    }, [props.steps]);

    const onCurrentChange = (position) => {
        topFunction();
        setCurrentStep(position);
        if(props.hasOwnProperty("onCurrentStepChange")) {
            if(Array.isArray(props.steps) === true) {
                const step = props.steps[position];
                props.onCurrentStepChange(position, step.key);
            } else {
                const step = props.steps.props.children[position];
                props.onCurrentStepChange(position, step.key);
            }
        }
    };

    const layoutVertical = (xs===true) ;
    const direction = (layoutVertical === true) ? "vertical" : "horizontal";

    const stepsRender = (steps, dom) => {
        if(passosValidos === null) return dom;
        const cacheItems = { };
        dom.props?.children?.props?.items.forEach(item => {
            cacheItems[item.key] = item;
        });
        dom.props?.children?.props?.items.clear();
        passosValidos.forEach((key, index) => {
            if(campoInformado(key) === true) {
                const currentItem = cacheItems[key];
                if(campoInformado(currentItem) === true) {
                    if(index < totalSteps) {
                        dom.props?.children?.props?.items.push(currentItem);
                    }
                }
            }
        });
        return dom;
    }

    console.log("props.formRef", props.formRef, formMapRef);

    return (
        <StepsForm
            formMapRef={formMapRef}
            formRef = { props.formRef }
            onFinish = { props.onFinishWizard }
            onCurrentChange = { onCurrentChange }
            stepsProps={{
                size: props.stepsSize,
                direction: direction
            }}
            stepsRender={stepsRender}
        >
            {props.steps}
        </StepsForm>
    );
};

export default IY2BProFormWizard;

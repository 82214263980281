//v5 import "antd/dist/antd.css";
import '../../assets/css/tema.scss';
import "./style.scss";

import React from "react";
import {Button} from "antd";
import Iframe from "react-iframe";
import ReactModal from 'react-modal';
import {useWindowSize} from "../../lib/iy2b-react";
import {onAfterClose, onAfterOpen} from "../IY2BReactModal";

const PopUpIFrame = ( props ) => {

    const windowSize = useWindowSize();

    const MARGIN_LEFT = 40;
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;

    const { name, url, exibePopUp, onRequestClose, iFrameWidth = (MODAL_CONTENT_WIDTH - 20), iFrameHeight = (windowSize.height - 100) } = props;

    const closePopUp = () => {

        onRequestClose();

    };

    return (
        <ReactModal
            isOpen={exibePopUp}
            className={"modalContent"}
            overlayClassName={"modalOverlay"}
            bodyOpenClassName={"popUpIFrameModal"}
            closeTimeoutMS={500}
            onRequestClose={closePopUp}
            style={{
                content: {
                    left: MARGIN_LEFT + 'px',
                    width: MODAL_CONTENT_WIDTH + "px"
                }
            }}
            onAfterOpen={() => {
                onAfterOpen();
            }}
            onAfterClose={() => {
                onAfterClose();
            }}
        >
            <div className={"boxIFrame"}>
                <Iframe url={url}
                        width={iFrameWidth + "px"}
                        height={iFrameHeight + "px"}
                        id={"iframe-" + name}
                        className={"iframe-peca"}
                />
            </div>
            <div className={"footer"}>
                <Button onClick={closePopUp}>
                    Fechar !
                </Button>
            </div>
        </ReactModal>
    );

}

export default PopUpIFrame;

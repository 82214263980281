//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';

import '../../../framework/assets/css/tema.scss';
import "./wizNovoUsuario.scss";

import React, {useEffect, useRef, useState} from 'react';
import {Transfer, Col, Row, Select, Form} from "antd";
import {ProForm} from "@ant-design/pro-components";

const StepAcessos = ( props ) => {

    const { dpPerfilAcesso, dpEmpresas } = props;

    const [targetKeys, setTargetKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);

    useEffect(() => {

        if(props.api) {
            props.api.current = {
                onValuesChange: onValuesChange,
                onPageShow: onPageShow,
                onFinish: onFinish
            };
        }

        if(dpEmpresas.length === 1) {

            const initialTargetKeys =  [];

            initialTargetKeys.push(dpEmpresas[0].key);

            props.formRef.current?.setFieldsValue({
                empresasComAcesso: initialTargetKeys
            });

        }

        return () => {

        };

    }, []);

    const onFinish = async () => {

        let resultado = true;

        return resultado;

    }

    const onPageShow = async () => {

    }

    const onValuesChange = async (changed, all) => {

    }

    const handleChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys( [...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    return (<div className={"containerStepAcessos"}>
        <Row gutter={[8,16]}>
            <Col xs={24}>
                <ProForm.Item
                    name="perfilAcesso"
                    label="Perfil de acesso"
                    tooltip={"Escolha o perfil de acesso deste novo usuário. Define as operações disponíveis para o usuário"}
                    rules={[
                        {required: true, message:"Informe o nível de permissão de acesso"},
                    ]}
                >
                    <Select style={{width:"100%"}}
                            labelInValue
                            placeholder={"Escolha um perfil de acesso as informações deste usuário"}
                            options={dpPerfilAcesso}
                    />
                </ProForm.Item>
            </Col>
        </Row>
        <Row gutter={[8,16]}>
            <Col xs={24}>
                <ProForm.Item
                    name="empresasComAcesso"
                    label="Seleção de empresas com permissão de acesso"
                    tooltip={"Coloque no lado direito as empresas que este novo usuário tem permissão de acesso. Define em quais empresas o usuário consegue executar as operações"}
                    valuePropName={"targetKeys"}
                    rules={[
                        {required: true, message:"Escolha ao menos uma empresa com acesso para este usuário"},
                    ]}
                >
                    <Transfer
                        dataSource={dpEmpresas}
                        titles={['Disponíveis', 'Com acesso']}
                        targetKeys={targetKeys}
                        selectedKeys={selectedKeys}
                        onChange={handleChange}
                        onSelectChange={handleSelectChange}
                        render={item => item.title}
                        oneWay
                    />
                </ProForm.Item>
            </Col>
        </Row>
    </div>);

}

export default StepAcessos;

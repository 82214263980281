//v5 import "antd/dist/antd.css";
import '../assets/css/tema.scss';
import '../assets/css/resetSenhaUsuario.scss';

import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {notificacaoErroValidForm, useQueryString, useWindowSize} from "../lib/iy2b-react";
import SinglePageLoadingIndicator from "../componentes/SinglePageLoadingIndicator";
import {Button, Col, Descriptions, Form, Input, Layout, Modal, Row} from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import {postWS} from "../lib/RequestAPI";
import Store from "../../store";
import InputMasked from "../componentes/InputMasked";
import {useSetupConfig} from "../lib/ConfigManager";
import {useHistory} from "react-router-dom";

const { Content } = Layout;

const ResetSenhaUsuario = props => {

    useSetupConfig({
        appConfig: props.appConfig,
        store: Store
    });

    const history = useHistory();
    const windowSize = useWindowSize();

    const qs = useQueryString();

    const [infoUsuario, setInfoUsuario] = useState({});

    const [protegeTela, setProtegeTela] = useState(true);

    const inputRefCPF = useRef();

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1);

        console.log("useEffect inicializa ResetSenhaUsuario");

        console.log("reset key = " + qs.get("key"));

        async function getKey () {

            const payload = {
                key: qs.get("key")
            };

            try {

                const result = await postWS({
                    sistema: "smk",
                    projeto: "wscadsolicsenhausr",
                    acaoWebAExecutar: "processarnegocio",
                    acaoAExecutar: "pesquisarKey",
                }, payload);

                setInfoUsuario(result.mensagens[0]);

                setProtegeTela(false);

            } catch (error) {

                if(error.data) {
                    if(error.data.erro === true) {
                        console.log(error.data);

                        const jsonErr = JSON.parse(error.data.descricao);

                        Modal.error({
                            title: 'Falha ao acessar',
                            content: jsonErr.descricao,
                        });

                    } else {
                        console.log(error);
                    }
                } else {
                    console.log(error);
                }

            }

        }

        getKey();

        return () => {

            console.log("final useEffect inicializa ResetSenhaUsuario");

        };

    }, []);

    console.log("windowSize " + windowSize.width + "x" + windowSize.height);

    useEffect(() => {

        if(protegeTela === false) inputRefCPF.current.focus();

    }, [protegeTela]);

    // http://localhost:3000/#/resetsenhausr?key=02670001703

    const cpfPatterns = [{
        pattern: "000.000.000-00",
        length: 11, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }];

    const onFinish = async values => {

        const payload = {
            "origin": window.location.origin,
            key: qs.get("key"),
            cpf: inputRefCPF.current.rawValue()
        };

        try {

            const result = await postWS({
                sistema: "smk",
                projeto: "wscadsolicsenhausr",
                acaoWebAExecutar: "processarnegocio",
                acaoAExecutar: "confirmarReset",
            }, payload);

            Modal.success({
                title: 'Sucesso',
                content: "Por favor verifique o seu email e acesse novamente o sistema !",
            });

            history.push('/login?email=' + infoUsuario.noLogin.trim());

        } catch (error) {

            if(error.data) {
                if(error.data.erro === true) {
                    console.log(error.data);

                    const jsonErr = JSON.parse(error.data.descricao);

                    Modal.error({
                        title: 'Falha ao acessar',
                        content: jsonErr.descricao,
                    });

                } else {
                    console.log(error);
                }
            } else {
                console.log(error);
            }

        }

    }

    const onFinishFailed = errorInfo => {
        notificacaoErroValidForm(errorInfo);
    };

    const [frmResetSenha] = Form.useForm();

    return (

        <div className="resetSenhaUsr">
            <Layout>
                <Form form={frmResetSenha} id={"frmLogin"}
                      layout={"vertical"}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                >

                <PageHeader
                    title="Solicitação de envio de nova senha de usuário"
                    footer={
                        <Col span={10} offset={14} >
                            <Button type={"primary"}
                                        key="3"
                                        htmlType="submit"
                                        disabled={protegeTela}
                                        block
                            >Confirmar envio de nova senha</Button>
                        </Col>
                    }
                    style={{
                        height: windowSize.height + "px"
                    }}
                >
                    <SinglePageLoadingIndicator />
                    <Content>
                            <Row>
                                <Col span={24} >
                                    <Descriptions
                                        bordered
                                        layout="vertical"
                                        column={4}
                                    >
                                        <Descriptions.Item label="Orientações" span={4}>
                                            Por favor confira o seu nome e seu endereço de email. Depois, informe o seu CPF e clique no botão "Confirmar envio de nova senha"
                                        </Descriptions.Item>
                                        <Descriptions.Item span={2} label="Nome">{infoUsuario.noPessoa}</Descriptions.Item>
                                        <Descriptions.Item span={2} label="E-Mail">{infoUsuario.noEMail}</Descriptions.Item>
                                        <Descriptions.Item label="Por favor informe o seu CPF" span={4}>
                                            <Col span={6}>
                                                <Form.Item
                                                    name="txtCPF"
                                                    rules={[{ required: true, message: 'Por favor, informe o seu CPF!' }, {
                                                        validator: async (rule, value) => {
                                                            if(inputRefCPF) {
                                                                const result = inputRefCPF.current.validate();
                                                                if(result.valido === false) throw new Error(result.message);
                                                            }
                                                        }
                                                    }]}
                                                >
                                                    <InputMasked api={inputRefCPF}
                                                                 patterns={cpfPatterns}
                                                                 disabled={protegeTela}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                    </Content>
                </PageHeader>
                </Form>
            </Layout>
        </div>

    );

};

const mapStateToProps = function( { appConfig } ) {

    return {
        appConfig: appConfig
    };

};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetSenhaUsuario);

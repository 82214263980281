import '../../assets/css/tema.scss';
import './style.scss';

import React, {useContext, useEffect, useImperativeHandle, useRef} from "react";
import {Alert, Button, Col, Row, Space} from "antd";
import {WORKSPACE_LOADING_INDICATOR_AREA, WorkspaceContext} from "../../pages/Workspace/workspace";
import {
    notificacaoErroRequest, notificacaoErroValidForm,
    PADRAO_MARGEM_POPUP,
    useConteudoAlterado,
    useGridWidth,
    useModoExecucao, useStateRef,
    useWindowSize
} from "../../lib/iy2b-react";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {campoInformado, formataNoRuaDoEndereco, ObjectUtils} from "../../lib/iy2b-javascript";
import {ProForm} from "@ant-design/pro-components";
import FormEnderecoEmpresa from "../../componentes/FormEnderecoEmpresa/formEnderecoEmpresa";
import IY2BReactModal from "../../componentes/IY2BReactModal";
import {useFormState} from "../../lib/useFormState";
import Promessas from "../../lib/Promessas";
import {postJSON} from "../../lib/RequestAPI";
import IY2BHiddenRow from "../../componentes/IY2BHiddenRow";
import IY2BProFormHidden from "../../componentes/IY2BProFormHidden";
import {notificacao} from "../../lib/Notificacao";
import {pesquisarCidadeDoCep} from "../../lib/Servicos";

const { ErrorBoundary } = Alert;

const EditarEndereco = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {

        };
    });

    const { exibePopUp, onRequestClose, salvar, excluir, dataProvider, noPessoa, nrCGCCIC, cdTransacao = 0 } = props;

    const { registros } = props;

    const { availableWidth } = useContext(WorkspaceContext);

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 10 : windowSize.widthMargin(PADRAO_MARGEM_POPUP.SM);
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = windowSize.heightMargin(PADRAO_MARGEM_POPUP.XL);
    const MARGIN_BOTTOM = windowSize.heightMargin(PADRAO_MARGEM_POPUP.XL); // 24;

    const modalRefApi = useRef();

    const formRef = useRef() ;
    const formState = useFormState({ });

    const formEnderecoApi = useRef();

    const forceUpdate = useForceUpdate();

    const modoExecucao = useModoExecucao("INC");
    const conteudoAlterado = useConteudoAlterado();

    const [, setRegistroDaTela, refRegistroDaTela] = useStateRef( null);
    const [, setTelaProtegida, refTelaProtegida] = useStateRef(false);

    const { loadingArea } = props;

    const { cdPessoa = 0, exibeInscricoes = true, exibeMeiosContato = true} = props;

    useEffect(() => {

        if(campoInformado(registros)) {

            if(exibePopUp) {

                if(registros.length > 0) {

                    pesquisarPk(registros[0]);

                } else {

                    novoRegistro();

                }

            }

        } else {

            if(exibePopUp) {

                novoRegistro();

            }

        }

        return () => {

        };

    }, [props.registros]);


    const pesquisarPk = async (registro) => {

        try {

            console.log("pesquisarPk", registro);

            novoRegistro();

            let registroDaTela = {
            };

            const promessas = new Promessas();

            promessas.add(async (resolve, reject) => {
                try {

                    const payload = {
                        acaoAExecutar: "acaoCRUD",
                        registro: {
                            "cdPessoa": registro.cdPessoa,
                            "nrSeqEnd": registro.nrSeqEnd
                        }
                    };

                    const resultado = await postJSON({
                        acaoWebAExecutar: "pesquisarPk",
                        sistema: "sgr",
                        projeto: "mendereco"
                    }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

                    resultado.mensagens.forEach(msg => {

                        if(msg.id === "modelo") {

                            registroDaTela = {
                                ...registroDaTela,
                                ...msg.modeloPadrao
                            };

                        }

                    });

                    resolve(true);

                } catch (e) {

                    console.log(e);

                    notificacaoErroRequest(e, cdTransacao);

                    reject (e);

                }
            });

            await promessas.run();

            console.log(registroDaTela);

            setRegistroNaTela(registroDaTela);

        } catch (e) {

            console.log(e);

            notificacaoErroRequest(e, cdTransacao);

        }

    }

    const setRegistroNaTela = (registro) => {

        console.log(registro);

        const protegeTela = false; // (registro.atendimento.dtFim.toMoment().ehDataZerada()) ? false : true;

        setTelaProtegida(protegeTela);

        const attrDaTela = [ "cdPessoa",
            "nrSeqEnd", "noBairro", "noLograd", "txNumero", "txCompl", "nrInsEstEnd", "nrInsMunicipEnd", "dtCad", "dtInicValid", "cdCidade", "noEmailNF_E", "noEmail",
            "nrTelefone", "descrInsEstEnd", "noContato", "cdRegiao", "cdTipoCalcImposto", "tpEndereco"
        ];

        const registroDaTela = {
            ... ObjectUtils.valuesFrom(registro, attrDaTela),
            nrCEP: registro.nrCep,
            noCidade: registro.noCidade.trim() + " / " + registro.ufCidade,
        }

        setRegistroDaTela ( registroDaTela );

        formRef.current?.setFieldsValue( registroDaTela );

        forceUpdate();

        controleExibicao ( registroDaTela );

        modoExecucao.setModoExecAlteracao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const controleExibicao = (registro) => {


    }

    const posicionaPrimeiroCampo = () => {

        setTimeout(() => {

            modalRefApi?.current?.bodyScrollTop();

            formEnderecoApi.current.focusCEP();

        }, 1);

    }

    const novoRegistro = () => {

        console.log("novoRegistro");

        formRef?.current?.resetFields();

        setTelaProtegida ( false ) ;

        setRegistroDaTela ({ });

        formState.clear({ });

        modoExecucao.setModoExecInclusao();

        conteudoAlterado.limpaConteudoAlterado(formRef.current);

        posicionaPrimeiroCampo();

    }

    const getRegistroDaTela = () => {

        const ffValues = formRef.current?.getFieldsValue();

        console.log(ffValues);

        const attrDaTela = [ "cdPessoa", "nrSeqEnd", "noBairro", "noLograd", "txNumero", "txCompl", "nrInsEstEnd", "nrInsMunicipEnd", "dtCad", "dtInicValid", "cdCidade", "noEmailNF_E", "noEmail", "tpEndereco" ];

        const registro = {
            ...ObjectUtils.valuesFrom(ffValues, attrDaTela),
            nrCep: ffValues.nrCEP.retiraCaracteres("-./"),
            nrTelefone: ffValues.nrTelefone?.retiraCaracteres("-() "),
            dtFimValid: "1901/01/01",
            cdRegiao: 0,
            cdTipoCalcImposto: 0,
            "flgZFranca":"N",
            "cdTribServ":"T",
            "cdRegTrib":"0",
            "cdTipoDoc":"01",
        };

        registro.nrInsEstEnd = registro.nrInsEstEnd?.toUpperCase();
        registro.nrInsMunicipEnd = registro.nrInsMunicipEnd?.toUpperCase();

        registro.nrTelefone = registro.nrTelefone?.trim().substr(0, 20);
        registro.noBairro = registro.noBairro?.trim().substr(0, 20);
        registro.txNumero = registro.txNumero?.trim().substr(0, 20);
        registro.txCompl = registro.txCompl?.trim().substr(0, 30);

        registro.noRua = formataNoRuaDoEndereco(registro);

        console.log("getRegistroDaTela", registro);

        return registro;

    }

    const validaTela = async (params) => {

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        const cidade = await pesquisarCidadeDoCep(ffValues.nrCEP.retiraCaracteres("-./"), WORKSPACE_LOADING_INDICATOR_AREA, cdTransacao);

        formRef.current?.setFieldsValue( cidade );

        return true;

    };

    const doSalvar = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return;

            if(conteudoAlterado.isConteudoAlterado() === false) {
                notificacao.aviso({message:"Sem alteração", description:"Nenhuma informação alterada para ser salva !"})
                return;
            }

            const registro = getRegistroDaTela();

            const respSalvar = await salvar (registro);

            if(respSalvar === false) return;

            onRequestClose();

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e, cdTransacao);

        }

    }

    const fiValues = {

    };

    const popupTitle = "Editar endereço";

    return (
        <ErrorBoundary>
            <IY2BReactModal
                left={MARGIN_LEFT}
                top={MODAL_CONTENT_TOP}
                width={MODAL_CONTENT_WIDTH}
                marginBottom={MARGIN_BOTTOM}
                exibePopUp={exibePopUp}
                onRequestClose={onRequestClose}
                bodyOpenClassName={"editar-atendimento"}
                title={popupTitle}
                api={modalRefApi}
                body={
                    <div className={"body-editar-atendimento"}>
                        <ProForm
                            formRef = { formRef }
                            isKeyPressSubmit = {true}
                            initialValues={fiValues}
                            onFinish = { async ( ) => {
                                if(refTelaProtegida.current === true) return;
                                await doSalvar();
                            }}
                            submitter={{
                                // Completely customize the entire area
                                render : ( props , doms ) => {
                                    return null;
                                }
                            }}
                            onValuesChange={async (changeValues, allValues) => {
                                // conteudoAlterado.fireOnValueChange(changeValues, allValues, "cmbCdTipoAtendimento", cmbCdTipoAtendimento_OnChange, true);
                                /*
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "acCentroCusto", acCentroCusto_OnChange);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "vlrDesconto", onUpdateVlrTotal);
                                conteudoAlterado.fireOnValueChange(changeValues, allValues, "flgOrcamento", flgOrcamento_OnChange);
                                conteudoAlterado.fireOnChangeFFR(changeValues, allValues, "itens", "vlrOrcPecas", onUpdateVlrTotal);
                                conteudoAlterado.fireOnChangeFFR(changeValues, allValues, "itens", "vlrOrcServ", onUpdateVlrTotal);
                                */
                            }}
                            onFieldsChange = {async (changedFields) => {
                                conteudoAlterado.updateConteudoAlterado(changedFields);
                            }}
                        >
                            <div className={"pro-form-group-spacer"}>
                                <FormEnderecoEmpresa
                                    formRef = { formRef }
                                    api = { formEnderecoApi }
                                    exibeInscricoes = { true }
                                    exibeMeiosContato = { true }
                                />
                            </div>
                        </ProForm>
                    </div>
                }
                actions={
                    <Space style={{
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <Space>
                            &nbsp;
                        </Space>
                        <Space>
                            <Button type={"primary"} className={"salvar"} onClick={async () => await doSalvar()} disabled={refTelaProtegida.current}>
                                Salvar
                            </Button>
                        </Space>
                    </Space>
                }
            />
        </ErrorBoundary>
    );

});

export default EditarEndereco;

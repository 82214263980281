import '../../assets/css/tema.scss';
import './style.scss';

import React, {useImperativeHandle} from "react";
import {Card, Descriptions} from "antd";
import {CUSTOM} from "../../assets/iconografia";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {campoInformado, dateTableCellRender, decimalTableCellRender} from "../../lib/iy2b-javascript";
import BotaoCheckBox from "../BotaoCheckBox/botaoCheckBox";



const IY2BCard = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {
        };
    });

    const forceUpdate = useForceUpdate();

    const { title, body, actions=null, extra=null } = props ;

    const {checkBody=false, record=null} = props;

    if(checkBody === true) {
        if(record.marcado === null || record.marcado === undefined) record.marcado = false;
    }

    const onClickCard = () => {
        if(checkBody === true) {
            record.marcado = ! record.marcado;
            forceUpdate ();
        }
    }

    const rootClassName = ["root-container-iy2b-card"];

    if(checkBody === true) {
        rootClassName.push("pointer");
        if(record?.marcado===true) {
            rootClassName.push("checked");
        }
    }

    return (
        <div className={rootClassName.toClassName()}>
            <Card title={title} actions={actions} extra={extra} onClick={onClickCard}>
                {body}
            </Card>
        </div>
    );

});

export default IY2BCard;

const buildCardCols = (params) => {

    const { tblCols } = params ;

    const cardCols = tblCols.filter(col => (col.card === true)).map(col => {
        const novaCol = {...col};
        if(col.hasOwnProperty("formatter")===true) {
            if(col.formatter==="data") {
                novaCol.format = "date";
                novaCol.render = (x,item) => dateTableCellRender(item[col.field]);
                novaCol.align = "center";
                novaCol.ellipsis = false;
            } else if(col.formatter==="valor") {
                const decs = col.decimais||2;
                novaCol.format = "valor";
                novaCol.render = (x,item) => decimalTableCellRender(decs)(item[col.field]);
                novaCol.align = "right";
                novaCol.ellipsis = false;
            }
        }
        return novaCol;
    });

    return cardCols;
}

const buildLabelCard = (params) => {

    const { tblCols, labelCard } = params ;

    const labelCardCols = tblCols.filter(col => (col.field === labelCard)).map(col => {
        const novaCol = {...col};
        if(col.hasOwnProperty("formatter")===true) {
            if(col.formatter==="data") {
                novaCol.format = "date";
                novaCol.render = (x,item) => dateTableCellRender(item[col.field]);
                novaCol.align = "center";
                novaCol.ellipsis = false;
            } else if(col.formatter==="valor") {
                const decs = col.decimais||2;
                novaCol.format = "valor";
                novaCol.render = (x,item) => decimalTableCellRender(decs)(item[col.field]);
                novaCol.align = "right";
                novaCol.ellipsis = false;
            }
        }
        return novaCol;
    });

    return labelCardCols;
}

const defaultItemCardRender = (params) => {

    const { cardCols, labelCard, orientationCard, cardColumns, enableRowCheck=false } = params;

    return (item, actionView) => {

        const items = cardCols.map(col => {
            const value = (campoInformado(col.render) === false) ? item[col.field] : col.render(null, item);
            return {
                key: col.field,
                label: col.noColuna,
                span: col.colSpan || 1,
                children: value
            }
        });

        const body = (
            <Descriptions layout={orientationCard} column={cardColumns}
                          items={items}
            />
        );

        const title = item[labelCard];

        const actions = [];

        if(enableRowCheck === true) {
            actions.push(<BotaoCheckBox
                key={"btnCheck" + item.key}
                registro={item}
            />);
        }
        actions.push(actionView(null, item));

        return <IY2BCard title={title}
                         body={body}
                         actions={actions}
        />;

    }


}

const buildOrdenadores = (params) => {

    const { cardCols, labelCardCols } = params;

    const ordenadores = cardCols.map(col => {
        return {
            key: col.field,
            label: col.noColuna,
            render: col.render
        }
    });

    ordenadores.addAll(labelCardCols.map(col => {
        return {
            key: col.field,
            label: col.noColuna,
            render: col.render
        }
    }));

    return ordenadores;
}


export {
    buildCardCols,
    buildLabelCard,
    defaultItemCardRender,
    buildOrdenadores
}

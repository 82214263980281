//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';

import "./style.scss";

const RowWrapper = (props) => {

    const { children, hidden=false, notExists=false, keepWidth=0, style={} } = props ;

    const divStyle = { };

    if(keepWidth > 0) {
        divStyle.minWidth = keepWidth;
        divStyle.width = "100%";
    }

    const divKeepWidth = (keepWidth === 0) ?
        null
        :
        <div style={divStyle}>&nbsp;</div>
    ;

    const className = ["iy2b-row-wrapper"];

    if(hidden===true) {
        className.push("hidden");
    }

    const element = (notExists===true)?
        {divKeepWidth}
        :<>
            <div className={className.toClassName()} style={style}>
                {children}
            </div>
            {divKeepWidth}
        </>
        ;

    return element;

}

export default RowWrapper;

//v5 import "antd/dist/antd.css";
import '../assets/css/tema.scss';
import '../assets/css/IY2BTable.scss';

import React, {useState, useEffect, useRef, useMemo} from 'react';
import { Resizable } from 'react-resizable';
import { ProTable } from "@ant-design/pro-components";
import {Input, Button, Tooltip, Menu, Alert} from 'antd';
import DataProvider from "../lib/DataProvider";
import {useStateRef} from "../lib/iy2b-react";
import {campoInformado, dateTableCellRender, decimalTableCellRender} from "../lib/iy2b-javascript";
import useForceUpdate from "antd/lib/_util/hooks/useForceUpdate";
import {useScrollPosition} from "../lib/useScrollPosition";
import {CUSTOM} from "../assets/iconografia";
import BotaoAcoesDisponiveis from "./BotaoAcoesDisponiveis/botaoAcoesDisponiveis";

const { ErrorBoundary } = Alert;

const { Search } = Input;

const ResizableTitle = props => {

    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th {...restProps} />
        </Resizable>
    );

};

const colSorter = (name, a, b) => {

    return a[name] > b[name] ? 1 : a[name] < b[name] ? -1 : 0;

};

const filterByValue = (dataSource, string) => {

    const array = (dataSource instanceof DataProvider) ? dataSource.data : dataSource ;

    return array.filter(o =>
        Object.keys(o).some(k => {
            return String(o[k]).toLowerCase().includes(string.toLowerCase());
        }));
};

const tableComponents = {
    header: {
        cell: ResizableTitle
    },
};

const IY2BTable = (props) => {

    const unknownGapHeight = 80;

    const actionRefTable = useRef(null);

    const [dataSource, setDataSource, refDataSource] = useStateRef(props.dataSource);
    const [columns, setColumns] = useState(props.columns);
    const [tableHeight, setTableHeight] = useState(props.tableHeight - unknownGapHeight);
    const [lastFilter, setLastFilter] = useState("");

    const [protegido, setProtegido] = useState(false);

    //const defaultPageSize = props.pagPageSize||50;

    const [defaultPageSize, setDefaultPageSize, refDefaultPageSize] = useStateRef(props.pagPageSize||50);
    const defaultNroPagina = 1;

    const [infoPag, setInfoPag, refInfoPag] = useStateRef({"nroPagina": defaultNroPagina, "tamPagina":refDefaultPageSize.current, "totRegistros":0, "direcao":"ASC"});

    const [dispatchRequestPage] = useState(props.hasOwnProperty("onRequestPage"));

    const { allowCheckForRow, tableTitle, infiniteScroll=false, visible=true, searchDisabled = false, paginationDisabled = false, downloadDisabled = false, configDisabled = false, enableRowCheck = false, onSelectionChange, onUpdatePageSize } = props;

    const [selectedRowKeys, setSelectedRowKeys, refSelectedRowKeys] = useStateRef([]);

    const forceUpdate = useForceUpdate();

    const refContainer = useRef();

    const {totalizacao = null} = props;

    // inicializacao, apenas na primeira vez porque os [] esta vazio
    useEffect(() => {

        console.log("useEffect inicializa IY2BTable");

        if(props.api) {
            props.api.current = {
                forceUpdate() {
                    forceUpdate();
                },
                infoPag() {
                    return refInfoPag.current;
                },
                clearData () {
                    setDataSource([]);
                    setSelectedRowKeys([]);
                    handleUpdateInfoPag({"nroPagina":defaultNroPagina, "tamPagina":refDefaultPageSize.current, "totRegistros":0, "direcao":"ASC"});
                },
                unckeckAll(ds) {
                    if(ds.hasOwnProperty("desmarca") === true) {
                        ds.desmarca();
                    } else {
                        ds.forEach(item => {
                            item.marcado = false;
                        });
                    }
                    setSelectedRowKeys([]);
                },
                uncheck(source) {
                    let upd = false;
                    source.forEach(item => {
                        const linha = refSelectedRowKeys.current.filter(row => row.key === item.key);
                        item.marcado = false;
                        if(linha.length > 0) {
                            linha.forEach(row => refSelectedRowKeys.current.removeItem(row));
                            upd = true;
                        }
                    });
                    if(upd === true) setSelectedRowKeys(refSelectedRowKeys.current.clone());
                },
                check(source) {
                    let upd = false;
                    source.forEach(item => {
                        item.marcado = true;
                        const linha = refSelectedRowKeys.current.filter(row => row.key === item.key);
                        if(linha.length === 0) {
                            refSelectedRowKeys.current.push(item.key);
                            upd = true;
                        }
                    });
                    if(upd === true) setSelectedRowKeys(refSelectedRowKeys.current.clone());
                }
            };
        }

        return () => {

            console.log("final useEffect inicializa IY2BTable");

        };

    }, []);

    const fetchNextPage = async () => {

        handleUpdateInfoPag({
            ...refInfoPag.current,
            nroPagina: refInfoPag.current.nroPagina + 1,
        });

        await props.onRequestPage();

    }

    const elAntTableBody = (infiniteScroll === true) ? refContainer.current?.querySelector(".ant-table-body") : null;

    useScrollPosition(({prevPos, currPos}) => {
        if(infiniteScroll === true) {
            if(currPos.yLimit > 0) {
                if(currPos.y > prevPos.y) { // pra frente
                    if((currPos.y/currPos.yLimit) > 0.80) {
                        if(refDataSource.current.hasNextPage === true) {
                            fetchNextPage();
                        }
                    }
                }
            }
        }
    }, elAntTableBody, [refContainer.current]);

    useEffect(() => {

        setProtegido(props.protegido);

    }, [props.protegido]);

    useEffect(() => {

        setLastFilter("");

        if(props.dataSource instanceof DataProvider) {

            dataSource.clear();

            setDataSource(props.dataSource);

            handleUpdateInfoPag({
                ...infoPag,
                totRegistros: props.dataSource.total,
                nroPagina: props.dataSource.page,
                tamPagina: (props.dataSource.pageSize===0)?refDefaultPageSize.current:props.dataSource.pageSize
            });

        } else {

            setDataSource(props.dataSource);

        }

        const selected = [];

        props.dataSource.forEach(item => {
           if(item.marcado === true) {
               selected.push(item.key);
           }
        });

        setSelectedRowKeys(selected);

    }, [props.dataSource]);

    useEffect(() => {

        setColumns(props.columns.map(item => {

            const col = { ...item };

            if(col.hasOwnProperty("align")===true) {
                col.align = col.align.replace("text-", "");
            }
            if(col.hasOwnProperty("noColuna")===true) {
                col.title = col.noColuna;
                if(campoInformado(col.align) === false) col.align = "left";
                col.ellipsis = true;
            }
            if(col.hasOwnProperty("field")===true) {
                col.dataIndex = col.field;
                col.key = col.field;
            }
            if(col.hasOwnProperty("formato")===true) {
                if(col.hasOwnProperty("formatter")===false) {
                    col.formatter = col.formato;
                }
            }
            if(col.hasOwnProperty("formatter")===true) {
                if(col.formatter==="data") {
                    col.format = "date";
                    col.render = dateTableCellRender;
                    col.align = "center";
                    col.ellipsis = false;
                } else if(col.formatter==="valor") {
                    const decs = campoInformado(col.decimais)===true?col.decimais:2;
                    col.format = "valor";
                    col.render = decimalTableCellRender(decs);
                    col.align = "right";
                    col.ellipsis = false;
                }
            }
            if(col.hasOwnProperty("labelFunction")===true) {
                col.render = col.labelFunction;
            }
            return col;
        }));

    }, [props.columns]);

    useEffect(() => {

        setTableHeight(props.tableHeight - unknownGapHeight);

    }, [props.tableHeight]);

    const handleUpdateInfoPag = (args) => {
        if(args.tamPagina !== refInfoPag.current?.tamPagina) {
            if(campoInformado(onUpdatePageSize) === true) {
                onUpdatePageSize ( {...args} );
            }
        }
        setInfoPag(args);
    };

    const handleResize = index => (e, { size }) => {

        const nextColumns = [...columns];

        nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
        };

        setColumns(nextColumns);

    };

    columns.forEach((col, index) => {

        col.onHeaderCell = column => ({
            width: column.width,
            onResize: handleResize(index)
        });

        if(col.tableOrder === false) {

        } else {

            col.sortDirections = ['descend', 'ascend'];

            col.sorter = (a, b) => colSorter(col.dataIndex, a, b);

        }

    });

    let tableClassName = "iy2b-table";

    const toolbarActions = [];

    if(campoInformado(props.formularioDeFiltro) === true) {

        toolbarActions.push(props.formularioDeFiltro);

        tableClassName += " with-filter-button";

    }

    if(props.customActions != null && props.customActions != undefined) {

        toolbarActions.addAll(props.customActions);

    }

    const executaAcaoBotaoAcoesDisponiveis = ( item ) => {

        if(item.key === "downloadTable") {
            props.onDownload();
        } else {
            props.botaoAcoesDisponiveis.onClickMenu ( item );
        }

    }

    if(campoInformado(props.botaoAcoesDisponiveis) === true) {

        const acoesLocais = [];
        acoesLocais.addAll( props.botaoAcoesDisponiveis.itensMenuAcoes );

        if(downloadDisabled === false) {
            if (campoInformado(props.onDownload) === true) {
                acoesLocais.push({
                    key: "downloadTable",
                    label: "Exportar dados",
                    icon: CUSTOM.CLOUD_ARROW_DOWN_ICON,
                });
            }
        }

        const menuAcoes = (
            <Menu onClick={executaAcaoBotaoAcoesDisponiveis}
                  items={acoesLocais}
            />
        );

        toolbarActions.push(
            <BotaoAcoesDisponiveis iy2bTable
                menuAcoes={menuAcoes}
                disabled={props.botaoAcoesDisponiveis.disabled}
            />
        );

        tableClassName += " with-acoes-disponiveis";

    } else {

        if(downloadDisabled === false) {

            if(campoInformado(props.onDownload) === true) {

                toolbarActions.push(
                    <Tooltip title="Exportar dados" color={"black"} key={"download"}>
                        <Button
                            type="text"
                            onClick={() => {
                                props.onDownload();
                            }}
                            className="action-button"
                            icon={CUSTOM.CLOUD_ARROW_DOWN_ICON}
                        >
                        </Button>
                    </Tooltip>
                );

                tableClassName += " with-download";

            }
        }

        if(campoInformado(props.onRefresh) === true) {

            toolbarActions.push(
                <Tooltip title="Atualizar dados" color={"black"} key={"refresh"}>
                    <Button
                        type="text"
                        onClick={() => {
                            props.onRefresh();
                        }}
                        className="action-button"
                        icon={CUSTOM.SYNC_ALT_ICON_BLACK_FIXED}
                    >
                    </Button>
                </Tooltip>
            );

            tableClassName += " with-refresh";

        }

    }

    // const dataSourceEhDataProvider = (dataSource instanceof DataProvider);

    // const tableDataSource = (dataSourceEhDataProvider === true)
    //    ? dataSource.data.clone()
    //    : dataSource.clone() ;

    const getCurrentDS = () => {

        const dataSourceEhDataProvider = (dataSource instanceof DataProvider);

        const tableDataSource = (dataSourceEhDataProvider === true)
            ? dataSource.data.clone()
            : dataSource.clone() ;

        return tableDataSource;

    }

    const tableDataSource = getCurrentDS();

    const paginationConfig =
        (infiniteScroll === true) ?
            {
                defaultPageSize: 999999999,
                hideOnSinglePage: false,
                showSizeChanger: false,
                total: infoPag.totRegistros,
                showTotal: total => tableDataSource.length + " de " + total + " regs",
            }
        :
            (paginationDisabled === false ) ?
                {
                    defaultPageSize: refDefaultPageSize.current,
                    hideOnSinglePage: false,
                    showSizeChanger: true,
                    pageSizeOptions:[10, 20, 50, 100, 250],
                    total: infoPag.totRegistros,
                    showTotal: total => " de " + total + " regs",
                    onChange(page, pageSize) {
                        if((page !== refInfoPag.current.nroPagina) || (pageSize !== refInfoPag.current.tamPagina)) {
                            setDefaultPageSize(pageSize);
                            handleUpdateInfoPag({
                                ...infoPag,
                                nroPagina: page,
                                tamPagina: pageSize
                            });
                            if(page > 0 && refInfoPag.current.totRegistros > 0) {
                                if(dispatchRequestPage === true) {
                                    const tamPagDiferente = (pageSize !== infoPag.tamPagina);
                                    if(tamPagDiferente) {
                                        console.log(tamPagDiferente, page);
                                        if(page === 1) {
                                            props.onRequestPage();
                                        }
                                    } else {
                                        props.onRequestPage();
                                    }
                                }
                            }
                        }
                    }
                }
            :
                false
    ;

    const classNameTableTitle = ["table-title"];

    if(campoInformado(tableTitle) === false) classNameTableTitle.push("not-visible");

    if(visible === false) tableClassName += " not-visible";

    const headerToolBar = {
        search: (
            <div className={"box-search-title"}>
                <Search className={"iy2b-table-search-box"}
                    allowClear
                    size="middle"
                    onSearch={(value) => {
                        const resultado = filterByValue(props.dataSource, value);
                        setDataSource(resultado);
                    }}
                    value={lastFilter}
                    onChange={(e) => {
                        setLastFilter(e.target.value);
                    }}
                    enterButton={true}
                    placeholder="Pesquise na tabela"
                    disabled={protegido}
                />
                <div className={classNameTableTitle.toClassName()}>{tableTitle}</div>
            </div>
        ),
        actions: toolbarActions
    };

    if(searchDisabled === true) {
        headerToolBar.search = null;
    }

    let tableSummary = null;

    if(campoInformado(totalizacao) === true) {

        tableSummary = (pageData) => {

            const summaryData = totalizacao.totalizar(pageData);

            return totalizacao.render(summaryData);

        }

    }

    let elemTable =
        <ProTable {...props}
            className={tableClassName}
            components={tableComponents}
            dataSource={tableDataSource}
            columns={columns}
            pagination={paginationConfig}
            bordered={true}
            scroll={{ y: tableHeight }}
            size="small"
            summary={tableSummary}
            /* pro table */
            actionRef={actionRefTable}
            manualRequest={true}
            rowKey="key"
            options={{
              search: (protegido === false),
              fullScreen: false,
              reload: false,
              density: false,
              setting: (configDisabled===false)
            }}
            search={false}
            toolbar={headerToolBar}
        >
        </ProTable>
    ;

    if (enableRowCheck===true) {

        const rowSelection = {
            type: "checkbox",
            selectedRowKeys: refSelectedRowKeys?.current||[],
            onChange: (selectedRowKeys, selectedRows) => {
                const tableDataSource = getCurrentDS();
                tableDataSource.desmarca();
                selectedRows.forEach(registro => registro.marcado = true);
                setSelectedRowKeys(selectedRowKeys);
                if(campoInformado(onSelectionChange)===true) {
                    onSelectionChange(selectedRows);
                }
            },
            getCheckboxProps: (record) => {
                const disabled = (campoInformado(allowCheckForRow) === true) ? (allowCheckForRow(record) === false) : false;
                return {
                    disabled: disabled
                }
            }
        };

        elemTable =
            <ProTable {...props}
                      className={tableClassName}
                      components={tableComponents}
                      dataSource={tableDataSource}
                      columns={columns}
                      pagination={paginationConfig}
                      bordered={true}
                      scroll={{ y: tableHeight }}
                      size="small"
                      rowSelection={rowSelection}
                      summary={tableSummary}
                /* pro table */
                      actionRef={actionRefTable}
                      manualRequest={true}
                      rowKey="key"
                      options={{
                          search: (protegido === false),
                          fullScreen: false,
                          reload: false,
                          density: false,
                          setting: (configDisabled===false)
                      }}
                      search={false}
                      toolbar={headerToolBar}
            >
            </ProTable>
        ;

    }

    return (
        <div ref={refContainer}>
            <ErrorBoundary>
                {elemTable}
            </ErrorBoundary>
        </div>
    );

};

export default IY2BTable;



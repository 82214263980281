import '../../assets/css/tema.scss';
import './style.scss';

import React, {useState, useEffect, useRef, useImperativeHandle, useContext} from 'react';
import {ProForm} from '@ant-design/pro-form' ;
import {Button, Card, Col, Row, Alert} from "antd";

import {campoInformado} from "../../lib/iy2b-javascript";
import {CUSTOM, FILTER_ICON_PRIMARY} from "../../assets/iconografia";
import {onAfterClose, onAfterOpen} from "../IY2BReactModal";
import {useWindowSize} from "../../lib/iy2b-react";
import ReactModal from "react-modal";
import {TransacaoContentContext} from "../../pages/TransacaoContent";

const { ErrorBoundary } = Alert;

const iconUp = CUSTOM.CHEVRON_UP_ICON_GREEN;
const iconDown = CUSTOM.CHEVRON_DOWN_ICON_GREEN;
const iconFilter = FILTER_ICON_PRIMARY;

const FormFiltroPopUp = React.forwardRef((props, ref) => {

    useImperativeHandle(ref, () => {
        return {
            submitFilter: async () => {
                await efetuarPesquisa();
            },
            setExibeFiltro: (value) => {
                setExibeFiltro( value );
                if(props.hasOwnProperty("onExibeFiltro")) {
                    props.onExibeFiltro( value );
                }
            }
        };
    });

    const windowSize = useWindowSize();

    const { showOnFirstInput, initialValues, onValuesChange, onFieldsChange, apresentaFiltro } = props;

    const { minFieldsHeight = 200 } = props;

    const [exibeFiltro, setExibeFiltro] = useState(false);

    const [alreadyShown, setAlreadyShown] = useState(false);

    if(showOnFirstInput === true) {
        if(exibeFiltro === undefined || exibeFiltro === false) {
            if(alreadyShown === false) {
                setExibeFiltro (true);
                setAlreadyShown ( true);
            }
        }
    }

    const [exibeExtra, setExibeExtra] = useState(false);

    const [opcoesAplicadas, setOpcoesAplicadas] = useState(0);
    const [backdropHeight, setBackdropHeight] = useState(0);
    const [topModal, setTopModal] = useState(80 + 5);
    const [fieldsHeight, setMyFieldsHeight] = useState(200);

    const { getCurrentTransacao } = useContext(TransacaoContentContext);

    const setFieldsHeight = ( value ) => {

        if(value > minFieldsHeight) {
            setMyFieldsHeight(value);
        } else {
            setMyFieldsHeight(minFieldsHeight);
        }

    }

    const calcAvailableHeight = () => {

        const MAX_FilterHeight = (windowSize.height||0) - (125 + 46 + 64 + 32 + 32);

        let myHeight = 10;

        const classNameContent = "filtro-trn-" + getCurrentTransacao();

        const modalContentPopUp = document.getElementsByClassName(classNameContent)[0];

        const elFiltroElements = modalContentPopUp?.getElementsByClassName("filtroElements");

        if(campoInformado(elFiltroElements)) {
            const elFieldsFilter = elFiltroElements[0]?.getElementsByClassName("fields-filter");
            if(campoInformado(elFieldsFilter)) {
                const elDefaultElements = elFieldsFilter[0]?.getElementsByClassName("defaultElements");
                myHeight += elDefaultElements[0]?.clientHeight;
                if(campoInformado(props.extra) === true) {
                    const elRowAlterna = elFieldsFilter[0]?.getElementsByClassName("rowAlternaExtra");
                    myHeight += (elRowAlterna[0]?.clientHeight + 20);
                    const elExtaElements = elFieldsFilter[0]?.getElementsByClassName("extraElements");
                    if(campoInformado(elExtaElements)) {
                        myHeight += (elExtaElements[0]?.clientHeight + 20);
                        if(exibeExtra === true) {
                            myHeight += 40;
                        }
                    } else {
                        console.log("elExtaElements vazio");
                    }
                }
            } else {
                console.log("elFieldsFilter vazio");
            }
            if(myHeight > MAX_FilterHeight) {
                myHeight = MAX_FilterHeight;
            }
        } else {
            console.log("elFiltroElements vazio");
        }

        return myHeight;

    }

    useEffect(() => {

        const height = document.getElementById('containerContent').clientHeight;

        // gap do tabHeader = 58
        // gap do botao de alternar o filtro
        setBackdropHeight(height - 58 - 32);

        const tabContentHeader = document.getElementById("containerContent").getElementsByClassName("ant-tabs-nav")[0];

        setTopModal(80 + tabContentHeader.clientHeight + 5);

        const newFilterHeight = calcAvailableHeight();

        setFieldsHeight(newFilterHeight);

    }, []);

    useEffect(() => {

        if(apresentaFiltro === null || apresentaFiltro === undefined) {

        } else {

            setExibeFiltro( apresentaFiltro );

            if(props.hasOwnProperty("onExibeFiltro")) {
                props.onExibeFiltro( apresentaFiltro );
            }

        }

        return () => {
        };

    }, [apresentaFiltro]);

    useEffect(() => {

        const newFilterHeight = calcAvailableHeight();

        if(newFilterHeight > 0) {
            setFieldsHeight(newFilterHeight);
        }

        return () => {
        };

    }, [windowSize]);

    useEffect(() => {

        const newFilterHeight = calcAvailableHeight();

        if(newFilterHeight > 0) {
            setFieldsHeight(newFilterHeight);
        }

        return () => {
        };

    }, [minFieldsHeight]);

    const alternatExibeFiltro = () => {

        setNovoRegistroAoFechar (false);

        const value = (!exibeFiltro) ;

        setExibeFiltro( value );

        if(props.hasOwnProperty("onExibeFiltro")) {
            props.onExibeFiltro( value );
        }

    }

    const updateFieldsHeight = () => {

        setTimeout(() => {

            const newFilterHeight = calcAvailableHeight();

            if(newFilterHeight > 0) {
                setFieldsHeight(newFilterHeight);
            }

        }, 450);

    }

    useEffect(() => {

        const newFilterHeight = calcAvailableHeight();

        if(newFilterHeight > 0) {
            setFieldsHeight(newFilterHeight);
        }

        return () => {
        };

    }, [exibeExtra]);

    const alternaExibeExtra = () => {

        setExibeExtra (! exibeExtra);

        // updateFieldsHeight();

    }

    const cancelarPesquisa = () => {

        setExibeFiltro( false );

        if(props.hasOwnProperty("onExibeFiltro")) {
            props.onExibeFiltro(false);
        }

    }

    const limparCampos = () => {
        props.formRef?.current?.resetFields();
    }

    const [novoRegistroAoFechar, setNovoRegistroAoFechar] = useState(false);

    const novoRegistro = () => {

        setNovoRegistroAoFechar (true);

        cancelarPesquisa();

        // props.onNovoRegistro();

    }

    const efetuarPesquisa = async () => {

        try {

            const resultado = await props.formRef?.current?.validateFields();

            if(campoInformado(props.validaCamposFiltro)) {

                const validado = await props.validaCamposFiltro(resultado);

                if(validado === false) return;

            }

            setOpcoesAplicadas(Object.keys(resultado).length);

            props.formRef?.current?.submit();

        } catch (e) {

            // não validou, logo, não faz pesquisa

            console.log(e);

        }

    }

    const labelAlternaExtra = exibeExtra ? "recolhe" : "mais opções";
    const iconAlternaExtra = exibeExtra ? iconUp : iconDown;

    const classesExtraContainer = ["extraContainer"];

    if(exibeExtra) {
        classesExtraContainer.push("exibe");
    } else {
        classesExtraContainer.push("nao-exibe");
    }

    const labelBotaoExibeFiltro = (opcoesAplicadas > 0 ) ? "Opções de filtro aplicadas" : "Aplicar opções de filtro";

    const classesContainerFiltro = ["containerFiltro"];
    const classesBackdropFiltro = ["backdropFiltro"];

    if(exibeFiltro) {
        classesContainerFiltro.push("exibe");
        classesBackdropFiltro.push("exibe");
    } else {
        classesContainerFiltro.push("nao-exibe");
        classesBackdropFiltro.push("nao-exibe");
    }

    /*
XS=104px for short numbers, short text or options.
S=216px for shorter field entries, such as name, phone, ID, etc.
M=328px Standard width, suitable for most field lengths.
L=440px Suitable for longer field entries, such as long URLs, tag groups, file paths, etc.
XL=552px
     */
    const popUpWidth = (campoInformado(props.width)===true) ?
        (
            (props.width === "xs") ? 104 + 200:
            (props.width === "sm") ? 216 + 200:
            (props.width === "md") ? 328 + 200:
            (props.width === "lg") ? 440 + 200:
            (props.width === "xl") ? 552 + 200:
            (props.width === "xxl") ? 660 + 200:
            parseInt(props.width)
        )
        : (windowSize.width * 0.75) ;

    const popUpLeft = (((windowSize.width - popUpWidth) / 3) * 2);

    const reactModalClassName = ["modalContent", "filtro-trn-" + getCurrentTransacao()];

    const botaoFiltrarClassName = ["botaoFiltrar"];

    return (
        <div className={"formFiltroContainer"}>
            <Button className={botaoFiltrarClassName.toClassName()} type={"text"} onClick={() => alternatExibeFiltro()}>
                Filtros&nbsp;{iconFilter}
            </Button>
            <ReactModal
                isOpen={exibeFiltro}
                className={reactModalClassName.toClassName()}
                overlayClassName={"modalOverlay"}
                bodyOpenClassName={"formFiltroPopUpModal"}
                closeTimeoutMS={500}
                onRequestClose={(e) => cancelarPesquisa()}
                style={{
                    content: {
                        top: topModal + 'px',
                        left: popUpLeft + "px",
                        width: popUpWidth + "px"
                    }
                }}
                onAfterOpen={() => {
                    onAfterOpen();
                    props.onAfterOpen();
                    updateFieldsHeight();
                }}
                onAfterClose={() => {
                    onAfterClose();
                    if(novoRegistroAoFechar === true) props.onNovoRegistro();
                }}
            >
                <ErrorBoundary>
                    <Card className={"filtroElements"} bordered={false}>
                        <div className={"header"}>
                            {labelBotaoExibeFiltro}
                        </div>
                        <ProForm
                            formRef = { props.formRef }
                            isKeyPressSubmit = {true}
                            autoFocusFirstInput = {true}
                            onFinish = { async ( ) => {
                                setExibeFiltro( false );
                                if(props.hasOwnProperty("onExibeFiltro")) {
                                    props.onExibeFiltro(false);
                                }
                                props.onFilter();
                            }}
                            layout = { props.layout }
                            submitter={{
                                // Completely customize the entire area
                                render : ( props , doms ) => {
                                    return null;
                                }
                            }}
                            initialValues = { initialValues }
                            onValuesChange = { onValuesChange }
                            onFieldsChange = { onFieldsChange }
                        >
                            <div className={"fields-filter"} style={{ height: fieldsHeight }}>
                                <div className={"defaultElements"}>
                                    { props.children }
                                </div>
                                {campoInformado(props.extra)?
                                    <Row gutter={[8, 16]} className={"rowAlternaExtra"}>
                                        <Col xs={{span:12}} md={10} lg={8}>
                                            <Button type={"text"} className={"alternaExtra"} onClick={() => alternaExibeExtra()}>
                                                {labelAlternaExtra}&nbsp;{iconAlternaExtra}
                                            </Button>
                                        </Col>
                                    </Row>
                                :null}
                                {campoInformado(props.extra)?
                                    <div className={classesExtraContainer.toClassName()}>
                                        <div className={"extraElements"}>
                                            { props.extra }
                                        </div>
                                    </div>
                                :null}
                            </div>
                            <Row gutter={[8, 16]} className={"rowAcoesFiltro"}>
                                <Col xs={{span:12}} md={{span:7}} lg={{span:5}}>
                                    <Button type={"primary"} danger className={"cancelar"} onClick={() => cancelarPesquisa()}>
                                        Fechar
                                    </Button>
                                </Col>
                                {(props.novoRegistro===true)?<Col xs={{span:12}} md={{span:7}} lg={{span:5}}>
                                    <Button type={"text"} className={"novoRegistro"} onClick={() => novoRegistro()}>
                                        Adicionar
                                    </Button>
                                </Col>:null}
                                <Col xs={{span:12}} md={{span:7}} lg={{span:5}}>
                                    <Button type={"text"} className={"limpar"} onClick={() => limparCampos()}>
                                        Limpar
                                    </Button>
                                </Col>
                                <Col xs={{span:12}} md={{span:7}} lg={{span:5}}>
                                    <Button type={"primary"} className={"pesquisar"} onClick={() => efetuarPesquisa()}>
                                        Pesquisar
                                    </Button>
                                </Col>
                            </Row>
                        </ProForm>
                    </Card>
                </ErrorBoundary>
            </ReactModal>
        </div>
    );

});

export default FormFiltroPopUp;

import Cookies from 'universal-cookie';
import { postJSON } from "../lib/RequestAPI";
import {useUserSession} from "../lib/ConfigManager";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../pages/Workspace/workspace";
import {SerializadorEventos} from "../lib/serializadorEventos";

const URL_PERFIL_SEM_FOTO = (process.env.NODE_ENV === 'development') ?
    window.location.protocol + "//" + window.location.host + "/api/systemakers/assets/images/perfil-sem-foto.png"
    : "https://erp.iy2b.com/systemakers/assets/images/perfil-sem-foto.png";

const SET_USER_SESSION = 'smk/usersession/SET_USER_SESSION';
const GET_USER_SESSION = 'smk/usersession/GET_USER_SESSION';
const CLEAR_USER_SESSION = 'smk/usersession/CLEAR_USER_SESSION';
const SET_EMPRESA_ATUAL_DA_SESSAO = 'smk/usersession/SET_EMPRESA_ATUAL';
const UPDATE_PROFILE_IMAGE = 'smk/usersession/UPDATE_PROFILE_IMAGE';
const UPDATE_INFO_COMPLEMENTAR = 'smk/usersession/UPDATE_INFO_COMPLEMENTAR';
const UPDATE_INFO_PERFIL = 'smk/usersession/UPDATE_INFO_PERFIL';
const UPDATE_CHAVE_POST = 'smk/usersession/UPDATE_CHAVE_POST';

const cookies = new Cookies( process.env.REACT_APP_NAME + "/session");

const initialState = {
    dPessoaEmpAtual: null,
    cdPessoaUsr: null,
    flgAlterSenha: "N",
    flgDebug: "N",
    flgLogin2FA: "N",
    flgSenhaExpirada: "N",
    loginHash: null,
    noEmail: "",
    noLogin: "",
    noPessoa: "",
    nrSeqAudit: null,
    nrTelCelular: null,
    tpUsuario: "N",
    txCP: null,
    txConfig: "desenv",
    urlFoto: URL_PERFIL_SEM_FOTO + "?1633982206170",
    planoContratado: { flgGratuito:"S", flgUsrMaster:"N"},
    master: "N",
    nivelAcesso: 999,
    conectado: false,
    flgPessoaEmpMaster: false
};

const getPlanoAtual = (cdPessoaEmpAtual, planos) => {

    const planoAtual = planos.filter(plano => {
        return (plano.cdPessoaEmp === cdPessoaEmpAtual)
    });

    if(planoAtual.length === 0) {
        return {
            planoContratado: { flgGratuito:"S", flgUsrMaster:"N"},
            master:  "N"
        }
    } else {
        return {
            planoContratado: planoAtual[0],
            master: planoAtual[0].flgUsrMaster
        }
    }
}

export default function userSession (state = initialState, action) {

    let newState = {...state};

    const agora = new Date();
    let  expires;

    switch (action.type) {

        case UPDATE_CHAVE_POST:

            expires = agora.adiciona(1, 'd');

            const newCookieValueChavePost = {
                ...newState,
                txCP: action.payload,
                expires: expires
            };

            console.log("newCookieValueChavePost", newCookieValueChavePost);

            cookies.set("session", newCookieValueChavePost);

            return newCookieValueChavePost;

        case UPDATE_INFO_PERFIL:

            expires = agora.adiciona(1, 'd');

            const newCookieValueInfoPerfil = {
                ...newState,
                ...action.payload,
                expires: expires
            };

            cookies.set("session", newCookieValueInfoPerfil);

            return newCookieValueInfoPerfil;

        case UPDATE_INFO_COMPLEMENTAR:

            expires = agora.adiciona(1, 'd');

            const newCookieValueInfoComplAtu = {
                ...newState,
                ...action.payload,
                expires: expires
            }

            cookies.set("session", newCookieValueInfoComplAtu);

            return newCookieValueInfoComplAtu;

        case SET_EMPRESA_ATUAL_DA_SESSAO:

            expires = agora.adiciona(1, 'd');

            newState.cdPessoaEmpAtual = action.payload.data.pessoaEmp.cdPessoa;

            const newCookieValueEmpAtu = {
                ...newState,
                ...getPlanoAtual(newState.cdPessoaEmpAtual, newState.planos),
                expires: expires
            };

            newCookieValueEmpAtu.flgPessoaEmpMaster = (newCookieValueEmpAtu.planoContratado?.cdPessoaEmpMaster === newCookieValueEmpAtu.cdPessoaEmpAtual);

            cookies.set("session", newCookieValueEmpAtu);

            return newCookieValueEmpAtu;

        case CLEAR_USER_SESSION:

            cookies.remove("session");

            newState = { };

            newState = {...initialState};

            return newState;

        case UPDATE_PROFILE_IMAGE:

            expires = agora.adiciona(1, 'd');

            newState.urlFoto = action.payload.urlFoto;

            const newCookieValue = {
                ...newState,
                expires: expires
            };

            cookies.set("session", newCookieValue);

            return newState;

        case GET_USER_SESSION:

            const currentSession = cookies.get("session");

            if(currentSession != null && currentSession != undefined) {

                newState = {
                    ...currentSession,
                };

            }

            return newState;

        case SET_USER_SESSION:

            expires = agora.adiciona(1, 'd');

            newState = action.payload;

            newState.conectado = true;

            const newCookieSession = {
                ...newState,
                expires: expires
            };

            cookies.set("session", newCookieSession);

            return newState;

        default:
            return state;

    }

};

// Action Creators

export function atualizarInfoPerfilUsr (perfil) {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {

            dispatch({
                type: UPDATE_INFO_PERFIL,
                payload: perfil
            });

            resolve(getState().userSession);

        });

    }

}


export function atualizarInfoComplUsr () {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {

            const newValue = {
                planos: []
            };

            // infoUsuario

            postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "smk",
                projeto: "minfousuariobuild",
                rest: true
            }, {
                "acaoAExecutar": "pesqInfoUsr"
            }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultado => {

                resultado.mensagens.forEach(msg => {

                    if(msg.id === "infoUsr") {

                        newValue.planos.addAll(msg.modeloPadrao.planos)

                        newValue.noApelido = msg.modeloPadrao.noApelido;

                    } else {

                        console.log(msg);

                    }

                });

                const planoAtual = getPlanoAtual(getState().userSession.cdPessoaEmpAtual, newValue.planos);

                newValue.planoContratado = planoAtual.planoContratado;
                newValue.master = planoAtual.master;

                dispatch({
                    type: UPDATE_INFO_COMPLEMENTAR,
                    payload: newValue
                });

                resolve(getState().userSession);

            }, (error) => {

                console.log(error);

                reject(error);

            });

        });

    }

}

export function updateChavePost(txCP) {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {

            dispatch({
                type: UPDATE_CHAVE_POST,
                payload: txCP
            });

            const newUserSession = getState().userSession;

            console.log(newUserSession);

            useUserSession(newUserSession);

            resolve(newUserSession);

        });

    };

}

export function setSession(userSession) {

    return (dispatch, getState) => {

        return new Promise((resolve, reject) => {

            useUserSession(userSession);

            const serializador = new SerializadorEventos();

            serializador.adicionaDependencia("fotoPerfil");

            serializador.adicionaDependencia("infoUsr");

            serializador.setCallback(() => {

                const planoAtual = getPlanoAtual(userSession.cdPessoaEmpAtual, userSession.planos);

                userSession.planoContratado = planoAtual.planoContratado;
                userSession.master = planoAtual.master;

                console.log("finaliza inicializa setSession - I", userSession);

                dispatch({
                    type: SET_USER_SESSION,
                    payload: userSession
                });

                resolve(getState().userSession);

            });

            // 1 foto

            postJSON({
                acaoWebAExecutar: "processarnegocio",
                sistema: "suite",
                projeto: "podperfil"
            }, {
                "acaoAExecutar": "pesqImagemUsuario",
                "acaoWebAExecutar": "ProcessarNegocioPerfil"
            }).then(resultado => {

                resultado.mensagens.forEach(msg => {

                    if(msg.id === "urlImgUsuario") {

                        /*
                        id: "urlImgUsuario"
                        mensagem: "http://erp.iy2b.com:80/systemakers/repositorio/sistema/suite/asp-dmpub/usuario/foto/2.png"
                        */

                        /*
                        id: "urlImgUsuario"
                        mensagem: "padrao"
                        ==> https://erp.iy2b.com/systemakers/assets/images/perfil-sem-foto.png?1633982206170
                         */

                        const agora = new Date();

                        if(msg.mensagem === "padrao") {
                            userSession.urlFoto = URL_PERFIL_SEM_FOTO + "?" + agora.getTime() ;
                        } else {
                            userSession.urlFoto = msg.mensagem + "?" + agora.getTime();
                        }

                    } else {

                        console.log(msg);

                    }

                });

                serializador.concluiDependencia("fotoPerfil");

            }, (error) => {

                serializador.removeDependencia("fotoPerfil");

                console.log(error);

                reject(error);

            });

            // 2 - infoUsuario

            postJSON({
                acaoWebAExecutar: "pesquisar",
                sistema: "smk",
                projeto: "minfousuariobuild",
                rest: true
            }, {
                "acaoAExecutar": "pesqInfoUsr"
            }, WORKSPACE_LOADING_INDICATOR_AREA).then(resultado => {

                resultado.mensagens.forEach(msg => {

                    if(msg.id === "infoUsr") {

                        userSession.planos = [];

                        userSession.planos.addAll(msg.modeloPadrao.planos)

                        userSession.noApelido = msg.modeloPadrao.noApelido;

                    } else {

                        console.log(msg);

                    }

                });

                serializador.concluiDependencia("infoUsr");

            }, (error) => {

                serializador.removeDependencia("infoUsr");

                console.log(error);

                reject(error);

            });

        });

    };

}

export function getSession() {

    return (dispatch, getState) => {

        dispatch({
            type: GET_USER_SESSION
        });

        return Promise.resolve(getState().userSession);

    };

}

export function clearSession() {

    return (dispatch, getState) => {

        dispatch({
            type: CLEAR_USER_SESSION
        });

        return Promise.resolve(getState().userSession);

    };

}

export function updateProfileImage(urlFoto) {

    return (dispatch, getState) => {

        const agora = new Date();

        dispatch({
            type: UPDATE_PROFILE_IMAGE,
            payload: {
                urlFoto: urlFoto + "?" + agora.getTime()
            }
        });

        return Promise.resolve(getState().userSession);

    };

}

export {
    SET_EMPRESA_ATUAL_DA_SESSAO,
    UPDATE_CHAVE_POST
}

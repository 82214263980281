//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';
import "./style.scss";

import BotaoToolTip from "../BotaoToolTip/botaoToolTip";
import React from "react";
import {PENCIL_ICON_PRIMARY} from "../../assets/iconografia";

const iconPencil = PENCIL_ICON_PRIMARY;

const BotaoEditarRegistroTabela = (props) => {

    const { tooltip, registro, editarRegistro } = props;

    return (
        <BotaoToolTip className={"botao-editar-registro"}
            tooltip={tooltip}
            icon={iconPencil}
            onClick={() => {
                registro.marcado = true;
                editarRegistro(registro);
            }}
        />
    )

}

export default BotaoEditarRegistroTabela;

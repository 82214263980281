import {skinPrimaryColor} from "./css/skins/StyleSkin";
import React from "react";
import fontawesome from "@fortawesome/fontawesome";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {
    faAddressCard,
    faArrowAltCircleRight, faBell as farBell, faDotCircle, faCreditCard,
    faBuilding, faCheckCircle, faAddressBook, faMessage, faImage,
    faCopy, faCalendarCheck, faComments,
    faEye, faFileCode, faIdCard, faMinusSquare, faPlusSquare, faSquare, faStar as farStar, faRectangleList,
    faTrashAlt, faQuestionCircle as farQuestionCircle, faArrowAltCircleDown, faCircleQuestion, faPenToSquare, faRectangleXmark, faCircleXmark
} from "@fortawesome/free-regular-svg-icons";


import {
    faArchive, faArrowDown, faArrowLeftLong, faArrowRight, faArrowRightLong, faCaretDown, faEllipsisVertical, faTableCellsLarge,
    faBan, faBars, faChalkboardTeacher, faChartPie, faRightToBracket, faCloudArrowDown,
    faCheck, faSort, faSuitcaseMedical, faFolderTree, faCity,
    faCheckSquare, faChevronDown, faChevronUp, faCircle, faCloud, faCloudDownloadAlt, faCloudUploadAlt, faUsers,
    faCodeBranch, faListOl, faSackDollar,
    faCogs, faDollarSign, faGripVertical, faPaperclip, faMoneyCheckDollar,
    faDownload,
    faEllipsisV, faExclamationCircle,
    faExclamationTriangle, faExternalLinkAlt, faBoxOpen, faLocationPin, faLocationDot,
    faFileInvoice,
    faFileInvoiceDollar, faFileSignature, faFilter, faHatWizard,
    faList, faListUl, faLock, faLongArrowAltRight,
    faMapMarker, faMinus, faMinusCircle, faTruck,
    faPaperPlane,
    faPencilAlt, faNetworkWired,
    faPlay,
    faPlus,
    faPlusCircle,
    faPrint,
    faQuestion,
    faQuestionCircle,
    faReceipt, faSearch,
    faShare, faShareSquare, faShoppingCart, faSignOutAlt, faSortAmountUp, faSortAmountUpAlt, faStar as fasStar,
    faSync, faSyncAlt,
    faTimes, faTimesCircle, faUndoAlt,
    faUpload, faUser,
    faVideo, faWallet,
    faWarehouse, faAngleDoubleRight, faAngleDoubleLeft, faCog, faTasks, faChartLine,
    faThumbtack, faAngleDown, faAngleUp, faBell, faConciergeBell, faBellConcierge, faCircleMinus, faAngleRight, faAngleLeft
} from "@fortawesome/free-solid-svg-icons";

import {faApple, faFacebookSquare, faTwitter} from "@fortawesome/free-brands-svg-icons";

import {iy2bSearch, iy2bPencil, iy2bTrash, iy2bPrint, iy2bCloudUpload, iy2bPlus, iy2bRectangleList,
    iy2bTableCellsLarge, iy2bSquarePlus as iy2bSquarePlusDuo, iy2bSquareMinus as iy2bSquareMinusDuo,
    iy2bListOl, iy2bHelp as iy2bHelpDuo, iy2bEdit, iy2bWarehouse, iy2bPayMoney, iy2bComments, iy2bSend
} from "./svg/iconografia/iy2bSvgIconsDuoTone";

import {iy2bBusinessProfit, iy2bBusinessReceipt, iy2bFileEdit, iy2bUserSquare2, iy2bUsers, iy2bDonate, iy2bBusinessInvoice, iy2bWeb,
    iy2bBuildingApartment, iy2bLogout, iy2bFilter, iy2bSetting3, iy2bSquarePlus, iy2bSquareMinus, iy2bSquare, iy2bEye2, iy2bNotification,
    iy2bHelp, iy2bList, iy2bCircleMinus, iy2bCirclePlus, iy2bCloudDownload, iy2bInfo, iy2bBusinessTax, iy2bEyeHidden, iy2bCircleCheck, iy2bStar
} from "./svg/iconografia/iy2bSvgIconsMono";


fontawesome.library.add(iy2bSearch, iy2bPencil, iy2bTrash, iy2bBusinessReceipt, iy2bFileEdit, iy2bBusinessProfit, iy2bUserSquare2,
    iy2bUsers, iy2bBuildingApartment, iy2bLogout, iy2bFilter, iy2bSetting3, iy2bPrint, iy2bCloudDownload, iy2bCloudUpload, iy2bPlus, iy2bSquarePlus,
    iy2bSquareMinus, iy2bRectangleList, iy2bTableCellsLarge, iy2bSquareMinusDuo, iy2bSquarePlusDuo, iy2bSquare, iy2bListOl, iy2bWeb,
    iy2bDonate, iy2bBusinessInvoice, iy2bEye2, iy2bNotification, iy2bHelp, iy2bHelpDuo, iy2bList, iy2bEdit, iy2bWarehouse, iy2bStar,
    iy2bPayMoney, faImage, iy2bCircleMinus, iy2bCirclePlus, iy2bComments, iy2bSend, iy2bInfo, iy2bBusinessTax, iy2bEyeHidden, iy2bCircleCheck,
    faApple, faFacebookSquare, faTwitter,faArchive, faArrowDown, faArrowLeftLong, faArrowRight, faArrowRightLong,
    faBan, faBars, faChalkboardTeacher, faChartPie, faRightToBracket, faAngleRight, faAngleLeft, faUsers,
    faCheck, faSuitcaseMedical, faSort, faPaperclip, faMoneyCheckDollar, faAngleUp,
    faCheckSquare, faChevronDown, faChevronUp, faCircle, faCloud, faCloudDownloadAlt, faCloudUploadAlt,
    faCodeBranch, faBoxOpen, faFolderTree, faComments,
    faCogs, faDollarSign, faMessage, faCity,
    faDownload, faDotCircle, faLocationDot, faNetworkWired,
    faEllipsisV, faExclamationCircle, faAddressBook,
    faExclamationTriangle, faExternalLinkAlt, faLocationPin,
    faFileInvoice,
    faFileInvoiceDollar, faFileSignature, faFilter, faHatWizard,
    faList, faListUl, faLock, faLongArrowAltRight, faCircleXmark,
    faMapMarker, faMinus, faMinusCircle,
    faPaperPlane, faCalendarCheck,
    faPencilAlt, faTableCellsLarge,
    faPlay,
    faPlus, faCreditCard, faSackDollar,
    faPlusCircle, faGripVertical,
    faPrint,
    faQuestion,
    faQuestionCircle,
    faReceipt, faSearch,
    faShare, faShareSquare, faShoppingCart, faSignOutAlt, faSortAmountUp, faSortAmountUpAlt, fasStar,
    faSync, faSyncAlt,
    faTimes, faTimesCircle, faUndoAlt,
    faUpload, faUser, faRectangleList,
    faVideo, faWallet,
    faWarehouse,
    faAddressCard, faListOl,
    faArrowAltCircleRight, farBell,
    faBuilding, faCheckCircle, faCloudArrowDown, faTruck,
    faCopy,
    faEye, faFileCode, faIdCard, faMinusSquare, faPlusSquare, faSquare, farStar, faRectangleXmark,
    faTrashAlt, farQuestionCircle, faArrowAltCircleDown, faAngleDoubleRight,
    faAngleDoubleLeft, faCog, faTasks, faChartLine, faThumbtack, faAngleDown, faAngleUp,
    faBell, faConciergeBell, faBellConcierge, faCircleMinus, faCaretDown, faEllipsisVertical, faPenToSquare);

const TRASH_ICON_RED = <FontAwesomeIcon icon={iy2bTrash} color={"#E00000"} />;
const ELLIPSIS_VERTICAL_ICON_PRIMARY = <FontAwesomeIcon icon={faEllipsisVertical} color={skinPrimaryColor} />;
const PLUS_CIRCLE_ICON_PRIMARY = <FontAwesomeIcon icon={faPlusCircle} color={skinPrimaryColor} />;
const PENCIL_ICON_PRIMARY = <FontAwesomeIcon icon={iy2bPencil} color={skinPrimaryColor} />;
const DOWNLOAD_ICON_PRIMARY = <FontAwesomeIcon icon={iy2bCloudDownload} color={skinPrimaryColor} />;
const INFOCIRCLE_ICON_PRIMARY = <FontAwesomeIcon icon={iy2bInfo} color={skinPrimaryColor} />;
const EYE_ICON_PRIMARY = <FontAwesomeIcon icon={iy2bEye2} color={skinPrimaryColor} />;
const CHECK_ICON_PRIMARY = <FontAwesomeIcon icon={faCheck} color={skinPrimaryColor} />;
const PRINT_ICON_PRIMARY = <FontAwesomeIcon icon={iy2bPrint} color={skinPrimaryColor} />;
const COPY_ICON_PRIMARY = <FontAwesomeIcon icon={faCopy} color={skinPrimaryColor} />;
const RECEIPT_ICON_PRIMARY = <FontAwesomeIcon icon={faReceipt} color={skinPrimaryColor} />;
const PAPER_PLANE_ICON_PRIMARY = <FontAwesomeIcon icon={faPaperPlane} color={skinPrimaryColor} />;
const FILE_INVOICE_ICON_PRIMARY = <FontAwesomeIcon icon={faFileInvoice} color={skinPrimaryColor} />;
const FILE_INVOICE_DOLLAR_ICON_PRIMARY = <FontAwesomeIcon icon={faFileInvoiceDollar} color={skinPrimaryColor} />;
const COGS_ICON_PRIMARY = <FontAwesomeIcon icon={faCogs} color={skinPrimaryColor} />;
const SYNC_ICON_PRIMARY = <FontAwesomeIcon icon={faSync} color={skinPrimaryColor}/>;
const WARE_HOUSE_ICON_PRIMARY = <FontAwesomeIcon icon={faWarehouse} color={skinPrimaryColor}/>;
const ELLIPSISV_ICON_GRAY = <FontAwesomeIcon icon={faEllipsisV} color={"#666666"} />;
const BARS_ICON_PRIMARY = <FontAwesomeIcon icon={faBars} color={skinPrimaryColor} />;
const LIST_ICON_PRIMARY = <FontAwesomeIcon icon={faList} color={skinPrimaryColor} />;
const FILESIGNATURE_ICON_PRIMARY = <FontAwesomeIcon icon={faFileSignature} color={skinPrimaryColor} />;
const UPLOAD_ICON_PRIMARY_4X = <FontAwesomeIcon icon={faUpload} color={skinPrimaryColor} size="4x"/>;
const FILTER_ICON_PRIMARY = <FontAwesomeIcon icon={iy2bFilter} color={skinPrimaryColor} />;
const CHEVRON_UP_ICON_PRIMARY = <FontAwesomeIcon icon={faChevronUp} color={skinPrimaryColor} />;
const CHEVRON_DOWN_ICON_PRIMARY = <FontAwesomeIcon icon={faChevronDown} color={skinPrimaryColor} />;
const UPLOAD_ICON_PRIMARY = <FontAwesomeIcon icon={faUpload} color={skinPrimaryColor} />;
const FILE_UPLOAD_ICON_PRIMARY = <FontAwesomeIcon icon={iy2bCloudUpload} color={skinPrimaryColor} />;
const SHARE_SQUARE_ICON_PRIMARY = <FontAwesomeIcon icon={faShareSquare} color={skinPrimaryColor} />;
const PLUS_ICON_PRIMARY = <FontAwesomeIcon icon={iy2bPlus} color={skinPrimaryColor} />;
const MINUS_SQUARE_ICON_PRIMARY = <FontAwesomeIcon icon={iy2bSquareMinus} color={skinPrimaryColor} />;
const PLUS_SQUARE_ICON_PRIMARY = <FontAwesomeIcon icon={iy2bSquarePlus} color={skinPrimaryColor} />;
const CLOUD_DOWNLOAD_ALT_ICON_PRIMARY = <FontAwesomeIcon icon={iy2bCloudDownload} color={skinPrimaryColor} />;
const ANGLE_DOUBLE_RIGHT_ICON_PRIMARY = <FontAwesomeIcon icon={faAngleDoubleRight} color={skinPrimaryColor} />;
const ANGLE_DOUBLE_LEFT_ICON_PRIMARY = <FontAwesomeIcon icon={faAngleDoubleLeft} color={skinPrimaryColor} />;
const SIGN_OUT_ALT_ICON_PRIMARY = <FontAwesomeIcon icon={iy2bLogout} color={skinPrimaryColor} />;
const MENU_ICONS = {
    'star': <FontAwesomeIcon icon={fasStar} color={"#8D8D8D"} />,
    'cogs': <FontAwesomeIcon icon={iy2bBusinessProfit} color={"#8D8D8D"} />,
    'tasks': <FontAwesomeIcon icon={iy2bFileEdit} color={"#8D8D8D"} />,
    'chart-line': <FontAwesomeIcon icon={iy2bBusinessReceipt} color={"#8D8D8D"} />,
    'pin': <FontAwesomeIcon icon={faThumbtack} color={skinPrimaryColor} />,
};
const BELL_ICON_PRIMARY = <FontAwesomeIcon icon={faBell} color={skinPrimaryColor} />;
const BELL_ICON_PRIMARY_2X = <FontAwesomeIcon icon={farBell} color={skinPrimaryColor} size="2x" />;

const PIN_ICON_PRIMARY = <FontAwesomeIcon icon={faThumbtack} color={skinPrimaryColor} />;

const HATWIZARD_ICON_PRIMARY_2X = <FontAwesomeIcon icon={faHatWizard} color={skinPrimaryColor} size="2x" />;

const RIGHT_TO_BRACKET_ICON = <FontAwesomeIcon icon={faRightToBracket} />;
const ANGLE_RIGHT_ICON = <FontAwesomeIcon icon={faAngleRight} />;
const ANGLE_LEFT_ICON = <FontAwesomeIcon icon={faAngleLeft}  />;

export {
    ANGLE_RIGHT_ICON, ANGLE_LEFT_ICON,
    RIGHT_TO_BRACKET_ICON,
    faCircleQuestion, ELLIPSIS_VERTICAL_ICON_PRIMARY,
    HATWIZARD_ICON_PRIMARY_2X,
    MINUS_SQUARE_ICON_PRIMARY,
    PLUS_SQUARE_ICON_PRIMARY,
    PIN_ICON_PRIMARY,
    BELL_ICON_PRIMARY_2X,
    BELL_ICON_PRIMARY,
    SIGN_OUT_ALT_ICON_PRIMARY,
    MENU_ICONS,
    ANGLE_DOUBLE_RIGHT_ICON_PRIMARY,
    ANGLE_DOUBLE_LEFT_ICON_PRIMARY,
    CLOUD_DOWNLOAD_ALT_ICON_PRIMARY,
    PLUS_ICON_PRIMARY,
    SHARE_SQUARE_ICON_PRIMARY,
    FILE_UPLOAD_ICON_PRIMARY,
    UPLOAD_ICON_PRIMARY,
    CHEVRON_DOWN_ICON_PRIMARY,
    CHEVRON_UP_ICON_PRIMARY,
    FILTER_ICON_PRIMARY,
    UPLOAD_ICON_PRIMARY_4X,
    FILESIGNATURE_ICON_PRIMARY,
    LIST_ICON_PRIMARY,
    TRASH_ICON_RED,
    PLUS_CIRCLE_ICON_PRIMARY,
    PENCIL_ICON_PRIMARY,
    DOWNLOAD_ICON_PRIMARY,
    INFOCIRCLE_ICON_PRIMARY,
    EYE_ICON_PRIMARY,
    CHECK_ICON_PRIMARY,
    PRINT_ICON_PRIMARY,
    COPY_ICON_PRIMARY,
    RECEIPT_ICON_PRIMARY,
    PAPER_PLANE_ICON_PRIMARY,
    FILE_INVOICE_ICON_PRIMARY,
    FILE_INVOICE_DOLLAR_ICON_PRIMARY,
    COGS_ICON_PRIMARY,
    SYNC_ICON_PRIMARY,
    ELLIPSISV_ICON_GRAY,
    WARE_HOUSE_ICON_PRIMARY,
    BARS_ICON_PRIMARY,
}

const PLUS_CIRCLE_ICON_PRIMARY_XS = <FontAwesomeIcon icon={faPlusCircle} color={skinPrimaryColor} size="xs" />;
const PENCIL_ICON_PRIMARY_XS = <FontAwesomeIcon icon={faPencilAlt} color={skinPrimaryColor} size="xs" />;
const CHECK_ICON_PRIMARY_XS = <FontAwesomeIcon icon={faCheck} color={skinPrimaryColor} size="xs" />;

const TRASH_ICON_RED_XS = <FontAwesomeIcon icon={iy2bTrash} color={"#E00000"} size="xs" />;
const UNDO_ALT_ICON_RED_XS = <FontAwesomeIcon icon={faUndoAlt} color={"#E00000"} size="xs" />;

export let xs = {
    TRASH_ICON_RED: TRASH_ICON_RED_XS,
    PLUS_CIRCLE_ICON_PRIMARY: PLUS_CIRCLE_ICON_PRIMARY_XS,
    PENCIL_ICON_PRIMARY: PENCIL_ICON_PRIMARY_XS,
    UNDO_ALT_ICON_RED: UNDO_ALT_ICON_RED_XS,
    CHECK_ICON_PRIMARY: CHECK_ICON_PRIMARY_XS
}

const PLUS_ICON_PRIMARY_LG = <FontAwesomeIcon icon={iy2bPlus} color={skinPrimaryColor} size={"lg"} />;
const SYNC_ICON_PRIMARY_LG = <FontAwesomeIcon icon={faSync} color={skinPrimaryColor} size={"lg"} />;

export let lg = {
    PLUS_ICON_PRIMARY: PLUS_ICON_PRIMARY_LG,
    SYNC_ICON_PRIMARY: SYNC_ICON_PRIMARY_LG
}

//////
//
// CUSTOM
//

const CHECK_CIRCLE_ICON_PURPLE = <FontAwesomeIcon icon={faCheckCircle} color={"#9982D1"} />;
const EXCLAMATION_CIRCLE_ICON_PURPLE_3X = <FontAwesomeIcon icon={faExclamationCircle} color={"#9982D1"} size={"3x"}/>;
const CIRCLE_ICON_PURPLE = <FontAwesomeIcon icon={faCircle} color={"#9982D1"} />;
const CHECK_ICON_PURPLE = <FontAwesomeIcon icon={faCheck} color={"#9982D1"} />;
const EXCLAMATION_CIRCLE_ICON_PURPLE = <FontAwesomeIcon icon={faExclamationCircle} color={"#9982D1"} />;

const CHECK_ICON_GREEN = <FontAwesomeIcon icon={faCheck} color={"#55CFA1"} />;
const PLUS_CIRCLE_ICON_GREEN_XS = <FontAwesomeIcon icon={faPlusCircle} color={"#55CFA1"} size="xs" />;
const UPLOAD_ICON_GREEN_4X = <FontAwesomeIcon icon={faUpload} color={"#38c593"} size="4x"/>;
const UPLOAD_ICON_GREEN_3X = <FontAwesomeIcon icon={faUpload} color={"#38c593"} size="3x"/>;
const CHECK_SQUARE_ICON_GREEN = <FontAwesomeIcon icon={faCheckSquare} color={"#55CFA1"}/>;
const CHECK_CIRCLE_ICON_GREEN_3X = <FontAwesomeIcon icon={faCheckCircle} color={"#55CFA1"} size={"3x"}/>;
const CHEVRON_UP_ICON_GREEN = <FontAwesomeIcon icon={faChevronUp} color={"#00C48C"} />;
const CHEVRON_DOWN_ICON_GREEN = <FontAwesomeIcon icon={faChevronDown} color={"#00C48C"} />;

const CHECK_CIRCLE_ICON_GREEN = <FontAwesomeIcon icon={faCheckCircle} color={"#20B2AA"} />;
const WALLET_ICON_GREEN = <FontAwesomeIcon icon={faWallet} color={"#20B2AA"} />;
const SYNC_ICON_GREEN = <FontAwesomeIcon icon={faSync} color={"#20B2AA"} />;

const SHOPPING_CART_ICON_WHITE = <FontAwesomeIcon icon={faShoppingCart} color={"#FFFFFF"}/>;
const BELL_ICON_WHITE = <FontAwesomeIcon icon={farBell} color={"#FFFFFF"}/>;
const BUILDING_ICON_WHITE_2X = <FontAwesomeIcon icon={iy2bBuildingApartment} color={"#FFFFFF"} size={"2x"}/>;
const HATWIZARD_ICON_WHITE_2X = <FontAwesomeIcon icon={faHatWizard} color={"#FFFFFF"} size={"2x"}/>;
const CHALKBOARD_TEACHER_ICON_WHITE = <FontAwesomeIcon icon={faChalkboardTeacher} color={"#FFFFFF"}/>;
const PLUS_ICON_WHITE = <FontAwesomeIcon icon={iy2bPlus} color={"#FFFFFF"}/>;
const MINUS_ICON_WHITE = <FontAwesomeIcon icon={faMinus} color={"#FFFFFF"}/>;
const ARROW_RIGHT_LONG_ICON_WHITE = <FontAwesomeIcon icon={faArrowRightLong} color={"#FFFFFF"} />;
const CHECK_ICON_WHITE = <FontAwesomeIcon icon={faCheck} color={"#FFFFFF"} />;
const BARS_ICON_WHITE = <FontAwesomeIcon icon={faBars} color={"#FFFFFF"} />;
const TIMES_ICON_WHITE = <FontAwesomeIcon icon={faTimes} color={"#FFFFFF"} />;
const EYE_ICON_WHITE = <FontAwesomeIcon icon={iy2bEye2} color={"#FFFFFF"} />;
const DOLLAR_SIGN_ICON_WHITE_3X = <FontAwesomeIcon icon={faDollarSign} color={"#FFFFFF"} size="3x"/>;
const SEARCH_ICON_WHITE = <FontAwesomeIcon icon={iy2bSearch} color={"#FFFFFF"} />;
const ANGLE_DOWN_ICON_WHITE = <FontAwesomeIcon icon={faAngleDown} color={"#FFFFFF"} />;
const BELL_ICON_WHITE_2X = <FontAwesomeIcon icon={faBell} color={"#FFFFFF"} size="2x"/>;

const PLUS_CIRCLE_ICON_GRAY_XS = <FontAwesomeIcon icon={faPlusCircle} color={"#999999"} size="xs" />;
const QUESTION_ICON_GRAY = <FontAwesomeIcon icon={faQuestion} color={"#999999"}/>;
const WARE_HOUSE_ICON_GRAY = <FontAwesomeIcon icon={faWarehouse} color={"#999999"}/>;

const BAN_ICON_GRAY_LG = <FontAwesomeIcon icon={faBan} color={"#666666"} size={"lg"} />;
const ARROW_ALT_CIRCLE_RIGHT_ICON_GRAY_INVERSE = <FontAwesomeIcon icon={faArrowAltCircleRight} color={"#666666"} inverse />;
const SHARE_ICON_GRAY = <FontAwesomeIcon icon={faShare} color={"#666666"} />;
const ARCHIVE_ICON_GRAY = <FontAwesomeIcon icon={faArchive} color={"#666666"} />;
const CHECK_SQUARE_ICON_GRAY = <FontAwesomeIcon icon={faCheckSquare} color={"#666666"} />;
const PLAY_ICON_GRAY = <FontAwesomeIcon icon={faPlay} color={"#666666"} />;
const EYE_ICON_GRAY = <FontAwesomeIcon icon={iy2bEye2} color={"#666666"} />;
const MAP_MARKER_ICON_GRAY = <FontAwesomeIcon icon={faMapMarker} color={"#666666"} />;
const CODE_BRANCH_ICON_GRAY = <FontAwesomeIcon icon={faCodeBranch} color={"#666666"} />;
const SQUARE_ICON_GRAY = <FontAwesomeIcon icon={iy2bSquare} color={"#666666"} />;
const ARROW_RIGHT_ICON_GRAY = <FontAwesomeIcon icon={faLongArrowAltRight} color={"#666666"} />;
const TIMES_CIRCLE_ICON_GRAY = <FontAwesomeIcon icon={faTimesCircle} color={"#666666"} />;
const CIRCLE_ICON_GRAY = <FontAwesomeIcon icon={faCircle} color={"#666666"} />;
const LOCK_ICON_GRAY = <FontAwesomeIcon icon={faLock} color={"#666666"} />;
const ARROW_RIGHT_ICON_GRAY_2X = <FontAwesomeIcon icon={faArrowRight} color={"#666666"} size={"2x"} />;
const ARROW_DOWN_ICON_GRAY_2X = <FontAwesomeIcon icon={faArrowDown} color={"#666666"} size={"2x"} />;

const USER_ICON_BLACK = <FontAwesomeIcon icon={faUser}  color={"#333333"}/>;
const TIMES_ICON_BLACK = <FontAwesomeIcon icon={faTimes} color={"#333333"} />;
const TIMES_ICON_BLACK_2X = <FontAwesomeIcon icon={faTimes} color={"#333333"} size={"2x"} />;
const PLAY_ICON_BLACK = <FontAwesomeIcon icon={faPlay} color={"#333333"}/>;
const QUESTION_CIRCLE_ICON_BLACK = <FontAwesomeIcon icon={faQuestionCircle} color={"#333333"}/>;
const VIDEO_ICON_BLACK = <FontAwesomeIcon icon={faVideo} color={"#333333"}/>;
const CHECK_ICON_BLACK = <FontAwesomeIcon icon={faCheck} color={"#333333"} />;
const CHART_PIE_ICON_BLACK = <FontAwesomeIcon icon={faChartPie} color={"#333333"} />;
const SIGN_OUT_ALT_ICON_BLACK = <FontAwesomeIcon icon={iy2bLogout} color={"#333333"} />;
const FILESIGNATURE_ICON_BLACK = <FontAwesomeIcon icon={faFileSignature} color={"#333333"} />;
const CLOUD_ICON_BLACK = <FontAwesomeIcon icon={faCloud} color={"#333333"} />;
const ADDRESSCARD_ICON_BLACK = <FontAwesomeIcon icon={faAddressCard} color={"#333333"} />;
const BUILDING_ICON_BLACK = <FontAwesomeIcon icon={iy2bBuildingApartment} color={"#333333"} />;
const QUESTIONCIRCLE_ICON_BLACK = <FontAwesomeIcon icon={faQuestionCircle} color={"#333333"} />;
const SQUARE_ICON_BLACK = <FontAwesomeIcon icon={iy2bSquare} color={"#333333"} />;
const LISTUL_ICON_BLACK = <FontAwesomeIcon icon={faListUl} color={"#333333"} />;
const SYNC_ALT_ICON_BLACK_FIXED = <FontAwesomeIcon icon={faSyncAlt} color={"#333333"} fixedWidth />;
const DOWNLOAD_ICON_BLACK_FIXED = <FontAwesomeIcon icon={faDownload} color={"#333333"} fixedWidth />;
const CHEVRON_UP_ICON_BLACK = <FontAwesomeIcon icon={faChevronUp} color={"#333333"} />;
const CHEVRON_DOWN_ICON_BLACK = <FontAwesomeIcon icon={faChevronDown} color={"#333333"} />;
const PLUS_SQUARE_ICON_BLACK = <FontAwesomeIcon icon={iy2bSquarePlus} color={"#333333"} />;
const MINUS_SQUARE_ICON_BLACK = <FontAwesomeIcon icon={iy2bSquareMinus} color={"#333333"} />;
const ARROW_LEFT_LONG_ICON_BLACK = <FontAwesomeIcon icon={faArrowLeftLong} color={"#333333"} />;
const SORT_AMOUNT_UP_ALT_ICON_BLACK = <FontAwesomeIcon icon={faSortAmountUpAlt} color={"#333333"} />;
const SORT_AMOUNT_UP_ICON_BLACK = <FontAwesomeIcon icon={faSortAmountUp} color={"#333333"} />;
const SEARCH_ICON_BLACK = <FontAwesomeIcon icon={iy2bSearch} color={"#333333"} />;
const EXTERNAL_LINK_ALT_ICON_BLACK = <FontAwesomeIcon icon={faExternalLinkAlt} color={"#333333"} />;
const LIST_ICON_BLACK = <FontAwesomeIcon icon={faList} color={"#333333"} />;
const ID_CARD_ICON_BLACK = <FontAwesomeIcon icon={faIdCard} color={"#333333"} />;

const BAN_ICON_RED = <FontAwesomeIcon icon={faBan} color={"#E00000"} />;
const TIMES_ICON_RED = <FontAwesomeIcon icon={faTimes} color={"#E00000"}/>;
const TIMES_CIRCLE_ICON_RED = <FontAwesomeIcon icon={faTimesCircle} color={"#E00000"} />;
const EXCLAMATION_TRIANGLE_ICON_RED = <FontAwesomeIcon icon={faExclamationTriangle} color={"#E00000"}/>;
const EXCLAMATION_CIRCLE_ICON_RED_3X = <FontAwesomeIcon icon={faExclamationCircle} color={"#E00000"} size={"3x"}/>;
const CIRCLE_ICON_RED = <FontAwesomeIcon icon={faCircle} color={"#E00000"} />;
const MINUS_CIRCLE_ICON_RED = <FontAwesomeIcon icon={faMinusCircle} color={"#E00000"} />;
const QUESTION_CIRCLE_ICON_RED = <FontAwesomeIcon icon={farQuestionCircle} color={"#E00000"}/>;
const BELL_ICON_RED_2X = <FontAwesomeIcon icon={faBell} color={"#E00000"} size="2x"/>;

//const iconAtrasado = <FontAwesomeIcon icon={faCircle} color={"#B52D33"} />;

const SYNC_ALT_ICON_PRIMARY_4X_PULSE = <FontAwesomeIcon icon={faSyncAlt} color={skinPrimaryColor} size="4x" pulse/>;

const STAR_REGULAR_ICON_YELLOW = <FontAwesomeIcon icon={farStar} color={"#DAA520"} />;
const STAR_SOLID_ICON_YELLOW = <FontAwesomeIcon icon={fasStar} color={"#DAA520"} />;

const PLUS_SQUARE_ICON = <FontAwesomeIcon icon={iy2bSquarePlusDuo}  />;
const MINUS_SQUARE_ICON = <FontAwesomeIcon icon={iy2bSquareMinusDuo}  />;



const STAR_REGULAR_ICON = <FontAwesomeIcon icon={farStar} />;
const STAR_SOLID_ICON = <FontAwesomeIcon icon={fasStar} />;
const FILE_CODE_ICON = <FontAwesomeIcon icon={faFileCode} />;
const ARROW_ALT_CIRCLE_DOWN_ICON = <FontAwesomeIcon icon={faArrowAltCircleDown} />;
const QUESTION_CIRCLE_ICON = <FontAwesomeIcon icon={farQuestionCircle} />;
const CHEVRON_DOWN_ICON = <FontAwesomeIcon icon={faChevronDown} />;
const DOWNLOAD_ICON = <FontAwesomeIcon icon={faDownload} />;
const APPLE_ICON = <FontAwesomeIcon icon={faApple} />;
const FACEBOOK_ICON = <FontAwesomeIcon icon={faFacebookSquare} />;
const SQUARE_ICON = <FontAwesomeIcon icon={iy2bSquare} />;
const TWITTER_ICON = <FontAwesomeIcon icon={faTwitter} />;
const TIMES_ICON = <FontAwesomeIcon icon={faTimes} />;
const BUILDING_ICON = <FontAwesomeIcon icon={iy2bBuildingApartment} />;
const LISTUL_ICON = <FontAwesomeIcon icon={faListUl} />;
const SIGN_OUT_ALT_ICON = <FontAwesomeIcon icon={iy2bLogout}  />;
const FILESIGNATURE_ICON = <FontAwesomeIcon icon={faFileSignature}  />;
const CLOUD_ICON = <FontAwesomeIcon icon={faCloud}  />;
const ADDRESSCARD_ICON = <FontAwesomeIcon icon={faAddressCard}  />;
const QUESTIONCIRCLE_ICON = <FontAwesomeIcon icon={faQuestionCircle} />;
const CARETDOWN_ICON = <FontAwesomeIcon icon={faCaretDown} />;
const ADDRESS_CARD_ICON = <FontAwesomeIcon icon={faAddressCard} />;
const USERS_ICON = <FontAwesomeIcon icon={iy2bUsers} />;
const RECTANGLE_XMARK_ICON = <FontAwesomeIcon icon={faRectangleXmark} />;
const CIRCLE_XMARK_ICON = <FontAwesomeIcon icon={faCircleXmark} />;
const PLUS_ICON = <FontAwesomeIcon icon={iy2bPlus} />;
const CLOUD_ARROW_DOWN_ICON = <FontAwesomeIcon icon={iy2bCloudDownload} />;
const CHECK_ICON = <FontAwesomeIcon icon={faCheck} />;
const CHECK_CIRCLE_ICON = <FontAwesomeIcon icon={faCheckCircle}  />;
const SORT_ICON = <FontAwesomeIcon icon={faSort} />;
const SUITCASE_MEDICAL_ICON = <FontAwesomeIcon icon={faSuitcaseMedical} />;
const RECTANGLE_LIST_ICON = <FontAwesomeIcon icon={iy2bRectangleList} />;
const TABLE_CELLS_LARGE_ICON = <FontAwesomeIcon icon={iy2bTableCellsLarge} />;
const GRIP_VERTICAL_ICON = <FontAwesomeIcon icon={faGripVertical} />;
const ANGLE_DOWN_ICON = <FontAwesomeIcon icon={faAngleDown} />;
const ANGLE_UP_ICON = <FontAwesomeIcon icon={faAngleUp} />;
const CALENDAR_CHECK_ICON = <FontAwesomeIcon icon={faCalendarCheck} />;
const PAPER_CLIP_ICON = <FontAwesomeIcon icon={faPaperclip} />;
const MONEY_CHECK_DOLLAR_ICON = <FontAwesomeIcon icon={faMoneyCheckDollar} />;
const WALLET_ICON = <FontAwesomeIcon icon={faWallet} />;
const DOT_CIRCLE_ICON = <FontAwesomeIcon icon={faDotCircle} />;
const LOCATION_PIN_ICON = <FontAwesomeIcon icon={faLocationPin} />;
const BOX_OPEN_ICON = <FontAwesomeIcon icon={faBoxOpen} />;
const DOLLAR_SIGN_ICON = <FontAwesomeIcon icon={faDollarSign} />;
const LOCATION_DOT_ICON = <FontAwesomeIcon icon={faLocationDot} />;
const ADDRESS_BOOK_ICON = <FontAwesomeIcon icon={faAddressBook} />;
const INFO_ICON = <FontAwesomeIcon icon={iy2bInfo} />;
const TRUCK_ICON = <FontAwesomeIcon icon={faTruck} />;
const MESSAGE_ICON = <FontAwesomeIcon icon={faMessage} />;
const FILE_INVOICE_ICON = <FontAwesomeIcon icon={faFileInvoice} />;
const HAT_WIZARD_ICON = <FontAwesomeIcon icon={faHatWizard} />;
const LIST_OL_ICON = <FontAwesomeIcon icon={iy2bListOl} />;
const PENCIL_ICON = <FontAwesomeIcon icon={faPencilAlt} />;
const CREDIT_CARD_ICON = <FontAwesomeIcon icon={faCreditCard} />;
const SACK_DOLAR_ICON = <FontAwesomeIcon icon={faSackDollar} />;
const FILE_INVOICE_DOLLAR_ICON = <FontAwesomeIcon icon={faFileInvoiceDollar} />;
const NETWORK_WIRED_ICON = <FontAwesomeIcon icon={faNetworkWired} />;

const FOLDER_TREE_ICON = <FontAwesomeIcon icon={faFolderTree} />;
const IMAGE_ICON = <FontAwesomeIcon icon={faImage} />;
const CITY_ICON = <FontAwesomeIcon icon={faCity} />;

const COMMENTS_ICON = <FontAwesomeIcon icon={iy2bComments} />;
const EYE_HIDDEN_ICON = <FontAwesomeIcon icon={iy2bEyeHidden} />;

const USER_ICON = <FontAwesomeIcon icon={iy2bUserSquare2} />;
// const USER_ICON = <FontAwesomeIcon icon={faUser} />;
const DONATE_ICON = <FontAwesomeIcon icon={iy2bDonate} />;
const BUSINESS_INVOICE_ICON = <FontAwesomeIcon icon={iy2bBusinessInvoice} />;
const BUSINESS_RECEIPT_ICON = <FontAwesomeIcon icon={iy2bBusinessReceipt} />;
const SEARCH_ICON = <FontAwesomeIcon icon={iy2bSearch} />;
const NOTIFICATION_ICON = <FontAwesomeIcon icon={iy2bNotification} />;
const HELP_ICON = <FontAwesomeIcon icon={iy2bHelp} />;
const HELP_DUO_ICON = <FontAwesomeIcon icon={iy2bHelpDuo} />;
const LIST_ICON = <FontAwesomeIcon icon={iy2bList} />;
const EDIT_ICON = <FontAwesomeIcon icon={iy2bEdit} />;
const WAREHOUSE_ICON = <FontAwesomeIcon icon={iy2bWarehouse} />;
const PAY_MONEY_ICON = <FontAwesomeIcon icon={iy2bPayMoney} />;
const CIRCLE_MINUS_ICON = <FontAwesomeIcon icon={iy2bCircleMinus} />;
const CIRCLE_PLUS_ICON = <FontAwesomeIcon icon={iy2bCirclePlus} />;
const SEND_ICON = <FontAwesomeIcon icon={iy2bSend} />;
const CIRCLE_CHECK_ICON = <FontAwesomeIcon icon={iy2bCircleCheck} />;
const BUSINESS_TAX = <FontAwesomeIcon icon={iy2bBusinessTax} />;
const STAR_ICON = <FontAwesomeIcon icon={iy2bStar} />;
const SETTINGS_ICON = <FontAwesomeIcon icon={iy2bSetting3} />;
const WEB_ICON = <FontAwesomeIcon icon={iy2bWeb} />;


const CIRCLE_ICON = <FontAwesomeIcon icon={faCircle} />;


const CIRCLE_ICON_YELLOW = <FontAwesomeIcon icon={faCircle} color={"#E0CE59"} />;
const CIRCLE_ICON_GREEN = <FontAwesomeIcon icon={faCircle} color={"#69B36C"} />;
const CIRCLE_ICON_RED_2X = <FontAwesomeIcon icon={faCircle} color={"#B3696C"} size={"2x"} />;

const PEN_TO_SQUARE_ICON_GREY = <FontAwesomeIcon icon={faPenToSquare} color={"#8D8D8D"} />;

export let CUSTOM = { ADDRESS_CARD_ICON, USERS_ICON, RECTANGLE_XMARK_ICON, CIRCLE_XMARK_ICON, CLOUD_ARROW_DOWN_ICON, CIRCLE_CHECK_ICON,
    PEN_TO_SQUARE_ICON_GREY, PLUS_ICON, CHECK_ICON, CHECK_CIRCLE_ICON, SORT_ICON, TABLE_CELLS_LARGE_ICON, STAR_ICON,
    CHEVRON_DOWN_ICON_GREEN, CHEVRON_UP_ICON_GREEN, SUITCASE_MEDICAL_ICON, RECTANGLE_LIST_ICON, SETTINGS_ICON,
    CARETDOWN_ICON, GRIP_VERTICAL_ICON, ANGLE_UP_ICON, CALENDAR_CHECK_ICON, CREDIT_CARD_ICON,
    BELL_ICON_WHITE_2X, BELL_ICON_RED_2X, ANGLE_DOWN_ICON, PAPER_CLIP_ICON, MONEY_CHECK_DOLLAR_ICON,
    STAR_REGULAR_ICON_YELLOW, BOX_OPEN_ICON, DOLLAR_SIGN_ICON, WALLET_ICON, BUSINESS_TAX,
    STAR_SOLID_ICON_YELLOW, LOCATION_PIN_ICON, LOCATION_DOT_ICON, ADDRESS_BOOK_ICON,
    SIGN_OUT_ALT_ICON, DOT_CIRCLE_ICON, INFO_ICON, TRUCK_ICON,
    FILESIGNATURE_ICON, MESSAGE_ICON, FILE_INVOICE_ICON, HELP_DUO_ICON,
    ADDRESSCARD_ICON, HAT_WIZARD_ICON, PENCIL_ICON, FILE_INVOICE_DOLLAR_ICON,
    QUESTIONCIRCLE_ICON, LIST_OL_ICON, SEARCH_ICON,
    CLOUD_ICON, SACK_DOLAR_ICON, BUSINESS_INVOICE_ICON, DONATE_ICON, BUSINESS_RECEIPT_ICON,
    LISTUL_ICON, NOTIFICATION_ICON, HELP_ICON, LIST_ICON, EDIT_ICON,
    BUILDING_ICON, WAREHOUSE_ICON, PAY_MONEY_ICON,
    USER_ICON, CIRCLE_MINUS_ICON, CIRCLE_PLUS_ICON,
    TIMES_ICON, COMMENTS_ICON,
    SQUARE_ICON, IMAGE_ICON, CIRCLE_ICON,
    DOWNLOAD_ICON, WEB_ICON,
    CHEVRON_DOWN_ICON,
    QUESTION_CIRCLE_ICON,
    ARROW_ALT_CIRCLE_DOWN_ICON,
    ID_CARD_ICON_BLACK,
    LIST_ICON_BLACK,
    EXTERNAL_LINK_ALT_ICON_BLACK,
    SEARCH_ICON_BLACK,
    TIMES_CIRCLE_ICON_RED,
    CIRCLE_ICON_RED_2X,
    CIRCLE_ICON_GREEN,
    CIRCLE_ICON_YELLOW,
    CIRCLE_ICON_GRAY,
    SORT_AMOUNT_UP_ICON_BLACK,
    SORT_AMOUNT_UP_ALT_ICON_BLACK,
    FILE_CODE_ICON,
    STAR_REGULAR_ICON,
    STAR_SOLID_ICON,
    CHECK_ICON_WHITE,
    ARROW_LEFT_LONG_ICON_BLACK,
    FACEBOOK_ICON,
    ARROW_RIGHT_ICON_GRAY,
    SQUARE_ICON_GRAY,
    MINUS_SQUARE_ICON_BLACK,
    PLUS_SQUARE_ICON_BLACK,
    TWITTER_ICON,
    CHEVRON_UP_ICON_BLACK,
    CHEVRON_DOWN_ICON_BLACK,
    CHALKBOARD_TEACHER_ICON_WHITE,
    DOWNLOAD_ICON_BLACK_FIXED,
    SYNC_ALT_ICON_BLACK_FIXED,
    EXCLAMATION_CIRCLE_ICON_RED_3X,
    CHECK_SQUARE_ICON_GREEN,
    SQUARE_ICON_BLACK,
    CHART_PIE_ICON_BLACK,
    SYNC_ALT_ICON_PRIMARY_4X_PULSE,
    BELL_ICON_WHITE,
    CHECK_CIRCLE_ICON_PURPLE,
    QUESTIONCIRCLE_ICON_BLACK,
    BUILDING_ICON_BLACK,
    ADDRESSCARD_ICON_BLACK,
    CLOUD_ICON_BLACK,
    FILESIGNATURE_ICON_BLACK,
    SIGN_OUT_ALT_ICON_BLACK,
    WARE_HOUSE_ICON_GRAY,
    ARROW_ALT_CIRCLE_RIGHT_ICON_GRAY_INVERSE,
    SHARE_ICON_GRAY,
    ARCHIVE_ICON_GRAY,
    CHECK_SQUARE_ICON_GRAY,
    PLAY_ICON_GRAY,
    EYE_ICON_GRAY,
    MAP_MARKER_ICON_GRAY,
    CODE_BRANCH_ICON_GRAY,
    PLUS_CIRCLE_ICON_GREEN_XS,
    PLUS_CIRCLE_ICON_GRAY_XS,
    BAN_ICON_RED,
    CHECK_ICON_GREEN,
    BAN_ICON_GRAY_LG,
    TIMES_ICON_RED,
    EXCLAMATION_TRIANGLE_ICON_RED,
    TIMES_ICON_BLACK,
    PLAY_ICON_BLACK,
    QUESTION_CIRCLE_ICON_BLACK,
    VIDEO_ICON_BLACK,
    QUESTION_ICON_GRAY,
    CHECK_ICON_BLACK,
    UPLOAD_ICON_GREEN_4X,
    SHOPPING_CART_ICON_WHITE,
    USER_ICON_BLACK,
    BUILDING_ICON_WHITE_2X,
    LISTUL_ICON_BLACK,
    CHECK_CIRCLE_ICON_GREEN_3X,
    EXCLAMATION_CIRCLE_ICON_PURPLE_3X,
    HATWIZARD_ICON_WHITE_2X,
    PLUS_ICON_WHITE,
    MINUS_ICON_WHITE,
    TIMES_CIRCLE_ICON_GRAY,
    ARROW_RIGHT_LONG_ICON_WHITE,
    BARS_ICON_WHITE,
    APPLE_ICON,
    TIMES_ICON_WHITE,
    CHECK_CIRCLE_ICON_GREEN,
    WALLET_ICON_GREEN,
    EYE_ICON_WHITE,
    SYNC_ICON_GREEN,
    DOLLAR_SIGN_ICON_WHITE_3X,
    LOCK_ICON_GRAY,
    CIRCLE_ICON_RED,
    CIRCLE_ICON_PURPLE,
    CHECK_ICON_PURPLE,
    ARROW_RIGHT_ICON_GRAY_2X,
    ARROW_DOWN_ICON_GRAY_2X,
    SEARCH_ICON_WHITE,
    MINUS_CIRCLE_ICON_RED,
    QUESTION_CIRCLE_ICON_RED,
    ANGLE_DOWN_ICON_WHITE,
    TIMES_ICON_BLACK_2X,
    EXCLAMATION_CIRCLE_ICON_PURPLE,
    UPLOAD_ICON_GREEN_3X, CITY_ICON,
    PLUS_SQUARE_ICON, MINUS_SQUARE_ICON, FOLDER_TREE_ICON, SEND_ICON, EYE_HIDDEN_ICON, NETWORK_WIRED_ICON
}

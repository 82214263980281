//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../framework/assets/css/tema.scss';
import './style.scss';

import React, {useState} from "react";

import AutoCompletePessoa from "../../framework/componentes/proform/AutoCompletePessoa";
import {pesquisarCliente} from "../../lib/servicosPortalIY2B";
import {Button} from "antd";

import {inicializar as inicializarDP} from "../../transacoes/Cliente/clienteAPI";
import {salvar as salvarCliente} from "../../transacoes/Cliente/clienteAPI";
import EditarCliente from "../../transacoes/Cliente/editarCliente";
import {notificacao} from "../../framework/lib/Notificacao";
import {campoInformado} from "../../framework/lib/iy2b-javascript";

const AutoCompleteCliente = ( props ) => {

    const {formRef, api, name, placeholder, tooltip, rules, cdPessoaEmpAtual, cdTransacao=0 } = props ;

    const {width="lg", label="Cliente", disabled=false, attrId="cdCli", habilitaAdicao=false, autoFocus=false, onNovoCliente=null } = props ;

    const [exibeNovoRegistro, setExibeNovoRegistro] = useState(false);

    const [dataProvider, setDataProvider] = useState({ inicializado: false });

    const [atLeastOneFetch, setFetched] = useState(false);

    const boxAutoComplete = ["box-auto-complete"];

    const btnAdicionarCliente = async () => {

        if(dataProvider.inicializado === false) {

            const dataProvider = await inicializarDP(cdPessoaEmpAtual, cdTransacao);

            setDataProvider(dataProvider);

        }

        setExibeNovoRegistro(true) ;

    };

    const salvarNovoCliente = async ( registro ) => {

        const modelo = await salvarCliente(registro, cdTransacao);

        if(modelo != null) {

            const acCliente = {
                cdCli: modelo.cliente.cdCli,
                cdPessoa: modelo.cdPessoaCli,
                noPessoa: modelo.noPessoa,
                nrCGCCIC: modelo.nrCGCCIC,
                srcData: {
                    cdCli: modelo.cliente.cdCli,
                    cdPessoa: modelo.cdPessoaCli,
                    noPessoa: modelo.noPessoa,
                    nrCGCCIC: modelo.nrCGCCIC,
                }
            }

            api.current.setFieldValue(acCliente);

            notificacao.sucesso({message:"Sucesso!", description:"Cliente salvo com sucesso!"})

        }

        return modelo;

    }

    let contentNotFoundAfterFetch = null ;

    if(habilitaAdicao===true && disabled===false && atLeastOneFetch===true) {
        contentNotFoundAfterFetch = <div>
            Não encontrou o cliente ?
            <Button
                type={"link"}
                onClick={btnAdicionarCliente}
                style={{"whiteSpace": "initial"}}
            >
                Clique aqui para adicionar um novo.
            </Button>
        </div>;
    }

    return (<div className={"box-auto-complete-cliente"}>
        <div className={boxAutoComplete.toClassName()}>
            <AutoCompletePessoa
                width={ width }
                formRef = { formRef }
                api = { api }
                name = { name }
                label = { label }
                placeholder = { placeholder }
                tooltip={ tooltip }
                attrId={ attrId }
                autoFocus= { autoFocus }
                onRequestCGCCIC={async (value) => {
                    const lista = await pesquisarCliente({nrCGCCIC: value});
                    setFetched(true);
                    return lista;
                }}
                onRequestNome={async (value) => {
                    const lista = await pesquisarCliente({noPessoa: value});
                    setFetched(true);
                    return lista;
                }}
                rules={rules}
                disabled={disabled}
                contentNotFoundAfterFetch={contentNotFoundAfterFetch}
                habilitaAdicao={habilitaAdicao}
            />
        </div>
        <EditarCliente
            exibePopUp={ exibeNovoRegistro }
            onRequestClose={() => {
                setExibeNovoRegistro(false) ;
            }}
            dataProvider={dataProvider}
            registros={[]}
            salvar={salvarNovoCliente}
            smaller
            closeAfterSave
            onNovoCliente={onNovoCliente}
        />
    </div>);

}

export default AutoCompleteCliente;

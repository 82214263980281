//v5 import "antd/dist/antd.css";
//v5 import '@ant-design/pro-layout/dist/layout.css';
//v5 import '@ant-design/pro-form/dist/form.css';
import '../../assets/css/tema.scss';

import BotaoToolTip from "../BotaoToolTip/botaoToolTip";
import React from "react";

import {PENCIL_ICON_PRIMARY, xs} from "../../assets/iconografia";

const iconPencil = PENCIL_ICON_PRIMARY;

const BotaoEditar = ( props ) => {

    const { editarRegistro } = props;

    return (
        <BotaoToolTip className={"botao-editar-item"}
            tooltip={"Editar o nome"}
            icon={iconPencil}
            placement={"left"}
            onClick={() => {
                editarRegistro ();
            }}
        />
    )

}

export {
    BotaoEditar
}

//v5 import "antd/dist/antd.css";
import "./style.scss";

import {usePromiseTracker} from "react-promise-tracker";
import React from "react";
import {Circles} from "react-loader-spinner";

const HeaderToolbarLoadingIndicator = ( props ) => {

    const { width, height } = props ;
    const { promiseInProgress } = usePromiseTracker( {area: props.area});

//

    return (
        promiseInProgress &&
        <div className="headerTBLoadingPosition">
            <div className="headerTBBackdropLoading"
                 style={{
                     width: width,
                     minHeight: height
                 }}
            >
                <div
                    style={{
                        display: "block",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: "2px",
                        left: "8px",
                    }}
                >
                    <Circles color="#00C48C" height="36" width="36"/>
                </div>
            </div>
        </div>
    );

}

export default HeaderToolbarLoadingIndicator;
